import React, { ReactNode } from "react";
import { useGetAssetDeviceTypesRequest } from "assets/api";
import { AssetType } from "assets/models/assetType";

interface DeviceTypesLoaderProps {
  assetType: AssetType;
  children: (params: { options: { label: string; id: string }[]; loading: boolean }) => ReactNode;
}

export const DeviceTypesLoader = (props: DeviceTypesLoaderProps) => {
  const { data, loading } = useGetAssetDeviceTypesRequest(props.assetType, false);

  const options = (data || []).map((d) => ({ label: d, id: d }));

  return <>{props.children({ options, loading })}</>;
};
