import React, { ReactNode, useEffect } from "react";
import { useGetSpecOptionsRequest } from "common";

interface SpecOptionLoaderProps {
   type: string;
   doNotCacheOptions?: boolean;
   disabled?: boolean;
   children: (params: { options: { label: string; id: string }[]; loading: boolean }) => ReactNode;
}

export const SpecOptionLoader = (props: SpecOptionLoaderProps) => {
   const { data, loading, call } = useGetSpecOptionsRequest(props.type, true, props.doNotCacheOptions);

   useEffect(() => {
      if (!props.disabled) {
         call();
      }
   }, [props.type, props.disabled]);

   const options = (data || []).map((d) => ({ label: d, id: d }));

   return <>{props.children({ options, loading })}</>;
};
