import React from "react";
import { Form } from "reactstrap";
import { EditKpiFormProps } from "./EditKpiFormProps";
import { EditKpiLegacyForm } from "./EditKpiLegacyForm";
import { EditKpiOneTimeDowntimeAvoidanceForm } from "./EditKpiOneTimeDowntimeAvoidanceForm";
import { EditKpiOneTimeLabourOrEquipmentCostSavingsForm } from "./EditKpiOneTimeLabourOrEquipmentCostSavingsForm";
import { EditKpiOneTimeReleaseOrSpillAvoidanceForm } from "./EditKpiOneTimeReleaseOrSpillAvoidanceForm";
import { EditKpiOngoingEmissionsReductionForm } from "./EditKpiOngoingEmissionsReductionForm";
import { EditKpiOngoingMaintenanceSavingsOrProductionImprovementForm } from "./EditKpiOngoingMaintenanceSavingsOrProductionImprovementForm";
import classNames from "classnames";
import { EditKpiOneTimeScheduleDelayReductionForm } from "./EditKpiOneTimeScheduleDelayReductionForm";

export const EditKpiForm: React.FunctionComponent<EditKpiFormProps> = (props) => {
  return (
    <Form className={classNames({ "set-max-width": props.detail.kpiType !== "Legacy" })}>
      {props.detail.kpiType === "Legacy" && <EditKpiLegacyForm {...props} />}
      {props.detail.kpiType === "OneTimeDowntimeAvoidance" && (
        <EditKpiOneTimeDowntimeAvoidanceForm {...(props as EditKpiFormProps)} />
      )}
      {props.detail.kpiType === "OneTimeLabourOrEquipmentCostSavings" && (
        <EditKpiOneTimeLabourOrEquipmentCostSavingsForm {...props} />
      )}
      {props.detail.kpiType === "OneTimeReleaseOrSpillAvoidance" && (
        <EditKpiOneTimeReleaseOrSpillAvoidanceForm {...props} />
      )}
      {props.detail.kpiType === "OneTimeScheduleDelayReduction" && (
        <EditKpiOneTimeScheduleDelayReductionForm {...props} />
      )}
      {props.detail.kpiType === "OngoingEmissionsReduction" && <EditKpiOngoingEmissionsReductionForm {...props} />}
      {props.detail.kpiType === "OngoingMaintenanceSavingsOrProductionImprovement" && (
        <EditKpiOngoingMaintenanceSavingsOrProductionImprovementForm {...props} />
      )}
      {props.children}
    </Form>
  );
};
