// react hook form docs state that when we populate default values for a form,
// we should not have 'undefined' variables. We should replace them with null or empty strings.
export const makeSafeForReactHookForm = <T extends object>(object: T): T => {
  const safeObject = { ...object };

  Object.keys(object).map((key) => {
    if (object[key] === undefined) {
      safeObject[key] = null;
    }
  });

  return safeObject;
};
