import React, { useState } from "react";
import {
   FailureMode,
   FailureSeverity,
   FailureProbability,
   FailureDetectionAbility,
   MitigationType,
   Strategy,
   FailureModeView,
   ApplicationDetailView,
} from "./models";
import {
   Input,
   FormGroup,
   Label,
   Row,
   Col,
   Badge,
   Collapse,
   Card,
   CardTitle,
   Button,
} from "reactstrap";
import Select from "react-select";
import Textarea from "react-textarea-autosize";
import { CollapseIcon } from "icons/CollapseIcon";
import classNames from "classnames";
import { Asset } from "assets";
import { dateFormat } from "common/formats";
import moment from "moment";
import _ from "lodash";
import { DeleteIcon } from "icons/DeleteIcon";
import { Link } from "react-router-dom";
import { formatRoutePath, IrisTooltip, Routes, useAutosaver, useUserContext } from "app";
import { ConfirmationModal } from "common/ConfirmationModal";
import { InformationIcon } from "icons/InformationIcon";
import { useGetRecordsByIdQuery } from "records";
import { useDeleteApplicationFailureModeRequest, useUpdateApplicationFailureModeRequest } from "./api";

const severityOptions = [
   { label: "Failure", value: "Failure" },
   { label: "Significant impact", value: "SignificantImpact" },
   { label: "Moderate impact", value: "ModerateImpact" },
   { label: "Low impact", value: "LowImpact" },
];

const probabilityOptions = [
   { label: "High", value: "High" },
   { label: "Moderate", value: "Moderate" },
   { label: "Low", value: "Low" },
   { label: "Remote", value: "Remote" },
];

const detectionAbilityOptions = [
   { label: "Unable to detect", value: "UnableToDetect" },
   { label: "Detected only", value: "DetectedOnly" },
   {
      label: "Sometimes detected and prevented",
      value: "SometimesDetectedAndPrevented",
   },
   {
      label: "Always detected and prevented",
      value: "AlwaysDetectedAndPrevented",
   },
];

interface Props {
   applicationView: ApplicationDetailView;
   failureModeView: FailureModeView;
   strategy: Strategy;
   forAsset: Asset | null;
   readOnly: boolean;
   expanded: boolean;
   toggleExpanded: () => void;
   failureModeDeleted: () => any;
   createFailureRecord?: (failureModeId: number) => any;
}

export const FailureModeDisplay: React.FunctionComponent<Props> = (props) => {
   const { userPermissions } = useUserContext();

   const { applicationView, failureModeView } = props;
   const updateFailureModeRequest = useUpdateApplicationFailureModeRequest(applicationView.id);
   const [failureMode, saveFailureMode] = useAutosaver(failureModeView.model, updateFailureModeRequest.call);

   const deleteFailureModeRequest = useDeleteApplicationFailureModeRequest(applicationView.id);

   const getFailureModeRecordsQuery = useGetRecordsByIdQuery(failureMode.records.map((r) => r.id));
   const failureModeRecordDetails = getFailureModeRecordsQuery.data ?? [];

   const [deleting, setDeleting] = useState(false);

   const saveChange = (changedProp: Partial<FailureMode>, skipDelay?: boolean) => {
      saveFailureMode(
         {
            ...failureMode,
            ...changedProp,
         },
         skipDelay
      );
   };

   const editable = !props.forAsset && userPermissions.userCanEditApplication && userPermissions.userCanEditRecord;
   const { expanded } = props;

   const mitigationBadges =
      failureModeView.mitigations.length > 0 ? (
         <div className="badges">
            {failureModeView.mitigations
               .filter((fmm) => fmm.type === MitigationType.Alert)
               .map((fmm) => (
                  <Badge key={fmm.id} color="alert-mitigation">
                     {fmm.name || "unnamed"}
                  </Badge>
               ))}
            {failureModeView.mitigations
               .filter((fmm) => fmm.type === MitigationType.Diagnostic)
               .map((fmm) => (
                  <Badge key={fmm.id} color="diagnostic-mitigation">
                     {fmm.name || "unnamed"}
                  </Badge>
               ))}
            {failureModeView.mitigations
               .filter((fmm) => fmm.type === MitigationType.FieldWork)
               .map((fmm) => (
                  <Badge key={fmm.id} color="field-work-mitigation">
                     {fmm.name || "unnamed"}
                  </Badge>
               ))}
            {failureModeView.mitigations
               .filter((fmm) => fmm.type === MitigationType.Proactive)
               .map((fmm) => (
                  <Badge key={fmm.id} color="proactive-mitigation">
                     {fmm.name || "unnamed"}
                  </Badge>
               ))}
         </div>
      ) : expanded ? (
         <div>No associated mitigations</div>
      ) : null;

   const failuresByAsset = _.groupBy(
      _.orderBy(failureModeRecordDetails, (r) => r.created),
      (r) => r.model.assets[0].tag
   );

   const failureStatus = (
      <div className="failure-mode-status">
         {props.strategy.assets.length === 0 ? (
            <span className="text-info">No assets assigned to strategy</span>
         ) : failureModeRecordDetails.length > 0 ? (
            <span className="text-danger">{`${failureModeRecordDetails.length} failure record(s) on ${
               _.uniqBy(
                  _.flatMap(failureModeRecordDetails, (record) => record.model.assets),
                  (ar) => ar.id
               ).length
            } asset(s)`}</span>
         ) : (
            <span className="text-success">No failure records</span>
         )}
      </div>
   );

   return (
      <Card body className={classNames("failure-mode", { collapsed: !expanded })}>
         <CardTitle className={classNames("failure-mode-header", { collapsed: !expanded })}>
            <div className="collapse-toggle" onClick={() => props.toggleExpanded()}>
               <CollapseIcon expanded={expanded} />

               <div className="name-and-badges">
                  <div className="name">
                     {expanded && <span>Failure mode:&nbsp;</span>}
                     {failureMode.name ? <span>{failureMode.name}</span> : <span className="text-muted">unnamed</span>}
                  </div>
                  {!expanded && mitigationBadges}
               </div>

               {!expanded && !props.forAsset && failureStatus}
            </div>

            {props.forAsset && !!props.createFailureRecord && (
               <Button size="sm" outline color="danger" onClick={() => props.createFailureRecord!(failureModeView.id)}>
                  Create failure record
               </Button>
            )}

            {!props.forAsset && editable && (
               <>
                  <div className="delete-button" onClick={() => setDeleting(true)}>
                     <DeleteIcon />
                  </div>

                  {deleting && (
                     <ConfirmationModal
                        title="Delete failure mode?"
                        body="Are you sure you want to delete this failure mode?"
                        show={deleting}
                        cancel={() => setDeleting(false)}
                        confirm={async () => {
                           deleteFailureModeRequest.call(failureMode.id).then(() => {
                              props.failureModeDeleted();
                           });
                        }}
                     />
                  )}
               </>
            )}
         </CardTitle>

         <Collapse isOpen={expanded}>
            {editable && (
               <FormGroup>
                  <Label for="failure-mode-name">Name</Label>
                  <Input
                     name="failure-mode-name"
                     value={failureMode.name}
                     onChange={(e) => {
                        saveChange({
                           name: e.target.value,
                        });
                     }}
                     plaintext={!editable}
                     readOnly={!editable}
                  />
               </FormGroup>
            )}
            <Row>
               <Col lg={6}>
                  <FormGroup>
                     <Label for="potential-root-cause">Potential root cause</Label>
                     {editable ? (
                        <Textarea
                           className="form-control"
                           name="potential-root-cause"
                           value={failureMode.potentialRootCause || ""}
                           onChange={(e) => {
                              saveChange({
                                 potentialRootCause: e.target.value,
                              });
                           }}
                        />
                     ) : (
                        <p>
                           {failureMode.potentialRootCause && failureMode.potentialRootCause.trim()
                              ? failureMode.potentialRootCause
                              : "--"}
                        </p>
                     )}
                  </FormGroup>
               </Col>

               <Col lg={6}>
                  <FormGroup>
                     <Label for="potential-effect">Potential effect</Label>
                     {editable ? (
                        <Select
                           name="potential-effect"
                           className="react-select"
                           classNamePrefix="react-select"
                           value={failureMode.potentialEffect ? { value: failureMode.potentialEffect } : null}
                           onChange={(newValue) =>
                              saveChange(
                                 {
                                    potentialEffect: (newValue as { value: string }).value,
                                 },
                                 true
                              )
                           }
                           getOptionLabel={(option) => option.value}
                           getOptionValue={(option) => option.value}
                           options={[
                              { value: "Mechanical failure" },
                              { value: "Loss of control" },
                              { value: "Reduced control" },
                              { value: "Loss of shutoff" },
                              { value: "Loss of containment" },
                              { value: "Loss of measurement" },
                              { value: "Reduced accuracy" },
                           ]}
                        />
                     ) : (
                        <p>
                           {failureMode.potentialEffect && failureMode.potentialEffect.trim()
                              ? failureMode.potentialEffect
                              : "--"}
                        </p>
                     )}
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col md={6}>
                  <FormGroup>
                     <Label for="severity">
                        Severity
                        <IrisTooltip
                           title={
                              <>
                                 <div className="popover-title">Failure:</div>
                                 <div>
                                    Loss of control, containment or ability to perform primary function. Address
                                    immediately.
                                 </div>
                                 <div className="popover-title">Significant impact:</div>
                                 <div>
                                    Immediate action required to prevent loss of control, containment, or ability to
                                    perform primary function. Address in next 24 hours.
                                 </div>
                                 <div className="popover-title">Moderate impact:</div>
                                 <div>
                                    Immediate action required to prevent loss of control, containment, or ability to
                                    perform primary function. Address in next 24 hours.
                                 </div>
                                 <div className="popover-title">Low impact:</div>
                                 <div>
                                    Minimal operational or safety related impact. Address at next suitable opportunity.
                                 </div>
                              </>
                           }
                        >
                           <span className="info-icon">
                              <InformationIcon />
                           </span>
                        </IrisTooltip>
                     </Label>
                     {editable ? (
                        <Select
                           name="severity"
                           className="react-select"
                           classNamePrefix="react-select"
                           value={
                              failureMode.severity !== null
                                 ? severityOptions.find((o) => o.value === failureMode.severity)
                                 : null
                           }
                           options={severityOptions}
                           formatOptionLabel={(option) => (
                              <div className="risk-option">
                                 <div
                                    className={classNames("swatch", {
                                       red: option.value === "Failure",
                                       orange: option.value === "SignificantImpact",
                                       yellow: option.value === "ModerateImpact",
                                       green: option.value === "LowImpact",
                                    })}
                                 />
                                 {option.label}
                              </div>
                           )}
                           onChange={(newValue) => {
                              saveChange(
                                 {
                                    severity: (newValue as { value: FailureSeverity }).value,
                                 },
                                 true
                              );
                           }}
                        />
                     ) : (
                        <p>
                           {failureMode.severity !== null
                              ? severityOptions.filter((o) => o.value === failureMode.severity)[0].label
                              : "--"}
                        </p>
                     )}
                  </FormGroup>
               </Col>

               <Col md={6}>
                  <FormGroup>
                     <Label for="detection-ability">Detection ability</Label>
                     {editable ? (
                        <Select
                           name="detection-ability"
                           className="react-select"
                           classNamePrefix="react-select"
                           value={
                              failureMode.detectionAbility !== null
                                 ? detectionAbilityOptions.find((o) => o.value === failureMode.detectionAbility)
                                 : null
                           }
                           options={detectionAbilityOptions}
                           formatOptionLabel={(option) => (
                              <div className="risk-option">
                                 <div
                                    className={classNames("swatch", {
                                       red: option.value === "UnableToDetect",
                                       orange: option.value === "DetectedOnly",
                                       yellow: option.value === "SometimesDetectedAndPrevented",
                                       green: option.value === "AlwaysDetectedAndPrevented",
                                    })}
                                 />
                                 {option.label}
                              </div>
                           )}
                           onChange={(newValue) => {
                              saveChange(
                                 {
                                    detectionAbility: (
                                       newValue as {
                                          value: FailureDetectionAbility;
                                       }
                                    ).value,
                                 },
                                 true
                              );
                           }}
                        />
                     ) : (
                        <p>
                           {failureMode.detectionAbility !== null
                              ? detectionAbilityOptions.filter((o) => o.value === failureMode.detectionAbility)[0].label
                              : "--"}
                        </p>
                     )}
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col md={6}>
                  <FormGroup>
                     <Label for="unmitigated-probability">
                        Unmitigated probability
                        <IrisTooltip
                           title={
                              <>
                                 <p>The frequency of the potential effect occurring with no mitigations in place.</p>
                                 <div className="popover-title">High:</div>
                                 <div>Several times per year</div>
                                 <div className="popover-title">Moderate:</div>
                                 <div>About once per year</div>
                                 <div className="popover-title">Low:</div>
                                 <div>Every 1-3 years</div>
                                 <div className="popover-title">Remote:</div>
                                 <div>Unlikely to occur within 3 years</div>
                              </>
                           }
                        >
                           <span className="info-icon">
                              <InformationIcon />
                           </span>
                        </IrisTooltip>
                     </Label>
                     {editable ? (
                        <Select
                           name="unmitigated-probability"
                           className="react-select"
                           classNamePrefix="react-select"
                           value={
                              failureMode.unmitigatedProbability !== null
                                 ? probabilityOptions.find((o) => o.value === failureMode.unmitigatedProbability)
                                 : null
                           }
                           options={probabilityOptions}
                           formatOptionLabel={(option) => (
                              <div className="risk-option">
                                 <div
                                    className={classNames("swatch", {
                                       red: option.value === "High",
                                       orange: option.value === "Moderate",
                                       yellow: option.value === "Low",
                                       green: option.value === "Remote",
                                    })}
                                 />
                                 {option.label}
                              </div>
                           )}
                           onChange={(newValue) => {
                              saveChange(
                                 {
                                    unmitigatedProbability: (
                                       newValue as {
                                          value: FailureProbability;
                                       }
                                    ).value,
                                 },
                                 true
                              );
                           }}
                        />
                     ) : (
                        <p>
                           {failureMode.unmitigatedProbability !== null
                              ? probabilityOptions.filter((o) => o.value === failureMode.unmitigatedProbability)[0]
                                   .label
                              : "--"}
                        </p>
                     )}
                  </FormGroup>
               </Col>

               <Col md={6}>
                  <FormGroup>
                     <Label for="mitigated-probability">
                        Mitigated probability
                        <IrisTooltip
                           title={
                              <>
                                 <p>The frequency of the potential effect occurring with all mitigations in place.</p>
                                 <div className="popover-title">High:</div>
                                 <div>Several times per year</div>
                                 <div className="popover-title">Moderate:</div>
                                 <div>About once per year</div>
                                 <div className="popover-title">Low:</div>
                                 <div>Every 1-3 years</div>
                                 <div className="popover-title">Remote:</div>
                                 <div>Unlikely to occur within 3 years</div>
                              </>
                           }
                        >
                           <span id={`mitigated-probability-info-icon${failureMode.id}`} className="info-icon">
                              <InformationIcon />
                           </span>
                        </IrisTooltip>
                     </Label>
                     {editable ? (
                        <Select
                           name="mitigated-probability"
                           className="react-select"
                           classNamePrefix="react-select"
                           value={
                              failureMode.mitigatedProbability !== null
                                 ? probabilityOptions.find((o) => o.value === failureMode.mitigatedProbability)
                                 : null
                           }
                           options={probabilityOptions}
                           formatOptionLabel={(option) => (
                              <div className="risk-option">
                                 <div
                                    className={classNames("swatch", {
                                       red: option.value === "High",
                                       orange: option.value === "Moderate",
                                       yellow: option.value === "Low",
                                       green: option.value === "Remote",
                                    })}
                                 />
                                 {option.label}
                              </div>
                           )}
                           onChange={(newValue) => {
                              saveChange(
                                 {
                                    mitigatedProbability: (
                                       newValue as {
                                          value: FailureProbability;
                                       }
                                    ).value,
                                 },
                                 true
                              );
                           }}
                        />
                     ) : (
                        <p>
                           {failureMode.mitigatedProbability !== null
                              ? probabilityOptions.filter((o) => o.value === failureMode.mitigatedProbability)[0].label
                              : "--"}
                        </p>
                     )}
                  </FormGroup>
               </Col>
            </Row>

            <FormGroup>
               <Label for="contingency-plan">Contingency plan</Label>
               {editable ? (
                  <Textarea
                     className="form-control"
                     name="contingency-plan"
                     value={failureMode.contingencyPlan || ""}
                     onChange={(e) => {
                        saveChange({
                           contingencyPlan: e.target.value,
                        });
                     }}
                     minRows={5}
                  />
               ) : (
                  <p>
                     {failureMode.contingencyPlan && failureMode.contingencyPlan.trim()
                        ? failureMode.contingencyPlan
                        : "--"}
                  </p>
               )}
            </FormGroup>

            <FormGroup>
               <div className="header">Mitigations</div>
               {mitigationBadges}
            </FormGroup>

            <div className="recorded-failures">
               <div className="header">Failure records</div>
               {failureModeRecordDetails.length > 0 ? (
                  <div className="failures">
                     {_.map(failuresByAsset, (failureRecords, tag) => {
                        const sortedFailureRecords = _.orderBy(failureRecords, (r) => r.created);
                        return (
                           <div key={tag} className="failures-for-asset">
                              {!props.forAsset && (
                                 <Link
                                    className="tag"
                                    to={formatRoutePath(Routes.Asset, failureRecords[0].model.assets[0])}
                                 >
                                    {tag}
                                 </Link>
                              )}
                              <div className="records">
                                 {sortedFailureRecords.map((record) => (
                                    <div key={record.id} className="record">
                                       <Link to={formatRoutePath(Routes.Record, record)}>
                                          <span className="date">{moment(record.created!).format(dateFormat)}</span>
                                          {record.model.description}
                                       </Link>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        );
                     })}
                  </div>
               ) : (
                  <div className="text-success">No failure records</div>
               )}
            </div>
         </Collapse>
      </Card>
   );
};
