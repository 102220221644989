import { TextField, TextFieldProps, useFormControl } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import * as React from "react";
import { RegisterOptions } from "react-hook-form";
import { BaseFormItemProps } from "../Base/BaseFormItemProps";
import { ReadonlyFormItem } from "../ReadonlyFormItem";
import { withEditableToggle } from "../withEditableToggle";

export interface TextFieldFormItemProps extends BaseFormItemProps {
  textFieldProps?: TextFieldProps;
  formFieldProps?: RegisterOptions<any, any>;
  children?: React.ReactNode;
}

export const TextFieldFormItem = (props: TextFieldFormItemProps) => {
  const control = useFormControl();
  return props.readOnly ? (
    <ReadonlyFormItem label={props.label || ""} fieldName={props.fieldName} />
  ) : (
    <FormItemLayout
      fieldName={props.fieldName}
      label={props.label}
      hint={props.hint}
      formFieldProps={props.formFieldProps}
    >
      {(inputProps) => (
        <>
          <TextField {...props.textFieldProps} {...inputProps} error={control?.error} />
          {props.children}
        </>
      )}
    </FormItemLayout>
  );
};

export const EditableTextFieldFormItem = withEditableToggle(TextFieldFormItem);
