import classNames from "classnames";
import { PhaseCompleteIcon } from "icons/PhaseCompleteIcon";
import { PhaseInProgressIcon } from "icons/PhaseInProgressIcon";
import { PhaseWaitingIcon } from "icons/PhaseWaitingIcon";
import React, { useState } from "react";
import { Card, CardBody, CardTitle, Col, Fade, Row } from "reactstrap";
import { Record } from "records/models";
import {
   RecordOutageDetailView,
   RecordOutagePlanningScope,
   RecordOutagePlanningScopeView,
   RecordOutageScopePhase,
} from "./models";
import { PlanningTab } from "./planning/PlanningTab";
import { EvaluationTab } from "./evaluation/EvaluationTab";
import { ExecutionTab } from "./execution/ExecutionTab";
import { useGetOutagePlanningScopesByRecordIdQuery, useGetRecordOutageDetailViewByRecordIdQuery } from "records";
import { Loading } from "app/bs";

interface RecordOutageDetailCardProps {
   record: Record;
   userCanEdit: boolean;
}

interface RecordOutageDetailCardWithDataProps extends RecordOutageDetailCardProps {
   outageDetailView: RecordOutageDetailView;
   recordOutagePlanningScopes: RecordOutagePlanningScopeView[];
   refetchView: () => any;
}

export interface RecordDetailSectionProps {
   userCanEdit: boolean;
   refetchView: () => Promise<any>;
}

export const RecordOutageDetailCard: React.FunctionComponent<RecordOutageDetailCardProps> = (props) => {
   const getOutageDetailViewQuery = useGetRecordOutageDetailViewByRecordIdQuery(props.record.id);
   const outageDetailView = getOutageDetailViewQuery.data;

   const outageScopesQuery = useGetOutagePlanningScopesByRecordIdQuery(props.record.id);
   const outageScopes = outageScopesQuery.data || [];

   if (!outageDetailView) return <Loading />;

   return (
      <RecordOutageDetailCardWithData
         {...props}
         recordOutagePlanningScopes={outageScopes}
         outageDetailView={outageDetailView}
         refetchView={() => {
            alert("refetched");
            //getOutageDetailViewQuery.call();
            //outageScopesQuery.call();
         }}
      />
   );
};

const RecordOutageDetailCardWithData: React.FunctionComponent<RecordOutageDetailCardWithDataProps> = (props) => {
   const { outageDetailView, recordOutagePlanningScopes } = props;
   const [selectedTab, setSelectedTab] = useState<RecordOutageScopePhase | null>(
      props.outageDetailView.currentPhase ?? "Planning"
   );

   return (
      <Card className="record-outage-details">
         <CardTitle className={classNames("section-title")}>
            <Row className="record-outage-details-nav">
               <RecordOutageDetailsTab
                  phase="Planning"
                  isSelected={selectedTab === "Planning"}
                  isCurrent={outageDetailView.currentPhase === "Planning"}
                  isCompleted={!!outageDetailView.completedPlanning}
                  onClick={() => setSelectedTab("Planning")}
               />
               <RecordOutageDetailsTab
                  phase="Execution"
                  isSelected={selectedTab === "Execution"}
                  isCurrent={outageDetailView.currentPhase === "Execution"}
                  isCompleted={!!outageDetailView.completedExecution}
                  onClick={() => setSelectedTab("Execution")}
               />
               <RecordOutageDetailsTab
                  phase="Evaluation"
                  isSelected={selectedTab === "Evaluation"}
                  isCurrent={outageDetailView.currentPhase === "Evaluation" && !outageDetailView.completedEvaluation}
                  isCompleted={!!outageDetailView.completedEvaluation}
                  onClick={() => setSelectedTab("Evaluation")}
               />
            </Row>
         </CardTitle>
         <CardBody>
            {selectedTab === "Planning" && (
               <Fade>
                  <PlanningTab
                     recordId={props.record.id}
                     outageDetailView={outageDetailView}
                     recordOutagePlanningScopes={recordOutagePlanningScopes}
                     userCanEdit={props.userCanEdit}
                     refetchView={props.refetchView}
                  />
               </Fade>
            )}
            {selectedTab === "Execution" && (
               <Fade>
                  <ExecutionTab
                     record={props.record}
                     outageDetailView={outageDetailView}
                     userCanEdit={props.userCanEdit}
                     refetchView={props.refetchView}
                  />
               </Fade>
            )}
            {selectedTab === "Evaluation" && (
               <Fade>
                  <EvaluationTab
                     outageDetailView={outageDetailView}
                     userCanEdit={props.userCanEdit}
                     refetchView={props.refetchView}
                  />
               </Fade>
            )}
         </CardBody>
      </Card>
   );
};

interface TabProps {
   phase: RecordOutageScopePhase;
   isCompleted: boolean;
   isCurrent: boolean;
   isSelected: boolean;
   onClick: () => any;
}

export const RecordOutageDetailsTab: React.FunctionComponent<TabProps> = (props) => {
   return (
      <Col
         xs={4}
         style={{ cursor: props.isSelected ? "auto" : "pointer" }}
         onClick={props.onClick}
         className={classNames(
            props.phase,
            "record-outage-details-tab",
            { "is-completed": props.isCompleted },
            { "is-current": props.isCurrent },
            { "is-selected": props.isSelected }
         )}
      >
         <div className="wrapper">
            <div className="icon">
               {props.isCompleted ? (
                  <PhaseCompleteIcon />
               ) : props.isCurrent ? (
                  <PhaseInProgressIcon />
               ) : (
                  <PhaseWaitingIcon />
               )}
            </div>
            <div className="title">{props.phase}</div>
            <div className="bottom-underline"></div>
         </div>
      </Col>
   );
};
