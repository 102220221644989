import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
} from "app";
import { Keyword, KeywordUsage } from "./models";

const apiKeywordsMutationUrls = [/api\/keywords/gi];

export const useGetKeywordUsageRequest = () => useBaseAxiosGetRequest<KeywordUsage[]>("/api/keywords/usage", {
  useNode: true,
});

export const useGetAllKeywordsRequest = () =>
  useBaseAxiosGetRequestWithAutoRefresh<Keyword[]>("/api/keywords", apiKeywordsMutationUrls, {
    useNode: true,
  });

interface SearchCachedKeywordsRequest {
  contains?: string;
  in?: string[];
}
export const useSearchKeywordsRequest = () =>
  useBaseAxiosPostRequest<SearchCachedKeywordsRequest, Keyword[]>(`/api/keywords/search`, {
    app: { hideFromAutosaver: true },
    useNode: true,
  });

export const useCreateKeywordRequest = () => useBaseAxiosPostRequest<Keyword>("/api/keywords", {
  useNode: true,
});

export const useDeleteKeywordRequest = () => useBaseAxiosDeleteRequest((id) => `/api/keywords/${id}`, {
  useNode: true,
});
