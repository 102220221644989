import React, { useEffect, useState } from "react";
import { KpiOneTimeLabourOrEquipmentCostSavings, KpiSavingsCategoryOptions } from "../models";
import { NumberInputGroup } from "records/bs/NumberInputGroup";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { getInvalidValidity, getValidValidity, numberWithCommas, twoDecimalNumberFormatter, Validity } from "common";
import classNames from "classnames";
import { EditKpiFormProps } from "./EditKpiFormProps";
import NumberFormat from "react-number-format";
import { KpiRemoteHoursOption, RemoteHoursFormGroup } from "../formFields/RemoteHoursFormGroup";
import { KpiHelpTooltip } from "kpis/formFields/KpiHelpTooltip";
import { EffectiveRateTooltip } from "kpis/formFields/EffectiveRateTooltip";

interface Props extends EditKpiFormProps {}

export interface KpiOneTimeLabourOrEquipmentCostSavingsValidity {
  multiplier: Validity;
  spartanHours: Validity;
  remoteHours: Validity;
  customerHours: Validity;
  actualEquipmentCost: Validity;
  potentialHours: Validity;
  potentialEquipmentCost: Validity;
  savingsCategory: Validity;
  dedicatedTripsAvoided: Validity;
}

const deserializeMultipliers = (option: string | null): Partial<KpiOneTimeLabourOrEquipmentCostSavings> => {
  const split = (option ?? "").split("_") as string[];
  if (split.length !== 3) return { multiplier: 0, multiplyLabour: false, multiplyEquipment: false };
  const multiplier = parseInt(split[0]);
  const multiplyLabour = !!parseInt(split[1]);
  const multiplyEquipment = !!parseInt(split[2]);

  return { multiplier, multiplyLabour, multiplyEquipment };
};

const serializeMultipliers = (fields: Partial<KpiOneTimeLabourOrEquipmentCostSavings>): string | null => {
  if (fields.multiplier === null || fields.multiplyEquipment === null || fields.multiplyLabour === null) return null;
  if (!!!fields.multiplier) return "";
  return `${fields.multiplier}_${!!fields.multiplyLabour ? 1 : 0}_${!!fields.multiplyEquipment ? 1 : 0}`;
};

const kpiApproachOptions = [
  {
    label: "Custom amount",
    value: serializeMultipliers({ multiplier: 0, multiplyLabour: false, multiplyEquipment: false }),
  },
]
  .concat(
    [2, 3, 4, 5].map((i) => ({
      label: `${i}x labour`,
      value: serializeMultipliers({ multiplier: i, multiplyLabour: true, multiplyEquipment: false }),
    }))
  )
  .concat(
    [2, 3, 4, 5].map((i) => ({
      label: `${i}x labour + equipment`,
      value: serializeMultipliers({ multiplier: i, multiplyLabour: true, multiplyEquipment: true }),
    }))
  );

export const getKpiOneTimeLabourOrEquipmentCostSavingsValidity = (
  fields: KpiOneTimeLabourOrEquipmentCostSavings
): KpiOneTimeLabourOrEquipmentCostSavingsValidity => {
  return {
    multiplier: fields.multiplier === null ? getInvalidValidity() : getValidValidity(),
    spartanHours: getValidValidity(),
    customerHours:
      !!!fields.potentialHours || fields.customerHours !== null ? getValidValidity() : getInvalidValidity(),
    actualEquipmentCost: getValidValidity(),
    potentialHours: getValidValidity(),
    remoteHours:
      !fields.spartanHours ||
      (fields.remoteHours !== null && fields.remoteHours >= 0 && fields.remoteHours <= fields.spartanHours)
        ? getValidValidity()
        : getInvalidValidity("Remote hours cannot be higher than labour hours."),
    potentialEquipmentCost: getValidValidity(),
    savingsCategory: getValidValidity(),
    dedicatedTripsAvoided: fields.dedicatedTripsAvoided === null ? getInvalidValidity() : getValidValidity(),
  };
};

export const EditKpiOneTimeLabourOrEquipmentCostSavingsForm: React.FunctionComponent<Props> = (props) => {
  const model = props.model as KpiOneTimeLabourOrEquipmentCostSavings;
  const serverModel = props.detail.model as KpiOneTimeLabourOrEquipmentCostSavings;

  const [validity, setValidity] = useState<KpiOneTimeLabourOrEquipmentCostSavingsValidity>(
    getKpiOneTimeLabourOrEquipmentCostSavingsValidity(model)
  );
  const [selectedRemoteHoursOption, setSelectedRemoteHoursOption] = useState<KpiRemoteHoursOption | null>(
    model.remoteHours === null
      ? null
      : model.remoteHours === 0
      ? "None"
      : model.remoteHours === model.spartanHours
      ? "All"
      : "Some"
  );

  const validate = (fields: KpiOneTimeLabourOrEquipmentCostSavings) => {
    const newValidity = getKpiOneTimeLabourOrEquipmentCostSavingsValidity(fields);
    setValidity(newValidity);
    return _.values(newValidity).filter((v) => !v.valid).length === 0;
  };

  useEffect(() => {
    if (!!props.onLoaded) {
      const isValid = _.values(validity).filter((v) => !v.valid).length === 0;
      props.onLoaded(isValid);
    }
  }, []);

  const saveChange = (updates: Partial<KpiOneTimeLabourOrEquipmentCostSavings>) => {
    const updatedFields = { ...model, ...updates };
    const isValid = validate(updatedFields);
    props.onUpdated(updatedFields, isValid);
  };

  const combinedActualHours = (model?.spartanHours || 0) + (model?.customerHours || 0);
  const specialistLabourCost = (model?.spartanHours || 0) * (model?.spartanRatePerHour || 0);
  const sitePersonnelLabourCost = (model?.customerHours || 0) * (model?.customerRatePerHour || 0);
  const potentialLaborCost = !!model.multiplyLabour
    ? (model?.multiplier || 0) * (model?.customerRatePerHour || 0) * combinedActualHours
    : (model?.potentialHours || 0) * (model?.customerRatePerHour || 0);
  const potentialEquipmentCost = !!model.multiplyEquipment
    ? (model?.multiplier || 0) * (model?.actualEquipmentCost || 0)
    : model?.potentialEquipmentCost || 0;

  const selectedApproachSerialized = serializeMultipliers(model);
  const selectedApproach = _.first(kpiApproachOptions.filter((o) => o.value === selectedApproachSerialized));

  return (
    <>
      <Row className="gray-form-section">
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <p className="form-section-header">KPI approach</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={7}>
              <FormGroup>
                <Label>&nbsp;</Label>
                <div className="form-group-input-wrapper">
                  <Select
                    className={classNames("react-select", {
                      "is-invalid": props.showValidationErrors && !validity.multiplier.valid,
                    })}
                    classNamePrefix="react-select"
                    value={selectedApproach}
                    onChange={(selected) => {
                      saveChange(deserializeMultipliers(selected?.value || null));
                    }}
                    options={kpiApproachOptions}
                    isDisabled={props.readOnly}
                  />
                  <KpiHelpTooltip type="kpiApproach" />
                </div>
                <div className="invalid-feedback">{validity.multiplier.message}</div>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={5}>
              <FormGroup>
                <Label>Savings category</Label>
                <div className="form-group-input-wrapper">
                  <Select
                    className={classNames("react-select", {
                      "is-invalid": props.showValidationErrors && !validity.savingsCategory.valid,
                    })}
                    classNamePrefix="react-select"
                    value={{ value: model.savingsCategory, label: model.savingsCategory }}
                    onChange={(newSavingsCategory) =>
                      saveChange({
                        savingsCategory: !!newSavingsCategory ? newSavingsCategory.value : null,
                      })
                    }
                    options={KpiSavingsCategoryOptions}
                    getOptionLabel={(option) => option.value || ""}
                    isDisabled={props.readOnly}
                  />
                  <KpiHelpTooltip type="savingsCategory" />
                </div>
                <div className="invalid-feedback">{validity.savingsCategory.message}</div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="gray-form-section">
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={11}>
              <p className="form-section-header">Actual costs</p>
              <p>Technology-driven approach</p>
            </Col>
            <Col xs={12} sm={1} className="totals">
              <div>
                <p className="figure">
                  <NumberFormat
                    displayType="text"
                    value={sitePersonnelLabourCost + specialistLabourCost + (serverModel.actualEquipmentCost || 0)}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="subheading" style={{ fontSize: "1em" }}>
                Labour
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={4}>
              <FormGroup>
                <Label>Specialist labour</Label>
                <NumberInputGroup
                  postLabel={
                    <>
                      <span>
                        hrs &times; ${twoDecimalNumberFormatter.format(serverModel.spartanRatePerHour ?? 0)}/ hr
                      </span>
                      <EffectiveRateTooltip
                        isKpiTemplate={!props.record}
                        rate={serverModel.spartanRatePerHour}
                        rateEffectiveDate={serverModel.rateEffectiveDate}
                        rateEffectiveDateType={serverModel.rateEffectiveDateType}
                        rateType="Specialist"
                      />
                    </>
                  }
                  placeholder="hours"
                  inputStyle={{ width: "60px" }}
                  value={model.spartanHours}
                  onChange={(newValue) =>
                    saveChange({
                      spartanHours: newValue,
                      remoteHours: selectedRemoteHoursOption === "All" ? newValue || 0 : model.remoteHours,
                    })
                  }
                  valid={!props.showValidationErrors || validity.spartanHours.valid}
                  validationError={validity.spartanHours.message}
                  readOnly={props.readOnly}
                />
              </FormGroup>
            </Col>
            <Col xs={12} lg={4}>
              <RemoteHoursFormGroup
                fields={model}
                readOnly={props.readOnly}
                saveChange={saveChange}
                selectedRemoteHoursOption={selectedRemoteHoursOption}
                setSelectedRemoteHoursOption={setSelectedRemoteHoursOption}
                showValidationErrors={props.showValidationErrors}
                validity={validity.remoteHours}
              />
            </Col>
            <Col xs={12} md={8} lg={3}>
              <FormGroup>
                <Label>Site visit avoided</Label>
                <div className="form-group-input-wrapper">
                  <FormGroup check style={{ display: "flex" }}>
                    <Label check disabled={props.readOnly}>
                      <Input
                        type="radio"
                        name="siteVisitAvoided"
                        checked={!model?.dedicatedTripsAvoided}
                        disabled={props.readOnly}
                        readOnly={props.readOnly}
                        onChange={(e) => saveChange({ dedicatedTripsAvoided: false })}
                      />
                      No
                    </Label>
                  </FormGroup>
                  <FormGroup check style={{ display: "flex" }}>
                    <Label check disabled={props.readOnly}>
                      <Input
                        type="radio"
                        name="siteVisitAvoided"
                        checked={model?.dedicatedTripsAvoided ?? false}
                        disabled={props.readOnly}
                        readOnly={props.readOnly}
                        onChange={(e) => saveChange({ dedicatedTripsAvoided: true })}
                      />
                      Yes
                    </Label>
                  </FormGroup>
                  <KpiHelpTooltip type="dedicatedTripsAvoided" />
                </div>

                <div className="invalid-feedback">{validity.dedicatedTripsAvoided.message}</div>
              </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={1} className="totals">
              <div>
                <p className="figure-light">
                  <NumberFormat
                    displayType="text"
                    value={specialistLabourCost}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={11}>
              <FormGroup>
                <Label>Site personnel labour</Label>
                <NumberInputGroup
                  postLabel={
                    <>
                      <span>
                        hrs &times; ${twoDecimalNumberFormatter.format(serverModel.customerRatePerHour ?? 0)} / hr
                      </span>
                      <EffectiveRateTooltip
                        isKpiTemplate={!props.record}
                        rate={serverModel.customerRatePerHour}
                        rateEffectiveDate={serverModel.rateEffectiveDate}
                        rateEffectiveDateType={serverModel.rateEffectiveDateType}
                        rateType="Site personnel"
                      />
                    </>
                  }
                  placeholder="hours"
                  inputStyle={{ width: "60px" }}
                  value={model.customerHours}
                  onChange={(newValue) =>
                    saveChange({
                      customerHours: newValue,
                    })
                  }
                  valid={!props.showValidationErrors || validity.customerHours.valid}
                  validationError={validity.customerHours.message}
                  readOnly={props.readOnly}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={1} className="totals">
              <div>
                <p className="figure-light">
                  <NumberFormat
                    displayType="text"
                    value={sitePersonnelLabourCost}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="subheading" style={{ fontSize: "1em" }}>
                Equipment
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={3}>
              <FormGroup>
                <NumberInputGroup
                  preLabel="$"
                  placeholder="cost"
                  inputStyle={{ width: "80px" }}
                  value={model.actualEquipmentCost}
                  onChange={(newValue) =>
                    saveChange({
                      actualEquipmentCost: newValue,
                    })
                  }
                  valid={!props.showValidationErrors || validity.actualEquipmentCost.valid}
                  validationError={validity.actualEquipmentCost.message}
                  readOnly={props.readOnly}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={9} className="totals">
              <div>
                <p className="figure-light">
                  <NumberFormat
                    displayType="text"
                    value={model?.actualEquipmentCost || 0}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="gray-form-section">
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={11}>
              <p className="form-section-header">Potential costs</p>
              <p>Traditional approach</p>
            </Col>
            <Col xs={12} sm={1} className="totals">
              <div>
                <p className="figure">
                  <NumberFormat
                    displayType="text"
                    value={potentialLaborCost + potentialEquipmentCost}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="subheading" style={{ fontSize: "1em", marginBottom: 0 }}>
                Labour
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={11}>
              <FormGroup>
                <div className="form-group-input-wrapper">
                  {!!!model.multiplyLabour && (
                    <NumberInputGroup
                      placeholder={"hours"}
                      postLabel={
                        <>
                          {"hrs "}
                          &times;
                          {" $" + twoDecimalNumberFormatter.format(model.customerRatePerHour || 0) + " / hr"}
                        </>
                      }
                      inputStyle={{
                        width: "60px",
                      }}
                      value={model.potentialHours}
                      onChange={(newValue) =>
                        saveChange({
                          potentialHours: newValue,
                        })
                      }
                      valid={!props.showValidationErrors || validity.potentialHours.valid}
                      readOnly={props.readOnly}
                    />
                  )}
                  {!!model.multiplyLabour && (
                    <>
                      {numberWithCommas(model.multiplier || 0)}
                      &nbsp;&times;&nbsp;
                      {numberWithCommas(combinedActualHours) + " hrs"}
                      &nbsp;{"="}&nbsp;
                      {numberWithCommas(combinedActualHours * (model.multiplier || 0)) + " hrs at "}
                      {"$" + twoDecimalNumberFormatter.format(model.customerRatePerHour || 0) + " / hr"}
                    </>
                  )}
                  <KpiHelpTooltip type="oneTimeLabourPotentialLabour" spanStyle={{ marginBottom: ".2em" }} />
                </div>
              </FormGroup>
            </Col>
            <Col xs={12} sm={1} className="totals">
              <div>
                <p className="figure-light">
                  <NumberFormat
                    displayType="text"
                    value={potentialLaborCost}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="subheading" style={{ fontSize: "1em" }}>
                Equipment
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={11}>
              {!!!model.multiplyEquipment && (
                <NumberInputGroup
                  preLabel="$"
                  placeholder="cost"
                  inputStyle={{ width: "80px" }}
                  value={model.potentialEquipmentCost}
                  onChange={(newValue) =>
                    saveChange({
                      potentialEquipmentCost: newValue,
                    })
                  }
                  valid={!props.showValidationErrors || validity.potentialEquipmentCost.valid}
                  readOnly={props.readOnly}
                />
              )}
              {!!model.multiplyEquipment && (
                <>
                  {numberWithCommas(model.multiplier || 0)}
                  &nbsp;&times;&nbsp;
                  {"$" + numberWithCommas(model.actualEquipmentCost || 0)}
                  &nbsp;{"="}&nbsp;
                  {"$" + numberWithCommas((model.actualEquipmentCost || 0) * (model.multiplier || 0))}
                </>
              )}
            </Col>
            <Col xs={12} sm={1} className="totals">
              <div>
                <p className="figure-light">
                  <NumberFormat
                    displayType="text"
                    value={potentialEquipmentCost || 0}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
