import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDownRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { EditableContextProvider } from "app/mui/forms/EditableContext";
import FormItemLabel from "app/mui/forms/FormItemLabel";
import { ReadonlyFormItem } from "app/mui/forms/ReadonlyFormItem";
import { TabSelect } from "app/mui/forms/Tabs/TabSelect";
import { IrisColors } from "app/mui/theme";
import { AssetMachineryComponent } from "assets/models/machineryComponent";
import { sortBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { AssetAttributeField } from "./AssetAttributeField";
import { DeleteAssetComponentDialog } from "./DeleteAssetComponentDialog";
import { RenameAssetComponentDialog } from "./RenameAssetComponentDialog";

interface AssetComponentCardProps {
  title: string;
  component: AssetMachineryComponent;
}

type DialogActionsType = "editName" | "delete" | null;
const validDialogActions = ["editName", "delete"];

export const AssetComponentCard = (props: AssetComponentCardProps) => {
  const { title, component } = props;

  const [expanded, setExpanded] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("1");

  const [openDialog, setOpenDialog] = React.useState<DialogActionsType>(null);
  const actionDispatcher = useActionDispatcher();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const overflowMenuItems = useMemo(
    () => [
      <MenuItem
        key={"overflow-rename"}
        onClick={() => {
          actionDispatcher.dispatch("editName", component);
          setAnchorEl(null);
        }}
      >
        <ListItemIcon>
          <EditOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Edit name</ListItemText>
      </MenuItem>,
      <MenuItem
        key={"overflow-remove"}
        onClick={() => {
          setAnchorEl(null);
          actionDispatcher.dispatch("delete", component);
        }}
      >
        <ListItemIcon>
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete component</ListItemText>
      </MenuItem>,
    ],
    []
  );

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const handleAction = useCallback((action: string) => {
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const [basicFields, advancedFields, tabFields] = useMemo(() => {
    return [
      sortBy(
        component.assetComponentAttributes.filter((attr) => attr.componentTemplateAttribute.isBasic),
        "componentTemplateAttribute.sortOrder"
      ),
      sortBy(
        component.assetComponentAttributes.filter(
          (attr) => !attr.componentTemplateAttribute.isBasic && attr.componentTemplateAttribute.tab == null
        ),
        "componentTemplateAttribute.sortOrder"
      ),
      sortBy(
        component.assetComponentAttributes.filter((attr) => attr.componentTemplateAttribute.tab === Number(activeTab)),
        "componentTemplateAttribute.sortOrder"
      ),
    ];
  }, [component.assetComponentAttributes, activeTab]);

  return (
    <>
      <Grid item xs={12} sm={6} lg={4} xl={3}>
        <Card
          sx={{
            width: "100%",
            minHeight: "410px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          variant="outlined"
        >
          <CardContent>
            <EditableContextProvider editable={editMode}>
              <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ position: "relative" }}>
                  <Stack direction="column">
                    <Typography variant="h6" fontWeight="600" lineHeight="1">
                      {title}
                    </Typography>
                    {component.name && <Typography color={IrisColors.graySpartan}>{component.name}</Typography>}
                  </Stack>
                  {overflowMenuItems && (
                    <>
                      <IconButton
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        sx={{ position: "absolute", right: 0 }}
                      >
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {overflowMenuItems}
                      </Menu>
                    </>
                  )}
                </Stack>
                {basicFields.map((node, index) => (
                  <AssetAttributeField key={node.id} attribute={node} isEdit={editMode} />
                ))}
                {component.componentType && <ReadonlyFormItem label="Type" value={component.componentType.fullName} />}
                <Collapse in={expanded}>
                  <Stack spacing={2}>
                    {advancedFields.map((node, index) => (
                      <AssetAttributeField key={node.id} attribute={node} isEdit={editMode} />
                    ))}
                    {component.tabs && component.tabs > 0 && (
                      <Paper sx={{ padding: "5px 10px" }}>
                        <Stack spacing={2}>
                          <Stack>
                            <FormItemLabel label={component.componentType?.tabFieldName} />
                            <TabSelect value={activeTab} onChange={setActiveTab} tabs={component.tabs} />
                          </Stack>
                          {tabFields.map((node, index) => (
                            <AssetAttributeField key={node.id} attribute={node} isEdit={editMode} />
                          ))}
                        </Stack>
                      </Paper>
                    )}
                  </Stack>
                </Collapse>
              </Stack>
            </EditableContextProvider>
          </CardContent>
          <CardActions>
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              <IconButton
                onClick={() => {
                  setEditMode(!editMode);
                }}
              >
                <EditRoundedIcon sx={{ color: "primary.dark" }} />
              </IconButton>
              <IconButton onClick={() => setExpanded(!expanded)}>
                <ExpandCircleDownRoundedIcon
                  sx={{
                    color: "primary.dark",
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Stack>
          </CardActions>
        </Card>
      </Grid>
      {openDialog === "delete" && (
        <DeleteAssetComponentDialog onClose={closeDialog} component={component} onDeleteComponent={closeDialog} />
      )}
      {openDialog === "editName" && <RenameAssetComponentDialog onClose={closeDialog} assetComponent={component} />}
    </>
  );
};
