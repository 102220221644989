import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Select,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFeedbackRequest, useSubmitFeedbackWithAttachmentsRequest } from "common/api";
import { FormItemLayout } from "./forms/FormItemLayout";
import TextAreaFormItem from "./forms/Input/TextAreaFormItem";
import { useNotifications } from "app/contexts";
import { useState } from "react";
import { DeleteIcon } from "icons/DeleteIcon";
import { FeatureFlagName, useFeatureEnabled } from "common/models";

const validationSchema = yup.object({
  feedbackCategory: yup.string().nullable().required("Required"),
  content: yup.string().nullable().required("Required"),
});

interface FeedbackTypeOption {
  label: string;
  value: string | undefined;
  placeholder: string;
}

export const SubmitFeedbackDialog = (props: BaseDialogProps) => {
  const [attachedFiles, setAttachedFiles] = useState([] as File[]);
  const [submitting, setSubmitting] = useState(false);
  const { onClose } = props;
  const submitFeedbackRequest = useSubmitFeedbackRequest();
  const submitFeedbackWithAttachmentsRequest = useSubmitFeedbackWithAttachmentsRequest();
  const notifications = useNotifications();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const useFeedbackWithAttachments = useFeatureEnabled(FeatureFlagName.UseFeedbackWithAttachments);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const feedbackTypes = [
    {
      label: "Bug submission",
      value: "Bug submission",
      placeholder: "Please be as details as possible and include any error messages",
    },
    { label: "Enhancement Idea", value: "Enhancement Idea", placeholder: "Please be as details as possible" },
    { label: "General Feedback", value: "General Feedback", placeholder: "Please be as details as possible" },
    { label: "Training/Education", value: "Training/Education", placeholder: "Please be as details as possible" },
    { label: "Not sure", value: "Not sure", placeholder: "Please be as details as possible" },
  ] as FeedbackTypeOption[];

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      setSubmitting(true);

      if (useFeedbackWithAttachments) {
        const formData = new FormData();
        formData.append("feedbackCategory", values.feedbackCategory);
        formData.append("content", values.content);
        formData.append("currentUrl", window.location.href);

        attachedFiles.forEach((file) => {
          formData.append("attachments", file);
        });

        submitFeedbackWithAttachmentsRequest
          .call(formData)
          .then((res) => {
            if (res !== undefined) {
              notifications.success(`Your feedback has been sent.`);
            }
            onClose();
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        submitFeedbackRequest
          .call({
            feedbackCategory: values.feedbackCategory,
            content: values.content,
            currentUrl: window.location.href,
          })
          .then((res) => {
            if (res !== undefined) {
              notifications.success(`Your feedback has been sent.`);
            }
            onClose();
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    })();
  };

  const getPlaceholder = (feedbackCategory: string) => {
    const feedbackType = feedbackTypes.find((f) => f.value === feedbackCategory);
    return feedbackType?.placeholder;
  };

  const addAttachments = (event) => {
    let attachmentsToUpdate = [...attachedFiles];

    for (const file of event.target.files) {
      const fileIndex = attachmentsToUpdate.findIndex((f) => f.name === file.name);
      if (fileIndex !== -1) {
        //replacing the file
        attachmentsToUpdate.splice(fileIndex, 1, file);
      } else {
        //adding the file
        attachmentsToUpdate.push(file);
      }

      setAttachedFiles(attachmentsToUpdate);
    }
  };

  const deleteAttachment = (fileName) => {
    let attachmentsToUpdate = [...attachedFiles];

    const fileIndex = attachmentsToUpdate.findIndex((f) => f.name === fileName);
    if (fileIndex !== -1) {
      attachmentsToUpdate.splice(fileIndex, 1);
    }

    setAttachedFiles(attachmentsToUpdate);
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogTitle>Submit Feedback</DialogTitle>
      <DialogContent dividers sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={3}>
            <FormItemLayout fieldName="feedbackCategory" label="What type of feedback are you submitting?">
              {(inputProps) => (
                <Select fullWidth size="small" {...inputProps} displayEmpty>
                  <MenuItem value={undefined}>
                    <em>- Select -</em>
                  </MenuItem>
                  {feedbackTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormItemLayout>
            <TextAreaFormItem
              minRows={5}
              inputProps={{ maxLength: 1500 }}
              label="Describe your experience or issue that you are encountering"
              fieldName="content"
              placeholder={getPlaceholder(methods.watch("feedbackCategory"))}
              readOnly={!methods.watch("feedbackCategory")}
            />
            {useFeedbackWithAttachments && (
              <Stack spacing={2}>
                <Stack spacing={0}>
                  {attachedFiles.map((file) => (
                    <Typography key={file.name} color="secondary">
                      {file.name}{" "}
                      <IconButton aria-label="delete" onClick={() => deleteAttachment(file.name)}>
                        <DeleteIcon />
                      </IconButton>
                    </Typography>
                  ))}
                </Stack>
                <div>
                  <Button component="label" variant="outlined" color="secondary">
                    Add Attachments
                    <VisuallyHiddenInput type="file" multiple accept="image/*, video/*" onChange={addAttachments} />
                  </Button>
                </div>
              </Stack>
            )}
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onSubmit} disabled={submitting}>
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
