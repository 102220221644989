import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { KpiOneTimeLabourOrEquipmentCostSavings } from "kpis/models";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

interface SelectedApproachFormItemProps extends Omit<BaseFormItemProps, "fieldName"> {
  multiplierFieldName: string;
  multiplyEquipmentFieldName: string;
  multiplyLabourFieldName: string;
  readOnly?: boolean;
}

function SelectedApproachFormItem(props: SelectedApproachFormItemProps) {
  const {
    label = "Selected Approach",
    readOnly,
    multiplierFieldName,
    multiplyEquipmentFieldName,
    multiplyLabourFieldName,
  } = props;

  const methods = useFormContext();

  const multiplier = methods.watch(multiplierFieldName);
  const multiplyEquipment = methods.watch(multiplyEquipmentFieldName);
  const multiplyLabour = methods.watch(multiplyLabourFieldName);

  const handleSelectedApproachChange = (value: string | null) => {
    const { multiplier, multiplyLabour, multiplyEquipment } = deserializeMultipliers(value);
    methods.setValue(multiplierFieldName, multiplier, { shouldDirty: true });
    methods.setValue(multiplyEquipmentFieldName, multiplyEquipment, { shouldDirty: true });
    methods.setValue(multiplyLabourFieldName, multiplyLabour, { shouldDirty: true });
  };

  const serializeMultipliers = (fields: Partial<KpiOneTimeLabourOrEquipmentCostSavings>): string | null => {
    if (fields.multiplier === undefined) {
      return "";
    }
    if (fields.multiplier === null || fields.multiplyEquipment === null || fields.multiplyLabour === null) {
      return null;
    }
    return `${fields.multiplier}_${!!fields.multiplyLabour ? 1 : 0}_${!!fields.multiplyEquipment ? 1 : 0}`;
  };

  const deserializeMultipliers = (
    option: string | null
  ): Pick<KpiOneTimeLabourOrEquipmentCostSavings, "multiplier" | "multiplyLabour" | "multiplyEquipment"> => {
    const split = (option ?? "").split("_") as string[];
    if (split.length !== 3) {
      return { multiplier: 0, multiplyLabour: false, multiplyEquipment: false };
    }

    const multiplier = parseInt(split[0]);
    const multiplyLabour = !!parseInt(split[1]);
    const multiplyEquipment = !!parseInt(split[2]);

    return { multiplier, multiplyLabour, multiplyEquipment };
  };

  const serializedValue = useMemo(
    () => serializeMultipliers({ multiplier, multiplyLabour, multiplyEquipment }),
    [multiplier, multiplyLabour, multiplyEquipment]
  );

  const kpiApproachOptions = [
    {
      label: "Custom amount",
      value: serializeMultipliers({ multiplier: 0, multiplyLabour: false, multiplyEquipment: false }),
    },
  ]
    .concat(
      [2, 3, 4, 5].map((i) => ({
        label: `${i}x labour`,
        value: serializeMultipliers({ multiplier: i, multiplyLabour: true, multiplyEquipment: false }),
      }))
    )
    .concat(
      [2, 3, 4, 5].map((i) => ({
        label: `${i}x labour + equipment`,
        value: serializeMultipliers({ multiplier: i, multiplyLabour: true, multiplyEquipment: true }),
      }))
    );

  return (
    <FormItemLayout
      controlled
      hint={props.hint}
      label={label}
      error={methods.formState.errors[multiplierFieldName]?.message?.toString()}
      formControlProps={{ disabled: readOnly }}
    >
      {(inputProps) => (
        <Select
          fullWidth
          size="small"
          onChange={(e: SelectChangeEvent) => {
            handleSelectedApproachChange(e.target.value as string);
          }}
          value={serializedValue ?? ""}
          placeholder="Select approach"
          {...inputProps}
        >
          {kpiApproachOptions
            .filter((o) => !!o.value)
            .map((option, i) => (
              <MenuItem key={i} value={option.value as string}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      )}
    </FormItemLayout>
  );
}

export default SelectedApproachFormItem;
