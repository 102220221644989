import { useBaseAxiosGetRequest } from "app";
import { User } from "./models";

export const useGetUsersInSiteRequest = (siteId: number | null) =>
  useBaseAxiosGetRequest<User[]>(!!siteId ? `/api/users/inSite/${siteId}` : null, {
    useNode: true,
  });

export const useGetAllUsersRequest = (fresh?: boolean) =>
  useBaseAxiosGetRequest<User[]>(`/api/users/all${!!fresh ? "?fresh=true" : ""}`, {
    useNode: true,
  });
