import React, { FunctionComponent, useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { FormGroup, Label, Input, ModalFooter, Button } from "reactstrap";
import { useSiteIdFromRoute, useNotifications } from "app";
import moment from "moment";
import classNames from "classnames";
import { RecordsEventDigest } from "../models";
import { useCreateRecordsEventRequest } from "../api";
import { dateFormat, getOverallValidity, getValidity } from "common";

interface Props {
  close: () => void;
  createdRecordsEvent: (recordsEvent: RecordsEventDigest, viewRecordsEvent: boolean) => void;
}

export const CreateRecordsEventModal: FunctionComponent<Props> = (props) => {
  const notifications = useNotifications();
  const siteId = useSiteIdFromRoute()!;

  const createRecordsEventRequest = useCreateRecordsEventRequest();

  const [name, setName] = useState("");
  const nameValidity = getValidity(!!name.trim());

  const [startDateValue, setStartDateValue] = useState(moment().toDate());
  const startDateValidity = getValidity(!!startDateValue && moment(startDateValue).isValid());

  const [endDateValue, setEndDateValue] = useState<Date | null>(null);
  const endDateValidity = getValidity(
    !endDateValue || (moment(endDateValue).isValid() && moment(endDateValue).isAfter(startDateValue))
  );

  const formValidity = getOverallValidity([nameValidity, startDateValidity, endDateValidity]);

  const createRecordsEvent = async (openNewRecordsEvent: boolean) => {
    if (!formValidity.valid) return;

    await createRecordsEventRequest
      .call({
        siteId,
        name,
        startDate: startDateValue,
        endDate: endDateValue,
      })
      .then((newRecordsEvent) => {
        if (!!newRecordsEvent) {
          notifications.success(`Created ${newRecordsEvent.name}`);
          props.createdRecordsEvent(newRecordsEvent, openNewRecordsEvent);
        }
      });
  };

  return (
    <Modal className="create-summary-modal" isOpen={true} toggle={props.close} container={"#bootstrap-modal-container"}>
      <ModalHeader>Create event</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Name</Label>
          <Input value={name} onChange={(e) => setName(e.target.value)} required={true} />
          <div className="invalid-feedback">Enter an event name.</div>
        </FormGroup>

        <FormGroup>
          <Label>Start date</Label>
          <Input
            className={classNames({
              "dim-date": !startDateValue,
              "is-invalid": !startDateValidity.valid,
            })}
            type="date"
            value={!startDateValue ? "" : moment(startDateValue).format(dateFormat)}
            onChange={(e) => setStartDateValue(moment(e.target.value).toDate())}
          />
          <div className="invalid-feedback">Enter a start date.</div>
        </FormGroup>
        <FormGroup>
          <Label>End date</Label>
          <Input
            className={classNames({
              "dim-date": !endDateValue,
              "is-invalid": !endDateValidity.valid,
            })}
            type="date"
            value={!endDateValue ? "" : moment(endDateValue).format(dateFormat)}
            onChange={(e) => setEndDateValue(moment(e.target.value).toDate())}
          />
          <div className="invalid-feedback">End date must be equal to or later than the start date</div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={async () => createRecordsEvent(true)}
          disabled={!formValidity.valid || createRecordsEventRequest.loading}
        >
          Create event and open
        </Button>
        <Button
          color="secondary"
          onClick={async () => createRecordsEvent(false)}
          disabled={!formValidity.valid || createRecordsEventRequest.loading}
        >
          Create
        </Button>
        <Button color="secondary" onClick={props.close} disabled={createRecordsEventRequest.loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
