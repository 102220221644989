import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { Asset, MeasurementSpec } from "assets/models/asset";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import AutoSave from "app/mui/forms/AutoSave";
import { useUpdateMeasurementSpecificationRequest } from "assets/api";

interface MeasurementSpecificationCardProps {
  asset: Asset;
  measurement: MeasurementSpec;
}

export const MeasurementSpecificationCard = (props: MeasurementSpecificationCardProps) => {
  const updateSpecificationRequest = useUpdateMeasurementSpecificationRequest();

  const defaultValues = makeSafeForReactHookForm(props.measurement);
  const methods = useForm({ defaultValues });

  const updateMeasurement = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <SpecOptionLoader type="MeasurementType">
      {(options) => <EditableAutocompleteFormItem label="Type" fieldName="type" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="Technology" fieldName="technology" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <EditableTextFieldFormItem label="Emission Vent ID" fieldName="ventId" />,
  ];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave onSubmit={updateMeasurement} />
      <AssetSpecificationCard title="Measurement" specs={fields} />
    </PermissionAwareFormProvider>
  );
};
