import _ from "lodash";
import { RecordDetailView, RecordDigest, RecordType } from "records";
import { KpiBase, KpiBaseFields } from "./KpiBase";
import {
  KpiOneTimeLabourOrEquipmentCostSavings,
  KpiOneTimeLabourOrEquipmentCostSavingsFields,
} from "./KpiOneTimeLabourOrEquipmentCostSavings";
import { KpiSignoff } from "./KpiSignoff";
import { TemplateKpiType, ValueKpiType } from "./KpiType";
import { KpiOneTimeDowntimeAvoidance, KpiOneTimeDowntimeAvoidanceFields } from ".";
import {
  KpiOneTimeReleaseOrSpillAvoidance,
  KpiOneTimeReleaseOrSpillAvoidanceFields,
} from "./KpiOneTimeReleaseOrSpillAvoidance";
import {
  KpiOneTimeScheduleDelayReduction,
  KpiOneTimeScheduleDelayReductionFields,
} from "./KpiOneTimeScheduleDelayReduction";
import { KpiOngoingEmissionsReduction, KpiOngoingEmissionsReductionFields } from "./KpiOngoingEmissionsReduction";
import {
  KpiOngoingMaintenanceSavingsOrProductionImprovement,
  KpiOngoingMaintenanceSavingsOrProductionImprovementFields,
} from "./KpiOngoingMaintenanceSavingsOrProductionImprovement";

export type TemplateOrValue = "Template" | "Value";

export type KpiEmissionsType = "Carbon Dioxide (CO2)" | "Methane (CH4)";
export const KpiEmissionsTypes: KpiEmissionsType[] = ["Carbon Dioxide (CO2)", "Methane (CH4)"];

export type KpiRemoteHoursType = "All" | "None" | "Some";
export const KpiRemoteHoursTypes: KpiRemoteHoursType[] = ["All", "None", "Some"];

export interface KpiId {
  templateOrValue: TemplateOrValue;
  kpiType: ValueKpiType;
  rowId: number;
}

export const kpiIdToString = (id: KpiId) => {
  return `${id.kpiType}${id.templateOrValue}_${id.rowId}`;
};

export const parseKpiId = (value: string): KpiId => {
  var split = value.split("_");
  var rowId = parseInt(split[1]);
  if (split[0].endsWith("Template"))
    return {
      templateOrValue: "Template",
      kpiType: split[0].substring(0, split[0].length - 8) as ValueKpiType,
      rowId,
    };
  else return { templateOrValue: "Value", kpiType: split[0].substring(0, split[0].length - 5) as ValueKpiType, rowId };
};

export type KpiTemplateStatusOption = "All" | "Active" | "Archived";
export const KpiTemplateStatusOptions: KpiTemplateStatusOption[] = ["All", "Active", "Archived"];

export class KpiClientTemplateFilters {
  public enabled: KpiTemplateStatusOption = "All";
  public kpiTypes: TemplateKpiType[] = [];
  public nameFragment: string = "";
  public recordTypes: RecordType[] = [];
  public withValuesOnly: boolean = false;
}

export class KpiTemplateFilters {
  public enabled: boolean | null = null;
  public ids: string[] = [];
  public kpiTypes: TemplateKpiType[] = [];
  public siteId: number | null = null;
  public withValuesOnly: boolean = false;
}

export interface KpiValueQbr {
  qbrNumber: string | null;
  qbrDate: Date | null;
}

export interface KpiValueCommon extends KpiBase, KpiValueQbr, KpiSignoff {
  recordId: number;
  siteId: number;
  kpiTemplateId: string | null;
  name: string | null;
  comments: string | null;
}

const kpiValueCommonFields: (keyof KpiValueCommon)[] = [
  ...KpiBaseFields,
  "recordId",
  "siteId",
  "kpiTemplateId",
  "name",
  "comments",
  "qbrNumber",
  "qbrDate",
];

export interface KpiCalculatedFields {
  created: Date;
  calculatedSavings: number;
  calculatedSavingsUnit: string | null;
  calculatedReturnOnInvestment: string;
}

export interface KpiTemplateDigest extends KpiTemplateCommon, KpiCalculatedFields {}
export interface KpiValueDigest extends KpiValueCommon, KpiCalculatedFields {}

export type CreateKpiValueWithComments = Omit<
  KpiValueCommon,
  | "rowId"
  | "createdOn"
  | "calculatedSavings"
  | "calculatedSavingsUnit"
  | "calculatedReturnOnInvestment"
  | "name"
  | "qbrNumber"
  | "qbrDate"
  | "templateComments"
  | "customerSignOff"
  | "signOffDate"
  | "signedOff"
>;

export interface KpiTemplateCommon extends Omit<KpiBase, "kpiType">, KpiSignoff {
  siteId: number;
  name: string;
  enabled: boolean;
  templateComments: string | null;
  kpiType: TemplateKpiType;
}
const kpiTemplateCommonFields: (keyof KpiTemplateCommon)[] = [...KpiBaseFields, "siteId", "name", "enabled"];

export interface KpiTemplateRecordTypeUsage {
  kpiType: TemplateKpiType;
  templateId: number;
  recordType: RecordType;
  numberOfRecords: number;
  lastAssignedOn: string;
}

export interface KpiLegacy extends KpiBase {
  // includeStartUpDelay
  potentialStartUpDelay: number | null;
  startUpDelayProbability: number | null;
  actualStartUpDelay: number | null;
  startUpDelayAvoided: number | null;

  // includeProductionRisk || includeProactiveOpportunity
  potentialProductionRisk: number | null;
  // includeProductionRisk
  potentialProductionRiskProbability: number | null;
  plausibleProductionRisk: number | null;
  actualProductionRisk: number | null;
  productionRiskAvoided: number | null;

  // includeMaintenanceCost || includeProactiveOpportunity
  potentialMaintenanceCost: number | null;
  // includeMaintenanceCost
  actualMaintenanceCost: number | null;
  maintenanceCostReduction: number | null;

  // includeProactiveOpportunity
  currentProductionImpact: number | null;
  productionImprovementOpportunity: number | null;
  currentMaintenanceImpact: number | null;
  maintenanceCostReductionOpportunity: number | null;
  totalOpportunity: number | null;
  costToImplement: number | null;
  timeToReturnInvestment: number | null;

  // *
  incidentAvoidance: boolean;
  fieldWorkReduction: boolean;
  environmental: boolean;
  regulatory: boolean;
}
const kpiLegacyFields: (keyof KpiLegacy)[] = [
  ...KpiBaseFields,
  "potentialProductionRisk",
  "potentialProductionRiskProbability",
  "plausibleProductionRisk",
  "actualProductionRisk",
  "productionRiskAvoided",
  "actualMaintenanceCost",
  "potentialMaintenanceCost",
  "maintenanceCostReduction",
  "currentProductionImpact",
  "productionImprovementOpportunity",
  "currentMaintenanceImpact",
  "maintenanceCostReductionOpportunity",
  "totalOpportunity",
  "costToImplement",
  "timeToReturnInvestment",
  "potentialStartUpDelay",
  "startUpDelayProbability",
  "actualStartUpDelay",
  "startUpDelayAvoided",
  "incidentAvoidance",
  "fieldWorkReduction",
  "environmental",
  "regulatory",
];

export type KpiFields =
  | KpiLegacy
  | KpiOneTimeDowntimeAvoidance
  | KpiOneTimeLabourOrEquipmentCostSavings
  | KpiOneTimeReleaseOrSpillAvoidance
  | KpiOneTimeScheduleDelayReduction
  | KpiOngoingEmissionsReduction
  | KpiOngoingMaintenanceSavingsOrProductionImprovement;

export type KpiTemplateModel = KpiFields & KpiTemplateCommon;
export type KpiValueModel = KpiFields & KpiValueCommon;
export type KpiModel = KpiFields & (KpiTemplateModel | KpiValueModel);

export interface KpiCommonDetail extends KpiCalculatedFields {
  id: string;
  kpiType: TemplateKpiType | ValueKpiType;
  created: Date;
  calculatedSavings: number;
  calculatedSavingsUnit: string | null;
  calculatedReturnOnInvestment: string;
}

export interface KpiTemplateDetail extends KpiCommonDetail {
  siteId: number;
  kpiType: TemplateKpiType;
  model: KpiTemplateModel;
  kpiValues: KpiValueDigest[];
  recordTypeUsages: KpiTemplateRecordTypeUsage[];
}

export interface KpiValueDetail extends KpiCommonDetail {
  kpiType: ValueKpiType;
  model: KpiValueModel;
  record: RecordDigest;
}

export interface KpiValueWithRecordDetailViewModel {
  kpi: KpiValueDetail;
  record: RecordDetailView;
}

export type KpiTemplateOrValueDetail = KpiTemplateDetail | KpiValueDetail;

export type KpiFieldNames =
  | keyof KpiLegacy
  | keyof KpiOneTimeDowntimeAvoidance
  | keyof KpiOneTimeLabourOrEquipmentCostSavings
  | keyof KpiOneTimeReleaseOrSpillAvoidance
  | keyof KpiOneTimeScheduleDelayReduction
  | keyof KpiOngoingEmissionsReduction
  | keyof KpiOngoingMaintenanceSavingsOrProductionImprovement;
export type KpiValueFieldNames = keyof KpiValueCommon | KpiFieldNames;
export type KpiTemplateFieldNames = keyof KpiTemplateCommon | KpiFieldNames;

export const getFieldsForKpiTypes = (kpiTypes: ValueKpiType[], templateOrValue: TemplateOrValue): string[] => {
  return kpiTypes.length === 0
    ? getFieldsForKpiType("None", templateOrValue)
    : _.uniq(_.flatMap(_.uniq(kpiTypes), (kpiType) => getFieldsForKpiType(kpiType, templateOrValue)));
};

export const getFieldsForKpiType = (kpiType: ValueKpiType, templateOrValue: TemplateOrValue): string[] => {
  const baseColumns: string[] = templateOrValue === "Template" ? kpiTemplateCommonFields : kpiValueCommonFields;
  switch (kpiType) {
    case "None":
      return baseColumns;
    case "Legacy":
      return baseColumns.concat(kpiLegacyFields);
    case "OneTimeDowntimeAvoidance":
      return baseColumns.concat(KpiOneTimeDowntimeAvoidanceFields);
    case "OneTimeLabourOrEquipmentCostSavings":
      return baseColumns.concat(KpiOneTimeLabourOrEquipmentCostSavingsFields);
    case "OneTimeReleaseOrSpillAvoidance":
      return baseColumns.concat(KpiOneTimeReleaseOrSpillAvoidanceFields);
    case "OneTimeScheduleDelayReduction":
      return baseColumns.concat(KpiOneTimeScheduleDelayReductionFields);
    case "OngoingEmissionsReduction":
      return baseColumns.concat(KpiOngoingEmissionsReductionFields);
    case "OngoingMaintenanceSavingsOrProductionImprovement":
      return baseColumns.concat(KpiOngoingMaintenanceSavingsOrProductionImprovementFields);
  }
};

export interface KpiValueFilters {
  assetId?: number;
  hasQbr?: boolean;
  ids?: string;
  kpiTypes?: ValueKpiType[];
  qbrNumbers?: string[];
  recordIds?: number[];
  signedOff?: boolean;
  siteId?: number;
  templateIds?: string[];
}

export type KpiValueTotalSavingsType =
  | "DelayAvoided"
  | "DowntimeAvoided"
  | "OngoingEmissionsReductions"
  | "OngoingMonetary"
  | "OneTimeMonetary"
  | "OneTimeReleaseOrSpillAvoidances";

export interface KpiValueTotal {
  total: string;
  unit: string;
  blurb: string;
  numericValue: number;
}

export interface KpiValueTotals {
  savings: KpiValueTotalSavingsType[];
  oneTimeMonetary: KpiValueTotal | null;
  ongoingMonetary: KpiValueTotal | null;
  delayAvoided: KpiValueTotal | null;
  downtimeAvoided: KpiValueTotal | null;
  oneTimeReleaseOrSpillAvoidances: KpiValueTotal[];
  ongoingEmissionsReductions: KpiValueTotal[];
}

export const flatMapKpiValueTotals = (kpiValueTotals: KpiValueTotals) => {
  return [
    kpiValueTotals.oneTimeMonetary,
    kpiValueTotals.ongoingMonetary,
    kpiValueTotals.delayAvoided,
    kpiValueTotals.delayAvoided,
    kpiValueTotals.downtimeAvoided,
    ...(kpiValueTotals.oneTimeReleaseOrSpillAvoidances ?? []),
    ...kpiValueTotals.ongoingEmissionsReductions,
  ].filter((t) => !!t) as KpiValueTotal[];
};

export const flatMapKpiValueMonetaryTotals = (kpiValueTotals: KpiValueTotals) => {
  return [kpiValueTotals.oneTimeMonetary, kpiValueTotals.ongoingMonetary].filter((t) => !!t) as KpiValueTotal[];
};
