import React from "react";
import { Box, Fade, Portal, Stack, styled, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IrisColors } from "app/mui/theme";

const DropOverlay = styled(Box)({
   position: "fixed",
   top: 0,
   left: 0,
   width: "100%",
   height: "100%",
   background: "rgba(255, 255, 255, 0.7)",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   zIndex: 100000,
   border: `solid 6px ${IrisColors.blueSpartan}`,
   boxSizing: "border-box",
});

interface DragAndDropPaneProps {
   handleFilesDropped: (files: FileList) => void;
}

const DragAndDropPane = (props: DragAndDropPaneProps) => {
   const dragCountRef = React.useRef(0);
   const [isDragging, setIsDragging] = React.useState(false);

   const setupListeners = (element: HTMLElement | null) => {
      element?.addEventListener("dragenter", handleDragIn as any); // as any becuase typescript is being dumb.
      element?.addEventListener("dragleave", handleDragOut as any);
      element?.addEventListener("dragover", handleDrag as any);
      element?.addEventListener("drop", handleDrop as any);
   };

   const removeListenersCleanupTask = (element: HTMLElement | null) => {
      element?.removeEventListener("dragenter", handleDragIn as any); // as any because typescript is being dumb.
      element?.removeEventListener("dragleave", handleDragOut as any);
      element?.removeEventListener("dragover", handleDrag as any);
      element?.removeEventListener("drop", handleDrop as any);
   };

   React.useEffect(() => {
      const body = document.body;
      setupListeners(body);
      return () => removeListenersCleanupTask(body);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleDrag = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
   };

   const handleDragIn = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCountRef.current += 1;

      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
         setIsDragging(true);
      }
   };

   const handleDragOut = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      dragCountRef.current -= 1;

      if (dragCountRef.current === 0) {
         setIsDragging(false);
      }
   };

   const handleDrop = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
         props.handleFilesDropped(e.dataTransfer.files);
         dragCountRef.current = 0;
      }
   };

   return (
      <>
         <Portal>
            <Fade in={isDragging}>
               <DropOverlay>
                  <Stack spacing={2} justifyContent="center" alignItems="center">
                     <Typography variant="h5">Drop files to upload</Typography>
                     <UploadFileIcon fontSize="large" />
                  </Stack>
               </DropOverlay>
            </Fade>
         </Portal>
      </>
   );
};

export default DragAndDropPane;
