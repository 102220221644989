import { KpiTypeIcon } from "kpis/KpiTypeIcon";
import * as React from "react";
import { TemplateKpiType, toKpiTypeDisplayName } from "./models";
import { Badge, BadgeProps } from "app/mui/Badge";

interface KpiTypeBadgeProps extends Omit<BadgeProps, "text"> {
  kpiType: TemplateKpiType;
}

export class KpiTypeBadge extends React.PureComponent<KpiTypeBadgeProps> {
  public render() {
    const { kpiType, ...badgeProps } = this.props;
    return <Badge icon={<KpiTypeIcon kpiType={kpiType} />} text={toKpiTypeDisplayName(kpiType)} {...badgeProps} />;
  }
}
