import React from "react";
import { Box, Paper, Tabs, Tab } from "@mui/material";

import { TabPanel } from "app/mui/tabs/TabPanel";
import { RecordDetailView, RecordType } from "records/models";
import { AttachmentsArea } from "attachments/mui/AttachmentsArea";
import RecordHistory from "../history/RecordHistory";

interface RecordAttachmentsAndHistoryProps {
  recordDetail: RecordDetailView;
}

export const RecordAttachmentsAndHistory = (props: RecordAttachmentsAndHistoryProps) => {
  const { recordDetail } = props;
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label="Attachments" />
          <Tab label="History" />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
        <AttachmentsArea
          parentType="Record"
          parentId={recordDetail.id}
          parentTag={recordDetail.model.description}
          canCopyAssemblyImage={recordDetail.model.recordType === RecordType.FieldInspection}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1} sx={{ p: 2 }}>
        <RecordHistory recordId={recordDetail.id} />
      </TabPanel>
    </Paper>
  );
};
