import * as React from "react";
import { Label } from "reactstrap";
import { CheckboxCheckedIcon } from "icons/CheckboxCheckedIcon ";
import { CheckboxEmptyIcon } from "icons/CheckboxEmptyIcon";
import classNames from "classnames";
import { ReactNode } from "react";

export interface Props {
   id?: string;
   onClick: () => void;
   value: boolean;
   disabled?: boolean;
   readOnly?: boolean;
   children?: ReactNode;
}

export class Checkbox extends React.Component<Props> {
   public render() {
      const { disabled, readOnly } = this.props;

      return (
         <div id={this.props.id} className={classNames("checkbox", { disabled: disabled })}>
            <span onClick={disabled || readOnly ? undefined : this.props.onClick}>
               {this.props.value ? <CheckboxCheckedIcon /> : <CheckboxEmptyIcon />}
            </span>
            <Label>{this.props.children}</Label>
         </div>
      );
   }
}
