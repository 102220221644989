import { FunctionComponent, useEffect, useState } from "react";
import { Row, Col, Card, FormGroup, Label, Button, Form, Input, Badge, CardTitle } from "reactstrap";
import { useAutosaver, useNotifications, useSiteIdFromRoute, useUserContext } from "app";
import { RTEditor, Avatar, getValidity, dateFormat, useFeatureEnabled, FeatureFlagName } from "common";
import { RecordDetailView, useGetRecordDetailsByRecordsEventQuery, usePostRecordsByIdQuery } from "records";
import RichTextEditor from "react-rte";
import { DeleteIcon } from "icons/DeleteIcon";
import { formatRoutePath, Routes } from "app";
import classNames from "classnames";
import Textarea from "react-textarea-autosize";
import { ConfirmationModal } from "common/ConfirmationModal";
import moment from "moment";
import Select, { SingleValue } from "react-select";
import { SummaryRecordSelector } from "./SummaryRecordSelector";
import { ConfigureGeneralSummaryReportModal } from "./ConfigureGeneralSummaryReportModal";
import { SelectedKpiValues } from "common/SelectedKpiValues";
import { ConfigureKPISummaryReportModal } from "./ConfigureKPISummaryReportModal";
import { RecordSelector, SelectedRecordListGroupedByAsset } from "common/RecordSelector";
import { Link, useNavigate } from "react-router-dom";
import { ConfigureOutageScopeSummaryReportModal } from "./ConfigureOutageScopeSummaryReportModal";
import { AttachmentsArea } from "attachments/mui/AttachmentsArea";
import { Summary, SummaryDetail, SummaryStatus, SummaryType } from "summaries/models";
import { useDeleteSummaryRequest, useUpdateSummaryRequest } from "summaries/api";
import { ConfigureBulkValveHealthReportModal } from "./ConfigureBulkValveHealthReportModal";
import { ConfigureBulkValveHealthReportModal as ConfigureBulkValveHealthNodeReportModal } from "../mui/ConfigureBulkValveHealthReportModal";
import { MultiUserSelect } from "users/MultiUserSelect";
import { EditHeader } from "./editHeader";
import { AssetSelector } from "common/AssetSelector";
import { recordBeforeSummaryDate } from "summaries/utilities";
import { SummaryIcon } from "icons/SummaryIcon";
import { ConfigureFieldInspectionSummaryReportModal } from "./ConfigureFieldInspectionSummaryReportModal";

interface Props {
  summary: SummaryDetail;
}

export const SummaryScreen: FunctionComponent<Props> = (props) => {
  const { userCanEditSummary, userCanGenerateSummaryReport, userCanViewRecord, userCanReopenRecord } =
    useUserContext().userPermissions;
  const notifications = useNotifications();
  const navigate = useNavigate();
  const siteId = useSiteIdFromRoute()!;

  const useNodeReport = useFeatureEnabled(FeatureFlagName.UseNodeBulkValveHealthReport);

  const updateSummaryRequest = useUpdateSummaryRequest();
  const postRecordsByIdQuery = usePostRecordsByIdQuery();
  const [summaryRecordDetails, setSummaryRecordDetails] = useState<RecordDetailView[]>([]);

  const [summary, saveSummary] = useAutosaver(props.summary.model, updateSummaryRequest.call);
  const saveChange = (changes: Partial<Summary>, now?: boolean) => saveSummary({ ...summary, ...changes }, now);
  const deleteSummaryRequest = useDeleteSummaryRequest();

  const [configureReportModalOpen, setConfigureReportModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const isClosed = props.summary.model.status === SummaryStatus.Closed;
  const isBulkValveHealthSummary = summary.summaryType === SummaryType.BulkValveHealthType;
  const isGeneralSummary = summary.summaryType === SummaryType.General;
  const isKpiSummary = summary.summaryType === SummaryType.KPI;
  const isOutageScopeSummary = summary.summaryType === SummaryType.OutageScope;
  const isFieldInspectionSummary = summary.summaryType === SummaryType.FieldInspection;

  const getRecordsEventRecordsRequest = useGetRecordDetailsByRecordsEventQuery(summary.recordsEventId);
  const recordsEventRecords = getRecordsEventRecordsRequest.data ?? [];

  useEffect(() => {
    postRecordsByIdQuery.call(summary.records.map((r) => r.id)).then((records) => {
      setSummaryRecordDetails(records ?? []);
    });
  }, [summary.records]);

  const [challengesValue, setChallengesValue] = useState(
    RichTextEditor.createValueFromString(props.summary.model.challenges || "", "html")
  );
  const [generalRecommendationsValue, setGeneralRecommendationsValue] = useState(
    RichTextEditor.createValueFromString(props.summary.model.generalRecommendations || "", "html")
  );
  const [highlightsValue, setHighlightsValue] = useState(
    RichTextEditor.createValueFromString(props.summary.model.highlights || "", "html")
  );
  const [pathForwardValue, setPathForwardValue] = useState(
    RichTextEditor.createValueFromString(props.summary.model.pathForward || "", "html")
  );

  const titleValidity = getValidity(!!summary.title.trim());
  const summaryDateValidity = getValidity(moment(summary.summaryDate).isValid());

  return (
    <div className="summary-screen">
      <title>SpartanPRO IRIS - {summary.title}</title>
      <Row className="title-card-header">
        <Col md={8} lg={9}>
          <Card body className="summary-screen-title">
            <div className="screen-title">
              <div className="icon-and-title">
                <SummaryIcon sx={{ fontSize: 42 }} />
                <Form className="hover-borders">
                  <Textarea
                    className={classNames("form-control", {
                      "is-invalid": !titleValidity.valid,
                    })}
                    value={summary.title}
                    onChange={(e) => saveChange({ title: e.target.value })}
                    readOnly={!userCanEditSummary || isClosed}
                  />
                  <div className="invalid-feedback">{titleValidity.message}</div>
                </Form>
              </div>
            </div>
            <div className="subtitle d-flex align-items-center mt-2">
              <Badge className="type" color="dark">
                {summary.summaryType}
              </Badge>
            </div>
          </Card>
        </Col>

        <Col md={4} lg={3} className="d-flex justify-content-end align-items-start">
          <div className="screen-commands">
            {userCanGenerateSummaryReport && (
              <>
                <Button color="secondary" size="sm" onClick={() => setConfigureReportModalOpen(true)}>
                  Generate report
                </Button>
                {isBulkValveHealthSummary &&
                  configureReportModalOpen &&
                  (useNodeReport ? (
                    <ConfigureBulkValveHealthNodeReportModal
                      summary={summary}
                      summaryRecordDetails={summaryRecordDetails}
                      onClose={() => setConfigureReportModalOpen(false)}
                    />
                  ) : (
                    <ConfigureBulkValveHealthReportModal
                      summary={summary}
                      summaryRecordDetails={summaryRecordDetails}
                      close={() => setConfigureReportModalOpen(false)}
                    />
                  ))}
                {isGeneralSummary && configureReportModalOpen && (
                  <ConfigureGeneralSummaryReportModal
                    summary={summary}
                    close={() => setConfigureReportModalOpen(false)}
                  />
                )}
                {isKpiSummary && configureReportModalOpen && (
                  <ConfigureKPISummaryReportModal summary={summary} close={() => setConfigureReportModalOpen(false)} />
                )}
                {isOutageScopeSummary && configureReportModalOpen && (
                  <ConfigureOutageScopeSummaryReportModal
                    summary={props.summary}
                    close={() => setConfigureReportModalOpen(false)}
                  />
                )}
                {isFieldInspectionSummary && configureReportModalOpen && (
                  <ConfigureFieldInspectionSummaryReportModal
                    summary={summary}
                    close={() => setConfigureReportModalOpen(false)}
                  />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={9}>
          <Card body>
            <div className="section">
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Date</Label>
                      {!userCanEditSummary || isClosed ? (
                        <Input
                          plaintext
                          readOnly
                          value={summary.summaryDate === null ? "" : moment(summary.summaryDate).format(dateFormat)}
                        />
                      ) : (
                        <Input
                          className={classNames("form-control", {
                            "dim-date": summary.summaryDate === null,
                            "is-invalid": !summaryDateValidity.valid,
                          })}
                          type="date"
                          value={summary.summaryDate === null ? "" : moment(summary.summaryDate).format(dateFormat)}
                          onChange={(e) =>
                            saveChange(
                              {
                                summaryDate: moment(e.target.value).toDate(),
                              },
                              true
                            )
                          }
                          readOnly={!userCanEditSummary}
                        />
                      )}
                      <div className="invalid-feedback">Summary must have a summary date.</div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>Status</Label>
                      {!userCanEditSummary || (isClosed && !userCanReopenRecord) ? (
                        <Input plaintext tag="p">
                          {summary.status}
                        </Input>
                      ) : (
                        <Select
                          value={{ value: summary.status }}
                          onChange={(newStatus: SingleValue<{ value: SummaryStatus }>) => {
                            saveChange(
                              {
                                status: (
                                  newStatus as {
                                    value: SummaryStatus;
                                  }
                                ).value,
                              },
                              true
                            );
                          }}
                          getOptionLabel={(option) => option.value}
                          getOptionValue={(status) => status.value}
                          options={[{ value: SummaryStatus.Open }, { value: SummaryStatus.Closed }]}
                          className="react-select hide-cursor"
                          classNamePrefix="react-select"
                          isDisabled={!userCanEditSummary || (isClosed && !userCanReopenRecord)}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6} />
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>Created by</Label>
                      <Input plaintext tag="p" className="avatar-and-name">
                        <Avatar user={props.summary.createdBy} />
                        {props.summary.createdBy.fullName}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>Closed by</Label>
                      {props.summary.closedBy && (
                        <Input plaintext tag="p" className="avatar-and-name">
                          <Avatar user={props.summary.closedBy} />
                          {props.summary.closedBy.fullName}
                        </Input>
                      )}
                    </FormGroup>
                  </Col>
                  {(isGeneralSummary || isOutageScopeSummary) && (
                    <Col sm={6}>
                      <FormGroup>
                        <Label>Report by</Label>
                        <MultiUserSelect
                          disabled={!userCanEditSummary}
                          selectedUsers={summary!.reportBy}
                          onChangeUsers={(users) => {
                            saveChange(
                              {
                                reportBy: users,
                              },
                              true
                            );
                          }}
                          siteId={summary!.siteId}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col sm={6}>
                    <FormGroup>
                      <Label>Customer contact</Label>
                      <Input
                        className="form-control"
                        value={summary.customerContact || ""}
                        onChange={(e) => {
                          saveChange({
                            customerContact: e.target.value,
                          });
                        }}
                        placeholder="name"
                        readOnly={!userCanEditSummary || isClosed}
                      />
                    </FormGroup>
                  </Col>
                  {props.summary.model.summaryType === SummaryType.General && (
                    <Col sm={6}>
                      <FormGroup>
                        <Label>Ref. WO/MOC</Label>
                        <Input
                          className="form-control"
                          value={summary.refWO || ""}
                          onChange={(e) => {
                            saveChange({
                              refWO: e.target.value,
                            });
                          }}
                          readOnly={!userCanEditSummary || isClosed}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <FormGroup>
                  <EditHeader
                    value={summary.highlightsLabel}
                    editable={userCanEditSummary && !isClosed}
                    onChange={(updatedLabel: string) => {
                      saveChange({
                        highlightsLabel: updatedLabel,
                      });
                    }}
                  />
                  {userCanEditSummary && !isClosed ? (
                    <RTEditor
                      value={highlightsValue}
                      onChange={(value) => {
                        setHighlightsValue(value);
                        saveChange({ highlights: value.toString("html") });
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: highlightsValue.toString("html"),
                      }}
                    />
                  )}
                </FormGroup>

                <FormGroup>
                  <EditHeader
                    value={summary.challengesLabel}
                    editable={userCanEditSummary && !isClosed}
                    onChange={(updatedLabel: string) => {
                      saveChange({
                        challengesLabel: updatedLabel,
                      });
                    }}
                  />
                  {userCanEditSummary && !isClosed ? (
                    <RTEditor
                      value={challengesValue}
                      onChange={(value) => {
                        setChallengesValue(value);
                        saveChange({ challenges: value.toString("html") });
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: challengesValue.toString("html"),
                      }}
                    />
                  )}
                </FormGroup>

                <FormGroup>
                  <EditHeader
                    value={summary.generalRecommendationLabel}
                    editable={userCanEditSummary && !isClosed}
                    onChange={(updatedLabel: string) => {
                      saveChange({
                        generalRecommendationLabel: updatedLabel,
                      });
                    }}
                  />
                  {userCanEditSummary && !isClosed ? (
                    <RTEditor
                      value={generalRecommendationsValue}
                      onChange={(value) => {
                        setGeneralRecommendationsValue(value);
                        saveChange({ generalRecommendations: value.toString("html") });
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: generalRecommendationsValue.toString("html"),
                      }}
                    />
                  )}
                </FormGroup>

                <FormGroup>
                  <EditHeader
                    value={summary.pathForwardLabel}
                    editable={userCanEditSummary && !isClosed}
                    onChange={(updatedLabel: string) => {
                      saveChange({
                        pathForwardLabel: updatedLabel,
                      });
                    }}
                  />
                  {userCanEditSummary && !isClosed ? (
                    <RTEditor
                      value={pathForwardValue}
                      onChange={(value) => {
                        setPathForwardValue(value);
                        saveChange({ pathForward: value.toString("html") });
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pathForwardValue.toString("html"),
                      }}
                    />
                  )}
                </FormGroup>
              </Form>
            </div>
          </Card>

          {!!summary.id && (
            <Card body className={classNames("attachments")}>
              <CardTitle className="section-title">
                <div className="title">Attachments</div>
                <div className="gap" />
                <AttachmentsArea parentType="Summary" parentId={summary.id} parentTag={summary.title} />
              </CardTitle>
            </Card>
          )}
        </Col>
        <Col md={4} lg={3}>
          {!isBulkValveHealthSummary &&
            summary.summaryType !== SummaryType.KPI &&
            summary.summaryType !== SummaryType.OutageScope && (
              <AssetSelector
                assets={summary.assets}
                siteId={summary.siteId}
                readOnly={isClosed}
                addAsset={(asset) => {
                  saveChange({ assets: summary.assets.concat(asset) });
                }}
                removeAsset={(asset) => {
                  saveChange({ assets: summary.assets.filter((a) => a.id !== asset.id) });
                }}
                parentDescriptor="summary"
                hideHint={true}
              />
            )}
          {summary.summaryType !== SummaryType.KPI && summary.summaryType !== SummaryType.OutageScope && (
            <SummaryRecordSelector
              records={summary.records}
              siteId={summary.siteId}
              userCanEdit={userCanEditSummary && !isClosed}
              userCanViewRecords={userCanViewRecord}
              addRecord={(record) => {
                saveChange({ records: summary.records.concat(record) });
              }}
              removeRecord={(record) => {
                saveChange({ records: summary.records.filter((r) => r.id !== record.id) });
              }}
              parentDescriptor="summary"
              valveDiagnosticsOnly={isBulkValveHealthSummary}
              selectingForBulkValveHealthSummary={false}
              recordFilter={(existingRecordDetails) =>
                isBulkValveHealthSummary
                  ? (r) =>
                      existingRecordDetails.every((sr) => sr.model.assets[0].id !== r.model.assets[0].id) &&
                      r.model.assets.length > 0 &&
                      recordBeforeSummaryDate(r.model, summary.summaryDate)
                  : (r) => recordBeforeSummaryDate(r.model, summary.summaryDate)
              }
              hideHint={true}
              recordWarning={(r) =>
                isBulkValveHealthSummary &&
                !!r.model.health &&
                (r.model.health.overall === null ||
                  r.model.health.condition === null ||
                  r.model.health.performance === null) ? (
                  <div
                    className="item-danger"
                    title="This record's health scores are incomplete. It will be excluded from the generated report."
                  >
                    incomplete (excluded from report)
                  </div>
                ) : null
              }
            />
          )}
          {summary.summaryType !== SummaryType.OutageScope && (
            <SelectedKpiValues
              kpiValues={props.summary.model.kpiValues}
              remove={(kpiValue) => {
                saveChange({ kpiValues: summary.kpiValues.filter((kpi) => kpi.id !== kpiValue.id) });
              }}
              ineligibleBecause={(kpiValue) =>
                kpiValue.calculatedSavings === 0
                  ? "No calculated savings."
                  : kpiValue.kpiType === "Legacy"
                  ? "Must be converted to a new KPI type"
                  : null
              }
            />
          )}
          {summary.summaryType === SummaryType.OutageScope && !!props.summary.recordsEvent && (
            <RecordSelector
              siteId={props.summary.siteId}
              records={recordsEventRecords}
              preventCollapse={true}
              renderRecords={(props) => (
                <SelectedRecordListGroupedByAsset userCanViewRecords={true} renderAssetAsLink={true} {...props} />
              )}
              renderTitle={() => (
                <Link to={formatRoutePath(Routes.RecordsEvent, props.summary.recordsEvent!)} target="_blank">
                  {props.summary.recordsEvent!.name}
                </Link>
              )}
            />
          )}
        </Col>
      </Row>
      <div className="infrequent-commands">
        {userCanEditSummary && (
          <>
            <Button color="danger" size="sm" onClick={() => setDeleting(true)}>
              <DeleteIcon />
              <span>Delete summary</span>
            </Button>
            <ConfirmationModal
              title="Delete summary?"
              body="Are you sure you want to delete this summary?"
              show={deleting}
              cancel={() => setDeleting(false)}
              confirm={async () => {
                deleteSummaryRequest.call(summary.id).then(() => {
                  notifications.success("Summary deleted");
                  navigate(formatRoutePath(Routes.Summaries, { siteId }));
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
