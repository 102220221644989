import { useBaseAxiosPostRequest } from "app";
import { AssetDigest } from "assets";
import { RecordDetailView } from "records";
import { GlobalSearchRequestModel, GlobalSearchResponseModel } from "./models";

export const useGlobalSearchRequest = () =>
   useBaseAxiosPostRequest<GlobalSearchRequestModel, GlobalSearchResponseModel>("/api/search/global", {
      app: { hideFromAutosaver: true },
      useNode: true
   });

export const useAssetDigestsSearchRequest = () =>
   useBaseAxiosPostRequest<GlobalSearchRequestModel, GlobalSearchResponseModel<AssetDigest>>(
      "/api/search/assetDigests",
      {
         app: { hideFromAutosaver: true },
         useNode: true
      }
   );

export const useRecordDetailsSearchRequest = () =>
   useBaseAxiosPostRequest<GlobalSearchRequestModel, GlobalSearchResponseModel<RecordDetailView>>(
      "/api/search/recordDetails",
      {
         app: { hideFromAutosaver: true },
         useNode: true
      }
   );
