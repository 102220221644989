import * as React from "react";
import { Summary } from "summaries";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { StandardModalFooter } from "app/bs/StandardModalFooter";
import { Checkbox } from "common";
import { Form } from "reactstrap";
import { useState } from "react";
import { useGeneralSummaryReportUrlBuilder } from "common/reports";

interface Props {
   summary: Summary;
   close: () => void;
}

export const ConfigureGeneralSummaryReportModal: React.FunctionComponent<Props> = (props) => {
   const [includeAssetsAndRecords, setIncludeAssetsAndRecords] = useState(true);
   const [includeHighlightsEtc, setIncludeHighlightsEtc] = useState(true);
   const [includePhotos, setIncludePhotos] = useState(true);
   const [appendPdfAttachments, setAppendPdfAttachments] = useState(true);
   const [includeRecordDetails, setIncludeRecordDetails] = useState(true);
   const [includeRecordRecommendations, setIncludeRecordRecommendations] = useState(true);

   const reportUrl = useGeneralSummaryReportUrlBuilder({
      id: props.summary.id,
      pdf: true,
      appendPdfAttachments,
      includeAssetsAndRecords,
      includeHighlightsEtc,
      includePictures: includePhotos,
      includeRecordDetails,
      includeRecordRecommendations,
   });

   return (
      <Modal
         isOpen={true}
         size="lg"
         className="configure-general-summary-report-modal"
         container={"#bootstrap-modal-container"}
      >
         <ModalHeader>Report options</ModalHeader>
         <ModalBody>
            <Form>
               <Checkbox
                  value={includeAssetsAndRecords}
                  onClick={() => {
                     setIncludeAssetsAndRecords(!includeAssetsAndRecords);
                     setIncludeRecordDetails(!includeAssetsAndRecords);
                     setIncludeRecordRecommendations(!includeAssetsAndRecords);
                  }}
               >
                  Include assets and records
               </Checkbox>
               <Checkbox
                  value={includeHighlightsEtc}
                  onClick={() => {
                     setIncludeHighlightsEtc(!includeHighlightsEtc);
                  }}
               >
                  Include highlights, challenges, general recommendations, path forward
               </Checkbox>
               <Checkbox
                  value={includePhotos}
                  onClick={() => {
                     setIncludePhotos(!includePhotos);
                  }}
               >
                  Include photos
               </Checkbox>
               <Checkbox
                  value={appendPdfAttachments}
                  onClick={() => {
                     setAppendPdfAttachments(!appendPdfAttachments);
                  }}
               >
                  Append PDF attachments
               </Checkbox>
               <Checkbox
                  value={includeRecordDetails}
                  onClick={() => {
                     setIncludeRecordDetails(includeAssetsAndRecords ? !includeRecordDetails : false);
                  }}
               >
                  Include record details
               </Checkbox>
               <Checkbox
                  value={includeRecordRecommendations}
                  onClick={() => {
                     setIncludeRecordRecommendations(includeAssetsAndRecords ? !includeRecordRecommendations : false);
                  }}
               >
                  Include record recommendations
               </Checkbox>
            </Form>
         </ModalBody>
         <StandardModalFooter
            confirmDisabled={!reportUrl}
            confirm={() => {
               window.open(reportUrl!, "_blank");
               props.close();
            }}
            cancel={props.close}
         />
      </Modal>
   );
};
