import { useState } from "react";
import { Container, Paper, Stack } from "@mui/material";
import { CustomersAndSitesPanel } from "./CustomersAndSitesPanel";
import { SiteAdministrationPanel } from "./SiteAdministrationPanel";
import {
   useGetFreshCustomerQuery,
   useGetFreshCustomersQuery,
   useGetSiteByIdRequestWithAutoRefresh,
   useGetSiteDigestsWithAutoRefreshRequest,
} from "../api";
import { AllUsersContextProvider } from "../allUsersContext";

export const CustomersPage = () => {
   const { data: allCustomers } = useGetFreshCustomersQuery();
   const customers = allCustomers || [];

   const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null);
   const [selectedSiteId, setSelectedSiteId] = useState<number | null>(null);

   const allSiteDigests = useGetSiteDigestsWithAutoRefreshRequest();
   const sites = allSiteDigests.data?.filter((s) => s.customerId === selectedCustomerId) || [];

   const getSiteDetailQuery = useGetSiteByIdRequestWithAutoRefresh(selectedSiteId);
   const site = !!selectedSiteId && getSiteDetailQuery.data?.id === selectedSiteId ? getSiteDetailQuery.data : null;

   const getCustomerDetailQuery = useGetFreshCustomerQuery(selectedCustomerId);
   const customer = selectedCustomerId ? getCustomerDetailQuery.data : null;

   return (
      <Container>
         <AllUsersContextProvider>
            <Stack spacing={2}>
               <Paper sx={{ p: 2 }}>
                  <CustomersAndSitesPanel
                     customers={customers}
                     sites={sites}
                     selectedCustomerId={selectedCustomerId}
                     onSelectCustomerId={(customerId) => {
                        setSelectedSiteId(null);
                        setSelectedCustomerId(customerId);
                     }}
                     selectedSiteId={selectedSiteId}
                     onSelectSiteId={setSelectedSiteId}
                     selectedCustomer={customer}
                     selectedSite={site}
                  />
               </Paper>

               {site && (
                  <Paper sx={{ p: 2 }}>
                     <SiteAdministrationPanel site={site} />
                  </Paper>
               )}
            </Stack>
         </AllUsersContextProvider>
      </Container>
   );
};
