import { IrisTooltip } from "app";
import { IrisColors } from "app/mui/theme";
import InfoIcon from "@mui/icons-material/Info";
import React, { CSSProperties } from "react";

export type HelpType =
  | "commodityPricePerUnitOneTime"
  | "commodityPricePerUnitOngoing"
  | "costsBeforeImplementation"
  | "dedicatedTripsAvoided"
  | "equipmentAndMaterialsCost"
  | "kpiApproach"
  | "kpiType"
  | "location"
  | "oneTimeLabourPotentialLabour"
  | "ongoingMaintenanceCostSavings"
  | "ongoingProductionImprovements"
  | "potentialDelayType"
  | "probabilityOfDowntime"
  | "remoteHours"
  | "savingsCategory"
  | "simplifiedView"
  | "simplifiedViewLocked";

const getHelpContent = (helpType: HelpType) => {
  switch (helpType) {
    case "commodityPricePerUnitOneTime":
      return (
        <p>
          The price for each unit of the production commodity, used to calculate the potential cost of the downtime
          which was avoided. For example, if the production commodity is barrels of oil, the market price per barrel of
          oil at the time of the work which prevented downtime would be appropriate.
        </p>
      );
    case "commodityPricePerUnitOngoing":
      return (
        <p>
          The price for each unit of the production commodity, used to calculate the projected annual increase. For
          example, if the production commodity is barrels of oil, the market price per barrel of oil at the time of the
          engineered change would be a reasonable approximation of the expected per barrel price to use when
          approximating the value of the ongoing production increase.
        </p>
      );
    case "costsBeforeImplementation":
      return <p>Estimated maintenance costs prior to implementation of proposed solution.</p>;
    case "dedicatedTripsAvoided":
      return (
        <>
          <p>
            Not all remote work saves a dedicated site trip. Fill this field only if the work was urgent or significant
            enough to justify a dedicated site trip if it couldn't have been done remotely.
          </p>
        </>
      );
    case "equipmentAndMaterialsCost":
      return <p>The cost to purchase devices, equipment, and materials with which to perform the engineered change.</p>;
    case "kpiApproach":
      return (
        <>
          <p>
            The <b>Multiplier KPI Approach</b> is a method by which the total actual costs are multiplied by a specified
            factor, to provide the potential costs. Based on studies, the indirect costs of reactive maintenance can be
            2x-5x more expensive than predictive maintenance.
          </p>
          <p>
            The <b>Custom KPI Approach</b> allows the potential costs to be directly specified.
          </p>
        </>
      );
    case "kpiType":
      return (
        <>
          <p>
            <b>One-time labour or equipment cost savings</b> - Savings resulting from a reduction in time and/or
            materials from what otherwise might have been spent or required. These savings are usually the result of
            analysis, insight, or applied expertise.
          </p>
          <p>
            <b>One-time downtime avoidance</b> - Prevention or reduction of facility downtime. This is usually achieved
            through technical mitigation, process or logisitics streamlining, or communication.
          </p>
          <p>
            <b>One-time release or spill avoidance</b> - Prevention or reduction of the release of substances which have
            a negative environmental effect.
          </p>
          <p>
            <b>Ongoing maintenance savings or production increase</b> - Ongoing cost savings or value creation by
            investment in an engineered improvement to assets. Improvements can increase savings and value by increasing
            reliability, decreasing maintenance obligations, or increasing production efficiency or throughput.
          </p>
          <p>
            <b>Ongoing emissions reduction</b> - Ongoing prevention or reduction of the release of substances which have
            a negative environmental effect.
          </p>
        </>
      );
    case "location":
      return (
        <p>
          The equipment or process unit that avoided downtime due to a corrective action against the underlying
          asset(s).
        </p>
      );
    case "oneTimeLabourPotentialLabour":
      return (
        <p>
          It is assumed that labour costs for this calculation will utilize the 'Site Personnel Labour' and not
          'Specialist Labour' due to the fact site personnel would conduct the work involved.
        </p>
      );
    case "ongoingMaintenanceCostSavings":
      return (
        <>
          <p>
            Indication of whether the engineered change is expected to result in ongoing maintenance cost savings.
            Changes such as installation of a less failure-prone device can be expected to save costs.
          </p>
          <p>
            An ongoing maintenance savings or production improvement KPI should include maintenance savings, production
            improvement, or both.
          </p>
        </>
      );
    case "ongoingProductionImprovements":
      return (
        <>
          <p>
            Indication of whether the engineered change is expected to result in increased production. Changes such as
            installation of a more efficient device can be expected to improve the rate of production.
          </p>
          <p>
            An ongoing maintenance savings or production improvement KPI should include maintenance savings, production
            improvement, or both.
          </p>
        </>
      );
    case "potentialDelayType":
      return (
        <>
          <p>
            <b>Logistics</b> - Downtime avoidance resulted from actively managing activities such as shipping and
            transportation, field work, and repair.
          </p>
          <p>
            <b>Technical</b> - Downtime avoidance resulted from mitigations identified and implemented through technical
            expertise.
          </p>
          <p>
            <b>Communication or capacity</b> - Downtime avoidance resulted from raising concerns, escalating requests,
            arranging for technician availability, and similar activities.
          </p>
        </>
      );
    case "probabilityOfDowntime":
      return (
        <p>
          The probability that, had the work represented by this record not been done, the downtime would have occurred.
          This probability is used to adjust the potential downtime cost - for example, a 75% probability will result in
          the potential dowtime cost being reduced by one quarter.
        </p>
      );
    case "remoteHours":
      return <p>The total number of specialist hours that were performed in a remote fashion.</p>;
    case "savingsCategory":
      return (
        <>
          <p>
            <b>Cancel scope</b> - Savings resulting from work deemed unnecessary.
          </p>
          <p>
            <b>Reduce equipment costs</b> - Savings resulting from lower costs secured by relationship, research, or
            negotiation.
          </p>
          <p>
            <b>Reduce parts</b> - Savings resulting from a reduction in materials.
          </p>
          <p>
            <b>Smart function testing</b> - Savings resulting from insights gained through smart function testing.
          </p>
          <p>
            <b>Work process improvement</b> - Savings resulting from streamlining a logistical or functional work
            process.
          </p>
        </>
      );
    case "simplifiedView":
      return (
        <p>
          <b>Simplified Version</b> is intended for cases whereby insufficient information is available to calculate
          savings in the detailed view. The actual and potential costs can be directly inputted.
        </p>
      );
    case "simplifiedViewLocked":
      return (
        <>
          <p>
            <b>Simplified Version</b> is intended for cases whereby insufficient information is available to calculate
            savings in the detailed view. The actual and potential costs can be directly inputted.
          </p>
          <p>
            The toggle is disabled as it was migrated from a legacy KPI format and works only within the simplified
            version. It can be deleted and recreated, using the detailed view.
          </p>
        </>
      );
  }
};

export const KpiHelpTooltip: React.FunctionComponent<{ type: HelpType; spanStyle?: CSSProperties }> = (props) => {
  const helpType = props.type;
  return (
    <IrisTooltip title={getHelpContent(helpType)}>
      <span id={`${helpType}Help`} className="kpi-help-icon" style={props.spanStyle}>
        <InfoIcon sx={{ color: IrisColors.gray500 }} />
      </span>
    </IrisTooltip>
  );
};
