import { DropIcon } from "icons/DropIcon";
import _ from "lodash";
import { useState, useCallback } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useCreateOutagePlanningScopeRequest, useUpdateRecordOutageDetailRequest, useDeleteOutagePlanningScopeRequest } from "records";
import {
   getNewOutageScope,
   RecordOutageDetail,
   RecordOutageDetailView,
   RecordOutagePlanningScope,
   RecordOutagePlanningScopeType,
   RecordOutagePlanningScopeView,
} from "../models";
import { RecordDetailSectionProps } from "../RecordOutageDetailCard";
import { ScopeCard } from "./ScopeCard";

interface PlanningTabProps extends RecordDetailSectionProps {
   outageDetailView: RecordOutageDetailView;
   recordOutagePlanningScopes: RecordOutagePlanningScopeView[];
   recordId: number;
}

export const PlanningTab = (props: PlanningTabProps) => {
   const { outageDetailView: outageDetail } = props;

   // temp array of deleted scope ids so we can hide deleted items
   // while waiting for the server to complete its operation
   const [deletedScopes, setDeletedScopes] = useState<number[]>([]);
   const deleteOutageScope = useDeleteOutagePlanningScopeRequest(props.recordId);
   const deleteScope = (scopeId: number) => {
      deleteOutageScope.call(scopeId);
   };

   const recordOutagePlanningScopes = props.recordOutagePlanningScopes
      .filter((s) => !deletedScopes.includes(s.id))
      .map((s) => s.model);

   const updateOutageDetails = useUpdateRecordOutageDetailRequest();
   const createOutageScope = useCreateOutagePlanningScopeRequest(props.recordId);

   const [addOriginalScopeMenuOpen, setAddOriginalScopeMenuOpen] = useState<boolean>(false);
   const [addRecommendedScopeMenuOpen, setAddRecommendedScopeMenuOpen] = useState<boolean>(false);

   const toggleAddOriginalScopeMenu = () => {
      setAddOriginalScopeMenuOpen(!addOriginalScopeMenuOpen);
   };

   const toggleAddRecommendedScopeMenu = () => {
      setAddRecommendedScopeMenuOpen(!addRecommendedScopeMenuOpen);
   };

   const [initialMaxId] = useState(_.max(recordOutagePlanningScopes.map((s) => s.id)));
   const budgetaryReplacementScope = _.first(
      recordOutagePlanningScopes.filter((s) => s.scopeType === "BudgetaryReplacementCost")
   );

   const originalScopes = recordOutagePlanningScopes.filter((s) => s.scopeType === "Original"); // will be length of 0 or 1
   const recommendationScopes = recordOutagePlanningScopes.filter((s) => s.scopeType === "Recommendation");
   const addScope = (type: RecordOutagePlanningScopeType) => {
      const newScope = getNewOutageScope(type, recordOutagePlanningScopes[0]?.recordOutageDetailId);
      createOutageScope.call(newScope);
   };

   const addReplaceAssemblyScope = useCallback(
      (type: RecordOutagePlanningScopeType) => {
         const copyFrom = recordOutagePlanningScopes.find((s) => s.scopeType === "BudgetaryReplacementCost")!;

         const newScope: RecordOutagePlanningScope = {
            ...getNewOutageScope(type, copyFrom.recordOutageDetailId),

            primaryAction: "Replace",
            primaryComponent: "Assembly",
            primaryLocation: null,

            quoteAmount: copyFrom.quoteAmount,
            leadTime: copyFrom.leadTime,
            leadTimeUnit: copyFrom.leadTimeUnit,
            vendor: copyFrom.vendor,
            quoteNumber: copyFrom.quoteNumber,
            quoteDate: copyFrom.quoteDate,
            labourPortion: copyFrom.labourPortion,

            secondaryAction: copyFrom.secondaryAction,
            secondaryComponent: copyFrom.secondaryComponent,
            secondaryLocation: copyFrom.secondaryLocation,

            details: copyFrom.details,
         };

         createOutageScope.call(newScope);
      },
      [createOutageScope, recordOutagePlanningScopes]
   );

   // remove scope only needs to hide the scope while the delete is happening.
   // deleting from the api happens inside the scopecard.
   const removeScope = (scopeId: number) => {
      deleteScope(scopeId);
      setDeletedScopes((current) => {
         return [...current, scopeId];
      });
   };

   // changing the accepted scope calls the update outage detail api, but that sends the entire detail view mode,
   // it should really call a dedicated api to set the accepted scope. Sending everything seems like a bad idea.
   const changeAcceptedScope = (scopeId?: number) => {
      const newOutageDetail: RecordOutageDetail = {
         ...outageDetail.model,
         acceptedScopeId: scopeId ?? null,
      };

      updateOutageDetails.call(newOutageDetail);
   };

   const acceptedScopeId = props.outageDetailView.acceptedScope?.id;
   const hasAcceptedScope = !!acceptedScopeId;
   const canRemoveScope = !hasAcceptedScope;

   return (
      <>
         {!!budgetaryReplacementScope && (
            <ScopeCard
               key={budgetaryReplacementScope.id}
               recordId={props.recordId}
               scope={budgetaryReplacementScope}
               userCanEdit={props.userCanEdit}
               defaultExpanded={budgetaryReplacementScope.id > (initialMaxId || 0)}
               selectable={true}
               accepted={acceptedScopeId === budgetaryReplacementScope.id}
               setAcceptedScopeId={changeAcceptedScope}
               acceptedScopeExists={hasAcceptedScope}
               refetchView={props.refetchView}
            />
         )}
         {originalScopes.map((originalScope) => (
            <ScopeCard
               key={originalScope.id}
               recordId={props.recordId}
               scope={originalScope}
               otherScopes={recordOutagePlanningScopes}
               userCanEdit={props.userCanEdit}
               defaultExpanded={originalScope.id > (initialMaxId || 0)}
               canRemoveScope={canRemoveScope}
               removeScope={() => removeScope(originalScope.id)}
               selectable={true}
               accepted={acceptedScopeId === originalScope.id}
               setAcceptedScopeId={changeAcceptedScope}
               acceptedScopeExists={hasAcceptedScope}
               refetchView={props.refetchView}
            />
         ))}
         {originalScopes.length === 0 && props.userCanEdit && !hasAcceptedScope && (
            <Row>
               <Col xs={12} style={{ textAlign: "right" }}>
                  <Dropdown
                     isOpen={addOriginalScopeMenuOpen}
                     toggle={toggleAddOriginalScopeMenu}
                     className="header-menu business-area-menu"
                  >
                     <DropdownToggle
                        size="sm"
                        style={{ marginBottom: "1em" }}
                        onClick={toggleAddOriginalScopeMenu}
                        data-toggle="dropdown"
                        aria-expanded={addOriginalScopeMenuOpen}
                     >
                        <span>Add original scope</span>
                        <DropIcon className={addOriginalScopeMenuOpen ? "show" : undefined} />
                     </DropdownToggle>
                     <DropdownMenu right>
                        <DropdownItem
                           onClick={() => {
                              addReplaceAssemblyScope("Original");
                           }}
                        >
                           Replace Assemply
                        </DropdownItem>
                        <DropdownItem
                           onClick={() => {
                              addScope("Original");
                           }}
                        >
                           Blank
                        </DropdownItem>
                     </DropdownMenu>
                  </Dropdown>
               </Col>
            </Row>
         )}
         {recommendationScopes.map((recommendationScope, i) => (
            <ScopeCard
               key={recommendationScope.id}
               recordId={props.recordId}
               scope={recommendationScope}
               otherScopes={recordOutagePlanningScopes}
               userCanEdit={props.userCanEdit}
               defaultExpanded={recommendationScope.id > (initialMaxId || 0)}
               canRemoveScope={canRemoveScope}
               removeScope={() => removeScope(recommendationScope.id)}
               selectable={true}
               accepted={acceptedScopeId === recommendationScope.id}
               setAcceptedScopeId={changeAcceptedScope}
               recommendationNumber={i + 1}
               acceptedScopeExists={hasAcceptedScope}
               refetchView={props.refetchView}
            />
         ))}

         {props.userCanEdit && !hasAcceptedScope && recommendationScopes.length < 2 && (
            <Row>
               <Col xs={12} style={{ textAlign: "right", marginTop: "1em" }}>
                  <Dropdown
                     isOpen={addRecommendedScopeMenuOpen}
                     toggle={toggleAddRecommendedScopeMenu}
                     className="header-menu business-area-menu"
                  >
                     <DropdownToggle
                        size="sm"
                        style={{ marginBottom: "1em" }}
                        onClick={toggleAddRecommendedScopeMenu}
                        data-toggle="dropdown"
                        aria-expanded={addRecommendedScopeMenuOpen}
                     >
                        <span>Add scope recommendation</span>
                        <DropIcon className={addRecommendedScopeMenuOpen ? "show" : undefined} />
                     </DropdownToggle>
                     <DropdownMenu right>
                        <DropdownItem
                           onClick={() => {
                              addReplaceAssemblyScope("Recommendation");
                           }}
                        >
                           Replace Assemply
                        </DropdownItem>
                        <DropdownItem
                           onClick={() => {
                              addScope("Recommendation");
                           }}
                        >
                           Blank
                        </DropdownItem>
                     </DropdownMenu>
                  </Dropdown>
               </Col>
            </Row>
         )}
      </>
   );
};
