import * as React from "react";
import { FormGroup, Label } from "reactstrap";
import classNames from "classnames";
import NumberFormat from "react-number-format";

export interface NumberInputGroupProps {
	currency?: boolean;
	percentage?: boolean;
	hours?: boolean;
	label?: string;
	preLabel?: string | JSX.Element;
	postLabel?: string | JSX.Element;
	value: number | null;
	valid: boolean;
	readOnly: boolean;
	className?: string;
	inputStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	placeholder?: string;
	validationError?: string;
	tooltip?: JSX.Element;
	onChange: (newValue: number | null) => void;
}

export const NumberInputGroup: React.FunctionComponent<NumberInputGroupProps> = (props: NumberInputGroupProps) => {
	return (
		<FormGroup className={classNames(props.className)} style={props.style}>
			{!!props.label && <Label className="label">{props.label}</Label>}
			<div className="form-group-input-wrapper">
				{!!props.preLabel && <Label className="pre-label">{props.preLabel}</Label>}
				<NumberFormat
					value={
						props.value !== null && props.value !== undefined
							? (props.percentage ? props.value * 100 : props.value).toString()
							: ""
					}
					onValueChange={(newValue) =>
						props.onChange(
							newValue.floatValue !== undefined
								? props.percentage
									? newValue.floatValue / 100
									: newValue.floatValue
								: null
						)
					}
					placeholder={!!props.placeholder ? props.placeholder : props.percentage ? "100%" : "0"}
					allowNegative={false}
					decimalScale={2}
					fixedDecimalScale={(props.value || 0) % 1 !== 0}
					readOnly={props.readOnly}
					thousandSeparator={true}
					prefix={props.currency ? "$" : undefined}
					suffix={props.percentage ? "%" : props.hours ? "h" : undefined}
					className={classNames("form-control", {
						"rounded-right-override": false,
						"text-right": true, //props.percentage || props.hours,
						"is-invalid": !props.valid,
					})}
					style={props.inputStyle}
				/>
				{!!props.postLabel && <Label className="post-label">{props.postLabel}</Label>}
				{!!props.tooltip && props.tooltip}
			</div>
			<div className="invalid-feedback" style={{ display: props.valid ? "none" : "block" }}>
				{props.validationError || (props.percentage ? "Enter a valid percentage." : "Enter a positive amount.")}
			</div>
		</FormGroup>
	);
};
