import * as React from "react";
import { Icon } from "icons/Icon";

export const CheckboxEmptyIcon: React.FunctionComponent = () => (
   <Icon viewBoxSize={32}>
      <g id="surface1">
         <path d="M 5 5 L 5 27 L 27 27 L 27 5 Z M 7 7 L 25 7 L 25 25 L 7 25 Z " />
      </g>
   </Icon>
);
