import classNames from "classnames";
import { Validity } from "common";
import { KpiPotentialDelayType, KpiPotentialDelayTypes } from "kpis/models";
import React from "react";
import { FunctionComponent } from "react";
import Select from "react-select";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { KpiHelpTooltip } from "./KpiHelpTooltip";
import Textarea from "react-textarea-autosize";

interface SectionFields {
  potentialDelayType: KpiPotentialDelayType | string | null;
  location: string | null;
  actionsTaken: string | null;
}

interface Props {
  readOnly: boolean;
  showValidationErrors: boolean;
  saveChange: (change: Partial<SectionFields>) => void;
  fields: SectionFields;
  validity: {
    potentialDelayType: Validity;
    location: Validity;
    actionsTaken: Validity;
  };
}

export const DelayTypeLocationAndActionsTakenRow: FunctionComponent<Props> = (props) => {
  return (
    <Row className="gray-form-section">
      <Col xs={12}>
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label>Potential delay type</Label>
              <div className="form-group-input-wrapper">
                <Select
                  className={classNames("react-select", {
                    "is-invalid": props.showValidationErrors && !props.validity.potentialDelayType.valid,
                  })}
                  classNamePrefix="react-select"
                  value={!!props.fields.potentialDelayType ? { value: props.fields.potentialDelayType } : null}
                  onChange={(newPotentialDelayType) =>
                    props.saveChange({
                      potentialDelayType: !!newPotentialDelayType ? newPotentialDelayType.value : null,
                    })
                  }
                  options={KpiPotentialDelayTypes.map((dt) => ({ value: dt }))}
                  getOptionLabel={(option) => option.value}
                  isDisabled={props.readOnly}
                />
                <KpiHelpTooltip type="potentialDelayType" />
              </div>
              <div className="invalid-feedback">{props.validity.potentialDelayType.message}</div>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label>Unit/facility/plant</Label>
              <div className="form-group-input-wrapper">
                <Input
                  value={props.fields.location || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.saveChange({ location: e.target.value });
                  }}
                  readOnly={props.readOnly}
                  className={classNames("form-control", {
                    "is-invalid": !props.validity.location.valid,
                  })}
                />
                <KpiHelpTooltip type="location" />
              </div>
              <div className="invalid-feedback">{props.validity.location.message}</div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>Actions taken</Label>
              <Textarea
                className={classNames("form-control", {
                  "is-invalid": !props.validity.actionsTaken.valid,
                })}
                value={props.fields.actionsTaken || ""}
                onChange={(e) => {
                  props.saveChange({ actionsTaken: e.target.value });
                }}
                readOnly={props.readOnly}
                minRows={3}
              />
              <div className="invalid-feedback">{props.validity.actionsTaken.message}</div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
