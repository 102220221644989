import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OperatingSystemTypeValues } from "@bluemarvel/iris-common/model";
import { useAddOperatingSystemRequest } from "packagedSolution/api";

interface AddPanelDialogProps extends BaseDialogProps {
  packagedSolutionId: number;
}

const validationSchema = yup.object({
  panelType: yup.string().nullable().required("required"),
});

export const AddPanelDialog = (props: AddPanelDialogProps) => {
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const addOperatingSystemRequest = useAddOperatingSystemRequest();

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      addOperatingSystemRequest
        .call({
          packagedSolutionId: props.packagedSolutionId,
          type: values.panelType,
        })
        .then(() => {
          handleClose();
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Add Panel
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <AutocompleteFormItem
              autocompleteProps={{ disablePortal: false }}
              label="Panel Type"
              fieldName="panelType"
              options={OperatingSystemTypeValues.map((type) => ({ id: type, label: type }))}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={onSubmit} variant="contained" color="secondary">
            Add
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
