import { Box, InputAdornment, Typography } from "@mui/material";
import { NumberFormItem, NumberFormItemProps } from "./NumberFormItem";
import { IrisColors, theme } from "app/mui/theme";
import { useController } from "react-hook-form";

interface CurrencyFormItemProps extends NumberFormItemProps {}

function CurrencyFormItem(props: CurrencyFormItemProps) {
  const { numberFormatInputProps, ...rest } = props;

  const control = useController({ name: props.fieldName });

  return (
    <Box
      display="flex"
      alignItems="top"
      sx={{
        "& .MuiInputBase-input.MuiOutlinedInput-input ": {
          paddingLeft: "25px",
        },
      }}
      position="relative"
    >
      <Box
        zIndex="1"
        position="absolute"
        bottom="0"
        marginLeft="8px"
        marginBottom="5px"
        display="flex"
        alignItems="flex-start"
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: !!control.field.value ? IrisColors.dark : IrisColors.gray500,
          }}
        >
          $
        </Typography>
      </Box>
      <NumberFormItem
        numberFormatInputProps={{
          ...numberFormatInputProps,
          placeholder: "0",
        }}
        {...rest}
      />
    </Box>
  );
}

export default CurrencyFormItem;
