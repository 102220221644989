import { Box, Paper, Stack, Typography, Link } from "@mui/material";
import { GridToolbarFilterButton, useGridApiRef } from "@mui/x-data-grid-pro";
import { Routes, formatRoutePath, useSiteContext } from "app";
import { ActionDispatcherProvider, useActionDispatcher } from "app/mui/ActionDispatcher";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import {
  createStaticActions,
  createStaticColumns,
  createStaticRowActions,
  useColumns,
  useStaticActions,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import { dateFormat } from "common";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useGetFilteredRecordsEventDetailsRequest, useUpdateRecordsEventRequest } from "recordsEvents/api";
import { EventIcon } from "icons/EventIcon";
import { RecordsEvent, RecordsEventDetail, RecordsEventFilters } from "recordsEvents/models";
import { Link as RouterLink } from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { DeleteEventDialog } from "./dialogs/DeleteEventDialog";
import { theme } from "app/mui/theme";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { CreateEventDialog } from "./dialogs/CreateEventDialog";

type DialogActionsType = "markAsActiveArchived" | "deleteEvent" | "createEvent" | null;
const validDialogActions = ["markAsActiveArchived", "deleteEvent", "createEvent"];

const rowActions = createStaticRowActions((actionDispatcher, permissions) => (params) => [
  {
    icon: <DeleteRoundedIcon />,
    onClick: () => {
      actionDispatcher.dispatch("deleteEvent", params.row);
    },
    label: "Delete",
    showInMenu: true,
    hidden: !permissions.userCanEditRecordsEvents,
  },
  {
    icon: (params.row as RecordsEventDetail).model.isArchived ? <EventAvailableIcon /> : <Inventory2Icon />,
    onClick: () => {
      actionDispatcher.dispatch("markAsActiveArchived", params.row);
    },
    label: (params.row as RecordsEventDetail).model.isArchived ? "Marks as Active" : "Marks as Archived",
    showInMenu: true,
    hidden: !permissions.userCanEditRecordsEvents,
  },
]);

const pageActions = createStaticActions((actionDispatcher, userPermissions) => [
  {
    icon: <InsertInvitationIcon />,
    label: "Create event",
    action: () => actionDispatcher.dispatch("createEvent"),
    hidden: !userPermissions.userCanEditRecordsEvents,
  },
]);

export const EventListPage = () => {
  const [filters] = useState(new RecordsEventFilters());
  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);
  const [eventForDialog, setEventForDialog] = useState<RecordsEventDetail>();

  const actionDispatcher = useActionDispatcher();
  const site = useSiteContext().currentSite!;
  const siteId = site.id;

  const eventsRequest = useGetFilteredRecordsEventDetailsRequest(siteId);
  const updateRecordsEventRequest = useUpdateRecordsEventRequest();
  const recordsEventDetails = eventsRequest.data || [];

  const actions = useStaticActions(pageActions, actionDispatcher);

  const closeDialog = () => setOpenDialog(null);

  const markAsActiveArchived = (eventDetail: RecordsEventDetail) => {
    const updatedEvent = {
      ...eventDetail.model,
      isArchived: !eventDetail.model.isArchived,
    } as RecordsEvent;

    updateRecordsEventRequest.call(updatedEvent).then((result) => {
      if (result !== undefined) {
        const updatedEventDetail = {
          ...eventDetail,
          model: updatedEvent,
        };
        reloadRowInTable(updatedEventDetail);
      }
    });
  };

  const handleAction = useCallback((action: string, params: RecordsEventDetail) => {
    if (validDialogActions.indexOf(action) > -1) {
      if (action === "deleteEvent") {
        setEventForDialog(params);
      }
      if (action === "markAsActiveArchived") {
        markAsActiveArchived(params);
      }

      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  const fetchEvents = () => eventsRequest.call(filters);

  useEffect(() => {
    fetchEvents();

    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const getTableRowActions = useStaticRowActions(rowActions, actionDispatcher);

  const columnDef = createStaticColumns((actionDispatcher) => [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) => params.row.model.name,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            color="secondary"
            component={RouterLink}
            to={formatRoutePath(Routes.RecordsEvent, params.row as RecordsEventDetail)}
            sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
            state={{ returnTo: window.location.pathname }}
          >
            {params.row.model.name}
          </Link>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "date",
      width: 150,
      valueGetter: (params) => moment(params.row.model.startDate).format(dateFormat),
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "date",
      width: 150,
      valueGetter: (params) => (params.row.model.endDate ? moment(params.row.model.endDate).format(dateFormat) : ""),
    },
    {
      field: "duration",
      headerName: "Duration (days)",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => (params.row.model.isArchived ? "Archived" : "Active"),
      type: "singleSelect",
      valueOptions: ["Archived", "Active"],
    },
    {
      field: "actions",
      type: "actions",
      width: 60,
      getActions: getTableRowActions,
      hideable: false,
    },
  ]);

  const columns = useColumns(columnDef, actionDispatcher);
  const apiRef = useGridApiRef();

  const reloadRowInTable = (updatedEvent: RecordsEventDetail) => {
    if (apiRef.current.getRow(updatedEvent.id)) {
      apiRef.current.updateRows([updatedEvent]);
    }
  };

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <EventIcon
                sx={{
                  fill: theme.palette.primary.main,
                  fontSize: "28px",
                }}
              />
              <Typography variant="h5" fontWeight="bold">
                Events
              </Typography>
            </Stack>
            <ActionsDropdownMenu primary actions={actions} />
          </Stack>

          <Box sx={{ height: "700px", width: "100%" }}>
            <StripedDataGrid
              apiRef={apiRef}
              density="compact"
              loading={eventsRequest.loading}
              columns={columns}
              components={{
                Toolbar: GridToolbarFilterButton,
              }}
              rows={recordsEventDetails}
            />
          </Box>
        </Stack>
        {openDialog === "deleteEvent" && (
          <DeleteEventDialog onClose={closeDialog} recordsEventDetail={eventForDialog!} onDeleteEvent={fetchEvents} />
        )}
        {openDialog === "createEvent" && <CreateEventDialog onClose={closeDialog} />}
      </Paper>
    </ActionDispatcherProvider>
  );
};
