import { useFormContext } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";

import { LeadTimeUnits } from "common/models";

interface LeadTimeInputProps {
   userCanEdit: boolean;
}

export const LeadTimeInput = (props: LeadTimeInputProps) => {
   const {
      register,
      formState: { errors },
   } = useFormContext();

   const { ref: leadTimeRef, ...leadTimeFieldProps } = register("leadTime");
   const { ref: leadTimeUnitRef, ...leadTimeUnitFieldProps } = register("leadTimeUnit");

   const userCanEdit = { readOnly: props.userCanEdit === false };

   return (
      <FormGroup>
         <Label>Lead Time</Label>
         <div className="outage-form-lead-time-input">
            <Input
               className="lead-time-number-input"
               {...leadTimeFieldProps}
               type="number"
               innerRef={leadTimeRef}
               {...userCanEdit}
            />
            <Input
               className="lead-time-unit-input"
               {...leadTimeUnitFieldProps}
               type="select"
               innerRef={leadTimeUnitRef}
               {...userCanEdit}
               disabled={!props.userCanEdit}
            >
               {LeadTimeUnits.map((unit) => (
                  <option key={unit} value={unit}>
                     {unit}s
                  </option>
               ))}
            </Input>
         </div>
         <div className="form-error">{errors.leadTime?.message as string | null | undefined}</div>
      </FormGroup>
   );
};
