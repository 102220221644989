import { Box } from "@mui/material";
import { IrisColors } from "app/mui/theme";
import { TrafficLightValue } from "records";

interface TrafficLightProps {
  score: TrafficLightValue | null;
}

export const TrafficLight = (props: TrafficLightProps) => {
  const grey = "#808080";
  return (
    <Box sx={{ width: "100%", maxWidth: "125px" }}>
      <svg style={{ width: "100%", height: "auto" }} width="465" height="120" viewBox="0 0 465 120" fill="none">
        <rect x="2.5" y="2.5" width="460" height="115" rx="57.5" stroke="#808080" strokeWidth="5" />

        <circle cx="60" cy="60" r="50" fill={props.score === TrafficLightValue.Green ? IrisColors.green : grey} />
        <circle cx="175" cy="60" r="50" fill={props.score === TrafficLightValue.Yellow ? IrisColors.yellow : grey} />
        <circle cx="290" cy="60" r="50" fill={props.score === TrafficLightValue.Orange ? IrisColors.orange : grey} />
        <circle cx="405" cy="60" r="50" fill={props.score === TrafficLightValue.Red ? IrisColors.red : grey} />
      </svg>
    </Box>
  );
};
