import * as React from "react";
import { Summary } from "summaries";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useKpiSummaryReportUrlBuilder } from "common/reports";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";

interface Props extends BaseDialogProps {
  summary: Summary;
}

interface FormValues {
  includeKpiDetails: boolean;
  includeHighlightsEtc: boolean;
  includePhotos: boolean;
  appendPdfAttachments: boolean;
}

export const ConfigureKpiSummaryReportModal: React.FunctionComponent<Props> = (props) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      includeKpiDetails: true,
      includeHighlightsEtc: true,
      includePhotos: true,
      appendPdfAttachments: true,
    },
  });

  const values = useWatch({ control: methods.control });

  const reportUrl = useKpiSummaryReportUrlBuilder({
    id: props.summary.id,
    pdf: true,
    appendPdfAttachments: values.appendPdfAttachments ?? true,
    includeKpiDetails: values.includeKpiDetails ?? true,
    includeHighlightsEtc: values.includeHighlightsEtc ?? true,
    includePictures: values.includePhotos ?? true,
  });

  const handleSubmit = methods.handleSubmit(() => {
    window.open(reportUrl!, "_blank");
    props.onClose();
  });

  return (
    <BaseDialog onClose={props.onClose} maxWidth="md">
      <DialogTitle variant="h6" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        Report options
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <CheckboxFormItem fieldName="includeKpiDetails" label="Include KPI details" />
            <CheckboxFormItem
              fieldName="includeHighlightsEtc"
              label="Include highlights, challenges, general recommendations, path forward"
            />
            <CheckboxFormItem fieldName="includePhotos" label="Include photos" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF attachments" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton loading={false} variant="contained" color="secondary" onClick={handleSubmit}>
            Generate
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
