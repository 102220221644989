import { useUserContext } from "app/contexts";
import { ReactNode } from "react";
import * as records from "records";
import { Option } from "../forms/AutocompleteFormItem";
import _ from "lodash";
import { Asset } from "assets";
import { AssetType } from "assets/models/assetType";
import { useFeatureEnabled, FeatureFlagName } from "common";

interface RecordTypesLoaderProps {
  assets?: Asset[];
  children: (params: { options: Option[] }) => ReactNode;
}

export const RecordTypesLoader = (props: RecordTypesLoaderProps) => {
  const { userPermissions } = useUserContext();
  const useVibrationAnalysis = useFeatureEnabled(FeatureFlagName.UseVibrationAnalysis);

  const recordTypes = [
    records.RecordType.Activity,
    records.RecordType.CandSU,
    records.RecordType.FieldInspection,
    records.RecordType.Predictive,
    records.RecordType.Preventative,
    records.RecordType.Proactive,
    records.RecordType.Reactive,
  ];

  // record types like ValveDiagnostic require additional permissions.
  // ValveDiagnostic records cannot be created for machinery assets
  // users must have explicit permissions to edit ValveDiagnostic records

  const assetTypes = !!props.assets ? _.uniq(props.assets.map((a) => a.assetType)) : [];
  const hasMachineryAssets = assetTypes.some((assetType) => assetType === AssetType.Machinery);
  const isValveRelatedAsset = assetTypes.some(
    (assetType) =>
      assetType === AssetType.ControlValve ||
      assetType === AssetType.IsolationValve ||
      assetType === AssetType.MotorOperatedValve
  );
  const hasTankAsset = assetTypes.some((assetType) => assetType === AssetType.Tank);

  const canEditValveDiagnostics = userPermissions.userCanEditRecord(assetTypes, records.RecordType.ValveDiagnostic);

  if (canEditValveDiagnostics && !hasMachineryAssets && isValveRelatedAsset) {
    recordTypes.push(records.RecordType.ValveDiagnostic);
  }

  if (!hasTankAsset) {
    recordTypes.push(records.RecordType.OutageScope);
  }

  if (hasMachineryAssets && useVibrationAnalysis) {
    recordTypes.push(records.RecordType.VibrationAnalysis);
  }

  const options = recordTypes.map((item) => ({ id: item, label: item }));

  return <>{props.children({ options })}</>;
};
