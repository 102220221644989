import { Stack, Typography } from "@mui/material";
import { HealthCategory } from "records";
import { HealthBadge, getColorForHealth, getTrafficLightColorForHealth } from "./HealthBadge";
import { TrafficLight } from "./TrafficLight";

interface HealthTrafficLightBadgeProps {
  overall: number | null;
  overallCategory: HealthCategory | null;
}

export const HealthTrafficLightBadge = (props: HealthTrafficLightBadgeProps) => {
  const trafficLightScore = getTrafficLightColorForHealth(props.overallCategory);

  return (
    <Stack alignItems="center" sx={{ flexBasis: "125px", flexShrink: 0 }}>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "2.1em",
          color: (theme) => theme.palette[getColorForHealth(props.overallCategory)!].main,
          lineHeight: 1,
          mb: 1,
        }}
      >
        {props.overall !== null ? Math.round(props.overall) + "%" : "N/A"}
      </Typography>
      <TrafficLight score={trafficLightScore} />
      {props.overallCategory && <HealthBadge health={props.overallCategory} />}
    </Stack>
  );
};
