import React, { useState } from "react";
import { Chip, Menu, MenuItem } from "@mui/material";
import { Criticality } from "sites";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

interface CriticalitySelectorProps {
   criticalities?: Criticality[];
   value?: number | null;
   onChange?: (newValue?: number | null) => void;
   disabled?: boolean;
}

export const CriticalitySelector = (props: CriticalitySelectorProps) => {
   const [internalValue, setInternalValue] = useState<number>();

   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

   const open = Boolean(anchorEl);

   const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
      if (props.disabled) return;

      setInternalValue(undefined);
      setAnchorEl(event.currentTarget.parentElement);
      props.onChange?.(null);
   };

   const handleChangedValue = (newValue: number) => {
      if (props.disabled) return;

      setInternalValue(newValue);
      setAnchorEl(null);
      props.onChange?.(newValue);
   };

   const criticalityLabel = props.criticalities?.find((c) => c.id === props.value)?.displayValue ?? "...";

   return (
      <>
         {props.value ? (
            <Chip label={criticalityLabel} size="small" onDelete={handleDelete} />
         ) : (
            <Chip label={"not set"} size="small" onDelete={handleDelete} deleteIcon={<EditRoundedIcon />} />
         )}
         <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={props.disabled ? false : open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
               "aria-labelledby": "lock-button",
               role: "listbox",
            }}
         >
            {props.criticalities?.map((option) => (
               <MenuItem
                  key={option.id}
                  selected={internalValue === option.id}
                  onClick={() => handleChangedValue(option.id)}
               >
                  {option.displayValue}
               </MenuItem>
            ))}
         </Menu>
      </>
   );
};
