import { MenuItem, Select } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { Controller, useFormContext } from "react-hook-form";
import { useEditableContext } from "./EditableContext";
import { ReadonlyFormItem } from "./ReadonlyFormItem";

export interface YesNoFormItemProps {
  label: string;
  hint?: string;
  fieldName: string;
}

export const YesNoFormItem = (props: YesNoFormItemProps) => {
  const transformInput = (value: boolean): string => {
    switch (value) {
      case true:
        return "true";
      case false:
        return "false";
      default:
        return "";
    }
  };

  const transformOutput = (value: any): boolean | null => {
    switch (value) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return null;
    }
  };

  return (
    <Controller
      name={props.fieldName}
      defaultValue={false}
      render={({ field: { onChange, value, ...fieldProps }, fieldState }) => (
        <FormItemLayout controlled hint={props.hint} label={props.label}>
          {(inputProps) => (
            <Select
              {...inputProps}
              value={transformInput(value)}
              onChange={(e) => onChange(transformOutput(e.target.value))}
            >
              <MenuItem value={"true"}>Yes</MenuItem>
              <MenuItem value={"false"}>No</MenuItem>
            </Select>
          )}
        </FormItemLayout>
      )}
    />
  );
};

export const EditableYesNoFormItem = (props: YesNoFormItemProps & { editable?: boolean }) => {
  const { editable: editableViaProps, ...rest } = props;
  const { editable: editableViaContext } = useEditableContext();
  const { watch } = useFormContext();

  const value = watch(props.fieldName);
  let valueAsString = "";
  if (value === true) {
    valueAsString = "Yes";
  } else if (value === false) {
    valueAsString = "No";
  }

  if (editableViaProps || editableViaContext) {
    return <YesNoFormItem {...rest} />;
  }

  return <ReadonlyFormItem {...rest} value={valueAsString} />;
};
