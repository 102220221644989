import { Chip, useTheme } from "@mui/material";
import { AssetType, toAssetTypeDisplay } from "assets/models/assetType";

interface Props {
  assetType?: AssetType;
  isDarkTheme?: boolean;
}

export const AssetTypeBadge = (props: Props) => {
  const assetColor = "primary";
  const theme = useTheme();
  const fill = !!props.isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;

  return (
    <Chip
      size={"small"}
      label={props.assetType ? toAssetTypeDisplay(props.assetType) : ""}
      variant="outlined"
      color={assetColor}
      sx={{
        borderRadius: 1,
        borderColor: fill,
        color: fill,
        width: "150px",
        justifyContent: "flex-start",
      }}
    />
  );
};
