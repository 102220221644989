import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const IrisTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "1em",
    fontWeight: "normal",
    ".popover-title": {
      fontWeight: 500,
    },
    ".popover-title:not(:first-of-type)": {
      marginTop: "6px",
    },
  },
}));
