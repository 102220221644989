import * as React from "react";
import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";

export const MachinerySpecificationCard = () => {
  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="machineryIsObsolete" />,
    <SpecOptionLoader type="MachineryRatedPower">
      {(options) => <EditableAutocompleteFormItem label="Rated Power (HP)" fieldName="ratedPower" {...options} />}
    </SpecOptionLoader>,
    <SpecOptionLoader type="MachineryDataSource">
      {(options) => <EditableAutocompleteFormItem label="Data Source" fieldName="dataSource" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem textFieldProps={{ rows: 4 }} label="Notes" fieldName="notes" />,
  ];
  return <AssetSpecificationCard title="Machinery" specs={fields} />;
};
