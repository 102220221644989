import { Record as IRecord, Status, RecordDetailView, RecordType } from ".";

export const RecommendationText =
  "Problem/Issue: Clearly define the situation\nScope: Details regarding corrective action\nMaterials: Define any parts or equipment required\nUrgency: Potential impact of the issue and the timeframe action is required to mitigate\nWork Type: In Service / Online or Out of Service / Offline";

export interface Recommendation {
  id: number;
  recordId: number;
  priority: number;
  title: string;
  content: string;
  record: IRecord;
  status: Status;
  assetComponents?: number[];
}

export interface RecommendationDetailView {
  id: number;
  model: Recommendation;
  record: RecordDetailView;
}

export interface TemplateRecommendation {
  id: number;
  title: string;
  content: string;
  category: string;
  recordType: RecordType;
  assetTypes: string;
}

export enum RecommendationFieldLabel {
  Title = "Title",
  Priority = "Priority",
  Content = "Content",
}
