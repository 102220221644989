import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { NumberFormatInput, NumberInputProps } from "app/mui/forms/Input/NumberFormatInput";
import { Controller } from "react-hook-form";
import { withEditableToggle } from "../withEditableToggle";

export interface NumberFormItemProps extends BaseFormItemProps {
  numberFormatInputProps?: NumberInputProps;
}

export function NumberFormItem(props: NumberFormItemProps) {
  const {
    readOnly,
    fieldName,
    label,
    numberFormatInputProps: { numberFormatProps, ...otherNumberFormatInputProps } = {},
    ...formItemLayoutProps
  } = props;

  return (
    <Controller
      name={fieldName}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <FormItemLayout
            controlled
            label={label}
            formControlProps={{ disabled: readOnly }}
            error={errors[fieldName]?.message?.toString()}
            {...formItemLayoutProps}
          >
            {(inputProps) => (
              <NumberFormatInput
                onChange={onChange}
                value={value}
                numberFormatProps={{
                  ...numberFormatProps,
                  thousandSeparator: true,
                }}
                {...otherNumberFormatInputProps}
                {...inputProps}
              />
            )}
          </FormItemLayout>
        );
      }}
    />
  );
}

export const EditableNumberFormItem = withEditableToggle(NumberFormItem);
