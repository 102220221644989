import { Box } from "@mui/material";
import { TemplateKpiTypes, getKpiTypeOption } from "kpis";
import { KpiTypeIcon } from "kpis/KpiTypeIcon";
import { AutocompleteFormItem } from "../AutocompleteFormItem";
import { KpiHelpTooltip } from "kpis/formFields/KpiHelpTooltip";

interface KpiTypeSelectFormItemProps {
  fieldName: string;
  showTip?: true;
}

export const KpiTypeSelectFormItem = (props: KpiTypeSelectFormItemProps) => {
  return (
    <AutocompleteFormItem
      label="KPI Type"
      fieldName={props.fieldName}
      options={TemplateKpiTypes.map((item) => getKpiTypeOption(item))}
      autocompleteProps={{
        disablePortal: false,
        isOptionEqualToValue: (option, value) => option.value === value.value,
        groupBy: (option) => option.category,
      }}
      tip={props.showTip ? <KpiHelpTooltip type="kpiType" /> : undefined}
      renderOption={(props, option) => (
        <Box component="li" {...props} sx={{ "& > svg": { mr: 2, flexShrink: 0 } }}>
          <KpiTypeIcon kpiType={option.value} />
          <span>{option.label}</span>
        </Box>
      )}
    />
  );
};
