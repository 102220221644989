import { Stack, Typography } from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

interface ArchivedStatusBadgeProps {
  isArchived: boolean;
  activeIcon?: React.ReactNode;
  archivedIcon?: React.ReactNode;
}

export const ArchivedStatusBadge = ({ isArchived, activeIcon, archivedIcon }: ArchivedStatusBadgeProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {!isArchived && (activeIcon !== undefined ? activeIcon : <EventAvailableIcon />)}
      {isArchived && (archivedIcon !== undefined ? archivedIcon : <Inventory2OutlinedIcon />)}
      <Typography>{isArchived ? "Archived" : "Active"}</Typography>
    </Stack>
  );
};
