import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";

interface SelectInputProps {
   field: string;
   label: string;
   placeholder?: string;
   userCanEdit: boolean;
   options: string[];
   getDisplayTextForValue?: (value: string) => string;
   isClearable?: boolean;
}

export const SelectInput = (props: SelectInputProps) => {
   const {
      formState: { errors },
      control,
   } = useFormContext();

   // TODO: figure out validity, what why how and also why again
   const isInvalid = false;
   // const isInvalid = !props.actionValidity.valid;

   const isClearable = props.isClearable?? false;
   const options = props.options.map((o) => ({
      label: props.getDisplayTextForValue ? props.getDisplayTextForValue(o) : o,
      value: o,
   }));

   const errorMessage = errors[props.field]?.message as string | null | undefined;

   return (
      <FormGroup>
         <Label>{props.label}</Label>
         <Controller
            control={control}
            name={props.field}
            render={({ field }) => {
               // react-select seems to call onChange with a {label: '', value: ''} object.
               // we want to translate that result to a string before passing it to our change handler,
               // which directly places the result back in the scope object

               const proxyOnChange = (result: { label: string; value: string } | null) => {
                  field.onChange({
                     target: {
                        value: result?.value ? result.value : null, 
                     },
                  });
               };

               const selectedOptions = options.filter((reo) => reo.value === field.value);

               return (
                  <Select
                     ref={field.ref}
                     className={classNames("react-select", { "is-invalid": isInvalid })}
                     classNamePrefix="react-select"
                     options={options}
                     value={selectedOptions.length === 0 ? null : selectedOptions[0]}
                     onChange={(value, _) => proxyOnChange(value)}
                     isDisabled={!props.userCanEdit}
                     placeholder={!props.userCanEdit ? "" : props.placeholder || "Select"}
                     isClearable={isClearable}
                  />
               );
            }}
         />
         <div className="form-error">{errorMessage}</div>
      </FormGroup>
   );
};
