import * as React from "react";
import { Icon } from "icons/Icon";

interface Props {
   className?: string;
}

export const DropIcon: React.FunctionComponent<Props> = (props) => (
   <Icon {...props}>
      <path d="M 7 10 L 12 15 L 17 10 Z " />
   </Icon>
);
