import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

interface SplitButtonProps {
   buttonGroupProps?: Partial<ButtonGroupProps>;
   primaryButton: React.ReactNode;
   additionalActions: React.ReactNode[];
}

export default function SplitButton(props: SplitButtonProps) {
   const [open, setOpen] = React.useState(false);
   const anchorRef = React.useRef<HTMLDivElement>(null);

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event: Event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
         return;
      }

      setOpen(false);
   };

   return (
      <>
         <ButtonGroup
            variant="outlined"
            color="secondary"
            {...props.buttonGroupProps}
            ref={anchorRef}
            aria-label="split button"
         >
            {props.primaryButton}
            <Button
               size="small"
               aria-controls={open ? "split-button-menu" : undefined}
               aria-expanded={open ? "true" : undefined}
               aria-haspopup="menu"
               onClick={handleToggle}
            >
               <ArrowDropDownIcon />
            </Button>
         </ButtonGroup>
         <Popper
            sx={{
               zIndex: 10000 /*apparently this number needs to be super high for it to work inside a modal*/,
               width: `${anchorRef.current?.clientWidth}px`,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            placement={"bottom-end"}
         >
            {({ TransitionProps, placement }) => (
               <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === "bottom-end" ? "right top" : "right bottom" }}
               >
                  <Paper elevation={3}>
                     <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                           {props.additionalActions.map((action, index) => (
                              <React.Fragment key={index}>{action}</React.Fragment>
                           ))}
                        </MenuList>
                     </ClickAwayListener>
                  </Paper>
               </Grow>
            )}
         </Popper>
      </>
   );
}
