import { useBaseAxiosGetRequest } from "app";
import { Site, SiteDigest } from "./models";

export const useGetSiteByIdRequest = (siteId: number | null) =>
  useBaseAxiosGetRequest<Site>(!!siteId ? `/api/sites/${siteId}` : null, {
    useNode: true,
  });

export const useGetSiteDigestsRequest = () =>
  useBaseAxiosGetRequest<SiteDigest[]>("/api/sites", {
    useNode: true,
  });
