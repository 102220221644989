import { Box, Divider, Link, Paper, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { IrisColors, systemFonts } from "app/mui/theme";
import { CustomerDashboardViewModel, SiteDashboardViewModel } from "dashboard/models";
import { formatRoutePath, Routes } from "app";
import { SinglePercentageBar } from "./components/SinglePercentageBar";
import { TotalAssetCountsPieChart } from "./components/TotalAssetCountsPieChart";
import { RecordStatusCountsPieChart } from "./components/RecordStatusCountsPieChart";
import Grid from "@mui/system/Unstable_Grid";
import { Hide } from "app/mui/layoutUtils/HideShowUtils";
import { generateLinkToLocation, isValidLatLng } from "app/mui/utils/google-maps-link";
import { WidgetSkeleton } from "../WidgetSkeleton";
import { NewTabLink } from "app/mui/common/links/NewTabLink";

interface MySitesWidgetProps {
  data?: CustomerDashboardViewModel;
}

export const MySitesWidget = (props: MySitesWidgetProps) => {
  const { data } = props;
  return (
    <Paper sx={{ p: { xs: 2, md: 3 }, width: "100%", height: "636px" }}>
      {!!!data ? (
        <WidgetSkeleton />
      ) : (
        <Stack spacing={4} sx={{ height: "100%" }}>
          <Typography variant="h5">My Sites</Typography>
          <Stack
            spacing={3}
            sx={{ height: "100%", overflowY: "scroll" }}
            justifyContent={data.sites.length === 1 ? "center" : "flex-start"}
          >
            {data.sites.map((site) => (
              <SiteWidget site={site} totals={data!} key={site.site.id} />
            ))}
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};

const SiteWidget = (props: { site: SiteDashboardViewModel; totals: CustomerDashboardViewModel }) => {
  return (
    <>
      <Stack direction="row" alignItems="stretch" flexWrap="wrap">
        <LeftPanel site={props.site} totals={props.totals} />
        <Hide at={(bp) => bp.down("lg")}>
          <Divider orientation="vertical" variant="inset" sx={{ height: "auto", m: 0, p: 0, flex: 0 }} />
        </Hide>

        <Stack
          direction="row"
          alignItems="stretch"
          flex="2"
          sx={{ flexWrap: { xs: "wrap", sm: "nowrap", md: "wrap", lg: "nowrap" } }}
        >
          <MiddlePanel site={props.site} />
          <Hide at={(bp) => bp.down("lg")}>
            <Divider orientation="vertical" variant="inset" sx={{ height: "auto", m: 0, p: 0, flex: 0 }} />
          </Hide>
          <RightPanel site={props.site} />
        </Stack>
      </Stack>
      <Divider variant="inset" />
    </>
  );
};

const LeftPanel = (props: { site: SiteDashboardViewModel; totals: CustomerDashboardViewModel }) => {
  return (
    <Stack spacing={3} flex="1" flexShrink="0" sx={{ pr: 4, minWidth: "240px", mb: 3 }}>
      <Stack spacing={0.5}>
        <Typography variant="h5" fontFamily={systemFonts} fontWeight="bold">
          <Link
            to={formatRoutePath(Routes.SiteDashboard, { siteId: props.site.site.id })}
            component={RouterLink}
            color="secondary"
          >
            {props.site.site.name}
          </Link>
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          <Typography variant="body2" component="span" fontWeight="600" sx={{ color: IrisColors.gray600 }}>
            Location:{" "}
          </Typography>
          {props.site.site.location ? (
            isValidLatLng(props.site.site.location) ? (
              <NewTabLink label="VIEW IN MAPS" url={generateLinkToLocation(props.site.site.location)} />
            ) : (
              <Typography variant="body2" component="span" fontWeight="600">
                {props.site.site.location}
              </Typography>
            )
          ) : (
            <Typography variant="body2" component="span" fontWeight="600">
              Not specified
            </Typography>
          )}
        </Typography>
      </Stack>
      <Box sx={{ mx: -1.5 }}>
        <Grid container spacing={3}>
          <Grid xs={12} sm={6} md={12}>
            <Typography variant="body2" fontWeight="bold" fontSize="0.8em">
              % of My Savings YTD
            </Typography>

            <SinglePercentageBar total={props.totals.mySavingsYtd.approved} savings={props.site.savingsYtd} />
          </Grid>
          <Grid xs={12} sm={6} md={12}>
            <Typography variant="body2" fontWeight="bold" fontSize="0.8em">
              % of My Savings ALL TIME
            </Typography>
            <SinglePercentageBar total={props.totals.mySavingsTotal.approved} savings={props.site.savingsTotal} />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

const MiddlePanel = (props: { site: SiteDashboardViewModel }) => {
  return (
    <Box flex="1" flexShrink="0" sx={{ height: "250px" }}>
      <Typography variant="body2" fontWeight="bold" fontSize="0.8em" sx={{ px: 3 }}>
        Assets
      </Typography>
      <TotalAssetCountsPieChart assetCounts={props.site.totalAssetCountByAssetTypes} />
    </Box>
  );
};
const RightPanel = (props: { site: SiteDashboardViewModel }) => {
  return (
    <Box flex="1" flexShrink="0" sx={{ height: "250px" }}>
      <Typography variant="body2" fontWeight="bold" fontSize="0.8em" sx={{ px: 3 }}>
        Records
      </Typography>
      <RecordStatusCountsPieChart recordCounts={props.site.recordCountsByStatus || []} />
    </Box>
  );
};
