import React from "react";
import { Row, Col, Label, FormGroup, Form, Input } from "reactstrap";
import {
  Record,
  Status,
  RecordStatusBadge,
  useGetUsedAssetIdsForRecord,
  useGetUsedRecordsEventIdsForRecord,
} from "records";
import Select, { SingleValue } from "react-select";
import { FollowUpInput } from "records/bs/FollowUpInput";
import moment from "moment";
import { dateFormat } from "common/formats";
import { AssetDigest } from "assets";
import { MultiAssetSelect } from "records/bs/MultiAssetSelect";
import Textarea from "react-textarea-autosize";
import classNames from "classnames";
import { RecordType, RecordFieldLabel, RecordDetailView, StatusValueLabelOptionsWithoutComplete } from "records/models";
import { RTEditor } from "common/RTEditor";
import { EditorValue } from "react-rte";
import _ from "lodash";
import { useGetApplicationRequest, useGetStrategiesByAssetIdsRequest } from "applications";
import { RecordsEventFormField } from "./fields/RecordsEventFormField";
import { getInvalidValidity, getValidValidity } from "common";

interface Props {
  record: Record;
  serverRecord: RecordDetailView;
  saveChange: (changedProp: object, skipDelay?: boolean) => void;
  isComplete: boolean;
  testCriteriaValue: EditorValue;
  testResultsValue: EditorValue;
  observationsValue: EditorValue;
  changeTestCriteriaValue: (e: EditorValue) => void;
  changeTestResultsValue: (e: EditorValue) => void;
  changeObservationsValue: (e: EditorValue) => void;
  assetsInvalid: boolean;
  eventDateInvalid: boolean;
  userCanEdit: boolean;
  userCanViewAssets: boolean;
}

export const RecordDetails: React.FunctionComponent<Props> = (props) => {
  const { record, serverRecord } = props;

  const disabledAssetIds = useGetUsedAssetIdsForRecord(props.serverRecord.model);
  const disabledRecordsEventIds = useGetUsedRecordsEventIdsForRecord(props.serverRecord.model);

  const isValveDiagnostic = record.recordType === RecordType.ValveDiagnostic;
  const isFieldInspection = record.recordType === RecordType.FieldInspection;
  const isOutageScope = record.recordType === RecordType.OutageScope;

  const statusIsSettable = !isValveDiagnostic && !isFieldInspection && record.recordType !== RecordType.Proactive;
  const { userCanEdit } = props;
  const applicationId = record.assets.length > 0 ? record.assets[0].applicationId : null;
  const assetApplicationRequest = useGetApplicationRequest(applicationId);
  const failureModeOptions = assetApplicationRequest.data?.failureModes ?? [];

  const assetStrategiesRequest = useGetStrategiesByAssetIdsRequest(record.assets.map((asset) => asset.id));
  const assetStrategies = assetStrategiesRequest.data ?? [];

  const isClosed = serverRecord.model.status === "Complete" || serverRecord.model.status === "Archived";
  const editableAndOpen = userCanEdit && !isClosed;

  const saveChange = (changedProp: Partial<Record>, skipDelay?: boolean) => {
    props.saveChange(changedProp, skipDelay);
  };

  const onChangeAssets = (selectedAssets: AssetDigest[]) => {
    saveChange(
      {
        assets: selectedAssets,
      },
      true
    );
  };

  const onChangeStatus = (newStatus: SingleValue<{ value: Status }>) => {
    saveChange(
      {
        status: (newStatus as { value: Status }).value,
      },
      true
    );
  };

  const bumpFollowUp = (days: number) => {
    const updatedDate = moment().startOf("day").add(days, "days");
    saveChange(
      {
        followUp: updatedDate.toDate(),
      },
      true
    );
  };

  const onChangeFollowUpDate = (newDate: string) => {
    saveChange(
      {
        followUp: moment(newDate).toDate(),
      },
      true
    );
  };

  const sameStrategies = () => {
    const strategiesOfFirst =
      record.assets.length > 1
        ? assetStrategies
            .filter((strategy) => strategy.assets.find((asset) => asset.id === record.assets[0].id))
            .map((s) => s.id)
            .sort()
        : [];
    for (let i = 1; i < record.assets.length; i++) {
      const strategiesOfCurrent = assetStrategies
        .filter((strategy) => strategy.assets.find((asset) => asset.id === record.assets[i].id))
        .map((s) => s.id)
        .sort();
      if (!_.isEqual(strategiesOfFirst, strategiesOfCurrent)) {
        return false;
      }
    }
    return true;
  };

  //Every asset has to have all every strategy
  const areSameStrategies = sameStrategies();
  const validFailureModes = areSameStrategies && failureModeOptions.length > 0;
  const placeHolderValue = validFailureModes
    ? "Select..."
    : record.assets.length > 0
    ? failureModeOptions.length > 0
      ? "All assets must have same strategies"
      : "Requires a strategy with failure modes"
    : "At least one asset must be assigned";
  const multiAssetInvalidMessage =
    record.recordType === RecordType.FieldInspection
      ? "Field Inspection records must belong to a single asset."
      : "Valve diagnostic records must belong to a single valve.";

  return (
    <div className="record-details section">
      <Form>
        <Row>
          <Col sm={6}>
            <FormGroup>
              {!isOutageScope && <Label>{RecordFieldLabel.Assets}</Label>}
              {isOutageScope && <Label>Asset</Label>}
              <MultiAssetSelect
                selectedAssets={record.assets}
                onChangeAssets={onChangeAssets}
                linkFromAssetTags={props.userCanViewAssets}
                disabled={!userCanEdit || isClosed}
                invalid={props.assetsInvalid}
                disabledAssetIds={disabledAssetIds}
              />
              <div className="invalid-feedback">{multiAssetInvalidMessage}</div>
            </FormGroup>
          </Col>
          {!isOutageScope && (
            <Col sm={6}>
              <FormGroup>
                <Label>{RecordFieldLabel.FailureMode}</Label>
                <Select
                  value={
                    validFailureModes
                      ? record.failureModeId !== null
                        ? failureModeOptions.find((fmo) => fmo.id === record.failureModeId)
                        : null
                      : null
                  }
                  onChange={(newFailureMode) =>
                    saveChange({ failureModeId: newFailureMode ? newFailureMode.id : null }, true)
                  }
                  formatOptionLabel={(failureMode) => failureMode.model.name}
                  getOptionValue={(failureMode) => failureMode.id.toString()}
                  options={failureModeOptions}
                  className="record-status-select react-select hide-cursor"
                  classNamePrefix="react-select"
                  isDisabled={
                    record.assets.length === 0 ||
                    record.assets[0] === undefined ||
                    !assetStrategies.find(
                      (strategy) => !!strategy.assets.find((asset) => asset.id === record.assets[0].id)
                    ) ||
                    !validFailureModes ||
                    !userCanEdit
                  }
                  isClearable={true}
                  placeholder={placeHolderValue}
                />
              </FormGroup>
            </Col>
          )}
          <Col sm={6}>
            {isOutageScope && (
              <RecordsEventFormField
                showValidity={true}
                validity={!!record.recordsEventId ? getValidValidity() : getInvalidValidity()}
                value={record.recordsEventId}
                setRecordsEventId={(recordsEventId) => saveChange({ recordsEventId })}
                disabledRecordsEventIds={disabledRecordsEventIds}
                showArchived={false}
              />
            )}
            {!isOutageScope && (
              <FormGroup>
                <Label>{RecordFieldLabel.EventDate}</Label>
                {!userCanEdit ? (
                  <Input
                    plaintext
                    readOnly
                    value={record.eventDate === null ? "" : moment(record.eventDate).format(dateFormat)}
                  />
                ) : (
                  <Input
                    className={classNames({
                      "dim-date": record.eventDate === null,
                      "is-invalid": props.eventDateInvalid,
                    })}
                    type="date"
                    placeholder="yyyy-MM-dd"
                    value={record.eventDate === null ? "" : moment(record.eventDate).format(dateFormat)}
                    onChange={(e) =>
                      saveChange(
                        {
                          eventDate: moment(e.target.value).toDate(),
                        },
                        true
                      )
                    }
                    max={moment().format(dateFormat)}
                    readOnly={!userCanEdit}
                  />
                )}
                <div className="invalid-feedback">Record must have an occurrence date after the year 1900.</div>
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={12} xl={6}>
            <FormGroup>
              <Label>{RecordFieldLabel.Status}</Label>
              {props.isComplete || !statusIsSettable || !userCanEdit ? (
                <Input plaintext tag="p">
                  <RecordStatusBadge status={props.serverRecord.model.status} />
                </Input>
              ) : (
                <Select
                  value={{ value: record.status }}
                  onChange={onChangeStatus}
                  formatOptionLabel={(status) => <RecordStatusBadge status={status.value} />}
                  getOptionValue={(status) => status.value}
                  filterOption={(t) => {
                    return statusIsSettable;
                  }}
                  options={StatusValueLabelOptionsWithoutComplete}
                  className="record-status-select react-select hide-cursor"
                  classNamePrefix="react-select"
                  isDisabled={props.isComplete || !statusIsSettable || !userCanEdit}
                />
              )}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={12} xl={6}>
            <FollowUpInput
              date={record.followUp === null ? "" : moment(record.followUp).format(dateFormat)}
              onChangeDate={onChangeFollowUpDate}
              bumpDate={bumpFollowUp}
              readOnly={!userCanEdit}
            />
          </Col>
        </Row>
        {!isOutageScope && (
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>{RecordFieldLabel.CustomerContact}</Label>
                <Input
                  className="form-control"
                  value={record.customerContact || ""}
                  onChange={(e) => {
                    saveChange({
                      customerContact: e.target.value,
                    });
                  }}
                  placeholder="name"
                  readOnly={!userCanEdit}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label>{RecordFieldLabel.RefWo}</Label>
                <Input
                  className="form-control"
                  value={record.refWO || ""}
                  onChange={(e) => {
                    saveChange({
                      refWO: e.target.value,
                    });
                  }}
                  readOnly={!userCanEdit}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {!isValveDiagnostic && !isFieldInspection && !isOutageScope && (
          <Row>
            <Col sm={12}>
              <FormGroup>
                <Label>{RecordFieldLabel.Details}</Label>
                <Textarea
                  className="form-control"
                  value={record.details}
                  onChange={(e) => {
                    saveChange({
                      details: e.target.value,
                    });
                  }}
                  readOnly={!userCanEdit || isClosed}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {isValveDiagnostic && !isOutageScope && (
          <>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label>{RecordFieldLabel.TestCriteria}</Label>
                  {editableAndOpen ? (
                    <RTEditor
                      value={props.testCriteriaValue}
                      onChange={(e: EditorValue) => {
                        props.changeTestCriteriaValue(e);
                        saveChange({ testCriteria: e.toString("html") });
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.testCriteriaValue.toString("html"),
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label>{RecordFieldLabel.TestResults}</Label>
                  {editableAndOpen ? (
                    <RTEditor
                      value={props.testResultsValue}
                      onChange={(e: EditorValue) => {
                        props.changeTestResultsValue(e);
                        saveChange({ testResults: e.toString("html") });
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.testResultsValue.toString("html"),
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {isFieldInspection && (
          <>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label>{RecordFieldLabel.Observations}</Label>
                  {userCanEdit ? (
                    <RTEditor
                      value={props.observationsValue}
                      onChange={(e: EditorValue) => {
                        props.changeObservationsValue(e);
                        saveChange({ observations: e.toString("html") });
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.observationsValue.toString("html"),
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
};
