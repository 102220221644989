import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { Routes, formatRoutePath } from "app/routes";
import { KpiIcon } from "icons/KpiIcon";
import { KpiTemplateDetail, KpiTemplateModel } from "kpis/models";
import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { DeleteKpiTemplateDialog } from "./dialogs/DeleteKpiTemplateDialog";

interface IKpiTemplateActionsProps {
  kpiTemplateDetail: KpiTemplateDetail;
  methods: UseFormReturn<KpiTemplateModel, any>;
  saveChanges: (onValid?: () => void) => void;
}

const pageActions = (kpiTemplateDetail: KpiTemplateDetail) => {
  return createStaticActions((actionDispatcher, userPermissions) => {
    const userCanEditKpiTemplate = userPermissions.userCanEditKpiTemplate;

    return [
      {
        icon: <KpiIcon />,
        label: "Edit name",
        action: () => actionDispatcher.dispatch("renameKpiTemplate"),
        hidden: !userCanEditKpiTemplate,
      },
      {
        icon: kpiTemplateDetail.model.enabled ? <Inventory2OutlinedIcon /> : <EventAvailableIcon />,
        label: kpiTemplateDetail.model.enabled ? "Mark as Archived" : "Mark as Active",
        action: () => actionDispatcher.dispatch("archiveKpiTemplate"),
        hidden: !userCanEditKpiTemplate,
      },
      {
        icon: <DeleteForeverOutlinedIcon />,
        label: "Delete event",
        action: () => actionDispatcher.dispatch("deleteKpiTemplate"),
        hidden: !userCanEditKpiTemplate,
      },
    ];
  });
};

type DialogActionsType = "renameKpiTemplate" | "archiveKpiTemplate" | "deleteKpiTemplate" | null;
const validDialogActions = ["renameKpiTemplate", "archiveKpiTemplate", "deleteKpiTemplate"];

export const KpiTemplateActions = (props: IKpiTemplateActionsProps) => {
  const { kpiTemplateDetail, methods } = props;
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(kpiTemplateDetail), actionDispatcher, [kpiTemplateDetail]);

  const handleAction = useCallback((action: string) => {
    if (action === "archiveKpiTemplate") {
      methods.setValue("enabled", !kpiTemplateDetail.model.enabled);
      props.saveChanges();
    }
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const handleSaveChanges = () => {
    props.saveChanges(closeDialog);
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const { returnTo } = state || {};

  const onDeletedKpiTemplate = async () => {
    if (returnTo) {
      return navigate(returnTo);
    }

    const kpiTemplatesList = formatRoutePath(Routes.KpiTemplates, {
      siteId: kpiTemplateDetail.siteId,
    });
    navigate(kpiTemplatesList);
    closeDialog();
  };

  return (
    <>
      <ActionsDropdownMenu primary actions={actions} />
      {openDialog === "renameKpiTemplate" && (
        <EditFieldDialog
          methods={methods}
          fieldName="name"
          saveChanges={handleSaveChanges}
          dialogTitle="Change KPI template name"
          label="Name"
          confirmButtonText="Change Name"
          onClose={closeDialog}
        />
      )}

      {openDialog === "deleteKpiTemplate" && (
        <DeleteKpiTemplateDialog
          onClose={closeDialog}
          kpiTemplateDetail={kpiTemplateDetail}
          onDeleteKpiTemplate={onDeletedKpiTemplate}
        />
      )}
    </>
  );
};
