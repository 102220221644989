import { CollapseIcon } from "icons/CollapseIcon";
import React, { useState } from "react";
import { Col, Collapse, Row } from "reactstrap";
import { DateInput } from "../../inputs/DateInput";
import { DollarAmountInput } from "../../inputs/DollarAmountInput";
import { LeadTimeInput } from "../../inputs/LeadTimeInput";
import { TextInput } from "../../inputs/TextInput";

interface QuoteDetailsProps {
   userCanEdit: boolean;
}

export const QuoteDetails = (props: QuoteDetailsProps) => {
   const [showAdditionalDetails, setShowAdditionalDetails] = useState<boolean>(false);

   const toggleExpanded = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setShowAdditionalDetails(!showAdditionalDetails);
   };

   return (
      <>
         <Row style={{ padding: "1rem" }}>
            <Col xl="2" lg="4">
               <DollarAmountInput label="Quote amount" field="quoteAmount" userCanEdit={props.userCanEdit} />
            </Col>
            <Col xl="3" lg="4">
               <LeadTimeInput userCanEdit={props.userCanEdit} />
            </Col>
            <Col xl="2" lg="4">
               <TextInput label="Vendor" field="vendor" userCanEdit={props.userCanEdit} />
            </Col>
            <Col xl="5">
               <div className="additional-details-toggle-button-wrapper">
                  <button onClick={toggleExpanded}>
                     <CollapseIcon expanded={showAdditionalDetails} />
                     Additional quote details
                  </button>
               </div>
            </Col>
         </Row>
         <Collapse isOpen={showAdditionalDetails}>
            <Row style={{ padding: "1rem" }}>
               <Col xl="2" lg="4">
                  <TextInput label="Quote number" field="quoteNumber" userCanEdit={props.userCanEdit} />
               </Col>
               <Col xl="3" lg="4">
                  <DateInput label="Quote date" field="quoteDate" userCanEdit={props.userCanEdit} />
               </Col>
               <Col xl="2" lg="4">
                  <DollarAmountInput label="Labour portion" field="labourPortion" userCanEdit={props.userCanEdit} />
               </Col>
            </Row>
         </Collapse>
      </>
   );
};
