import { Box, Paper, Tabs } from "@mui/material";
import { TabPanel } from "app/mui/tabs/TabPanel";
import { TabWithCount } from "app/mui/tabs/TabWithCount";
import { KpiTemplateDetail } from "kpis/models";
import { sumBy } from "lodash";
import React, { useState } from "react";
import { KpiTemplateRecordsTab } from "./KpiTemplateRecordsTab";

interface KpiTemplateTabSectionProps {
  kpiTemplateDetail: KpiTemplateDetail;
  saveChanges: () => void;
}

export const KpiTemplateTabSections: React.FC<KpiTemplateTabSectionProps> = ({ kpiTemplateDetail, saveChanges }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  const recordsCount = sumBy(kpiTemplateDetail.recordTypeUsages, "numberOfRecords");

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <TabWithCount label={"Records"} badgeCount={recordsCount} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
        <KpiTemplateRecordsTab kpiTemplateDetail={kpiTemplateDetail} />
      </TabPanel>
    </Paper>
  );
};
