import {
   useBaseAxiosGetRequestWithAutoRefresh,
   useBaseAxiosPostRequest,
   useBaseAxiosPutRequest,
   useBaseAxiosDeleteRequest,
} from "app";
import { Site } from "sites";
import { CustomDataView, CustomDataViewType } from "./models";

export const customDataViewsMutationUriMatchPatterns = [/api\/customDataViews.*/gi];

export const useGetCustomDataViewsRequest = (type: CustomDataViewType, site: Site | null) => {
   const siteId = site?.id ?? 0;
   const customerId = site?.customerId ?? 0;

   var params = new URLSearchParams();

   params.append("type", type);

   if (siteId) {
      params.append("siteId", siteId.toString());
   }
   if (customerId) {
      params.append("customerId", customerId.toString());
   }

   return useBaseAxiosGetRequestWithAutoRefresh<CustomDataView[]>(
      `/api/customDataViews?${params.toString()}`,
      customDataViewsMutationUriMatchPatterns,
      { useNode: true }
   );
};

export const useCreateCustomDataViewRequest = () => {
   return useBaseAxiosPostRequest<Partial<CustomDataView>, CustomDataView>("/api/customDataViews", { useNode: true });
};

export const useUpdateCustomDataViewRequest = () => {
   return useBaseAxiosPutRequest<CustomDataView, void>("/api/customDataViews", { useNode: true });
};

export const useDeleteCustomDataViewRequest = () => {
   return useBaseAxiosDeleteRequest((id) => `/api/customDataViews/${id}`, { useNode: true });
};
