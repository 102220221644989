import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Divider, Grid, List, ListItem, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { IrisTooltip, useUserContext } from "app";
import { TrafficLightFormItem } from "app/mui/forms/Select/TrafficLightFormItem";
import { IrisColors } from "app/mui/theme";
import React from "react";
import {
  Record as RecordModel,
  VisualIntegrity,
  VisualIntegrityFieldLabel,
  getTrafficLightOption,
} from "records/models";
import TrafficLightCircle from "../TrafficLightCircle";
import InfoIcon from "@mui/icons-material/Info";

interface FieldInspectionReportCardProps {
  record: RecordModel;
}

export const FieldInspectionReportCard = (props: FieldInspectionReportCardProps) => {
  const { record } = props;
  const { userPermissions } = useUserContext();

  const userCanEdit = userPermissions.userCanEditRecord(
    record.assets.map((asset) => asset.assetType),
    record.recordType
  );

  const conditionFields: Array<{
    fieldName: keyof Omit<VisualIntegrity, "overallScore" | "overallIntegrity">;
    label: VisualIntegrityFieldLabel;
    toolTipContent?: string;
  }> = [
    {
      fieldName: "environmental",
      label: VisualIntegrityFieldLabel.Environmental,
      toolTipContent:
        "Any asset condition that has the potential for environmental impact (ex. process spill, loss of containment through flange/packing/hydraulic leakage, or emission release)",
    },
    {
      fieldName: "healthAndSafety",
      label: VisualIntegrityFieldLabel.HealthAndSafety,
      toolTipContent:
        "Any asset condition that can potentially harm equipment, personnel, or property (ex. steam/hazardous gas leaks, line of fire hazards, or excess noise)",
    },
    {
      fieldName: "installation",
      label: VisualIntegrityFieldLabel.Installation,
      toolTipContent:
        "Any asset condition that lacks installation best practices and may affect overall integrity (ex. proper mounting, orientation, or wiring)",
    },
    {
      fieldName: "mechanicalIntegrity",
      label: VisualIntegrityFieldLabel.MechanicalIntegrity,
      toolTipContent:
        "Any asset condition that may affect equipment operation and/or performance (ex. air leaks, bent tubing, scored stems, or any deterioration of mechanical components)",
    },
    {
      fieldName: "obsolescence",
      label: VisualIntegrityFieldLabel.Obsolescence,
      toolTipContent: "The assembly or any component deemed to be obsolete or inactive by the manufacturer",
    },
    {
      fieldName: "taggingSignage",
      label: VisualIntegrityFieldLabel.TaggingSignage,
      toolTipContent: "Availability and legibility of asset tagging and/or asset specifications",
    },
  ];

  const asFoundWidth = "65px";
  const arrowWidth = "35px";

  return (
    <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems={"center"}>
        <Typography variant="h6">REPORT CARD</Typography>
      </Stack>
      <Paper>
        <List sx={{ width: "100%" }}>
          <ListItem alignItems="flex-start">
            <Grid container justifyContent="space-between" columnSpacing={2} alignItems="flex-end">
              <Grid item xs={5}>
                <Typography sx={{ fontSize: "0.75rem", fontWeight: 500, textTransform: "uppercase" }}></Typography>
              </Grid>
              <Grid item xs={false} width={asFoundWidth}>
                <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;found</Typography>
              </Grid>
              <Grid item xs={false} width={arrowWidth} />
              <Grid item xs={true}>
                <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;left</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <Divider component="li" />
          {conditionFields.map((cField, cIndex) => (
            <React.Fragment key={`condition-${cField.fieldName}`}>
              <ListItem>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                    <Grid item xs={5} sx={{ display: "flex" }}>
                      <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                        {cField.label}
                      </Typography>
                      {cField.toolTipContent && (
                        <IrisTooltip title={<>{cField.toolTipContent}</>}>
                          <span>
                            <InfoIcon sx={{ color: IrisColors.gray500, fontSize: "1.1rem" }} />
                          </span>
                        </IrisTooltip>
                      )}
                    </Grid>
                    <Grid item xs={false} width={asFoundWidth}>
                      {userCanEdit ? (
                        <TrafficLightFormItem
                          fieldName={"asFoundVisualIntegrity." + cField.fieldName}
                          syncFieldName={"asLeftVisualIntegrity." + cField.fieldName}
                          filterOptions={cField.label === VisualIntegrityFieldLabel.Obsolescence|| cField.label === VisualIntegrityFieldLabel.TaggingSignage}
                        />
                      ) : (
                        <Tooltip title={getTrafficLightOption(record.asFoundVisualIntegrity[cField.fieldName]).label}>
                          <TrafficLightCircle value={record.asFoundVisualIntegrity[cField.fieldName]} />
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item xs={false} width={arrowWidth}>
                      <ArrowForwardIcon sx={{ fontSize: "20px", color: IrisColors.gray500 }} />
                    </Grid>
                    <Grid item xs={true}>
                      {userCanEdit ? (
                        <TrafficLightFormItem 
                          fieldName={"asLeftVisualIntegrity." + cField.fieldName}
                          filterOptions={cField.label === VisualIntegrityFieldLabel.Obsolescence|| cField.label === VisualIntegrityFieldLabel.TaggingSignage}
                          />
                      ) : (
                        <Tooltip title={getTrafficLightOption(record.asLeftVisualIntegrity[cField.fieldName]).label}>
                          <TrafficLightCircle value={record.asLeftVisualIntegrity[cField.fieldName]} />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              {cIndex < conditionFields.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Paper>
  );
};
