import { TimeUnit } from "common";
import { KpiCommodityUnit, KpiEmissionsType } from "kpis/models";
import * as yup from "yup";

export const KpiOngoingEmissionsReductionValidationSchema = yup.object({
  amountBeforeReduction: yup
    .number()
    .nullable()
    .test("is-valid", "Emissions should be reduced", function (value) {
      const { amountAfterReduction, amountBeforeReduction } = this.parent;
      const amountsReversed = (amountAfterReduction ?? 0) > (amountBeforeReduction ?? 0);
      if (value === null) {
        return false;
      }
      return !amountsReversed;
    })
    .required("Required"),
  amountAfterReduction: yup
    .number()
    .nullable()
    .test("is-valid", "Emissions should be reduced", function (value) {
      const { amountAfterReduction, amountBeforeReduction } = this.parent;
      const amountsReversed = (amountAfterReduction ?? 0) > (amountBeforeReduction ?? 0);
      if (value === null) {
        return false;
      }
      return !amountsReversed;
    })
    .required("Required"),
  reductionUnit: yup.mixed<KpiCommodityUnit>().nullable().required("Required"),
  reductionType: yup.mixed<KpiEmissionsType>().nullable().required("Required"),
  reductionTimeUnit: yup.mixed<TimeUnit>().nullable().required("Required"),
});
