import { SnackbarKey } from "notistack";
import * as React from "react";
import { useContext } from "react";

interface NotificationContextType {
   success: (message: string) => void;
   warning: (message: string) => void;
   error: (message: string) => void;
   info: (message: string, skipClose?: boolean) => SnackbarKey;
   scheduleDismiss: (key: SnackbarKey) => void;
}

export const NotificationContext = React.createContext<NotificationContextType>({} as NotificationContextType);

export function useNotifications() {
   const context = useContext(NotificationContext);

   if (!context) {
      throw "cannot use hook: 'useNotifications' outside of a notificationProvider context";
   }

   return context;
}
