import * as React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { ModalFooter, Button, ModalBody, FormGroup, Label } from "reactstrap";
import { Record, RecordType, RecordTypes } from "records/models";
import Select from "react-select";
import { isSingleAssetRecordType, RecordTypeBadge, useChangeRecordTypeRequest } from "records";
import classNames from "classnames";
import { useState } from "react";

interface Props {
   open: boolean;
   record: Record;
   toggle: () => void;
   recordTypeUpdated: (newRecordType: RecordType) => void;
}

const recordTypeOptions = RecordTypes.filter((rt) => !isSingleAssetRecordType(rt)).map((rt) => ({
   value: rt,
}));

export const ChangeRecordTypeModal: React.FunctionComponent<Props> = (props) => {
   const changeRecordTypeRequest = useChangeRecordTypeRequest();
   const [newRecordType, setNewRecordType] = useState<RecordType | null>(props.record.recordType);

   const isValid = !!newRecordType && !isSingleAssetRecordType(props.record.recordType);

   return (
      <Modal
         className="change-record-type-modal"
         isOpen={props.open}
         toggle={props.toggle}
         container={"#bootstrap-modal-container"}
      >
         <ModalHeader>Change record type</ModalHeader>
         <ModalBody>
            <FormGroup>
               <Label>Record type</Label>
               <Select
                  className={classNames("react-select", "hide-cursor", { "is-invalid": !isValid })}
                  classNamePrefix="react-select"
                  value={newRecordType ? { value: newRecordType } : null}
                  onChange={(change) => {
                     const recordType = change?.value ?? null;
                     setNewRecordType(recordType);
                  }}
                  formatOptionLabel={(recordType) => <RecordTypeBadge type={recordType.value} />}
                  getOptionValue={(option) => option.value}
                  options={recordTypeOptions}
               />
               <div className="invalid-feedback">Select a record type.</div>
            </FormGroup>
         </ModalBody>
         <ModalFooter>
            <Button
               color="primary"
               onClick={() =>
                  changeRecordTypeRequest
                     .call({ recordId: props.record.id, recordType: newRecordType! })
                     .then(() => window.location.reload())
               }
               disabled={changeRecordTypeRequest.loading || !isValid}
            >
               Update type
            </Button>
            <Button color="secondary" onClick={() => props.toggle()} disabled={changeRecordTypeRequest.loading}>
               Cancel
            </Button>
         </ModalFooter>
      </Modal>
   );
};
