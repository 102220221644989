import {
  PackagedSolutionListViewModel,
  UpdatePackagedSolutionModel,
  CreatePackagedSolutionModel,
  PackagedSolutionModel,
  OperatingSystemModel,
  AddOperatingSystemModel,
  PackagedSolutionDetailViewModel,
  AddPackagedSolutionEquipmentModel,
  PackagedSolutionEquipmentModel,
} from "@bluemarvel/iris-common/model";
import {
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosGetRequest,
  useBaseAxiosPutRequest,
  useBaseAxiosPostRequest,
} from "app";
import { ReportCardPackagedSolution } from "./mui/reportCardPackageSolution";

export const packagedSolutionsMutationUriMatchPatterns = [/api\/packagedSolutions$/gi];
export const operatingSystemsMutationUriMatchPatterns = [/api\/packagedSolutions\/operatingSystem$/gi];
export const equipmentsMutationUriMatchPatterns = [/api\/packagedSolutions\/equipments$/gi];

export const useGetPackagedSolutionByIdRequest = (id: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<PackagedSolutionDetailViewModel>(
    `/api/packagedSolutions/detail/${id}`,
    packagedSolutionsMutationUriMatchPatterns,
    {
      useNode: true,
    }
  );

export const useGetPackageSolutionsRequest = (siteId: number) =>
  useBaseAxiosGetRequest<PackagedSolutionListViewModel[]>(`api/packagedSolutions/by/site/${siteId}`, { useNode: true });

export const useGetPackagedSolutionsRequest = (siteId: number) =>
  useBaseAxiosGetRequest<PackagedSolutionListViewModel[]>(`api/packagedSolutions/by/site/${siteId}`, { useNode: true });

export const useCreatePackagedSolutionRequest = () =>
  useBaseAxiosPostRequest<CreatePackagedSolutionModel, PackagedSolutionModel>(`api/packagedSolutions`, {
    useNode: true,
  });

export const useUpdateReportCardPackagedSolution = () =>
  useBaseAxiosPutRequest<ReportCardPackagedSolution>(`api/reportCards/PackagedSolution`, {
    useNode: true,
  });

export const useUpdatePackageSolutionsRequest = () =>
  useBaseAxiosPutRequest<UpdatePackagedSolutionModel, void>(`api/packagedSolutions`, { useNode: true });

export const useGetOperatingSystemsRequest = (packagedSolutionId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<OperatingSystemModel[]>(
    `api/packagedSolutions/${packagedSolutionId}/operatingSystems`,
    operatingSystemsMutationUriMatchPatterns,
    {
      useNode: true,
    }
  );

export const useAddOperatingSystemRequest = () =>
  useBaseAxiosPostRequest<AddOperatingSystemModel, OperatingSystemModel>(`api/packagedSolutions/operatingSystem`, {
    useNode: true,
  });

export const useUpdateOperatingSystemRequest = () =>
  useBaseAxiosPutRequest<OperatingSystemModel, void>(`api/packagedSolutions/operatingSystem`, { useNode: true });

export const useAddPackagedSolutionEquipmentRequest = () =>
  useBaseAxiosPostRequest<AddPackagedSolutionEquipmentModel, PackagedSolutionEquipmentModel>(
    `api/packagedSolutions/equipment`,
    {
      useNode: true,
    }
  );

export const useGetPackagedSolutionEquipmentsRequest = (packagedSolutionId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<PackagedSolutionEquipmentModel[]>(
    `api/packagedSolutions/${packagedSolutionId}/equipments`,
    equipmentsMutationUriMatchPatterns,
    {
      useNode: true,
    }
  );
