import React, { useEffect } from "react";
import { AssetDigest } from "assets";
import { RecordDigest } from "records";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Select from "react-select";
import { FormGroup, Label, Input, ModalFooter, Button } from "reactstrap";
import moment from "moment";
import { dateFormat } from "common/formats";
import classNames from "classnames";
import _ from "lodash";
import { SelectedKpiValues } from "common/SelectedKpiValues";
import { KpiValueDigest } from "kpis";
import { RecordsEventFormField } from "records/bs/fields/RecordsEventFormField";
import { useState } from "react";
import { getInvalidValidity, getValidValidity, Validity } from "common";
import { useNotifications } from "app";
import { SummaryDigest, SummaryStatus, SummaryType } from "summaries/models";
import { useCreateSummaryRequest } from "summaries/api";
import { AssetSelector } from "common/AssetSelector";
import { recordBeforeSummaryDate } from "summaries/utilities";
import { SummaryRecordSelector } from "./SummaryRecordSelector";

interface Props {
  siteId: number;
  toggle: () => void;
  createdSummary: (summary: SummaryDigest, viewSummary: boolean) => void;
  initialRecords?: RecordDigest[];
  initalAssets?: AssetDigest[];
  initialKpiValues?: KpiValueDigest[];
  summaryType?: SummaryType;
}

interface SummaryValidity {
  summaryType: Validity;
  title: Validity;
  summaryDate: Validity;
  recordsEventId: Validity;
}

export const CreateSummaryModal: React.FunctionComponent<Props> = (props) => {
  const createSummaryRequest = useCreateSummaryRequest();

  const [summaryType, setSummaryType] = useState<SummaryType | null>(props.summaryType || null);
  const [assets, setAssets] = useState<AssetDigest[]>(props.initalAssets || []);
  const [records, setRecords] = useState<RecordDigest[]>(props.initialRecords || []);
  const [kpiValues, setKpiValues] = useState<KpiValueDigest[]>(props.initialKpiValues || []);
  const [title, setTitle] = useState<string>("");
  const [summaryDate, setSummaryDate] = useState<string>(moment().format(dateFormat));
  const [recordsEventId, setRecordsEventId] = useState<number | null>(null);
  const [validity, setValidity] = useState<SummaryValidity>({
    summaryType: getValidValidity(),
    summaryDate: getValidValidity(),
    recordsEventId: getValidValidity(),
    title: getValidValidity(),
  });
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const notifications = useNotifications();

  // validate
  useEffect(() => {
    setValidity({
      summaryType: summaryType !== null ? getValidValidity() : getInvalidValidity(),
      title: title.trim().length > 0 ? getValidValidity() : getInvalidValidity(),
      summaryDate: moment(summaryDate).isValid() ? getValidValidity() : getInvalidValidity(),
      recordsEventId:
        summaryType !== SummaryType.OutageScope || !!recordsEventId ? getValidValidity() : getInvalidValidity(),
    });
  }, [summaryType, title, summaryDate, recordsEventId]);

  const createSummary = async (openNewSummary: boolean) => {
    setShowValidationErrors(true);
    const valid = !_.toPairs(validity).some((v) => !v[1].valid);
    if (!valid) {
      return;
    }

    await createSummaryRequest
      .call({
        siteId: props.siteId,
        summaryType: summaryType!,
        title: title,
        summaryDate: moment(summaryDate).toDate(),
        status: SummaryStatus.Open,
        assets: assets,
        records,
        kpiValues: kpiValues,
        recordsEventId: recordsEventId,
      })
      .then((newSummary) => {
        if (!!newSummary) {
          props.createdSummary(newSummary, openNewSummary);
          notifications.success("Summary created.");
        }
      });
  };

  const creatingBulkValveHealthSummary = summaryType === SummaryType.BulkValveHealthType;

  return (
    <Modal
      className="create-summary-modal"
      isOpen={true}
      toggle={props.toggle}
      container={"#bootstrap-modal-container"}
    >
      <ModalHeader>Create summary</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Summary type</Label>
          <Select
            className={classNames("react-select", "hide-cursor", {
              "is-invalid": showValidationErrors && !validity.summaryType.valid,
            })}
            classNamePrefix="react-select"
            value={summaryType ? { value: summaryType } : null}
            onChange={(change) => setSummaryType(change?.value || null)}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.value}
            options={[{ value: SummaryType.General }, { value: SummaryType.BulkValveHealthType }]
              .concat(kpiValues.length > 0 ? [{ value: SummaryType.KPI }] : [])
              .concat([{ value: SummaryType.OutageScope }])}
            isDisabled={props.summaryType === SummaryType.KPI || !!(props.initalAssets || props.initialRecords)}
          />
          <div className="invalid-feedback">Select a summary type.</div>
        </FormGroup>

        <FormGroup>
          <Label>Title</Label>
          <Input
            className={classNames({ "is-invalid": showValidationErrors && !validity.title.valid })}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required={true}
          />
          <div className="invalid-feedback">Enter a summary title.</div>
        </FormGroup>

        <FormGroup>
          <Label>Summary date</Label>
          <Input
            className={classNames({
              "dim-date": summaryDate === "" || summaryDate === "Invalid date",
              "is-invalid": showValidationErrors && !validity.summaryDate.valid,
            })}
            type="date"
            value={summaryDate}
            onChange={(e) => setSummaryDate(e.target.value)}
          />
          <div className="invalid-feedback">Enter a summary date.</div>
        </FormGroup>

        {summaryType === SummaryType.General && (
          <AssetSelector
            assets={assets}
            siteId={props.siteId}
            parentDescriptor="summary"
            readOnly={false}
            addAsset={(asset) => setAssets(assets.concat(asset))}
            removeAsset={(asset) => setAssets(assets.filter((a) => a.id !== asset.id))}
            hideHint={true}
          />
        )}

        {(summaryType === SummaryType.General || summaryType === SummaryType.BulkValveHealthType) && (
          <SummaryRecordSelector
            records={records}
            siteId={props.siteId}
            parentDescriptor="summary"
            userCanEdit={true}
            userCanViewRecords={false}
            addRecord={(record) => setRecords(records.concat(record))}
            removeRecord={(record) => setRecords(records.filter((r) => r.id !== record.id))}
            valveDiagnosticsOnly={summaryType === SummaryType.BulkValveHealthType}
            selectingForBulkValveHealthSummary={creatingBulkValveHealthSummary}
            createSummaryMode={creatingBulkValveHealthSummary}
            hideHint={true}
            recordFilter={(existingRecordDetails) =>
              creatingBulkValveHealthSummary
                ? (r) =>
                    existingRecordDetails.every((ir) => ir.model.assets[0].id !== r.model.assets[0].id) &&
                    r.model.assets.length > 0 &&
                    recordBeforeSummaryDate(r.model, summaryDate)
                : (r) => recordBeforeSummaryDate(r.model, summaryDate)
            }
          />
        )}

        {summaryType === SummaryType.KPI && (
          <SelectedKpiValues
            kpiValues={kpiValues}
            remove={(kpiValue) => setKpiValues(kpiValues.filter((skv) => skv.id !== kpiValue.id))}
            ineligibleBecause={(kpiValue) =>
              kpiValue.calculatedSavings === 0
                ? "No calculated savings."
                : kpiValue.kpiType === "Legacy"
                ? "Must be converted to a new KPI type"
                : null
            }
          />
        )}

        {summaryType === SummaryType.OutageScope && (
          <RecordsEventFormField
            setRecordsEventId={setRecordsEventId}
            showValidity={showValidationErrors}
            validity={validity.recordsEventId}
            value={recordsEventId}
            showArchived={true}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={async () => createSummary(true)} disabled={createSummaryRequest.loading}>
          Create summary and open
        </Button>
        <Button color="secondary" onClick={async () => createSummary(false)} disabled={createSummaryRequest.loading}>
          Create
        </Button>
        <Button color="secondary" onClick={() => props.toggle()} disabled={createSummaryRequest.loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
