import { Site } from "../../sites/models";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCrossSiteRoutePath, Routes, useDefaultCustomerSite, useSiteIdFromRouteWithHistory, useUserContext } from "app";
import { useGetSiteByIdRequest } from "../../sites/api";
import { createContext, FunctionComponent, ReactNode, useContext, useEffect } from "react";
import { makeSiteContextRouteNavigator } from "app/SiteNavigator";
import { LinearProgress } from "@mui/material";

export type CurrentSiteWithNavigation = ReturnType<typeof makeSiteContextRouteNavigator> | null;
export interface SiteContextProps {
  currentSite: Site | null;
  currentSiteWithNavigation: CurrentSiteWithNavigation;
}

export const SiteContext = createContext<SiteContextProps>({
  currentSite: null,
  currentSiteWithNavigation: null,
});

export const SiteContextProvider: FunctionComponent<{
  children?: ReactNode;
}> = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const siteId = useSiteIdFromRouteWithHistory();
  const { data: currentSite, loading, error } = useGetSiteByIdRequest(siteId);
  const defaultCustomerSites = useDefaultCustomerSite(null);
  const { currentUserLoading } = useUserContext();

  useEffect(() => {
    if (!!error && !defaultCustomerSites.loading) {
      const importedView = searchParams.get("import");
      // cross site if user has no access to original site of view import
      if (importedView && error.response?.status === 403 && defaultCustomerSites.customerSites.length > 0) {
        const crossSiteRoutePath = getCrossSiteRoutePath(defaultCustomerSites.customerSites[0].id, window.location.pathname);
        navigate(`${crossSiteRoutePath}?import=${importedView}`);
      } else {
        navigate(Routes.Home);
        navigate(0);
      }
    }
  }, [!!error, defaultCustomerSites.loading]);

  return (
    <SiteContext.Provider
      value={{ currentSite, currentSiteWithNavigation: makeSiteContextRouteNavigator(currentSite) }}
    >
      {!!error ? (
        error.message
      ) : loading || currentUserLoading || (!!siteId && siteId !== currentSite?.id) ? (
        <LinearProgress />
      ) : (
        props.children
      )}
    </SiteContext.Provider>
  );
};

export const useSiteContext = () => useContext(SiteContext);

export const withSiteContext = <P extends SiteContextProps>(WrappedComponent: React.ComponentType<P>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithSiteContextProps = (props: Omit<P, keyof SiteContextProps>) => {
    const { currentSite } = useContext(SiteContext);

    return <WrappedComponent {...(props as P)} siteContext={currentSite} />;
  };

  ComponentWithSiteContextProps.displayName = `withSiteContext(${displayName})`;

  return ComponentWithSiteContextProps;
};
