import { Box, Paper, Stack, Typography } from "@mui/material";
import { Pie, PieTooltipProps } from "@nivo/pie";
import { CustomTooltip } from "app/mui/charts/common/CustomTooltip";
import { RecordCountByStatus } from "dashboard/models";
import _ from "lodash";
import customColors from "assets/customColors";
import { useMemo, useRef } from "react";
import { GetOpenOrClosedStatus, statusToDisplayName } from "records";

interface RecordStatusCountsPieChartProps {
  recordCounts: RecordCountByStatus[];
}

const RecordStatusColorMap = {
  Closed: customColors.smalt,
  Open: customColors.robinsEggBlue,
};

const getOpenAndClosedRecordCounts = (recordCounts: RecordCountByStatus[]) => {
  const groupedData = _.groupBy(recordCounts, (r) => GetOpenOrClosedStatus(r.status));

  const openStats = (groupedData["Open"] || []).reduce(
    (a, b) => ({
      ...a,
      value: a.value + b.value,
      breakdown: [...a["breakdown"], { key: statusToDisplayName(b.status), value: b.value }],
    }),
    { status: "Open", value: 0, breakdown: [] } as any
  );

  const open = {
    id: "Open",
    label: "Open",
    value: openStats.value,
    color: RecordStatusColorMap.Open,
    status: "Open",
    breakdown: openStats["breakdown"],
  };

  const closedStats = (groupedData["Closed"] || []).reduce(
    (a, b) => ({
      ...a,
      value: a.value + b.value,
      breakdown: [...a["breakdown"], { key: statusToDisplayName(b.status), value: b.value }],
    }),
    { status: "Closed", value: 0, breakdown: [] } as any
  );

  const closed = {
    id: "Closed",
    label: "Closed",
    value: closedStats.value,
    color: RecordStatusColorMap.Closed,
    status: "Closed",
    breakdown: closedStats["breakdown"],
  };

  return [open, closed];
};

export const RecordStatusCountsPieChart = (props: RecordStatusCountsPieChartProps) => {
  const data = useMemo(() => getOpenAndClosedRecordCounts(props.recordCounts), []);

  const orderedColors: string[] = [];

  for (var i = 0; i < data.length; i++) {
    orderedColors.push(data[i].color);
  }

  return (
    <Box
      sx={{
        "& div": {
          display: "flex",
          justifyContent: { xs: "flex-start", lg: "center" },
          alignItems: { xs: "flex-start", lg: "center" },
        },
      }}
    >
      <PieChart data={data} colors={orderedColors} />
    </Box>
  );
};

const theme = {
  legends: {
    text: {
      fontWeight: "bold",
      fontSize: "12px",
    },
  },
};

const PieChart = ({ data, colors }) => {
  const ref = useRef<HTMLElement>();
  return (
    <Box ref={ref}>
      <Pie
        width={320}
        height={250}
        data={data}
        theme={theme}
        margin={{ top: 40, right: 0, bottom: 50, left: -140 }}
        startAngle={-14}
        innerRadius={0.7}
        padAngle={0}
        cornerRadius={0}
        activeOuterRadiusOffset={2}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={colors}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 12,
            symbolSize: 20,
            itemDirection: "left-to-right",
          },
        ]}
        layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
        tooltip={(params) => (
          <CustomTooltip containerRef={ref}>
            <Tooltip {...params} allData={data} />
          </CustomTooltip>
        )}
      />
    </Box>
  );
};

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  dataWithArc.forEach((datum: any) => {
    total += datum.value;
  });

  return (
    <g>
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="bottom"
        style={{
          fill: "#333",
          fontSize: "24px",
          fontWeight: 600,
        }}
      >
        {total}
      </text>
      <text
        x={centerX}
        y={centerY + 20}
        textAnchor="middle"
        dominantBaseline="top"
        style={{
          fill: "#777",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        Total
      </text>
    </g>
  );
};

interface TooltipProps extends PieTooltipProps<unknown> {
  allData: (RecordCountByStatus & { breakdown: { key: string; value: string }[] })[];
}

const Tooltip = (props: TooltipProps) => {
  const breakdown = _.sortBy(props.allData.find((a) => a.status === props.datum.id)?.breakdown, (x) => x.key);

  return (
    <Paper sx={{ width: 200, background: "white", p: 2, pointerEvents: "none" }}>
      <Stack justifyContent="start" alignItems="start">
        {breakdown?.map((item) => (
          <Typography textAlign="left" key={item.key}>
            {item.key}: {item.value}
          </Typography>
        ))}
      </Stack>
    </Paper>
  );
};
