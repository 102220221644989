import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDeleteKpiValueRequest } from "kpis";
import { KpiValueDetail, getKpiValueFullDisplayName, parseKpiId } from "kpis/models";

interface DeleteKpiDialogProps {
  onClose: () => void;
  kpiValue: KpiValueDetail;
}

export const DeleteKpiDialog = (props: DeleteKpiDialogProps) => {
  const deleteKpiValueRequest = useDeleteKpiValueRequest();

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Delete &quot;{getKpiValueFullDisplayName(props.kpiValue.model)}&quot;?</DialogTitle>
      <DialogContent>
        <Typography variant="body2">Are you sure you want to delete this KPI?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} disabled={deleteKpiValueRequest.loading}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteKpiValueRequest.call(parseKpiId(props.kpiValue.id)).then(() => {
              props.onClose();
            });
          }}
          color="error"
          variant="contained"
          disabled={deleteKpiValueRequest.loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
