import { ReactNode, useEffect, useState } from "react";
import moment from "moment";
import { useSiteIdFromRoute } from "app";
import { dateFormat } from "common/formats";
import { Option } from "../forms/AutocompleteFormItem";
import _ from "lodash";
import { useGetFilteredRecordsEventDigestsRequest } from "recordsEvents";

interface EventsLoaderProps {
  disabledRecordsEventIds?: number[] | null;
  children: (params: { options: Option[] }) => ReactNode;
}

export const EventsLoader = (props: EventsLoaderProps) => {
  const [results, setResults] = useState<Option[]>([]);

  const siteId = useSiteIdFromRoute()!;
  const { data, call } = useGetFilteredRecordsEventDigestsRequest(siteId);

  useEffect(() => {
    call({ isArchived: false, siteId });
  }, []);

  useEffect(() => {
    if (!data) return;

    const options = _.orderBy(data ?? [], (re) => re.startDate, "desc")
      .filter((re) => (!!props.disabledRecordsEventIds ? !props.disabledRecordsEventIds?.includes(re.id) : true))
      .map((re) => ({
        id: re.id,
        label: `${re.name} (${moment(re.startDate).format(dateFormat)})`,
      }));

    setResults(options);
  }, [data]);

  return <>{props.children({ options: results })}</>;
};
