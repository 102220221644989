import { useState, useMemo } from "react";
import ReactQuill from "react-quill";
import { Box } from "@mui/material";
import { IrisColors } from "../theme";
import { FormItemLayout } from "./FormItemLayout";
import { useController } from "react-hook-form";

import "react-quill/dist/quill.snow.css";

interface RichTextEditorProps {
  label?: string;
  hint?: string;
  fieldName?: string;
  disabled?: boolean;
}

export const RichTextEditor = (props: RichTextEditorProps) => {
  const modules = useMemo(
    () => ({
      toolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ size: [] }]
      ],
      clipboard: {
        matchVisual: false
      }
    }),
    []
  );

  const quillStyles = {
    "& .ql-toolbar.ql-snow": {
      border: "none !important",
    },
    "& .ql-container.ql-snow": {
      border: "none !important",
      background: `${IrisColors.gray100} !important`,
      borderTop: `solid 1px ${IrisColors.gray300} !important`,
      minHeight: "150px",
    },
    "& .ql-editor ol": {
      counterReset: "list-item",
    }
  };

  const { field, fieldState } = useController({ name: props.fieldName || "" });
  const [quillValue, setQuillValue] = useState(field.value);
  const quillOnChange = (newValue) => {
    setQuillValue(newValue);
    field.onChange(newValue);
  };

  return props.disabled ? (
    <Box
      sx={{
        width: "100%",
        borderRadius: "4px",
        border: `solid 1px ${IrisColors.gray300}`,
        ...quillStyles,
      }}
    >
      <ReactQuill readOnly theme="snow" value={field.value} modules={{ toolbar: null }} />
    </Box>
  ) : (
    <FormItemLayout label={props.label} hint={props.hint} controlled error={fieldState.error?.message}>
      {(inputProps) => (
        <Box
          sx={{
            width: "100%",
            borderRadius: "4px",
            border: `solid 1px ${IrisColors.gray300}`,
            ...quillStyles,
          }}
        >
          <ReactQuill
            theme="snow"
            modules={modules}
            value={quillValue}
            onChange={quillOnChange}
            {...inputProps}
          />
        </Box>
      )}
    </FormItemLayout>

  );
};
