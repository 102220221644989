import { TrafficLightValue } from "records";

export interface MotorOperatedPerformance {
  setPointTracking: TrafficLightValue | null;
  overshoot: TrafficLightValue | null;
  offset: TrafficLightValue | null;
  motorSpeed: TrafficLightValue | null;
  strokeTime: TrafficLightValue | null;
}
export interface MotorOperatedPerformanceValidity {
  valid: boolean;
  setPointTrackingValid: boolean;
  overshootValid: boolean;
  cyclingValid: boolean;
  speedValid: boolean;
  offsetValid: boolean;
}

export enum MotorOperatedPerformanceFieldLabel {
  SetPointTracking = "Set point tracking",
  Overshoot = "Overshoot",
  Offset = "Offset",
  MotorSpeed = "Motor speed",
  StrokeTime = "Stroke time",
}

export enum MotorOperatedPerformanceTooltip {
  SetPointTracking = "Assess the travel feedback and its ability to reach and maintain set point.",
  Overshoot = "Upon step change, assess whether the travel feedback bypasses set point and check recovery behavior.",
  Offset = "Assess the differential between travel feedback and set point throughout step test or normal operating conditions.",
  MotorSpeed = "Assess the rotation speed of the electric motor to ensure optimal opening/closing performance.",
  StrokeTime = "Assess the timing for full rated travel and/or specific performance-based steps.",
}
