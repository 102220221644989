import { Tooltip } from "@mui/material";
import { KpiValueTotal } from "kpis";
import _ from "lodash";
import { FunctionComponent} from "react";

export const KpiValueTotalCellValue: FunctionComponent<{ kpiValueTotal: KpiValueTotal }> = (props) => (
   <Tooltip title={props.kpiValueTotal.blurb}>
      <span>
         {props.kpiValueTotal.total} {_.lowerFirst(props.kpiValueTotal.unit)}
      </span>
   </Tooltip>
);
