import React, { useCallback, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { GridActionsCellItem, GridRowModel, useGridApiRef } from "@mui/x-data-grid-pro";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { useDeleteSiteAreaRequest, useUpdateSiteAreaRequest } from "sites/manage/api";
import { createStaticColumns, useColumns } from "app/mui/tables/utils/createStaticColumns";
import { Site, SiteArea } from "sites/models";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { useModal } from "app/mui/common/useModal";
import { SimpleDeleteModal } from "app/mui/common/modals/SimpleDeleteModal";

interface AreasTabProps {
  site: Site;
}

const columnDef = createStaticColumns((actionDispatcher) => [
  {
    field: "name",
    headerName: "Area name",
    width: 200,
    editable: true,
  },
  {
    field: "location",
    headerName: "Area GPS coordinates",
    width: 200,
    editable: true,
  },
  {
    type: "actions",
    field: "actions",
    width: 60,
    hideable: false,
    getActions: (params: any) => [
      <GridActionsCellItem
        icon={<DeleteRoundedIcon />}
        label={"Remove area"}
        onClick={() => {
          actionDispatcher.dispatch("remove-area", params);
        }}
        showInMenu
        placeholder={undefined}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      />,
    ],
  },
]);

export const AreasTab = (props: AreasTabProps) => {
  const apiRef = useGridApiRef();

  const actionDispatcher = useActionDispatcher();
  const columns = useColumns(columnDef, actionDispatcher);

  const { modalProps, openModal } = useModal();

  const removeAreasRequest = useDeleteSiteAreaRequest(props.site.id);
  const updateAreaRequest = useUpdateSiteAreaRequest(props.site.id);

  const areaIdToDelete = useRef<number>();

  const onActionReceived = useCallback((action: string, params: any) => {
    if (action === "remove-area") {
      areaIdToDelete.current = params.id;
      openModal();
    }
  }, []);

  useEffect(() => {
    actionDispatcher.subscribe(onActionReceived);
    return () => actionDispatcher.unsubscribe(onActionReceived);
  }, []);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel) => {
      const originalArea = props.site.areas.find((a) => a.id === newRow.id);

      const newArea = {
        ...originalArea,
        ...newRow,
      } as SiteArea;
      updateAreaRequest.call(newArea);
      return newArea;
    },
    [updateAreaRequest]
  );

  const handleUpdateError = React.useCallback((error) => {
    console.log(error);
  }, []);

  const confirmDelete = () => {
    const areaId = areaIdToDelete.current;
    if (areaId !== undefined) {
      removeAreasRequest.call(areaId);
    }

    areaIdToDelete.current = undefined;
  };

  return (
    <Box sx={{ height: "500px", my: 1 }}>
      <StripedDataGrid
        apiRef={apiRef}
        columns={columns}
        rows={props.site.areas}
        experimentalFeatures={{ newEditingApi: true }}
        editMode="row"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleUpdateError}
        initialState={{
          pinnedColumns: {
            right: ["actions"],
          },
        }}
      />
      <SimpleDeleteModal
        {...modalProps}
        title="Remove area?"
        confirmButtonText="Remove area"
        onConfirm={confirmDelete}
      />
    </Box>
  );
};
