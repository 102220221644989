// colors are named with https://chir.ag/projects/name-that-color/#6195ED

const customColors = {
	// reds
	frenchRose: "#ED4B82",
	amaranth: "#E91E63",
	illusion: "#F6A5C1",
	redBerry: "#910000",
	// oranges
	pizzaz: "#FF8F00",
	// yellows
	lightningYellow: "#FBC02D",
	roti: "#CAA947",
	// greens
	bilbao: "#29750D",
	brightTurquoise: "#03F0B1",
	forestGreen: "#2E7D32",
	// blues
	aeroBlue: "#C5FCE6",
	congressBlue: "#004B8D",
	lynch: "#607D8B",
	robinsEggBlue: "#00BCD4",
	smalt: "#003C8F",
	// purples
	seance: "#7B1FA2",
	// browns
	spicyMix: "#915A44",
	// grayscale
	doveGray: "#666666",
	nobel: "#B5B5B5",
};

export default customColors;
