import * as React from "react";
import { Badge, BadgeProps } from "app/mui/Badge";

interface IPackagedSolutionTypeProps extends Omit<BadgeProps, "text"> {
  packagedSolutionType: string;
}

export const PackagedSolutionTypeBadge = ({ packagedSolutionType, ...badgeProps }: IPackagedSolutionTypeProps) => {
  return <Badge text={packagedSolutionType} {...badgeProps} />;
};
