import { AutocompleteFormItem, AutocompleteFormItemProps, Option } from "app/mui/forms/AutocompleteFormItem";
import { TimeUnit, TimeUnits } from "common";

export type TimeUnitMode = "Per" | "Plural";

export interface TimeUnitFormItemProps extends Omit<AutocompleteFormItemProps<false, false, Option>, "options"> {
  readOnly?: boolean;
  limitTo?: TimeUnit[];
  mode: TimeUnitMode;
}

const TimeUnitSingularOptions = TimeUnits.map((u) => {
  return { value: u, label: u as string };
});
const TimeUnitPluralOptions = TimeUnits.map((u) => {
  return { value: u, label: `${u}s` };
});

export function TimeUnitFormItem(props: TimeUnitFormItemProps) {
  const { readOnly, autocompleteProps, ...rest } = props;

  const allOptions = props.mode === "Plural" ? TimeUnitPluralOptions : TimeUnitSingularOptions;
  const filteredOptions = !!props.limitTo ? allOptions.filter((o) => props.limitTo!.indexOf(o.value) >= 0) : allOptions;
  const options = filteredOptions.map((x) => ({ id: x.value, label: x.label }));

  return (
    <AutocompleteFormItem
      options={options}
      autocompleteProps={{ ...autocompleteProps, disabled: readOnly }}
      {...rest}
    />
  );
}
