import { NotEnoughData } from "app/mui/common/placeholders/NotEnoughDataYetMessage";
import { YearlyKpiSavingsFact } from "dashboard/models";
import _ from "lodash";
import { FunctionComponent, useMemo, useState } from "react";
import { KpiSavingsBarChart, KpiSavingsBarChartData } from "./KpiSavingsBarChart";
import { FormControlLabel, Switch } from "@mui/material";

interface KpiSavingsWidgetProps {
  data: YearlyKpiSavingsFact[];
}

type KpiSavingsBarChartBar = Omit<KpiSavingsBarChartData, "year">;

const getData = (yearlyRecordsCounts: YearlyKpiSavingsFact[], cumulative: boolean) => {
  const yearlyCountsDict: { [year: number]: KpiSavingsBarChartBar } = {};

  _.orderBy(_.uniq(yearlyRecordsCounts.map((d) => d.year)), (x) => x).forEach((year) => {
    yearlyCountsDict[year] = {} as KpiSavingsBarChartBar;
  });

  if (!cumulative) {
    yearlyRecordsCounts.forEach(({ year, approved, pending }) => {
      yearlyCountsDict[year]["Approved"] = (yearlyCountsDict[year]["Approved"] || 0) + approved;
      yearlyCountsDict[year]["Pending"] = (yearlyCountsDict[year]["Pending"] || 0) + pending;
    });
  } else {
    const startYear = Math.min(...yearlyRecordsCounts.map((d) => d.year));
    const maxYear = Math.max(...yearlyRecordsCounts.map((d) => d.year)) ?? startYear;
    let i = startYear;
    let approvedAcc = 0;
    let pendingAcc = 0;
    while (i <= maxYear) {
      const yearValues = yearlyRecordsCounts.filter((d) => d.year === i);

      const { approved, pending } = yearValues.reduce(
        (acc, { approved, pending }) => {
          acc.approved += approved;
          acc.pending += pending;
          return acc;
        },
        { approved: 0, pending: 0 }
      );

      yearlyCountsDict[i]["Approved"] = approvedAcc += approved;
      yearlyCountsDict[i]["Pending"] = pendingAcc += pending;
      i++;
    }
  }

  return Object.keys(yearlyCountsDict).map((key) => {
    return {
      year: key,
      ...yearlyCountsDict[key],
    };
  });
};

export const KpiSavingsWidget: FunctionComponent<KpiSavingsWidgetProps> = (props) => {
  const [cumulative, setCumulative] = useState<boolean>(false);
  const data = useMemo(() => getData(props.data, cumulative), [props.data, cumulative]);

  // if (data.length === 0) {
  //   return <NotEnoughData />;
  // }
  if (data.filter(({ Approved, Pending }) => Approved > 0 || Pending > 0).length === 0) {
    return <NotEnoughData />;
  }

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <FormControlLabel
          control={<Switch color="secondary" checked={cumulative} onChange={() => setCumulative(!cumulative)} />}
          label="Cumulative"
        />
      </div>
      <KpiSavingsBarChart data={data} />;
    </>
  );
};
