import * as React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { StandardModalFooter } from "app/bs/StandardModalFooter";
import { Checkbox } from "common";
import { ApplicationDetailView } from "./models";
import { useState } from "react";
import { useApplicationReportUrlBuilder } from "common/reports";

interface Props {
   application: ApplicationDetailView;
   open: boolean;
   toggle: () => void;
}

export const ConfigureApplicationReportModal: React.FunctionComponent<Props> = (props) => {
   const [includeAssets, setIncludeAssets] = useState(true);
   const [includeFailureModes, setIncludeFailureModes] = useState(true);
   const [includeMitigations, setIncludeMitigations] = useState(true);
   const [includeMitigationImplementations, setIncludeMitigationImplementations] = useState(true);
   const [includePictures, setIncludePictures] = useState(true);
   const [appendPdfAttachments, setAppendPdfAttachments] = useState(true);

   const reportUrl = useApplicationReportUrlBuilder({
      id: props.application.id,
      pdf: true,
      appendPdfAttachments,
      includeAssets,
      includeFailureModes,
      includeMitigations,
      includeMitigationImplementations,
      includePictures,
   });

   return (
      <Modal isOpen={props.open} container={"#bootstrap-modal-container"}>
         <ModalHeader>Report options</ModalHeader>
         <ModalBody>
            <Checkbox value={includeAssets} onClick={() => setIncludeAssets(!includeAssets)}>
               Include assets
            </Checkbox>
            <Checkbox value={includeFailureModes} onClick={() => setIncludeFailureModes(!includeFailureModes)}>
               Include failure modes
            </Checkbox>
            <Checkbox value={includeMitigations} onClick={() => setIncludeMitigations(!includeMitigations)}>
               Include mitigations
            </Checkbox>
            <Checkbox
               value={includeMitigationImplementations}
               onClick={() => setIncludeMitigationImplementations(!includeMitigationImplementations)}
            >
               Include mitigation implementations
            </Checkbox>
            <Checkbox value={includePictures} onClick={() => setIncludePictures(!includePictures)}>
               Include pictures
            </Checkbox>
            <Checkbox value={appendPdfAttachments} onClick={() => setAppendPdfAttachments(!appendPdfAttachments)}>
               Append PDF attachments
            </Checkbox>
         </ModalBody>
         <StandardModalFooter
            confirmDisabled={!reportUrl}
            confirm={() => {
               window.open(reportUrl!);
               props.toggle();
            }}
            cancel={props.toggle}
         />
      </Modal>
   );
};
