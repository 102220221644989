import React, { useEffect } from "react";
import { Summary } from "summaries";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { StandardModalFooter } from "app/bs/StandardModalFooter";
import { Checkbox } from "common";
import { Row, Col, FormGroup, Label, Input, Form } from "reactstrap";
import { Range, createSliderWithTooltip } from "rc-slider";
import { RecordDetailView } from "records";
import { Criticality, SiteArea } from "../../sites";
import Select from "react-select";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { useSiteContext } from "app";
import { useGetAssetsByIdsRequest } from "assets";
import { useBulkValveHealthSummaryReportUrlBuilder } from "common/reports";
import "rc-slider/assets/index.css";

const RangeWithTooltip = createSliderWithTooltip(Range);

interface Props {
  summary: Summary;
  summaryRecordDetails: RecordDetailView[];
  close: () => void;
}

export const ConfigureBulkValveHealthReportModal: React.FunctionComponent<Props> = (props) => {
  const currentSite = useSiteContext().currentSite!;

  const allRecordAssetIds = _.uniq(props.summaryRecordDetails.flatMap((r) => r.model.assets.map((a) => a.id)));
  const allRecordAssetsQuery = useGetAssetsByIdsRequest(allRecordAssetIds);
  const allRecordAssets = allRecordAssetsQuery.data ?? [];

  const [includeExecutiveSummary, setIncludeExecutiveSummary] = useState(true);
  const [includeHighlightsEtc, setIncludeHighlightsEtc] = useState(true);
  const [includeHealthLegend, setIncludeHealthLegend] = useState(true);
  const [includeTestResults, setIncludeTestResults] = useState(true);
  const [includePhotos, setIncludePhotos] = useState(true);
  const [appendPdfAttachments, setAppendPdfAttachments] = useState(true);

  const [minScore, setMinScore] = useState(0);
  const [minScoreText, setMinScoreText] = useState("0");
  const [maxScore, setMaxScore] = useState(100);
  const [maxScoreText, setMaxScoreText] = useState("100");

  const [worstN, setWorstN] = useState<number | null>(null);
  const [overall, setOverall] = useState(true);
  const [condition, setCondition] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [onlyWithOpenRecommendations, setOnlyWithOpenRecommendations] = useState(false);

  const [areas, setAreas] = useState<SiteArea[]>([]);
  useEffect(() => {
    setAreas(
      _.orderBy(
        currentSite.areas.filter((area) => !!allRecordAssets.find((asset) => asset.model.areaId === area.id)),
        (area) => area.name
      )
    );
  }, [allRecordAssets?.length ?? 0]);

  const [selectedAreas, setSelectedAreas] = useState<SiteArea[]>([]);

  const [criticalities, setCriticalities] = useState<Criticality[]>([]);
  useEffect(() => {
    setCriticalities(
      _.orderBy(
        currentSite.customer.criticalities.filter((c) => !!allRecordAssets.find((a) => a.model.criticalityId === c.id)),
        (c) => c.displayValue
      )
    );
  }, [allRecordAssets?.length ?? 0]);
  const [selectedCriticalities, setSelectedCriticalities] = useState<Criticality[]>([]);
  /**
   * Rename includePictures to includePhotos when converting the report to node
   */
  const reportUrl = useBulkValveHealthSummaryReportUrlBuilder({
    id: props.summary.id,
    pdf: true,
    appendPdfAttachments,
    includeExecutiveSummary,
    includeHighlightsEtc,
    includeHealthLegend,
    includeTestResults,
    includePictures: includePhotos,
    overall,
    condition,
    performance,
    minScore,
    maxScore,
    worstN,
    onlyWithOpenRecommendations,
    areaIds: selectedAreas.length === 0 ? null : selectedAreas.map((s) => s.id.toString()).join(","),
    criticalityIds:
      selectedCriticalities.length === 0 ? null : selectedCriticalities.map((c) => c.id.toString()).join(","),
  });

  const countSelectedValves = () => {
    const filteredRecords = props.summaryRecordDetails.filter((r) => {
      const recordAssets = allRecordAssets.filter(
        (asset) => !!r.model.assets.find((recordAsset) => recordAsset.id === asset.id)
      );
      if (selectedAreas.length > 0 && selectedAreas.every((a) => recordAssets[0].model.areaId !== a.id)) {
        return false;
      }

      if (
        selectedCriticalities.length > 0 &&
        selectedCriticalities.every((c) => recordAssets[0].model.criticalityId !== c.id)
      ) {
        return false;
      }

      if (
        onlyWithOpenRecommendations &&
        !r.recommendations.some((rec) => rec.status !== "Complete" && rec.status !== "Archived")
      ) {
        return false;
      }

      return true;
    });

    const scoredRecords: [RecordDetailView, number][] = [];
    filteredRecords.forEach((record) => {
      if (!record.model.health) return;

      let score: number | null = null;
      if (overall) {
        score = record.model.health.overall;
      } else if (condition) {
        score = record.model.health.condition;
      } else if (performance) {
        score = record.model.health.performance;
      }

      if (score === null || score > maxScore || score < minScore) return;

      scoredRecords.push([record, score]);
    });

    const sortOrder = overall ? "asc" : "desc";
    const recordsOrderedByScore = _.orderBy(scoredRecords, (sr) => sr[1], sortOrder);

    return recordsOrderedByScore.slice(0, worstN !== null ? worstN : undefined).length;
  };

  const parseCount = (value: string) => {
    const valueAsNumber = parseInt(value);
    return isNaN(valueAsNumber) ? null : valueAsNumber;
  };

  const parseScore = (value: string) => {
    const valueAsNumber = parseFloat(value);
    return isNaN(valueAsNumber) ? 0 : valueAsNumber;
  };

  const getIncompleteValveCountIndicator = () => {
    const count = props.summaryRecordDetails.filter(
      (r) => !r.model.health || (r.model.health && r.model.health.overall === null)
    ).length;
    return count > 0 ? ` (${count} incomplete and excluded)` : "";
  };

  const selectedValveCount = countSelectedValves();

  return (
    <Modal
      isOpen={true}
      size="lg"
      className="configure-bulk-valve-health-report-modal"
      container={"#bootstrap-modal-container"}
    >
      <ModalHeader>Report options</ModalHeader>
      <ModalBody>
        <Form>
          <Checkbox
            value={includeExecutiveSummary}
            onClick={() => setIncludeExecutiveSummary(!includeExecutiveSummary)}
          >
            Include executive summary
          </Checkbox>
          <Checkbox value={includeHighlightsEtc} onClick={() => setIncludeHighlightsEtc(!includeHighlightsEtc)}>
            Include highlights, challenges, general recommendations, path forward
          </Checkbox>
          <Checkbox value={includeHealthLegend} onClick={() => setIncludeHealthLegend(!includeHealthLegend)}>
            Include health legend
          </Checkbox>
          <Checkbox value={includePhotos} onClick={() => setIncludePhotos(!includePhotos)}>
            Include photos
          </Checkbox>
          <Checkbox value={appendPdfAttachments} onClick={() => setAppendPdfAttachments(!appendPdfAttachments)}>
            Append PDF attachments
          </Checkbox>

          <hr />

          <fieldset disabled={!includeTestResults} className="mt-3">
            <legend>Test results</legend>
            <Row>
              <Col>
                <FormGroup>
                  <Checkbox value={includeTestResults} onClick={() => setIncludeTestResults(!includeTestResults)}>
                    Select valves to include in test results
                  </Checkbox>
                </FormGroup>
              </Col>
              <Col>
                {includeTestResults && (
                  <div
                    className={classNames("selected-valve-count", "d-flex", "justify-context-end", {
                      "text-info": selectedValveCount > 0,
                      "text-danger": selectedValveCount === 0,
                    })}
                  >
                    <span className="count">{selectedValveCount}</span>&nbsp;valve
                    {selectedValveCount !== 1 ? "s" : ""} selected{getIncompleteValveCountIndicator()}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <FormGroup tag="fieldset">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="score"
                        checked={overall}
                        onChange={(e) => {
                          setOverall(true);
                          setCondition(false);
                          setPerformance(false);
                          setMinScore(0);
                          setMinScoreText("0");
                          setMaxScore(100);
                          setMaxScoreText("100");
                        }}
                      />{" "}
                      Overall
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="score"
                        checked={condition}
                        onChange={(e) => {
                          setOverall(false);
                          setCondition(true);
                          setPerformance(false);
                          setMinScore(1);
                          setMinScoreText("1.00");
                          setMaxScore(4);
                          setMaxScoreText("4.00");
                        }}
                      />{" "}
                      Condition
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="score"
                        checked={performance}
                        onChange={(e) => {
                          setOverall(false);
                          setCondition(false);
                          setPerformance(true);
                          setMinScore(1);
                          setMinScoreText("1.00");
                          setMaxScore(4);
                          setMaxScoreText("4.00");
                        }}
                      />{" "}
                      Performance
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Score range</Label>
                  <div className="px-3 my-3">
                    <RangeWithTooltip
                      min={overall ? 0 : 1}
                      max={overall ? 100 : 4}
                      defaultValue={overall ? [0, 100] : [1, 4]}
                      step={overall ? 5 : 0.1}
                      tipFormatter={(value: number) => value.toFixed(overall ? 0 : 2)}
                      value={[minScore, maxScore]}
                      onChange={(values: number[]) => {
                        setMinScore(values[0]);
                        setMinScoreText(values[0].toFixed(overall ? 0 : 2));
                        setMaxScore(values[1]);
                        setMaxScoreText(values[1].toFixed(overall ? 0 : 2));
                      }}
                      disabled={!includeTestResults}
                    />
                  </div>
                  <div className="score-range">
                    <FormGroup>
                      <Label>Minimum</Label>
                      <Input
                        type="number"
                        step={overall ? 5 : 0.1}
                        value={minScoreText}
                        onChange={(e) => {
                          setMinScoreText(e.target.value);
                          setMinScore(parseScore(e.target.value));
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Maximum</Label>
                      <Input
                        type="number"
                        step={overall ? 5 : 0.1}
                        value={maxScoreText}
                        onChange={(e) => {
                          setMaxScoreText(e.target.value);
                          setMaxScore(parseScore(e.target.value));
                        }}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Worst valves (count)</Label>
                  <Input
                    type="number"
                    step={1}
                    value={worstN === null ? "" : worstN.toString()}
                    onChange={(e) => {
                      setWorstN(parseCount(e.target.value));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label />
                  <Checkbox
                    value={onlyWithOpenRecommendations}
                    onClick={() => {
                      setOnlyWithOpenRecommendations(!onlyWithOpenRecommendations);
                    }}
                    disabled={!includeTestResults}
                  >
                    Only valves with open recommendations
                  </Checkbox>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Area</Label>
                  <Select
                    isMulti
                    className={classNames("react-select")}
                    classNamePrefix="react-select"
                    value={selectedAreas}
                    onChange={(newAreas) => {
                      setSelectedAreas(newAreas as SiteArea[]);
                    }}
                    options={_.orderBy(areas, (a) => a.name)}
                    getOptionLabel={(a) => a.name}
                    getOptionValue={(a) => a.id.toString()}
                    isClearable
                    placeholder="any"
                    isDisabled={!includeTestResults}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Criticality</Label>
                  <Select
                    isMulti
                    className={classNames("react-select")}
                    classNamePrefix="react-select"
                    value={selectedCriticalities}
                    onChange={(newCriticalities) => {
                      setSelectedCriticalities(newCriticalities as Criticality[]);
                    }}
                    options={_.orderBy(criticalities, (c) => c.displayValue)}
                    getOptionLabel={(c) => c.displayValue}
                    getOptionValue={(c) => c.id.toString()}
                    isClearable
                    placeholder="any"
                    isDisabled={!includeTestResults}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
        </Form>
      </ModalBody>
      <StandardModalFooter
        confirmDisabled={!reportUrl}
        confirm={() => {
          window.open(reportUrl!, "_blank");
          props.close();
        }}
        cancel={props.close}
      />
    </Modal>
  );
};
