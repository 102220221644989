import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { Controller } from "react-hook-form";
import { BaseFormItemProps } from "../Base/BaseFormItemProps";
import { FormItemLayout } from "../FormItemLayout";

interface ISwitchFormItemProps extends BaseFormItemProps {
  switchProps?: SwitchProps;
  readOnly?: boolean;
}

export const SwitchFormItem = (props: ISwitchFormItemProps) => {
  const { fieldName, switchProps, label, hint, readOnly, ...formItemLayoutProps } = props;

  return (
    <Controller
      name={fieldName}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormItemLayout
          controlled
          error={error?.message}
          fieldName={fieldName}
          label={undefined}
          hint={hint}
          {...formItemLayoutProps}
        >
          {(inputProps) => (
            <FormControlLabel
              control={
                <Switch
                  {...props.switchProps}
                  color="secondary"
                  size="medium"
                  onChange={onChange}
                  checked={!!value}
                  disabled={readOnly}
                />
              }
              label={label}
            />
          )}
        </FormItemLayout>
      )}
    />
  );
};
