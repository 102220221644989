import { TrafficLightValue } from "./trafficLight";

export interface VibrationAnalysisFields {
  balance: TrafficLightValue | null;
  alignmentCoupling: TrafficLightValue | null;
  bearings: TrafficLightValue | null;
  gears: TrafficLightValue | null;
  resonance: TrafficLightValue | null;
  looseness: TrafficLightValue | null;
  electrical: TrafficLightValue | null;
  process: TrafficLightValue | null;
  structurePiping: TrafficLightValue | null;
}

export enum VibrationAnalysisFieldLabel {
  Balance = "Balance",
  AlignmentCoupling = "Alignment / Coupling",
  Bearings = "Bearings",
  Gears = "Gears",
  Resonance = "Resonance",
  Looseness = "Looseness",
  Electrical = "Electrical",
  Process = "Process",
  StructurePiping = "Structure / Piping",
}

export enum VibrationAnalysisTooltip {
  Balance = "Any indications of vibration concern due to unbalance (ex. static unbalance, dynamic unbalance, couple imbalance, overhung rotor unbalance).",
  AlignmentCoupling = "Any indications of vibration concern due to alignment or coupling issues (ex. angular misalignment, parallel misalignment, misaligned bearing on shaft, worn or locked coupling, belt or sheave issues, chain or sprocket issues).",
  Bearings = "Any indications of vibration concern due to bearing issues (ex. rolling element bearing deterioration, lubrication issues, oil whip or oil whirl).",
  Gears = "Any indications of vibration concern related to a gearbox (ex. worn or broken teeth, gear misalignment, backlash, assembly issues).",
  Resonance = "Any indications of vibration concerns due to the excitations of natural frequencies of the machine.",
  Looseness = "Any indications of vibration concerns due to looseness (ex. loose hold down bolts, excessive clearance, cracked or broken welds, etc.).",
  Electrical = "Any indications of vibration concerns due to electrical frequencies.",
  Process = "Any indications of vibration concerns as a result of current process conditions.",
  StructurePiping = "Any indications of vibration concern of the structure or piping (ex. pipe strain, resonance of structure or piping, damaged or degrading foundation, etc.).",
}

export type ReportCardVibrationAnalysis = VibrationAnalysisFields & {
  id: number;
  recordId: number;
};

export const createNewReportCardVibrationAnalysis = (recordId: number): ReportCardVibrationAnalysis => ({
  id: -1,
  recordId,
  balance: null,
  alignmentCoupling: null,
  bearings: null,
  gears: null,
  resonance: null,
  looseness: null,
  electrical: null,
  process: null,
  structurePiping: null,
});
