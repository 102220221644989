import { TimeUnit } from "common";
import { KpiBase, KpiBaseFields, KpiCommodityUnit, KpiEmissionsType } from ".";

export interface KpiOngoingEmissionsReduction extends KpiBase {
  kpiType: "OngoingEmissionsReduction";
  amountBeforeReduction: number | null;
  amountAfterReduction: number | null;
  reductionUnit: KpiCommodityUnit | null;
  reductionType: KpiEmissionsType | string | null;
  reductionTimeUnit: TimeUnit | null;
}
export const KpiOngoingEmissionsReductionFields: (keyof KpiOngoingEmissionsReduction)[] = [
  ...KpiBaseFields,
  "amountBeforeReduction",
  "amountAfterReduction",
  "reductionUnit",
  "reductionType",
  "reductionTimeUnit",
];

export class KpiOngoingEmissionsReductionViewModel {
  model: KpiOngoingEmissionsReduction;

  constructor(kpiValueDetail: KpiOngoingEmissionsReduction) {
    this.model = kpiValueDetail;
  }
}
