import { Box, MenuItem, Select, Stack } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { NumberFormatInput } from "app/mui/forms/Input/NumberFormatInput";
import { useEffect, useState } from "react";
import { useController } from "react-hook-form";

interface RemoteHoursFormItemProps extends BaseFormItemProps {
  hoursFieldName: string;
}

type RemoteHoursOption = "all" | "some" | "none";

export function RemoteHoursFormItem(props: RemoteHoursFormItemProps) {
  const {
    hoursFieldName: spartanHoursFieldName,
    readOnly,
    fieldName,
    label = "Remote hours",
    ...formItemLayoutProps
  } = props;
  const [selectedRemoteHours, setSelectedRemoteHours] = useState<RemoteHoursOption>("all");

  const spartanHoursControl = useController({ name: spartanHoursFieldName });

  const remoteHoursControl = useController({ name: fieldName });

  useEffect(() => {
    const spartanHours = spartanHoursControl.field.value ?? 0;

    if (remoteHoursControl.field.value === 0 || remoteHoursControl.field.value === null) {
      setSelectedRemoteHours("none");
    } else if (remoteHoursControl.field.value === spartanHours) {
      setSelectedRemoteHours("all");
    } else {
      setSelectedRemoteHours("some");
    }
  }, [spartanHoursControl.field.value, remoteHoursControl.field.value]);

  const showOverride = selectedRemoteHours === "some";

  return (
    <FormItemLayout
      controlled
      hint={props.hint}
      label={label}
      error={remoteHoursControl.formState.errors[fieldName]?.message?.toString()}
      formControlProps={{ disabled: readOnly }}
      {...formItemLayoutProps}
    >
      {(inputProps) => (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Select
            fullWidth
            size="small"
            onChange={(e) => {
              const val = e.target.value as RemoteHoursOption;
              let remoteHours = 0;
              setSelectedRemoteHours(val);
              if (val === "none") {
                remoteHours = 0;
              } else if (val === "all") {
                remoteHours = spartanHoursControl.field.value;
              } else {
                remoteHours = remoteHoursControl.field.value ?? 0;
              }
              remoteHoursControl.field.onChange(remoteHours);
            }}
            value={selectedRemoteHours}
            {...inputProps}
          >
            <MenuItem key="none" value="none">
              None
            </MenuItem>
            <MenuItem key="some" value="some">
              Some
            </MenuItem>
            <MenuItem key="all" value="all">
              All {spartanHoursControl.field.value} hours
            </MenuItem>
          </Select>
          {showOverride && (
            <Box>
              <NumberFormatInput
                value={remoteHoursControl.field.value}
                onChange={remoteHoursControl.field.onChange}
                {...inputProps}
                numberFormatProps={{ ...inputProps.numberFormatProps, readOnly }}
              />
            </Box>
          )}
        </Stack>
      )}
    </FormItemLayout>
  );
}
