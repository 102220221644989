import { Stack, Typography } from "@mui/material";
import { EventIcon } from "icons/EventIcon";
import { UseFormReturn } from "react-hook-form";
import { RecordsEvent, RecordsEventDetail } from "recordsEvents/models";

import { RecordsEventActions } from "./RecordsEventActions";
import { ArchivedStatusBadge } from "../ArchivedStatusBadge";

interface IRecordsEventHeaderProps {
  recordsEventDetail: RecordsEventDetail;
  methods: UseFormReturn<RecordsEvent>;
  saveChanges: (onValid?: () => void) => void;
}

export const RecordsEventHeader = (props: IRecordsEventHeaderProps) => {
  const { recordsEventDetail, methods, saveChanges } = props;
  const event = recordsEventDetail.model;

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" pb={2}>
          <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
            <EventIcon sx={{ fontSize: 28 }} />
            <Typography variant="h5">{event.name}</Typography>
          </Stack>
          <RecordsEventActions recordsEventDetail={recordsEventDetail} methods={methods} saveChanges={saveChanges} />
        </Stack>
        <ArchivedStatusBadge isArchived={recordsEventDetail.model.isArchived} />
      </Stack>
    </>
  );
};
