import React, { useEffect, useState } from "react";
import {
  GetKpiCommodityUnitsForEmissions,
  KpiCommodityUnitsForSpills,
  KpiEmissionsType,
  KpiEmissionsTypes,
} from "../models";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import _ from "lodash";
import { getInvalidValidity, getValidValidity, Validity } from "common";
import { EditKpiFormProps } from "./EditKpiFormProps";
import { ProductionRateFormGroup } from "kpis/formFields/ProductionRateFormGroup";
import {
  KpiOneTimeReleaseOrSpillAvoidance,
  KpiReleaseTypes,
  KpiReleaseType,
} from "kpis/models/KpiOneTimeReleaseOrSpillAvoidance";

interface Props extends EditKpiFormProps {}

export interface KpiOneTimeReleaseOrSpillAvoidanceValidity {
  releaseType: Validity;
  reductionAmount: Validity;
  reductionUnit: Validity;
  reductionType: Validity;
}

export const getKpiOneTimeReleaseOrSpillAvoidanceValidity = (
  fields: KpiOneTimeReleaseOrSpillAvoidance
): KpiOneTimeReleaseOrSpillAvoidanceValidity => {
  const validUnits =
    fields.releaseType === "Emission"
      ? GetKpiCommodityUnitsForEmissions(fields.reductionType)
      : KpiCommodityUnitsForSpills;
  return {
    releaseType:
      !!fields.releaseType && KpiReleaseTypes.indexOf(fields.releaseType) >= 0
        ? getValidValidity()
        : getInvalidValidity(),
    reductionAmount: !!fields.reductionAmount && fields.reductionAmount > 0 ? getValidValidity() : getInvalidValidity(),
    reductionUnit:
      !!fields.reductionUnit && validUnits.indexOf(fields.reductionUnit) >= 0
        ? getValidValidity()
        : getInvalidValidity(),
    reductionType:
      !!fields.reductionType &&
      (fields.releaseType !== "Emission" || KpiEmissionsTypes.indexOf(fields.reductionType as KpiEmissionsType) >= 0)
        ? getValidValidity()
        : getInvalidValidity(),
  };
};

export const EditKpiOneTimeReleaseOrSpillAvoidanceForm: React.FunctionComponent<Props> = (props) => {
  const model = props.model as KpiOneTimeReleaseOrSpillAvoidance;

  const [validity, setValidity] = useState<KpiOneTimeReleaseOrSpillAvoidanceValidity>(
    getKpiOneTimeReleaseOrSpillAvoidanceValidity(model)
  );

  const validate = (fields: KpiOneTimeReleaseOrSpillAvoidance) => {
    const newValidity = getKpiOneTimeReleaseOrSpillAvoidanceValidity(fields);
    setValidity(newValidity);
    return _.values(newValidity).filter((v) => !v.valid).length === 0;
  };

  useEffect(() => {
    if (!!props.onLoaded) {
      const isValid = _.values(validity).filter((v) => !v.valid).length === 0;
      props.onLoaded(isValid);
    }
  }, []);

  const saveChange = (updates: Partial<KpiOneTimeReleaseOrSpillAvoidance>) => {
    const updatedFields = { ...model, ...updates };
    const isValid = validate(updatedFields);
    props.onUpdated(updatedFields, isValid);
  };

  const onChangeReleaseType = (newReleaseType: KpiReleaseType) => {
    if (model.releaseType !== newReleaseType) {
      saveChange({
        releaseType: newReleaseType,
        reductionUnit: newReleaseType === "Emission" ? "tonnes" : "barrels",
        reductionType: newReleaseType === "Emission" ? KpiEmissionsTypes[0] : "",
      });
    }
  };

  const commodityUnits =
    model.releaseType === "Emission"
      ? GetKpiCommodityUnitsForEmissions(model.reductionType)
      : model.releaseType === "Spill"
      ? KpiCommodityUnitsForSpills
      : [];

  return (
    <Row className="gray-form-section">
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <p className="form-section-header">Release or spill avoided</p>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>Release type</Label>
              <div className="form-group-input-wrapper">
                <FormGroup check style={{ display: "flex" }}>
                  <Label check disabled={props.readOnly}>
                    <Input
                      type="radio"
                      name="releaseType"
                      checked={model.releaseType === "Emission"}
                      disabled={props.readOnly}
                      readOnly={props.readOnly}
                      onChange={(e) => {
                        onChangeReleaseType("Emission");
                      }}
                    />
                    Emission
                  </Label>
                </FormGroup>
                <FormGroup check style={{ display: "flex" }}>
                  <Label check disabled={props.readOnly}>
                    <Input
                      type="radio"
                      name="releaseType"
                      checked={model.releaseType === "Spill"}
                      disabled={props.readOnly}
                      readOnly={props.readOnly}
                      onChange={(e) => {
                        onChangeReleaseType("Spill");
                      }}
                    />
                    Spill
                  </Label>
                </FormGroup>
              </div>

              <div className="invalid-feedback">{validity.releaseType.message}</div>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <ProductionRateFormGroup
              label="Amount (quantity/unit/product)"
              showValidationErrors={props.showValidationErrors}
              productionRateReadOnly={props.readOnly}
              productionRateValidity={validity.reductionAmount}
              productionRateValue={model.reductionAmount}
              setProductionRate={(reductionAmount) => saveChange({ reductionAmount })}
              unitReadOnly={props.readOnly}
              unitValidity={validity.reductionUnit}
              unitValue={model.reductionUnit}
              units={commodityUnits}
              setUnit={(reductionUnit) => saveChange({ reductionUnit })}
              typeReadOnly={props.readOnly}
              typeValidity={validity.reductionType}
              typeValue={model.reductionType}
              typeValues={model.releaseType === "Emission" ? KpiEmissionsTypes : null}
              setType={(reductionType) => saveChange({ reductionType })}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
