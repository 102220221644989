import { ListSubheader, MenuItem, Select } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import _ from "lodash";
import { useCallback } from "react";
import { Controller } from "react-hook-form";
import { BaseFormItemProps } from "../Base/BaseFormItemProps";

export type CascadingSelectOption = {
  id: number | string;
  path: string[];
  selectable: boolean;
  sortOrder?: number | null;
};

export interface CascadingSelectFormItemProps extends BaseFormItemProps {
  syncFieldName?: string;
  options: CascadingSelectOption[];
}

export const CascadingSelectFormItem = (props: CascadingSelectFormItemProps) => {
  const { readOnly, options, ...formItemLayoutProps } = props;

  const renderMenuItems = useCallback(
    (path: string[] = [], depth: number = 0) => {
      const renderOptions = options.filter(
        (o) => o.path.length === depth + 1 && o.path.slice(0, depth).join() === path.join()
      );
      return _.sortBy(renderOptions, (x) => [x.sortOrder, x.path.slice(-1)], ["asc", "asc"]).map((option) => {
        if (option.selectable) {
          return (
            <MenuItem key={option.id} value={option.id} sx={{ pl: 2 * option.path.length - 1 }}>
              {option.path.slice(-1)[0]}
            </MenuItem>
          );
        } else {
          return [
            <ListSubheader key={option.id} value={option.id} sx={{ pl: 2 * option.path.length - 1 }}>
              {option.path[0]}
            </ListSubheader>,
            renderMenuItems(option.path, option.path.length),
          ];
        }
      });
    },
    [options]
  );

  return (
    <Controller
      name={props.fieldName}
      render={({ field: { onChange, value } }) => {
        return (
          <FormItemLayout controlled hint={props.hint} label={props.label} {...formItemLayoutProps}>
            {(inputProps) => (
              <Select
                {...inputProps}
                onChange={(e) => {
                  const value = e.target?.value;
                  onChange(value);
                }}
                value={value}
                renderValue={(selectedValue: number | string) => {
                  return <span>{options.find((x) => x.id === selectedValue)?.path.join(" > ") ?? selectedValue}</span>;
                }}
              >
                {renderMenuItems()}
              </Select>
            )}
          </FormItemLayout>
        );
      }}
    />
  );
};
