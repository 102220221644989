import React, { useState } from "react";
import Select from "react-select";
import { User } from "users";
import { Avatar } from "common";
import _ from "lodash";
import { useGetUsersInSiteRequest } from "./api";

interface Props {
  selectedUsers: User[];
  onChangeUsers: (selectedUsers: User[]) => void;
  disabled: boolean;
  siteId: number;
}

export const MultiUserSelect: React.FunctionComponent<Props> = (props) => {
  const [inputValue, setInputValue] = useState("");
  const { data: users, loading } = useGetUsersInSiteRequest(props.siteId);
  if (loading || users === null) return null;

  return (
    <Select
      isMulti
      inputValue={inputValue}
      onInputChange={(value) => setInputValue(value)}
      onChange={(selectedUsersValue) => {
        if (!selectedUsersValue) return;

        const selectedUsers = selectedUsersValue as User[];
        props.onChangeUsers(selectedUsers);
      }}
      isClearable={false}
      value={_.orderBy(props.selectedUsers, (user) => user.fullName)}
      formatOptionLabel={(user) => (
        <div className="team-member">
          <Avatar user={user} />
          {user.fullName}
        </div>
      )}
      getOptionValue={(user) => user.userId}
      className="user-select react-select"
      classNamePrefix="react-select"
      options={users}
      isDisabled={props.disabled}
      backspaceRemovesValue={false}
      filterOption={(option, searchValue) => option.data.fullName.toLowerCase().includes(searchValue.toLowerCase())}
    />
  );
};
