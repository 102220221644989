import { Box, Stack, Typography } from "@mui/material";
import { IrisColors } from "app/mui/theme";

export const NoDataToDisplay = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "70px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack spacing={1} sx={{ textAlign: "center" }}>
        <Typography sx={{ color: IrisColors.gray500 }}>No Data to Display</Typography>
      </Stack>
    </Box>
  );
};
