import React, { ChangeEventHandler } from "react";
import { FunctionComponent } from "react";
import { FormGroup, Input, InputProps, Label } from "reactstrap";

interface Props {
   checked: boolean;
   inputProps?: Omit<InputProps, "checked" | "onChange">;
   children?: React.ReactNode;
   onChange: ChangeEventHandler<HTMLInputElement>;
   disabled?: boolean;
   title?: string;
}

export const CheckBoxFormGroup: FunctionComponent<Props> = (props) => (
   <FormGroup>
      <Label
         check
         style={{
            alignItems: "center",
            cursor: props.disabled ? "auto" : "pointer",
            color: props.disabled ? "gray" : "inherit",
         }}
         title={props.title}
      >
         <Input
            type="checkbox"
            disabled={props.disabled}
            {...props.inputProps}
            checked={props.checked}
            onChange={props.onChange}
            style={{ marginTop: "0", marginLeft: "0", cursor: props.disabled ? "auto" : "pointer" }}
         />
         <span style={{ paddingLeft: "20px" }}>{props.children}</span>
      </Label>
   </FormGroup>
);
