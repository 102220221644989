import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { TabsApi } from "../useTabbedViews";

interface SaveTabViewButtonProps {
   tabsApi: TabsApi;
}

export function SaveTabViewButton(props: SaveTabViewButtonProps) {
   const buttonEnabled = props.tabsApi.isDirty;
   const buttonShowing = props.tabsApi.canSaveView;

   if (!buttonShowing) {
      return null;
   }

   return (
      <Button
         variant="text"
         color="secondary"
         size="small"
         disabled={!buttonEnabled}
         onClick={() => {
            props.tabsApi.saveCurrentView();
         }}
         startIcon={<SaveIcon />}
      >
         Save View
      </Button>
   );
}
