import * as React from "react";
import { Summary } from "summaries";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useGeneralSummaryReportUrlBuilder } from "common/reports";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";

interface Props extends BaseDialogProps {
  summary: Summary;
}

interface FormValues {
  includeAssetsAndRecords: boolean;
  includeHighlightsEtc: boolean;
  includePhotos: boolean;
  appendPdfAttachments: boolean;
  includeRecordDetails: boolean;
  includeRecordRecommendations: boolean;
}

export const ConfigureGeneralSummaryReportModal: React.FunctionComponent<Props> = (props) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      includeAssetsAndRecords: true,
      includeHighlightsEtc: true,
      includePhotos: true,
      appendPdfAttachments: true,
      includeRecordDetails: true,
      includeRecordRecommendations: true,
    },
  });

  const values = useWatch({ control: methods.control }) as FormValues;

  const reportUrl = useGeneralSummaryReportUrlBuilder({
    id: props.summary.id,
    pdf: true,
    appendPdfAttachments: values.appendPdfAttachments,
    includeAssetsAndRecords: values.includeAssetsAndRecords,
    includeHighlightsEtc: values.includeHighlightsEtc,
    includePictures: values.includePhotos,
    includeRecordDetails: values.includeRecordDetails,
    includeRecordRecommendations: values.includeRecordRecommendations,
  });

  const handleSubmit = methods.handleSubmit(() => {
    window.open(reportUrl!, "_blank");
    props.onClose();
  });

  React.useEffect(() => {
    if (!values.includeAssetsAndRecords) {
      methods.setValue("includeRecordDetails", false);
      methods.setValue("includeRecordRecommendations", false);
    }
  }, [values.includeAssetsAndRecords]);

  return (
    <BaseDialog onClose={props.onClose} maxWidth="md">
      <DialogTitle variant="h6" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        Report options
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <CheckboxFormItem fieldName="includeAssetsAndRecords" label="Include assets and records" />
            <CheckboxFormItem
              fieldName="includeHighlightsEtc"
              label="Include highlights, challenges, general recommendations, path forward"
            />
            <CheckboxFormItem fieldName="includePhotos" label="Include photos" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF attachments" />
            <CheckboxFormItem
              fieldName="includeRecordDetails"
              label="Include record details"
              disabled={!values.includeAssetsAndRecords}
            />
            <CheckboxFormItem
              fieldName="includeRecordRecommendations"
              label="Include record recommendations"
              disabled={!values.includeAssetsAndRecords}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton loading={false} variant="contained" color="secondary" onClick={handleSubmit}>
            Generate
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
