import { UseFormWatch } from "react-hook-form";
import { KpiBase, KpiBaseFields } from "./KpiBase";
import { KpiSavingsCategory } from "./KpiSavingsCategory";

export interface KpiOneTimeLabourOrEquipmentCostSavings extends KpiBase {
  kpiType: "OneTimeLabourOrEquipmentCostSavings";
  multiplier: number | null;
  multiplyEquipment: boolean | null;
  multiplyLabour: boolean | null;
  spartanHours: number | null;
  spartanRatePerHour: number | null;
  remoteHours: number;
  customerHours: number | null;
  customerRatePerHour: number | null;
  actualEquipmentCost: number | null;
  potentialEquipmentCost: number | null;
  potentialHours: number | null;
  savingsCategory: KpiSavingsCategory | string | null;
  dedicatedTripsAvoided: boolean;
  rateEffectiveDate: Date | null;
  rateEffectiveDateType: string | null;
  isSimplifiedVersion: boolean;
  migratedKpiLegacyId: number | null;
  simplifiedPotentialCost: number | null;
  simplifiedActualCost: number | null;
}

export const KpiOneTimeLabourOrEquipmentCostSavingsFields: (keyof KpiOneTimeLabourOrEquipmentCostSavings)[] = [
  ...KpiBaseFields,
  "multiplier",
  "multiplyEquipment",
  "multiplyLabour",
  "spartanHours",
  "spartanRatePerHour",
  "remoteHours",
  "customerHours",
  "customerRatePerHour",
  "actualEquipmentCost",
  "potentialHours",
  "potentialEquipmentCost",
  "savingsCategory",
  "dedicatedTripsAvoided",
  "isSimplifiedVersion",
  "migratedKpiLegacyId",
  "simplifiedPotentialCost",
  "simplifiedActualCost",
];

export class KpiOneTimeLabourOrEquipmentCostSavingsViewModel {
  model: KpiOneTimeLabourOrEquipmentCostSavings;
  watch: UseFormWatch<Partial<KpiOneTimeLabourOrEquipmentCostSavings>>;

  constructor(
    kpiValueDetail: KpiOneTimeLabourOrEquipmentCostSavings,
    watch: UseFormWatch<Partial<KpiOneTimeLabourOrEquipmentCostSavings>>
  ) {
    this.model = kpiValueDetail;
    this.watch = watch;
  }

  get isSimplifiedVersionValue(): boolean {
    return this.watch("isSimplifiedVersion") ?? false;
  }
  get isSimplifiedVersionLockedValue(): boolean {
    return this.watch("migratedKpiLegacyId") != null;
  }
  get actualEquipmentCostFieldValue(): number {
    return this.watch("actualEquipmentCost") ?? 0;
  }
  get customerHoursFieldValue(): number {
    return Number(this.watch("customerHours") ?? 0);
  }
  get customerRatePerHourFieldValue(): number {
    return Number(this.watch("customerRatePerHour") ?? 0);
  }
  get multiplierFieldValue(): number {
    return Number(this.watch("multiplier") ?? 0);
  }
  get multiplyEquipmentFieldValue(): boolean {
    return this.watch("multiplyEquipment") ?? false;
  }
  get multiplyLabourFieldValue(): boolean {
    return this.watch("multiplyLabour") ?? false;
  }
  get potentialEquipmentCostFieldValue(): number {
    return Number(this.watch("potentialEquipmentCost") ?? 0);
  }
  get potentialHoursFieldValue(): number {
    return Number(this.watch("potentialHours") ?? 0);
  }
  get spartanHoursFieldValue(): number {
    return Number(this.watch("spartanHours") ?? 0);
  }
  get spartanRatePerHourFieldValue(): number {
    return Number(this.watch("spartanRatePerHour") ?? 0);
  }

  get combinedActualHours(): number {
    return this.spartanHoursFieldValue + this.customerHoursFieldValue;
  }

  get specialistLabourCost(): number {
    return (this.spartanHoursFieldValue || 0) * (this.spartanRatePerHourFieldValue || 0);
  }

  get sitePersonnelLabourCost(): number {
    return this.customerHoursFieldValue * this.customerRatePerHourFieldValue;
  }

  get potentialLabourCost(): number {
    return !!this.multiplyLabourFieldValue
      ? this.multiplierFieldValue * this.customerRatePerHourFieldValue * this.combinedActualHours
      : this.potentialHoursFieldValue * this.customerRatePerHourFieldValue;
  }

  get potentialEquipmentCost(): number {
    return !!this.multiplyEquipmentFieldValue
      ? this.multiplierFieldValue * this.actualEquipmentCostFieldValue
      : this.potentialEquipmentCostFieldValue;
  }

  get potentialLaborCost(): number {
    return !!this.multiplyLabourFieldValue
      ? this.multiplierFieldValue * this.customerRatePerHourFieldValue * this.combinedActualHours
      : this.potentialHoursFieldValue * this.customerRatePerHourFieldValue;
  }

  get actualLabourCost(): number {
    return this.sitePersonnelLabourCost + this.specialistLabourCost;
  }

  get actualEquipmentCost(): number {
    return Number(this.actualEquipmentCostFieldValue);
  }

  get actualCosts(): number {
    return this.actualLabourCost + this.actualEquipmentCost;
  }

  get potentialCosts(): number {
    return this.potentialLaborCost + this.potentialEquipmentCost;
  }
}
