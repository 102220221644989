import { useBaseAxiosGetRequest } from "app";
import { CustomerDashboardViewModel } from "./models";

export const useGetCustomerDashboard = (customerId: number, siteId?: number) =>
  useBaseAxiosGetRequest<CustomerDashboardViewModel>(
    `api/dashboard/customer/${customerId}${!!siteId ? "/" + siteId.toString() : ""}`,
    {
      useNode: true,
    }
  );
