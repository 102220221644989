import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import TextAreaFormItem from "app/mui/forms/Input/TextAreaFormItem";
import { IrisColors } from "app/mui/theme";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCreateRecordHistoryRequest, useGetRecordHistoryByRecordIdRequest } from "records/api";

import { GetRecordHistoryEventDescription, RecordHistoryEvent, RecordHistoryEventType } from "records/models";

import { yupResolver } from "@hookform/resolvers/yup";
import { dateFormat } from "common";
import _ from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

interface RecordHistoryProps {
  recordId: number;
}

const validationSchema = yup.object().shape({
  comment: yup.string().required(""),
});

export default function RecordHistory(props: RecordHistoryProps) {
  const [recordHistory, setRecordHistory] = useState<RecordHistoryEvent[]>([]);

  const [filter, setFilter] = useState("audits");
  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const { call: postComment } = useCreateRecordHistoryRequest();
  const { data, loading } = useGetRecordHistoryByRecordIdRequest(props.recordId);

  useEffect(() => {
    if (!loading) {
      setRecordHistory(data ?? []);
    }
  }, [loading]);

  const getCreateDate = () => {
    const createEvent = recordHistory.find((h) => h.type === RecordHistoryEventType.Created);
    return createEvent ? moment(createEvent.postedDate).format(dateFormat) : "-";
  };

  const closeDate = React.useMemo(() => {
    // look for the most recent reopen event
    const reopenEvent = recordHistory.reduce(
      (recent: RecordHistoryEvent | undefined, curr) =>
        curr.type === RecordHistoryEventType.Reopened && (!recent || curr.postedDate > recent.postedDate)
          ? curr
          : recent,
      undefined
    );

    // look for the most recent close event
    const closeEvent = recordHistory.reduce(
      (recent: RecordHistoryEvent | undefined, curr) =>
        curr.type === RecordHistoryEventType.Closed && (!recent || curr.postedDate > recent.postedDate) ? curr : recent,
      undefined
    );

    // the record is closed if there is no reopen event that occured after
    return closeEvent && (!reopenEvent || closeEvent.postedDate > reopenEvent.postedDate)
      ? moment(closeEvent.postedDate).format(dateFormat)
      : "-";
  }, [recordHistory]);

  const filteredHistory = React.useMemo(() => {
    return _.sortBy(recordHistory, (h) => h.postedDate)
      .reverse()
      .filter(
        (h) =>
          (filter === "audits" && h.type !== RecordHistoryEventType.Comment) ||
          (filter === "comments" && h.type === RecordHistoryEventType.Comment)
      );
  }, [recordHistory, filter]);

  const onSubmit = () => {
    formMethods.handleSubmit(async (values) => {
      const result = await postComment({
        type: RecordHistoryEventType.Comment,
        recordId: props.recordId,
        content: values.comment,
        postedDate: new Date(),
      });

      if (result) {
        setRecordHistory((prev) => [...prev, result]);
        setFilter("comments");
        formMethods.reset();
      }
    })();
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={6} sm={12} xs={12}>
        <Grid
          container
          sx={{
            backgroundColor: IrisColors.gray300,
            fontSize: "0.875rem",
            borderRadius: "4px",
            padding: "8px",
            maxWidth: "380px",
          }}
        >
          <Grid item xs={6} whiteSpace="nowrap" color={IrisColors.gray600} paddingX={1}>
            Record created
          </Grid>
          <Grid item xs={6} whiteSpace="nowrap" color={IrisColors.gray600} paddingX={1}>
            Record closed
          </Grid>

          <Grid item xs={6} whiteSpace="nowrap" paddingX={1} sx={{ fontWeight: 700 }}>
            {getCreateDate()}
          </Grid>
          <Grid item xs={6} whiteSpace="nowrap" paddingX={1} sx={{ fontWeight: 700 }}>
            {closeDate}
          </Grid>
        </Grid>
        <ToggleButtonGroup
          exclusive
          size="small"
          color="secondary"
          sx={{ margin: "12px 0" }}
          value={filter}
          onChange={(_evt, val) => setFilter(val)}
        >
          <ToggleButton value="audits" sx={{ padding: "4px 12px" }}>
            Audit
          </ToggleButton>
          <ToggleButton value="comments" sx={{ padding: "4px 12px" }}>
            Comments
          </ToggleButton>
        </ToggleButtonGroup>
        <List dense>
          {filteredHistory.length > 0 ? (
            filteredHistory.map((h) => (
              <ListItem key={h.id}>
                <ListItemAvatar>
                  <Avatar src={h.postedBy.picture} alt={h.postedBy.fullName} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="span" color={IrisColors.graySpartan}>
                      {h.postedBy.fullName}{" "}
                      <Typography variant="body2" component="span" fontStyle="italic">
                        {GetRecordHistoryEventDescription(h)}{" "}
                        <span style={{ whiteSpace: "nowrap" }}>{moment(h.postedDate).format(dateFormat)}</span>
                      </Typography>
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color={IrisColors.dark}>
                      {h.content}
                    </Typography>
                  }
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary={`No ${filter}`} />
            </ListItem>
          )}
        </List>
      </Grid>
      <Box component={Grid} item md={1} sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
        <Divider orientation="vertical" />
      </Box>
      <Grid item md={5} sm={12} xs={12}>
        <FormProvider {...formMethods}>
          <Stack spacing={1} maxWidth={450}>
            <Typography variant="body1">Comment</Typography>
            <TextAreaFormItem fieldName="comment" />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={onSubmit}
                disabled={!formMethods.formState.isValid}
              >
                Add
              </Button>
            </Box>
          </Stack>
        </FormProvider>
      </Grid>
    </Grid>
  );
}
