import { useSiteContext } from "app/contexts";
import { ReactNode } from "react";
import { SiteArea } from "sites/models";

interface SiteAreasContextType {
  areas: SiteArea[];
}

export const SiteAreasForCurrentSite = (props: { children: (params: SiteAreasContextType) => ReactNode }) => {
  const { currentSite } = useSiteContext();

  const areas = currentSite?.areas ?? [];

  return <>{props.children({ areas })}</>;
};
