import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useNotifications } from "app";
import { pluralize } from "common/utilities";
import { useDeleteKpiTemplateRequest } from "kpis/api";
import { KpiTemplateDetail, parseKpiId } from "kpis/models";
import { sumBy } from "lodash";

interface DeleteKpiTemplateDialogProps {
  onClose: () => void;
  kpiTemplateDetail: KpiTemplateDetail;
  onDeleteKpiTemplate: () => void;
}

export const DeleteKpiTemplateDialog = (props: DeleteKpiTemplateDialogProps) => {
  const { onClose, kpiTemplateDetail, onDeleteKpiTemplate } = props;

  const deleteKpiTemplateRequest = useDeleteKpiTemplateRequest();
  const notifications = useNotifications();
  const existingRecordsNumber = sumBy(kpiTemplateDetail.recordTypeUsages, (x) => x.numberOfRecords);

  const handleDelete = () => {
    const kpiId = parseKpiId(kpiTemplateDetail.id);
    deleteKpiTemplateRequest.call(kpiId).then(() => {
      onDeleteKpiTemplate();
      notifications.success("KPI template deleted");
      onClose();
    });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Delete KPI Template</DialogTitle>
      <DialogContent>
        {deleteKpiTemplateRequest.loading && <LinearProgress />}
        {existingRecordsNumber > 0 && (
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            This KPI template has {existingRecordsNumber} {pluralize(existingRecordsNumber, "record")} associated with
            it. Deleting the template will remove it from those records.
          </Alert>
        )}
        <Typography variant="body2" sx={existingRecordsNumber > 0 ? { pt: 2, pl: 6 } : null}>
          Are you sure you want to delete this KPI template?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={deleteKpiTemplateRequest.loading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleDelete}
          color="error"
          variant="contained"
          loading={deleteKpiTemplateRequest.loading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
