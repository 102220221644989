import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FunctionComponent } from "react";
import { useGetCustomerDashboard } from "./api";
import { CustomerDashboardHeader } from "./widgets/CustomerDashboardHeader";
import { HighRiskAssetsWidget } from "./widgets/HighRiskAssetsWidget";
import { MySitesWidget } from "./widgets/MySitesWidget";
import { TrendingWidget } from "./widgets/TrendingWidget";
import { WinsWidget } from "./widgets/WinsWidget";
import { SitesMapWidget } from "./widgets/SitesMap";
import { FeatureFlagName, useFeatureEnabled } from "common";

interface DashboardPageProps {
  customerId: number;
  siteId?: number;
}

export const DashboardPage: FunctionComponent<DashboardPageProps> = (props) => {
  const { data, loading } = useGetCustomerDashboard(props.customerId, props.siteId);

  const viewModel = loading || !data ? undefined : data;
  const showMapWidget = useFeatureEnabled(FeatureFlagName.UseCustomerMap);

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <CustomerDashboardHeader data={viewModel} />
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={12} xl={9}>
        <MySitesWidget data={viewModel} />
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
        <WinsWidget data={viewModel} />
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={8} xl={6}>
        <TrendingWidget data={viewModel} />
      </Grid>
      <Grid xs={12} sm={12} xl={6}>
        <HighRiskAssetsWidget data={viewModel} />
      </Grid>
      {showMapWidget && (
        <Grid xs={12} sm={12} xl={6}>
          <SitesMapWidget data={viewModel} />
        </Grid>
      )}
      <Grid xs={12}>
        {/* spacer */}
        <Box height="100px" width="100%" />
      </Grid>
    </Grid>
  );
};
