import * as React from "react";
import { ReadonlyFormItem } from "app/mui/forms/ReadonlyFormItem";
import { useEditableContext } from "./EditableContext";

interface withEditableToggleProps {
  editable?: boolean;
}

/**
 * Wrap components with an HOC that exposes an optional 'editable' prop.
 * When editable is not true, return a readOnly form item - otherwise,
 * return the original component.
 *
 * This HOC also reads editable state from an editable context provider.
 */
export function withEditableToggle<T>(WrappedComponent: React.ComponentType<T>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  const WrappedEditableToggledComponent = (props: T & withEditableToggleProps) => {
    const { editable: editableViaProps, ...rest } = props;
    const { editable: editableViaContext } = useEditableContext();

    // editable will usually be determined by the context, but it should be overridable by props.
    let editable = editableViaContext;

    if (editableViaProps !== undefined && editableViaProps !== null) {
      editable = editableViaProps;
    }

    return editable ? <WrappedComponent {...(rest as any)} /> : <ReadonlyFormItem {...(rest as any)} />;
  };

  WrappedEditableToggledComponent.displayName = `withEditableToggle(${displayName})`;

  return WrappedEditableToggledComponent;
}
