import { TrafficLightValue } from "records";

export interface PneumaticFullStrokePerformance {
  setPointTracking: TrafficLightValue | null;
  overshoot: TrafficLightValue | null;
  offset: TrafficLightValue | null;
  cycling: TrafficLightValue | null;
  speed: TrafficLightValue | null;
}
export interface PneumaticFullStrokePerformanceValidity {
  valid: boolean;

  setPointTrackingValid: boolean;
  overshootValid: boolean;
  cyclingValid: boolean;
  speedValid: boolean;
  offsetValid: boolean;
}

export enum PneumaticFullStrokePerformanceFieldLabel {
  SetPointTracking = "Set point tracking",
  Overshoot = "Overshoot",
  Offset = "Offset",
  Cycling = "Cycling",
  Speed = "Speed",
}

export enum PneumaticFullStrokePerformanceTooltip {
  SetPointTracking = "Assess the travel feedback and its ability to reach and maintain set point",
  Overshoot = "Upon step change, assess whether the travel feedback bypasses set point and the recovery behavior",
  Offset = "Assess the differential between travel feedback and set point throughout step test",
  Cycling = "Assess any cyclical behavior occurring in step changes and/or steady state condition",
  Speed = "Assess the timing for full rated travel and/or specific performance-based steps",
}
