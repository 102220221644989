import React from "react";
import ReactDOM from "react-dom";
import { App } from "app/App";
import reportWebVitals from "./reportWebVitals";

import { LicenseInfo } from "@mui/x-license-pro";
import { BrowserRouter } from "react-router-dom";
// Insert a date parser that will parse date strings from the server into JavaScript dates
require("json.date-extensions"); // tslint:disable-line:no-var-requires
(JSON as any).useDateParser();

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || "");

const rootNode = document.getElementById("root") as HTMLElement;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  rootNode
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
