import {
  AssetHealthValidity,
  PneumaticFullStrokeConditionValidity,
  PneumaticFullStrokePerformanceValidity,
  RecordType,
  Record,
} from "records/models";
import { isInRange } from "common";

export const derivedStatusTypes: string[] = [
  RecordType.ValveDiagnostic,
  RecordType.FieldInspection,
  RecordType.Proactive,
];
const truthyStrings: string[] = ["true", "t", "y", "yes", "1"];
const falseyStrings: string[] = ["false", "f", "n", "no", "0"];

export interface RecordValidationResult {
  valid: boolean;
  message: string | null;
}

export function booleanIsInvalid(booleanValue: any) {
  if (typeof booleanValue === typeof true) return false;
  if (booleanValue === null || booleanValue === undefined) return false;

  return booleanFromString(booleanValue.toString()) === undefined;
}

export function booleanFromString(booleanString: any) {
  if (booleanString === null || booleanString === undefined) return undefined;
  const normalString = booleanString.toString().toLowerCase().trim();
  if (truthyStrings.some((truthy) => truthy === normalString)) return true;
  if (falseyStrings.some((falsey) => falsey === normalString)) return false;
  return undefined;
}

export function validateHealthValue(value: number | null) {
  return isInRange(value, 1, 4);
}

export function validateAssetHealth(record: Partial<Record>) {
  const validity = getFreshAssetHealthValidity();

  const health = record.health;
  if (health === undefined) return validity;

  validity.asFoundConditionValid = validateHealthValue(health.asFoundCondition);
  validity.asFoundPerformanceValid = validateHealthValue(health.asFoundPerformance);
  validity.asLeftConditionValid = validateHealthValue(health.asLeftCondition);
  validity.asLeftPerformanceValid = validateHealthValue(health.asLeftPerformance);

  validity.valid =
    validity.asFoundConditionValid &&
    validity.asFoundPerformanceValid &&
    validity.asLeftConditionValid &&
    validity.asLeftPerformanceValid;

  return validity;
}

export function getFreshAssetHealthValidity() {
  const newValidity: AssetHealthValidity = {
    valid: true,
    asFoundConditionValid: true,
    asFoundPerformanceValid: true,
    asLeftConditionValid: true,
    asLeftPerformanceValid: true,
  };
  return newValidity;
}

export function getFreshConditionValidity() {
  const newValidity: PneumaticFullStrokeConditionValidity = {
    valid: true,
    valveFrictionValid: true,
    ratedTravelValid: true,
    seatingProfileValid: true,
    dynamicErrorBandValid: true,
    driveSignalValid: true,
  };

  return newValidity;
}

export function getFreshPerformanceValidity() {
  const newValidity: PneumaticFullStrokePerformanceValidity = {
    valid: true,
    setPointTrackingValid: true,
    overshootValid: true,
    cyclingValid: true,
    speedValid: true,
    offsetValid: true,
  };

  return newValidity;
}
