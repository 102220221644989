import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { useCreateAssetMachineryComponentsRequest } from "assets/api";
import { Asset } from "assets/models/asset";
import { AssetMachineryComponent } from "assets/models/machineryComponent";
import _ from "lodash";
import { useGetAllComponentTemplatesRequest } from "machinery/api";
import { MachineryCreateForm } from "machinery/mui/MachineryCreateForm";
import { useCallback } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

interface CreateMachineryComponentsDialogProps extends BaseDialogProps {
  asset: Asset;
  onComplete?: (components: AssetMachineryComponent[]) => void;
}

const requiredMessage = "Required field";

type CreateMachineryComponentForm = {
  componentTemplateId: number;
  tabs: number;
  typeId: number;
};

export type CreateAssetComponentsForm = {
  components: CreateMachineryComponentForm[];
};

const componentFormSchema = {
  componentTemplateId: yup.number().required(requiredMessage),
  hasTabs: yup.boolean().required(requiredMessage),
  hasTypes: yup.boolean().required(requiredMessage),
  tabs: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : parseInt(value);
    })
    .when("hasTabs", {
      is: true,
      then: yup.number().nullable().required(requiredMessage),
      otherwise: yup.number().nullable(),
    }),
  typeId: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : parseInt(value);
    })
    .when("hasTypes", {
      is: true,
      then: yup.number().nullable().required(requiredMessage),
      otherwise: yup.number().nullable(),
    }),
};

const validationSchema = yup.object().shape({
  components: yup
    .array()
    .of(yup.object().shape(componentFormSchema))
    .required("Must contain at least one component")
    .min(1, "Must contain at least one component"),
});

export const CreateMachineryComponentsDialog = (props: CreateMachineryComponentsDialogProps) => {
  const createMachineryComponentRequest = useCreateAssetMachineryComponentsRequest();

  const { data: templatesData, loading: templatesLoading } = useGetAllComponentTemplatesRequest();
  const templates = templatesData ?? [];
  const templateOptions = _.orderBy(templates, (template) => template.name);

  const defaultValues: CreateAssetComponentsForm = {
    components: [],
  };
  const methods = useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    shouldUnregister: true,
  });

  // component template shorthand
  const ct = useCallback(
    (index: number) => {
      const ctId = methods.getValues(`components.${index}.componentTemplateId`);
      return templates.find((t) => t.id === ctId);
    },
    [templates, methods]
  );
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "components",
  });

  const appendComponentForm = (componentTemplateId: number) => {
    if (componentTemplateId) {
      append({
        componentTemplateId,
        typeId: undefined,
        tabs: undefined,
        hasTabs: false,
        hasTypes: false,
      });
    }
  };

  const submit = () => {
    methods.handleSubmit(
      (values) => {
        const createMachineryComponents = {
          assetId: props.asset.id,
          components: values.components.map((c: CreateMachineryComponentForm) => {
            return {
              componentTemplateId: c.componentTemplateId,
              typeId: c.typeId,
              tabs: c.tabs,
            };
          }),
        };

        createMachineryComponentRequest.call(createMachineryComponents).then((newComponents) => {
          props.onComplete?.(newComponents);
          props.onClose();
        });
      },
      (errs) => console.log(errs)
    )();
  };

  const title = (
    <Typography variant="h6" component="span">
      {"Add Components"}
    </Typography>
  );

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "450px" }}>
        {templatesLoading ? (
          <Skeleton />
        ) : (
          <FormProvider {...methods}>
            <Stack direction="column" spacing={1}>
              <FormItemLayout
                controlled
                label={"Component Type"}
                formControlProps={{ placeholder: "Select Component Type" }}
              >
                {(inputProps) => (
                  <Select {...inputProps} value="" onChange={(e) => appendComponentForm(Number(e.target?.value))}>
                    {templateOptions.map((o) => (
                      <MenuItem key={o.id} value={o.id}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormItemLayout>
              {fields.map((field, index) => (
                <MachineryCreateForm
                  key={field.id}
                  componentTemplates={templates}
                  componentTemplate={ct(index)}
                  methods={methods}
                  index={index}
                  fieldArrayName="components"
                  onRemove={remove}
                />
              ))}
            </Stack>
          </FormProvider>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton
            loading={createMachineryComponentRequest.loading}
            variant="contained"
            color="secondary"
            onClick={submit}
          >
            Submit
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
