import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import AutoSave from "app/mui/forms/AutoSave";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useCreateAssetDeviceRequest, useDeleteAssetDeviceRequest, useUpdateAssetDeviceRequest } from "assets/api";
import { useForm } from "react-hook-form";
import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { DeviceTypesLoader } from "./DeviceTypesLoader";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { Asset } from "assets/models/asset";
import { AssetDevice } from "assets/models/assetDevice";

interface AssetDeviceSpecificationCardProps {
  asset: Asset;
  device: AssetDevice;
  deviceNumber: number;
}

export const AssetDeviceSpecificationCard = (props: AssetDeviceSpecificationCardProps) => {
  const createDeviceRequest = useCreateAssetDeviceRequest(props.asset.id);
  const updateDeviceRequest = useUpdateAssetDeviceRequest(props.asset.id);
  const deleteDeviceRequest = useDeleteAssetDeviceRequest(props.asset.id);

  const defaultValues = makeSafeForReactHookForm(props.device);
  const methods = useForm({ defaultValues });

  const [deviceType, modelNumber, supplyFluid, levelControllerInterface] = methods.watch([
    "deviceType",
    "modelNumber",
    "supplyFluid",
    "levelControllerInterface",
  ]);

  const removeThisDevice = () => {
    deleteDeviceRequest.call(props.device.id);
  };

  const updateThisDevice = () => {
    methods.handleSubmit((values) => {
      // if the id is 0, then this is a dummy placeholder device,
      // we should send a create request, otherwise send an update
      // request.
      if (props.device.id === 0) {
        createDeviceRequest.call({ ...props.device, ...values, assetId: props.asset.id });
      } else {
        updateDeviceRequest.call({ ...props.device, ...values });
      }
    })();
  };

  const standardFields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="obsolete" />,
    <SpecOptionLoader type="DeviceType">
      {(options) => <EditableAutocompleteFormItem label="Type" fieldName="deviceType" {...options} />}
    </SpecOptionLoader>,
    deviceType === "Other" && (
      <DeviceTypesLoader assetType={props.asset.assetType}>
        {(options) => (
          <EditableAutocompleteFormItem label="Other type" fieldName="deviceTypeOther" freeSolo {...options} />
        )}
      </DeviceTypesLoader>
    ),

    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <SpecOptionLoader type="DeviceModelNumber">
      {(options) => <EditableAutocompleteFormItem label="Model number" fieldName="modelNumber" {...options} />}
    </SpecOptionLoader>,
    modelNumber === "Other" && <EditableTextFieldFormItem label="Other model number" fieldName="modelNumberOther" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <SpecOptionLoader type="DiagnosticTier">
      {(options) => (
        <EditableAutocompleteFormItem label="Diagnostic tier" fieldName="diagnosticTier" freeSolo {...options} />
      )}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="Supply pressure setpoint (psig)" fieldName="supplyPressureSetPoint" />,
    <EditableTextFieldFormItem label="Design bleed rate (Sm3/h)" fieldName="designBleedRate" />,
    <SpecOptionLoader type="DeviceRelayType">
      {(options) => <EditableAutocompleteFormItem label="Relay type" fieldName="relayType" {...options} />}
    </SpecOptionLoader>,
    <SpecOptionLoader type="DeviceBleedType">
      {(options) => <EditableAutocompleteFormItem label="Bleed type" fieldName="bleedType" {...options} />}
    </SpecOptionLoader>,
    <SpecOptionLoader type="DeviceSupplyFluid">
      {(options) => <EditableAutocompleteFormItem label="Supply fluid" fieldName="supplyFluid" {...options} />}
    </SpecOptionLoader>,

    supplyFluid === "Other" && <EditableTextFieldFormItem label="Other supply fluid" fieldName="supplyFluidOther" />,

    <SpecOptionLoader type="DeviceOutputPressure">
      {(options) => (
        <EditableAutocompleteFormItem label="Output pressure (psig)" fieldName="outputPressure" {...options} />
      )}
    </SpecOptionLoader>,
    <SpecOptionLoader type="DeviceInstrumentAction">
      {(options) => (
        <EditableAutocompleteFormItem label="Instrument action" fieldName="instrumentAction" {...options} />
      )}
    </SpecOptionLoader>,

    <SpecOptionLoader type="DeviceBourdonTubeRange">
      {(options) => (
        <EditableAutocompleteFormItem label="Bourdon tube range (psig)" fieldName="bourdonTubeRange" {...options} />
      )}
    </SpecOptionLoader>,

    <EditableTextFieldFormItem label="Prop, Band/Fulcrum Setting" fieldName="propBandFulcrumSetting" />,

    <SpecOptionLoader type="DeviceInstrumentActuationFrequency">
      {(options) => (
        <EditableAutocompleteFormItem
          label="Instrument Actuation Frequency"
          fieldName="instrumentActuationFrequency"
          {...options}
        />
      )}
    </SpecOptionLoader>,

    <SpecOptionLoader type="DeviceLevelControllerInterface">
      {(options) => (
        <EditableAutocompleteFormItem
          label="Level controller interface"
          fieldName="levelControllerInterface"
          {...options}
        />
      )}
    </SpecOptionLoader>,

    levelControllerInterface === "Other" && (
      <EditableTextFieldFormItem label="Other interface" fieldName="levelControllerInterfaceOther" />
    ),

    <SpecOptionLoader type="DeviceLevelControllerAction">
      {(options) => (
        <EditableAutocompleteFormItem label="Level controller action" fieldName="levelControllerAction" {...options} />
      )}
    </SpecOptionLoader>,

    <EditableTextFieldFormItem
      label="Level controller vessel connection (Size, NPT)"
      fieldName="levelControllerVesselConnection"
    />,

    <EditableTextFieldFormItem label="Emission Vent ID" fieldName="ventId" />,
  ];

  const overflowMenuItems = [
    <MenuItem
      key={"overflow-remove"}
      disabled={props.device.id === 0 || deleteDeviceRequest.loading}
      onClick={removeThisDevice}
    >
      <ListItemIcon>
        <DeleteOutlineOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Remove this device</ListItemText>
    </MenuItem>,
  ];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave defaultValues={defaultValues} onSubmit={updateThisDevice} />
      <AssetSpecificationCard
        title={`Device ${props.deviceNumber}`}
        specs={standardFields}
        overflowMenuOptions={overflowMenuItems}
      />
    </PermissionAwareFormProvider>
  );
};
