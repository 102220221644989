import { IrisColors } from "app/mui/theme";

export enum TrafficLightValue {
  Green = 1,
  Yellow = 2,
  Orange = 3,
  Red = 4,
}

export interface TrafficLightOption {
  value: TrafficLightValue | null;
  label: string;
  color: string;
}

const unknownTrafficLight = { label: "N/A", value: null, color: IrisColors.dark };

export const TrafficLightOptions = [
  { label: "Good", value: TrafficLightValue.Green, color: IrisColors.green },
  { label: "Minor", value: TrafficLightValue.Yellow, color: IrisColors.yellow },
  { label: "Moderate", value: TrafficLightValue.Orange, color: IrisColors.orange },
  { label: "Severe", value: TrafficLightValue.Red, color: IrisColors.red },
  unknownTrafficLight,
];

export const getTrafficLightOption = (val: TrafficLightValue | null): TrafficLightOption => {
  return TrafficLightOptions.find((x) => x.value === val) ?? unknownTrafficLight;
};
