import { Box, Grid, Stack, Typography } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import FormItemLabel from "app/mui/forms/FormItemLabel";
import { formItemLayoutStackProps } from "app/mui/forms/FormItemLayout";
import { ControlledTextFieldFormItem } from "app/mui/forms/Input/ControlledTextFieldFormItem";
import { NumberFormItem } from "app/mui/forms/Input/NumberFormItem";
import { TimeUnit } from "common";
import { KpiCommodityUnit } from "kpis/models";
import { ProductFormItem } from "./ProductFormItem";
import { TimeUnitFormItem, TimeUnitMode } from "./TimeUnitFormItem";
import { UnitFormItem } from "./UnitFormItem";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

export type ProductTypeInputControl = "none" | "emissions";

export interface ProductionRateProps {
  limitTo?: TimeUnit[];
  mode: TimeUnitMode;
}

interface ProductionRateFormItemProps extends Omit<BaseFormItemProps, "fieldName"> {
  quantityFieldName: string;
  unitFieldName: string;
  productFieldName: string;
  rateFieldName: string;
  productTypeInputControl?: ProductTypeInputControl;
  productionRateProps?: ProductionRateProps;
  units: KpiCommodityUnit[];
  // This flag makes units readonly to mirror units from elsewhere
  quantityOnly?: boolean;
}

/**
 * From left to right the fields are:
 * quantityFieldName, unitFIeldName, productFieldName, rateFieldName
 * @param props productionRateProps is required to show the rate Field
 * @returns
 */
function ProductionRateFormItem(props: ProductionRateFormItemProps) {
  const {
    label,
    quantityFieldName,
    unitFieldName,
    productFieldName,
    rateFieldName,
    productTypeInputControl = "none",
    productionRateProps,
    units,
    quantityOnly,
    readOnly,
    ...inputProps
  } = props;

  const methods = useFormContext();
  const unitFieldValue = methods.watch(unitFieldName);

  useEffect(() => {
    if (!!!unitFieldValue || units.includes(unitFieldValue)) {
      return;
    }
    methods.setValue(unitFieldName, null, { shouldDirty: true });
  }, [units]);

  return (
    <Stack {...formItemLayoutStackProps}>
      <FormItemLabel label={label} />
      <Box>
        <Grid container columnSpacing={1} rowSpacing={2}>
          <Grid item xs={4} lg={2} alignContent="flex-start">
            <NumberFormItem
              fieldName={quantityFieldName}
              numberFormatInputProps={{ placeholder: "Quantity" }}
              label={null}
              readOnly={readOnly}
              {...inputProps}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <UnitFormItem
              units={units}
              fieldName={unitFieldName}
              label={null}
              {...inputProps}
              placeholder="Select unit"
              readOnly={readOnly || quantityOnly}
            />
          </Grid>
          <Grid item xs={2} lg="auto">
            <Box pt={"0.5em"}>
              <Typography>of</Typography>
            </Box>
          </Grid>
          {productTypeInputControl === "emissions" && (
            <Grid item xs={true} lg={true}>
              <ProductFormItem
                productTypeInputControl={productTypeInputControl}
                fieldName={productFieldName}
                label={null}
                readOnly={readOnly || quantityOnly}
                placeholder="Select Product"
                {...inputProps}
              />
            </Grid>
          )}
          {productTypeInputControl === "none" && (
            <Grid item xs={true}>
              <ControlledTextFieldFormItem
                alwaysShowInput
                fieldName={productFieldName}
                label={null}
                readOnly={readOnly || quantityOnly}
                textFieldProps={{ placeholder: "Product name" }}
                {...inputProps}
              />
            </Grid>
          )}
          {productionRateProps && (
            <Grid item xs={5} lg={3}>
              <Grid container columnSpacing={1}>
                {productionRateProps.mode === "Per" && (
                  <Grid item xs="auto">
                    <Box pt={"0.5em"}>
                      <Typography>per</Typography>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={true}>
                  <TimeUnitFormItem
                    fieldName={rateFieldName}
                    label={null}
                    readOnly={readOnly || quantityOnly}
                    {...inputProps}
                    {...productionRateProps}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Stack>
  );
}

export default ProductionRateFormItem;
