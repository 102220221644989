import React, { useEffect, useState } from "react";
import { KpiCommodityUnitsForProduction, KpiOneTimeDowntimeAvoidance } from "../models";
import { NumberInputGroup } from "records/bs/NumberInputGroup";
import { Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import { getInvalidValidity, getValidValidity, Validity } from "common";
import classNames from "classnames";
import { EditKpiFormProps } from "./EditKpiFormProps";
import NumberFormat from "react-number-format";
import { ProductionRateFormGroup } from "kpis/formFields/ProductionRateFormGroup";
import { CommodityPricePerUnitFormGroup } from "kpis/formFields/CommodityPricePerUnitFormGroup";
import { KpiHelpTooltip } from "kpis/formFields/KpiHelpTooltip";
import { DelayTypeLocationAndActionsTakenRow } from "kpis/formFields/DelayTypeLocationAndActionsTakenRow";
import { SelectTimeUnit } from "common/fields/SelectTimeUnit";

interface Props extends EditKpiFormProps {}

export const probabilityOfDowntimeOptions: { value: number; label: string }[] = [
  { value: 17, label: "Low (1%-33%)" },
  { value: 50, label: "Medium (34%-66%)" },
  { value: 83, label: "High (67%-99%)" },
  { value: 100, label: "Certain (100%)" },
];
export interface KpiOneTimeDowntimeAvoidanceValidity {
  potentialDelayType: Validity;
  location: Validity;
  actionsTaken: Validity;
  commodityProductionRate: Validity;
  commodityUnit: Validity;
  commodityType: Validity;
  commodityProductionRateTimeUnit: Validity;
  commodityPricePerUnit: Validity;
  potentialDowntime: Validity;
  potentialDowntimeTimeUnit: Validity;
  probabilityOfDowntime: Validity;
}

export const getKpiOneTimeDowntimeAvoidanceValidity = (
  fields: KpiOneTimeDowntimeAvoidance
): KpiOneTimeDowntimeAvoidanceValidity => {
  return {
    potentialDelayType: getValidValidity(),
    location: getValidValidity(),
    actionsTaken: getValidValidity(),
    commodityProductionRate: (fields.commodityProductionRate || 0) > 0 ? getValidValidity() : getInvalidValidity(),
    commodityUnit:
      !!fields.commodityUnit && KpiCommodityUnitsForProduction.indexOf(fields.commodityUnit) >= 0
        ? getValidValidity()
        : getInvalidValidity(),
    commodityType: !!fields.commodityType ? getValidValidity() : getInvalidValidity(),
    commodityProductionRateTimeUnit: !!fields.commodityProductionRateTimeUnit
      ? getValidValidity()
      : getInvalidValidity(),
    commodityPricePerUnit: (fields.commodityPricePerUnit || 0) > 0 ? getValidValidity() : getInvalidValidity(),
    potentialDowntime:
      !!fields.potentialDowntime && fields.potentialDowntime > 0 ? getValidValidity() : getInvalidValidity(),
    potentialDowntimeTimeUnit: !!fields.potentialDowntimeTimeUnit ? getValidValidity() : getInvalidValidity(),
    probabilityOfDowntime: !!fields.probabilityOfDowntime ? getValidValidity() : getInvalidValidity(),
  };
};

export const EditKpiOneTimeDowntimeAvoidanceForm: React.FunctionComponent<Props> = (props) => {
  const model = props.model as KpiOneTimeDowntimeAvoidance;

  const [validity, setValidity] = useState<KpiOneTimeDowntimeAvoidanceValidity>(
    getKpiOneTimeDowntimeAvoidanceValidity(model)
  );

  const validate = (fields: KpiOneTimeDowntimeAvoidance) => {
    const newValidity = getKpiOneTimeDowntimeAvoidanceValidity(fields);
    setValidity(newValidity);
    return _.values(newValidity).filter((v) => !v.valid).length === 0;
  };

  useEffect(() => {
    if (!!props.onLoaded) {
      const isValid = _.values(validity).filter((v) => !v.valid).length === 0;
      props.onLoaded(isValid);
    }
  }, []);

  const saveChange = (updates: Partial<KpiOneTimeDowntimeAvoidance>) => {
    const updatedFields = { ...model, ...updates };
    const isValid = validate(updatedFields);
    props.onUpdated(updatedFields, isValid);
  };

  const selectedProbabilityOfDowntime = !!model.probabilityOfDowntime
    ? _.first(probabilityOfDowntimeOptions.filter((o) => o.value === model.probabilityOfDowntime)) || {
        label: `${model.probabilityOfDowntime}`,
        value: model.probabilityOfDowntime,
      }
    : null;

  return (
    <>
      <Row className="gray-form-section">
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={6}>
              <p className="form-section-header">Potential downtime cost</p>
            </Col>
            <Col xs={12} sm={6} className="totals">
              <div>
                <p className="figure">
                  <NumberFormat
                    displayType="text"
                    value={props.detail?.calculatedSavings || 0}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <div style={{ display: "flex", gap: "0 10px" }}>
                <ProductionRateFormGroup
                  label="Production rate (quantity/unit/product/rate)"
                  style={{ flexGrow: 1 }}
                  showValidationErrors={props.showValidationErrors}
                  productionRateValue={model.commodityProductionRate}
                  setProductionRate={(commodityProductionRate) => saveChange({ commodityProductionRate })}
                  productionRateValidity={validity.commodityProductionRate}
                  productionRateReadOnly={props.readOnly}
                  productionRateTimeUnitValue={model.commodityProductionRateTimeUnit}
                  setProductionRateTimeUnit={(commodityProductionRateTimeUnit) =>
                    saveChange({ commodityProductionRateTimeUnit })
                  }
                  productionRateTimeUnitValidity={validity.commodityProductionRateTimeUnit}
                  productionRateTimeUnitReadOnly={props.readOnly}
                  typeValue={model.commodityType}
                  setType={(commodityType) => saveChange({ commodityType })}
                  typeValidity={validity.commodityType}
                  typeReadOnly={props.readOnly}
                  unitValue={model.commodityUnit}
                  units={KpiCommodityUnitsForProduction}
                  unitValidity={validity.commodityUnit}
                  unitReadOnly={props.readOnly}
                  setUnit={(commodityUnit) => saveChange({ commodityUnit })}
                />
                <CommodityPricePerUnitFormGroup
                  commodityPricePerUnitValidity={validity.commodityPricePerUnit}
                  commodityPricePerUnitValue={model.commodityPricePerUnit}
                  commodityTypeValue={model.commodityType}
                  readOnly={props.readOnly}
                  setCommodityPricePerUnit={(newValue) =>
                    saveChange({
                      commodityPricePerUnit: newValue,
                    })
                  }
                  showValidationErrors={props.showValidationErrors}
                  isOngoing={false}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={8} md={6}>
              <FormGroup>
                <Label>Potential downtime avoided</Label>
                <div className="form-group-wrapper">
                  <NumberInputGroup
                    value={model.potentialDowntime}
                    onChange={(newValue) =>
                      saveChange({
                        potentialDowntime: newValue,
                      })
                    }
                    valid={!props.showValidationErrors || validity.potentialDowntime.valid}
                    validationError={validity.potentialDowntime.message}
                    readOnly={props.readOnly}
                    style={{ width: "90px" }}
                  />
                  <SelectTimeUnit
                    mode="Plural"
                    value={model.potentialDowntimeTimeUnit}
                    readOnly={props.readOnly}
                    saveChange={(timeUnit) => saveChange({ potentialDowntimeTimeUnit: timeUnit })}
                    showValidationErrors={props.showValidationErrors}
                    validity={validity.potentialDowntimeTimeUnit}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <FormGroup>
                <Label>Probability of downtime</Label>
                <div className="form-group-input-wrapper">
                  <Select
                    className={classNames("react-select", {
                      "is-invalid": props.showValidationErrors && !validity.probabilityOfDowntime.valid,
                    })}
                    classNamePrefix="react-select"
                    value={selectedProbabilityOfDowntime}
                    onChange={(selectedOption) =>
                      saveChange({
                        probabilityOfDowntime: !!selectedOption ? selectedOption.value : null,
                      })
                    }
                    options={probabilityOfDowntimeOptions}
                    getOptionLabel={(option) => option.label}
                    isDisabled={props.readOnly}
                  />
                  <KpiHelpTooltip type="probabilityOfDowntime" />
                </div>
                <div className="invalid-feedback">{validity.probabilityOfDowntime.message}</div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <DelayTypeLocationAndActionsTakenRow
        fields={model}
        readOnly={props.readOnly}
        saveChange={saveChange}
        showValidationErrors={props.showValidationErrors}
        validity={validity}
      />
    </>
  );
};
