import React, { useCallback, useEffect, useRef } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { useUpdateSiteRequest } from "sites/manage/api";
import { Site } from "sites/models";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { createStaticColumns, useColumns } from "app/mui/tables/utils/createStaticColumns";
import { SimpleDeleteModal } from "app/mui/common/modals/SimpleDeleteModal";
import { useModal } from "app/mui/common/useModal";

interface UsersTabProps {
  site: Site;
}

const columnDef = createStaticColumns((actionDispatcher) => [
  {
    field: "fullName",
    headerName: "Full Name",
    width: 250,
    renderCell: (params) => {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          {" "}
          <Avatar src={params.row.picture} sx={{ width: "32px", height: "32px" }} />
          <Typography variant="body2">{params.value}</Typography>
        </Stack>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
  {
    type: "actions",
    field: "actions",
    width: 60,
    hideable: false,
    getActions: (params: any) => [
      <GridActionsCellItem
        icon={<DeleteRoundedIcon />}
        label={"Remove user"}
        onClick={() => {
          actionDispatcher.dispatch("remove-user", params);
        }}
        showInMenu
        placeholder={undefined}
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      />,
    ],
  },
]);

export const UsersTab = (props: UsersTabProps) => {
  const actionDispatcher = useActionDispatcher();
  const columns = useColumns(columnDef, actionDispatcher);

  const updateSiteRequest = useUpdateSiteRequest();
  const userIdToDelete = useRef<string>();

  const onActionReceived = useCallback((action: string, params: any) => {
    if (action === "remove-user") {
      userIdToDelete.current = params.id;
      openModal();
    }
  }, []);

  useEffect(() => {
    actionDispatcher.subscribe(onActionReceived);
    return () => actionDispatcher.unsubscribe(onActionReceived);
  }, []);

  const { modalProps, openModal } = useModal();

  const handleDelete = () => {
    const userId = userIdToDelete.current;

    const newSite = {
      ...props.site,
      team: props.site.team.filter((user) => user.userId !== userId),
    };

    updateSiteRequest.call(newSite);

    userIdToDelete.current = undefined;
  };

  return (
    <Box sx={{ height: "500px", my: 1 }}>
      <StripedDataGrid
        getRowId={(row) => row.userId}
        columns={columns}
        rows={props.site.team}
        initialState={{
          pinnedColumns: {
            right: ["actions"],
          },
        }}
      />
      <SimpleDeleteModal
        {...modalProps}
        title="Remove user?"
        confirmButtonText="Remove user"
        onConfirm={handleDelete}
      />
    </Box>
  );
};
