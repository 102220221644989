import { Button, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CustomDataViewScopes, getCustomDataViewScopeOptions, importCustomDataViewScopeOptions } from "./models";
import { FormProvider, useForm } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { useSiteContext } from "app/contexts";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { TabsApi } from "../useTabbedViews";

export interface NewViewModalData {
  name: string;
  scope: CustomDataViewScopes;
  duplicate: boolean;
  importedState?: string;
}

interface NewViewModalProps {
  open: boolean;
  onClose: VoidFunction;
  modalData: NewViewModalData;
  tabsApi: TabsApi;
}

export const NewViewModal = (props: NewViewModalProps) => {
  const { currentSite } = useSiteContext();
  const { open, onClose, modalData, tabsApi } = props;

  const appliesToOptions = currentSite
    ? modalData.importedState
      ? importCustomDataViewScopeOptions(currentSite)
      : getCustomDataViewScopeOptions(currentSite)
    : [];

  const methods = useForm({ defaultValues: { name: modalData.name, scope: modalData.scope } });

  const onSubmit = () => {
    methods.handleSubmit(async (values) => {
      let view: string | undefined = undefined;

      if (modalData.importedState) {
        view = modalData.importedState;
      } else if (modalData.duplicate) {
        view = tabsApi.exportView();
      }

      await tabsApi.createNewView({ name: values.name, scope: values.scope, viewState: view });

      onClose();
    })();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <DialogTitle>{modalData.importedState ? "Import View" : "New View"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextFieldFormItem label="Name" fieldName="name" textFieldProps={{ autoFocus: true }} />

            {currentSite && (
              <AutocompleteFormItem
                label="Applies to"
                options={appliesToOptions}
                fieldName={"scope"}
                autocompleteProps={{ disableClearable: true, disablePortal: false }}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSubmit} variant="contained" color="secondary">
            Create View
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
