import { useNotifications } from "app";
import { AttachmentParent, useAddAttachmentRequest } from "attachments/api";
import imageCompression from "browser-image-compression";
import React, { useRef, useState } from "react";
import { useCanEditAttachment } from "users";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import DragAndDropPane from "./DragAndDropPane";

interface AttachmentUploadingProps {
  parentType: AttachmentParent;
  parentId: number;
  children: (params: { openUploadDialog: () => void; currentlyUploadingCount: number }) => React.ReactNode;
  disabled?: boolean;
}

const compressImage = async (file: File): Promise<File> => {
  const options = { maxSizeMB: 0.5, maxWidthOrHeight: 1920, useWebWorker: false };
  const compressedFileBlob = await imageCompression(file, options);

  return new File([compressedFileBlob], file.name, { type: file.type, lastModified: file.lastModified });
};

export const AttachmentUploading = (props: AttachmentUploadingProps) => {
  const canEdit = useCanEditAttachment(props.parentType) || props.disabled;
  const notifications = useNotifications();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const addAttachmentRequest = useAddAttachmentRequest();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [uploadCount, setUploadCount] = useState<number>(0);

  const attachmentsAreaRef = useRef(null);

  const openUploadDialog = () => {
    fileInputRef?.current?.click();
  };

  const onFileUpload = () => {
    if (!canEdit) {
      return false;
    }
    const files = fileInputRef.current?.files ?? new FileList();
    uploadFileList(files);
  };

  const uploadFileList = async (files: FileList) => {
    if (files.length === 0) {
      return;
    }

    setUploadCount((count) => count + files.length);

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      await uploadSingleFile(file).finally(() => setUploadCount((count) => count - 1));
    }
  };

  const uploadSingleFile = async (file: File) => {
    if (/\.(gif|jpe?g|png|webp|bmp)$/i.test(file.name)) {
      try {
        file = await compressImage(file);
      } catch (error: any) {
        notifications.error(error);
        return;
      }
    }

    const formData = new FormData();
    switch (props.parentType) {
      case "Application":
        formData.set("ApplicationId", props.parentId.toString());
        break;
      case "Asset":
        formData.set("AssetId", props.parentId.toString());
        break;
      case "Record":
        formData.set("RecordId", props.parentId.toString());
        break;
      case "Summary":
        formData.set("SummaryId", props.parentId.toString());
        break;
      case "PackagedSolution":
        formData.set("PackagedSolutionId", props.parentId.toString());
        break;
    }

    formData.append("Attachment", file);

    await addAttachmentRequest.call(formData);
  };

  return (
    <div ref={attachmentsAreaRef}>
      <input
        disabled={!canEdit}
        ref={fileInputRef}
        type="file"
        multiple
        accept={"*"}
        onChange={onFileUpload}
        style={{
          position: "absolute",
          visibility: "hidden",
          width: 0,
          height: 0,
        }}
      />

      {canEdit && !isMobile && <DragAndDropPane handleFilesDropped={uploadFileList} />}
      {props.children({ openUploadDialog: openUploadDialog, currentlyUploadingCount: uploadCount })}
    </div>
  );
};
