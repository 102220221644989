import {
   Box,
   Button,
   Chip,
   Link,
   Paper,
   Stack,
} from "@mui/material";
import { GridFooterContainer } from "@mui/x-data-grid-pro";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { createStaticColumns, useColumns } from "app/mui/tables/utils/createStaticColumns";
import { formatRoutePath, Routes } from "app/routes";
import { Link as RouterLink, useMatch, useNavigate } from "react-router-dom";
import { useGetOfflineDataConfirmRequest } from "importOffline/api";
import Add from "@mui/icons-material/Add";
import { useSiteContext } from "app";

export const ImportOfflineConfirm = () => {

   const actionDispatcher = useActionDispatcher();
   const navigate = useNavigate();

   const routeMatch = useMatch(Routes.ImportOfflineConfirm);
   const importId = Number(routeMatch?.params?.id);
   const site = useSiteContext().currentSite!;
   const importOfflinePage = formatRoutePath(Routes.ImportOffline, { siteId: site.id });

   const getOfflineDataConfirmRequest = useGetOfflineDataConfirmRequest(importId);
   const offlineDataConfirm = getOfflineDataConfirmRequest.data || [];

   const columnDef = createStaticColumns(() => [
      {
         field: "offlineAssetTags",
         headerName: "Offline Asset Tags",
         valueGetter: (params) => params.row.offlineAssetTag,
         flex: 1,
      },
      {
         field: "action",
         headerName: "Action",
         flex: 1,
         type: "string",
         valueGetter: (params) => params.row.importAction,
      },
      {
         field: "onlineAssetTags",
         headerName: "Online Asset Tags",
         flex: 1,
         renderCell: (params) => {
            return (
               params.row.onlineAssetTag &&
               <Link
                  color="secondary"
                  component={RouterLink}
                  to={formatRoutePath(Routes.Asset, params.row.onlineAssetTag)}
                  sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
                  state={{ returnTo: window.location.pathname }}
               >
                  {params.row.onlineAssetTag.name}
               </Link >
            );
         },
      },
      {
         field: "newAssetTags",
         headerName: "New Asset Tags",
         flex: 1,
         type: "string",
         valueGetter: (params) => params.row.newAssetTag,
      },
      {
         field: "recordCreation",
         headerName: "Record Creation",
         flex: 1,
         renderCell: (params) => {
            return (
               params.row.record &&
               <Link
                  color="secondary"
                  component={RouterLink}
                  to={formatRoutePath(Routes.Record, params.row.record)}
                  sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
                  state={{ returnTo: window.location.pathname }}
               >
                  {params.row.record.name}
               </Link >
            );
         },
      },
      {
         field: "photos",
         headerName: "Photos",
         flex: 1,
         type: "number",
         valueGetter: (params) => params.row.photosCount,
      },
   ]);

   const columns = useColumns(columnDef, actionDispatcher);

   const renderFooter = () => (
      <GridFooterContainer>
         <Stack width="100%" direction="row" justifyContent="flex-end" spacing={2}>
            <Button
               variant="contained"
               color="secondary"
               onClick={() => navigate(importOfflinePage)}>
               done
            </Button>
         </Stack>
      </GridFooterContainer >
   );

   return (
      <Box sx={{ width: "100%" }}>
         <Paper sx={{ p: 2 }}>
            <Stack
               direction="row"
               justifyContent="flex-end"
               alignItems="center"
               spacing={2}
               sx={{ m: "10px" }}
            >
               <Chip icon={<Add />} label={`${offlineDataConfirm.length}/${offlineDataConfirm.length} assets to create`} />
            </Stack>
            <Box sx={{ width: "100%", height: "700px" }}>
               <StripedDataGrid
                  density="compact"
                  components={{
                     Footer: renderFooter,
                  }}
                  columns={columns}
                  rows={offlineDataConfirm}
               />
            </Box>
         </Paper>
      </Box >

   );
};
