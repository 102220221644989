import { TrafficLightValue } from "records/models";

export interface PneumaticFullStrokeCondition {
  valveFriction: TrafficLightValue | null;
  ratedTravel: TrafficLightValue | null;
  seatingProfile: TrafficLightValue | null;
  dynamicErrorBand: TrafficLightValue | null;
  driveSignal: TrafficLightValue | null;
}

export interface PneumaticFullStrokeConditionValidity {
  valid: boolean;

  valveFrictionValid: boolean;
  ratedTravelValid: boolean;
  seatingProfileValid: boolean;
  dynamicErrorBandValid: boolean;
  driveSignalValid: boolean;
}

export enum PneumaticFullStrokeConditionFieldLabel {
  ValveFriction = "Valve friction",
  RatedTravel = "Rated travel",
  SeatingProfile = "Seating profile",
  DynamicErrorBand = "Dynamic error band",
  DriveSignal = "Drive signal",
}

export enum PneumaticFullStrokeConditionTooltip {
  ValveFriction = "Assess the frictional forces present within full stroke operation by comparing against expected values",
  RatedTravel = "Assess the ability to meet expected travel range between fully closed and open position",
  SeatingProfile = "Assess the area of the curve as the valve transitions into the seating area",
  DynamicErrorBand = "Assess the hysteresis and dead band present in opening and closing signal lines, by comparing offset",
  DriveSignal = "Assess the effort level of the positioner to maintain control and/or reach desired set point",
}
