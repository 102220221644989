import React, { useState, useEffect } from "react";
import { Box, Grid, Avatar, Paper, Stack, Typography } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { Summary, SummaryDetail, SummaryStatus, SummaryType } from "summaries/models";
import { useUserContext, useSiteIdFromRoute } from "app";
import moment from "moment";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { useGetUsersInSiteRequest, User } from "users";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { dateFormat } from "common";

interface SummaryDetailSectionProps {
  summaryDetail: SummaryDetail;
  methods: UseFormReturn<Summary, any>;
  saveChanges: () => void;
}

export const SummaryDetails: React.FC<SummaryDetailSectionProps> = ({ summaryDetail, methods, saveChanges }) => {
  const summary = summaryDetail.model;
  const { userCanEditSummary } = useUserContext().userPermissions;
  const siteId = useSiteIdFromRoute()!;

  const [isClosed, setIsClosed] = useState(summary.status === SummaryStatus.Closed);

  const { data: users, loading } = useGetUsersInSiteRequest(siteId);

  useEffect(() => {
    setIsClosed(summary.status === SummaryStatus.Closed);
  }, [summary.status]);

  if (loading || users === null) return null;

  const selectedUsers = summary.reportBy;
  const availableUsers = users.filter(
    (user) => !selectedUsers.some((selectedUser: User) => selectedUser.userId === user.userId)
  );

  return (
    <Paper>
      <ExpansionPanel title="DETAILS" defaultExpanded={true}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Stack spacing={2}>
                {isClosed ? (
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      Date
                    </Typography>
                    <Typography variant="body1">
                      {moment(summaryDetail.model.summaryDate).format(dateFormat)}
                    </Typography>
                  </Box>
                ) : (
                  <DateInputFormItem fieldName="summaryDate" label="Date" readOnly={isClosed || !userCanEditSummary} />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Created by
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ pt: 1 }}>
                    <Avatar
                      sx={{ width: 30, height: 30, fontSize: 12, mr: 1 }}
                      src={summaryDetail.createdBy.picture}
                      alt={summaryDetail.createdBy.fullName}
                    />
                    <Typography variant="body1">{summaryDetail.createdBy.fullName}</Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={2}>
                {isClosed && summaryDetail.closedBy && (
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      Closed by
                    </Typography>
                    <Box display="flex" alignItems="center" sx={{ pt: 1 }}>
                      <Avatar
                        sx={{ width: 30, height: 30, fontSize: 12, mr: 1 }}
                        src={summaryDetail.closedBy.picture}
                        alt={summaryDetail.closedBy.fullName}
                      />
                      <Typography variant="body1">{summaryDetail.closedBy.fullName}</Typography>
                    </Box>
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={2}>
                <TextFieldFormItem
                  fieldName="customerContact"
                  label="Customer contact"
                  readOnly={isClosed || !userCanEditSummary}
                />
              </Stack>
            </Grid>
            {summary.summaryType === SummaryType.General && (
              <Grid item xs={12} md={4}>
                <TextFieldFormItem fieldName="refWO" label="Ref. WO/MOC" readOnly={isClosed || !userCanEditSummary} />
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <Stack spacing={2}>
                {(summary.summaryType === SummaryType.General || summary.summaryType === SummaryType.OutageScope) && (
                  <AutocompleteFormItem
                    fieldName="reportBy"
                    label="Report by"
                    options={availableUsers}
                    getOptionLabel={(user: User) => user.fullName}
                    autocompleteProps={{
                      disabled: isClosed || !userCanEditSummary,
                      multiple: true,
                    }}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </ExpansionPanel>
    </Paper>
  );
};
