import { PneumaticPartialStrokeCondition } from "./pneumaticPartialStrokeCondition";
import { PneumaticPartialStrokePerformance } from "./pneumaticPartialStrokePerformance";
import { TrafficLightValue } from "./trafficLight";

type ReportCardCategory = "asFound" | "asLeft";
type ReportCardPneumaticPartialStrokeConditionAttribute =
  `${ReportCardCategory}Condition_${keyof PneumaticPartialStrokeCondition}`;
type ReportCardPneumaticPartialStrokePerformanceAttribute =
  `${ReportCardCategory}Performance_${keyof PneumaticPartialStrokePerformance}`;
type ReportCardPneumaticPartialStrokeFields = {
  [k in
    | ReportCardPneumaticPartialStrokeConditionAttribute
    | ReportCardPneumaticPartialStrokePerformanceAttribute]: TrafficLightValue | null;
};

export type ReportCardPneumaticPartialStroke = ReportCardPneumaticPartialStrokeFields & {
  id: number;
  recordId: number;
};

export const createNewReportCardPneumaticPartialStroke = (recordId: number): ReportCardPneumaticPartialStroke => ({
  id: -1,
  recordId,
  asFoundCondition_torqueThrust: null,
  asFoundCondition_breakoutPressure: null,
  asFoundCondition_endPointProfile: null,
  asFoundCondition_dynamicErrorBand: null,
  asFoundCondition_driveSignal: null,
  asLeftCondition_torqueThrust: null,
  asLeftCondition_breakoutPressure: null,
  asLeftCondition_endPointProfile: null,
  asLeftCondition_dynamicErrorBand: null,
  asLeftCondition_driveSignal: null,

  asFoundPerformance_minMaxTravel: null,
  asFoundPerformance_rampRate: null,
  asFoundPerformance_travelDeviation: null,
  asFoundPerformance_breakoutTime: null,
  asFoundPerformance_testDuration: null,
  asLeftPerformance_minMaxTravel: null,
  asLeftPerformance_rampRate: null,
  asLeftPerformance_travelDeviation: null,
  asLeftPerformance_breakoutTime: null,
  asLeftPerformance_testDuration: null,
});
