import { TemplateKpiTypes, toKpiTypeDisplayName } from "kpis";
import React, { useRef, useState } from "react";
import { FunctionComponent } from "react";
import { Site } from "sites";
import { useDownloadCsvTemplateRequest } from "./api";
import { ImportType } from "./models";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ValueLabelOption } from "common";
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, ButtonGroup, Button } from "@mui/material";
import { AssetTypes, toAssetTypeDisplay } from "assets/models/assetType";

const assetTypeOptions = AssetTypes.map((value) => ({ value, label: toAssetTypeDisplay(value) }));
const kpiTypeOptions = TemplateKpiTypes.map((value) => ({ value, label: toKpiTypeDisplayName(value) }));
const recordsOptions = [{ value: "Records", label: "Records" }];
const getOptionsForImportType = (importType: ImportType) =>
  importType === "Assets" ? assetTypeOptions : importType === "KPIs" ? kpiTypeOptions : recordsOptions;

export const DownloadCsvTemplate: FunctionComponent<{ importType: ImportType; site: Site }> = (props) => {
  const downloadTemplateRequest = useDownloadCsvTemplateRequest(props.site, props.importType);

  const options = getOptionsForImportType(props.importType);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (options.length === 1) downloadTemplateRequest.call([]);
    else handleToggle();
  };

  const handleMenuItemClick = (option: ValueLabelOption) => {
    downloadTemplateRequest.call([option.value], option.label);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button onClick={handleClick} disabled={downloadTemplateRequest.loading}>
          Download template
        </Button>
        {options.length > 1 && (
          <Button
            disabled={downloadTemplateRequest.loading}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option) => (
                    <MenuItem key={option.value} onClick={(event) => handleMenuItemClick(option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
