import React, { useEffect, useState } from "react";
import { KpiLegacy } from "../models";
import { NumberInputGroup } from "records/bs/NumberInputGroup";
import { Card, CardSubtitle, Col, FormGroup, Row } from "reactstrap";
import _ from "lodash";
import { Checkbox, getInvalidValidity, getValidValidity, isInRange, Validity } from "common";
import { EditKpiFormProps } from "./EditKpiFormProps";
import { RecordType } from "records";
import { KpiOutputGroup } from "kpis/formFields/KpiOutputGroup";

interface Props extends EditKpiFormProps {}

export interface KpiLegacyValidity {
  potentialProductionRisk: Validity;
  potentialProductionRiskProbability: Validity;
  actualProductionRisk: Validity;

  potentialMaintenanceCost: Validity;
  actualMaintenanceCost: Validity;

  currentProductionImpact: Validity;
  currentMaintenanceImpact: Validity;
  costToImplement: Validity;

  actualStartUpDelay: Validity;

  potentialStartUpDelay: Validity;
  startUpDelayProbability: Validity;
}

export const getKpiLegacyValidity = (fields: KpiLegacy): KpiLegacyValidity => {
  return {
    potentialProductionRisk: getValidValidity(),
    actualProductionRisk: getValidValidity(),
    actualMaintenanceCost: getValidValidity(),
    potentialMaintenanceCost: getValidValidity(),
    currentProductionImpact: getValidValidity(),
    currentMaintenanceImpact: getValidValidity(),
    costToImplement: getValidValidity(),
    potentialStartUpDelay: getValidValidity(),
    actualStartUpDelay: getValidValidity(),
    startUpDelayProbability: isInRange(fields.startUpDelayProbability, 0, 1)
      ? getValidValidity()
      : getInvalidValidity(),
    potentialProductionRiskProbability: isInRange(fields.potentialProductionRiskProbability, 0, 1)
      ? getValidValidity()
      : getInvalidValidity(),
  };
};

export const includeStartUpDelay = (recordType: RecordType) => [RecordType.CandSU].includes(recordType);
export const includeProductionRisk = (recordType: RecordType) =>
  [
    RecordType.Reactive,
    RecordType.Preventative,
    RecordType.Predictive,
    RecordType.Activity,
    RecordType.ValveDiagnostic,
    RecordType.FieldInspection,
    RecordType.CandSU,
  ].includes(recordType);
export const includeMaintenanceCost = (recordType: RecordType) =>
  [
    RecordType.Reactive,
    RecordType.Preventative,
    RecordType.Predictive,
    RecordType.Activity,
    RecordType.ValveDiagnostic,
    RecordType.FieldInspection,
    RecordType.CandSU,
  ].includes(recordType);
export const includeProactiveOpportunity = (recordType: RecordType) => [RecordType.Proactive].includes(recordType);

export const EditKpiLegacyForm: React.FunctionComponent<Props> = (props) => {
  const model = props.model as KpiLegacy;
  const [validity, setValidity] = useState<KpiLegacyValidity>(getKpiLegacyValidity(model));
  const serverDetail = props.detail;
  const serverVersion = serverDetail.model as KpiLegacy;

  const validate = (fields: KpiLegacy) => {
    const newValidity = getKpiLegacyValidity(fields);
    setValidity(newValidity);
    return _.values(newValidity).filter((v) => !v.valid).length === 0;
  };

  useEffect(() => {
    if (!!props.onLoaded) {
      const isValid = _.values(validity).filter((v) => !v.valid).length === 0;
      props.onLoaded(isValid);
    }
  }, []);

  const saveChange = (updates: Partial<KpiLegacy>) => {
    const updatedFields = { ...model, ...updates };
    const isValid = validate(updatedFields);
    props.onUpdated(updatedFields, isValid);
  };

  function renderStartUpDelay() {
    return (
      <Col sm={6} lg={4} xl={3}>
        <Card body>
          <CardSubtitle>Start-up delay</CardSubtitle>
          <NumberInputGroup
            hours
            label="Potential delay"
            value={model.potentialStartUpDelay}
            onChange={(newValue) =>
              saveChange({
                potentialStartUpDelay: newValue,
              })
            }
            valid={validity.potentialStartUpDelay.valid}
            readOnly={props.readOnly}
          />
          <NumberInputGroup
            percentage
            label="Delay probability"
            value={model.startUpDelayProbability}
            onChange={(newValue) =>
              saveChange({
                startUpDelayProbability: newValue,
              })
            }
            valid={validity.startUpDelayProbability.valid}
            readOnly={props.readOnly}
          />
          <NumberInputGroup
            hours
            label="Actual delay"
            value={model.actualStartUpDelay}
            onChange={(newValue) =>
              saveChange({
                actualStartUpDelay: newValue,
              })
            }
            valid={validity.actualStartUpDelay.valid}
            readOnly={props.readOnly}
          />

          <KpiOutputGroup label="Delay avoided" value={serverVersion.startUpDelayAvoided} units="h" emphasize={true} />
        </Card>
      </Col>
    );
  }

  function renderProductionRisk() {
    return (
      <Col sm={6} lg={4} xl={3}>
        <Card body>
          <CardSubtitle>Production risk avoidance</CardSubtitle>
          <NumberInputGroup
            currency
            label="Potential"
            value={model.potentialProductionRisk}
            onChange={(newValue) =>
              saveChange({
                potentialProductionRisk: newValue,
              })
            }
            valid={validity.potentialProductionRisk.valid}
            readOnly={props.readOnly}
          />

          <NumberInputGroup
            percentage
            label="Probability"
            value={model.potentialProductionRiskProbability}
            onChange={(newValue) =>
              saveChange({
                potentialProductionRiskProbability: newValue,
              })
            }
            valid={validity.potentialProductionRiskProbability.valid}
            readOnly={props.readOnly}
          />

          <KpiOutputGroup label="Plausible" value={serverVersion.plausibleProductionRisk} />

          <NumberInputGroup
            currency
            label="Actual"
            value={model.actualProductionRisk}
            onChange={(newValue) =>
              saveChange({
                actualProductionRisk: newValue,
              })
            }
            valid={validity.actualProductionRisk.valid}
            readOnly={props.readOnly}
          />

          <KpiOutputGroup label="Risk avoided" value={serverVersion.productionRiskAvoided} emphasize={true} />
        </Card>
      </Col>
    );
  }

  function renderMaintenanceEfficiency() {
    return (
      <Col sm={6} lg={4} xl={3}>
        <Card body>
          <CardSubtitle>Maintenance efficiency</CardSubtitle>
          <NumberInputGroup
            currency
            label="Potential cost"
            value={model.potentialMaintenanceCost}
            onChange={(newValue) =>
              saveChange({
                potentialMaintenanceCost: newValue,
              })
            }
            valid={validity.potentialMaintenanceCost.valid}
            readOnly={props.readOnly}
          />
          <NumberInputGroup
            currency
            label="Actual cost"
            value={model.actualMaintenanceCost}
            onChange={(newValue) =>
              saveChange({
                actualMaintenanceCost: newValue,
              })
            }
            valid={validity.actualMaintenanceCost.valid}
            readOnly={props.readOnly}
          />

          <KpiOutputGroup label="Cost reduction" value={serverVersion.maintenanceCostReduction} emphasize={true} />
        </Card>
      </Col>
    );
  }

  function renderProactiveOpportunities() {
    const investment = Math.round((serverVersion.timeToReturnInvestment || 0) * 12 * 2) / 2.0;

    return (
      <>
        <Col sm={6} lg={4} xl={3}>
          <Card body>
            <CardSubtitle>Production improvement (per year)</CardSubtitle>
            <NumberInputGroup
              currency
              label="Current impact"
              value={model.currentProductionImpact}
              onChange={(newValue) =>
                saveChange({
                  currentProductionImpact: newValue,
                })
              }
              valid={validity.currentProductionImpact.valid}
              readOnly={props.readOnly}
            />
            <NumberInputGroup
              currency
              label="Potential risk"
              value={model.potentialProductionRisk}
              onChange={(newValue) =>
                saveChange({
                  potentialProductionRisk: newValue,
                })
              }
              valid={validity.potentialProductionRisk.valid}
              readOnly={props.readOnly}
            />
            <KpiOutputGroup label="Opportunity" value={serverVersion.productionImprovementOpportunity} />
          </Card>
        </Col>

        <Col sm={6} lg={4} xl={3}>
          <Card body>
            <CardSubtitle>Maintenance cost reduction (per year)</CardSubtitle>
            <NumberInputGroup
              currency
              label="Current impact"
              value={model.currentMaintenanceImpact}
              onChange={(newValue) =>
                saveChange({
                  currentMaintenanceImpact: newValue,
                })
              }
              valid={validity.currentMaintenanceImpact.valid}
              readOnly={props.readOnly}
            />
            <NumberInputGroup
              currency
              label="Potential cost"
              value={model.potentialMaintenanceCost}
              onChange={(newValue) =>
                saveChange({
                  potentialMaintenanceCost: newValue,
                })
              }
              valid={validity.potentialMaintenanceCost.valid}
              readOnly={props.readOnly}
            />
            <KpiOutputGroup label="Opportunity" value={serverVersion.maintenanceCostReductionOpportunity} />
          </Card>
        </Col>

        <Col sm={6} lg={4} xl={3}>
          <Card body>
            <CardSubtitle>Return on investment</CardSubtitle>

            <KpiOutputGroup label="Total opportunity (per year)" value={serverVersion.totalOpportunity} />

            <NumberInputGroup
              currency
              label="Cost to implement"
              value={model.costToImplement}
              onChange={(newValue) =>
                saveChange({
                  costToImplement: newValue,
                })
              }
              valid={validity.costToImplement.valid}
              readOnly={props.readOnly}
            />

            <KpiOutputGroup
              label="Time to return investment"
              value={investment}
              units="months"
              dontShowZero={true}
              places={Math.floor(investment) === investment ? 0 : 1}
              emphasize={true}
            />
          </Card>
        </Col>
      </>
    );
  }

  function renderHse() {
    return (
      <Col sm={6} lg={4} xl={3}>
        <Card body>
          <CardSubtitle>HSE</CardSubtitle>
          <FormGroup>
            <Checkbox
              value={model.environmental}
              onClick={() => {
                saveChange({
                  environmental: !model.environmental,
                });
              }}
              readOnly={props.readOnly}
            >
              Environmental
            </Checkbox>
            <Checkbox
              value={model.fieldWorkReduction}
              onClick={() => {
                saveChange({
                  fieldWorkReduction: !model.fieldWorkReduction,
                });
              }}
              readOnly={props.readOnly}
            >
              Field work reduction
            </Checkbox>
            <Checkbox
              value={model.incidentAvoidance}
              onClick={() => {
                saveChange({
                  incidentAvoidance: !model.incidentAvoidance,
                });
              }}
              readOnly={props.readOnly}
            >
              Incident avoidance
            </Checkbox>
            <Checkbox
              value={model.regulatory}
              onClick={() => {
                saveChange({
                  regulatory: !model.regulatory,
                });
              }}
              readOnly={props.readOnly}
            >
              Regulatory
            </Checkbox>
          </FormGroup>
        </Card>
      </Col>
    );
  }

  const record = props.record!.model;
  return (
    <Row>
      {includeStartUpDelay(record.recordType) && renderStartUpDelay()}
      {includeProductionRisk(record.recordType) && renderProductionRisk()}
      {includeMaintenanceCost(record.recordType) && renderMaintenanceEfficiency()}
      {includeProactiveOpportunity(record.recordType) && renderProactiveOpportunities()}
      {renderHse()}
    </Row>
  );
};
