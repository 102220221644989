import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
} from "app";
import { KpiValueFilters } from "records";
import {
  KpiId,
  kpiIdToString,
  KpiTemplateDetail,
  KpiTemplateDigest,
  KpiTemplateFilters,
  KpiTemplateModel,
  KpiValueDetail,
  KpiValueModel,
} from "./models";

/// KPI Values
export const kpiValuesMutationUriMatchPatterns = [/api\/kpiValues.*/gi];
export const useGetKpiValueDetailsByRecordRequest = (recordId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<KpiValueDetail[]>(
    `/api/kpiValues/details/by/record/${recordId}`,
    kpiValuesMutationUriMatchPatterns,
    { useNode: true }
  );

export const useGetKpiValueDetailWithRecordsListInSiteRequest = (siteId: number) =>
  useBaseAxiosPostRequest<{ filters: KpiValueFilters }, KpiValueDetail[]>(
    `/api/kpiValues/detailWithRecords/filtered/${siteId}`,
    { app: { hideFromAutosaver: true }, useNode: true },
  );

export const useUpdateKpiValuesRequest = () =>
  useBaseAxiosPutRequest<Partial<KpiValueModel>, KpiValueDetail>(`/api/kpiValues`, { useNode: true });

export const useCreateKpiValuesRequest = () =>
  useBaseAxiosPostRequest<Partial<KpiValueModel>, KpiValueDetail>(`/api/kpiValues`, { useNode: true });

export const useDeleteKpiValueRequest = () =>
  useBaseAxiosDeleteRequest((kpiId: KpiId) => `/api/kpiValues/${kpiIdToString(kpiId)}`, { useNode: true });

/// KPI Templates
export const kpiTemplatesMutationUriMatchPatterns = [/api\/kpiTemplates.*/gi];

export const useGetKpiTemplateDetailRequest = (id: KpiId) =>
  useBaseAxiosGetRequestWithAutoRefresh<KpiTemplateDetail>(
    `api/kpiTemplates/details/${kpiIdToString(id)}`,
    kpiTemplatesMutationUriMatchPatterns,
    { useNode: true }
  );
export const useGetFilteredKpiTemplateDetailsInSiteRequest = (siteId: number) =>
  useBaseAxiosPostRequest<Partial<KpiTemplateFilters>, KpiTemplateDetail[]>(
    `api/kpiTemplates/details/filtered/${siteId}`,
    { app: { hideFromAutosaver: true }, useNode: true }
  );
export const useGetFilteredKpiTemplateDigestsInSiteRequest = (siteId: number) =>
  useBaseAxiosPostRequest<Partial<KpiTemplateFilters>, KpiTemplateDigest[]>(
    `api/kpiTemplates/digests/filtered/${siteId}`,
    { app: { hideFromAutosaver: true }, useNode: true }
  );
export const useCreateKpiTemplateRequest = () =>
  useBaseAxiosPostRequest<Partial<KpiTemplateModel>, KpiTemplateModel>(`api/kpiTemplates`, { useNode: true });
export const useUpdateKpiTemplateRequest = () =>
  useBaseAxiosPutRequest<KpiTemplateModel>(`api/kpiTemplates`, { useNode: true });
export const useDeleteKpiTemplateRequest = () =>
  useBaseAxiosDeleteRequest((id: KpiId) => `api/kpiTemplates/${kpiIdToString(id)}`, { useNode: true });
