import { User } from "../users";
import { RecordDigest } from "records";

export interface RecordsEventDigest {
  id: number;
  siteId: number;
  name: string;
  startDate: Date;
  endDate: Date | null;
}

export interface RecordsEvent {
  id: number;
  siteId: number;
  name: string;
  startDate: Date;
  endDate: Date | null;
  isArchived: boolean;
}

export interface RecordsEventDetail {
  id: number;
  siteId: number;
  createdBy: User;
  created: Date;
  model: RecordsEvent;
  records: RecordDigest[];
}

export class RecordsEventFilters {
  siteId: number | null = null;
  ids: number[] = [];
  nameFragment: string | null = null;
  startDateFrom: Date | null = null;
  startDateTo: Date | null = null;
  endDateFrom: Date | null = null;
  endDateTo: Date | null = null;
  isArchived: boolean | null = null;
}

export enum RecordsEventFieldLabel {
  StartDate = "Start Date",
  EndDate = "End Date",
}
