import { ReactNode } from "react";
import { Option } from "../forms/AutocompleteFormItem";
import _ from "lodash";
import { DiagnosticType } from "records/models/diagnosticType";
import { Asset } from "assets";
import { AssetType } from "assets/models/assetType";

interface DiagnosticTypesLoaderProps {
  assets?: Asset[];
  children: (params: { options: Option[] }) => ReactNode;
}

export const DiagnosticTypesLoader = (props: DiagnosticTypesLoaderProps) => {
  const diagnosticTypes: Array<DiagnosticType> = [];

  // record types like ValveDiagnostic require additional permissions.
  // ValveDiagnostic records cannot be created for machinery assets
  // users must have explicit permissions to edit ValveDiagnostic records

  const assetType = !!props.assets ? _.uniq(props.assets.map((a) => a.assetType)) : [];
  const hasControlOrInsolationValve = assetType.some(
    (assetType) => assetType === AssetType.ControlValve || assetType === AssetType.IsolationValve
  );
  const hasMovAsset = assetType.some((assetType) => assetType === AssetType.MotorOperatedValve);

  if (hasControlOrInsolationValve) {
    diagnosticTypes.push(DiagnosticType.PneumaticFullStroke);
    diagnosticTypes.push(DiagnosticType.PneumaticPartialStroke);
  }
  if (hasMovAsset) {
    diagnosticTypes.push(DiagnosticType.MotorOperated);
  }

  const options = diagnosticTypes.map((item) => ({ id: item, label: item }));

  return <>{props.children({ options })}</>;
};
