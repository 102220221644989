import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { KpiTypeSelectFormItem } from "app/mui/forms/Select/KpiTypeSelectFormItem";
import { KPITemplatesLoader } from "app/mui/KpiTemplatesLoader";
import { useCreateKpiValuesRequest, useGetKpiValueDetailsByRecordRequest } from "kpis";
import { getKpiTypeOption } from "kpis/models";
import _ from "lodash";
import { useEffect } from "react";

interface AddRecordKpiDialogProps extends BaseDialogProps {
  recordId: number;
  siteId: number;
}

const validationSchema = yup.object({
  kpiType: yup.object().nullable().required("Required"),
});

export const AddRecordKpiDialog = (props: AddRecordKpiDialogProps) => {
  const { recordId, siteId, onClose } = props;

  const methods = useForm({
    defaultValues: {
      kpiType: getKpiTypeOption("OneTimeLabourOrEquipmentCostSavings"),
      kpiTemplateId: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const kpiValuesQuery = useGetKpiValueDetailsByRecordRequest(recordId);
  const createKpiValuesRequest = useCreateKpiValuesRequest();

  const kpiValues = kpiValuesQuery.data || [];
  const kpiType = methods.watch("kpiType");

  const usedTemplateIds = _.uniq(
    kpiValues
      .filter((k) => k.kpiType === kpiType?.value && !!k.model.kpiTemplateId)
      .filter((k) => !!k.model.kpiTemplateId)
      .map((k) => k.model.kpiTemplateId!)
  );

  useEffect(() => {
    methods.setValue("kpiTemplateId", "");
  }, [kpiType]);

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      createKpiValuesRequest
        .call({
          siteId: siteId,
          recordId: recordId,
          kpiType: values.kpiType.value,
          kpiTemplateId: values.kpiTemplateId,
        })
        .then(() => {
          onClose();
        });
    })();
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Add KPI
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <KpiTypeSelectFormItem fieldName="kpiType" />
            <KPITemplatesLoader kpiType={kpiType} usedTemplateIds={usedTemplateIds}>
              {(options) => (
                <AutocompleteFormItem
                  label="Template"
                  fieldName="kpiTemplateId"
                  options={options.options}
                  autocompleteProps={{
                    disablePortal: false,
                    isOptionEqualToValue: (option, value) => option.id === value.id,
                  }}
                />
              )}
            </KPITemplatesLoader>
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            Add
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
