import { Stack, Skeleton } from "@mui/material";

export const WidgetSkeleton = () => {
	return (
		<Stack height="100%">
			<Skeleton />
			<Skeleton variant="rectangular" height={100} />
		</Stack>
	);
};
