import { Box, Skeleton, Stack, Typography } from "@mui/material";

export const LabeledChartSkeleton = () => {
  return (
    <Stack sx={{ flex: "0 1 auto", width: "100%", alignItems: "center" }}>
      <Box sx={{ height: "48px", display: "flex", justifyContent: "center", paddingTop: "8px" }}>
        <Skeleton variant="rectangular" width={80} height={40} />
      </Box>
      <Skeleton width={40} sx={{ alignItems: "center" }}>
        <Typography variant="caption">.</Typography>
      </Skeleton>
    </Stack>
  );
};
