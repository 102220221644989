import { Stack } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { NumberFormItem, NumberFormItemProps } from "app/mui/forms/Input/NumberFormItem";
import { TimeUnitFormItem, TimeUnitFormItemProps } from "./TimeUnitFormItem";

interface NumberTimeUnitFormItemProps extends Omit<BaseFormItemProps, "fieldName"> {
  valueFieldName: string;
  timeUnitFieldName: string;
  valueProps?: Partial<Omit<NumberFormItemProps, "FieldName" | "label">>;
  timeUnitProps?: TimeUnitFormItemProps;
}

export function NumberTimeUnitFormItem(props: NumberTimeUnitFormItemProps) {
  const {
    valueFieldName,
    timeUnitFieldName,
    readOnly,
    label,
    valueProps = {},
    timeUnitProps = {},
    ...formItemLayoutProps
  } = props;

  return (
    <FormItemLayout
      controlled
      hint={props.hint}
      label={label}
      formControlProps={{ disabled: readOnly }}
      {...formItemLayoutProps}
    >
      {(inputProps) => (
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <NumberFormItem fieldName={valueFieldName} label={null} readOnly={readOnly} {...valueProps} {...inputProps} />
          <TimeUnitFormItem
            fieldName={timeUnitFieldName}
            label={null}
            readOnly={readOnly}
            {...timeUnitProps}
            {...inputProps}
          />
        </Stack>
      )}
    </FormItemLayout>
  );
}
