import { TrafficLightValue } from "records";

export interface PackagedSolutionOperatingSystem {
  hardware: TrafficLightValue;
  software: TrafficLightValue;
  performance: TrafficLightValue;
  security: TrafficLightValue;
}

export enum PackagedSolutionOperatingSystemFieldLabel {
  Hardware = "Hardware",
  Software = "Software",
  Performance = "Performance",
  Security = "Security",
}
