import { Box, IconButton, Stack } from "@mui/material";
import { NewTabLink } from "app/mui/common/links/NewTabLink";
import { theme } from "app/mui/theme";
import { generateLinkToLocation, isValidLatLng } from "app/mui/utils/google-maps-link";
import { LocationIcon } from "icons/LocationIcon";
import { useFormContext } from "react-hook-form";
import { ControlledTextFieldFormItem } from "./ControlledTextFieldFormItem";
import { TextFieldFormItemProps } from "./TextFieldFormItem";

interface GpsFormItemProps extends TextFieldFormItemProps {
  includeViewLink?: true;
}

export default function GpsFormItem(props: GpsFormItemProps) {
  const methods = useFormContext();
  var fieldValue = methods.watch(props.fieldName);

  const gpsCoordinatesAreValid = isValidLatLng(fieldValue ?? "");

  return (
    <Stack
      spacing={1}
      sx={{
        "& .MuiInputBase-root.MuiOutlinedInput-root ": {
          width: `calc(100% - 30px)`,
        },
      }}
    >
      <ControlledTextFieldFormItem
        {...props}
        textFieldProps={{ ...props.textFieldProps, sx: { ...props.textFieldProps?.sx, position: "relative" } }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            paddingRight: 0,
            paddingLeft: 0,
            width: "35px",
          }}
        >
          <IconButton
            sx={{
              paddingLeft: "3px",
              paddingRight: "3px",
              borderRadius: 0,
              backgroundColor: theme.palette.grey[700],
              color: theme.palette.primary.contrastText,
              "&:hover": {
                backgroundColor: theme.palette.grey[800],
                color: theme.palette.primary.contrastText,
              },
            }}
            aria-label="gps-select"
            onClick={() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) =>
                    methods.setValue(
                      props.fieldName,
                      `${position.coords.latitude.toFixed(6)}, ${position.coords.longitude.toFixed(6)}`,
                      { shouldDirty: true }
                    ),
                  (_error) => {
                    methods.setValue(props.fieldName, "unknown", { shouldDirty: true });
                  }
                );
              } else {
                methods.setValue(props.fieldName, "unknown", { shouldDirty: true });
              }
            }}
          >
            <LocationIcon />
          </IconButton>
        </Box>
      </ControlledTextFieldFormItem>

      {props.includeViewLink && (
        <Box>
          <NewTabLink
            label="VIEW IN MAPS"
            url={fieldValue ? generateLinkToLocation(fieldValue) : ""}
            disabled={!gpsCoordinatesAreValid}
          />
        </Box>
      )}
    </Stack>
  );
}
