import * as React from "react";
import { Icon } from "icons/Icon";

export const CheckboxCheckedIcon: React.FunctionComponent = () => (
   <Icon viewBoxSize={32}>
      <g id="surface1">
         <path d="M 5 5 L 5 27 L 27 27 L 27 5 Z M 7 7 L 25 7 L 25 25 L 7 25 Z M 21.28125 11.28125 L 14 18.5625 L 10.71875 15.28125 L 9.28125 16.71875 L 13.28125 20.71875 L 14 21.40625 L 14.71875 20.71875 L 22.71875 12.71875 Z " />
      </g>
   </Icon>
);
