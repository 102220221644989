import React, { CSSProperties } from "react";
import { GetKpiCommodityUnitOptions } from "../models";
import { FormGroup, Input, Label } from "reactstrap";
import { TimeUnit, Validity } from "common";
import classNames from "classnames";
import { NumberInputGroup } from "records/bs/NumberInputGroup";
import Select from "react-select";
import { SelectTimeUnit } from "../../common/fields/SelectTimeUnit";
import { KpiCommodityUnit } from "../models";

interface Props {
  label: string;
  style?: CSSProperties;
  showValidationErrors: boolean;
  productionRateValue: number | null;
  setProductionRate: (newValue: number | null) => any;
  productionRateValidity: Validity;
  productionRateReadOnly: boolean;
  typeValue: string | null;
  typeValues?: string[] | null;
  typeValidity: Validity;
  typeReadOnly: boolean;
  setType: (newValue: string | null) => any;
  unitValue: KpiCommodityUnit | null;
  unitValidity: Validity;
  unitReadOnly: boolean;
  units: KpiCommodityUnit[];
  setUnit: (newValue: KpiCommodityUnit | null) => any;
}

interface PropsWithProductionRate extends Props {
  productionRateTimeUnitValue: TimeUnit | null;
  productionRateTimeUnitValidity: Validity;
  productionRateTimeUnitReadOnly: boolean;
  setProductionRateTimeUnit: (newValue: TimeUnit | null) => any;
}

/**
 * Fields from left to right are:
 * productionRateValue, unitValue, typeValue, productionRateTimeUnitValue
 * @param props
 * @returns
 */
export const ProductionRateFormGroup: React.FunctionComponent<Props | PropsWithProductionRate> = (props) => {
  const selectedUnit = !!props.unitValue
    ? {
        label: props.unitValue,
        value: props.unitValue,
      }
    : null;

  const propsWithProductionRate = props as PropsWithProductionRate;
  const unitOptions = GetKpiCommodityUnitOptions(props.units);

  return (
    <FormGroup style={props.style}>
      <Label>{props.label}</Label>
      <div className="form-group-wrapper">
        <NumberInputGroup
          style={{ width: "80px" }}
          value={props.productionRateValue}
          onChange={props.setProductionRate}
          valid={!props.showValidationErrors || props.productionRateValidity.valid}
          validationError={props.productionRateValidity.message}
          readOnly={props.productionRateReadOnly}
          placeholder={props.productionRateReadOnly ? "" : "Quantity"}
        />
        <FormGroup style={{ width: "175px" }}>
          <div className="form-group-input-wrapper">
            <Select
              className={classNames("react-select", {
                "is-invalid": props.showValidationErrors && !props.unitValidity.valid,
              })}
              classNamePrefix="react-select"
              value={selectedUnit}
              onChange={(newUnit) => props.setUnit(!!newUnit ? newUnit.value : null)}
              options={unitOptions}
              getOptionLabel={(option) => option.value || ""}
              isDisabled={props.unitReadOnly}
              placeholder={props.unitReadOnly ? "" : "Select unit"}
            />
          </div>
          <div className="invalid-feedback">{props.unitValidity.message}</div>
        </FormGroup>
        <FormGroup>
          <Label style={{ marginTop: "7px" }}> of </Label>
        </FormGroup>
        <FormGroup style={{ minWidth: "190px" }}>
          {!!!props.typeValues && (
            <Input
              value={props.typeValue || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                props.setType(e.target.value);
              }}
              disabled={props.typeReadOnly}
              className={classNames("form-control", {
                "is-invalid": props.showValidationErrors && !props.typeValidity.valid,
              })}
              placeholder={props.typeReadOnly ? "" : "Product name"}
            />
          )}
          {!!props.typeValues && (
            <Select
              className={classNames("react-select", {
                "is-invalid": props.showValidationErrors && !props.typeValidity.valid,
              })}
              classNamePrefix="react-select"
              value={!!props.typeValue ? { value: props.typeValue } : null}
              onChange={(newValue) => props.setType(!!newValue ? newValue.value : null)}
              options={props.typeValues.map((value) => ({ value }))}
              getOptionLabel={(option) => option.value}
              isDisabled={props.typeReadOnly}
              placeholder={props.typeReadOnly ? "" : "Select product"}
            />
          )}
          <div className="invalid-feedback">{props.typeValidity.message}</div>
        </FormGroup>
        {!!propsWithProductionRate.setProductionRateTimeUnit && (
          <SelectTimeUnit
            mode="Per"
            value={propsWithProductionRate.productionRateTimeUnitValue}
            readOnly={propsWithProductionRate.productionRateTimeUnitReadOnly}
            saveChange={propsWithProductionRate.setProductionRateTimeUnit}
            showValidationErrors={propsWithProductionRate.showValidationErrors}
            validity={propsWithProductionRate.productionRateTimeUnitValidity}
          />
        )}
      </div>
    </FormGroup>
  );
};
