import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { FunctionComponent, PropsWithChildren } from "react";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
export const appInsightsReactPlugin = new ReactPlugin();
export const appInsightsCustomTelemetry = { appName: "ClientApp", currentUserId: null } as {
  appName: string;
  currentUserId: string | null;
};
export const appInsights = !!process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING
  ? new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
        extensions: [appInsightsReactPlugin],
        extensionConfig: {
          [appInsightsReactPlugin.identifier]: { history: browserHistory },
        },
      },
    })
  : null;

if (!!appInsights) {
  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.tags = envelope.tags || [];
    envelope.tags["ai.cloud.role"] = appInsightsCustomTelemetry.appName;

    envelope.data = envelope.data || [];
    envelope.data.IrisAppName = appInsightsCustomTelemetry.appName;
    envelope.data.IrisUserId = appInsightsCustomTelemetry.currentUserId;
  });

  appInsights.loadAppInsights();
}

export const AppInsightsContextProvider: FunctionComponent<PropsWithChildren> = (props) => (
  <AppInsightsContext.Provider value={appInsightsReactPlugin}>{props.children}</AppInsightsContext.Provider>
);
