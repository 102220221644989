import { TemplateKpiType } from "./KpiType";

export type KpiSavingsCategory =
  | "None"
  | "Cancel scope"
  | "Reduce equipment costs"
  | "Reduce parts"
  | "Smart function testing"
  | "Work process improvement";
export const KpiSavingsCategories: KpiSavingsCategory[] = [
  "None",
  "Cancel scope",
  "Reduce equipment costs",
  "Reduce parts",
  "Smart function testing",
  "Work process improvement",
];
export const KpiSavingsCategoryOptions = KpiSavingsCategories.map((o) => ({ label: o, value: o }));

export const normalizeKpiSavingsCategories = (value?: string | null) => {
  if (!!value) {
    const selected = KpiSavingsCategories.filter((v) => v.toLowerCase() === value.toLowerCase());
    if (selected.length > 0) {
      return selected[0];
    }
  }
  return value || null;
};

export const getKpiCategory = (kpiTemplate: TemplateKpiType): string => {
  if (kpiTemplate.startsWith("Legacy")) {
    return "Legacy";
  }
  if (kpiTemplate.startsWith("OneTime")) {
    return "One-time";
  }
  if (kpiTemplate.startsWith("Ongoing")) {
    return "Ongoing";
  }
  return "";
};
