import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormEditPermission } from "./PermissionAwareFormProvider";

export interface CheckboxFormItemProps {
  label: string;
  fieldName: string;
  disabled?: boolean;
}

export const CheckboxFormItem = (props: CheckboxFormItemProps) => {
  const { hasPermissionToEdit } = useFormEditPermission();
  return (
    <Controller
      name={props.fieldName}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox checked={!!field.value} {...field} disabled={!hasPermissionToEdit || props.disabled === true} />
          }
          label={props.label}
        />
      )}
    />
  );
};
