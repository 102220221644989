import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { formatRoutePath, Routes } from "app";
import { dateFormat, FeatureFlagName, formatSortCaret, useFeatureEnabled } from "common";
import { AuthorizationProvider, useSiteIdFromRoute } from "app";
import moment from "moment";
import { useState } from "react";
import { useGetSummaryDetailsBySiteRequest } from "summaries/api";
import { Summary, SummaryType } from "summaries/models";
import { SummaryIcon } from "icons/SummaryIcon";
import { CreateSummaryModal } from "./CreateSummaryModal";

export const SummaryList: React.FunctionComponent<{}> = () => {
  const siteId = useSiteIdFromRoute()!;
  const navigate = useNavigate();
  const [createSummaryModalOpen, setCreateSummaryModalOpen] = useState(false);

  const query = useGetSummaryDetailsBySiteRequest(siteId);

  const toggleCreateSummaryModal = () => {
    setCreateSummaryModalOpen(!createSummaryModalOpen);
  };

  const getDataColumns = () => {
    const nameColumn = {
      dataField: "model.title",
      text: "Title",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (summaryTitle: string, summary: Summary) => (
        <Link key={summary.id} to={formatRoutePath(Routes.Summary, summary)}>
          {summaryTitle}
        </Link>
      ),
      headerClasses: "md-column",
    };

    const dateColumn = {
      dataField: "model.summaryDate",
      text: "Summary date",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: Date, summary: Summary) => <div>{moment(value).format(dateFormat)}</div>,
      headerClasses: "xs-column",
    };

    const statusColumn = {
      dataField: "model.status",
      text: "Status",
      sort: true,
      sortCaret: formatSortCaret,
      headerClasses: "xs-column",
    };

    const typeColumn = {
      dataField: "model.summaryType",
      text: "Type",
      sort: true,
      sortCaret: formatSortCaret,
      headerClasses: "md-column",
    };

    const createdByColumn = {
      dataField: "createdBy.fullName",
      text: "Created by",
      sort: true,
      sortCaret: formatSortCaret,
      headerClasses: "md-column",
    };

    const closedByColumn = {
      dataField: "closedBy.fullName",
      text: "Closed by",
      sort: true,
      sortCaret: formatSortCaret,
      headerClasses: "md-column",
    };

    const baseColumns = [nameColumn, dateColumn, statusColumn, typeColumn, createdByColumn, closedByColumn];

    return baseColumns;
  };
  const useFieldInspectionSummary = useFeatureEnabled(FeatureFlagName.UseFieldInspectionSummary);

  let summaries = query.data ?? [];
  if (!useFieldInspectionSummary) {
    summaries = summaries.filter((s) => s.model.summaryType !== SummaryType.FieldInspection);
  }
  return (
    <div className="legacy_bootstrap">
      <AuthorizationProvider>
        {(userPermissions, permissionsNotYetLoaded) => {
          if (permissionsNotYetLoaded) return null;
          return (
            <Card body className="summary-list">
              <title>SpartanPRO IRIS - Summaries</title>

              <ToolkitProvider keyField="id" data={summaries} columns={getDataColumns()}>
                {(toolkitProps: any) => (
                  <>
                    <div className="screen-title">
                      <div className="icon-and-title">
                        <SummaryIcon sx={{ fontSize: 42 }} />
                        <div>Summaries</div>
                      </div>
                      <div className="screen-commands">
                        {userPermissions.userCanEditSummary && (
                          <Button color="secondary" onClick={() => toggleCreateSummaryModal()}>
                            Create a summary
                          </Button>
                        )}
                      </div>
                    </div>
                    <BootstrapTable
                      {...toolkitProps.baseProps}
                      classes="table-sm list-table"
                      remote={{ filter: true }}
                      loading={query.loading}
                      bootstrap4={true}
                      striped
                      bordered={false}
                      defaultSorted={[{ dataField: "title", order: "asc" }]}
                      defaultSortDirection="asc"
                      headerClass="header"
                      pagination={paginationFactory({
                        sizePerPage: 100,
                        hideSizePerPage: true,
                        hidePageListOnlyOnePage: true,
                      })}
                      noDataIndication={() => (query.loading ? <div>Loading...</div> : <div>No summaries found.</div>)}
                    />
                    {userPermissions.userCanEditSummary && createSummaryModalOpen && (
                      <CreateSummaryModal
                        key="summary-list-create-summary-modal"
                        siteId={siteId}
                        toggle={toggleCreateSummaryModal}
                        createdSummary={(newSummary) => {
                          toggleCreateSummaryModal();
                          navigate(formatRoutePath(Routes.Summary, newSummary));
                        }}
                      />
                    )}
                  </>
                )}
              </ToolkitProvider>
            </Card>
          );
        }}
      </AuthorizationProvider>
    </div>
  );
};
