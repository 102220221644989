import { useEffect, useMemo } from "react";
import { Box, Link, Paper, Stack, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { generateHealthHistoryDataFromRecords, getStartAndEndDates } from "../health/charts";
import { formatRoutePath, Routes, useSiteIdFromRoute } from "app/routes";
import { ValveHealthChart } from "../health/charts/ValveHealthChart";
import { RecordDetailView, RecordFilters, RecordType, useGetRecordDetailsByFilterRequest } from "records";
import _ from "lodash";
import { IrisColors } from "app/mui/theme";
import { IrisTooltip } from "app/mui/IrisTooltip";
import { HealthTrafficLightBadge } from "../health/HealthTrafficLightBadge";
import InfoIcon from "@mui/icons-material/Info";
import PreviousRecordLoader from "app/mui/loaders/PreviousRecordLoader";

interface ValveHealthProps {
  assetId: number;
  title: string;
  showHealthBadge?: boolean;
  showToolTip?: boolean;
  recordId?: number;
}

export const HealthHistory = (props: ValveHealthProps) => {
  const { assetId, recordId, title, showHealthBadge, showToolTip } = props;
  const siteId = useSiteIdFromRoute()!;
  const theme = useTheme();

  const getAssetRecordsRequest = useGetRecordDetailsByFilterRequest(siteId);
  useEffect(() => {
    const filters = new RecordFilters();
    filters.siteId = siteId;
    filters.assetIds = [assetId];
    filters.recordTypes = [RecordType.ValveDiagnostic];
    getAssetRecordsRequest.call({ filters });
  }, []);

  const recordDetails = getAssetRecordsRequest.data ?? [];
  const recordDetailsWithHealth = recordDetails.filter((r) => r.model.health.overall !== null);
  const data = useMemo(() => generateHealthHistoryDataFromRecords(recordDetailsWithHealth, theme), [recordDetails]);
  const [start, end] = useMemo(() => getStartAndEndDates(data), [data]);

  const sortedRecords = _.orderBy(recordDetailsWithHealth, (r) => r.model.eventDate, "desc");

  const latestValveDiagnostic = recordDetailsWithHealth.length > 0 ? sortedRecords[0] : null;

  let lastValveDiagnostic: RecordDetailView | null = null;
  if (!!recordId && sortedRecords.length > 0) {
    const recordIndex = sortedRecords.findIndex((x) => x.id === recordId);
    if (recordIndex > -1 && recordIndex < sortedRecords.length - 1) {
      lastValveDiagnostic = sortedRecords[recordIndex + 1];
    }
  }

  const valveHealth = latestValveDiagnostic?.model.health;

  if (!latestValveDiagnostic || !valveHealth?.overallCategory) {
    return null;
  }

  const { overall, overallCategory } = valveHealth;

  return (
    <Paper sx={{ p: 2, height: "240px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems={"center"}>
        <Typography variant="h6">{title}</Typography>
        {showToolTip && (
          <IrisTooltip
            title={
              <p>
                This trend represents the valve diagnostic health score over time, thus illustrating potential asset
                degradation and/or work execution that results in an improved health.
              </p>
            }
          >
            <span>
              <InfoIcon sx={{ color: IrisColors.gray500, verticalAlign: "middle", fontSize: "1.7rem" }} />
            </span>
          </IrisTooltip>
        )}
      </Stack>

      {!!lastValveDiagnostic && (
        <Typography component="span" variant="body2" sx={{ pt: 1, textAlign: "right" }}>
          <Link
            component={RouterLink}
            to={formatRoutePath(Routes.Record, {
              siteId,
              id: lastValveDiagnostic.model.id,
            })}
            color="secondary"
            sx={{ fontWeight: "bold" }}
          >
            Last&nbsp;{_.lowerFirst(lastValveDiagnostic.model.description)}
          </Link>
        </Typography>
      )}
      {!!latestValveDiagnostic && !!!recordId && (
        <Typography component="span" variant="body2" sx={{ pt: 1, textAlign: "right" }}>
          <Link
            component={RouterLink}
            to={formatRoutePath(Routes.Record, {
              siteId,
              id: latestValveDiagnostic.id,
            })}
            color="secondary"
            sx={{ fontWeight: "bold" }}
          >
            Last&nbsp;{_.lowerFirst(latestValveDiagnostic.model.description)}
          </Link>
        </Typography>
      )}

      <Stack
        justifyContent="space-between"
        direction="row"
        height="100%"
        alignItems="center"
        spacing={2}
        sx={{
          minHeight: "0",
          minWidth: "0",
          width: "100%",
          height: "100%",
        }}
      >
        {showHealthBadge && <HealthTrafficLightBadge overall={overall} overallCategory={overallCategory} />}
        <Box width="100%" height="100%">
          {data.length < 2 ? (
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                color: "primary.dark",
                height: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              Not enough data to show trend
            </Typography>
          ) : (
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <div style={{ width, height }}>
                    <ValveHealthChart
                      startDate={start}
                      endDate={end}
                      data={[
                        {
                          id: "main",
                          data: data ?? [],
                        },
                      ]}
                    />
                  </div>
                );
              }}
            </AutoSizer>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};
