import { MotorOperatedCondition } from "./motorOperatedCondition";
import { MotorOperatedPerformance } from "./motorOperatedPerformance";
import { TrafficLightValue } from "./trafficLight";

type ReportCardCategory = "asFound" | "asLeft";
type ReportCardMotorOperatedConditionAttribute = `${ReportCardCategory}Condition_${keyof MotorOperatedCondition}`;
type ReportCardMotorOperatedPerformanceAttribute = `${ReportCardCategory}Performance_${keyof MotorOperatedPerformance}`;
type ReportCardMotorOperatedFields = {
  [k in
    | ReportCardMotorOperatedConditionAttribute
    | ReportCardMotorOperatedPerformanceAttribute]: TrafficLightValue | null;
};

export type ReportCardMotorOperated = ReportCardMotorOperatedFields & {
  id: number;
  recordId: number;
};

export const createNewReportCardMotorOperated = (recordId: number): ReportCardMotorOperated => ({
  id: -1,
  recordId,
  asFoundCondition_currentDraw: null,
  asFoundCondition_voltage: null,
  asFoundCondition_ratedTravel: null,
  asFoundCondition_torqueThrust: null,
  asFoundCondition_stemNutWear: null,
  asLeftCondition_currentDraw: null,
  asLeftCondition_voltage: null,
  asLeftCondition_ratedTravel: null,
  asLeftCondition_torqueThrust: null,
  asLeftCondition_stemNutWear: null,

  asFoundPerformance_setPointTracking: null,
  asFoundPerformance_overshoot: null,
  asFoundPerformance_offset: null,
  asFoundPerformance_motorSpeed: null,
  asFoundPerformance_strokeTime: null,
  asLeftPerformance_setPointTracking: null,
  asLeftPerformance_overshoot: null,
  asLeftPerformance_offset: null,
  asLeftPerformance_motorSpeed: null,
  asLeftPerformance_strokeTime: null,
});
