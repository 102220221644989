import { FunctionComponent, useState } from "react";
import { Box, Container, Divider, IconButton, Stack, useMediaQuery } from "@mui/material";
import { NavigatingSiteSelector, NavigatingSiteSelectorProps } from "./SiteSelector";
import { Hide } from "./layoutUtils/HideShowUtils";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { MobileMenuDrawer } from "./siteMenu/MobileMenuDrawer";
import { SavingIndicator } from "./SavingIndicator";
import { useUiThemeContext } from "app/contexts/UiThemeContext";
import { theme } from "./theme";

interface SiteContextHeaderProps extends NavigatingSiteSelectorProps {
   showSiteSelector?: boolean;
   
}

export const SiteContextHeader: FunctionComponent<SiteContextHeaderProps> = (props) => {
   const [menuOpen, setMenuOpen] = useState<boolean>(false);
   const bgColor = useUiThemeContext().header === "dark" ? "primary.main" : "background.paper";
   const padding =  useMediaQuery(theme.breakpoints.down('md'))  ? 0 : 10;

   return (
      <>
         <Divider sx={{ borderColor: bgColor }} />
         <Box flexGrow={1} display="flex" alignItems="end" justifyContent="space-around" sx={{ bgcolor: bgColor, py: 1 , paddingLeft: padding}}>
            <Container maxWidth={false}>
               <Stack direction="row" spacing={1}>
                  <Hide at={(breakpoint) => breakpoint.up("md")}>
                     <IconButton onClick={() => setMenuOpen(true)}>
                        <MenuRoundedIcon style={{color: "white"}}/>
                     </IconButton>
                     <MobileMenuDrawer open={menuOpen} requestClose={() => setMenuOpen(false)} />
                  </Hide>

                  <Stack
                     direction="row"
                     spacing={1}
                     justifyContent="space-between"
                     alignItems="center"
                     sx={{ width: "100%", height: "40px" }}
                  >
                     {props.showSiteSelector ? <NavigatingSiteSelector {...props} /> : <Box />}
                     <SavingIndicator />
                  </Stack>
               </Stack>
            </Container>
         </Box>
      </>
   );
};
