export type Status = "Identified" | "Complete" | "CustomerAction" | "ImpactPartnerAction" | "Archived";
export const Statuses: Status[] = ["Identified", "Complete", "CustomerAction", "ImpactPartnerAction", "Archived"];

export const GetOpenOrClosedStatus = (status: Status | string) =>
  status === "Complete" || status === "Archived" ? "Closed" : "Open";

export const statusToDisplayName = (status: Status | string): string => {
  switch (status) {
    case "CustomerAction":
      return "Customer action";
    case "ImpactPartnerAction":
      return "Impact Partner action";
    default:
      return status;
  }
};

export const StatusIdLabelOptions = Statuses.map((item) => ({ id: item, label: statusToDisplayName(item) }));
export const StatusValueLabelOptions = Statuses.map((s) => ({ value: s, label: statusToDisplayName(s) }));
export const StatusValueLabelOptionsWithoutComplete = StatusValueLabelOptions.filter((s) => s.value !== "Complete");
