import { Box, Portal } from "@mui/material";
import { TooltipWrapper, useTooltipState } from "@nivo/tooltip";

export const CustomTooltip = (props: {
   containerRef: React.MutableRefObject<HTMLElement | undefined>;
   children: React.ReactNode;
}) => {
   const state = useTooltipState();

   if (!state.isVisible) {
      return null;
   }

   const [x, y] = state.position;
   const modifiedPosition: [number, number] = [
      x + (props.containerRef.current?.getBoundingClientRect()?.x ?? 0),
      y + (props.containerRef.current?.getBoundingClientRect()?.y ?? 0),
   ];

   return (
      <Portal>
         <Box sx={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", pointerEvents: "none" }}>
            <TooltipWrapper position={modifiedPosition} anchor={state.anchor}>
               {props.children}
            </TooltipWrapper>
         </Box>
      </Portal>
   );
};
