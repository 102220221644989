import { LeadTimeUnits, StringValueWithUsageCountViewModel, TimeUnit } from "common";
import NumberFormat from "react-number-format";
import { OutageRecordMilestoneFieldLabels } from "records/models";
import * as yup from "yup";

export type RecordOutageScopePhase = "Planning" | "Execution" | "Evaluation";
export const RecordOutageScopePhases: RecordOutageScopePhase[] = ["Planning", "Execution", "Evaluation"];
export const RecordOutageScopePhaseOptions = RecordOutageScopePhases.map((value) => ({ value, label: value }));

export type RecordOutageExecutionStatus = "Pending" | "Complete" | "DidNotComplete";
export const GetRecordOutageExecutionStatusDisplay = (status: RecordOutageExecutionStatus | string): string => {
  switch (status) {
    case "DidNotComplete":
      return "Did not complete";
    default:
      return status;
  }
};
export const RecordOutageExecutionStatuses = ["Pending", "Complete", "DidNotComplete"];

export type RecordOutagePlanningScopeType = "BudgetaryReplacementCost" | "Original" | "Recommendation";

export const GetRecordOutagePlanningScopeDisplay = (
  scope: RecordOutagePlanningScopeType,
  recommendationNumber?: number
): string => {
  switch (scope) {
    case "BudgetaryReplacementCost":
      return "Direct replacement cost";
    case "Original":
      return "Original scope of work";
    case "Recommendation":
      return `Scope recommendation ${recommendationNumber}`;
  }
};

export interface RecordOutageDetail {
  id: number;
  recordId: number;
  acceptedScopeId: number | null;
  executionStatus: RecordOutageExecutionStatus | null;
  expectedMilestones: RecordOutageExecutionMilestones;
  actualMilestones: RecordOutageExecutionMilestones;
  summaryOfRepair: string | null;
  scopeOutcome: RecordOutageEvaluationScopeOutcome | null;

  scopes: RecordOutagePlanningScope[];
  evaluationConsiderations: RecordOutageEvaluationConsideration[];
}

export interface RecordOutageDetailView {
  id: number;
  recordId: number;
  model: RecordOutageDetail;
  scopes: RecordOutagePlanningScopeView[];
  acceptedOrDefaultScope: RecordOutagePlanningScopeView | null;
  acceptedScope: RecordOutagePlanningScopeView | null;
  completedPlanning: boolean;
  completedExecution: boolean;
  completedEvaluation: boolean;
  currentPhase: RecordOutageScopePhase;
  latestCompleteMilestone: string | null;
  latestCompleteMilestoneDate: Date | null;
  lastModified: Date;
  originalScope: RecordOutagePlanningScopeView | null;
}

/// Planning
export interface RecordOutagePlanningScopeView {
  id: number;
  leadTime: string | null;
  primaryScopeOfWorkName: string | null;
  model: RecordOutagePlanningScope;
}

export interface RecordOutagePlanningScope {
  id: number;
  recordOutageDetailId: number;
  scopeType: RecordOutagePlanningScopeType;
  primaryAction: RecordOutagePlanningAction | null;
  primaryComponent: RecordOutagePlanningScopeComponent | null;
  primaryLocation: RecordOutagePlanningScopeLocation | null;
  secondaryAction: SecondaryRecordOutagePlanningAction | null;
  secondaryComponent: RecordOutagePlanningScopeComponent | null;
  secondaryLocation: RecordOutagePlanningScopeLocation | null;
  quoteAmount: number | null;
  quoteDate: Date | null;
  quoteNumber: string | null;
  leadTime: number | null;
  leadTimeUnit: TimeUnit | null;
  vendor: string | null;
  labourPortion: number | null;
  details: string | null;
  validationDetails: string | null;
  justification: string | null;
  poAmount: number | null;
  poDate: Date | null;
  poNumber: string | null;
}

export const getNewOutageScope = (
  scopeType: RecordOutagePlanningScopeType,
  recordOutageDetailId: number
): RecordOutagePlanningScope => {
  return {
    id: 0,
    recordOutageDetailId,
    scopeType,
    details: null,
    justification: null,
    labourPortion: null,
    leadTime: null,
    leadTimeUnit: null,
    primaryAction: null,
    primaryComponent: null,
    primaryLocation: null,
    secondaryAction: null,
    secondaryComponent: null,
    secondaryLocation: null,
    poAmount: null,
    poDate: null,
    poNumber: null,
    quoteAmount: null,
    quoteDate: null,
    quoteNumber: null,
    validationDetails: null,
    vendor: null,
  };
};

export type RecordOutagePlanningAction =
  | "Function test only"
  | "Visual inspection only"
  | "Rebuild"
  | "Replace"
  | "Design change"
  | "Removed from scope";
export const RecordOutagePlanningActions: RecordOutagePlanningAction[] = [
  "Function test only",
  "Visual inspection only",
  "Rebuild",
  "Replace",
  "Design change",
  "Removed from scope",
];

export type SecondaryRecordOutagePlanningAction = "Rebuild" | "Replace" | "Design change";

export const SecondaryRecordOutagePlanningActions: RecordOutagePlanningAction[] = [
  "Rebuild",
  "Replace",
  "Design change",
];

// This is the order of appearance in the drop down
export type RecordOutagePlanningScopeComponent =
  | "Assembly"
  | "Actuator"
  | "Bare-stem valve"
  | "Soft parts"
  | "Trim (full)"
  | "Trim (partial)"
  | "Positioner"
  | "Accessory";

// This is the order of appearance in the drop down
export const RecordOutagePlanningScopeComponents: RecordOutagePlanningScopeComponent[] = [
  "Assembly",
  "Actuator",
  "Bare-stem valve",
  "Soft parts",
  "Trim (full)",
  "Trim (partial)",
  "Positioner",
  "Accessory",
];

export type RecordOutagePlanningScopeLocation = "Onsite" | "Offsite";
export const RecordOutagePlanningScopeLocations: RecordOutagePlanningScopeLocation[] = ["Onsite", "Offsite"];
export const RecordOutagePlanningScopeLocationOptions = RecordOutagePlanningScopeLocations.map((value) => ({ value }));

/// Execution
export interface RecordOutageExecutionMilestones {
  readyToLeaveCustomerFacility: Date | null;
  receivedByVendor: Date | null;
  repairWorkStarted: Date | null;
  repairWorkComplete: Date | null;
  shippedToCustomerFacility: Date | null;
  receivedAtCustomerFacility: Date | null;
}

/// Evaluation

export type RecordOutageEvaluationScopeOutcome = "Excessive" | "Satisfactory" | "Deficient" | "NotPerformed";
export const RecordOutageEvaluationScopeOutcomes: RecordOutageEvaluationScopeOutcome[] = [
  "NotPerformed",
  "Excessive",
  "Satisfactory",
  "Deficient",
];
export const RecordOutageEvaluationScopeOutcomeOptions = RecordOutageEvaluationScopeOutcomes.map((value) => ({
  value,
  label: value === "NotPerformed" ? "Evaluation not performed" : value,
}));

export type RecordOutageEvaluationConsiderationCategory =
  | "Modernization"
  | "Design change"
  | "Extensive repair"
  | "Long lead item";
export const RecordOutageEvaluationConsiderationCategories: RecordOutageEvaluationConsiderationCategory[] = [
  "Modernization",
  "Design change",
  "Extensive repair",
  "Long lead item",
];
export const RecordOutageEvaluationConsiderationCategoryOptions = RecordOutageEvaluationConsiderationCategories.map(
  (value) => ({ value })
);

export interface RecordOutageEvaluationConsideration {
  id: number;
  recordOutageDetailId: number;
  order: number;
  category: RecordOutageEvaluationConsiderationCategory | null;
  details: string | null;
}

export type MilestoneItem = {
  label: string;
  fieldName: keyof RecordOutageExecutionMilestones;
  previousFieldName?: keyof RecordOutageExecutionMilestones;
};

export const GetMilestonesForOutage = (outageDetail: RecordOutageDetailView): MilestoneItem[] => {
  const hasAcceptedScope = !!outageDetail.acceptedScope;

  if (
    outageDetail.acceptedScope?.model.primaryAction === "Replace" &&
    outageDetail.acceptedScope?.model.primaryComponent === "Assembly"
  ) {
    return [
      {
        label: OutageRecordMilestoneFieldLabels.shippedToCustomerFacility,
        fieldName: "shippedToCustomerFacility",
      },
      {
        label: OutageRecordMilestoneFieldLabels.receivedAtCustomerFacility,
        fieldName: "receivedAtCustomerFacility",
        previousFieldName: "shippedToCustomerFacility",
      },
    ];
  }

  if (hasAcceptedScope && outageDetail.acceptedScope?.model.primaryLocation === "Offsite") {
    return [
      {
        label: OutageRecordMilestoneFieldLabels.readyToLeaveCustomerFacility,
        fieldName: "readyToLeaveCustomerFacility",
      },
      {
        label: OutageRecordMilestoneFieldLabels.receivedByVendor,
        fieldName: "receivedByVendor",
      },
      {
        label: OutageRecordMilestoneFieldLabels.repairWorkStarted,
        fieldName: "repairWorkStarted",
      },
      {
        label: OutageRecordMilestoneFieldLabels.repairWorkComplete,
        fieldName: "repairWorkComplete",
        previousFieldName: "repairWorkStarted",
      },
      {
        label: OutageRecordMilestoneFieldLabels.shippedToCustomerFacility,
        fieldName: "shippedToCustomerFacility",
      },
      {
        label: OutageRecordMilestoneFieldLabels.receivedAtCustomerFacility,
        fieldName: "receivedAtCustomerFacility",
        previousFieldName: "shippedToCustomerFacility",
      },
    ];
  } else {
    return [
      {
        label: OutageRecordMilestoneFieldLabels.repairWorkStarted,
        fieldName: "repairWorkStarted",
      },
      {
        label: OutageRecordMilestoneFieldLabels.repairWorkComplete,
        fieldName: "repairWorkComplete",
        previousFieldName: "repairWorkStarted",
      },
    ];
  }
};

export type RecordOutageScopeOutcome = "Excessive" | "Satisfactory" | "Deficient" | "NotPerformed";

export const RecordOutageScopeOutcomes: RecordOutageScopeOutcome[] = [
  "Excessive",
  "Satisfactory",
  "Deficient",
  "NotPerformed",
];

const requiredMessage = "Required";
const numberTypeMessage = "Must be a number";
const positiveMessage = "Must be a positive number";

export const RecordOutagePlanningScopeSchema = yup
  .object({
    primaryAction: yup.string().required(requiredMessage).nullable(),
    primaryComponent: yup.string().nullable(),
    primaryLocation: yup.string().nullable(),
    secondaryAction: yup.string().nullable(),
    secondaryComponent: yup.string().nullable(),
    secondaryLocation: yup.string().nullable(),
    quoteAmount: yup
      .number()
      .label("Quote amount")
      .transform((value: any) => (value === "" || isNaN(value) ? null : Number(value)))
      .when("primaryAction", {
        is: "Removed from scope",
        then: yup
          .number()
          .transform((x) => x || 0)
          .min(0, "Must be greater than or equal to 0")
          .nullable(),
        otherwise: yup
          .number()
          .transform((x) => (isNaN(x) ? null : Number(x)))
          .positive(positiveMessage)
          .test("valid-quote-amount", "Must be more than labour portion", function (value) {
            const { labourPortion } = this.parent;
            if (value == null) {
              return labourPortion == null || isNaN(labourPortion);
            }
            if (isNaN(value)) {
              return false;
            }
            return labourPortion == null || isNaN(labourPortion) || (!!value && value >= labourPortion);
          })
          .nullable(),
      })
      .nullable(),
    quoteDate: yup.date().nullable(),
    quoteNumber: yup.string().nullable(),
    leadTime: yup.number().typeError(numberTypeMessage).positive(positiveMessage).nullable(),
    leadTimeUnit: yup.string().nullable().oneOf(LeadTimeUnits),
    vendor: yup.string().nullable(),
    labourPortion: yup
      .number()
      .transform((value: any) => (value === "" || isNaN(value) ? null : Number(value)))
      .label("Labour portion")
      .test("valid-labour-portion", "Must be less than quote amount", function (value) {
        const { primaryAction, quoteAmount } = this.parent;
        if (!!primaryAction && primaryAction === "Removed from scope") {
          return true;
        }
        if (value == null) {
          return quoteAmount == null || quoteAmount === 0;
        }
        if (isNaN(value)) {
          return false;
        }
        return !!primaryAction && !!quoteAmount && value <= quoteAmount;
      })
      .nullable(),
    details: yup.string().nullable(),
    validationDetails: yup.string().nullable(),
    justification: yup.string().nullable(),
    poAmount: yup.number().typeError(numberTypeMessage).positive().nullable(),
    poDate: yup.date().nullable(),
    poNumber: yup.string().nullable(),
  })
  .required();

export interface RecordOutageDetailsLookupValuesViewModel {
  scopesOfWork: StringValueWithUsageCountViewModel[];
  vendors: StringValueWithUsageCountViewModel[];
}
