import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useAxiosGenerateReport } from "app";
import { useAppSettingsContext } from "app/contexts/AppSettingsContext";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import { RecordReportV2Params, generateReportUrl } from "common/reports";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RecordDetailView, RecordType } from "records/models";

interface GenerateReportDialogProps extends BaseDialogProps {
  record: RecordDetailView;
}

export const GenerateReportDialogV2 = (props: GenerateReportDialogProps) => {
  const { appSettings } = useAppSettingsContext();
  const [isLoading, setIsLoading] = useState(false);

  const showOptions = getShowOptions(props.record.model.recordType);

  const methods = useForm<RecordReportV2Params>({
    defaultValues: {
      id: props.record.id,
      includeAssetSpecifications: showOptions.includeAssetSpecifications,
      includeAssetTags: showOptions.includeAssetTags,
      includeRecordDetails: showOptions.includeRecordDetails,
      includeKpis: showOptions.includeKpis,
      includeHistory: showOptions.includeHistory,
      includePhotos: showOptions.includePhotos,
      includeLegend: showOptions.includeLegend,
      appendPdfAttachments: showOptions.appendPdfAttachments,
    },
  });

  const generateRecordReportNode = useAxiosGenerateReport<RecordReportV2Params>("api/reports/record", {
    onStartCallback: () => setIsLoading(true),
    onSuccessCallback: () => {
      setIsLoading(false);
      props.onClose();
    },
    onFailCallback: () => setIsLoading(false),
  });

  const handleSubmit = useCallback(() => {
    generateRecordReportNode.call(methods.getValues());
  }, []);

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6">Generate report </Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={0}>
            {showOptions.includeAssetSpecifications && (
              <CheckboxFormItem fieldName="includeAssetSpecifications" label="Include Asset Specifications" />
            )}
            {showOptions.includeAssetTags && (
              <CheckboxFormItem fieldName="includeAssetTags" label="Include Asset Tags" />
            )}
            {showOptions.includeRecordDetails && (
              <CheckboxFormItem fieldName="includeRecordDetails" label="Include Record Details" />
            )}
            {showOptions.includeKpis && <CheckboxFormItem fieldName="includeKpis" label="Include KPIs" />}
            {showOptions.includeHistory && <CheckboxFormItem fieldName="includeHistory" label="Include History" />}
            {showOptions.includePhotos && <CheckboxFormItem fieldName="includePhotos" label="Include Photos" />}
            {showOptions.includeLegend && <CheckboxFormItem fieldName="includeLegend" label="Include Legend" />}
            {showOptions.appendPdfAttachments && (
              <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF Attachments" />
            )}
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="secondary"
            disabled={!appSettings || isLoading}
            onClick={handleSubmit}
          >
            Generate <OpenInNewIcon sx={{ pl: 1, width: "1rem" }} />
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};

function getShowOptions(recordType: RecordType): { [key: string]: boolean } {
  return {
    includeAssetSpecifications: [
      RecordType.FieldInspection,
      RecordType.ValveDiagnostic,
      RecordType.VibrationAnalysis,
    ].includes(recordType),
    includeAssetTags: [
      RecordType.Activity,
      RecordType.CandSU,
      RecordType.OutageScope,
      RecordType.Predictive,
      RecordType.Preventative,
      RecordType.Proactive,
      RecordType.Reactive,
      RecordType.VibrationAnalysis,
    ].includes(recordType),
    includeRecordDetails: true,
    includeKpis: true,
    includeHistory: true,
    includePhotos: true,
    includeLegend: [RecordType.FieldInspection, RecordType.ValveDiagnostic, RecordType.VibrationAnalysis].includes(
      recordType
    ),
    appendPdfAttachments: true,
  };
}
