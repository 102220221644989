import { Keyword } from "keywords";
import { AssetType } from "./assetType";

export interface AssetDigest {
  id: number;
  siteId: number;
  assetType: AssetType;
  tag: string;
  applicationId: number | null;
  areaId: number | null;
  serviceDescription: string;
}

export interface AssetTableDigest extends AssetDigest {
  areaName: string;
  criticality: string;
}

export interface AssetBase {
  id: number;
  siteId: number;
  assetType: AssetType;
  tag: string;

  areaId: number | null;
  applicationId: number | null;
  criticalityId: number | null;
  serviceDescription: string;
  locationDescription: string | null;
  legalSubdivision: string | null;
  gpsCoordinates: string | null;
  pipingAndInstrumentDiagramNumber: string | null;
  datasheetNumber: string | null;

  keywords: Keyword[];
}

export interface Valve extends AssetBase {
  valveIsObsolete: boolean;
  valveManufacturer: string | null;
  valveModel: string | null;
  valveSerialNumber: string | null;
}

export interface AdvancedValve extends Valve {
  valveSize: string | null;
  valvePressureClass: string | null;
  valveRatedTravel: string | null;
  valveSeatMaterial: string | null;
  valveLeakClass: string | null;
  valvePortSize: string | null;
  valveBodyMaterial: string | null;
  valveTrimStyleNumber: string | null;
  valvePlugDiscGateBallMaterial: string | null;
  valveStemShaftMaterial: string | null;
  valveStemDiameter: string | null;
  valveCageMaterial: string | null;
  valvePackingTypeMaterial: string | null;
  valveProcessFluid: string | null;
}

export interface AutomatedValve extends AdvancedValve {
  actuatorObsolete: boolean;
  actuatorManufacturer: string | null;
  actuatorModel: string | null;
  actuatorSerialNumber: string | null;
  actuatorSize: string | null;
  actuatorLowerBenchSet: string | null;
  actuatorUpperBenchSet: string | null;
  actuatorNominalSupplyPressure: string | null;
  actuatorStrokeTime: string | null;

  accessoryObsolete: boolean;
  volumeBooster: string | null;
  quickRelease: string | null;
  solenoidValve: string | null;
  instrumentRegulator: string | null;
  pressureSwitch: string | null;
  positionTransmitter: string | null;
  limitSwitch: string | null;
  handwheel?: boolean | null;
}

export interface ControlValve extends AutomatedValve {
  actuatorAir: string | null;
}

export interface IsolationValve extends AutomatedValve {
  actuatorFailAction: string | null;
  actuatorVoltage: string | null;
  actuatorPhase: string | null;
  actuatorTorque: string | null;
}

export interface ManualValve extends AdvancedValve {
  positionTransmitter: string | null;
  limitSwitch: string | null;
  handwheel?: boolean | null;
}

export interface ReliefValve extends Valve, HasPilotBase {
  capacity: string | null;
  capacityUnits: string | null;
  setPressure: string | null;
  setPressureUnits: string | null;
  inletSize: string | null;
  inletSizeUnits: string | null;
  inletRatingType: string | null;
  outletSize: string | null;
  outletSizeUnits: string | null;
  outletRatingType: string | null;
  orificeSizeLetter: string | null;
}

export interface HasPilotBase {
  pilotIsObsolete: boolean | null;
  pilotManufacturer: string | null;
  pilotModel: string | null;
  pilotSize: string | null;
  pilotSpringRange: string | null;
}

export interface Regulator extends AssetBase, HasPilotBase {
  regulatorIsObsolete: boolean | null;
  regulatorManufacturer: string | null;
  regulatorModelNumber: string | null;
  regulatorSerialNumber: string | null;
  regulatorSize: string | null;
  regulatorRating: string | null;
  regulatorOrificeSize: string | null;
  regulatorSpringRange: string | null;
  regulatorSetpoint: string | null;
  regulatorSetpointUnits: string | null;
}

export interface Measurement extends AssetBase {
  measurementIsObsolete: boolean | null;
  measurementType: string | null;
  measurementTechnology: string | null;

  transmitterManufacturer: string | null;
  transmitterModelNumber: string | null;
  transmitterSerialNumber: string | null;
  ventID: string | null;

  elementIsObsolete: boolean | null;
  elementManufacturer: string | null;
  elementModel: string | null;
  elementSerialNumber: string | null;
  elementSize: string | null;
  elementPressureClass: string | null;
  elementKFactor: string | null;
  elementCalNumber: string | null;
}

export interface Machinery extends AssetBase {
  component1IsObsolete: boolean | null;
  component1Type: string | null;
  component1SubType: string | null;
  component1BearingType: string | null;

  component2IsObsolete: boolean | null;
  component2Type: string | null;
  component2SubType: string | null;
  component2BearingType: string | null;

  component3IsObsolete: boolean | null;
  component3Type: string | null;
  component3SubType: string | null;
  component3BearingType: string | null;

  machineryIsObsolete: boolean | null;
  ratedPower: string | null;
  dataSource: string | null;
  notes: string | null;
}

export type Asset = ControlValve & IsolationValve & Machinery & ManualValve & Measurement & Regulator & ReliefValve;

export interface SteamTrapSpec {
  id: number;
  assetId: number;
  isObsolete: boolean;
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  size: string | null;
  type: string | null;
  connection: string | null;
  orificeSize: string | null;
  installOrientation: string | null;
  applicationType: string | null;
  inletPressure: string | null;
  outletPressure: string | null;
  inletTemperature: string | null;
  outletTemperature: string | null;
  ultrasonicReading: string | null;
  ultrasonicFlow: string | null;
  isInletIsolationValve: boolean | null;
  isOutletIsolationValve: boolean | null;
  isInletStrainer: boolean | null;
  isOutletCheckValve: boolean | null;
  isCondensateRecovered: boolean | null;
}

export interface ValveSpec {
  id: number;
  assetId: number;
  isObsolete: boolean;
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  size: string | null;
  bodyMaterial: string | null;
  cageMaterial: string | null;
  leakClass: string | null;
  packingTypeMaterial: string | null;
  plugDiscGateBallMaterial: string | null;
  portSize: string | null;
  pressureClass: string | null;
  ratedTravel: string | null;
  seatMaterial: string | null;
  stemShaftMaterial: string | null;
  trimStyleNumber: string | null;
  processFluid: string | null;
  stemDiameter: string | null;
  capacity: string | null;
  capacityUnits: string | null;
  setPressure: string | null;
  setPressureUnits: string | null;
  inletRatingType: string | null;
  inletSize: string | null;
  inletSizeUnits: string | null;
  outletRatingType: string | null;
  outletSize: string | null;
  outletSizeUnits: string | null;
  orificeSizeLetter: string | null;
}

export interface ActuatorSpec {
  id: number;
  assetId: number;
  isObsolete: boolean;
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  size: string | null;
  air: string | null;
  failAction: string | null;
  lowerBenchSet: string | null;
  nominalSupplyPressure: string | null;
  phase: string | null;
  strokeTime: string | null;
  torque: string | null;
  upperBenchSet: string | null;
  voltage: string | null;
  order: string | null;
  speedRating: string | null;
  powerSupply: string | null;
  temperatureRange: string | null;
  motorCurrent: string | null;
  dutyCycle: string | null;
}

export interface AccessorySpec {
  id: number;
  assetId: number;
  isObsolete: boolean;
  instrumentRegulator: string | null;
  limitSwitch: string | null;
  positionTransmitter: string | null;
  pressureSwitch: string | null;
  quickRelease: string | null;
  solenoidValve: string | null;
  volumeBooster: string | null;
  handwheel: boolean | null;
  gearBox: string | null;
}

export interface MeasurementSpec {
  id: number;
  assetId: number;
  isObsolete: boolean;
  type: string | null;
  technology: string | null;
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  ventId: string | null;
}

export interface ElementSpec {
  id: number;
  assetId: number;
  isObsolete: boolean;
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  size: string | null;
  pressureClass: string | null;
  kFactor: string | null;
  calNumber: string | null;
}

export interface TankSpec {
  id: number;
  assetId: number;
  isObsolete: boolean;
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  product: string | null;
  apiStandard: string | null;
  annex: string | null;
  edition: string | null;
  nominalDiameter: string | null;
  maximumCapacity: string | null;
  designSpecificGravity: string | null;
  designPressure: string | null;
  pressureCombinationFactor: string | null;
  fabricatedBy: string | null;
  erectedBy: string | null;
  yearCompleted: string | null;
  nominalHeight: string | null;
  designLiquidLevel: string | null;
  designMetalTemperature: string | null;
  maximumDesignTemperature: string | null;
  stressRelief: string | null;
  purchasersTankNumber: string | null;
  shellCourse: string | null;
  material: string | null;
}

export interface RegulatorSpec {
  id: number;
  assetId: number;
  isObsolete: boolean | null;
  manufacturer: string | null;
  model: string | null;
  serialNumber: string | null;
  size: string | null;
  rating: string | null;
  orificeSize: string | null;
  springRange: string | null;
  setpoint: string | null;
  setpointUnits: string | null;
}

export interface PilotSpec {
  id: number;
  assetId: number;
  isObsolete: boolean | null;
  manufacturer: string | null;
  model: string | null;
  size: string | null;
  springRange: string | null;
}

export class AssetFilters {
  public businessArea: string | null = null;
  public tagFragment = "";
  public assetType: AssetType | null = null;
  public areaIds: (number | null)[] = [];
  public applicationIds: (number | null)[] = [];
  public failureModeNames: (string | null)[] = [];
  public criticalityIds: (number | null)[] = [];
  public deviceTypes: string[] = [];
  public otherDeviceTypes: string[] = [];
  public measurementTypes: string[] = [];
  public serviceDescription = "";
  public locationDescription = "";
  public keywords: Keyword[] = [];
}

export interface SpecOption {
  id: number;
  type: string;
  value: string;
  order: number;
}
