import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { TimeUnit, TimeUnits, Validity } from "common";
import classNames from "classnames";

interface Props {
   value: TimeUnit | null;
   showValidationErrors: boolean;
   validity: Validity;
   readOnly: boolean;
   limitTo?: TimeUnit[];
   saveChange: (selected: TimeUnit | null) => any;
   mode: "Per" | "Plural";
   style?: React.CSSProperties;
}

const TimeUnitSingularOptions = TimeUnits.map((u) => {
   return { value: u, label: u as string };
});
const TimeUnitPluralOptions = TimeUnits.map((u) => {
   return { value: u, label: `${u}s` };
});

export const SelectTimeUnit: React.FunctionComponent<Props> = (props) => {
   const allOptions = props.mode === "Plural" ? TimeUnitPluralOptions : TimeUnitSingularOptions;
   const options = !!props.limitTo ? allOptions.filter((o) => props.limitTo!.indexOf(o.value) >= 0) : allOptions;
   return (
      <FormGroup style={props.style}>
         <div className="form-group-input-wrapper">
            {props.mode === "Per" && <Label className="pre-label">per</Label>}
            <Input
               name="selectTimeUnit"
               type="select"
               className={classNames(
                  {
                     "is-invalid": props.showValidationErrors && !props.validity.valid,
                  },
                  { placeholder: !!!props.value }
               )}
               disabled={props.readOnly}
               onChange={(change) => {
                  const newOption = change.target.value as TimeUnit;
                  props.saveChange(newOption);
               }}
               value={props.value || ""}
               style={{ width: "auto" }}
            >
               {!!!props.value && (
                  <option value="" disabled>
                     {props.readOnly ? "" : "Select rate"}
                  </option>
               )}
               {options.map((o) => (
                  <option key={o.value} value={o.value}>
                     {o.label}
                  </option>
               ))}
            </Input>
         </div>
         <div className="invalid-feedback">{props.validity.message}</div>
      </FormGroup>
   );
};
