import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { Asset, SteamTrapSpec } from "assets/models/asset";
import { useForm } from "react-hook-form";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import AutoSave from "app/mui/forms/AutoSave";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { EditableYesNoFormItem } from "app/mui/forms/YesNoForItem";
import { useUpdateSteamTrapSpecificationRequest } from "assets/api";

interface SteamTrapSpecificationCardProps {
  asset: Asset;
  steamTrap: SteamTrapSpec;
}

export const SteamTrapSpecificationCard = (props: SteamTrapSpecificationCardProps) => {
  const updateSpecificationRequest = useUpdateSteamTrapSpecificationRequest();

  const defaultValues = makeSafeForReactHookForm(props.steamTrap);
  const methods = useForm({ defaultValues });

  const updateSteamTrap = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const standardFields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <SpecOptionLoader type="SteamTrapManufacturer">
      {(options) => <EditableAutocompleteFormItem label="Manufacturer" fieldName="manufacturer" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <SpecOptionLoader type="ValveOrElementSize">
      {(options) => <EditableAutocompleteFormItem label="Size" fieldName="size" {...options} />}
    </SpecOptionLoader>,
    <SpecOptionLoader type="SteamTrapType">
      {(options) => <EditableAutocompleteFormItem label="Type" fieldName="type" {...options} />}
    </SpecOptionLoader>,
    <SpecOptionLoader type="SteamTrapConnection">
      {(options) => <EditableAutocompleteFormItem label="Connection" fieldName="connection" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="Orifice size" fieldName="orificeSize" />,
    <SpecOptionLoader type="SteamTrapInstallOrientation">
      {(options) => (
        <EditableAutocompleteFormItem label="Install Orientation" fieldName="installOrientation" {...options} />
      )}
    </SpecOptionLoader>,
    <SpecOptionLoader type="SteamTrapApplicationType">
      {(options) => <EditableAutocompleteFormItem label="Application Type" fieldName="applicationType" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="Inlet Pressure" fieldName="inletPressure" />,
    <EditableTextFieldFormItem label="Outlet Pressure" fieldName="outletPressure" />,
    <EditableTextFieldFormItem label="Inlet Temperature" fieldName="inletTemperature" />,
    <EditableTextFieldFormItem label="Outlet Temperature" fieldName="outletTemperature" />,
    <EditableTextFieldFormItem label="Ultrasonic Reading" fieldName="ultrasonicReading" />,
    <SpecOptionLoader type="UltrasonicFlow">
      {(options) => <EditableAutocompleteFormItem label="Ultrasonic dB flow" fieldName="ultrasonicFlow" {...options} />}
    </SpecOptionLoader>,
    <EditableYesNoFormItem label="Inlet isolation valve" fieldName="isInletIsolationValve" />,
    <EditableYesNoFormItem label="Outlet isolation valve" fieldName="isOutletIsolationValve" />,
    <EditableYesNoFormItem label="Inlet strainer" fieldName="isInletStrainer" />,
    <EditableYesNoFormItem label="Outlet check valve" fieldName="isOutletCheckValve" />,
    <EditableYesNoFormItem label="Condensate recovered" fieldName="isCondensateRecovered" />,
  ];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave onSubmit={updateSteamTrap} />
      <AssetSpecificationCard title="Steam Trap" specs={standardFields} />
    </PermissionAwareFormProvider>
  );
};
