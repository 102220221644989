import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { Tooltip, Typography } from "@mui/material";
import { IrisColors, theme } from "app/mui/theme";

interface KpiSignOffProps {
  isSignedOff: boolean;
}
export function KpiSignOff({ isSignedOff }: KpiSignOffProps) {
  return (
    <Tooltip
      title={
        <span
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {isSignedOff ? "Signed-off" : "Not signed-off"}
        </span>
      }
      placement="top"
      sx={{ pointerEvents: "auto" }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Typography fontSize={20} color={isSignedOff ? theme.palette.secondary.main : IrisColors.gray300}>
        <AssignmentTurnedInIcon />
      </Typography>
    </Tooltip>
  );
}
