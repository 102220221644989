import * as React from "react";
import RichTextEditor, { EditorValue } from "react-rte";

export interface Props {
   onChange: (value: EditorValue) => void;
   value: EditorValue;
}

export class RTEditor extends React.Component<Props> {
   public render() {
      return (
         <RichTextEditor
            className="rich-text-editor"
            value={this.props.value}
            onChange={this.props.onChange}
            rootStyle={{
               fontFamily: '"Segoe UI", Segoe, Calibri, sans-serif',
               borderRadius: "0",
               fontSize: "14px",
            }}
            toolbarConfig={{
               display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
               INLINE_STYLE_BUTTONS: [
                  { label: "Bold", style: "BOLD" },
                  { label: "Italic", style: "ITALIC" },
                  { label: "Underline", style: "UNDERLINE" },
               ],
               BLOCK_TYPE_DROPDOWN: [
                  { label: "Normal", style: "unstyled" },
                  { label: "Heading Large", style: "header-one" },
                  { label: "Heading Medium", style: "header-two" },
                  { label: "Heading Small", style: "header-three" },
               ],
               BLOCK_TYPE_BUTTONS: [
                  { label: "UL", style: "unordered-list-item" },
                  { label: "OL", style: "ordered-list-item" },
               ],
            }}
         />
      );
   }
}
