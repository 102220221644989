import { AttachmentModel as Attachment } from "@bluemarvel/iris-common/model";

export const attachmentIsImage = (fileName: string) => {
  return /\.(gif|jpe?g|png|x-png|webp|bmp)$/i.test(fileName.toLowerCase());
};

export const attachmentIsPdf = (fileName: string) => {
  return /\.pdf$/i.test(fileName.toLowerCase());
};

export const downloadAttachment = async (attachment: Attachment) => {
  /*
   * To download the file create an anchor tag with the url of the file and the desired file name like such:
   * <a href='url-to-blob-storage' download='desired-file-name' />
   *
   * and then programmatically click the button.
   *
   * some browsers like safari require the element to actually be added to the body of the page in order for this to
   * work, so we ensure it is hidden and added to the page before we click on it, we should then remove it afterwords.
   */

  // Fetch blob resource from azure and create a object url for download
  const blobRequest = await fetch(attachment.uri);
  if (!blobRequest.ok) {
    return;
  }
  const blobData = await blobRequest.blob();
  const objectUrl = window.URL.createObjectURL(new Blob([blobData]));
  createAttachmentElement(objectUrl, attachment.fileName);
};

export const openAttachment = (attachment: Attachment) => {
  createAttachmentElement(attachment.uri, attachment.fileName);
};

const createAttachmentElement = (uri: string, fileName: string) => {
  const tag = document.createElement("a");
  tag.href = uri;
  tag.download = fileName;
  tag.target = "_blank"; // some browsers no longer allow cross origin downloads, and instead open in a new tab
  tag.style.display = "hidden";
  tag.style.visibility = "none";
  document.body.appendChild(tag);
  tag.click();
  document.body.removeChild(tag);
};
