import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useUpdatePilotSpecificationRequest } from "assets/api";
import { Asset, PilotSpec } from "assets/models/asset";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";

interface PilotSpecificationCardProps {
  asset: Asset;
  pilot: PilotSpec;
}

export const PilotSpecificationCard = (props: PilotSpecificationCardProps) => {
  const updateSpecificationRequest = useUpdatePilotSpecificationRequest();

  const defaultValues = makeSafeForReactHookForm(props.pilot);
  const methods = useForm({ defaultValues });

  const updatePilot = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Pilot size" fieldName="size" />,
    <EditableTextFieldFormItem label="Spring range" fieldName="springRange" />,
  ];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave onSubmit={updatePilot} />
      <AssetSpecificationCard title="Pilot" specs={fields} />
    </PermissionAwareFormProvider>
  );
};
