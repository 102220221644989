import React, { useEffect } from "react";
import { Record } from "records";
import { Label, FormGroup, Card, CardBody, CardTitle } from "reactstrap";
import { Keyword, useSearchKeywordsRequest } from "keywords";
import { Checkbox } from "common";
import { KeywordSelect } from "keywords/KeywordSelect";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";

export enum SpecialKeywords {
  Outage = "outage",
}

interface Props {
  record: Record;
  saveChange: (changedProp: Partial<Record>, skipDelay?: boolean) => void;
  userCanEdit: boolean;
  mobileOnly?: boolean;
}

export const RecordKeywords: React.FunctionComponent<Props> = (props) => {
  const outageKeywordQuery = useSearchKeywordsRequest();
  useEffect(() => {
    outageKeywordQuery.call({ in: ["outage"] });
  }, []);
  const outageKeywordResponse = outageKeywordQuery.data || [];
  const outageKeyword = outageKeywordResponse.length > 0 ? outageKeywordResponse[0] : null;

  const renderKeywordCheckbox = (keyword: Keyword, userCanEdit: boolean) => {
    return (
      <Checkbox
        value={recordHasKeyword(keyword)}
        onClick={() => {
          const updatedKeywords = recordHasKeyword(keyword)
            ? props.record.keywords.filter((rk) => rk.id !== keyword.id)
            : props.record.keywords.concat(keyword);
          onChangeKeywords(updatedKeywords, false);
        }}
        disabled={!userCanEdit}
      >
        {keyword.value}
      </Checkbox>
    );
  };

  const recordHasKeyword = (keyword: Keyword) => {
    return props.record.keywords.some((rk) => rk.id === keyword.id);
  };

  const onChangeKeywords = (selectedKeywords: Keyword[], preserveSpecialKeywords = true) => {
    const specialKeywords = preserveSpecialKeywords
      ? props.record.keywords.filter((rk) => [SpecialKeywords.Outage.toString()].includes(rk.value))
      : [];

    props.saveChange(
      {
        keywords: specialKeywords.concat(selectedKeywords),
      },
      true
    );
  };

  return (
    <Card
      className={classNames("keywords", {
        "mobile-only": props.mobileOnly,
        "non-mobile-only": !props.mobileOnly,
      })}
    >
      <CardBody>
        <CardTitle>Keywords</CardTitle>
        {!outageKeyword && <CircularProgress />}
        {!!outageKeyword && (
          <>
            <FormGroup>{renderKeywordCheckbox(outageKeyword, props.userCanEdit)}</FormGroup>
            <FormGroup>
              <Label>Additional keywords</Label>
              <KeywordSelect
                ignoreKeywords={[SpecialKeywords.Outage]}
                selectedKeywords={props.record.keywords.filter((k) => k.id !== outageKeyword.id)}
                onChangeKeywords={onChangeKeywords}
                disabled={!props.userCanEdit}
              />
            </FormGroup>
          </>
        )}
      </CardBody>
    </Card>
  );
};
