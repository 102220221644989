import * as React from "react";
import {
  Autocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { FormItemLayout, FormItemLayoutProps } from "./FormItemLayout";
import { withEditableToggle } from "./withEditableToggle";
import { RecordTypeBadge } from "../RecordTypeBadge";

export type Option = { id?: string | number; label?: string };
export interface AutocompleteFormItemProps<
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  T = Option
> extends Omit<FormItemLayoutProps, "controlled" | "children" | "formFieldProps"> {
  fieldName?: string;
  freeSolo?: FreeSolo;
  multiple?: Multiple;
  loading?: boolean;
  placeholder?: string;
  autoDefault?: boolean;
  options: T[];
  noOptionsText?: string | null;
  getOptionLabel?: (_: T | AutocompleteFreeSoloValueMapping<FreeSolo>) => string;
  autocompleteProps?: Partial<AutocompleteProps<T, Multiple, boolean | undefined, FreeSolo>>;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => void;
  idSelector?: (_: T) => string | number | T | null | undefined;
  transformMultiFieldValues?: (fieldValue: T[]) => T[];
  groupBy?: (option: any) => any;
  renderOption?: (
    props: Omit<React.HTMLAttributes<HTMLLIElement>, "key">,
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
}

export function AutocompleteFormItem<
  Mulitple extends boolean | undefined,
  FreeSolo extends boolean | undefined = false,
  T = Option
>(props: AutocompleteFormItemProps<Mulitple, FreeSolo, T>) {
  /*
   * MUIs autocomplete usually deals with values as objects such as {label: string, id: string}
   * but, I want the form data to be identical in format to the asset model we receive from the
   * api. So inside this component we transform the values going into and out of the autocomplete
   * component, this allows us to work with ids in the model, but display lists of objects in the
   * dropdown.
   *
   * NOTE: This does not apply to autocompletes when multiple=true.
   */
  const selectId = props.idSelector || ((obj: any) => (obj === null ? null : obj.id));

  const _noTransform = (value: T | any) => value;

  const _transformInput = (id: string | number | undefined): any => {
    return props.options.find((o) => selectId(o) === id) || id;
  };

  const _transformOutput = (option: any): any => {
    return selectId(option) ?? option ?? null;
  };

  const transformInput = props.multiple ? props.transformMultiFieldValues ?? _noTransform : _transformInput;

  const transformOutput = props.multiple ? _noTransform : _transformOutput;

  const fieldName = props.fieldName;
  const getOptionLabel = (option: T | AutocompleteFreeSoloValueMapping<FreeSolo>): string => {
    if (props.getOptionLabel) {
      return props.getOptionLabel(option);
    }

    return typeof option === "string" ? option : option["label"] || "";
  };

  const { autoDefault = true } = props;

  const inputValueProps =
    props.onInputChange !== undefined
      ? {
          onInputChange: props.onInputChange,
        }
      : {};

  return (
    <Controller
      name={props.fieldName ?? ""}
      defaultValue={
        autoDefault && props.options && props.options.length > 0 ? (props.options[0] as Option)?.id : undefined
      }
      render={({ field: { onChange, value, ...fieldProps }, fieldState }) => (
        <FormItemLayout
          controlled
          label={props.label}
          hint={props.hint}
          error={props.error || fieldState.error?.message}
          formControlProps={{
            disabled: props.autocompleteProps?.disabled,
          }}
          tip={props.tip}
        >
          {(inputProps) => (
            <Autocomplete
              size={"small"}
              autoHighlight
              autoComplete
              forcePopupIcon
              freeSolo={props.freeSolo}
              multiple={props.multiple}
              loading={props.loading}
              options={props.options}
              getOptionLabel={getOptionLabel}
              groupBy={props.groupBy}
              renderOption={
                props.renderOption ||
                ((props, option) => (
                  <li {...props} key={selectId(option)}>
                    {fieldName === "recordType" ? <RecordTypeBadge type={option["label"]} /> : getOptionLabel(option)}
                  </li>
                ))
              }
              noOptionsText={props.noOptionsText}
              value={transformInput(value) || null}
              onInputChange={(_e, data) => {
                if (props.freeSolo) {
                  onChange(transformOutput(data));
                }
              }}
              onChange={(_e, data) => {
                onChange(transformOutput(data));
              }}
              {...inputValueProps}
              {...fieldProps}
              {...{
                fullWidth: true,
                ...props.autocompleteProps,
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...inputProps}
                    {...params}
                    error={!!fieldState.error}
                    placeholder={props?.placeholder ?? "Select"}
                  />
                );
              }}
            />
          )}
        </FormItemLayout>
      )}
    />
  );
}

export const EditableAutocompleteFormItem = withEditableToggle(AutocompleteFormItem);
