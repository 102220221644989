import React, { useEffect, useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { StandardModalFooter } from "app/bs/StandardModalFooter";
import { ApplicationDetailView, MitigationType, MitigationView } from "./models";
import { AssetDigest } from "assets";
import Table from "reactstrap/lib/Table";
import _ from "lodash";
import classNames from "classnames";
import { CheckmarkIcon } from "icons/CheckmarkIcon";
import { useUserContext } from "app/contexts";
import { useUpsertApplicationStrategyMitigationImplementationsRequest } from "./api";

interface Props {
   applicationView: ApplicationDetailView;
   strategyId: number;
   mitigations: MitigationView[];
   assets: AssetDigest[];
   close: () => void;
}

export const MitigationImplementationsModal: React.FunctionComponent<Props> = (props) => {
   const { userPermissions } = useUserContext();
   const userCanEdit = userPermissions.userCanEditApplication && userPermissions.userCanEditAsset;

   const [mitigationImplementations, setMitigationImplementations] = useState<any[]>([]);
   useEffect(() => {
      setMitigationImplementations(_.flatMap(props.mitigations.map((mitigation) => mitigation.implementations)));
   }, [props.mitigations]);

   const upsertMitigationImplementations = useUpsertApplicationStrategyMitigationImplementationsRequest(
      props.strategyId
   );

   const sortedMitigations = props.mitigations
      .map((m) => m)
      .sort((a, b) => {
         if (a.model.type === b.model.type) return a.model.name.localeCompare(b.model.name);

         if (a.model.type === MitigationType.Alert) return -1;
         if (b.model.type === MitigationType.Alert) return 1;
         if (a.model.type === MitigationType.Diagnostic) return -1;
         if (b.model.type === MitigationType.Diagnostic) return 1;
         if (a.model.type === MitigationType.FieldWork) return -1;
         if (b.model.type === MitigationType.FieldWork) return 1;
         if (a.model.type === MitigationType.Proactive) return -1;
         if (b.model.type === MitigationType.Proactive) return 1;

         return a.id - b.id;
      });

   return (
      <Modal
         isOpen={true}
         className="mitigation-implementations-modal"
         size="lg"
         container={"#bootstrap-modal-container"}
      >
         <ModalHeader>Mitigation implementations</ModalHeader>
         <ModalBody>
            <Table size="sm" bordered className="mitigation-implementations-table">
               <thead>
                  <tr>
                     <th className="origin-cell">Asset</th>
                     {sortedMitigations.map((mitigation) => (
                        <th
                           key={mitigation.id}
                           className={classNames("mitigation", {
                              alert: mitigation.model.type === MitigationType.Alert,
                              diagnostic: mitigation.model.type === MitigationType.Diagnostic,
                              "field-work": mitigation.model.type === MitigationType.FieldWork,
                              proactive: mitigation.model.type === MitigationType.Proactive,
                           })}
                        >
                           <span className="mitigation-name">{mitigation.model.name || "unnamed"}</span>
                        </th>
                     ))}
                  </tr>
               </thead>
               <tbody>
                  {_.orderBy(props.assets, (a) => a.tag).map((asset) => (
                     <tr key={asset.id}>
                        <td>
                           <span className="tag">{asset.tag}</span>
                        </td>
                        {sortedMitigations.map((mitigation) => (
                           <td
                              key={mitigation.id}
                              className={classNames("implementation", {
                                 clickable: userCanEdit,
                              })}
                              onClick={
                                 userCanEdit
                                    ? () => {
                                         const existingMi = mitigationImplementations.find(
                                            (mi) => mi.mitigationId === mitigation.id && mi.assetId === asset.id
                                         );
                                         const updatedMis = existingMi
                                            ? mitigationImplementations.filter(
                                                 (mi) => !(mi.mitigationId === mitigation.id && mi.assetId === asset.id)
                                              )
                                            : mitigationImplementations.concat([
                                                 {
                                                    mitigationId: mitigation.id,
                                                    assetId: asset.id,
                                                    implemented: new Date(),
                                                 },
                                              ]);
                                         setMitigationImplementations(updatedMis);
                                      }
                                    : undefined
                              }
                           >
                              {mitigationImplementations.some(
                                 (mc) => mc.mitigationId === mitigation.id && mc.assetId === asset.id
                              ) ? (
                                 <CheckmarkIcon />
                              ) : null}
                           </td>
                        ))}
                     </tr>
                  ))}
               </tbody>
            </Table>
         </ModalBody>

         <StandardModalFooter
            confirmLabel="Save"
            confirm={() => {
               upsertMitigationImplementations.call(mitigationImplementations).then(() => {
                  props.close();
               });
            }}
            confirmDisabled={!userCanEdit || upsertMitigationImplementations.loading}
            cancel={() => {
               props.close();
            }}
         />
      </Modal>
   );
};
