import React from "react";
import { Row, Col, Card, CardTitle, FormGroup, Label, Button, Form, Collapse } from "reactstrap";
import { IrisTooltip, useAutosaver, useNotifications, useUserContext, useValidatedSiteIdFromRoute } from "app";
import { RTEditor, getValidity } from "common";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Application, ApplicationDetailView } from "./models";
import RichTextEditor, { EditorValue } from "react-rte";
import { AddIcon } from "icons/AddIcon";
import { StrategyDisplay } from "./StrategyDisplay";
import { DeleteIcon } from "icons/DeleteIcon";
import { formatRoutePath, Routes } from "app";
import { ApplicationIcon } from "icons/ApplicationIcon";
import classNames from "classnames";
import Textarea from "react-textarea-autosize";
import { ConfirmationModal } from "common/ConfirmationModal";
import { ConfigureApplicationReportModal } from "./ConfigureApplicationReportModal";
import { DuplicateApplicationModal } from "applications/DuplicateApplicationModal";
import { InformationIcon } from "icons/InformationIcon";
import { CollapseIcon } from "icons/CollapseIcon";
import { AssetSelector } from "../common/AssetSelector";
import { useState } from "react";
import {
   useCreateApplicationStrategyRequest,
   useDeleteApplicationRequest,
   useGetApplicationRequest,
   useUpdateApplicationRequest,
} from "./api";
import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import { AttachmentsArea } from "attachments/mui/AttachmentsArea";

export const ApplicationScreen: React.FunctionComponent<{}> = (props) => {
   const applicationId = useValidatedSiteIdFromRoute(Routes.Applications);
   const getApplicationRequest = useGetApplicationRequest(applicationId);
   const applicationView =
      getApplicationRequest.data && getApplicationRequest.data.id === applicationId ? getApplicationRequest.data : null;
   if (getApplicationRequest.loading && !applicationView) return <LinearProgress color="secondary" />;
   if (!applicationView) return <>Not found.</>;
   return <ApplicationScreenWithApplication key={applicationId} applicationView={applicationView} />;
};

const ApplicationScreenWithApplication: React.FunctionComponent<{ applicationView: ApplicationDetailView }> = (
   props
) => {
   const navigate = useNavigate();
   const notifications = useNotifications();

   const { applicationView } = props;
   const updateApplicationRequest = useUpdateApplicationRequest();
   const [applicationModel, saveApplicationModel] = useAutosaver(applicationView.model, (app) =>
      updateApplicationRequest.call(app)
   );
   const saveChange = (changed: Partial<Application>) => saveApplicationModel({ ...applicationModel, ...changed });
   const deleteApplicationRequest = useDeleteApplicationRequest();

   const createStrategyRequest = useCreateApplicationStrategyRequest();

   const applicationNameValidity = getValidity(!!applicationModel.name);
   const [processCriticalityValue, setProcessCriticalityValue] = useState(
      RichTextEditor.createValueFromString(applicationModel.processCriticality || "", "html")
   );

   const [maintenanceHistoryAndIntensityValue, setMaintenanceHistoryAndIntensityValue] = useState(
      RichTextEditor.createValueFromString(applicationModel.maintenanceHistoryAndIntensity || "", "html")
   );
   const [proactiveRecommendationsValue, setProactiveRecommendationsValue] = useState(
      RichTextEditor.createValueFromString(applicationModel.proactiveRecommendations || "", "html")
   );
   const [deleting, setDeleting] = useState(false);
   const [configureReportModalOpen, setConfigureReportModalOpen] = useState(false);
   const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
   const [backgroundInfoExpanded, setBackgroundInfoExpanded] = useState(true);
   const [deletedStrategyIds, setDeletedStrategyIds] = useState<number[]>([]);

   const { userPermissions } = useUserContext();
   const userCanEdit = userPermissions.userCanEditApplication;

   const applicationAssets = applicationView.model.assets;
   const applicationAssetsWhichAreAssigned = _.flatMap(applicationView.strategies.map((s) => s.assets));
   const applicationAssetsWhichAreUnassigned = applicationAssets.filter(
      (asset) => !_.find(applicationAssetsWhichAreAssigned, (sa) => sa.id === asset.id)
   );

   return (
      <div className="legacy_bootstrap">
         <div className="application-screen">
            <title>SpartanPRO IRIS - {applicationModel.name}</title>
            <Row className="title-card-header">
               <Col md={8} lg={9}>
                  <Card body className="application-screen-title">
                     <div className="screen-title">
                        <div className="icon-and-title">
                           <ApplicationIcon />
                           <Form className="hover-borders">
                              <Textarea
                                 className={classNames("form-control", {
                                    "is-invalid": !applicationNameValidity.valid,
                                 })}
                                 value={applicationModel.name}
                                 onChange={(e) => saveChange({ name: e.target.value })}
                                 readOnly={!userPermissions.userCanEditApplication}
                              />
                              <div className="invalid-feedback">{applicationNameValidity.message}</div>
                           </Form>
                        </div>
                     </div>
                  </Card>
               </Col>

               <Col md={4} lg={3} className="d-flex justify-content-end align-items-start">
                  <div className="screen-commands">
                     {userPermissions.userCanGenerateApplicationReport && (
                        <>
                           <Button color="secondary" size="sm" onClick={() => setConfigureReportModalOpen(true)}>
                              Generate report
                           </Button>
                           <ConfigureApplicationReportModal
                              application={applicationView}
                              open={configureReportModalOpen}
                              toggle={() => setConfigureReportModalOpen(false)}
                           />
                        </>
                     )}
                     <>
                        <Button color="secondary" size="sm" onClick={() => setDuplicateModalOpen(true)}>
                           Duplicate application
                        </Button>
                        {duplicateModalOpen && (
                           <DuplicateApplicationModal
                              open={duplicateModalOpen}
                              toggle={() => setDuplicateModalOpen(false)}
                              application={applicationView}
                              duplicatedApplication={(application, viewRecord) => {
                                 setDuplicateModalOpen(false);
                                 if (viewRecord) {
                                    navigate(formatRoutePath(Routes.Application, application));
                                 }
                              }}
                           />
                        )}
                     </>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col md={8} lg={9}>
                  <Card body>
                     <CardTitle
                        className={classNames("collapse-toggle", {
                           collapsed: !backgroundInfoExpanded,
                        })}
                        onClick={() => setBackgroundInfoExpanded(!backgroundInfoExpanded)}
                     >
                        <CollapseIcon expanded={backgroundInfoExpanded} />
                        <div className="title">Background information</div>
                     </CardTitle>
                     <Collapse isOpen={backgroundInfoExpanded}>
                        <FormGroup>
                           <Label>
                              Process criticality
                              <IrisTooltip
                                 title={
                                    <>
                                       <p>
                                          Overview of the potential risk of asset failure to production, personnel, and
                                          environment.
                                       </p>
                                       <p>Key information:</p>
                                       <ol>
                                          <li>What is the asset controlling or measuring?</li>
                                          <li>
                                             What is the impact of failure in terms of production loss? What are the
                                             associated costs?
                                          </li>
                                          <li>
                                             What is the impact of failure in terms of health and safety (personnel)?
                                          </li>
                                          <li>What is the impact of failure on the environment?</li>
                                          <li>What is the impact of failure on equipment availability?</li>
                                          <li>Can the asset be bypassed for maintenance?</li>
                                          <li>Are there any severe service conditions present?</li>
                                       </ol>
                                    </>
                                 }
                              >
                                 <span id="process-criticality-info-icon" className="info-icon">
                                    <InformationIcon />
                                 </span>
                              </IrisTooltip>
                           </Label>
                           {userCanEdit ? (
                              <RTEditor
                                 value={processCriticalityValue}
                                 onChange={(value: EditorValue) => {
                                    setProcessCriticalityValue(value);
                                    saveChange({ processCriticality: value.toString("html") });
                                 }}
                              />
                           ) : (
                              <div
                                 dangerouslySetInnerHTML={{
                                    __html: processCriticalityValue.toString("html"),
                                 }}
                              />
                           )}
                        </FormGroup>
                        <FormGroup>
                           <Label>
                              Maintenance history &amp; intensity
                              <IrisTooltip
                                 title={
                                    <>
                                       <p>Overview of maintenance and failure history of assets in this application.</p>
                                       <p>Key information:</p>
                                       <ol>
                                          <li>
                                             Is there any maintenance history for assets in this application? (CMMS,
                                             IRIS, Maintenance/Ops Communication). When did the failure(s) occur, what
                                             was the problem, what was the fix, and what were the associated costs?
                                          </li>
                                          <li>Based on the application, what failure modes may become an issue?</li>
                                          <li>Are there any associated MOC's? Have any design changes occurred?</li>
                                          <li>Can similar applications in peer facilities be referenced?</li>
                                       </ol>
                                    </>
                                 }
                              >
                                 <span id="maintenance-history-info-icon" className="info-icon">
                                    <InformationIcon />
                                 </span>
                              </IrisTooltip>
                           </Label>
                           {userCanEdit ? (
                              <RTEditor
                                 value={maintenanceHistoryAndIntensityValue}
                                 onChange={(value: EditorValue) => {
                                    setMaintenanceHistoryAndIntensityValue(value);
                                    saveChange({ maintenanceHistoryAndIntensity: value.toString("html") });
                                 }}
                              />
                           ) : (
                              <div
                                 dangerouslySetInnerHTML={{
                                    __html: maintenanceHistoryAndIntensityValue.toString("html"),
                                 }}
                              />
                           )}
                        </FormGroup>
                        <FormGroup>
                           <Label>
                              Proactive recommendations
                              <IrisTooltip
                                 title={
                                    <>
                                       Opportunities to reduce the effects of failure or increase asset lifespan via
                                       improvements in asset design, strategy, and inventory.
                                    </>
                                 }
                              >
                                 <span id="proactive-recommendations-info-icon" className="info-icon">
                                    <InformationIcon />
                                 </span>
                              </IrisTooltip>
                           </Label>
                           {userCanEdit ? (
                              <RTEditor
                                 value={proactiveRecommendationsValue}
                                 onChange={(value: EditorValue) => {
                                    setProactiveRecommendationsValue(value);
                                    saveChange({ proactiveRecommendations: value.toString("html") });
                                 }}
                              />
                           ) : (
                              <div
                                 dangerouslySetInnerHTML={{
                                    __html: proactiveRecommendationsValue.toString("html"),
                                 }}
                              />
                           )}
                        </FormGroup>
                     </Collapse>
                  </Card>

                  {_.orderBy(applicationView.strategies, (s) => s.sortOrder)
                     .filter((s) => deletedStrategyIds.indexOf(s.id) === -1)
                     .map((s) => (
                        <StrategyDisplay
                           key={s.id}
                           applicationView={applicationView}
                           strategyId={s.id}
                           forAsset={null}
                           strategyWasDeleted={() => setDeletedStrategyIds(deletedStrategyIds.concat(s.id))}
                        />
                     ))}

                  {userCanEdit && (
                     <div className="add-strategy-command">
                        <div className="gap" />
                        <Button
                           color="secondary"
                           size="sm"
                           disabled={!userCanEdit || createStrategyRequest.loading}
                           onClick={async () => {
                              await createStrategyRequest.call({
                                 name: "",
                                 applicationId: applicationView.id,
                                 sortOrder: applicationView.strategies.length + 1,
                              });
                           }}
                        >
                           <AddIcon />
                           <span>Add strategy</span>
                        </Button>
                     </div>
                  )}

                  {!!applicationView.id && (
                     <Card body className={classNames("attachments")}>
                        <CardTitle className="section-title">
                           <div className="title">Attachments</div>
                           <div className="gap" />
                           <AttachmentsArea parentType="Application" parentId={applicationView.id} parentTag={applicationView.model.name} />
                        </CardTitle>
                     </Card>
                  )}
               </Col>
               <Col md={4} lg={3}>
                  <AssetSelector
                     assets={applicationView.model.assets}
                     siteId={applicationView.siteId}
                     readOnly={!userCanEdit}
                     parentDescriptor="application"
                     assetFilter={(asset) => asset.applicationId === null || asset.applicationId === applicationView.id}
                     assetWarning={(asset) =>
                        applicationAssetsWhichAreUnassigned.findIndex((a) => a.id === asset.id) >= 0 ? (
                           <div className="item-warning" title="This asset is not assigned to any strategy.">
                              unassigned
                           </div>
                        ) : null
                     }
                     addAsset={(asset) => saveChange({ assets: applicationModel.assets.concat(asset) })}
                     removeAsset={(asset) =>
                        saveChange({ assets: applicationModel.assets.filter((appAsset) => asset.id !== appAsset.id) })
                     }
                  />
               </Col>
            </Row>
            <div className="infrequent-commands">
               {userCanEdit && (
                  <>
                     <Button color="danger" size="sm" onClick={() => setDeleting(true)}>
                        <DeleteIcon />
                        <span>Delete application</span>
                     </Button>
                     <ConfirmationModal
                        title="Delete application?"
                        body="Are you sure you want to delete this application?"
                        show={deleting}
                        cancel={() => setDeleting(false)}
                        confirm={async () => {
                           await deleteApplicationRequest.call(applicationView.id).then(() => {
                              notifications.success("Application deleted.");
                              navigate(formatRoutePath(Routes.Applications, applicationView));
                           });
                        }}
                     />
                  </>
               )}
            </div>
         </div>
      </div>
   );
};
