import * as React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import { RecordsEventFilters } from "../models";
import { dateFormat } from "common/formats";
import moment from "moment";

import Select from "react-select";
import classNames from "classnames";
import _ from "lodash";

interface Props {
  filters: RecordsEventFilters;
  updateFilters: (filters: RecordsEventFilters) => void;
  nameFragment: string | null;
}

const showStatusOptions = [
  { value: null, label: "All" },
  { value: false, label: "Active" },
  { value: true, label: "Archived" },
];

export const RecordsEventFiltersDisplay: React.FunctionComponent<Props> = (props) => {
  const [nameFragment, setNameFragment] = useState(props.nameFragment);
  const debouncedUpdateFilters = useRef(
    _.debounce((updatedFilters: RecordsEventFilters) => {
      props.updateFilters(updatedFilters);
    }, 750)
  ).current;

  useEffect(() => debouncedUpdateFilters({ ...props.filters, nameFragment }), [nameFragment]);

  return (
    <div className="kpi-template-filters filters">
      <div className="filter-row">
        <FormGroup>
          <Label>Name (may be partial)</Label>
          <Input
            value={nameFragment || ""}
            onChange={(e) => {
              setNameFragment(e.target.value);
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>Start Date</Label>
          <Input
            type="date"
            value={props.filters.startDateFrom ? moment(props.filters.startDateFrom).format(dateFormat) : ""}
            onChange={(e) =>
              props.updateFilters({
                ...props.filters,
                startDateFrom: e.target.value ? moment(e.target.value).toDate() : null,
              })
            }
          />
          <Input
            type="date"
            value={props.filters.startDateTo ? moment(props.filters.startDateTo).format(dateFormat) : ""}
            onChange={(e) =>
              props.updateFilters({
                ...props.filters,
                startDateTo: e.target.value ? moment(e.target.value).toDate() : null,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>End Date</Label>
          <Input
            type="date"
            value={props.filters.endDateFrom ? moment(props.filters.endDateFrom).format(dateFormat) : ""}
            onChange={(e) =>
              props.updateFilters({
                ...props.filters,
                endDateFrom: e.target.value ? moment(e.target.value).toDate() : null,
              })
            }
          />
          <Input
            type="date"
            value={props.filters.endDateTo ? moment(props.filters.endDateTo).format(dateFormat) : ""}
            onChange={(e) =>
              props.updateFilters({
                ...props.filters,
                endDateTo: e.target.value ? moment(e.target.value).toDate() : null,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <Label>Status</Label>
          <Select
            className={classNames("react-select")}
            classNamePrefix="react-select"
            value={showStatusOptions.filter((c) => c.value === props.filters.isArchived)[0]}
            onChange={(option) =>
              props.updateFilters({
                ...props.filters,
                isArchived: option ? option.value : false,
              })
            }
            options={showStatusOptions}
          />
        </FormGroup>
      </div>
    </div>
  );
};
