import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { Controller } from "react-hook-form";

interface RadioYesNoFormItemProps extends BaseFormItemProps {
  showYesFirst?: boolean;
}

export function RadioYesNoFormItem(props: RadioYesNoFormItemProps) {
  const { readOnly, fieldName, label, showYesFirst = false, ...formItemLayoutProps } = props;

  const transformInput = (value?: boolean | null): string | null => {
    if (value === undefined || value === null) {
      return "";
    }
    return !!value ? "true" : "false";
  };

  const transformOutput = (value: string): boolean | null => {
    if (value === "") {
      return null;
    }
    return value === "true";
  };

  return (
    <Controller
      name={fieldName}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <FormItemLayout
            controlled
            variant="unstyled"
            hint={props.hint}
            label={label}
            error={errors[fieldName]?.message?.toString()}
            formControlProps={{ disabled: readOnly }}
            fullWidth={false}
            {...formItemLayoutProps}
          >
            {(inputProps) => (
              <RadioGroup row onChange={(e) => onChange(transformOutput(e.target.value))} value={transformInput(value)}>
                {!showYesFirst && (
                  <FormControlLabel
                    value="false"
                    label="No"
                    checked={value === false}
                    control={<Radio {...inputProps} />}
                  />
                )}
                <FormControlLabel
                  value="true"
                  label="Yes"
                  checked={value === true}
                  control={<Radio {...inputProps} />}
                />
                {showYesFirst && (
                  <FormControlLabel
                    value="false"
                    label="No"
                    checked={value === false}
                    control={<Radio {...inputProps} />}
                  />
                )}
              </RadioGroup>
            )}
          </FormItemLayout>
        );
      }}
    />
  );
}
