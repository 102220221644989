import { Grid, Stack } from "@mui/material";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { Routes } from "app/routes";
import { useGetAssetDetailByIdRequest, useUpdateAssetRequest } from "assets/api";
import { AssetDetailView } from "assets/models/assetDetail";
import { checkAssetType } from "assets/utilities";
import { FeatureFlagName, useFeatureEnabled } from "common";
import _ from "lodash";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useMatch } from "react-router-dom";
import { AssetDetails } from "./sections/AssetDetails";
import { AssetRecordsAndAttachments } from "./sections/AssetRecordsAndAttachments";
import { AssetSpecifications } from "./sections/AssetSpecifications";
import { HealthHistory } from "./sections/HealthHistory";
import { VisualIntegrityCard } from "./sections/VisualIntegrityCard";
import { AssetComponents } from "./sections/components/AssetComponents";
import { VibrationHealth } from "./sections/VibrationHealth";

export const AssetPage = () => {
  const routeMatch = useMatch(Routes.Asset);

  const { data, loading } = useGetAssetDetailByIdRequest(Number(routeMatch?.params?.id));

  if (loading && !data) {
    return <EntityDetailsContainerSkeleton />;
  } else if (!data) {
    return null;
  }

  return <AssetPageWithForm assetDetail={data} />;
};

interface AssetPageWithFormProps {
  assetDetail: AssetDetailView;
}

const AssetPageWithForm: React.FunctionComponent<AssetPageWithFormProps> = (props) => {
  const { assetDetail } = props;
  const asset = assetDetail.model;
  const defaultValues = makeSafeForReactHookForm(asset);
  const methods = useForm({ defaultValues });
  const updateAsset = useUpdateAssetRequest();
  const saveChanges = (onValid?: () => void) => {
    methods.handleSubmit((values) => {
      updateAsset.call(values).then(() => {
        onValid?.();
      });
    })();
  };

  const useMachineryComponents = useFeatureEnabled(FeatureFlagName.UseMachineryComponents);
  const useMachinerySpecs = useFeatureEnabled(FeatureFlagName.UseMachinerySpecs);
  const { isValve, isMachinery } = checkAssetType(asset.assetType);

  return (
    <PermissionAwareFormProvider {...methods} require={(permissions) => permissions.userCanEditAsset(asset.assetType)}>
      <AutoSave onSubmit={saveChanges} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={9}>
          <Stack spacing={2}>
            <AssetDetails assetDetail={assetDetail} methods={methods} saveChanges={saveChanges} />
            {(!isMachinery || useMachinerySpecs) && <AssetSpecifications assetDetail={assetDetail} />}
            {isMachinery && useMachineryComponents && <AssetComponents assetDetail={assetDetail} />}
            <AssetRecordsAndAttachments asset={assetDetail} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <Stack spacing={2} direction={{ xs: "column", sm: "row", lg: "column" }}>
            <VisualIntegrityCard
              visualIntegrity={assetDetail.records.latestFieldInspection?.visualIntegrity}
              previousRecordId={assetDetail.records.latestFieldInspection?.record.id}
              previousRecordDescription={
                assetDetail.records.latestFieldInspection?.record
                  ? `Last ${_.lowerFirst(assetDetail.records.latestFieldInspection?.record.description)}`
                  : undefined
              }
              siteId={assetDetail.siteId}
            />
            {isValve && <HealthHistory assetId={assetDetail.id} title="Valve Health" showHealthBadge={true} />}
            {isMachinery && (
              <VibrationHealth
                health={assetDetail.records.latestVibrationAnalysis?.vibrationAnalysis}
                previousRecordId={assetDetail.records.latestVibrationAnalysis?.record.id}
                previousRecordDescription={
                  assetDetail.records.latestVibrationAnalysis?.record
                    ? `Last ${_.lowerFirst(assetDetail.records.latestVibrationAnalysis?.record.description)}`
                    : undefined
                }
                siteId={assetDetail.siteId}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </PermissionAwareFormProvider>
  );
};
