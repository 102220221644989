import { Grid } from "@mui/material";
import { useMobileView } from "app";
import { IrisColors } from "app/mui/theme";

interface KpiDividerProps {
  left: React.ReactNode;
  right: React.ReactNode;
  dividerSize?: "big" | "small";
}

function KpiDivider(props: KpiDividerProps) {
  const { left, right, dividerSize = "big" } = props;
  const isMobileView = useMobileView();
  const dividerColumns = dividerSize === "small" ? 6 : 10;

  return (
    <Grid container columnSpacing={2} alignItems="center">
      <Grid item width="max-content" justifyContent="flex-start" maxWidth={isMobileView ? undefined : "75%"}>
        {left}
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container alignItems="center">
          <Grid item xs />
          <Grid item xs={dividerColumns} position="relative">
            <div
              style={{
                width: "100%",
                borderTop: `1px solid ${IrisColors.gray300}`,
                position: "absolute",
                top: "50%",
                left: "0",
              }}
            />
          </Grid>
          <Grid item xs />
        </Grid>
      </Grid>
      <Grid item width="max-content" justifyContent="flex-end">
        {right}
      </Grid>
    </Grid>
  );
}

export default KpiDivider;
