import { TrafficLightValue } from "records";

export interface PneumaticPartialStrokePerformance {
  minMaxTravel: TrafficLightValue | null;
  rampRate: TrafficLightValue | null;
  travelDeviation: TrafficLightValue | null;
  breakoutTime: TrafficLightValue | null;
  testDuration: TrafficLightValue | null;
}
export interface PneumaticPartialStrokePerformanceValidity {
  valid: boolean;
  minMaxTravel: boolean;
  rampRate: boolean;
  travelDeviation: boolean;
  breakoutTime: boolean;
  testDuration: boolean;
}

export enum PneumaticPartialStrokePerformanceFieldLabel {
  MinMaxTravel = "Min/max travel",
  RampRate = "Ramp Rate",
  TravelDeviation = "Travel deviation",
  BreakoutTime = "Breakout time",
  TestDuration = "Test duration",
}

export enum PneumaticPartialStrokePerformanceTooltip {
  MinMaxTravel = "Assess the ability to meet the minimum and maximum travel from fail-safe position, as outlined in partial stroke settings.",
  RampRate = "Assess the velocity by which the valve moves from fail-safe position to the intended travel target, by considering the assembly size and specifications.",
  TravelDeviation = "Travel deviation - Assess the differential between travel feedback and set point throughout the partial stroke test.",
  BreakoutTime = "Assess the time required to initiate valve movement from it’s fail-safe position, considering the loading or exhausting of actuator.",
  TestDuration = "Assess the timing duration of the partial stroke test as it pertains to a safety demand signal, and the potential impact to operations.",
}
