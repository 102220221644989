import { Box, Grid, Stack, Typography } from "@mui/material";
import { ControlledTextFieldFormItem } from "app/mui/forms/Input/ControlledTextFieldFormItem";
import CurrencyFormItem from "app/mui/forms/Input/CurrencyFormItem";
import { NumberFormItem } from "app/mui/forms/Input/NumberFormItem";
import TextAreaFormItem from "app/mui/forms/Input/TextAreaFormItem";
import { SwitchFormItem } from "app/mui/forms/Toggle/SwitchFormItem";
import { formatCurrency } from "common";
import { KpiHelpTooltip } from "kpis/formFields/KpiHelpTooltip";
import {
  KpiCommodityUnitsForProduction,
  KpiOneTimeDowntimeAvoidance,
  KpiOneTimeDowntimeAvoidanceViewModel,
  KpiValueModel,
} from "kpis/models";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { EditKpiFormProps } from "../EditKpiFormProps";
import { KpiGridStyles } from "../KpiGridStyles";
import { KpiCommentsFormItem } from "../formItems/KpiCommentsFormItem";
import { NumberTimeUnitFormItem } from "../formItems/NumberTimeUnitFormItem";
import { PotentialDelayTypeFormItem } from "../formItems/PotentialDelayTypeFormItem";
import { ProbabilityOfDowntimeFormItem } from "../formItems/ProbabilityOfDowntimeFormItem";
import ProductionRateFormItem from "../formItems/ProductionRateFormItem";
import KpiDivider from "../sections/KpiDivider";

interface KpiOneTimeDowntimeAvoidanceProps extends EditKpiFormProps {}

function KpiOneTimeDowntimeAvoidanceDetails(props: KpiOneTimeDowntimeAvoidanceProps) {
  const { detail } = props;

  const isKpiTemplate = props.record === undefined;
  const hasTemplateComments =
    isKpiTemplate || (!!(props.model as KpiValueModel).kpiTemplateId && !!props.model.templateComments);

  const { gridRowProps, gridContainerProps, secondaryDividerTitleProps } = KpiGridStyles;
  const { watch } = useFormContext();

  const inputProps = useMemo(() => ({ readOnly: props.readOnly }), [props.readOnly]);

  const kpi = useMemo(
    () => new KpiOneTimeDowntimeAvoidanceViewModel(props.detail.model as KpiOneTimeDowntimeAvoidance, watch),
    [props.detail.model, watch]
  );

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid container {...gridContainerProps} rowSpacing={0}>
          <Grid item xs={12}>
            <Box width="min-content">
              <SwitchFormItem
                label="Simplified&nbsp;Version"
                fieldName="isSimplifiedVersion"
                {...inputProps}
                readOnly={kpi.isSimplifiedVersionLockedValue || inputProps.readOnly}
                tip={
                  <KpiHelpTooltip
                    type={kpi.isSimplifiedVersionLockedValue ? "simplifiedViewLocked" : "simplifiedView"}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} {...gridRowProps}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Potential Downtime Cost</Typography>
              <Typography {...secondaryDividerTitleProps}>{formatCurrency(detail.calculatedSavings, true)}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container {...gridContainerProps}>
          {kpi.isSimplifiedVersionValue && (
            <Grid item xs={12}>
              <Grid container paddingLeft={10}>
                <Grid container {...gridRowProps}>
                  <Grid item xs={5} columnGap={2}>
                    <PotentialDelayTypeFormItem
                      fieldName="potentialDelayType"
                      tip={<KpiHelpTooltip type="potentialDelayType" />}
                      {...inputProps}
                    />
                  </Grid>
                  <Grid item xs={true}>
                    <ControlledTextFieldFormItem
                      alwaysShowInput
                      fieldName="location"
                      label="Unit/facility/plant"
                      textFieldProps={{ placeholder: "Name" }}
                      tip={<KpiHelpTooltip type="location" />}
                      {...inputProps}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} {...gridRowProps}>
                  <KpiDivider
                    left={<Typography {...secondaryDividerTitleProps}>Potential cost</Typography>}
                    right={
                      <Typography {...secondaryDividerTitleProps}>
                        <CurrencyFormItem
                          fieldName="simplifiedPotentialCost"
                          label={undefined}
                          numberFormatInputProps={{ placeholder: "0" }}
                          {...inputProps}
                        />
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={4} {...gridRowProps}>
                  <ProbabilityOfDowntimeFormItem
                    fieldName="probabilityOfDowntime"
                    tip={<KpiHelpTooltip type="probabilityOfDowntime" />}
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={12} {...gridRowProps}>
                  <KpiDivider
                    left={<Typography {...secondaryDividerTitleProps}>Actual cost</Typography>}
                    right={
                      <Typography {...secondaryDividerTitleProps}>
                        <CurrencyFormItem
                          fieldName="simplifiedActualCost"
                          label={undefined}
                          numberFormatInputProps={{ placeholder: "0" }}
                          {...inputProps}
                        />
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!kpi.isSimplifiedVersionValue && (
            <>
              <Grid item xs={12} xl={2} {...gridRowProps}>
                <NumberFormItem
                  label="Price per 1 unit"
                  fieldName="commodityPricePerUnit"
                  numberFormatInputProps={{ placeholder: "0" }}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} {...gridRowProps}>
                <ProductionRateFormItem
                  label="Production rate quantity/unit/product/rate"
                  quantityFieldName="commodityProductionRate"
                  unitFieldName="commodityUnit"
                  units={KpiCommodityUnitsForProduction}
                  productFieldName="commodityType"
                  rateFieldName="commodityProductionRateTimeUnit"
                  productionRateProps={{ mode: "Per" }}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} lg={6} {...gridRowProps}>
                <NumberTimeUnitFormItem
                  label="Potential downtime avoided"
                  valueFieldName="potentialDowntime"
                  timeUnitFieldName="potentialDowntimeTimeUnit"
                  timeUnitProps={{ mode: "Plural" }}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={0} lg={2} />
              <Grid item xs={12} lg={4} {...gridRowProps}>
                <ProbabilityOfDowntimeFormItem
                  fieldName="probabilityOfDowntime"
                  tip={<KpiHelpTooltip type="probabilityOfDowntime" />}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={5} columnGap={2} {...gridRowProps}>
                <PotentialDelayTypeFormItem
                  fieldName="potentialDelayType"
                  tip={<KpiHelpTooltip type="potentialDelayType" />}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={true} {...gridRowProps}>
                <ControlledTextFieldFormItem
                  alwaysShowInput
                  fieldName="location"
                  label="Unit/facility/plant"
                  textFieldProps={{ placeholder: "Name" }}
                  tip={<KpiHelpTooltip type="location" />}
                  {...inputProps}
                />
              </Grid>
              <Grid item xs={12} {...gridRowProps}>
                <TextAreaFormItem label="Actions taken" fieldName="actionsTaken" {...inputProps} />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} {...gridRowProps}>
          <Grid container rowSpacing={2}>
            {hasTemplateComments && (
              <Grid item xs={12}>
                <KpiCommentsFormItem
                  label="Comments"
                  fieldName="templateComments"
                  alwaysShowInput
                  readOnly={!isKpiTemplate}
                />
              </Grid>
            )}
            {!isKpiTemplate && (
              <Grid item xs={12}>
                <KpiCommentsFormItem label="Additional Comments" fieldName="comments" readOnly={!props.userCanEdit} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default KpiOneTimeDowntimeAvoidanceDetails;
