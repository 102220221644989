import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { useUpdateAssetMachineryComponentRequest } from "assets/api";
import { AssetMachineryComponent } from "assets/models/machineryComponent";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

interface RenameAssetComponentDialogProps extends BaseDialogProps {
  assetComponent: AssetMachineryComponent;
}

const validationSchema = yup.object({
  name: yup.string(),
});

export const RenameAssetComponentDialog = (props: RenameAssetComponentDialogProps) => {
  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const updateComponent = useUpdateAssetMachineryComponentRequest(props.assetComponent.assetId);

  const onSubmit = () => {
    methods.handleSubmit(async (values) => {
      if (values.name.trim() !== props.assetComponent.name.trim()) {
        methods.setValue("name", values.name.trim());
        await updateComponent.call({ id: props.assetComponent.id, name: values.name.trim() });
      }
      props.onClose();
    })();
  };

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Change component name
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <TextFieldFormItem
              {...methods.register("name")}
              label="Name"
              fieldName="name"
              textFieldProps={{ autoFocus: true, defaultValue: props.assetComponent.name }}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton variant="contained" color="secondary" onClick={onSubmit} loading={updateComponent.loading}>
            Change name
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
