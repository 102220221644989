import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  AddPackagedSolutionEquipmentSchema,
  AddPackagedSolutionEquipmentModel,
  OperatingSystemModel,
} from "@bluemarvel/iris-common/model";
import { useAddPackagedSolutionEquipmentRequest } from "packagedSolution/api";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { MultiAssetSelectFormItem } from "app/mui/forms/MultiAssetSelectFormItem";

interface AddEquipmentDialogProps extends BaseDialogProps {
  packagedSolutionId: number;
  operatingSystems: OperatingSystemModel[];
}

export const AddEquipmentDialog = (props: AddEquipmentDialogProps) => {
  const { packagedSolutionId, operatingSystems } = props;
  const methods = useForm<AddPackagedSolutionEquipmentModel>({
    defaultValues: {
      packagedSolutionId,
      assets: [],
      operatingSystemId: null,
    },
    resolver: zodResolver(AddPackagedSolutionEquipmentSchema),
  });
  const addPackagedSolutionEquipmentRequest = useAddPackagedSolutionEquipmentRequest();

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      addPackagedSolutionEquipmentRequest.call(values).then(() => {
        handleClose();
      });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Add Equipment
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <TextFieldFormItem label="Name" fieldName="name" />
            <MultiAssetSelectFormItem label="Asset(s)" fieldName="assets" selectedAssets={[]} />
            <AutocompleteFormItem
              autocompleteProps={{ disablePortal: false }}
              label="Operating System"
              fieldName="operatingSystemId"
              options={operatingSystems.map((os) => ({ id: os.id, label: os.name }))}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={onSubmit} variant="contained" color="secondary">
            Add
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
