import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { FollowUpDateFormItem } from "app/mui/forms/Date/FollowUpDateFormItem";
import { MultiAssetSelectFormItem } from "app/mui/forms/MultiAssetSelectFormItem";
import { useCreateRecordRequest, Record } from "records";
import SplitButton from "app/mui/SplitButton";
import { useSiteContext } from "app";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { PackagedSolutionEquipmentModel } from "@bluemarvel/iris-common/model";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface CreateEquipmentHealthDialogProps extends BaseDialogProps {
  equipment: PackagedSolutionEquipmentModel;
  onComplete: () => void;
}

const validationSchema = yup.object({
  assets: yup.array(),
  equipmentName: yup.string().required("required"),
  recordType: yup.string().required("required"),
  eventDate: yup.date().required("required"),
  followUp: yup.date().nullable(),
});

export const CreateEquipmentHealthDialog = (props: CreateEquipmentHealthDialogProps) => {
  const currentSiteWithNavigation = useSiteContext().currentSiteWithNavigation!;
  const navigate = useNavigate();

  const createRecordRequest = useCreateRecordRequest();

  const methods = useForm<any>({
    defaultValues: {
      assets: props.equipment.assets,
      equipmentName: props.equipment.name,
      recordType: "Equipment health",
      eventDate: new Date(),
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
  });

  const submit = (navigateToRecord?: (recordId: number) => any) => {
    if (!currentSiteWithNavigation) return;

    methods.handleSubmit(
      (values) => {
        const newRecord: Partial<Record> = {
          siteId: currentSiteWithNavigation.id,
          description: "Equipment health",
          status: "Identified",
          packagedSolutionEquipmentId: props.equipment.id,
          recordType: values.recordType,
          followUp: values.followUp ? moment(values.followUp).toDate() : null,
          assets: values.assets,
          eventDate: moment(values.eventDate).toDate(),
        };

        createRecordRequest
          .call({
            record: newRecord,
            resolution: null,
          })
          .then((newRecord) => {
            if (!!navigateToRecord) {
              navigateToRecord(newRecord.id);
            }
            props.onComplete();
            props.onClose();
          });
      },
      (errs) => console.log(errs)
    )();
  };

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Create record
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "450px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <TextFieldFormItem
              label="Equipment Name"
              fieldName="equipmentName"
              textFieldProps={{ InputProps: { disabled: true } }}
            />
            <MultiAssetSelectFormItem
              label="Asset(s)"
              fieldName="assets"
              selectedAssets={props.equipment.assets}
              disabled={true}
              placeholder={""}
            />
            <TextFieldFormItem
              label="Type"
              fieldName="recordType"
              textFieldProps={{ InputProps: { disabled: true } }}
            />
            <DateInputFormItem label="Occurrence date" fieldName="eventDate" />
            <FollowUpDateFormItem label="Follow up" fieldName="followUp" />
          </Stack>
        </FormProvider>
        {props.equipment.latestRecord && (
          <Typography p={"6px"}>
            Note: If a previous record exists, the as left value for equipment health, report card and open
            recommendations will be automatically transferred to the newly created record. Recommendations in previous
            record will be archived.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => props.onClose()}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained", disabled: createRecordRequest.loading }}
            primaryButton={
              <Button
                onClick={() => {
                  submit((id) => {
                    navigate(currentSiteWithNavigation.routeTo.Record({ id }));
                  });
                }}
              >
                Create And Open
              </Button>
            }
            additionalActions={[<MenuItem onClick={() => submit()}>Create</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
