import { Box, Skeleton } from "@mui/material";

export const FieldSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height="16px" width="35%" />
      <Skeleton variant="rounded" height="40px" width="100%" sx={{ mt: "8px" }} />
    </Box>
  );
};
