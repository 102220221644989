import { Chip, Theme } from "@mui/material";
import { ChipProps } from "@mui/material/Chip";
import { HealthCategory, TrafficLightValue } from "records";

interface Props {
  health: HealthCategory | null;
}

type ChipColor = Pick<ChipProps, "color">["color"];

export const getTrafficLightColorForHealth = (category: HealthCategory | null): TrafficLightValue | null => {
  switch (category) {
    case "Severe":
      return TrafficLightValue.Red;
    case "Moderate":
      return TrafficLightValue.Orange;
    case "Minor":
      return TrafficLightValue.Yellow;
    case "Good":
      return TrafficLightValue.Green;
    default:
      return null;
  }
};

export const getColorForHealth = (category: HealthCategory | null): ChipColor => {
  switch (category) {
    case "Severe":
      return "AssetHealth_MajorIssues";
    case "Moderate":
      return "AssetHealth_ModerateIssues";
    case "Minor":
      return "AssetHealth_MinorIssues";
    case "Good":
      return "AssetHealth_Good";
    default:
      return "AssetHealth_Unknown";
  }
};

export const getLabelForHealth = (category: HealthCategory | null): string | null => {
  return category || null;
};

export const getStaticColorForHealth = (category: HealthCategory | null, theme: Theme) => {
  const healthColor = getColorForHealth(category);
  const color = theme.palette[healthColor ?? "primary"].main;

  return color;
};

export const HealthBadge = (props: Props) => {
  const color = getColorForHealth(props.health ?? null);
  const label = getLabelForHealth(props.health ?? null);

  if (label == null) {
    return <></>;
  }

  return (
    <Chip variant="outlined" size="small" sx={{ borderRadius: 1, fontWeight: "bold" }} color={color} label={label} />
  );
};
