import UploadFileIcon from "@mui/icons-material/UploadFile";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { CurrentSiteWithNavigation, Routes, useMatchesRoutes, useUserContext } from "app";
import { useUiThemeContext } from "app/contexts/UiThemeContext";
import { ApplicationIcon } from "icons/ApplicationIcon";
import { AssetIcon } from "icons/AssetIcon";
import { EventIcon } from "icons/EventIcon";
import { KpiIcon } from "icons/KpiIcon";
import { SummaryIcon } from "icons/SummaryIcon";
import { TasksIcon } from "icons/TasksIcon";
import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../theme";
import { AssetsMenuButton } from "./AssetsMenuButton";
import { FeatureFlagName, useFeatureEnabled } from "common";

interface SiteSpecificMenuProps {
  expanded: boolean;
  currentSiteWithNavigation: CurrentSiteWithNavigation;
}

export const SiteSpecificMenu = (props: SiteSpecificMenuProps) => {
  const { expanded, currentSiteWithNavigation } = props;

  const useAssetTypePages = useFeatureEnabled(FeatureFlagName.UseAssetTypeListPages);

  const { userPermissions } = useUserContext();
  const useImportDataLayout = useFeatureEnabled(FeatureFlagName.UseImportDataLayout);

  const assetsMatch = useMatchesRoutes(Routes.Assets, Routes.Asset);
  const tasksMatch = useMatchesRoutes(Routes.Tasks, Routes.Records, Routes.Record, Routes.Recommendations, Routes.Kpis);
  const eventsMatch = useMatchesRoutes(Routes.RecordsEvents, Routes.RecordsEvent);
  const applicationsMatch = useMatchesRoutes(Routes.Applications, Routes.Application, Routes.PackagedSolutions, Routes.PackagedSolution);
  const summariesMatch = useMatchesRoutes(Routes.Summaries, Routes.Summary);
  const kpisMatch = useMatchesRoutes(Routes.KpiTemplates, Routes.KpiTemplate);
  const importCsvMatch = useMatchesRoutes(Routes.ImportCsv);
  const importDataMatch = useMatchesRoutes(Routes.ImportData, Routes.ImportCsv, Routes.ImportOffline, Routes.ImportOfflineCreate, Routes.ImportOfflineConfirm);

  if (!currentSiteWithNavigation) {
    return null;
  }

  return (
    <List sx={{ width: "100%" }}>
      {useAssetTypePages ? (
        <AssetsMenuButton
          expanded={expanded}
          currentSiteWithNavigation={currentSiteWithNavigation}
          active={!!assetsMatch}
        />
      ) : (
        <NavItem
          open={expanded}
          label="Assets"
          icon={AssetIcon}
          link={currentSiteWithNavigation.routeTo.Assets()}
          active={!!assetsMatch}
        />
      )}
      <NavItem
        open={expanded}
        label="Tasks"
        icon={TasksIcon}
        link={currentSiteWithNavigation.routeTo.Tasks()}
        active={!!tasksMatch}
      />
      <NavItem
        open={expanded}
        label="Events"
        icon={EventIcon}
        link={currentSiteWithNavigation.routeTo.RecordsEvents()}
        active={!!eventsMatch}
      />
      <NavItem
        open={expanded}
        label="Applications"
        icon={ApplicationIcon}
        link={currentSiteWithNavigation.routeTo.Applications()}
        active={!!applicationsMatch}
      />
      <NavItem
        open={expanded}
        label="Summaries"
        link={currentSiteWithNavigation.routeTo.Summaries()}
        icon={SummaryIcon}
        active={!!summariesMatch}
      />

      {userPermissions.userCanViewKpiTemplate && (
        <NavItem
          open={expanded}
          label="KPIs"
          icon={KpiIcon}
          link={currentSiteWithNavigation.routeTo.KpiTemplates()}
          active={!!kpisMatch}
        />
      )}
      {useImportDataLayout ?
        <NavItem
          open={expanded}
          label="Import Data"
          icon={() => (
            <UploadFileIcon
              className="icon"
              sx={{
                display: "block",
              }}
            />
          )}
          link={currentSiteWithNavigation.routeTo.ImportData()}
          active={!!importDataMatch}
        />
        :
        <NavItem
          open={expanded}
          label="Import CSV"
          icon={() => (
            <UploadFileIcon
              className="icon"
              sx={{
                display: "block",
              }}
            />
          )}
          link={currentSiteWithNavigation.routeTo.ImportCsv()}
          active={!!importCsvMatch}
        />
      }
    </List>
  );
};

const NavItem = (props: {
  label: string;
  active?: boolean;
  open?: boolean;
  icon: React.FunctionComponent;
  link: string;
}) => {
  const isDarkTheme = useUiThemeContext().navbar === "dark";
  const bgColor = isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper;
  const fillColor = isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;

  return (
    <Tooltip title={props.open ? "" : props.label} placement="right">
      <ListItem key={props.label} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            px: "12px",
            height: 48,
            justifyContent: "initial",
            color: props.active ? bgColor : fillColor,
            background: props.active ? fillColor : bgColor,
            "&:hover": {
              background: props.active ? theme.palette.background.paper : "",
            },
          }}
          to={props.link}
          component={Link}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              width: "24px",
              svg: {
                fill: props.active ? bgColor : fillColor,
              },
            }}
          >
            {props.icon({ width: "100%", height: "100%" })}
          </ListItemIcon>
          <ListItemText
            primary={props.label}
            sx={{ opacity: props.open ? 1 : 0, px: 2, transition: "opacity 0.3s", whiteSpace: "nowrap" }}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
