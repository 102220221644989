import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { useCreateSummaryRequest } from "summaries/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import SplitButton from "app/mui/SplitButton";
import { useNavigate } from "react-router-dom";
import { formatRoutePath, Routes, useSiteContext } from "app";
import { SummaryStatus, SummaryType } from "summaries";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { RecordDetailView, RecordDigest } from "records/models";

interface CreateRecordSummaryDialogProps extends BaseDialogProps {
  records: RecordDetailView[];
}

const validationSchema = yup.object({
  title: yup.string().nullable().required("required"),
  date: yup.date().nullable().required("required"),
});

export const CreateRecordSummaryDialog = (props: CreateRecordSummaryDialogProps) => {
  const navigate = useNavigate();
  const { currentSiteWithNavigation } = useSiteContext();

  const createSummaryRequest = useCreateSummaryRequest();

  const methods = useForm({ defaultValues: { title: "", date: new Date() }, resolver: yupResolver(validationSchema) });

  const onSubmit = (options?: { navigate?: boolean }) => {
    if (!currentSiteWithNavigation) return;

    methods.handleSubmit((values) => {
      createSummaryRequest
        .call({
          siteId: currentSiteWithNavigation.id,
          records: props.records as any as RecordDigest[],
          title: values.title,
          summaryDate: values.date,
          summaryType: SummaryType.General,
          status: SummaryStatus.Open,
        })
        .then((newSummary) => {
          if (options?.navigate) {
            navigate(formatRoutePath(Routes.Summary, { siteId: newSummary.siteId, id: newSummary.id }));
          } else {
            props.onClose();
            window.location.reload();
          }
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6">Create summary for {props.records.length} records</Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <TextFieldFormItem label="Title" fieldName="title" />
            <DateInputFormItem label="Summary date" fieldName="date" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained" }}
            primaryButton={<Button onClick={() => onSubmit({ navigate: true })}>Create And Open</Button>}
            additionalActions={[<MenuItem onClick={() => onSubmit()}>Create</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
