import * as React from "react";
import { Card, CardTitle, Input, Button, Collapse, Alert, Label } from "reactstrap";
import classNames from "classnames";
import { Record, RecordDetailView } from "records";
import _ from "lodash";
import { CheckmarkIcon } from "icons/CheckmarkIcon";
import { AddIcon } from "icons/AddIcon";
import { formatRoutePath, Routes, useDebounce } from "app";
import { Link } from "react-router-dom";
import { DeleteIcon } from "icons/DeleteIcon";
import { CollapseIcon } from "icons/CollapseIcon";
import moment from "moment";
import { dateFormat } from "./formats";
import { useEffect, useCallback, useState } from "react";
import { useRecordDetailsSearchRequest } from "search";

interface ViewProps {
   siteId: number;
   records: RecordDetailView[];
   preventCollapse?: boolean;
   renderTitle?: () => JSX.Element;
   renderRecords: (props: SelectedRecordsPropsFromParent) => JSX.Element;
}

interface EditProps extends ViewProps {
   userCanEdit: boolean;
   parentDescriptor: string;
   addRecord: (record: RecordDetailView) => void;
   removeRecord: (record: Record) => void;
   queryIncludeKpis?: boolean;
   queryValveDiagnosticsOnly?: boolean;
   inputPlaceholder?: string;
   hideHint?: boolean;
   /* Return null if record is eligible, otherwise a string for why ineligible */
   recordValidator: (record: Record) => string | null;
}

interface ValidatedTaggedRecord {
   record: RecordDetailView;
   invalid: string | null;
   tag: string;
}

const getAddRecordLabel = (record: Record) =>
   `${record.description}${` (${
      record.assets.length === 1 ? record.assets[0].tag : record.assets.length > 1 ? "multiple assets" : "site record"
   })`}`;

export const RecordSelector: React.FunctionComponent<ViewProps | EditProps> = (props) => {
   const editProps = !!(props as EditProps).recordValidator ? (props as EditProps) : null;
   const [recordSearchPhrase, setRecordSearchPhrase] = useState("");
   const [preexistingRecords] = useState(props.records);
   const [expanded, setExpanded] = useState(true);
   const [hasAttemptedSearch, setHasAttemptedSearch] = useState(false);

   const debouncedSearchPhrase = useDebounce(recordSearchPhrase, 700);

   const recordsSearchRequest = useRecordDetailsSearchRequest();

   const performSearch = useCallback(
      (query: string) => {
         recordsSearchRequest.call({
            query,
            siteId: editProps!.siteId,
            valveDiagnosticsOnly: editProps!.queryValveDiagnosticsOnly || false,
         });
      },
      [recordsSearchRequest]
   );

   useEffect(() => {
      if (debouncedSearchPhrase.length >= 3) {
         performSearch(debouncedSearchPhrase);
         setHasAttemptedSearch(true);
      } else {
         setHasAttemptedSearch(false);
         if (recordsSearchRequest.loading) recordsSearchRequest.cancel();
      }
   }, [debouncedSearchPhrase]);

   const matchingRecords = hasAttemptedSearch ? recordsSearchRequest.data?.results ?? [] : [];

   const newRecords = matchingRecords.filter(
      (a) => preexistingRecords.every((pa) => pa.id !== a.id) || props.records.every((aa) => aa.id !== a.id)
   );
   const recordsToShow = 12;
   const sortedRecords = _.take(
      _.orderBy(newRecords, (record) => record.model.eventDate, "desc"),
      recordsToShow
   );

   const validatedTaggedRecords: ValidatedTaggedRecord[] = props.records.map((record) => ({
      record,
      invalid: !!editProps ? editProps.recordValidator!(record.model) : null,
      tag:
         record.model.assets.length === 1
            ? record.model.assets[0].tag
            : record.model.assets.length > 1
            ? `Multiple: ${record.model.assets.map((asset) => asset.tag).join(", ")}`
            : "Site record",
   }));

   const invalidRecordLength = validatedTaggedRecords.filter((r) => !!r.invalid).length;
   const allowCollapse = !!!props.preventCollapse;

   return (
      <Card body className="record-selector">
         <CardTitle
            className={classNames({ "collapse-toggle": allowCollapse }, "d-flex", "align-items-center", {
               collapsed: !expanded,
            })}
            onClick={() => allowCollapse && setExpanded(!expanded)}
         >
            {allowCollapse && <CollapseIcon expanded={expanded} />}
            <div className="title">{!!props.renderTitle ? props.renderTitle() : "Records"}</div>
            <div className="ml-auto counter">
               <Label>Record count:</Label>
               {invalidRecordLength > 0 && (
                  <span style={{ textDecoration: "line-through", marginRight: "1em" }}>
                     &nbsp;{props.records.length}&nbsp;
                  </span>
               )}
               &nbsp;
               {validatedTaggedRecords.length - invalidRecordLength}
            </div>
         </CardTitle>
         <Collapse isOpen={expanded}>
            {!!editProps && editProps.userCanEdit && (
               <>
                  <Input
                     placeholder={editProps.inputPlaceholder || "Search by tag or description to add more records"}
                     className={classNames({
                        "no-results": props.records.length === 0 && !editProps.hideHint,
                     })}
                     value={recordSearchPhrase}
                     onChange={(e) => setRecordSearchPhrase(e.target.value)}
                  />

                  {!!matchingRecords && (
                     <div className="add-buttons">
                        {sortedRecords.map((record) => {
                           const recordAlreadyAdded = props.records.some((a) => a.id === record.id);
                           return (
                              <Button
                                 key={record.id}
                                 color="primary"
                                 size="sm"
                                 onClick={() => editProps.addRecord(record)}
                                 disabled={recordAlreadyAdded}
                              >
                                 {recordAlreadyAdded ? <CheckmarkIcon /> : <AddIcon />}
                                 {getAddRecordLabel(record.model)}
                              </Button>
                           );
                        })}
                        {hasAttemptedSearch && !recordsSearchRequest.loading && sortedRecords.length === 0 && (
                           <div className="text-info">No eligible records found.</div>
                        )}
                     </div>
                  )}
               </>
            )}
            {props.records.length === 0 && !!editProps && !editProps.hideHint && (
               <div className="text-info">Add some records to the {editProps.parentDescriptor}.</div>
            )}

            {props.renderRecords({
               siteId: props.siteId,
               records: validatedTaggedRecords,
               hasInvalidRecords: invalidRecordLength > 0,
               removeRecord: !!editProps ? editProps.removeRecord : null,
               userCanEdit: !!editProps ? editProps.userCanEdit : false,
            })}
         </Collapse>
      </Card>
   );
};

interface SelectedRecordsPropsFromParent {
   siteId: number;
   records: ValidatedTaggedRecord[];
   hasInvalidRecords: boolean;
   userCanEdit: boolean;
   removeRecord: null | ((record: Record) => void);
}

interface SelectedRecordsProps {
   /* Show only the ineligible records. */
   showOnlyIneligible?: boolean;
   userCanViewRecords: boolean;
   recordWarning?: (record: Record) => JSX.Element | null;
}

export const SelectedRecordAssetList: React.FunctionComponent<SelectedRecordsPropsFromParent & SelectedRecordsProps> = (
   props
) => {
   return (
      <div className="items valve-diagnostics">
         {_.orderBy(props.records, (r) => r.tag).map((vr) => {
            const { record, tag, invalid } = vr;
            const identifier = (
               <>
                  <div className="tag mr-3">
                     {tag} {record.model.description}
                  </div>
                  <div>{moment(record.model.eventDate!).format(dateFormat)}</div>
               </>
            );

            if (props.showOnlyIneligible && !!invalid) return null;

            return (
               <div className={classNames("item", { skip: !!invalid })} key={record.id} title={invalid || ""}>
                  <div className="item-identifier">
                     {props.userCanViewRecords ? (
                        <Link target="_blank" to={formatRoutePath(Routes.Record, record)}>
                           {identifier}
                        </Link>
                     ) : (
                        identifier
                     )}
                  </div>
                  {!!invalid &&
                     (props.recordWarning ? (
                        props.recordWarning(record.model)
                     ) : (
                        <div className="item-danger" title={invalid}>
                           {invalid}
                        </div>
                     ))}
                  {props.userCanEdit && !!props.removeRecord && (
                     <Button className="delete-button" onClick={() => props.removeRecord!(record.model)}>
                        <DeleteIcon />
                     </Button>
                  )}
               </div>
            );
         })}
      </div>
   );
};

export const SelectedRecordListGroupedByAsset: React.FunctionComponent<
   SelectedRecordsPropsFromParent & SelectedRecordsProps & { renderAssetAsLink?: boolean }
> = (props) => {
   const groupedRecords = _.toPairs(
      _.groupBy(
         _.sortBy(props.records, (record) => record.tag),
         (record) => record.tag
      )
   );

   return (
      <div className="items-by-category">
         {props.hasInvalidRecords && (
            <>
               <h5 className="mt-3">Ineligible records</h5>
               {!!props.showOnlyIneligible && (
                  <Alert color="info">These records are ineligible and will not be included.</Alert>
               )}
               {!props.showOnlyIneligible && (
                  <Alert color="info">Ineligible records marked below will not be included.</Alert>
               )}
            </>
         )}

         {groupedRecords.map((group) => {
            if (!!props.showOnlyIneligible && !_.some(group[1].filter((vr) => !!vr.invalid))) return null;

            return (
               <div className="item-group" key={group[0]}>
                  <div className="category">
                     {!!!props.renderAssetAsLink || group[1][0].record.model.assets.length === 0
                        ? group[0]
                        : group[1][0].record.model.assets.map((asset) => (
                             <Link
                                key={asset.id}
                                to={formatRoutePath(Routes.Asset, { siteId: props.siteId, id: asset.id })}
                                target="_blank"
                             >
                                {asset.tag}
                             </Link>
                          ))}
                  </div>
                  <div className="items">
                     {_(group[1])
                        .orderBy(["eventDate", "id"], "desc")
                        .value()
                        .map((vr) => {
                           const { record, invalid } = vr;

                           if (props.showOnlyIneligible && !invalid) return null;

                           return (
                              <div
                                 className={classNames("item", {
                                    skip: !!invalid,
                                 })}
                                 key={record.id}
                                 title={invalid || ""}
                              >
                                 <div className="item-identifier">
                                    {props.userCanViewRecords ? (
                                       <Link
                                          target="_blank"
                                          to={formatRoutePath(Routes.Record, { siteId: props.siteId, id: record.id })}
                                       >
                                          {record.model.description}
                                       </Link>
                                    ) : (
                                       record.model.description
                                    )}
                                 </div>
                                 {!!invalid &&
                                    (props.recordWarning ? (
                                       props.recordWarning(record.model)
                                    ) : (
                                       <div className="item-danger" title={invalid}>
                                          {invalid}
                                       </div>
                                    ))}
                                 {props.userCanEdit && !!props.removeRecord && (
                                    <Button className="delete-button" onClick={() => props.removeRecord!(record.model)}>
                                       <DeleteIcon />
                                    </Button>
                                 )}
                              </div>
                           );
                        })}
                  </div>
               </div>
            );
         })}
      </div>
   );
};
