import { ComponentTemplateDigest } from "machinery/models/componentTemplate";
import { ComponentTemplateAttribute } from "machinery/models/componentTemplateAttribute";

export interface ComponentTypeDigest {
  id: number;
  name: string;
  fullName: string;
  tabFieldName: string;
}

export interface AssetMachineryComponent {
  id: number;
  assetId: number;
  title: string;
  name: string;
  tabs: number | null;
  componentTemplate: ComponentTemplateDigest;
  componentType: ComponentTypeDigest;
  assetComponentAttributes: AssetComponentAttribute[];
}

export interface AssetMachineryComponentUpdate {
  id: number;
  name: string;
}

export interface AssetComponentAttribute {
  id: number;
  attributeComponentId: number;
  componentTemplateAttribute: ComponentTemplateAttribute;
  componentTemplateAttributeId: number;
  value: string;
}

export interface AssetComponentAttributeUpdate {
  id: number;
  value: string;
}

export class AssetComponentFilters {
  public templateId: number | null = null;
}

export interface AssetComponentDetailView {
  [key: string]: string | number;
}
