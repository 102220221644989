import { Box, SxProps, Theme } from "@mui/material";

export interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
   sx?: SxProps<Theme>;
}

export function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div hidden={value !== index} {...other}>
         <Box sx={props.sx}>{children}</Box>
      </div>
   );
}
