import { yupResolver } from "@hookform/resolvers/yup";
import { useSiteContext } from "app";
import { Loading } from "app/bs";
import AutoSave from "app/mui/forms/AutoSave";
import classNames from "classnames";
import { CheckBoxFormGroup } from "common/fields/FormGroupCheckBox";
import { CollapseIcon } from "icons/CollapseIcon";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Col, Collapse, Row } from "reactstrap";
import { useUpdateOutagePlanningScopeRequest } from "records/api";
import { Site } from "sites";
import {
  GetRecordOutagePlanningScopeDisplay,
  RecordOutagePlanningScope,
  RecordOutagePlanningScopeSchema,
} from "../models";
import { ConfirmAcceptScopeModal } from "./ConfirmAcceptScopeModal";
import { ConfirmRemoveScopeModal } from "./ConfirmRemoveScopeModal";
import { BudgetaryReplacementCostDetails } from "./forms/BudgetaryReplacementCostDetails";
import { OriginalScopeOfWorkDetails } from "./forms/OriginalScopeOfWorkDetails";
import { RecommendedScopeOfWorkDetails } from "./forms/RecommendedScopeOfWorkDetails";

interface PlanningCardProps {
  recordId: number;
  defaultExpanded?: boolean;
  scope: RecordOutagePlanningScope;
  otherScopes?: RecordOutagePlanningScope[];
  recommendationNumber?: number;
  userCanEdit: boolean;
  canRemoveScope?: boolean;
  removeScope?: () => void;
  accepted?: boolean;
  selectable?: boolean;
  setAcceptedScopeId?: (scopeId?: number) => void;
  acceptedScopeExists: boolean;
  refetchView: () => void;
}

export const ScopeCard = (props: PlanningCardProps) => {
  const updateOutageScope = useUpdateOutagePlanningScopeRequest(props.recordId);

  const methods = useForm<RecordOutagePlanningScope>({
    defaultValues: props.scope,
    resolver: yupResolver(RecordOutagePlanningScopeSchema),
    mode: "onChange",
  });

  const { currentSite } = useSiteContext();

  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);

  const [expanded, setExpanded] = useState(!!props.defaultExpanded);

  const onAcceptedChanged = (accepted: boolean) => {
    // modal confirmation only required for accepting a new scope, not un-selecting.
    if (accepted) {
      setAcceptModalOpen(true);
    } else {
      props.setAcceptedScopeId?.(undefined);
    }
  };

  const canRemoveScope = !!props.removeScope;

  const scopeTitle = GetRecordOutagePlanningScopeDisplay(props.scope.scopeType, props.recommendationNumber);

  const handleSaveChanges = () => {
    methods.handleSubmit((values) => {
      const updatedScope = { ...props.scope, ...values };
      updateOutageScope.call(updatedScope);
    })();
  };

  if (!currentSite) return <Loading />;

  return (
    <>
      <FormProvider {...methods}>
        <AutoSave defaultValues={props.scope} onSubmit={handleSaveChanges} />
        <div className="planning-scope-card outage-detail-form">
          <ScopeHeader
            scope={props.scope}
            otherScopes={props.otherScopes}
            scopeTitle={scopeTitle}
            accepted={props.accepted}
            selectable={props.selectable}
            toggleExpanded={() => props.scope.id > 0 && setExpanded(!expanded)}
            expanded={expanded && props.scope.id > 0}
            onAcceptedChanged={onAcceptedChanged}
            acceptedScopeExists={props.acceptedScopeExists}
            site={currentSite!}
          />
          <Collapse isOpen={expanded && props.scope.id > 0}>
            {canRemoveScope && (
              <Row>
                <Col xs={12} style={{ textAlign: "right" }}>
                  <div className="add-secondary-scope-button-wrapper">
                    <button onClick={() => setRemoveModalOpen(true)}>Remove scope of work</button>
                  </div>
                </Col>
              </Row>
            )}
            {props.scope.scopeType === "BudgetaryReplacementCost" && (
              <BudgetaryReplacementCostDetails
                userCanEdit={props.userCanEdit}
                accepted={props.accepted}
                acceptedScopeExists={props.acceptedScopeExists}
              />
            )}
            {props.scope.scopeType === "Original" && (
              <OriginalScopeOfWorkDetails
                userCanEdit={props.userCanEdit}
                accepted={props.accepted}
                acceptedScopeExists={props.acceptedScopeExists}
              />
            )}
            {props.scope.scopeType === "Recommendation" && (
              <RecommendedScopeOfWorkDetails
                userCanEdit={props.userCanEdit}
                accepted={props.accepted}
                acceptedScopeExists={props.acceptedScopeExists}
              />
            )}
          </Collapse>
          <ConfirmAcceptScopeModal
            isOpen={acceptModalOpen}
            close={() => setAcceptModalOpen(false)}
            validationDetails={props.scope.validationDetails}
            justification={props.scope.justification}
            onAccept={() => props.setAcceptedScopeId?.(props.scope.id)}
            scope={props.scope}
          />
          <ConfirmRemoveScopeModal
            isOpen={removeModalOpen}
            close={() => setRemoveModalOpen(false)}
            scopeDisplayName={scopeTitle}
            onRemoveScope={() => props.removeScope?.()}
          />
        </div>
      </FormProvider>
    </>
  );
};

type SummaryLabelHint = [text: string, danger: boolean];

interface ScopeSummaryLabelProps {
  label: string;
  value: string | number | null;
  showAsMoney?: boolean;
  hints?: SummaryLabelHint[];
}

const ScopeSummaryLabel = (props: ScopeSummaryLabelProps) => {
  return (
    <div className="scope-summary-item">
      <span className="label">{props.label}</span>
      <span className="value">
        {props.value == null || props.value.toString().trim() === "" ? (
          "--"
        ) : props.showAsMoney ? (
          <NumberFormat displayType="text" value={props.value || 0} prefix="$" thousandSeparator decimalScale={0} />
        ) : (
          props.value
        )}
      </span>

      {props.hints?.map(([text, danger], i) => {
        return (
          <span key={i} className={classNames("subtext", { danger: danger })}>
            {text}
          </span>
        );
      })}
    </div>
  );
};

interface ScopeHeaderProps {
  expanded: boolean;
  toggleExpanded: () => void;
  scopeTitle: string;
  accepted?: boolean;
  selectable?: boolean;
  onAcceptedChanged: (accepted: boolean) => void;
  scope: RecordOutagePlanningScope;
  otherScopes?: RecordOutagePlanningScope[];
  acceptedScopeExists: boolean;
  site: Site;
}

const ScopeHeader = (props: ScopeHeaderProps) => {
  const scopeOfWorkLocation = props.scope.primaryLocation ? ` - ${props.scope.primaryLocation}` : "";
  const scopeOfWorkText = `${props.scope.primaryAction ?? ""} ${
    props.scope.primaryComponent ?? ""
  } ${scopeOfWorkLocation}`;

  const hasLeadTime = props.scope.leadTime != null && props.scope.leadTimeUnit != null;
  const leadTimePlural = props.scope.leadTime === 1 ? `` : `s`;
  const leadTimeText = hasLeadTime
    ? `${props.scope.leadTime ?? "0"} ${props.scope.leadTimeUnit ?? "Day"}${leadTimePlural}`
    : null;

  const threshold = Number(
    props.site.siteConfigValues?.find((i) => i.name === "QuoteAmountPercentageRedValue")?.value || 80
  );

  const quoteAmountHints = getQuoteAmountHints(props.scope, props.otherScopes, threshold);

  // don't show lead time hints if quote amount hints can't be shown yet..
  const canShowLeadTimeHints = quoteAmountHints.length > 0;
  const leadTimeHints = canShowLeadTimeHints ? getLeadTimeHints(props.scope, props.otherScopes) : [];

  const canAcceptScope = hasValidScopeOfWork(props.scope);

  return (
    <div
      className={classNames("planning-scope-card-heading", {
        accepted: props.accepted,
        selectable: props.selectable && !props.accepted,
      })}
    >
      <div className="scope-title">{props.scopeTitle}</div>
      <div className="scope-summary">
        <Row style={{ width: "100%" }}>
          <Col>
            <div className="scope-summary-columns">
              <div className="scope-summary-col scope-of-work">
                <ScopeSummaryLabel label="Scope of Work" value={scopeOfWorkText} />
              </div>
              <div className="scope-summary-col quote-amount">
                <ScopeSummaryLabel
                  label="Quote Amount"
                  value={props.scope.quoteAmount}
                  showAsMoney
                  hints={quoteAmountHints}
                />
              </div>
              <div className="scope-summary-col lead-time">
                <ScopeSummaryLabel label="Lead Time" value={leadTimeText} hints={leadTimeHints} />
              </div>
              <div className="scope-summary-col accept-button">
                {props.selectable && (
                  <div className="accept-scope-button-wrapper">
                    {(!props.acceptedScopeExists || props.accepted) && (
                      <CheckBoxFormGroup
                        checked={!!props.accepted}
                        onChange={() => props.onAcceptedChanged(!props.accepted)}
                        disabled={!canAcceptScope}
                        title={!canAcceptScope ? "scope of work required" : ""}
                      >
                        Accept
                      </CheckBoxFormGroup>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="details-button-wrapper">
          <button onClick={props.toggleExpanded}>
            Details
            <CollapseIcon expanded={props.expanded} />
          </button>
        </div>
      </div>
    </div>
  );
};

const getNameOfScope = (scope: RecordOutagePlanningScope): string => {
  if (
    scope.primaryAction === "Function test only" ||
    scope.primaryAction === "Visual inspection only" ||
    scope.primaryAction === "Removed from scope"
  ) {
    return scope.primaryAction;
  }

  if (scope.primaryAction && scope.primaryComponent) {
    return `${scope?.primaryAction} ${scope?.primaryComponent}`;
  }

  // fallback if we can't determine the name from the scope of work
  if (scope.scopeType === "BudgetaryReplacementCost") {
    return "Direct Replacement";
  }

  if (scope.scopeType === "Original") {
    return "Original Scope";
  }

  if (scope.scopeType === "Recommendation") {
    return "Recommended Scope";
  }

  return "";
};

const scopeIsReplaceAssembly = (scope: RecordOutagePlanningScope) => {
  return scope.primaryAction === "Replace" && scope.primaryComponent === "Assembly";
};

const getQuoteAmountHints = (
  currentScope: RecordOutagePlanningScope,
  otherScopes: RecordOutagePlanningScope[] | undefined,
  threshold: number
): SummaryLabelHint[] => {
  const budgetaryReplacementCostScope = otherScopes?.find((s) => s.scopeType === "BudgetaryReplacementCost");
  const originalScope = otherScopes?.find((s) => s.scopeType === "Original");

  const results: SummaryLabelHint[] = [];

  if (currentScope.scopeType === "BudgetaryReplacementCost" || !currentScope.quoteAmount) {
    // no hints for budgetaryReplacementCostScope, or if the current scope is missing quote amount
    return [];
  }

  if (scopeIsReplaceAssembly(currentScope) && currentScope.quoteAmount === budgetaryReplacementCostScope?.quoteAmount) {
    // no hints if this is a duplicate of the direct replacement cost
    return [];
  }

  if (canCompareQuoteAmountTo(budgetaryReplacementCostScope)) {
    results.push(getQuoteAmountComparison(currentScope, budgetaryReplacementCostScope, threshold));
  }

  if (currentScope.scopeType === "Recommendation" && canCompareQuoteAmountTo(originalScope)) {
    results.push(getQuoteAmountComparison(currentScope, originalScope, threshold));
  }

  return results;
};

const getQuoteAmountComparison = (
  scope: RecordOutagePlanningScope,
  otherScope: RecordOutagePlanningScope,
  threshold: number
): SummaryLabelHint => {
  const quoteAmountDifference = Math.round((scope.quoteAmount! / otherScope.quoteAmount!) * 100);
  const quoteAmountSubText = `${quoteAmountDifference}% of ${getNameOfScope(otherScope)}`;
  const quoteAmountSubTextDanger = quoteAmountDifference >= threshold;

  return [quoteAmountSubText, quoteAmountSubTextDanger];
};

const canCompareQuoteAmountTo = (
  scope?: RecordOutagePlanningScope
): scope is NonNullable<RecordOutagePlanningScope> => {
  return !!scope && scope.quoteAmount != null && scope.quoteAmount.toString() !== "";
};

const getLeadTimeHints = (
  currentScope: RecordOutagePlanningScope,
  otherScopes?: RecordOutagePlanningScope[]
): SummaryLabelHint[] => {
  const budgetaryReplacementCostScope = otherScopes?.find((s) => s.scopeType === "BudgetaryReplacementCost");
  const originalScope = otherScopes?.find((s) => s.scopeType === "Original");

  const results: SummaryLabelHint[] = [];

  if (currentScope.scopeType === "BudgetaryReplacementCost") {
    // no hints for budgetaryReplacementCostScope
    return [];
  }

  if (scopeIsReplaceAssembly(currentScope)) {
    // no hints for Replace Assembly
    return [];
  }

  if (canCompareLeadTimeTo(budgetaryReplacementCostScope)) {
    results.push(getLeadTimeComparison(currentScope, budgetaryReplacementCostScope!));
  }

  if (
    currentScope.scopeType === "Recommendation" &&
    canCompareLeadTimeTo(originalScope) &&
    !scopeIsReplaceAssembly(originalScope)
  ) {
    results.push(getLeadTimeComparison(currentScope, originalScope));
  }

  return results;
};

const getLeadTimeComparison = (
  scope: RecordOutagePlanningScope,
  otherScope: RecordOutagePlanningScope
): SummaryLabelHint => {
  // lead times between the 2 scopes need to be in the same unit for comparison (days vs weeks)
  // and if the time is less than 7 days it should days, if it is more than 7 days it should show weeks
  // including 0.25 increments .. i.e. 1.5 weeks

  if (!scope.leadTime || !scope.leadTimeUnit) {
    return ["", false];
  }

  const scopeLeadTimeInDays = scope.leadTimeUnit === "Week" ? scope.leadTime! * 7 : scope.leadTime!;
  const otherScopeLeadTimeInDays = otherScope.leadTimeUnit === "Week" ? otherScope.leadTime! * 7 : otherScope.leadTime!;

  const leadTimeDifference = Math.abs(scopeLeadTimeInDays - otherScopeLeadTimeInDays);
  const moreOrLess = scopeLeadTimeInDays < otherScopeLeadTimeInDays ? "less" : "longer";

  if (leadTimeDifference === 0) {
    return ["", false];
  }

  const unitToShow = leadTimeDifference >= 7 ? "week" : "day";

  // this expression will show weeks as increments of 0.25
  const inv = 1.0 / 0.25;
  const valueToShow = leadTimeDifference >= 7 ? Math.round((leadTimeDifference / 7) * inv) / inv : leadTimeDifference;

  const plural = valueToShow > 1 ? "s" : "";

  const text = `${valueToShow} ${unitToShow}${plural} ${moreOrLess}`;

  return [text, false];
};

const canCompareLeadTimeTo = (scope?: RecordOutagePlanningScope): scope is NonNullable<RecordOutagePlanningScope> => {
  return !!scope && !!scope.leadTime && !!scope.leadTimeUnit;
};

const hasValidScopeOfWork = (scope: RecordOutagePlanningScope): boolean => {
  if (scope.primaryAction === "Replace" && scope.primaryComponent === "Assembly") {
    return true;
  }

  if (
    scope.primaryAction === "Function test only" ||
    scope.primaryAction === "Visual inspection only" ||
    scope.primaryAction === "Removed from scope"
  ) {
    return true;
  }

  return !!scope.primaryAction && !!scope.primaryComponent && !!scope.primaryLocation;
};
