import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { IrisColors } from "app/mui/theme";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGetAssetHistoryByAssetIdRequest } from "records/api";

import _ from "lodash";
import { dateFormat } from "common";
import { AssetHistoryEvent, AssetHistoryEventType, GetAssetHistoryEventDescription } from "assets/models/historyEvent";

interface AssetHistoryProps {
  assetId: number;
}

export default function AssetHistory(props: AssetHistoryProps) {
  const [assetHistory, setAssetHistory] = useState<AssetHistoryEvent[]>([]);

  const { data, loading } = useGetAssetHistoryByAssetIdRequest(props.assetId);

  useEffect(() => {
    if (!loading) {
      setAssetHistory(_.sortBy(data, (h) => h.postedDate) ?? []);
    }
  }, [loading]);

  const createEvent = assetHistory.find((h) => h.type === AssetHistoryEventType.Created);
  const createdDate = createEvent ? moment(createEvent.postedDate).format(dateFormat) : "-";

  return (
    <Grid container spacing={4}>
      <Grid item md={6} sm={12} xs={12}>
        <Grid
          container
          sx={{
            backgroundColor: IrisColors.gray300,
            fontSize: "0.875rem",
            borderRadius: "4px",
            padding: "8px",
            maxWidth: "380px",
          }}
        >
          <Grid item xs={6} whiteSpace="nowrap" color={IrisColors.gray600} paddingX={1}>
            Asset created
          </Grid>
          <Grid item xs={6} whiteSpace="nowrap" color={IrisColors.gray600} paddingX={1}>
            Created by
          </Grid>
          <Grid item xs={6} whiteSpace="nowrap" paddingX={1} sx={{ fontWeight: 700 }}>
            {createdDate}
          </Grid>
          <Grid item xs={6} whiteSpace="nowrap" paddingX={1} sx={{ fontWeight: 700 }}>
            {createEvent?.postedBy ? createEvent?.postedBy?.fullName : "-"}
          </Grid>
        </Grid>

        <List dense>
          {assetHistory.length > 0 ? (
            assetHistory.map((h) => (
              <ListItem key={h.id}>
                <ListItemAvatar>
                  <Avatar src={h.postedBy?.picture} alt={h.postedBy?.fullName} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="span" color={IrisColors.graySpartan}>
                      {h.postedBy ? h.postedBy?.fullName : "-"}{" "}
                      <Typography variant="body2" component="span" fontStyle="italic">
                        {GetAssetHistoryEventDescription(h)}{" "}
                        <span style={{ whiteSpace: "nowrap" }}>{moment(h.postedDate).format(dateFormat)}</span>
                      </Typography>
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color={IrisColors.dark}>
                      {h.content}
                    </Typography>
                  }
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary={`No history`} />
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
}
