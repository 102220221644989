import { Theme } from "@mui/material";
import { formatRoutePath, Routes } from "app/routes";
import { HealthCategory, RecordDetailView, RecordType } from "records";
import { getStaticColorForHealth } from "../../HealthBadge";
import { Datum } from "@nivo/line";
import { sortBy } from "lodash";

export interface VibrationHealthChartDataModel extends Datum {
   x: Date;
   y: number | null;
   category: HealthCategory | null;
   link: string;
   color: string;
}

export const generateVibrationHealthHistoryDataFromRecords = (records: RecordDetailView[], theme: Theme): VibrationHealthChartDataModel[] => {
   const dataPoints = records
      .filter((r) => r.model.recordType === RecordType.VibrationAnalysis && r.model.health.overall !== null)
      .map((r) => ({
         y: r.model.health.overall,
         x: r.model.eventDate || r.model.closed || r.lastModified || r.created,
         category: r.model.health.overallCategory,
         link: formatRoutePath(Routes.Record, { siteId: r.siteId, id: r.id }),
         color: getStaticColorForHealth(r.model.health.overallCategory, theme),
      }))
      .filter((point) => point.x != null && point.y != null);

   return sortBy(dataPoints, (data) => data.x);
};
