import { Box, Button, Divider, Paper, Popover, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { IrisColors } from "app/mui/theme";
import { compactWholeNumberFormatter } from "common";
import { CustomerDashboardViewModel } from "dashboard/models";
import { FunctionComponent, ReactNode, useRef, useState } from "react";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

import { useHorizontalScrolling } from "dashboard/hooks/useHorizontalScrolling";

interface CustomerDashboardHeaderProps {
  data?: CustomerDashboardViewModel;
}

const SmallCard = styled(Paper)(({ theme }) =>
  theme.unstable_sx({
    p: 3,
    height: "90px",
    flexShrink: 0,
    flex: 1,
  })
);

export const CustomerDashboardHeader: FunctionComponent<CustomerDashboardHeaderProps> = ({ data }) => {
  const { sliderRef, containerRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight } = useHorizontalScrolling({
    buffer: 60,
  });

  if (!!!data) {
    return <Skeleton />;
  }

  return (
    <Box ref={containerRef} sx={{ overflowX: "hidden", paddingBottom: "3px", position: "relative" }}>
      <Stack direction="row" spacing={2} justifyContent="flex-start" ref={sliderRef}>
        <SmallCard sx={{ p: 2, minWidth: "260px" }}>
          <img
            src={data.customer.logoUri}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            alt="Customer logo"
          />
        </SmallCard>
        <SmallCard sx={{ minWidth: "300px" }}>
          <Stack direction="row" height="100%">
            <IconBox icon={<FactoryOutlinedIcon />} />
            <Metric label="My sites" value={data.mySitesCount} />
            <BlueDivider />
            <Metric label="Total sites" value={data.allSitesCount} />
          </Stack>
        </SmallCard>
        <SmallCard sx={{ minWidth: "320px" }}>
          <Stack direction="row" height="100%">
            <IconBox icon={<SpeedRoundedIcon />} />
            <Metric label="My assets" value={data.myAssetsCount} />
            <BlueDivider />
            <Metric label="Total assets" value={data.allAssetsCount} />
          </Stack>
        </SmallCard>
        <SmallCard sx={{ minWidth: "360px" }}>
          <Stack direction="row" height="100%">
            <IconBox icon={<AttachMoneyRoundedIcon />} />
            <Metric label="My savings YTD" value={data.mySavingsYtd.approved}>
              <Stack>
                <Typography variant="body2" sx={{ color: IrisColors.green, fontWeight: 600 }}>
                  Approved: ${compactWholeNumberFormatter.format(data.mySavingsYtd.approved)}
                </Typography>
                <Typography variant="body2" sx={{ color: IrisColors.orange, fontWeight: 600 }}>
                  Pending: ${compactWholeNumberFormatter.format(data.mySavingsYtd.pending)}
                </Typography>
              </Stack>
            </Metric>
            <BlueDivider />
            <Metric label="My savings all time" value={data.mySavingsTotal.approved}>
              <Stack>
                <Typography variant="body2" sx={{ color: IrisColors.green, fontWeight: 600 }}>
                  Approved: ${compactWholeNumberFormatter.format(data.mySavingsTotal.approved)}
                </Typography>
                <Typography variant="body2" sx={{ color: IrisColors.orange, fontWeight: 600 }}>
                  Pending: ${compactWholeNumberFormatter.format(data.mySavingsTotal.pending)}
                </Typography>
              </Stack>
            </Metric>
          </Stack>
        </SmallCard>
        <SmallCard sx={{ minWidth: "400px" }}>
          <Stack direction="row" height="100%">
            <IconBox icon={<AttachMoneyRoundedIcon />} />
            <Metric label="Customer savings YTD" value={data.allSavingsYtd.approved}>
              <Stack>
                <Typography variant="body2" sx={{ color: IrisColors.green, fontWeight: 600 }}>
                  Approved: ${compactWholeNumberFormatter.format(data.allSavingsYtd.approved)}
                </Typography>
                <Typography variant="body2" sx={{ color: IrisColors.orange, fontWeight: 600 }}>
                  Pending: ${compactWholeNumberFormatter.format(data.allSavingsYtd.pending)}
                </Typography>
              </Stack>
            </Metric>
            <BlueDivider />
            <Metric label="Customer savings all time" value={data.allSavingsTotal.approved}>
              <Stack>
                <Typography variant="body2" sx={{ color: IrisColors.green, fontWeight: 600 }}>
                  Approved: ${compactWholeNumberFormatter.format(data.allSavingsTotal.approved)}
                </Typography>
                <Typography variant="body2" sx={{ color: IrisColors.orange, fontWeight: 600 }}>
                  Pending: ${compactWholeNumberFormatter.format(data.allSavingsTotal.pending)}
                </Typography>
              </Stack>
            </Metric>
          </Stack>
        </SmallCard>
      </Stack>

      {canScrollLeft && <ScrollButton scrollLeft={scrollLeft} direction="left" />}
      {canScrollRight && <ScrollButton scrollRight={scrollRight} direction="right" />}
    </Box>
  );
};

const InfoBubble = ({ children }: { children: React.ReactNode }) => {
  const anchorEl = useRef();

  const [clicked, setClicked] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  const open = clicked || hovered;

  return (
    <>
      <Box
        ref={anchorEl}
        sx={{
          background: "white",
          color: IrisColors.blueSpartan,
          borderRadius: "50%",
          position: "absolute",
          top: 0,
          right: 0,
          lineHeight: 1,
          height: "24px",
          width: "24px",
          padding: "0",
          margin: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "-10px",
          marginRight: "-4px",
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => setClicked(true)}
      >
        <InfoRoundedIcon />
      </Box>
      <Popover
        sx={{ pointerEvents: clicked ? "auto" : "none" }}
        open={open}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => {
          setClicked(false);
          setHovered(false);
        }}
      >
        <Box
          sx={{
            p: 2,
            minWidth: "120px",
          }}
        >
          {children}
        </Box>
      </Popover>
    </>
  );
};

interface MetricProps {
  value: number;
  label: string;
  children?: React.ReactNode;
}

const Metric = (props: MetricProps) => {
  return (
    <Box flex="1">
      <Stack alignItems="center" spacing={0} position="relative">
        <Typography
          variant="montserrat"
          sx={{
            color: IrisColors.blueSpartan,
            fontWeight: "bold",
            lineHeight: 1,
            textTransform: "uppercase",
            fontSize: "26pt",
          }}
        >
          {compactWholeNumberFormatter.format(props.value)}
        </Typography>
        <Typography
          sx={{
            fontSize: "8pt",
            color: IrisColors.legacyGray600,
            lineHeight: 1,
            textTransform: "uppercase",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {props.label}
        </Typography>
        {props.children && <InfoBubble>{props.children}</InfoBubble>}
      </Stack>
    </Box>
  );
};

interface IconBoxProps {
  icon: ReactNode;
}

const IconBox = (props: IconBoxProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50px",
        height: "50px",
        background: IrisColors.gray300,
        color: IrisColors.legacyGray600,
        p: 1,
        borderRadius: 2,
        mr: 3,
        "& svg": {
          fontSize: "24pt",
        },
      }}
    >
      {props.icon}
    </Box>
  );
};

const BlueDivider = () => (
  <Divider orientation="vertical" sx={{ borderColor: IrisColors.gray500, ml: 0, mx: 1, height: "50px" }} />
);

interface ScrollButtonProps {
  scrollLeft?: () => void;
  scrollRight?: () => void;
  direction: "left" | "right";
}

const ScrollButton = (props: ScrollButtonProps) => {
  const handleClick = () => {
    if (props.direction === "left") {
      props.scrollLeft?.();
    } else {
      props.scrollRight?.();
    }
  };

  const positioning = props.direction === "left" ? { left: 0 } : { right: 0 };

  return (
    <Button
      onClick={handleClick}
      sx={{
        position: "absolute",
        top: 0,
        ...positioning,
        height: "100%",
        width: "60px",
        background:
          props.direction === "left"
            ? "linear-gradient(to left, #efedea00, #efedea, #efedea)"
            : "linear-gradient(to right, #efedea00, #efedea, #efedea)",
      }}
    >
      <Box
        sx={{
          width: "50%",
          height: "100%",
          ...positioning,
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.direction === "left" ? <ArrowBackIosNewRoundedIcon /> : <ArrowForwardIosRoundedIcon />}
      </Box>
    </Button>
  );
};
