import React from "react";
import { Chip } from "@mui/material";
import {
  AssetSearchResult,
  AttachmentSearchResult,
  HistoryEventSearchResult,
  RecommendationSearchResult,
  RecordSearchResult,
  SearchResult,
} from "search/models";
import { SearchResultComponent } from "./SearchResult";
import { AssetIcon } from "icons/AssetIcon";
import { AssetTypeBadge } from "app/mui/AssetTypeBadge";
import { AttachmentIcon } from "icons/AttachmentIcon";
import { RecordIcon } from "icons/RecordIcon";
import moment from "moment";
import { RecordStatusBadge } from "app/mui/RecordStatusBadge";
import { RecordTypeBadge } from "app/mui/RecordTypeBadge";

export const GenericSearchResultItem = (props: { searchResult: SearchResult; searchWords: string[]; isDarkTheme: boolean }) => {
  switch (props.searchResult.resultType) {
    case "Asset":
      const assetSearchResult = props.searchResult as AssetSearchResult;
      return (
        <SearchResultComponent
          icon={<AssetIcon />}
          title={assetSearchResult.tag ?? ""}
          description={assetSearchResult.serviceDescription ?? ""}
          siteName={props.searchResult.site.name}
          additionalDetails={""}
          badges={[<AssetTypeBadge assetType={assetSearchResult.assetType} isDarkTheme={props.isDarkTheme} />]}
          searchWords={props.searchWords}
        />
      );
    case "Attachment":
      const attachmentSearchResult = props.searchResult as AttachmentSearchResult;
      return (
        <SearchResultComponent
          icon={<AttachmentIcon />}
          title={attachmentSearchResult.caption || attachmentSearchResult.fileName || ""}
          description={attachmentSearchResult.recordDescription ?? ""}
          siteName={props.searchResult.site.name}
          additionalDetails={attachmentSearchResult.assetTag ?? ""}
          badges={[
            <Chip
              size={"small"}
              label={"Attachment"}
              variant="outlined"
              color={"warning"}
              sx={{ borderRadius: 1, borderColor: "warning", color: "warning" }}
            />,
          ]}
          searchWords={props.searchWords}
        />
      );
    case "HistoryEvent":
      const historyEventSearchResult = props.searchResult as HistoryEventSearchResult;
      return (
        <SearchResultComponent
          icon={<RecordIcon />}
          title={`${moment(historyEventSearchResult.postedDate).calendar()}`}
          description={historyEventSearchResult.content ?? ""}
          siteName={props.searchResult.site.name}
          additionalDetails={historyEventSearchResult.assetTag ?? ""}
          badges={[
            <Chip
              size={"small"}
              label={"Comment"}
              variant="outlined"
              color={"info"}
              sx={{ borderRadius: 1, borderColor: "info", color: "info" }}
            />,
          ]}
          searchWords={props.searchWords}
        />
      );
    case "Recommendation":
      const recommendationSearchResult = props.searchResult as RecommendationSearchResult;
      return (
        <SearchResultComponent
          icon={<RecordIcon />}
          title={recommendationSearchResult.title ?? ""}
          description={recommendationSearchResult.content ?? ""}
          siteName={props.searchResult.site.name}
          additionalDetails={recommendationSearchResult.assetTag ?? ""}
          badges={[
            <Chip
              size={"small"}
              label={"Recommendation"}
              variant="outlined"
              color={"success"}
              sx={{ borderRadius: 1, borderColor: "success", color: "success" }}
            />,
          ]}
          searchWords={props.searchWords}
        />
      );
    case "Record":
      const recordSearchResult = props.searchResult as RecordSearchResult;
      return (
        <SearchResultComponent
          icon={<RecordIcon />}
          title={recordSearchResult.recordDescription ?? ""}
          description={recordSearchResult.recordDetails ?? ""}
          siteName={props.searchResult.site.name}
          additionalDetails={recordSearchResult.assetTag ?? ""}
          badges={[
            <RecordTypeBadge type={recordSearchResult.recordType} isDarkTheme={props.isDarkTheme} />,
            <RecordStatusBadge status={recordSearchResult.recordStatus} isDarkTheme={props.isDarkTheme} />,
          ]}
          searchWords={props.searchWords}
        />
      );
    default:
      return null;
  }
};
