import { PneumaticFullStrokeCondition } from "./pneumaticFullStrokeCondition";
import { PneumaticFullStrokePerformance } from "./pneumaticFullStrokePerformance";
import { TrafficLightValue } from "./trafficLight";

type ReportCardCategory = "asFound" | "asLeft";
type ReportCardPneumaticFullStrokeConditionAttribute =
  `${ReportCardCategory}Condition_${keyof PneumaticFullStrokeCondition}`;
type ReportCardPneumaticFullStrokePerformanceAttribute =
  `${ReportCardCategory}Performance_${keyof PneumaticFullStrokePerformance}`;
type ReportCardPneumaticFullStrokeFields = {
  [k in
    | ReportCardPneumaticFullStrokeConditionAttribute
    | ReportCardPneumaticFullStrokePerformanceAttribute]: TrafficLightValue | null;
};

export type ReportCardPneumaticFullStroke = ReportCardPneumaticFullStrokeFields & {
  id: number;
  recordId: number;
};

export const createNewReportCardPneumaticFullStroke = (recordId: number): ReportCardPneumaticFullStroke => ({
  id: -1,
  recordId,
  asFoundCondition_valveFriction: null,
  asFoundCondition_ratedTravel: null,
  asFoundCondition_seatingProfile: null,
  asFoundCondition_dynamicErrorBand: null,
  asFoundCondition_driveSignal: null,
  asLeftCondition_valveFriction: null,
  asLeftCondition_ratedTravel: null,
  asLeftCondition_seatingProfile: null,
  asLeftCondition_dynamicErrorBand: null,
  asLeftCondition_driveSignal: null,

  asFoundPerformance_setPointTracking: null,
  asFoundPerformance_overshoot: null,
  asFoundPerformance_offset: null,
  asFoundPerformance_cycling: null,
  asFoundPerformance_speed: null,
  asLeftPerformance_setPointTracking: null,
  asLeftPerformance_overshoot: null,
  asLeftPerformance_offset: null,
  asLeftPerformance_cycling: null,
  asLeftPerformance_speed: null,
});
