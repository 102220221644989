import * as React from "react";
import { Summary } from "summaries";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { StandardModalFooter } from "app/bs/StandardModalFooter";
import { Checkbox } from "common";
import { Form } from "reactstrap";
import { useState } from "react";
import { useKpiSummaryReportUrlBuilder } from "common/reports";

interface Props {
   summary: Summary;
   close: () => void;
}

export const ConfigureKPISummaryReportModal: React.FunctionComponent<Props> = (props) => {
   const [includeKpiDetails, setIncludeKpiDetails] = useState(true);
   const [includeHighlightsEtc, setIncludeHighlightsEtc] = useState(true);
   const [includePhotos, setIncludePhotos] = useState(true);
   const [appendPdfAttachments, setAppendPdfAttachments] = useState(true);

   const reportUrl = useKpiSummaryReportUrlBuilder({
      id: props.summary.id,
      pdf: true,
      appendPdfAttachments,
      includeKpiDetails,
      includeHighlightsEtc,
      includePictures: includePhotos,
   });

   return (
      <Modal
         isOpen={true}
         size="lg"
         className="configure-general-summary-report-modal"
         container={"#bootstrap-modal-container"}
      >
         <ModalHeader>Report options</ModalHeader>
         <ModalBody>
            <Form>
               <Checkbox
                  value={includeKpiDetails}
                  onClick={() => {
                     setIncludeKpiDetails(!includeKpiDetails);
                  }}
               >
                  Include KPI details
               </Checkbox>
               <Checkbox
                  value={includeHighlightsEtc}
                  onClick={() => {
                     setIncludeHighlightsEtc(!includeHighlightsEtc);
                  }}
               >
                  Include highlights, challenges, general recommendations, path forward
               </Checkbox>
               <Checkbox
                  value={includePhotos}
                  onClick={() => {
                     setIncludePhotos(!includePhotos);
                  }}
               >
                  Include photos
               </Checkbox>
               <Checkbox
                  value={appendPdfAttachments}
                  onClick={() => {
                     setAppendPdfAttachments(!appendPdfAttachments);
                  }}
               >
                  Append PDF attachments
               </Checkbox>
            </Form>
         </ModalBody>
         <StandardModalFooter
            confirmDisabled={!reportUrl}
            confirm={() => {
               window.open(reportUrl!, "_blank");
               props.close();
            }}
            cancel={props.close}
         />
      </Modal>
   );
};