import { NotEnoughData } from "app/mui/common/placeholders/NotEnoughDataYetMessage";
import { YearlyAssetCountByHealthCategory } from "dashboard/models";
import _ from "lodash";
import { FunctionComponent, useMemo } from "react";
import { AssetHealthBarChart, AssetHealthBarChartData } from "./AssetHealthBarChart";

interface AssetHealthWidgetProps {
  data: YearlyAssetCountByHealthCategory[];
}

type AssetHealthBarChartBar = Omit<AssetHealthBarChartData, "year">;

const getData = (yearlyRecordsCounts: YearlyAssetCountByHealthCategory[]) => {
  const yearlyCountsDict: { [year: number]: AssetHealthBarChartBar } = {};

  _.orderBy(_.uniq(yearlyRecordsCounts.map((d) => d.year)), (x) => x).forEach((year) => {
    yearlyCountsDict[year] = {} as AssetHealthBarChartBar;
  });

  yearlyRecordsCounts.forEach(({ year, healthCategory, value }) => {
    yearlyCountsDict[year][healthCategory] = (yearlyCountsDict[year][healthCategory] ?? 0) + value;
  });

  return Object.keys(yearlyCountsDict).map((key) => {
    return {
      year: key,
      ...yearlyCountsDict[key],
    };
  });
};

export const AssetHealthWidget: FunctionComponent<AssetHealthWidgetProps> = (props) => {
  const data = useMemo(() => getData(props.data), [props.data]);

  if (data.length === 0) {
    return <NotEnoughData />;
  }

  return <AssetHealthBarChart data={data} />;
};
