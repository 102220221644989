import { Typography } from "@mui/material";
import { useNotifications } from "app";
import ConfirmationDialog from "common/mui/ConfirmationDialog";

import { useDeleteSummaryRequest } from "summaries/api";
import { SummaryDetail } from "summaries/models";

interface DeleteSummaryDialogProps {
  onClose: () => void;
  summaryDetail: SummaryDetail;
  onDeleteSummary: () => void;
}

export const DeleteSummaryDialog = (props: DeleteSummaryDialogProps) => {
  const { onClose, summaryDetail, onDeleteSummary } = props;

  const deleteSummaryRequest = useDeleteSummaryRequest();
  const notifications = useNotifications();

  const handleDelete = () => {
    deleteSummaryRequest.call(summaryDetail.id).then((success) => {
      if (success !== undefined) {
        notifications.success("Summary deleted.");
        onDeleteSummary();
      }
    });
  };

  return (
    <ConfirmationDialog
      title="Delete Summary"
      open={true}
      cancel={onClose}
      loading={deleteSummaryRequest.loading}
      confirmButtonText="Delete"
      confirm={handleDelete}
    >
      <Typography variant="body2">Are you sure you want to delete this summary?</Typography>
    </ConfirmationDialog>
  );
};
