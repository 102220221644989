import { Box, Paper, Stack, Typography } from "@mui/material";
import { Pie, PieTooltipProps } from "@nivo/pie";
import { CustomTooltip } from "app/mui/charts/common/CustomTooltip";
import customColors from "assets/customColors";
import { toAssetTypeDisplay } from "assets/models/assetType";
import { TotalAssetCountByAssetTypeFact } from "dashboard/models";
import { useRef } from "react";

interface TotalAssetCountsPieChartProps {
  assetCounts: TotalAssetCountByAssetTypeFact[];
}

const AssetColorMap = {
  ControlValve: customColors.smalt,
  ManualValve: customColors.forestGreen,
  ReliefValve: customColors.robinsEggBlue,
  IsolationValve: customColors.seance,
  Measurement: customColors.lynch,
  Regulator: customColors.amaranth,
  Machinery: customColors.spicyMix,
};

export const TotalAssetCountsPieChart = (props: TotalAssetCountsPieChartProps) => {
  const data = props.assetCounts.map((a) => ({
    id: a.assetType,
    label: toAssetTypeDisplay(a.assetType),
    value: a.value,
    color: AssetColorMap[a.assetType],
  }));

  const orderedColors: string[] = [];

  for (var i = 0; i < data.length; i++) {
    orderedColors.push(data[i].color);
  }

  return (
    <Box
      sx={{
        "& div": {
          display: "flex",
          justifyContent: { xs: "flex-start", lg: "center" },
          alignItems: { xs: "flex-start", lg: "center" },
        },
      }}
    >
      <PieChart data={data} colors={orderedColors} total={data.reduce((a, b) => a + b.value, 0)} />
    </Box>
  );
};

const theme = {
  legends: {
    text: {
      fontWeight: "bold",
      fontSize: "12px",
    },
  },
};

const PieChart = ({ data, colors, total }) => {
  const ref = useRef<HTMLElement>();
  return (
    <Box ref={ref}>
      <Pie
        width={365}
        height={250}
        data={data}
        theme={theme}
        margin={{ top: 40, right: 0, bottom: 50, left: -140 }}
        startAngle={-14}
        innerRadius={0.7}
        padAngle={0}
        cornerRadius={0}
        activeOuterRadiusOffset={2}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={colors}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: 0,
            itemWidth: 140,
            itemHeight: 12,
            itemsSpacing: 12,
            symbolSize: 20,
            itemDirection: "left-to-right",
          },
        ]}
        layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
        tooltip={(params) => (
          <CustomTooltip containerRef={ref}>
            <Tooltip {...params} total={total} />
          </CustomTooltip>
        )}
      />
    </Box>
  );
};

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  dataWithArc.forEach((datum) => {
    total += datum.value;
  });

  return (
    <g>
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="bottom"
        style={{
          fill: "#333",
          fontSize: "24px",
          fontWeight: 600,
        }}
      >
        {total}
      </text>
      <text
        x={centerX}
        y={centerY + 20}
        textAnchor="middle"
        dominantBaseline="top"
        style={{
          fill: "#777",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        Total
      </text>
    </g>
  );
};

interface TooltipProps extends PieTooltipProps<unknown> {
  total: number;
}

const Tooltip = (props: TooltipProps) => {
  return (
    <Paper
      elevation={10}
      sx={{
        width: 200,
        background: "white",
        p: 2,
        pointerEvents: "none",
        zIndex: 1000000,
      }}
    >
      <Stack justifyContent="start" alignItems="start">
        <Typography textAlign="left">Count: {props.datum.value}</Typography>
        <Typography textAlign="left">Percentage: {Math.round((props.datum.value / props.total) * 100)}%</Typography>
      </Stack>
    </Paper>
  );
};
