import { User } from "users";

export enum RecordHistoryEventType {
  Comment = "Comment on record",
  Created = "Record created",
  Closed = "Record closed",
  Reopened = "Record reopened",
  Resolution = "Record closed with resolution",
  RecommendationChange = "Recommendations changed",
  DescriptionChange = "Description changed",
  DetailsChange = "Details changed",
  StatusChange = "Status changed",
  Imported = "Record imported",
  LessonsLearned = "Record marked as a lesson learned",
  AddFlag = "Flag added",
  RemoveFlag = "Flag removed",
  UnMarkLessonsLearned = "Record unmarked as a lesson learned",
  KpiSignOff = "KPI signed off",
  KpiSignOffTypeRemoved = "KPI sign-off removed",
  RecordTypeChangeType = "Record type changed",
  BulkEdited = "Record changed in bulk edit",
}

export interface RecordHistoryEvent {
  id: number;
  type: RecordHistoryEventType;
  content: string | null;
  oldValue: string | null;
  newValue: string | null;
  postedDate: Date;
  postedBy: User;
  hiddenFromCustomer?: boolean;
  recordId: number;
}

export function GetRecordHistoryEventDescription(event: RecordHistoryEvent) {
  let eventDescription = "";
  switch (event.type) {
    case RecordHistoryEventType.Resolution:
    case RecordHistoryEventType.Closed:
      eventDescription = "closed the record";
      break;
    case RecordHistoryEventType.Reopened:
      eventDescription = "reopened the record";
      break;
    case RecordHistoryEventType.Created:
      eventDescription = "created the record";
      break;
    case RecordHistoryEventType.RecommendationChange:
      eventDescription = "updated the recommendations";
      break;
    case RecordHistoryEventType.DescriptionChange:
      eventDescription = "updated the description";
      break;
    case RecordHistoryEventType.DetailsChange:
      eventDescription = "updated the details";
      break;
    case RecordHistoryEventType.StatusChange:
      eventDescription = `changed the status from ${event.oldValue} to ${event.newValue}`;
      break;
    case RecordHistoryEventType.RecordTypeChangeType:
      eventDescription = `changed the record type from ${event.oldValue} to ${event.newValue}`;
      break;
    case RecordHistoryEventType.Imported:
      eventDescription = "Imported";
      break;
    case RecordHistoryEventType.LessonsLearned:
      eventDescription = "marked the record as a lesson learned";
      break;
    case RecordHistoryEventType.UnMarkLessonsLearned:
      eventDescription = "unmarked the record as a lesson learned";
      break;
    case RecordHistoryEventType.AddFlag:
      eventDescription = `added the flag ${event.newValue}`;
      break;
    case RecordHistoryEventType.RemoveFlag:
      eventDescription = `removed the flag ${event.oldValue}`;
      break;
    case RecordHistoryEventType.KpiSignOff:
      eventDescription = "added KPI sign-off";
      break;
    case RecordHistoryEventType.KpiSignOffTypeRemoved:
      eventDescription = "removed KPI sign-off";
      break;
    case RecordHistoryEventType.BulkEdited:
      eventDescription = "edited the record in bulk";
      break;
    case RecordHistoryEventType.Comment:
      return eventDescription;
  }

  return eventDescription;
}
