import React from "react";
import { useFormContext } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";

interface TextInputProps {
   field: any;
   label: string;
   userCanEdit: boolean;
}

export const TextInput = (props: TextInputProps) => {
   const {
      register,
      formState: { errors },
   } = useFormContext();

   const { ref, ...fieldProps } = register(props.field);

   const userCanEdit = { readOnly: props.userCanEdit === false };

   const errorMessage = errors[props.field]?.message as string | null | undefined;

   return (
      <FormGroup>
         <Label>{props.label}</Label>
         <Input type="text" {...fieldProps} innerRef={ref} {...userCanEdit} />
         <div className="form-error">{errorMessage}</div>
      </FormGroup>
   );
};
