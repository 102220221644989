import React from "react";
import { Button, ModalFooter } from "reactstrap";

interface StandardModalFooterProps {
   confirm: () => void;
   confirmLabel?: string;
   cancelLabel?: string;
   confirmDisabled?: boolean;
   cancel: () => void;
}

export class StandardModalFooter extends React.Component<StandardModalFooterProps> {
   public render() {
      return (
         <ModalFooter>
            <Button color="primary" onClick={() => this.props.confirm()} disabled={this.props.confirmDisabled}>
               {this.props.confirmLabel ? this.props.confirmLabel : "Confirm"}
            </Button>
            <Button color="secondary" onClick={() => this.props.cancel()}>
               {this.props.cancelLabel ? this.props.cancelLabel : "Cancel"}
            </Button>
         </ModalFooter>
      );
   }
}
