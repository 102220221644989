export type KpiPotentialDelayType = "Logistics" | "Technical" | "Communication or capacity";

export const KpiPotentialDelayTypes: KpiPotentialDelayType[] = ["Logistics", "Technical", "Communication or capacity"];

export const normalizeKpiPotentialDelayType = (value?: string | null) => {
  if (!!value) {
    const selected = KpiPotentialDelayTypes.filter((v) => v.toLowerCase() === value.toLowerCase());
    if (selected.length > 0) {
      return selected[0];
    }
  }
  return value || null;
};
