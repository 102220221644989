import { ClickAwayListener, Link, Paper, Popper, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Point, ResponsiveLine } from "@nivo/line";
import { dateFormat } from "common";
import moment from "moment";
import { getColorForHealth } from "assets/mui/health/HealthBadge";
import { useState } from "react";
import { virtualElement } from "../utils";
import { VibrationHealthChartDataModel } from "./models";

const chartTheme = {
  axis: {
    ticks: {
      text: {
        fontSize: "15px",
      },
    },
    domain: {
      line: {
        stroke: "#777777",
        strokeWidth: 2,
      },
    },
  },
};

interface VibrationHealthChartProps {
  data: { id: string; data: VibrationHealthChartDataModel[] }[];
  startDate: Date;
  endDate: Date;
}

export const VibrationHealthChart = ({ data, startDate, endDate /* see data tab */ }: VibrationHealthChartProps) => {
  const theme = useTheme();

  const [focusedTooltip, setFocusedTooltip] = useState<{ point: Point; virtualElement: any }>();

  const handlePointClicked = (point: Point, event: React.MouseEvent) => {
    setFocusedTooltip({ point, virtualElement: virtualElement(event.clientX, event.clientY) });
  };

  return (
    <>
      <ResponsiveLine
        theme={chartTheme}
        data={data}
        margin={{ top: 20, bottom: 40, left: 10, right: 0 }}
        enableGridX={false}
        enableGridY={false}
        curve="monotoneX"
        xScale={{
          type: "time",
          min: startDate,
          max: endDate,
        }}
        yScale={{
          type: "linear",
          min: 0,
          max: 100,
          stacked: false,
          reverse: false,
        }}
        axisLeft={null}
        axisBottom={{
          tickSize: 0,
          tickValues: [startDate, endDate], // only show first and last tick
          format: (value) => moment(value).year().toString(),
          tickPadding: 10,
          ticksPosition: "before",
          renderTick: ({ value, format, tickIndex, ...rest }) => {
            return (
              <text
                textAnchor={tickIndex === 0 ? "start" : "end"}
                style={{ transform: "translateY(20px)" }}
                x={rest.x}
                y={rest.y}
                fill="#777777"
              >
                {format?.(value)}
              </text>
            );
          },
        }}
        colors={() => "#000000"} // line color
        pointSymbol={(e) => {
          const healthColor = getColorForHealth(e?.datum?.category);
          const color = theme.palette[healthColor ?? "primary"].main;
          return (
            <circle cx="0" cy="0" r="5" stroke={color} strokeWidth="2" fill={color} style={{ cursor: "pointer" }} />
          );

        }}
        pointSize={10}
        pointBorderWidth={3}
        useMesh={true}
        enableCrosshair={false}
        onClick={handlePointClicked}
        tooltip={({ point }) => {
          return <>{!focusedTooltip && <Tooltip point={point} />} </>;
        }}
      />
      {focusedTooltip && (
        <ClickAwayListener
          onClickAway={() => {
            setFocusedTooltip(undefined);
          }}
        >
          <Popper
            open={Boolean(focusedTooltip)}
            anchorEl={focusedTooltip?.virtualElement}
            placement={"left"}
            sx={{ pb: 2 }}
          >
            <Tooltip point={focusedTooltip.point} />
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

interface TooltipProps {
  point: Point;
}

const Tooltip = (props: TooltipProps) => {
  const theme = useTheme();
  const data = props.point.data as unknown as VibrationHealthChartDataModel;
  const healthColor = getColorForHealth(data.category);
  const color = theme.palette[healthColor ?? "primary"].main;

  return (
    <Paper
      sx={{ p: 1, fontFamily: theme.typography.fontFamily }}
      style={{ position: "absolute", top: "0", left: "0", transform: "translate(-100%, -100%)", width: "max-content" }}
    >
      <Stack>
        <Typography fontWeight="medium">
          Record Date:{" "}
          <Link component={RouterLink} to={(props.point.data as any).link} color="secondary">
            {moment(props.point.data.x).format(dateFormat)}
          </Link>
        </Typography>
        <Typography fontWeight="medium">
          Vibration Health:{" "}
          <Typography component="span" fontWeight="bold" color={color}>
            {Math.round(Number(props.point.data.y))}%
          </Typography>
        </Typography>

      </Stack>
    </Paper>
  );
};
