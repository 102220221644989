import { KpiCommonDetail } from "../models";
import _ from "lodash";
import { Typography } from "@mui/material";
import { formatCurrency, numberWithCommas } from "common";

interface KpiSavingsSummaryProps {
  kpi: KpiCommonDetail;
}

export const KpiSavingsSummary = (props: KpiSavingsSummaryProps) => {
  const { kpi } = props;

  let calculatedSavingsString = kpi.calculatedSavings.toString();

  if (kpi.calculatedSavingsUnit?.startsWith("Dollars")) {
    const savingsUnitParts = kpi.calculatedSavingsUnit.split("Dollars").filter((x) => x.trim() !== "");
    const calculatedSavings = formatCurrency(kpi.calculatedSavings, true);

    if (savingsUnitParts.length > 0) {
      calculatedSavingsString = `${calculatedSavings} ${_.lowerFirst(savingsUnitParts[0].trimStart())}`;
    } else {
      calculatedSavingsString = calculatedSavings;
    }
  } else {
    calculatedSavingsString = `${numberWithCommas(kpi.calculatedSavings)} ${_.lowerFirst(
      kpi.calculatedSavingsUnit ?? ""
    )}`;
  }

  return (
    <span>
      <Typography component="span">Savings:&nbsp;</Typography>
      <Typography component="span" fontWeight={600}>
        {calculatedSavingsString}
      </Typography>
      {kpi.kpiType === "OngoingMaintenanceSavingsOrProductionImprovement" &&
        kpi.calculatedReturnOnInvestment !== "" &&
        kpi.calculatedReturnOnInvestment !== "Immediate" &&
        kpi.calculatedReturnOnInvestment !== null &&
        kpi.calculatedReturnOnInvestment !== undefined && (
          <Typography component="span">&nbsp;{`after ${kpi.calculatedReturnOnInvestment}`}</Typography>
        )}
    </span>
  );
};
