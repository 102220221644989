import * as React from "react";
import { Icon } from "icons/Icon";
import classNames from "classnames";

interface Props {
   expanded: boolean;
   className?: string;
}

export const CollapseIcon: React.FunctionComponent<Props> = (props) => (
   <Icon
      className={classNames("collapse-icon", props.className, {
         expanded: props.expanded,
         "collapsed": !props.expanded,
      })}
   >
      <path d="M 12 6.9296875 L 5.9296875 13 L 7.4296875 14.5 L 12 9.9296875 L 16.570312 14.5 L 18.070312 13 L 12 6.9296875 z" />
   </Icon>
);
