import { Paper, Stack } from "@mui/material";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";

export const RecordObservations = () => {
  return (
    <Paper>
      <ExpansionPanel title="OBSERVATIONS" defaultExpanded={false}>
        <Stack spacing={4} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
          <RichTextEditor fieldName="observations" label={undefined} />
        </Stack>
      </ExpansionPanel>
    </Paper>
  );
};
