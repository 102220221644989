import { BarDatum, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { IrisColors } from "app/mui/theme";
import { formatCurrency, getUniqueKeys } from "common";
import React from "react";

const theme = {
  axis: {
    ticks: {
      text: {
        fill: "#777",
      },
    },
    legend: {
      text: {
        fontWeight: 500,
        fontSize: "12pt",
        fill: "#777",
      },
    },
  },
  legends: {
    text: {
      fontWeight: 600,
    },
  },
};

const keysAndColors = [
  { key: "Pending", color: IrisColors.orange },
  { key: "Approved", color: IrisColors.green },
];

export const KpiSavingsBarChart = ({ data }) => {
  const uniqKeysFromDataSet = getUniqueKeys(data);

  const keys = keysAndColors.filter((kc) => uniqKeysFromDataSet.includes(kc.key)).map((kc) => kc.key);
  const colors = keysAndColors.filter((kc) => uniqKeysFromDataSet.includes(kc.key)).map((kc) => kc.color);

  return (
    <ResponsiveBar
      theme={theme}
      data={data}
      keys={keys}
      indexBy="year"
      margin={{ top: 50, right: 160, bottom: 80, left: 120 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      colors={colors}
      enableLabel={false}
      axisTop={null}
      axisRight={null}
      tooltip={(props) => <KpiSavingsPopup {...props} />}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "TIME",
        legendPosition: "middle",
        legendOffset: 42,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "TOTAL SAVINGS",
        legendPosition: "middle",
        legendOffset: -100,
        format: formatCurrency,
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 9,
          itemWidth: 110,
          itemHeight: 26,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 18,

          symbolShape: (params) => {
            let color = keysAndColors.find((kc) => kc.key === params.id)!.color;
            let strokeColor = keysAndColors.find((kc) => kc.key === params.id)!.color;

            return (
              <rect
                x={params.x}
                y={params.y}
                fill={color}
                opacity="1"
                strokeWidth="2"
                stroke={strokeColor}
                width={params.size}
                height={params.size}
                style={{ pointerEvents: "none" }}
              ></rect>
            );
          },
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

type KpiSavingsType = "Approved" | "Pending";

export interface KpiSavingsBarChartData extends Record<KpiSavingsType, number> {
  year: number;
}

const KpiSavingsPopup = (props: BarTooltipProps<BarDatum>) => {
  const { data } = props;
  const approved = (data["Approved"] ?? 0) as number;
  const pending = (data["Pending"] ?? 0) as number;
  const roundApproved = Math.round(approved * 100) / 100;
  const roundPending = Math.round(pending * 100) / 100;
  const roundTotal = roundApproved + roundPending;

  return (
    <div
      style={{
        padding: 12,
        background: "white",
        border: "1px solid black",
        display: "grid",
        gridTemplateColumns: "100px 1fr",
        gridTemplateAreas: `
        "at av"
        "pt pv"
        "br br"
        "tt tv"`,
      }}
    >
      <div style={{ gridArea: "at", color: IrisColors.green }}>
        <span style={{ fontWeight: "bold", width: "300px" }}>Approved:&nbsp;</span>
      </div>
      <div style={{ gridArea: "av", color: IrisColors.green }}>
        <span>{formatCurrency(roundApproved)}</span>
      </div>
      <div style={{ gridArea: "pt", color: IrisColors.orange }}>
        <span style={{ fontWeight: "bold", width: "300px" }}>Pending:&nbsp;</span>
      </div>
      <div style={{ gridArea: "pv", color: IrisColors.orange }}>
        <span>{formatCurrency(roundPending)}</span>
      </div>
      <div style={{ gridArea: "br" }}>
        <hr />
      </div>
      <div style={{ gridArea: "tt" }}>
        <span style={{ fontWeight: "bold", width: "300px" }}>Total:&nbsp;</span>
      </div>
      <div style={{ gridArea: "tv" }}>
        <span>{formatCurrency(roundTotal)}</span>
      </div>
    </div>
  );
};
