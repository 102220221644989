import { Typography } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import CurrencyFormItem from "app/mui/forms/Input/CurrencyFormItem";
import { formatCurrency, numberWithCommas } from "common";

interface PotentialEquipmentCostFormItemProps extends BaseFormItemProps {
  multiply: boolean;
  multiplier: number;
  actualEquipmentCost: number;
}

export function PotentialEquipmentCostFormItem(props: PotentialEquipmentCostFormItemProps) {
  const { fieldName, multiply, multiplier, actualEquipmentCost, ...formItemProps } = props;

  if (multiply) {
    return (
      <Typography>
        {numberWithCommas(multiplier || 0)} &nbsp;&times;&nbsp; {formatCurrency(actualEquipmentCost, true)}
        &nbsp;{"="}&nbsp;
        {formatCurrency(actualEquipmentCost * multiplier)}/hr;
      </Typography>
    );
  }

  return <CurrencyFormItem fieldName={fieldName} {...formItemProps} />;
}
