import { LoadingButton } from "@mui/lab";
import { useBackgroundReportContext } from "app/contexts/BackgroundReportContext";
import { Checkbox } from "common";
import * as React from "react";
import { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { Summary } from "summaries";

interface Props {
  summary: Summary;
  close: () => void;
}

export const ConfigureFieldInspectionSummaryReportModal: React.FunctionComponent<Props> = (props) => {
  const [includeBadActors, setIncludeBadActors] = useState(true);
  const [includeInspectionResults, setIncludeInspectionResults] = useState(true);
  const [includeNotes, setIncludeNotes] = useState(true);
  const [includePhotos, setIncludePhotos] = useState(true);
  const [includeVisualIntegrityLegend, setIncludeVisualIntegrityLegend] = useState(true);
  const [appendPdfAttachments, setAppendPdfAttachments] = useState(true);

  const { generateFieldReport: generateFieldInspectionSummaryReportNode } = useBackgroundReportContext();

  const requestBody = {
    id: props.summary.id,
    pdf: true,
    includeBadActors,
    includeInspectionResults,
    includeNotes,
    includePhotos,
    includeVisualIntegrityLegend,
    appendPdfAttachments,
  };

  return (
    <Modal
      isOpen={true}
      size="lg"
      className="configure-general-summary-report-modal"
      container={"#bootstrap-modal-container"}
    >
      <ModalHeader>Report options</ModalHeader>
      <ModalBody>
        <Form>
          <Checkbox
            value={includeBadActors}
            onClick={() => {
              setIncludeBadActors(!includeBadActors);
            }}
          >
            Include Bad actors
          </Checkbox>
          <Checkbox
            value={includeNotes}
            onClick={() => {
              setIncludeNotes(!includeNotes);
            }}
          >
            Include Notes
          </Checkbox>
          <Checkbox
            value={includeInspectionResults}
            onClick={() => {
              setIncludeInspectionResults(!includeInspectionResults);
            }}
          >
            Include Inspection Results
          </Checkbox>
          <Checkbox
            value={includePhotos}
            onClick={() => {
              setIncludePhotos(!includePhotos);
            }}
          >
            Include photos
          </Checkbox>
          <Checkbox
            value={includeVisualIntegrityLegend}
            onClick={() => {
              setIncludeVisualIntegrityLegend(!includeVisualIntegrityLegend);
            }}
          >
            Include Visual Integrity Legend
          </Checkbox>
          <Checkbox
            value={appendPdfAttachments}
            onClick={() => {
              setAppendPdfAttachments(!appendPdfAttachments);
            }}
          >
            Append PDF attachments
          </Checkbox>
        </Form>
      </ModalBody>
      <ModalFooter>
        <LoadingButton
          loading={false}
          color="secondary"
          variant="contained"
          onClick={() => {
            generateFieldInspectionSummaryReportNode?.call(requestBody);
            props.close();
          }}
        >
          Confirm
        </LoadingButton>
        <Button color="secondary" onClick={() => props.close()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
