import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LinkOffIcon from "@mui/icons-material/LinkOff";

import { Box, Grid, Stack, Typography } from "@mui/material";
import { Routes, formatRoutePath } from "app";
import { ActionDispatcherApi } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { InternalLink } from "common/mui/InternalLink";
import { KpiValueDetail, toKpiTypeDisplayName } from "kpis";
import { KpiTypeIcon } from "kpis/KpiTypeIcon";
import { KpiSavingsSummary } from "kpis/mui/KpiSavingsSummary";
import { KpiSignOff } from "kpis/mui/KpiSignOff";
import { RecordDetailView } from "records/models";

interface RecordKpiHeaderProps {
  recordDetail: RecordDetailView;
  kpiValue: KpiValueDetail;
  actionDispatcher: ActionDispatcherApi;
  isLegacy?: boolean;
}

function RecordKpiHeader(props: RecordKpiHeaderProps) {
  const { recordDetail, actionDispatcher, kpiValue, isLegacy = false } = props;

  return (
    <Stack direction="row" sx={{ pl: 1, width: "100%" }} justifyContent="space-between" alignItems="flex-start">
      <Grid container spacing={4} flexGrow={1} justifyContent="flex-start">
        <Grid item xs={12} md={8}>
          <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
            <KpiTypeIcon kpiType={kpiValue.kpiType} />
            <Box pl={1}>
              <Typography fontWeight="600" component="div">
                {toKpiTypeDisplayName(kpiValue.kpiType)}
                {!!kpiValue.model.kpiTemplateId && (
                  <>
                    <br />
                    <Typography fontWeight="600">
                      <InternalLink
                        to={formatRoutePath(Routes.KpiTemplate, {
                          siteId: recordDetail.siteId,
                          id: kpiValue.model.kpiTemplateId,
                        })}
                        openInNewTab
                      >
                        {kpiValue.model.name}
                      </InternalLink>
                    </Typography>
                  </>
                )}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="flex-start" alignItems="center">
          <Stack direction="row" spacing={1}>
            <KpiSignOff isSignedOff={kpiValue.model.signedOff} />
            <KpiSavingsSummary kpi={kpiValue} />
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ width: "32px" }}>
        {!isLegacy && (
          <ActionsDropdownMenu
            rowActionMenu
            actions={[
              {
                icon: <DeleteRoundedIcon />,
                label: "Delete KPI",
                action: () => {
                  actionDispatcher.dispatch("deleteKpi", kpiValue);
                },
              },
              {
                icon: <LinkOffIcon />,
                label: "Unlink from template",
                action: () => {
                  actionDispatcher.dispatch("unlinkKpi", kpiValue);
                },
                hidden: !kpiValue.model.kpiTemplateId,
              },
            ]}
          />
        )}
      </Box>
    </Stack>
  );
}

export default RecordKpiHeader;
