import { Box, Typography } from "@mui/material";
import { Link } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
interface NewTabLinkProps {
  label: string;
  url: string;
  disabled?: boolean;
}

export const NewTabLink = (props: NewTabLinkProps) => {
  return props.disabled ? (
    <Typography sx={{ color: (theme) => theme.palette.grey[600] }}>{props.label}</Typography>
  ) : (
    <Link href={props.url} color="secondary" target="_blank">
      <Box component="span" sx={{ display: "inline-flex", alignItems: "center" }}>
        <span>{props.label}</span> <OpenInNewIcon sx={{ pl: "2px" }} />
      </Box>
    </Link>
  );
};
