export enum DiagnosticType {
  PneumaticFullStroke = "Pneumatic full stroke",
  PneumaticPartialStroke = "Pneumatic partial stroke",
  MotorOperated = "Motor operated",
}

export const DiagnosticTypes: DiagnosticType[] = [
  DiagnosticType.PneumaticFullStroke,
  DiagnosticType.PneumaticPartialStroke,
  DiagnosticType.MotorOperated,
];
