import React, { useCallback, useEffect } from "react";
import { Box, Popover, Stack, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import { gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import _ from "lodash";
import { usePopover } from "app/mui/common/usePopover";
import colors from "assets/customColors";
import { wholeNumberFormatter } from "common";
import { RecommendationDetailView, Statuses } from "records";
import { ChartsContainer } from "./ChartsContainer";

const gray_color = colors.doveGray;
const graphColors = [
  colors.congressBlue,
  colors.seance,
  colors.roti,
  colors.brightTurquoise,
  colors.frenchRose,
  colors.illusion,
  colors.pizzaz,
  colors.lightningYellow,
  colors.robinsEggBlue,
  colors.forestGreen,
];

export const useTabelRowDataExtractor = (apiRef: React.MutableRefObject<GridApiPro>) => {
  const [rows, setRows] = React.useState<RecommendationDetailView[] | null>(null);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const updateData = () => {
    setGridLoading(true);
    setRows(gridFilteredSortedRowEntriesSelector(apiRef).map((r) => r.model as RecommendationDetailView));
    setGridLoading(false);
  };

  const debouncedHandleStateChanged = useCallback(_.debounce(updateData, 500), []);

  useEffect(() => {
    if (!apiRef.current || !apiRef.current.subscribeEvent) {
      return;
    }
    setGridLoading(true);
    const eventListeners = [apiRef.current.subscribeEvent("stateChange", debouncedHandleStateChanged)];

    return () => {
      eventListeners.forEach((cleanUp) => cleanUp());
    };
  }, [apiRef.current]);

  return { rows, gridLoading };
};

interface ChartItemDataFormat {
  id: any;
  label: string;
  value: number;
  color?: string;
}

const useStatusData = (data: RecommendationDetailView[]): ChartItemDataFormat[] => {
  const statusesMap = {};
  Statuses.forEach((item) => {
    const itemLabel = item.replace(/([a-z])([A-Z])/g, "$1 $2"); // Add a space between words
    statusesMap[item] = { id: item, label: itemLabel, value: 0 };
  });

  data.forEach((d) => {
    const id = d.model.status?.toString();
    if (statusesMap[id]) {
      statusesMap[id].value = statusesMap[id].value + 1;
    }
  });

  return Object.values(statusesMap);
};

const useContributorsCountData = (data: RecommendationDetailView[]) => {
  const users: ChartItemDataFormat[] = data
    .map((value) => {
      return value.record.createdBy;
    })
    .reduce((acc, current) => {
      const matchingItem = acc.find((item) => item.label === current.fullName);
      if (!matchingItem) {
        acc.push({
          id: current.userId,
          label: current.fullName,
          value: 1,
          color: gray_color,
        });
      } else {
        matchingItem.value += 1;
      }
      return acc;
    }, new Array<ChartItemDataFormat>())
    .sort((a, b) => {
      const first = a.label.toLowerCase();
      const second = b.label.toLowerCase();

      if (first === "deleted user") {
        return 1;
      }
      if (second === "deleted user") {
        return -1;
      }

      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    });

  return users;
};

const useRecommendationTypesData = (data: RecommendationDetailView[]) => {
  const stats: { [key: string]: any } = {};

  data.forEach((d) => {
    const key = d.record?.model?.recordType?.toString();
    if (!stats[key]) {
      stats[key] = 0;
    }

    stats[key] += 1;
  });

  return [stats];
};

export const RecommendationsListDataViz = (props: {
  apiRef: React.MutableRefObject<GridApiPro>;
  loading: boolean | null;
}) => {
  const { rows, gridLoading } = useTabelRowDataExtractor(props.apiRef);
  const total = rows?.length || 0;
  const recordTypes = useRecommendationTypesData(rows || []);
  const statuses = useStatusData(rows || []);
  const contributors = useContributorsCountData(rows || []);

  return (
    <ChartsContainer loading={props.loading || gridLoading} totalNumberOfRows={rows ? rows.length : null}>
      <TotalRecommendationsCountBarChart data={total} />
      <RecommendationTypeBarChart data={recordTypes} />
      <RecommendationsByStatusPieChart data={statuses} />
      <RecommendationsContributorCountChart data={contributors} />
    </ChartsContainer>
  );
};

const LabeledChart = (props: { label: string; children: React.ReactNode }) => {
  return (
    <Stack sx={{ flex: "0 1 auto", width: "100%" }}>
      <Box sx={{ height: "48px", display: "flex", alignItems: "end", justifyContent: "center", paddingTop: "8px" }}>
        {props.children}
      </Box>
      <Typography variant="caption" sx={{ textAlign: "center" }}>
        {props.label}
      </Typography>
    </Stack>
  );
};

export const TotalRecommendationsCountBarChart = (props: { data: number }) => {
  return (
    <LabeledChart label="Recommendations">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          background: graphColors[0],
          color: "white",
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        {props.data}
      </Typography>
    </LabeledChart>
  );
};

export const RecommendationTypeBarChart = (props: { data: any[] }) => {
  return (
    <LabeledChart label="Types">
      <MyResponsiveBar data={props.data} />
    </LabeledChart>
  );
};

export const RecommendationsByStatusPieChart = (props: { data: ChartItemDataFormat[] }) => {
  return (
    <LabeledChart label="Status">
      <MyResponsivePie data={props.data} />
    </LabeledChart>
  );
};

export const RecommendationsContributorCountChart = (props: { data: ChartItemDataFormat[] }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <LabeledChart label="Contributors">
      <Box onMouseEnter={openPopover} onMouseLeave={closePopover} height="100%">
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            background: graphColors[0],
            color: "white",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          {props.data.length}
        </Typography>
      </Box>
      <HoverTooltip {...popoverProps} allData={Object.values(props.data)} />
    </LabeledChart>
  );
};

const MyResponsivePie = ({ data }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <Box onMouseEnter={openPopover} onMouseLeave={closePopover} width="100%" height="100%">
      <ResponsivePie
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        startAngle={-90}
        endAngle={90}
        borderWidth={0}
        isInteractive={false}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={graphColors}
        legends={[]}
        animate={false}
      />
      <HoverTooltip {...popoverProps} allData={data} />
    </Box>
  );
};

const MyResponsiveBar = ({ data }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <Box onMouseEnter={openPopover} onMouseLeave={closePopover} width="100%" height="100%">
      <ResponsiveBar
        data={data}
        margin={{ top: 0, right: 20, bottom: 0, left: 20 }}
        layout="horizontal"
        groupMode={"stacked"}
        valueScale={{ type: "linear" }}
        keys={Object.keys(data[0])}
        indexScale={{ type: "band", round: true }}
        colors={graphColors}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        legends={[]}
        isInteractive={false}
        animate={false}
      />
      <HoverTooltip
        {...popoverProps}
        allData={Object.keys(data[0]).map((k) => ({ id: k, label: k, value: data[0][k] }))}
      />
    </Box>
  );
};

interface HoverTooltipProps {
  allData: ChartItemDataFormat[];
  open: boolean;
  anchorEl: any;
  onClose: () => void;
}

const HoverTooltip = (props: HoverTooltipProps) => {
  return (
    <Popover
      sx={{
        pointerEvents: "none",
        marginTop: "20px",
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={props.onClose}
      disableRestoreFocus
    >
      <Box sx={{ p: 2 }}>
        <table style={{ border: "none", borderCollapse: "collapse", maxWidth: 0, whiteSpace: "nowrap" }}>
          {props.allData?.map((item, index) => (
            <tr key={item.id}>
              <td style={{ textAlign: "right", width: "100%" }}>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: item.color || graphColors[index], fontWeight: "bold" }}
                >
                  {item.label}:
                </Typography>
              </td>
              <td style={{ textAlign: "left" }}>
                <Typography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
                  {wholeNumberFormatter.format(item.value)}
                </Typography>
              </td>
            </tr>
          ))}
        </table>
      </Box>
    </Popover>
  );
};
