import { Box, Fade, Stack, Typography } from "@mui/material";
import { LabeledChartSkeleton } from "app/mui/common/skeletons/LabeledChartSkeleton";
import { IrisColors } from "app/mui/theme";

export interface ChartsContainerProps {
  children: React.ReactNode;
  loading: boolean | null;
  totalNumberOfRows: number | null;
}

export const ChartsContainer = (props: ChartsContainerProps) => {
  const showResults = props.totalNumberOfRows != null && props.totalNumberOfRows > 0 && props.loading === false;
  const showNoResults = props.totalNumberOfRows != null && props.totalNumberOfRows === 0 && props.loading === false;

  return (
    <Box
      sx={{
        backgroundColor: IrisColors.legacyGray200,
        borderRadius: 1,
        height: "70px",
        width: "100%",
        maxWidth: "700px",
      }}
    >
      {(props.loading == null || props.loading === true || props.totalNumberOfRows === null) && (
        <Stack
          direction={"row"}
          height={"70px"}
          justifyContent="space-evenly"
          display="flex"
          alignContent="center"
          alignItems="center"
        >
          {[...new Array(4)].map((_, index) => (
            <LabeledChartSkeleton key={index} />
          ))}
        </Stack>
      )}
      {showNoResults && (
        <Fade in={showNoResults} style={{ transitionDelay: "500ms" }}>
          <Stack sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "24px" }}>
            <Typography variant="caption" sx={{ textAlign: "center" }}>
              No results found.
            </Typography>
          </Stack>
        </Fade>
      )}
      {showResults && (
        <Stack direction={"row"} height={"70px"}>
          {props.children}
        </Stack>
      )}
    </Box>
  );
};
