import { AppSettings, useAppSettingsRequest } from "common";
import { createContext, FunctionComponent, ReactNode, useContext } from "react";

export interface AppSettingsContextProps {
   appSettings: AppSettings | null;
}

export const AppSettingsContext = createContext<AppSettingsContextProps>({
   appSettings: null,
});

export const AppSettingsContextProvider: FunctionComponent<{
   children?: ReactNode;
}> = (props) => {
   const { data: appSettings } = useAppSettingsRequest();

   return <AppSettingsContext.Provider value={{ appSettings }}>{props.children}</AppSettingsContext.Provider>;
};

export const useAppSettingsContext = () => useContext(AppSettingsContext);

