import { Box, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { FieldSkeleton } from "./FieldSkeleton";

export const EntityHeaderSkeleton = () => {
  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" width="50%">
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" width="25%">
              <Skeleton />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" width="50%">
              <Skeleton />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          {[...Array(9)].map((a, index) => (
            <Grid item xs={12} lg={4} key={index}>
              <FieldSkeleton />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};
