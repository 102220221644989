import * as React from "react";
import { Box, Divider, Grid, Paper, Stack } from "@mui/material";
import { AssetIcon } from "icons/AssetIcon";
import { AssetTypeBadge } from "app/mui/AssetTypeBadge";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { UseFormReturn } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { AssetImage } from "../AssetImage";
import { CriticalitiesLoader } from "app/mui/loaders/CriticalitiesLoader";
import { CriticalityFormItem } from "app/mui/forms/CriticalityFormItem";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { CreateRecordDialog } from "assets/mui/dialogs/CreateRecord";
import { DuplicateDialog } from "assets/mui/dialogs/Duplicate";
import { GenerateReportDialog } from "assets/mui/dialogs/GenerateReport";
import { SiteAreasForCurrentSite } from "app/mui/AreasContext";
import { KeywordsField } from "keywords/mui/KeywordsField";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { DeleteAssetModal } from "../dialogs/DeleteAssetModal";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { useCallback } from "react";
import { useUserContext } from "app/contexts/UserContext";
import GpsFormItem from "app/mui/forms/Input/GpsFormItem";
import { LastModified } from "app/mui/LastModified";
import { AssetTag } from "./AssetTag";
import { AssetStatus } from "./AssetStatus";
import { useMobileView } from "app/hooks/useMobileView";
import { AssetApplication } from "./AssetApplications";
import { Asset } from "assets/models/asset";
import { AssetDetailView } from "assets/models/assetDetail";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";

interface AssetDetailsProps {
  assetDetail: AssetDetailView;
  methods: UseFormReturn<Asset, any>;
  saveChanges: (onValid?: () => void) => void;
}

type DialogActionsType = "createRecord" | "generateReport" | "duplicate" | "editName" | "delete" | null;
const validDialogActions = ["createRecord", "generateReport", "duplicate", "editName", "delete"];

const pageActions = (asset: Asset) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <CreateNewFolderRoundedIcon />,
      label: "Create record",
      action: () => actionDispatcher.dispatch("createRecord"),
      hidden: !userPermissions.userCanEditRecords || !userPermissions.userCanEditAsset(asset.assetType),
    },
    <Divider />,
    {
      icon: <AssessmentRoundedIcon />,
      label: "Generate report",
      action: () => actionDispatcher.dispatch("generateReport"),
      hidden: !userPermissions.userCanGenerateRecordReport,
    },
    {
      icon: <ContentCopyRoundedIcon />,
      label: "Duplicate",
      action: () => actionDispatcher.dispatch("duplicate"),
      hidden: !userPermissions.userCanAddAsset(asset.assetType),
    },
    {
      icon: <AssetIcon />,
      label: "Edit name",
      action: () => actionDispatcher.dispatch("editName"),
      hidden: !userPermissions.userCanAddAsset(asset.assetType),
    },
    {
      icon: <DeleteForeverRoundedIcon />,
      label: "Delete asset",
      action: () => actionDispatcher.dispatch("delete"),
      hidden: !userPermissions.userCanEditAsset(asset.assetType),
    },
  ]);

export const AssetDetails = (props: AssetDetailsProps) => {
  const isMobileView = useMobileView();

  const { assetDetail, methods } = props;
  const asset = assetDetail.model;
  const { userPermissions } = useUserContext();
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(asset), actionDispatcher);

  const handleAction = useCallback((action: string) => {
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const [openDialog, setOpenDialog] = React.useState<DialogActionsType>(null);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const userCanEditAsset = userPermissions.userCanEditAsset(asset.assetType);

  const showAssetActions =
    (userPermissions.userCanEditRecords && userCanEditAsset) ||
    userPermissions.userCanGenerateRecordReport ||
    userCanEditAsset;

  const handleSave = () => {
    props.saveChanges(closeDialog);
  };

  return (
    <>
      <Paper>
        <Box sx={{ p: 2 }}>
          <Stack direction="row" justifyContent={isMobileView ? "center" : "space-between"} pb={2}>
            <Stack direction="row" flexWrap="wrap" spacing={2} alignContent="center">
              <AssetImage assetType={asset.assetType} />

              {!isMobileView && (
                <Box>
                  <Stack spacing={1} justifyContent="center" height="100%">
                    <Stack spacing={0.4} justifyContent="center" height="100%">
                      <AssetTag tag={asset.tag} />
                      <AssetTypeBadge assetType={asset.assetType} />
                    </Stack>
                    <Stack direction="row" spacing={3} alignItems="center">
                      <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: "auto", minWidth: "100px", maxWidth: "150px" }}>
                        <CriticalitiesLoader>
                          {(options) => (
                            <CriticalityFormItem fieldName="criticalityId" {...options} label="Criticality" />
                          )}
                        </CriticalitiesLoader>
                      </Box>
                      <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                        <AssetStatus
                          obsoleteComponentCount={assetDetail.obsoleteComponentCount}
                          obsoleteComponents={assetDetail.obsoleteComponents}
                        />
                      </Box>
                      {asset.applicationId && (
                        <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: "auto" }}>
                          <AssetApplication applicationId={asset.applicationId} siteId={asset.siteId} />
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              )}
            </Stack>
            {!isMobileView && (
              <Stack spacing={2} alignItems="flex-end">
                {showAssetActions && <ActionsDropdownMenu primary actions={actions} />}
                <LastModified
                  lastModifiedBy={assetDetail.lastModifiedBy}
                  lastModified={assetDetail.lastModified}
                  textAlign="right"
                />
              </Stack>
            )}
          </Stack>
          {isMobileView && (
            <Stack direction="column" spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <AssetTag tag={asset.tag} />
                {showAssetActions && <ActionsDropdownMenu primary actions={actions} />}
              </Stack>
              <LastModified lastModifiedBy={assetDetail.lastModifiedBy} lastModified={assetDetail.lastModified} />
              <AssetTypeBadge assetType={asset.assetType} />

              <Stack direction="row" spacing={2}>
                <Box>
                  <CriticalitiesLoader>
                    {(options) => <CriticalityFormItem fieldName="criticalityId" {...options} label="Criticality" />}
                  </CriticalitiesLoader>
                </Box>
                <AssetStatus
                  obsoleteComponentCount={assetDetail.obsoleteComponentCount}
                  obsoleteComponents={assetDetail.obsoleteComponents}
                />
              </Stack>
            </Stack>
          )}
        </Box>
        <Divider />
        <ExpansionPanel title="Details" defaultExpanded={true}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={5} xl={4}>
                <Stack spacing={2}>
                  <TextFieldFormItem
                    fieldName="serviceDescription"
                    label="Service Description"
                    textFieldProps={{
                      multiline: true,
                      rows: 4,
                    }}
                  />
                  <TextFieldFormItem fieldName="pipingAndInstrumentDiagramNumber" label="P&Id number" />
                  <TextFieldFormItem fieldName="datasheetNumber" label="Datasheet number" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <Stack spacing={2}>
                  <TextFieldFormItem fieldName="locationDescription" label="Location description" />
                  <SiteAreasForCurrentSite>
                    {({ areas }) => (
                      <AutocompleteFormItem
                        fieldName="areaId"
                        label="Area"
                        options={areas}
                        getOptionLabel={(area) => area.name}
                        autocompleteProps={{
                          disabled: !userCanEditAsset,
                        }}
                      />
                    )}
                  </SiteAreasForCurrentSite>
                  <TextFieldFormItem fieldName="legalSubdivision" label="Legal Subdivision" />
                  <KeywordsField
                    modelKeywords={assetDetail.model.keywords}
                    formMethods={methods}
                    readOnly={!userCanEditAsset}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4}>
                <GpsFormItem fieldName="gpsCoordinates" label="GPS coordinates" includeViewLink />
              </Grid>
            </Grid>
          </Box>
        </ExpansionPanel>
      </Paper>

      {openDialog === "createRecord" && <CreateRecordDialog onClose={closeDialog} asset={asset} />}
      {openDialog === "generateReport" && <GenerateReportDialog onClose={closeDialog} asset={asset} />}
      {openDialog === "duplicate" && <DuplicateDialog onClose={closeDialog} asset={asset} />}
      {openDialog === "editName" && (
        <EditFieldDialog
          methods={methods}
          saveChanges={handleSave}
          fieldName="tag"
          dialogTitle="Change asset name"
          label="Asset Name"
          confirmButtonText="Change name"
          onClose={closeDialog}
        />
      )}
      {openDialog === "delete" && <DeleteAssetModal onClose={closeDialog} asset={asset} />}
    </>
  );
};
