import * as React from "react";
import { Icon } from "icons/Icon";

export const CheckmarkIcon: React.FunctionComponent = () => (
   <Icon>
      <g id="surface1">
         <path d="M 19.28125 5.28125 L 9 15.5625 L 4.71875 11.28125 L 3.28125 12.71875 L 8.28125 17.71875 L 9 18.40625 L 9.71875 17.71875 L 20.71875 6.71875 Z " />
      </g>
   </Icon>
);
