import { Grid } from "@mui/material";
import { GetKpiCommodityUnitsForEmissions, KpiCommodityUnitsForSpills, KpiValueModel } from "kpis/models";
import {
  KpiOneTimeReleaseOrSpillAvoidance,
  KpiOneTimeReleaseOrSpillAvoidanceViewModel,
} from "kpis/models/KpiOneTimeReleaseOrSpillAvoidance";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { EditKpiFormProps } from "../EditKpiFormProps";
import { KpiGridStyles } from "../KpiGridStyles";
import { KpiCommentsFormItem } from "../formItems/KpiCommentsFormItem";
import ProductionRateFormItem from "../formItems/ProductionRateFormItem";
import ReleaseTypeFormItem from "../formItems/ReleaseTypeFormItem";

interface KpiOneTimeReleaseOrSpillAvoidanceDetailsProps extends EditKpiFormProps {}

function KpiOneTimeReleaseOrSpillAvoidanceDetails(props: KpiOneTimeReleaseOrSpillAvoidanceDetailsProps) {
  const kpi = useMemo(
    () => new KpiOneTimeReleaseOrSpillAvoidanceViewModel(props.detail.model as KpiOneTimeReleaseOrSpillAvoidance),
    [props.detail.model]
  );
  const isKpiTemplate = props.record === undefined;
  const hasTemplateComments =
    isKpiTemplate || (!!(props.model as KpiValueModel).kpiTemplateId && !!props.model.templateComments);

  const { gridRowProps, gridContainerProps } = KpiGridStyles;

  const inputProps = useMemo(() => ({ readOnly: props.readOnly }), [props.readOnly]);

  const methods = useFormContext();
  const releaseType: string = methods.watch("releaseType");

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid container {...gridContainerProps} rowSpacing={0}>
          <Grid item xs={12}>
            <ReleaseTypeFormItem
              fieldName="releaseType"
              reductionTypeFieldName="reductionType"
              reductionUnitFieldName="reductionUnit"
              {...inputProps}
            />
          </Grid>
        </Grid>
        <Grid container {...gridContainerProps} {...gridRowProps} rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12}>
            <ProductionRateFormItem
              label="Amount (quantity/unit/product)"
              quantityFieldName="reductionAmount"
              unitFieldName="reductionUnit"
              productFieldName="reductionType"
              rateFieldName="reductionAmount"
              units={
                releaseType === "Emission"
                  ? GetKpiCommodityUnitsForEmissions(kpi.model.reductionType)
                  : KpiCommodityUnitsForSpills
              }
              productTypeInputControl={releaseType === "Emission" ? "emissions" : "none"}
              {...inputProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          {hasTemplateComments && (
            <Grid item xs={12}>
              <KpiCommentsFormItem
                label="Comments"
                fieldName="templateComments"
                alwaysShowInput
                readOnly={!isKpiTemplate}
              />
            </Grid>
          )}
          {!isKpiTemplate && (
            <Grid item xs={12}>
              <KpiCommentsFormItem label="Additional Comments" fieldName="comments" readOnly={!props.userCanEdit} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default KpiOneTimeReleaseOrSpillAvoidanceDetails;
