import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { RecordDetailView } from "records/models";
import { FormProvider, useForm } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateKpiValuesRequest } from "kpis/api";
import { KpiTemplateSelectFormItem } from "app/mui/forms/Select/KpiTemplateSelectFormItem";
import { useGetRecordsByIdQuery } from "records/api";

interface AssignKpiTemplateDialogProps extends BaseDialogProps {
  record: RecordDetailView;
  onComplete?: (record: RecordDetailView) => void;
}

const validationSchema = yup.object({
  comments: yup.string().nullable(),
  selectedKpi: yup.mixed().nullable().required("required"),
});

export const AssignKpiTemplateDialog = (props: AssignKpiTemplateDialogProps) => {
  const assignKpiTemplateRequest = useCreateKpiValuesRequest();
  const { call: refetchRecords } = useGetRecordsByIdQuery([props.record.id]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = () => {
    methods.handleSubmit(
      (values) => {
        assignKpiTemplateRequest
          ?.call({
            siteId: props.record.siteId,
            kpiType: values.selectedKpi.type,
            kpiTemplateId: values.selectedKpi.value,
            recordId: props.record.id,
            comments: values.comments || "",
          })
          .then(() => refetchRecords())
          .then((records) => {
            records.map((record) => props.onComplete?.(record));
            props.onClose();
          });
      },
      (rej) => console.log(rej)
    )();
  };

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6">"Assign KPI template to 1 record"</Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <KpiTemplateSelectFormItem label="Select a KPI template" fieldName="selectedKpi" />
            <TextFieldFormItem label="Comments" fieldName="comments" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            Assign template
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
