import { useCallback, useEffect, useRef } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowModel,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { useDeleteSiteConfigValueRequest, useUpdateSiteConfigValueRequest } from "sites/manage/api";
import { getKpiRateRelatedConfigs, KpiRateRoleOptions, Site, SiteConfigValue } from "sites/models";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { createStaticColumns, useColumns } from "app/mui/tables/utils/createStaticColumns";
import { SimpleDeleteModal } from "app/mui/common/modals/SimpleDeleteModal";
import { useModal } from "app/mui/common/useModal";
import moment from "moment";
import { dateFormat } from "common";
import { IrisColors } from "app/mui/theme";
import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useNotifications } from "app";
import { toString } from "lodash";

interface KpiSettingsTabProps {
  site: Site;
}

const columnDef = createStaticColumns((actionDispatcher) => [
  {
    field: "name",
    headerName: "Role",
    width: 220,
    type: "singleSelect",
    editable: true,
    valueOptions: KpiRateRoleOptions,
    valueFormatter: (params) => KpiRateRoleOptions.find((o) => o.value === params.value)?.label ?? "",
  },
  {
    field: "value",
    type: "number",
    headerName: "Rate",
    editable: true,
    width: 220,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">$ {params.value}</Typography>
          <Typography variant="body2" sx={{ color: IrisColors.legacyGray600 }}>
            / hr
          </Typography>
        </Stack>
      );
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const lessThanZero = params.props.value.toString() === "" || params.props.value < 0;
      return { ...params.props, error: lessThanZero };
    },
  },
  {
    type: "date",
    field: "effective",
    headerName: "Effective Date",
    editable: true,
    valueFormatter: (params) => moment(params.value).format(dateFormat),
    width: 220,
    renderEditCell: (params) => {
      return <EditEffectiveDateCell {...params} />;
    },
  },
  {
    type: "actions",
    field: "actions",
    width: 60,
    hideable: false,
    getActions: (params: any) => [
      <GridActionsCellItem
        icon={<DeleteRoundedIcon />}
        label={"Remove rate"}
        onClick={() => {
          actionDispatcher.dispatch("remove-rate", params);
        }}
        showInMenu
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        placeholder=""
      />,
    ],
  },
]);

export const KpiSettingsTab = (props: KpiSettingsTabProps) => {
  const notifications = useNotifications();
  const actionDispatcher = useActionDispatcher();
  const columns = useColumns(columnDef, actionDispatcher);

  const updateConfigValueRequest = useUpdateSiteConfigValueRequest(props.site.id);
  const deleteConfigValueRequest = useDeleteSiteConfigValueRequest(props.site.id);
  const configIdToDelete = useRef<number>();

  const onActionReceived = useCallback((action: string, params: any) => {
    if (action === "remove-rate") {
      configIdToDelete.current = params.id;
      openModal();
    }
  }, []);

  useEffect(() => {
    actionDispatcher.subscribe(onActionReceived);
    return () => actionDispatcher.unsubscribe(onActionReceived);
  }, []);

  const { modalProps, openModal } = useModal();

  const handleDelete = () => {
    const configId = configIdToDelete.current;

    if (configId) {
      deleteConfigValueRequest.call(configId);
    }

    configIdToDelete.current = undefined;
  };

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel) => {
      const originalConfig = props.site.siteConfigValues.find((a) => a.id === newRow.id);

      const newConfig = {
        ...originalConfig,
        ...newRow,
        value: toString(newRow.value),
      } as SiteConfigValue;

      updateConfigValueRequest.call(newConfig);
      return newConfig;
    },
    [updateConfigValueRequest]
  );

  const handleUpdateError = React.useCallback((error: any) => {
    notifications.error(error);
  }, []);

  const transformedSiteConfigValues = getKpiRateRelatedConfigs(props.site);

  return (
    <Box sx={{ height: "500px", my: 1 }}>
      <StripedDataGrid
        columns={columns}
        rows={transformedSiteConfigValues}
        experimentalFeatures={{ newEditingApi: true }}
        editMode="row"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleUpdateError}
        initialState={{
          sorting: {
            sortModel: [{ field: "effectiveDate", sort: "desc" }],
          },
          pinnedColumns: {
            right: ["actions"],
          },
        }}
      />
      <SimpleDeleteModal
        {...modalProps}
        title="Remove rate?"
        confirmButtonText="Remove rate"
        onConfirm={handleDelete}
      />
    </Box>
  );
};

const EditEffectiveDateCell = (props: GridRenderEditCellParams<any, any, any>) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (newDate?: Date) => {
    apiRef.current.setEditCellValue({ id, field, value: newDate });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disableFuture
        value={value}
        onChange={(parsedValue, keyboardValue) => handleValueChange(parsedValue?.toDate())}
        renderInput={(inputParams) => <TextField {...props.textFieldProps} {...inputParams} />}
      />
    </LocalizationProvider>
  );
};
