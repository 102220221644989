import { Accordion, AccordionDetails, AccordionSummaryProps, Box, Paper, styled } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { formatRoutePath, Routes } from "app";
import { useUserContext } from "app/contexts/UserContext";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { IrisColors } from "app/mui/theme";
import { useUpdateKpiTemplateRequest } from "kpis/api";
import { KpiTemplateDetail, KpiTemplateModel } from "kpis/models";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteKpiTemplateDialog } from "./dialogs/DeleteKpiTemplateDialog";
import { KpiTemplateDetailForm } from "./KpiTemplateDetailForm";
import { KpiTemplateRowHeader } from "./KpiTemplateRowHeader";

interface KpiTemplateRowProps {
  kpiTemplateDetail: KpiTemplateDetail;
}

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(() => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

type DialogActionsType = "deleteKpi" | null;
const validDialogActions: DialogActionsType[] = ["deleteKpi"];

export const KpiTemplateRow = (props: KpiTemplateRowProps) => {
  const { kpiTemplateDetail } = props;

  const { userPermissions } = useUserContext();
  const navigate = useNavigate();

  const updateKpiTemplateRequest = useUpdateKpiTemplateRequest();

  const userCanEdit = userPermissions.userCanEditKpiTemplate;

  const userCanUnlock = userPermissions.userCanUnlockKpi;

  const actionDispatcher = useActionDispatcher();

  const goToList = () => {
    const kpiTemplateList = formatRoutePath(Routes.KpiTemplates, {
      siteId: kpiTemplateDetail.siteId,
    });
    navigate(kpiTemplateList);
  };

  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);
  const closeDialog = () => {
    setOpenDialog(null);
  };

  const handleAction = useCallback((action: string, params: any) => {
    if (validDialogActions.includes(action as DialogActionsType)) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const saveChanges = (model: KpiTemplateModel, updatedKpiModel: Partial<KpiTemplateModel>) => {
    const updatedFields = { ...model, ...updatedKpiModel } as KpiTemplateModel;
    updateKpiTemplateRequest.call(updatedFields);
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <KpiTemplateRowHeader kpiTemplateDetail={kpiTemplateDetail} />

        <KpiTemplateDetailForm
          kpiTemplateDetail={kpiTemplateDetail}
          readOnly={!userCanEdit || kpiTemplateDetail.model.signedOff || actionDispatcher.isActionInProgress}
          userCanEdit={userCanEdit}
          userCanUnlock={userCanUnlock}
          saveChanges={(fields: Partial<KpiTemplateModel>) => saveChanges(kpiTemplateDetail.model, fields)}
        />
      </Paper>

      {openDialog === "deleteKpi" && (
        <DeleteKpiTemplateDialog
          onClose={closeDialog}
          kpiTemplateDetail={kpiTemplateDetail!}
          onDeleteKpiTemplate={goToList}
        />
      )}
    </>
  );
};
