import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RecordDetailView, RecordTypes } from "records/models";
import { useChangeRecordTypeRequest } from "records/api";
import { isSingleAssetRecordType } from "records/utilities";

interface ChangeRecordTypeDialogProps extends BaseDialogProps {
	record: RecordDetailView;
}

const validationSchema = yup.object({
	recordType: yup.string().nullable().required("required"),
});

export const ChangeRecordTypeDialog = (props: ChangeRecordTypeDialogProps) => {
	const changeRecordTypeRequest = useChangeRecordTypeRequest();

	const methods = useForm({ resolver: yupResolver(validationSchema) });

	const recordTypeOptions = RecordTypes.filter((rt) => !isSingleAssetRecordType(rt)).map((rt) => ({
		id: rt,
		label: rt,
	}));

	const onSubmit = () => {
		methods.handleSubmit((values) => {
			changeRecordTypeRequest
				.call({
					recordId: props.record.id,
					recordType: values.recordType,
				})
				.then(() => {
					props.onClose();
				});
		})();
	};
	return (
		<BaseDialog onClose={props.onClose}>
			<DialogTitle
				sx={{
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					overflow: "hidden",
				}}
			>
				<Typography variant="h6">Change record type</Typography>
			</DialogTitle>
			<DialogContent dividers={true} sx={{ minHeight: "100px" }}>
				<FormProvider {...methods}>
					<Stack spacing={1}>
						<AutocompleteFormItem
							autocompleteProps={{ disablePortal: false }}
							label="Record type"
							fieldName="recordType"
							options={recordTypeOptions}
						/>
					</Stack>
				</FormProvider>
			</DialogContent>
			<DialogActions>
				<Stack direction="row" spacing={2}>
					<Button onClick={props.onClose}>Cancel</Button>
					<Button variant="contained" color="secondary" onClick={onSubmit}>
						Change type
					</Button>
				</Stack>
			</DialogActions>
		</BaseDialog>
	);
};
