import * as React from "react";

interface EditableContextType {
   editable: boolean | null;
}

interface EditableContextProviderProps {
   editable: boolean;
   children: React.ReactNode;
}

const EditableContext = React.createContext<EditableContextType>({ editable: false });

export const EditableContextProvider = (props: EditableContextProviderProps) => {
   return <EditableContext.Provider value={{ editable: props.editable }}>{props.children}</EditableContext.Provider>;
};

export const useEditableContext = () => {
   const context = React.useContext(EditableContext);

   if (!context) {
      // if there is no context, return neither true no false.
      return { editable: null };
   }

   return context;
};
