import { Stack, Typography } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { NumberFormatInput } from "app/mui/forms/Input/NumberFormatInput";
import { formatCurrency, numberWithCommas } from "common";
import { Controller } from "react-hook-form";

interface PotentialLabourCostFormItemProps extends BaseFormItemProps {
  multiply: boolean;
  multiplier: number;
  ratePerHour: number;
  combinedActualHours: number;
}

export function PotentialLabourCostFormItem(props: PotentialLabourCostFormItemProps) {
  const { fieldName, readOnly, multiply, multiplier, ratePerHour, combinedActualHours, ...formItemProps } = props;

  if (multiply) {
    return (
      <Typography>
        {numberWithCommas(multiplier)} &nbsp;&times;&nbsp; {`${numberWithCommas(combinedActualHours)} hrs =`}
        &nbsp;
        {`${numberWithCommas(combinedActualHours * (multiplier || 0))} hrs at ${formatCurrency(ratePerHour)}/hr`};
      </Typography>
    );
  }

  return (
    <Controller
      name={fieldName}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <FormItemLayout
          controlled
          error={errors[fieldName]?.message?.toString()}
          formControlProps={{ disabled: readOnly }}
          {...formItemProps}
        >
          {(inputProps) => (
            <Stack direction="row" alignItems="center" spacing={2}>
              <NumberFormatInput
                onChange={onChange}
                value={value}
                placeholder="hours"
                disabled={readOnly}
                {...inputProps}
              />
              <Typography>hrs&nbsp;&times;&nbsp;{`${formatCurrency(ratePerHour)}/hr`}</Typography>
            </Stack>
          )}
        </FormItemLayout>
      )}
    />
  );
}
