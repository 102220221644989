import { Theme } from "@mui/material";
import _ from "lodash";
import { formatRoutePath, Routes } from "app/routes";
import { HealthCategory, RecordDetailView, RecordType } from "records";
import { getStaticColorForHealth } from "../../HealthBadge";
import { Datum } from "@nivo/line";

export interface ValveHealthChartDataModel extends Datum {
   x: Date;
   y: number | null;
   category: HealthCategory | null;
   type: "asfound" | "asleft";
   link: string;
   color: string;
}

export const generateHealthHistoryDataFromRecords = (records: RecordDetailView[], theme: Theme): ValveHealthChartDataModel[] => {
   const dataPoints = records
      .filter((r) => r.model.recordType === RecordType.ValveDiagnostic && r.model.health.overall !== null)
      .map((r) => {
         return [
            {
               y: r.model.health.asFoundOverall,
               x: r.model.eventDate || r.model.closed || r.lastModified || r.created,
               category: r.model.health.asFoundOverallCategory,
               type: "asfound",
               link: formatRoutePath(Routes.Record, { siteId: r.siteId, id: r.id }),
               color: getStaticColorForHealth(r.model.health.asFoundOverallCategory, theme),
            } as ValveHealthChartDataModel,
            {
               y: r.model.health.asLeftOverall,
               x: r.model.eventDate || r.model.closed || r.lastModified || r.created,
               category: r.model.health.asLeftOverallCategory,
               type: "asleft",
               link: formatRoutePath(Routes.Record, { siteId: r.siteId, id: r.id }),
               color: getStaticColorForHealth(r.model.health.asLeftOverallCategory, theme),
            } as ValveHealthChartDataModel,
         ];
      })
      .reduce((a, b) => [...a, ...b], [])
      .filter((point) => point.x != null && point.y != null);

   return _.sortBy(dataPoints, "x");
};
