import {
   createContext,
   FunctionComponent,
   ReactNode,
   useCallback,
   useContext,
   useEffect,
   useRef,
   useState,
} from "react";

export interface Message {
   id: string;
   state: "started" | "completed" | "failed";
}

type SubscriberFn = (message: Message) => void;

const useApiStatusProvider = () => {
   const subscribers = useRef(new Set<SubscriberFn>());
   const subscribe = useCallback((callback: SubscriberFn) => {
      subscribers.current.add(callback);
      return () => {
         subscribers.current.delete(callback);
      };
   }, []);

   const notify = useCallback((message: Message) => {
      subscribers.current.forEach((callback) => callback(message));
   }, []);

   const addSaving = useCallback((id: string) => {
      notify({ id, state: "started" });
   }, []);

   const removeSaving = useCallback((id: string) => {
      notify({ id, state: "completed" });
   }, []);

   const savingFailed = useCallback((id: string) => {
      notify({ id, state: "failed" });
   }, []);

   return {
      subscribe,
      addSaving,
      removeSaving,
      savingFailed,
   };
};

type UseApiStatusProviderType = ReturnType<typeof useApiStatusProvider>;

export const ApiStatusContext = createContext<UseApiStatusProviderType | null>(null);

export const ApiStatusContextProvider: FunctionComponent<{
   children?: ReactNode;
}> = (props) => {
   return <ApiStatusContext.Provider value={useApiStatusProvider()}>{props.children}</ApiStatusContext.Provider>;
};

export const useApiStatusHistoryWatcher = () => {
   const apiStatusContext = useContext(ApiStatusContext)!;
   const timeout = useRef<any>();

   const [isSaving, setIsSaving] = useState<boolean>(false);
   const [justSaved, setJustSaved] = useState<boolean>(false);

   const handleApiRequestStatusChanged = useCallback((message: Message) => {
      if (message.state === "started") {
         setIsSaving(() => true);
      }
      if (message.state === "completed") {
         setIsSaving(() => false);
         setJustSaved(() => true);

         timeout.current = setTimeout(() => {
            setJustSaved(false);
         }, 5000);
      }
      if (message.state === "failed") {
         setIsSaving(() => false);
      }
   }, []);

   useEffect(() => {
      const cleanup = apiStatusContext.subscribe(handleApiRequestStatusChanged);

      return () => {
         try {
            clearTimeout(timeout.current);
         } catch {}

         cleanup();
      };
   }, []);

   return { isSaving, justSaved };
};
