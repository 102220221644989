import * as React from "react";
import { Chip } from "@mui/material";
import { ChipProps } from "@mui/material/Chip";
import { VisualIntegrity } from "records";

interface Props {
  visualIntegrity?: VisualIntegrity | null;
}

type ChipColor = Pick<ChipProps, "color">["color"];

export const getColorForVisualIntegrity = (value?: number | string | null): ChipColor => {
  if (value === 4 || value == "Severe") return "AssetHealth_MajorIssues";
  else if (value === 3 || value == "Moderate") return "AssetHealth_ModerateIssues";
  else if (value === 2 || value == "Minor") return "AssetHealth_MinorIssues";
  else if (value === 1 || value == "Good") return "AssetHealth_Good";

  return "AssetHealth_Unknown";
};

export const getLabelForVisualIntegrity = (value?: number | string | null): string | null => {
  if (value === 4 || value === "Severe") return "Severe";
  else if (value === 3 || value === "Moderate") return "Moderate";
  else if (value === 2 || value === "Minor") return "Minor";
  else if (value === 1 || value === "Good") return "Good";

  return null;
};

export const VisualIntegrityBadge = (props: { score?: number | null }) => {
  const color = getColorForVisualIntegrity(props.score);
  const label = getLabelForVisualIntegrity(props.score);

  if (label === null) {
    return <></>;
  }

  return (
    <Chip variant="outlined" size="small" sx={{ borderRadius: 1, fontWeight: "bold" }} color={color} label={label} />
  );
};
