import React from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Loading } from "app/bs";
import { ListGroup, ListGroupItem, Card, Button } from "reactstrap";
import _ from "lodash";
import { KeywordUsage } from "./models";
import { formatRoutePath, Routes } from "app";
import { Link } from "react-router-dom";
import { DeleteIcon } from "icons/DeleteIcon";
import { useState } from "react";
import { useDeleteKeywordRequest, useGetKeywordUsageRequest } from "./api";
import { useGetSiteDigestsRequest } from "sites/api";

export const ManageKeywords: React.FunctionComponent<{}> = (props) => {
   const [selectedKeyword, setSelectedKeyword] = useState<KeywordUsage | null>(null);

   const noUsages = !!selectedKeyword && selectedKeyword.assets.length === 0 && selectedKeyword.records.length === 0;

   const getSiteDigestsQuery = useGetSiteDigestsRequest();
   const sites = getSiteDigestsQuery.data ?? [];

   const { data: keywords, loading: loadingKeywords, call: refetchKeywords } = useGetKeywordUsageRequest();

   const deleteKeywordRequest = useDeleteKeywordRequest();

   return (
      <div className="legacy_bootstrap">
         <div className="manage-keywords">
            <title>SpartanPRO IRIS - Manage keywords</title>
            <Row className="title-card-header">
               <Col>
                  <Card body>
                     <div className="screen-title">Manage keywords</div>
                  </Card>
               </Col>
            </Row>
            <Row>
               <Col md={3}>
                  <Card body>
                     {(getSiteDigestsQuery.loading || loadingKeywords) && <Loading />}
                     {!!keywords && (
                        <div className="keyword-list">
                           <h3>Keywords</h3>
                           <ListGroup className="keyword-list">
                              {_.orderBy(keywords, (k) => k.value).map((k) => (
                                 <ListGroupItem
                                    key={k.id}
                                    tag="button"
                                    action
                                    active={selectedKeyword !== null && k.id === selectedKeyword.id}
                                    onClick={() => setSelectedKeyword(k)}
                                 >
                                    <span>{k.value}</span>
                                    {k.assets.length === 0 && k.records.length === 0 && (
                                       <span className="unused-marker">unused</span>
                                    )}
                                 </ListGroupItem>
                              ))}
                           </ListGroup>
                        </div>
                     )}
                  </Card>
               </Col>

               <Col md={9}>
                  {selectedKeyword && (
                     <Card body>
                        <h3>Usages</h3>

                        {selectedKeyword.assets.length > 0 && (
                           <div className="mb-4">
                              <h4>Assets</h4>
                              {selectedKeyword.assets.map((asset) => {
                                 const site = _.first(sites.filter((s) => s.id === asset.siteId));
                                 return (
                                    <div key={asset.id}>
                                       <Link to={formatRoutePath(Routes.Asset, asset)} className="tag mr-3">
                                          {asset.tag}
                                       </Link>
                                       <span>
                                          {site?.customer.name} &gt; {site?.name}
                                       </span>
                                    </div>
                                 );
                              })}
                           </div>
                        )}

                        {selectedKeyword.records.length > 0 && (
                           <div className="mb-4">
                              <h4>Records</h4>
                              {selectedKeyword.records.map((record) => {
                                 const site = _.first(sites.filter((s) => s.id === record.siteId));
                                 return (
                                    <div key={record.id}>
                                       <Link to={formatRoutePath(Routes.Record, record)} className="mr-3">
                                          {record.description}
                                       </Link>
                                       <span>
                                          {site?.customer.name} &gt; {site?.name}
                                       </span>
                                    </div>
                                 );
                              })}
                           </div>
                        )}

                        {noUsages && <div>None.</div>}

                        <Button
                           color="danger"
                           className="align-self-end"
                           disabled={deleteKeywordRequest.loading || loadingKeywords || !noUsages}
                           title={noUsages ? undefined : "Remove all usages before deleting the keyword."}
                           onClick={() => {
                              deleteKeywordRequest.call(selectedKeyword.id).then(() => {
                                 refetchKeywords();
                                 setSelectedKeyword(null);
                              });
                           }}
                        >
                           <DeleteIcon />
                           <span> Delete keyword</span>
                        </Button>
                     </Card>
                  )}
               </Col>
            </Row>
         </div>
      </div>
   );
};
