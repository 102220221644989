import * as React from "react";
import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Label, Input } from "reactstrap";
import { DollarAmountInput } from "../inputs/DollarAmountInput";
import { TextAreaInput } from "../inputs/TextAreaInput";
import { RecordOutagePlanningScope } from "../models";

interface ConfirmAcceptScopeModalProps {
  validationDetails: string | null;
  justification: string | null;
  close: () => void;
  isOpen: boolean;
  onAccept: () => void;
  scope: RecordOutagePlanningScope;
}

export const ConfirmAcceptScopeModal = (props: ConfirmAcceptScopeModalProps) => {
  const { getValues } = useFormContext();

  const missingQuoteAmount = useMemo(() => {
    return getValues().quoteAmount == null || getValues().quoteAmount === "";
  }, [props.isOpen]);

  const canAcceptScope = useCanAcceptScope(props.scope);

  return (
    <Modal
      className="create-summary-modal outage-modal"
      isOpen={props.isOpen}
      toggle={() => props.close()}
      container={"#bootstrap-modal-container"}
    >
      <ModalHeader>Mark scope as accepted?</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to accept this scope?</p>
        {missingQuoteAmount && <DollarAmountInput label="Quote amount" field="quoteAmount" userCanEdit={true} />}

        <TextAreaInput label="Scope of work details" field={"details"} userCanEdit={true} />

        {props.scope.scopeType === "Original" && (
          <TextAreaInput label="Scope validation details" field={"validationDetails"} userCanEdit={true} />
        )}
        {props.scope.scopeType === "Recommendation" && (
          <TextAreaInput label="Justification" field={"justification"} userCanEdit={true} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.onAccept();
            props.close();
          }}
          disabled={!canAcceptScope}
        >
          Accept Scope
        </Button>
        <Button color="secondary" onClick={() => props.close()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const useCanAcceptScope = (scope: RecordOutagePlanningScope) => {
  const [quoteAmount, details, validationDetails, justification] = useWatch({
    name: ["quoteAmount", "details", "validationDetails", "justification"],
  });

  if (quoteAmount == null || quoteAmount === "") {
    return false;
  }

  if (!details) {
    return false;
  }

  if (scope.scopeType === "Original" && !validationDetails) {
    return false;
  }

  if (scope.scopeType === "Recommendation" && !justification) {
    return false;
  }

  return true;
};
