import { Box, Stack, Typography } from "@mui/material";
import { IrisColors } from "app/mui/theme";

export const NotEnoughData = () => {
   return (
      <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
         <Stack spacing={1} sx={{ textAlign: "center" }}>
            <Typography variant="h5" fontWeight="bold" sx={{ color: IrisColors.gray600 }}>
               We don't have enough data yet.
            </Typography>
            <Typography fontWeight="600" sx={{ color: IrisColors.gray600 }}>
               Please check back later.
            </Typography>
         </Stack>
      </Box>
   );
};
