import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import React, { useCallback } from "react";
import { useBackgroundReportContext } from "app/contexts/BackgroundReportContext";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import { FormProvider, useForm } from "react-hook-form";
import { Summary } from "summaries";
import { RadioGroupFormItem } from "app/mui/forms/RadioButtonFormItem";

interface ConfigureFieldInspectionSummaryReportModalProps extends BaseDialogProps {
  summary: Summary;
}

export const ConfigureFieldInspectionSummaryReportModal: React.FunctionComponent<
  ConfigureFieldInspectionSummaryReportModalProps
> = (props) => {
  const methods = useForm({
    defaultValues: {
      includeBadActors: true,
      includeInspectionResults: true,
      inspectionResultsFilter: "filtered",
      includeNotes: true,
      includePhotos: true,
      includeVisualIntegrityLegend: true,
      appendPdfAttachments: true,
    },
  });

  const { generateFieldReport: generateFieldInspectionSummaryReportNode } = useBackgroundReportContext();

  const handleSubmit = useCallback(() => {
    const values = methods.getValues();
    const requestBody = {
      id: props.summary.id,
      pdf: true,
      ...values,
    };
    generateFieldInspectionSummaryReportNode?.call(requestBody);
    props.onClose();
  }, [methods, generateFieldInspectionSummaryReportNode, props]);

  const canIncludeInspectionResults = methods.watch("includeInspectionResults");

  return (
    <BaseDialog onClose={props.onClose} maxWidth="md">
      <DialogTitle>Report options</DialogTitle>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <CheckboxFormItem fieldName="includeBadActors" label="Include Bad actors" />
            <CheckboxFormItem fieldName="includeInspectionResults" label="Include Inspection Results" />
            <Box sx={{ paddingLeft: 5 }}>
              <RadioGroupFormItem
                readOnly={!canIncludeInspectionResults}
                fieldName="inspectionResultsFilter"
                options={[
                  { value: "filtered", label: "Include only severe and moderate" },
                  { value: "all", label: "Include all inspection results" },
                ]}
              />
            </Box>
            <CheckboxFormItem fieldName="includeNotes" label="Include Notes" />
            <CheckboxFormItem fieldName="includePhotos" label="Include photos" />
            <CheckboxFormItem fieldName="includeVisualIntegrityLegend" label="Include Visual Integrity Legend" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF attachments" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton loading={false} variant="contained" color="secondary" onClick={handleSubmit}>
            Confirm <OpenInNewIcon sx={{ pl: 1, width: "1rem" }} />
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
