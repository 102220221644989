import { Stack } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { TabViews as GridTabViews } from "./persistence/tabViews";
import { CustomDataViewScopes, CustomDataViewType } from "./persistence/tabViews/models";
import { SaveTabViewButton } from "./persistence/tabViews/SaveTabViewButton";
import _ from "lodash";
import { useTabbedViews } from "./persistence/useTabbedViews";
import { MoreOptionsTabViewButton } from "./persistence/tabViews/MoreOptionsTabViewButton";
import { BusinessAreaFilterToggle } from "./BusinessAreaFilterToggle";
import { NewViewModal, NewViewModalData } from "./persistence/tabViews/NewViewModal";
import { useModal } from "../common/useModal";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface ToolbarOptions {
  includeUserDefinedTabs?: boolean;
  includeBusinessAreaFilter?: boolean;
}

export const ToolbarWithTabViews = (type: CustomDataViewType, options?: ToolbarOptions) => {
  const TypedToolbarWithTabViews = () => {
    const tabsApi = useTabbedViews(type);

    const [searchParams] = useSearchParams();
    const { openModal, modalProps } = useModal();
    const [modalData, setModalData] = useState<NewViewModalData>();

    const tryImportView = (view: string) => {
      setModalData({
        name: "",
        scope: CustomDataViewScopes.Everywhere,
        duplicate: false,
        importedState: view,
      });
      openModal();
    };

    useEffect(() => {
      const importedView = searchParams.get("import");
      if (importedView) {
        tryImportView(importedView);
        var params = new URLSearchParams("");
        window.history.replaceState(null, "", `?${params.toString()}`);
      }
    }, []);

    return (
      <GridToolbarContainer>
        <Stack sx={{ width: "100%" }}>
          {options?.includeUserDefinedTabs && <GridTabViews tabsApi={tabsApi} />}
          <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={{ xs: 0, md: 3 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <GridToolbarColumnsButton
                placeholder={undefined}
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
              <GridToolbarFilterButton
                placeholder={undefined}
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            </Stack>
            {options?.includeBusinessAreaFilter && <BusinessAreaFilterToggle />}
            {options?.includeUserDefinedTabs && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <SaveTabViewButton tabsApi={tabsApi} />
                <MoreOptionsTabViewButton tabsApi={tabsApi} />
              </Stack>
            )}
          </Stack>
        </Stack>
        {modalProps.open && modalData && <NewViewModal {...modalProps} modalData={modalData} tabsApi={tabsApi} />}
      </GridToolbarContainer>
    );
  };

  return TypedToolbarWithTabViews;
};
