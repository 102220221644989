import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import _ from "lodash";
import { useEffect, useState, useCallback } from "react";
import { StateTransformer } from "./queryParamSync/stateTransformer";
import { CustomDataView } from "./tabViews/models";
import { useTableStateSubscription } from "./useTableStateSubscription";
import { getDiff } from "json-difference";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";

// this hook can be used with in a grid context
// to determine if the state of the grid has changed
// from the original custom data view

export const useDirtyStateTracker = (apiRef: React.MutableRefObject<GridApiPro>) => {
   const [isDirty, setIsDirty] = useState<boolean>(false);
   const [originalView, setOriginalView] = useState<CustomDataView>();
   const [workingState, setWorkingState] = useState<GridInitialStatePro>();

   const tableStateChanges = useTableStateSubscription();
   const updateWorkingState = useCallback((state: GridInitialStatePro) => {
      setWorkingState(state);
   }, []);

   useEffect(() => {
      tableStateChanges.subscribe(updateWorkingState);
      return () => tableStateChanges.unsubscribe(updateWorkingState);
   }, []);

   const updateUrlQueryParamsWithNewWorkingState = useCallback((state?: GridInitialStatePro) => {
      // !!! important !!!
      //
      // We cannot use the useSearchParams hook for this is,
      // as it causes page re-renders, which messes with the
      // DataGrid and breaks everything! we have to manually
      // and carefully update window history!
      if (state) {
         const encodedState = StateTransformer.transformOutgoing(state, apiRef);
         var params = new URLSearchParams(window.location.search);
         params.set("query", encodedState);
         window.history.replaceState(null, "", `?${params.toString()}`);
      } else {
         var params = new URLSearchParams(window.location.search);
         params.delete("query");
         window.history.replaceState(null, "", `?${params.toString()}`);
      }
   }, []);

   const recalculateDirtyState = useCallback(
      (originalView: CustomDataView) => {
         if (!originalView || !originalView.view || !workingState) return;
         const originalState = JSON.parse(originalView.view);

         // comparing states is hard, the mui table seems to have some internal
         // states that keep changing and are hard to diff... So my workaround is
         // to reuse our state transfomer, get both states formatted the same way,
         // then run it through a json diff library.

         const encodedOriginalState = StateTransformer.transformOutgoing(originalState, apiRef);
         const encodedWorkingState = StateTransformer.transformOutgoing(workingState, apiRef);

         const diff = getDiff(JSON.parse(encodedOriginalState), JSON.parse(encodedWorkingState));
         const dirty = diff.added.length > 0 || diff.edited.length > 0 || diff.removed.length > 0;

         if (dirty) {
            console.log(diff);
         }

         if (dirty) {
            updateUrlQueryParamsWithNewWorkingState(workingState);
         } else {
            updateUrlQueryParamsWithNewWorkingState(undefined);
         }

         setIsDirty(dirty);
      },
      [workingState, originalView]
   );

   useEffect(() => {
      recalculateDirtyState(originalView!);
   }, [workingState]);

   return { isDirty, setOriginalView, recalculateDirtyState };
};
