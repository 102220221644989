import InventoryIcon from "@mui/icons-material/Inventory";
import { Box, Stack, useTheme } from "@mui/material";
import { CompleteIcon } from "icons/CompleteIcon";
import { IdentifiedIcon } from "icons/IdentifiedIcon";
import { PendingIcon } from "icons/PendingIcon";
import { Status, statusToDisplayName } from "records";

const getIconForStatus = (status?: Status) => {
  switch (status) {
    case "Identified":
      return <IdentifiedIcon />;
    case "CustomerAction":
      return <PendingIcon />;
    case "ImpactPartnerAction":
      return <PendingIcon />;
    case "Complete":
      return <CompleteIcon />;
    case "Archived":
      return <InventoryIcon sx={{ fontSize: 14 }} />;
    default:
      return undefined;
  }
};

type BadgeVariant = "outlined" | "simple";

interface Props {
  status?: Status;
  isDarkTheme?: boolean;
  variant?: BadgeVariant;
}

export const RecordStatusBadge = (props: Props) => {
  const { status, variant = "outlined", isDarkTheme } = props;
  const theme = useTheme();
  const fill = !!isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;
  if (!status) {
    return null;
  }
  return (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        border: variant === "outlined" ? `1px solid ${fill}` : "none",
        padding: variant === "outlined" ? "0.1rem 0.5rem" : "0",
        fontSize: variant === "outlined" ? ".8rem" : "1rem",
        borderRadius: "5px",
        color: fill,
        alignItems: "center",
      }}
    >
      <Box
        flex="0"
        sx={{
          width: "16px",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          svg: { fill: fill },
        }}
      >
        {getIconForStatus(status)}
      </Box>
      <Box flex="1">{statusToDisplayName(status)}</Box>
    </Stack>
  );
};
