import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { numberWithCommas, Validity } from "common";
import classNames from "classnames";
import { NumberInputGroup } from "records/bs/NumberInputGroup";
import { KpiHelpTooltip } from "./KpiHelpTooltip";

export type KpiRemoteHoursOption = "All" | "None" | "Some";

interface Props {
  fields: { spartanHours: number | null; remoteHours: number };
  showValidationErrors: boolean;
  validity: Validity;
  readOnly: boolean;
  selectedRemoteHoursOption: KpiRemoteHoursOption | null;
  setSelectedRemoteHoursOption: (selected: KpiRemoteHoursOption) => any;
  saveChange: (change: { remoteHours: number }) => any;
}

export const RemoteHoursFormGroup: React.FunctionComponent<Props> = (props) => {
  return (
    <FormGroup style={{ margin: "0" }}>
      <Label>Remote hours</Label>
      <div className="form-group-wrapper">
        <Input
          name="remoteHours"
          type="select"
          className={classNames({
            "is-invalid": props.showValidationErrors && !props.validity.valid,
          })}
          disabled={props.readOnly}
          onChange={(change) => {
            const newOption = change.target.value as KpiRemoteHoursOption;
            props.setSelectedRemoteHoursOption(newOption);
            switch (newOption) {
              case "All":
                props.saveChange({
                  remoteHours: props.fields.spartanHours || 0,
                });
                break;
              case "None":
                props.saveChange({
                  remoteHours: 0,
                });
            }
          }}
          value={props.selectedRemoteHoursOption || ""}
          style={{ width: props.selectedRemoteHoursOption === "Some" ? "100px" : "150px" }}
        >
          {!props.selectedRemoteHoursOption && (
            <option value="" disabled>
              Select...
            </option>
          )}
          <option value="All">
            All {props.fields.spartanHours !== null ? numberWithCommas(props.fields.spartanHours) : ""} hour
            {props.fields.spartanHours === 1 ? "" : "s"}
          </option>
          <option value="Some">Some</option>
          <option value="None">None</option>
        </Input>
        {props.selectedRemoteHoursOption === "Some" && (
          <NumberInputGroup
            placeholder="hours"
            postLabel="hrs"
            inputStyle={{ width: "60px" }}
            value={props.fields.remoteHours}
            onChange={(newValue) =>
              props.saveChange({
                remoteHours: newValue || 0,
              })
            }
            valid={!props.showValidationErrors || props.validity.valid}
            validationError={props.validity.message}
            readOnly={props.readOnly}
          />
        )}
        <KpiHelpTooltip type="remoteHours" spanStyle={{ marginTop: ".2em" }} />
      </div>
    </FormGroup>
  );
};
