import { LinearProgress } from "@mui/material";
import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import { useGetDefaultLandingPageForCustomer } from "./routes";

export const RedirectToFirstCustomer: FunctionComponent<{}> = () => {
   const defaultLandingPageForCustomer = useGetDefaultLandingPageForCustomer(null);
   if (defaultLandingPageForCustomer.loading) return <LinearProgress />;

   if (!!defaultLandingPageForCustomer.url) {
      return <Navigate to={defaultLandingPageForCustomer.url} replace />;
   }
   return (
      <>
         <p>You have successfully logged into IRIS.</p>{" "}
         <p>Please contact an IRIS system administrator to associate you to a site.</p>
      </>
   );
};
