import { useBaseAxiosPostRequest, useNotifications } from "app";
import { dateFormat } from "common";
import fileDownload from "js-file-download";
import moment from "moment";
import { Site } from "sites";
import {
   getImportTypeDisplayName,
   ImportCsvResult,
   ImportCsvSaveDeltaModel,
   ImportCsvSaveDeltaResultModel,
   ImportType,
} from "./models";

export const useDownloadCsvExportRequest = (site: Site, importType: ImportType) => {
   const request = useBaseAxiosPostRequest<number[] | string[], string>(`api/csv/export/${importType}/${site.id}`, {
      app: { hideFromAutosaver: true },
      useNode: true
   });
   const notifications = useNotifications();
   const name = getImportTypeDisplayName(importType);

   const call = (ids: number[] | string[]) => {
      var key = notifications.info(`Downloading ${name}`, true);
      return request.call(ids).then((file) => {
         const fileName = `${site.name} ${name} ${moment().format(dateFormat)}.csv`;

         // encoding issue: https://github.com/kennethjiang/js-file-download/issues/56
         fileDownload(file, fileName, "text/csv;charset=utf-8", "\uFEFF");
         notifications.scheduleDismiss(key);
      });
   };

   return { ...request, call };
};

export const useDownloadCsvTemplateRequest = (site: Site, importType: ImportType) => {
   const request = useBaseAxiosPostRequest<string[], string>(`api/csv/export/${importType}/${site.id}/template`, {
      app: { hideFromAutosaver: true },
      useNode: true
   });
   const name = getImportTypeDisplayName(importType);

   const call = (types: string[], displayName?: string) => {
      return request.call(types).then((file) => {
         const fileName = `${displayName ?? ""} ${name} Template.csv`.trim();

         // encoding issue: https://github.com/kennethjiang/js-file-download/issues/56
         fileDownload(file, fileName, "text/csv;charset=utf-8", "\uFEFF");
      });
   };

   return { ...request, call };
};

export const useUploadCsvRequest = (importType: ImportType | null, siteId: number) =>
   useBaseAxiosPostRequest<FormData, ImportCsvResult>(`api/csv/upload/${importType ?? ""}/${siteId}`, {
      app: { hideFromAutosaver: true },
      useNode: true,
   });

export const useSaveCsvDeltasRequest = (importType: ImportType | null, siteId: number) =>
   useBaseAxiosPostRequest<ImportCsvSaveDeltaModel[], ImportCsvSaveDeltaResultModel[]>(
      `api/csv/save/${importType ?? ""}/${siteId}`,
      { app: { hideFromAutosaver: true }, useNode: true },

   );
