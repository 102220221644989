import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { usePopover } from "app/mui/common/usePopover";
import { IrisColors } from "app/mui/theme";
import { compactWholeNumberFormatter } from "common";
import { KpiSavingsTotal } from "dashboard/models";

interface SinglePercentageBarProps {
  total: number;
  savings: KpiSavingsTotal;
}

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: "100%",
    height: "30px",
    position: "relative",
    borderRadius: 1,
    overflow: "hidden",
  })
);

const EmptyBar = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    background: IrisColors.gray300,
  })
);

const FullBar = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    top: 0,
    left: 0,
    height: "100%",
    position: "absolute",
    backgroundColor: IrisColors.green,
  })
);

const SectionHints = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
  })
);

const SectionHint = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: "25%",
    height: "100%",
    position: "relative",
    float: "left",
    borderRight: "solid 1px " + IrisColors.legacyGray400,
  })
);

const AmountOverlay = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "white",
    fontWeight: 600,
    top: 0,
    left: 0,
    position: "absolute",
    height: "100%",
    display: "flex",
    alignItems: "center",
    pl: 1,
    fontSize: "0.85em",
    textShadow: "0px 0px 1px rgba(0, 0, 0, 0.45)",
  })
);

export const SinglePercentageBar = (props: SinglePercentageBarProps) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <>
      <Container onMouseEnter={openPopover} onMouseLeave={closePopover}>
        <EmptyBar />
        <FullBar width={`${(props.savings.approved / props.total) * 100}%`} />
        <SectionHints>
          <SectionHint />
          <SectionHint />
          <SectionHint />
        </SectionHints>
        <AmountOverlay>${compactWholeNumberFormatter.format(props.savings.approved)}</AmountOverlay>
        <HoverTooltip {...popoverProps} savings={props.savings} />
      </Container>
    </>
  );
};

interface HoverTooltipProps {
  savings: KpiSavingsTotal;
  open: boolean;
  anchorEl: any;
  onClose: () => void;
}

const HoverTooltip = (props: HoverTooltipProps) => {
  return (
    <Popover
      sx={{
        pointerEvents: "none",
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={props.onClose}
      disableRestoreFocus
      disablePortal
    >
      <Stack spacing={0} sx={{ p: 2 }} alignItems="stretch" justifyContent="flex-start">
        <Typography sx={{ color: IrisColors.green, fontWeight: 600 }}>
          Approved: ${compactWholeNumberFormatter.format(props.savings.approved)}
        </Typography>
        <Typography sx={{ color: IrisColors.orange, fontWeight: 600 }}>
          Pending: ${compactWholeNumberFormatter.format(props.savings.pending)}
        </Typography>

        <Divider sx={{ width: "100%", my: 1 }} />
        <Typography sx={{ fontWeight: 600 }}>
          Total: ${compactWholeNumberFormatter.format(props.savings.approved + props.savings.pending)}
        </Typography>
      </Stack>
    </Popover>
  );
};
