import _ from "lodash";
import { useEffect, useRef, useState } from "react";

export function useAutosaver<D, R = D>(
  initialValue: D,
  saveFunction: (data: D) => Promise<R>,
  onSaved?: (result: R) => Promise<any>
): [D, (changed: D, now?: boolean) => void, (changed: D) => void] {
  const [data, setData] = useState<D>(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const [needsToSave, setNeedsToSave] = useState(false);
  const [needsToSaveNow, setNeedsToSaveNow] = useState(false);

  const saveData = (changed: D, now = false) => {
    setData(changed);
    setNeedsToSaveNow(now);
    setNeedsToSave(true);
  };

  const resetData = (changed: D) => {
    setData(changed);
    setNeedsToSaveNow(false);
    setNeedsToSave(false);
  };

  const debouncedSave = useRef(
    _.debounce((postData: D) => {
      setIsSaving(true);
      saveFunction(postData)
        .then((result) => {
          if (!!onSaved) onSaved(result).finally(() => setIsSaving(false));
          else setIsSaving(false);
        })
        .catch((error) => {
          setIsSaving(false);
        });
    }, 2000)
  ).current;

  useEffect(() => {
    if (!isSaving && needsToSave && !!data) {
      debouncedSave(data);
      setNeedsToSave(false);
      if (needsToSaveNow) {
        setNeedsToSaveNow(false);
        debouncedSave.flush();
      }
    }
  }, [isSaving, needsToSave]);

  return [data, saveData, resetData];
}
