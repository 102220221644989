import Add from "@mui/icons-material/Add";
import { Box, Paper, Button, Stack, Typography, Chip } from "@mui/material";
import { GridFooterContainer } from "@mui/x-data-grid-pro";
import { formatRoutePath, Routes, useSiteContext } from "app";
import { getGeneratedColumnsFromMetadata } from "app/mui/tables/metadata";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { useGetOfflineCreateAssetRequest, useSyncOfflineCreateAssetRequest } from "importOffline/api";
import { fromPairs } from "lodash";
import { useMatch, useNavigate } from "react-router-dom";


export const CreateOfflineAssetList = () => {

   const routeMatch = useMatch(Routes.ImportOfflineCreate);
   const importId = Number(routeMatch?.params?.id);
   const site = useSiteContext().currentSite!;
   const importOfflinePage = formatRoutePath(Routes.ImportOffline, { siteId: site.id });
   const navigate = useNavigate();

   const getOfflineCreateAssetRequest = useGetOfflineCreateAssetRequest(importId);
   const requestResults = !getOfflineCreateAssetRequest.error ? getOfflineCreateAssetRequest.data : null;
   const rows = requestResults?.rows ?? [];

   const columns = requestResults?.muiColumns || [];
   const muiColumns = columns.length > 0 ? getGeneratedColumnsFromMetadata(columns, [site]) : [];
   const columnVisibilityModel = fromPairs((columns).map((c) => [c.field, true]));

   const syncOfflineCreateAssetRequest = useSyncOfflineCreateAssetRequest(importId);

   const syncDataOnClick = () => {
      syncOfflineCreateAssetRequest.call(rows.map((r) => (r.id)))
         .then(() => navigate(formatRoutePath(Routes.ImportOfflineConfirm, { siteId: site.id, id: importId })))
   };

   const renderFooter = () => (
      <GridFooterContainer>
         <Stack width="100%" direction="row" justifyContent="flex-end" spacing={2}>
            <Button onClick={() => navigate(importOfflinePage)}>
               Cancel
            </Button>
            <Button
               variant="contained"
               color="secondary"
               onClick={syncDataOnClick}
            >
               Sync Data
            </Button>
         </Stack>
      </GridFooterContainer >
   );

   return (
      <Box sx={{ width: "100%" }}>
         {!getOfflineCreateAssetRequest.loading && (
            <Paper sx={{ p: 2 }}>
               <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ m: "10px" }}
               >
                  <Typography variant="body2">
                     The following assets will be created automatically within the online database.
                     If an offline tag matches an existing online tag, a new name will be given to the respective asset (tagname_duplicate).</Typography>
                  <Chip icon={<Add />} label={`${rows.length}/${rows.length} assets to create`} />
               </Stack>
               <Box sx={{ width: "100%", height: "700px" }}>
                  <StripedDataGrid
                     density="compact"
                     components={{
                        Footer: renderFooter,
                     }}
                     rows={rows}
                     columns={muiColumns}
                     columnVisibilityModel={columnVisibilityModel}
                     getRowHeight={() => "auto"}
                  />
               </Box>
            </Paper>
         )
         }
      </Box >
   );
};
