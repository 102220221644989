import { Typography } from "@mui/material";
import { useDeleteAssetComponentRequest } from "assets/api";
import { AssetMachineryComponent } from "assets/models/machineryComponent";
import ConfirmationDialog from "common/mui/ConfirmationDialog";
import { useFormContext } from "react-hook-form";
import { createAttributeFieldName } from "./AssetAttributeField";

interface DeleteAssetComponentDialogProps {
  onClose: () => void;
  component: AssetMachineryComponent;
  onDeleteComponent: () => void;
}

export const DeleteAssetComponentDialog = (props: DeleteAssetComponentDialogProps) => {
  const deleteComponentRequest = useDeleteAssetComponentRequest();
  const { onClose, component } = props;

  const methods = useFormContext();

  const handleDelete = () => {
    const fallbackFormFields = methods.getValues();
    const attributeFieldNames = component.assetComponentAttributes.map(createAttributeFieldName);
    methods.unregister(attributeFieldNames);
    deleteComponentRequest
      .call(component.id)
      .then(() => {
        props.onClose();
      })
      .catch(() => {
        methods.reset(fallbackFormFields);
      });
  };

  return (
    <ConfirmationDialog
      title="Delete Component"
      open={true}
      cancel={onClose}
      loading={deleteComponentRequest.loading}
      confirmButtonText="Delete"
      confirm={handleDelete}
    >
      <Typography variant="body2">Are you sure you want to delete {component.title}?</Typography>
    </ConfirmationDialog>
  );
};
