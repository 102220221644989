import { Draggable } from "@hello-pangea/dnd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography, styled, useTheme } from "@mui/material";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionMenuActionType, ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import AutoSave from "app/mui/forms/AutoSave";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { RecordStatusFormItem } from "app/mui/forms/Select/RecordStatusFormItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { IrisColors } from "app/mui/theme";
import { DeleteIcon } from "icons/DeleteIcon";
import { DragIcon } from "icons/DragIcon";
import { EditIcon } from "icons/EditIcon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Recommendation, RecordType } from "records/models";
import { DeleteRecommendationDialog } from "records/mui/dialogs/DeleteRecommendation";
import { EditRecommendationTitleDialog } from "records/mui/dialogs/EditRecommendationTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AssetDigest } from "assets";
import { MultiAssetComponentSelectFormItem } from "app/mui/forms/MultiAssetComponentSelectFormItem";

interface IRecordRecommendationsCardProps {
  assets: AssetDigest[];
  recordType: RecordType;
  recommendation: Recommendation;
  index: number;
  expanded: boolean;
  onExpandChange: () => void;
  canEdit: boolean;
  isClosed: boolean;
  onSave: (values: Recommendation) => void;
  doRefresh: () => void;
}

function getStyle(isDragging: boolean): React.CSSProperties {
  return {
    backgroundColor: isDragging ? "lightgrey" : "white",
  };
}

const DraggableBox = styled(Box)({
  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  padding: "20px 5px",
});

const RecommendationNumber = styled(Box)({
  backgroundColor: `${IrisColors.blueSpartan}`,
  borderRadius: "50%",
  width: "1.5em",
  aspectRatio: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1em",
  color: "white",
});

const GhostAccordion = styled(Accordion)({
  padding: 0,
  boxShadow: "none",
  border: "none",
  borderBottom: "none",
  marginTop: 0,
  alignItems: "flex-start",
  "& .MuiAccordionSummary-root": {
    alignItems: "flex-start",
  },
  "& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded": {
    marginTop: 0,
    marginBottom: 0,
    width: "100%",
  },
});

type DialogActionsType = "deleteRecommendation" | "renameTitle" | null;
const validDialogActions = ["renameTitle", "deleteRecommendation"];

export const RecordRecommendationCard = ({
  assets,
  recordType,
  recommendation,
  index,
  expanded,
  onExpandChange,
  canEdit,
  isClosed,
  onSave,
  doRefresh,
}: IRecordRecommendationsCardProps) => {
  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);

  const formDefaultValues = makeSafeForReactHookForm(recommendation);
  const methods = useForm({ defaultValues: formDefaultValues });

  const saveChanges = useCallback(() => {
    methods.handleSubmit((values) => {
      onSave(values);
    })();
  }, [onSave]);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  useEffect(() => {
    methods.setValue("priority", recommendation.priority, { shouldDirty: false });
  }, [recommendation.priority]);

  const actionDispatcher = useActionDispatcher();

  const isComplete = useMemo(() => recommendation.status === "Complete", [recommendation.status]);

  const actions = [
    { icon: <EditIcon />, label: "Rename title", action: () => actionDispatcher.dispatch("renameTitle") },
    {
      icon: <DeleteIcon />,
      label: "Delete recommendation",
      action: () => actionDispatcher.dispatch("deleteRecommendation"),
      hidden: !canEdit,
    },
  ] as ActionMenuActionType[];

  const handleAction = useCallback((action: string, params: any) => {
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Draggable draggableId={recommendation.id.toString()} key={recommendation.id.toString()} index={index}>
        {(provided, snapshot) => (
          <DraggableBox {...provided.draggableProps} ref={provided.innerRef}>
            <div style={{ display: "flex", flexFlow: "row nowrap", ...getStyle(snapshot.isDragging) }}>
              <Box sx={{ flex: "0 0 32px", textAlign: "center" }} {...provided.dragHandleProps}>
                <DragIcon />
              </Box>
              <div style={{ flex: 1 }}>
                <FormProvider {...methods}>
                  <AutoSave defaultValues={formDefaultValues} onSubmit={saveChanges} />
                  <GhostAccordion key={recommendation.id} expanded={expanded} onChange={onExpandChange}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ flexDirection: isMobile ? "row" : "row-reverse" }}
                    >
                      <Stack
                        direction={isMobile ? "column" : "row"}
                        spacing={1}
                        width="100%"
                        alignContent="flex-start"
                        marginLeft={isMobile ? "0" : theme.spacing(1)}
                      >
                        <Stack direction="row" spacing={1} flex="1 0 50%">
                          <Box flexGrow={0}>
                            <RecommendationNumber>{index + 1}</RecommendationNumber>
                          </Box>
                          <Box flexGrow={1}>
                            <Typography
                              sx={
                                isMobile && !expanded
                                  ? {
                                    display: "block",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    width: "50vw",
                                  }
                                  : {}
                              }
                            >
                              {recommendation.title}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          marginRight={isMobile ? "-30px !important" : "0"}
                          alignItems="center"
                          pb={isMobile ? 2 : 0}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Box
                            flexGrow={1}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <RecordStatusFormItem fieldName="status" />
                          </Box>
                          <Box textAlign="center">
                            {(canEdit || !isClosed) && <ActionsDropdownMenu rowActionMenu actions={actions} />}
                          </Box>
                        </Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={0}>
                        {recordType === RecordType.VibrationAnalysis && assets.length === 1 &&
                          < Grid item xs={12} md={8} lg={4} py={1}>
                            <MultiAssetComponentSelectFormItem
                              assetId={assets[0].id}
                              fieldName="assetComponents"
                              label='Component'
                            />
                          </Grid>
                        }
                        <Grid item xs={12}>
                          <RichTextEditor fieldName="content" disabled={!canEdit || isClosed || isComplete} />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </GhostAccordion>
                </FormProvider>
              </div>
            </div>
          </DraggableBox>
        )}
      </Draggable>

      {openDialog === "renameTitle" && (
        <EditRecommendationTitleDialog
          recommendation={recommendation}
          methods={methods}
          saveChanges={saveChanges}
          onClose={closeDialog}
        />
      )}
      {openDialog === "deleteRecommendation" && (
        <DeleteRecommendationDialog
          onClose={closeDialog}
          recommendation={recommendation}
          onDeleteRecommendation={doRefresh}
        />
      )}
    </>
  );
};
