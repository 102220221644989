import { AutocompleteFormItem, AutocompleteFormItemProps, Option } from "app/mui/forms/AutocompleteFormItem";
import { KpiEmissionsTypes } from "kpis/models";

interface ProductFormItemProps extends Omit<AutocompleteFormItemProps<false, false, Option>, "options"> {
  productTypeInputControl: ProductTypeInputControl;
  readOnly?: boolean;
}

export type ProductTypeInputControl = "none" | "emissions";

export function ProductFormItem(props: ProductFormItemProps) {
  const { readOnly, autocompleteProps, ...rest } = props;

  const options = KpiEmissionsTypes.map((et) => ({ id: et, label: et }));

  return (
    <AutocompleteFormItem
      options={options}
      placeholder="Select product"
      autocompleteProps={{ ...autocompleteProps, disabled: readOnly }}
      {...rest}
    />
  );
}
