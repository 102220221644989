import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm } from "react-hook-form";
import { Box, Divider, Grid, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import { IrisTooltip, useUserContext } from "app";
import { TrafficLightFormItem } from "app/mui/forms/Select/TrafficLightFormItem";
import { IrisColors } from "app/mui/theme";
import React from "react";
import {
  PneumaticFullStrokeCondition,
  PneumaticFullStrokeConditionFieldLabel,
  PneumaticFullStrokeConditionTooltip,
  PneumaticFullStrokePerformance,
  PneumaticFullStrokePerformanceFieldLabel,
  PneumaticFullStrokePerformanceTooltip,
  Record as RecordModel,
} from "records/models";

import InfoIcon from "@mui/icons-material/Info";
import {
  ReportCardPneumaticFullStroke,
  createNewReportCardPneumaticFullStroke,
} from "records/models/reportCardPneumaticFullStroke";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import AutoSave from "app/mui/forms/AutoSave";
import { useUpdateReportCardPneumaticFullStroke } from "records/api";

interface PneumaticFullStrokeReportCardProps {
  record: RecordModel;
  reportCard?: ReportCardPneumaticFullStroke | null;
}

const conditionFields: Array<{
  fieldName: keyof PneumaticFullStrokeCondition;
  label: PneumaticFullStrokeConditionFieldLabel;
  toolTipContent?: string;
}> = [
  {
    fieldName: "valveFriction",
    label: PneumaticFullStrokeConditionFieldLabel.ValveFriction,
    toolTipContent: PneumaticFullStrokeConditionTooltip.ValveFriction,
  },
  {
    fieldName: "ratedTravel",
    label: PneumaticFullStrokeConditionFieldLabel.RatedTravel,
    toolTipContent: PneumaticFullStrokeConditionTooltip.RatedTravel,
  },
  {
    fieldName: "seatingProfile",
    label: PneumaticFullStrokeConditionFieldLabel.SeatingProfile,
    toolTipContent: PneumaticFullStrokeConditionTooltip.SeatingProfile,
  },
  {
    fieldName: "dynamicErrorBand",
    label: PneumaticFullStrokeConditionFieldLabel.DynamicErrorBand,
    toolTipContent: PneumaticFullStrokeConditionTooltip.DynamicErrorBand,
  },
  {
    fieldName: "driveSignal",
    label: PneumaticFullStrokeConditionFieldLabel.DriveSignal,
    toolTipContent: PneumaticFullStrokeConditionTooltip.DriveSignal,
  },
];

const performanceFields: Array<{
  fieldName: keyof PneumaticFullStrokePerformance;
  label: PneumaticFullStrokePerformanceFieldLabel;
  toolTipContent?: string;
}> = [
  {
    fieldName: "setPointTracking",
    label: PneumaticFullStrokePerformanceFieldLabel.SetPointTracking,
    toolTipContent: PneumaticFullStrokePerformanceTooltip.SetPointTracking,
  },
  {
    fieldName: "overshoot",
    label: PneumaticFullStrokePerformanceFieldLabel.Overshoot,
    toolTipContent: PneumaticFullStrokePerformanceTooltip.Overshoot,
  },
  {
    fieldName: "offset",
    label: PneumaticFullStrokePerformanceFieldLabel.Offset,
    toolTipContent: PneumaticFullStrokePerformanceTooltip.Offset,
  },
  {
    fieldName: "cycling",
    label: PneumaticFullStrokePerformanceFieldLabel.Cycling,
    toolTipContent: PneumaticFullStrokePerformanceTooltip.Cycling,
  },
  {
    fieldName: "speed",
    label: PneumaticFullStrokePerformanceFieldLabel.Speed,
    toolTipContent: PneumaticFullStrokePerformanceTooltip.Speed,
  },
];

export const PneumaticFullStrokeReportCard = (props: PneumaticFullStrokeReportCardProps) => {
  const { record, reportCard } = props;
  const defaultValues = makeSafeForReactHookForm(reportCard || createNewReportCardPneumaticFullStroke(record.id));
  const methods = useForm({ defaultValues });
  const updateReportCard = useUpdateReportCardPneumaticFullStroke();
  const { userPermissions } = useUserContext();

  if (!reportCard) {
    return <></>;
  }

  const userCanEdit = userPermissions.userCanEditRecord(
    record.assets.map((asset) => asset.assetType),
    record.recordType
  );

  const saveChanges = () => {
    methods.handleSubmit((values) => {
      updateReportCard.call({ ...values, recordId: record.id });
    })();
  };

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permissions) =>
        permissions.userCanEditRecord(
          record.assets?.map((asset) => asset.assetType),
          record.recordType
        )
      }
    >
      <AutoSave onSubmit={saveChanges} />
      <PneumaticFullStrokeReportCardFields canEdit={userCanEdit} />
    </PermissionAwareFormProvider>
  );
};

const PneumaticFullStrokeReportCardFields = (props: { canEdit: boolean }) => {
  const asFoundWidth = "65px";
  const arrowWidth = "35px";
  const { canEdit: userCanEdit } = props;

  return (
    <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="column" sx={{ width: "100%" }}>
        <Typography variant="h6">REPORT CARD</Typography>
        <Paper>
          <List sx={{ width: "100%" }}>
            <ListItem alignItems="flex-start">
              <Grid container justifyContent="space-between" columnSpacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 500, textTransform: "uppercase" }}>
                    Condition
                  </Typography>
                </Grid>
                <Grid item xs={false} width={asFoundWidth}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;found</Typography>
                </Grid>
                <Grid item xs={false} width={arrowWidth} />
                <Grid item xs={true}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;left</Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider component="li" />
            {conditionFields.map((cField, cIndex) => (
              <React.Fragment key={`condition-${cField.fieldName}`}>
                <ListItem>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                      <Grid item xs={5} sx={{ display: "flex" }}>
                        <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                          {cField.label}
                        </Typography>
                        {cField.toolTipContent && (
                          <IrisTooltip title={<>{cField.toolTipContent}</>}>
                            <span>
                              <InfoIcon sx={{ color: IrisColors.gray500, fontSize: "1.1rem" }} />
                            </span>
                          </IrisTooltip>
                        )}
                      </Grid>
                      <Grid item xs={false} width={asFoundWidth}>
                        <TrafficLightFormItem
                          fieldName={"asFoundCondition_" + cField.fieldName}
                          syncFieldName={"asLeftCondition_" + cField.fieldName}
                          readOnly={!userCanEdit}
                        />
                      </Grid>
                      <Grid item xs={false} width={arrowWidth}>
                        <ArrowForwardIcon sx={{ fontSize: "20px", color: IrisColors.gray500 }} />
                      </Grid>
                      <Grid item xs={true}>
                        <TrafficLightFormItem
                          fieldName={"asLeftCondition_" + cField.fieldName}
                          readOnly={!userCanEdit}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ListItem>
                {cIndex < conditionFields.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
        <Paper sx={{ mt: 3 }}>
          <List sx={{ width: "100%" }}>
            <ListItem alignItems="flex-start">
              <Grid container justifyContent="space-between" columnSpacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 500, textTransform: "uppercase" }}>
                    Performance
                  </Typography>
                </Grid>
                <Grid item xs={false} width={asFoundWidth}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;found</Typography>
                </Grid>
                <Grid item xs={false} width={arrowWidth} />
                <Grid item xs={true}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;left</Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider component="li" />
            {performanceFields.map((pField, pIndex) => (
              <React.Fragment key={`performance-${pField.fieldName}`}>
                <ListItem>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                      <Grid item xs={5} sx={{ display: "flex" }}>
                        <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                          {pField.label}
                        </Typography>
                        {pField.toolTipContent && (
                          <IrisTooltip title={<>{pField.toolTipContent}</>}>
                            <span>
                              <InfoIcon sx={{ color: IrisColors.gray500, fontSize: "1.1rem" }} />
                            </span>
                          </IrisTooltip>
                        )}
                      </Grid>
                      <Grid item xs={false} width={asFoundWidth}>
                        <TrafficLightFormItem
                          fieldName={"asFoundPerformance_" + pField.fieldName}
                          readOnly={!userCanEdit}
                          syncFieldName={"asLeftPerformance_" + pField.fieldName}
                        />
                      </Grid>
                      <Grid item xs={false} width={arrowWidth}>
                        <ArrowForwardIcon sx={{ fontSize: "20px", color: IrisColors.gray500 }} />
                      </Grid>
                      <Grid item xs={true}>
                        <TrafficLightFormItem
                          fieldName={"asLeftPerformance_" + pField.fieldName}
                          readOnly={!userCanEdit}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ListItem>
                {pIndex < performanceFields.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Stack>
    </Paper>
  );
};
