import { useState } from "react";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { useSiteContext } from "app/contexts/SiteContext";
import { SiteSpecificMenu } from "./SiteSpecificMenu";
import { useUiThemeContext } from "app/contexts/UiThemeContext";

export const ExpandableLeftHandNavBar = () => {
   const { currentSiteWithNavigation } = useSiteContext();
   const [expanded, setExpanded] = useState<boolean>(false);
   const isDarkTheme = useUiThemeContext().navbar === "dark";

   if (!currentSiteWithNavigation) {
      return null;
   }

   const styles = {
      minHeight: "80vh",
      width: expanded ? "220px" : "48px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      overflow: "hidden",
      transition: "all 0.3s",
   };

   return (
      <>
         <Paper
            sx={{
               ...styles,
               position: "fixed",
               background: (theme) => isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper,
            }}
         >
            <SiteSpecificMenu expanded={expanded} currentSiteWithNavigation={currentSiteWithNavigation} />

            <List sx={{ width: "100%" }}>
               <DrawerExpansionButton
                  toggleExpand={() => {
                     setExpanded((e) => !e);
                  }}
                  isExpanded={expanded}
                  isDarkTheme={isDarkTheme}
               />
            </List>
         </Paper>
         {/*
            The side nav is sticky, so we need to display a hidden element in the background
            to offset the content to the right
         */}
         <Box
            sx={{
               ...styles,
               flexShrink: 0,
               width: (theme) => (expanded ? `calc(220px + ${theme.spacing(3)})` : "48px"),
            }}
         />
      </>
   );
};

const DrawerExpansionButton = (props: { toggleExpand: VoidFunction; isExpanded: boolean; isDarkTheme: boolean }) => {
   return (
      <ListItem key={"expand"} disablePadding sx={{ display: "block" }}>
         <ListItemButton
            sx={{
               px: "12px",
               minHeight: 48,
               justifyContent: "initial",
               color: (theme) => props.isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main,
            }}
            onClick={() => {
               props.toggleExpand();
            }}
         >
            <ListItemIcon sx={{
               minWidth: 0,
               color: (theme) => props.isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main,
            }}>
               {props.isExpanded ? <KeyboardDoubleArrowLeftRoundedIcon /> : <KeyboardDoubleArrowRightRoundedIcon />}
            </ListItemIcon>
            <ListItemText
               primary={"Collapse"}
               sx={{ opacity: props.isExpanded ? 1 : 0, px: 2, transition: "opacity 0.3s" }}
            />
         </ListItemButton>
      </ListItem>
   );
};
