import { Grid, Stack } from "@mui/material";
import { KpiTemplateDetail } from "kpis/models";
import { KpiSavingsSummary } from "./KpiSavingsSummary";
import { KpiSignOff } from "./KpiSignOff";

interface KpiTemplateRowHeaderProps {
  kpiTemplateDetail: KpiTemplateDetail;
}

export const KpiTemplateRowHeader = ({ kpiTemplateDetail }: KpiTemplateRowHeaderProps) => {
  // return <>{props.kpiTemplateDetail.model.name}</>;
  return (
    <Grid container>
      <Grid item xs></Grid>
      <Grid item xs="auto">
        <Stack direction="row" gap={2}>
          <KpiSignOff isSignedOff={kpiTemplateDetail.model.signedOff} />
          <KpiSavingsSummary kpi={kpiTemplateDetail} />
        </Stack>
      </Grid>
    </Grid>
  );
};
