import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useNotifications } from "app";
import { pluralize } from "common/utilities";
import { useDeleteRecordsEventRequest } from "recordsEvents/api";
import { RecordsEventDetail } from "recordsEvents/models";

interface DeleteEventDialogProps {
  onClose: () => void;
  recordsEventDetail: RecordsEventDetail;
  onDeleteEvent: () => void;
}

export const DeleteEventDialog = (props: DeleteEventDialogProps) => {
  const { onClose, recordsEventDetail, onDeleteEvent } = props;

  const deleteRecordsEventRequest = useDeleteRecordsEventRequest();
  const notifications = useNotifications();
  const existingRecordsNumber = recordsEventDetail.records?.length ?? 0;

  const handleDelete = () => {
    deleteRecordsEventRequest.call(recordsEventDetail.id).then(() => {
      onDeleteEvent();
      notifications.success("Event deleted.");
      onClose();
    });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Delete event</DialogTitle>
      <DialogContent>
        {deleteRecordsEventRequest.loading && <LinearProgress />}
        {existingRecordsNumber > 0 && (
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            The event has {existingRecordsNumber} {pluralize(existingRecordsNumber, "record")} associated with it.
            Deleting the event will remove the event from those records.
          </Alert>
        )}
        <Typography variant="body2" sx={existingRecordsNumber > 0 ? { pt: 2, pl: 6 } : null}>
          Are you sure you want to delete this event?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={deleteRecordsEventRequest.loading}>
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" variant="contained" disabled={deleteRecordsEventRequest.loading}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
