import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Button, ButtonProps } from "@mui/material";

interface DeleteButtonProps extends Omit<ButtonProps, "children" | "variant" | "color"> {
	label?: string;
}

export const DeleteButton = (props: DeleteButtonProps) => {
	const { label = "Delete", ...buttonProps } = props;
	return (
		<Button color="error" variant="contained" {...buttonProps}>
			<DeleteRoundedIcon /> {label}
		</Button>
	);
};
