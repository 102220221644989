import * as React from "react";
import {
   FormGroup,
   Label,
   Form,
   Input,
   Badge,
   Button,
   ButtonDropdown,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
   Card,
   Collapse,
} from "reactstrap";
import { useAutosaver, useUserContext } from "app";
import _ from "lodash";
import { Strategy, MitigationType, ApplicationDetailView, MitigationView, MitigationImplementation } from "./models";
import { Asset } from "assets";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import { formatRoutePath, Routes } from "app";
import { FailureModeDisplay } from "./FailureModeDisplay";
import { MitigationDisplay } from "./MitigationDisplay";
import { AddIcon } from "icons/AddIcon";
import { MitigationImplementationsModal } from "./MitigationImplementationsModal";
import { CollapseIcon } from "icons/CollapseIcon";
import classNames from "classnames";
import { DeleteIcon } from "icons/DeleteIcon";
import { ConfirmationModal } from "common/ConfirmationModal";
import {
   useCreateApplicationFailureModeRequest,
   useCreateApplicationMitigationRequest,
   useDeleteApplicationStrategyRequest,
   useUpdateApplicationStrategyRequest,
   useUpsertApplicationStrategyMitigationImplementationsForAssetRequest,
} from "./api";
import { FunctionComponent, useEffect, useState } from "react";

interface Props {
   applicationView: ApplicationDetailView;
   strategyId: number;
   forAsset: Asset | null;
   strategyWasDeleted: () => any;
   createFailureRecord?: (failureModeId: number) => any;
}

export const StrategyDisplay: FunctionComponent<Props> = (props) => {
   const { userPermissions } = useUserContext();
   const userCanEdit = !props.forAsset && userPermissions.userCanEditApplication;

   const { applicationView } = props;
   const updateStrategyRequest = useUpdateApplicationStrategyRequest();
   const [strategy, saveStrategy, resetStrategy] = useAutosaver(
      applicationView.strategies.find((s) => s.id === props.strategyId)!,
      updateStrategyRequest.call
   );
   const saveChange = (changed: Partial<Strategy>) => saveStrategy({ ...strategy, ...changed });
   const deleteStrategyRequest = useDeleteApplicationStrategyRequest();

   const createFailureModeRequest = useCreateApplicationFailureModeRequest(applicationView.id);

   const createMitigationRequest = useCreateApplicationMitigationRequest(applicationView.id);

   const isSingle = applicationView.strategies.length === 1;

   const [expanded, setExpanded] = useState(!props.forAsset);
   const [addMitigationDropdownOpen, setAddMitigationDropdownOpen] = useState(false);
   const [configurationModalOpen, setConfigurationModalOpen] = useState(false);
   const [deleting, setDeleting] = useState(false);
   const [expandedChildType, setExpandedChildType] = useState<"FailureMode" | "Mitigation" | null>(null);
   const [expandedChildId, setExpandedChildId] = useState<number | null>(null);
   const [deletedFailureModeIds, setDeletedFailureModeIds] = useState<number[]>([]);
   const [deletedMitigationIds, setDeletedMitigationIds] = useState<number[]>([]);

   const strategyFailureModes = applicationView.failureModes.filter(
      (fm) => fm.strategyId === strategy.id && deletedFailureModeIds.indexOf(fm.id) === -1
   );
   const strategyMitigations = applicationView.mitigations.filter(
      (m) => m.strategyId === strategy.id && deletedMitigationIds.indexOf(m.id) === -1
   );

   useEffect(
      () => {
         resetStrategy(applicationView.strategies.find((s) => s.id === props.strategyId)!);
      },
      applicationView.model.assets.map((a) => a.id)
   );

   const labelWithAssetLink = (props: any) => {
      // The option data is available on the props, but it's undocumented. https://github.com/JedWatson/react-select/issues/2553#issuecomment-385612099
      const asset = (props as any).data;
      return (
         <components.MultiValueLabel {...props}>
            <Link
               to={formatRoutePath(Routes.Asset, { siteId: applicationView.siteId, id: asset.id })}
               className="tag"
               target="_blank"
            >
               <div title={asset.serviceDescription}>{asset.tag}</div>
            </Link>
         </components.MultiValueLabel>
      );
   };

   const toggleExpandedChild = (childType: "FailureMode" | "Mitigation", failureModeId: number) => {
      if (expandedChildType !== childType || expandedChildId !== failureModeId) {
         setExpandedChildType(childType);
         setExpandedChildId(failureModeId);
      } else {
         setExpandedChildType(null);
         setExpandedChildId(null);
      }
   };

   return (
      <Card body className={classNames("strategy", { collapsed: !expanded })}>
         <div
            className={classNames("section-title strategy-section-title", {
               "collapse-toggle": props.forAsset,
               collapsed: !expanded,
            })}
            onClick={props.forAsset ? () => setExpanded(!expanded) : undefined}
         >
            {props.forAsset && <CollapseIcon expanded={expanded} />}
            <div>Asset strategy</div>
            {!isSingle && (
               <>
                  {!userCanEdit ? (
                     <div className="strategy-name">{strategy.name}</div>
                  ) : (
                     <Form className="strategy-name hover-borders">
                        <Input
                           bsSize="lg"
                           className={classNames({
                              empty: !strategy.name || !strategy.name.trim(),
                           })}
                           value={strategy.name}
                           onChange={(e) => {
                              saveChange({
                                 name: e.target.value,
                              });
                           }}
                           placeholder="Enter strategy name"
                        />
                     </Form>
                  )}
               </>
            )}

            <div className="gap" />

            {userCanEdit && !isSingle && (
               <>
                  <Button
                     className="delete-button"
                     onClick={() => setDeleting(true)}
                     disabled={strategy.assets.length > 0}
                     title={
                        strategy.assets.length > 0
                           ? "Remove all assets from the strategy before deleting it."
                           : undefined
                     }
                  >
                     <DeleteIcon />
                  </Button>

                  {deleting && (
                     <ConfirmationModal
                        title="Delete strategy?"
                        body="Are you sure you want to delete this strategy?"
                        show={deleting}
                        cancel={() => setDeleting(false)}
                        confirm={async () => {
                           setDeleting(false);
                           deleteStrategyRequest.call(strategy.id).then(() => {
                              setDeleting(false);
                              props.strategyWasDeleted();
                           });
                        }}
                     />
                  )}
               </>
            )}
         </div>
         <Collapse isOpen={expanded}>
            {userCanEdit && (
               <FormGroup>
                  <Label>Assets</Label>
                  {userCanEdit && !props.forAsset ? (
                     <Select
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        value={_.orderBy(strategy.assets, (sa) => sa.tag)}
                        onChange={(assets) => {
                           saveChange({ assets: assets.map((a) => a) });
                        }}
                        getOptionLabel={(asset) => asset.tag}
                        getOptionValue={(asset) => asset.id.toString()}
                        options={applicationView.model.assets}
                        placeholder={
                           applicationView.model.assets.length > 0
                              ? "Select assets"
                              : "Add assets to the application before assigning them to strategies."
                        }
                        components={{ MultiValueLabel: labelWithAssetLink }}
                        backspaceRemovesValue={false}
                     />
                  ) : (
                     <div className="assets">
                        {strategy.assets.map((sa) => (
                           <Link key={sa.id} to={formatRoutePath(Routes.Asset, sa)}>
                              <div title={sa.serviceDescription}>{sa.tag}</div>
                           </Link>
                        ))}
                     </div>
                  )}
               </FormGroup>
            )}

            <div className="failure-mode-section">
               <div className="subheading">
                  <div>Failure modes</div>
                  <div className="gap" />
                  {!props.forAsset && userPermissions.userCanEditApplication && (
                     <Button
                        size="sm"
                        onClick={async () => {
                           createFailureModeRequest.call({
                              name: "",
                              strategyId: strategy.id,
                              potentialEffect: "",
                              potentialRootCause: "",
                              severity: null,
                              unmitigatedProbability: null,
                              mitigatedProbability: null,
                              detectionAbility: null,
                              contingencyPlan: "",
                           });
                        }}
                     >
                        <AddIcon />
                        <span>Add failure mode</span>
                     </Button>
                  )}
               </div>
               {_.orderBy(strategyFailureModes, (s) => s.id || Number.MAX_VALUE).map((fm) => (
                  <FailureModeDisplay
                     key={fm.id}
                     applicationView={applicationView}
                     failureModeView={fm}
                     strategy={strategy}
                     forAsset={props.forAsset}
                     readOnly={!userCanEdit}
                     expanded={expandedChildType === "FailureMode" && expandedChildId === fm.id}
                     toggleExpanded={() => toggleExpandedChild("FailureMode", fm.id)}
                     failureModeDeleted={() => setDeletedFailureModeIds(deletedFailureModeIds.concat(fm.id))}
                     createFailureRecord={props.createFailureRecord}
                  />
               ))}
            </div>

            <div className="mitigations">
               <div className="subheading">
                  <div>Mitigations</div>
                  <div className="gap" />
                  <div className="commands">
                     {!props.forAsset && (
                        <Button
                           color="secondary"
                           size="sm"
                           onClick={() => setConfigurationModalOpen(true)}
                           disabled={strategyMitigations.length === 0 || strategy.assets.length === 0}
                        >
                           Implementations...
                        </Button>
                     )}
                     {!props.forAsset && userPermissions.userCanEditApplication && (
                        <ButtonDropdown
                           isOpen={addMitigationDropdownOpen}
                           toggle={() => setAddMitigationDropdownOpen(!addMitigationDropdownOpen)}
                           size="sm"
                        >
                           <DropdownToggle caret disabled={strategyFailureModes.length === 0}>
                              <AddIcon />
                              <span>Add mitigation</span>
                           </DropdownToggle>
                           <DropdownMenu>
                              <DropdownItem
                                 onClick={() => {
                                    createMitigationRequest.call({
                                       strategyId: strategy.id,
                                       type: MitigationType.Alert,
                                       name: "",
                                    });
                                 }}
                              >
                                 <Badge color="alert-mitigation" className="mitigation-type-badge" />
                                 Alert
                              </DropdownItem>
                              <DropdownItem
                                 onClick={() => {
                                    createMitigationRequest.call({
                                       strategyId: strategy.id,
                                       type: MitigationType.Diagnostic,
                                       name: "",
                                    });
                                 }}
                              >
                                 <Badge color="diagnostic-mitigation" className="mitigation-type-badge" />
                                 Diagnostic
                              </DropdownItem>
                              <DropdownItem
                                 onClick={() => {
                                    createMitigationRequest.call({
                                       strategyId: strategy.id,
                                       type: MitigationType.FieldWork,
                                       name: "",
                                    });
                                 }}
                              >
                                 <Badge color="field-work-mitigation" className="mitigation-type-badge" />
                                 Field work
                              </DropdownItem>
                              <DropdownItem
                                 onClick={() => {
                                    createMitigationRequest.call({
                                       strategyId: strategy.id,
                                       type: MitigationType.Proactive,
                                       name: "",
                                    });
                                 }}
                              >
                                 <Badge color="proactive-mitigation" className="mitigation-type-badge" />
                                 Proactive modification
                              </DropdownItem>
                           </DropdownMenu>
                        </ButtonDropdown>
                     )}
                  </div>
               </div>
               <StrategyMitigations
                  expandedId={expandedChildType === "Mitigation" ? expandedChildId : null}
                  toggleExpanded={(mitigationId) => toggleExpandedChild("Mitigation", mitigationId)}
                  applicationView={applicationView}
                  forAsset={props.forAsset}
                  strategyMitigations={strategyMitigations}
                  userCanEdit={userCanEdit}
                  onDeletedMitigation={(mitigationId) =>
                     setDeletedMitigationIds(deletedMitigationIds.concat(mitigationId))
                  }
               />

               {configurationModalOpen && (
                  <MitigationImplementationsModal
                     close={() => setConfigurationModalOpen(false)}
                     applicationView={applicationView}
                     strategyId={strategy.id}
                     mitigations={strategyMitigations}
                     assets={strategy.assets}
                  />
               )}
            </div>
         </Collapse>
      </Card>
   );
};

interface StrategyMitigationsProps {
   applicationView: ApplicationDetailView;
   strategyMitigations: MitigationView[];
   forAsset: Asset | null;
   userCanEdit: boolean;
   expandedId: number | null;
   onDeletedMitigation: (mitigationId: number) => any;
   toggleExpanded: (mitigationId) => any;
}

const StrategyMitigations: FunctionComponent<StrategyMitigationsProps> = (props) => {
   const { strategyMitigations } = props;

   const upsertAssetMitigationImplementations = useUpsertApplicationStrategyMitigationImplementationsForAssetRequest(
      props.applicationView.id,
      props.forAsset?.id ?? 0
   );
   const [assetMitigationImplementations, saveAssetMitigationImplementations] = useAutosaver(
      strategyMitigations.flatMap((sm) => sm.implementations).filter((mi) => mi.assetId === props.forAsset?.id),
      (mis) => upsertAssetMitigationImplementations.call(mis.map((mi) => mi.mitigationId))
   );
   const toggleImplemented = (implementation: MitigationImplementation) => {
      if (assetMitigationImplementations.findIndex((m) => m.mitigationId === implementation.mitigationId) > -1) {
         saveAssetMitigationImplementations(
            assetMitigationImplementations.filter((m) => m.mitigationId !== implementation.mitigationId),
            true
         );
      } else {
         saveAssetMitigationImplementations(assetMitigationImplementations.concat(implementation), true);
      }
   };

   const mitigationTypes = _.orderBy(_.uniq(strategyMitigations.map((m) => m.model.type)), (t) => t);

   return (
      <>
         {mitigationTypes.map((mitigationType) => (
            <div className="mitigation-group">
               <div className="subsubheading">
                  <Badge color="alert-mitigation" className="mitigation-type-badge" />
                  {mitigationType === MitigationType.Proactive ? "Proactive mitigation" : mitigationType}
               </div>
               {strategyMitigations
               .filter((mitigation) => mitigation.model.type == mitigationType)
               .map((mitigation) => (
                  <MitigationDisplay
                     key={mitigation.id}
                     mitigationView={mitigation}
                     applicationView={props.applicationView}
                     forAsset={props.forAsset}
                     readOnly={!props.userCanEdit}
                     expanded={props.expandedId === mitigation.id}
                     toggleExpanded={() => props.toggleExpanded(mitigation.id)}
                     mitigationDeleted={() => props.onDeletedMitigation(mitigation.id)}
                     implementedForAsset={
                        props.forAsset
                           ? assetMitigationImplementations.find((ami) => ami.mitigationId === mitigation.id)
                                ?.implemented
                           : null
                     }
                     toggleImplementedForAsset={
                        !!props.forAsset
                           ? () =>
                                toggleImplemented(
                                   assetMitigationImplementations.find((ami) => ami.mitigationId === mitigation.id) ?? {
                                      assetId: props.forAsset!.id,
                                      implemented: new Date(),
                                      mitigationId: mitigation.id,
                                   }
                                )
                           : null
                     }
                  />
               ))}
            </div>
         ))}
      </>
   );
};
