import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useCallback } from "react";
import { EditFieldDialog } from "../../app/mui/common/dialogs/EditFieldDialog";
import { useForm } from "react-hook-form";
import { useUserContext } from "app/contexts/UserContext";
import { PackagedSolutionDetailViewModel } from "@bluemarvel/iris-common/model";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { PackagedSolutionIcon } from "icons/PackagedSolutionIcon";
import React from "react";
import { useUpdatePackageSolutionsRequest } from "packagedSolution/api";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";

interface IPackagedSolutionActionsProps {
  packagedSolutionDetail: PackagedSolutionDetailViewModel;
}

type DialogActionsType = "editName" | null;
const validDialogActions = ["editName"];

const pageActions = (packagedSolution: PackagedSolutionDetailViewModel) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <PackagedSolutionIcon />,
      label: "Edit name",
      action: () => actionDispatcher.dispatch("editName"),
      hidden: !userPermissions.userCanEditPackagedSolution,
    },
  ]);

export const PackagedSolutionActions = (props: IPackagedSolutionActionsProps) => {
  const { packagedSolutionDetail } = props;
  const packagedSolution = packagedSolutionDetail.model;
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(props.packagedSolutionDetail), actionDispatcher);
  const { userPermissions } = useUserContext();
  const showActions = userPermissions.userCanEditRecords;
  const defaultValues = makeSafeForReactHookForm(packagedSolution);

  const updatePackagedSolutionName = useUpdatePackageSolutionsRequest();
  const methods = useForm({ defaultValues });
  const updateName = () => {
    methods.handleSubmit((values) => {
      updatePackagedSolutionName.call({ ...packagedSolution, name: values.name }).then(() => {
        closeDialog();
      });
    })();
  };

  const handleAction = useCallback((action: string) => {
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const [openDialog, setOpenDialog] = React.useState<DialogActionsType>(null);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  return (
    <>
      {showActions && <ActionsDropdownMenu primary actions={actions} />}

      {openDialog === "editName" && (
        <EditFieldDialog
          methods={methods}
          saveChanges={updateName}
          fieldName="name"
          dialogTitle="Change Packaged Solution name"
          label="Packaged Solution Name"
          confirmButtonText="Change name"
          onClose={closeDialog}
        />
      )}
    </>
  );
};
