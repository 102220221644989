import React, { useMemo } from "react";
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IrisColors } from "./theme";
import { ActionDispatcherApi } from "./ActionDispatcher";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PendingIcon from "@mui/icons-material/Pending";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";

type ActionItem = {
  icon: React.ReactNode;
  label: string;
  action?: () => void;
  href?: string;
  disabled?: boolean;
  hidden?: boolean;
};

export type ActionMenuActionType = ActionItem | React.ReactElement<any>;
interface ActionsDropdownMenuProps {
  label?: string;
  actions: ActionMenuActionType[];
  primary?: boolean;
  disabled?: boolean;
  rowActionMenu?: boolean;
}

export const ActionsDropdownMenu = (props: ActionsDropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.rowActionMenu) {
      event.stopPropagation();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.rowActionMenu ? (
        <IconButton aria-label="actions" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      ) : (
        <>
          {isMobile ? (
            <IconButton disabled={props.disabled} color={"secondary"} onClick={handleClick}>
              {props.primary ? (
                <PendingIcon sx={{ fontSize: "1.7em" }} />
              ) : (
                <PendingOutlinedIcon sx={{ fontSize: "1.7em" }} />
              )}
            </IconButton>
          ) : (
            <Button
              disabled={props.disabled}
              variant={props.primary ? "contained" : "outlined"}
              color={"secondary"}
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ justifyContent: "space-between", width: "200px" }}
            >
              {props.label ?? "Actions"}
            </Button>
          )}
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 200,
          },
        }}
      >
        <MenuList>
          {props.actions
            .map((item, index) => {
              if (React.isValidElement(item)) {
                return <React.Fragment key={index}>{item}</React.Fragment>;
              }

              const action = item as ActionItem;
              if (!!action.hidden) return null;

              const menuItemProps = action.action
                ? {
                    onClick: (event) => {
                      if (props.rowActionMenu) {
                        event.stopPropagation();
                      }
                      action.action?.();
                      handleClose();
                    },
                  }
                : action.href
                ? { href: action.href }
                : {};

              return (
                <MenuItem key={action.label} disabled={action.disabled} {...menuItemProps}>
                  <ListItemIcon sx={{ svg: { fill: IrisColors.legacyTertiaryGray, width: "24px", height: "24px" } }}>
                    {action.icon}
                  </ListItemIcon>
                  <ListItemText>{action.label}</ListItemText>
                </MenuItem>
              );
            })
            .filter((a) => !!a)}
        </MenuList>
      </Menu>
    </>
  );
};

type creatorFunction = (actionDispatcher: ActionDispatcherApi) => (ActionItem | React.ReactElement<any>)[];

export const createActionMenu = (fn: creatorFunction) => {
  return fn;
};

export const useActionMenu = (fn: creatorFunction, api: ActionDispatcherApi) => {
  const getActionsFunction = useMemo(() => fn(api), []);
  return getActionsFunction;
};
