import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { LinearProgress } from "@mui/material";

interface ConfirmationDialogProps extends DialogProps {
  title: string;
  open: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirm: () => void;
  cancel: () => void;
  noDanger?: boolean;
  loading?: boolean;
}

function ConfirmationDialog(props: React.PropsWithChildren<ConfirmationDialogProps>) {
  const {
    title,
    open,
    confirmButtonText = "Ok",
    cancelButtonText = "Cancel",
    confirm,
    cancel,
    noDanger,
    loading,
    children,
    ...dialogProps
  } = props;

  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }} maxWidth="xs" open={open} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {loading && <LinearProgress sx={{ mb: "5px" }} />}
        {children}
      </DialogContent>
      <DialogActions>
        {!!cancel && (
          <Button autoFocus disabled={loading} onClick={cancel}>
            {cancelButtonText}
          </Button>
        )}
        {!!confirm && (
          <Button disabled={loading} color={noDanger ? "primary" : "error"} onClick={confirm} variant="contained">
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
