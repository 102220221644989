import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { GridAlignment, GridColDef } from "@mui/x-data-grid";

/**
 * This code is taken (and slightly modified) from the example in the docs:
 * https://mui.com/x/react-data-grid/column-definition/#expand-cell-renderer
 *
 * Our modifications allow us to still use a custom renderer, and this
 * component becomes more of a wrapper cell that will be used on
 * everything by default.
 */

interface GridCellExpandProps {
   children: React.ReactNode;
   width: number;
   textAlign: GridAlignment;
}

function isOverflown(element: Element): boolean {
   return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const MSDelayToShowPopper = 700;

const GridCellExpand = React.memo(function GridCellExpand(props: GridCellExpandProps) {
   const { width, children } = props;
   const wrapper = React.useRef<HTMLDivElement | null>(null);
   const cellDiv = React.useRef(null);
   const cellValue = React.useRef(null);
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const [showFullCell, setShowFullCell] = React.useState(false);
   const [showPopper, setShowPopper] = React.useState(false);
   const showPopperTimeout = React.useRef<any>();

   const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current!);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      showPopperTimeout.current = setTimeout(() => {
         setShowFullCell(true);
      }, MSDelayToShowPopper);
   };

   const handleMouseLeave = () => {
      clearTimeout(showPopperTimeout.current);
      setShowFullCell(false);
   };

   React.useEffect(() => {
      if (!showFullCell) {
         return undefined;
      }

      function handleKeyDown(nativeEvent: KeyboardEvent) {
         // IE11, Edge (prior to using Bink?) use 'Esc'
         if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
            setShowFullCell(false);
         }
      }

      document.addEventListener("keydown", handleKeyDown);

      return () => {
         document.removeEventListener("keydown", handleKeyDown);
      };
   }, [setShowFullCell, showFullCell]);

   React.useEffect(() => {
      return () => clearTimeout(showPopperTimeout.current);
   }, []);

   return (
      <Box
         ref={wrapper}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         sx={{
            alignItems: "center",
            lineHeight: "24px",
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
         }}
      >
         <Box
            ref={cellDiv}
            sx={{
               height: "100%",
               width,
               display: "block",
               position: "absolute",
               top: 0,
               pointerEvents: "none",
            }}
         />
         <Box
            ref={cellValue}
            sx={{
               whiteSpace: "nowrap",
               overflow: "hidden",
               textOverflow: "ellipsis",
               textAlign: props.textAlign,
               width: "100%",
            }}
         >
            {children}
         </Box>
         {showPopper && (
            <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} style={{ width, marginLeft: -17 }}>
               <Paper elevation={1} style={{ minHeight: wrapper.current!.offsetHeight - 3 }}>
                  <Typography variant="body2" style={{ padding: 8 }}>
                     {children}
                  </Typography>
               </Paper>
            </Popper>
         )}
      </Box>
   );
});

type RenderCellType = Pick<GridColDef, "renderCell">["renderCell"];
const defaultRenderFunction: RenderCellType = (params) => (
   <>{params.formattedValue ?? params.value?.toString() ?? ""}</>
);

export const renderCellWithOverflowSupport = (renderCell: RenderCellType | null): RenderCellType => {
   const innerRenderFunction = renderCell ?? defaultRenderFunction;

   return (params) => (
      <GridCellExpand
         width={params.colDef.computedWidth + 24}
         textAlign={params.colDef.type === "number" ? "right" : "left"}
      >
         {innerRenderFunction(params)}
      </GridCellExpand>
   );
};
