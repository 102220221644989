import { Autocomplete, AutocompleteProps } from "@mui/material";
import { IrisColors, theme } from "app/mui/theme";

interface ThemeAutoCompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  isDarkTheme: boolean;
}

const darkThemeStyle = {
  "+ .MuiAutocomplete-popper": {
    ".MuiAutocomplete-listbox": {
      padding: 0,
    },
    ".MuiAutocomplete-listbox::-webkit-scrollbar": {
      display: "none",
    },
    ".MuiAutocomplete-option": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
    },
    ".MuiAutocomplete-option.Mui-focused": {
      backgroundColor: IrisColors.gray500,
    },
    ".MuiAutocomplete-option[aria-selected='true'], .MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
      backgroundColor: IrisColors.gray600,
    },
  },
  "& .MuiOutlinedInput-root": {
    color: theme.palette.background.paper,
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: IrisColors.gray600,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.background.paper,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.background.paper,
  },
  "& .MuiOutlinedInput-input::selection": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: theme.palette.background.paper,
  },
};

export const ThemeAutoComplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: ThemeAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const { sx, isDarkTheme, ...rest } = props;
  const themeStyle = isDarkTheme ? darkThemeStyle : {};
  return <Autocomplete sx={{ ...themeStyle, ...sx }} {...rest} />;
};
