import { BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { FeatureFlagName, useFeatureEnabled } from "common";
import { RecordDetailView } from "records/models";
import { GenerateReportDialogV1 } from "./GenerateReportV1";
import { GenerateReportDialogV2 } from "./GenerateReportV2";

interface GenerateReportDialogProps extends BaseDialogProps {
  record: RecordDetailView;
}

export const GenerateReportDialog = (props: GenerateReportDialogProps) => {
  const useNodeReports = useFeatureEnabled(FeatureFlagName.UseNodeRecordReport);

  if (useNodeReports) {
    return <GenerateReportDialogV2 {...props} />;
  }
  return <GenerateReportDialogV1 {...props} />;
};
