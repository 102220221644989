import * as React from "react";
import { User } from "users";

interface Props {
   user: User;
   hideTooltip?: boolean;
   tooltip?: string;
}

export class Avatar extends React.PureComponent<Props> {
   public render() {
      const title = this.props.hideTooltip ? {} : { title: this.props.tooltip || this.props.user.fullName };
      return this.props.user.picture ? (
         <img className="avatar" src={this.props.user.picture} {...title} alt="avatar" />
      ) : (
         <div className="avatar missing-avatar">
            <span>?</span>
         </div>
      );
   }
}
