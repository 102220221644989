import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { RecordDetailView } from "records/models";
import { FormProvider, useForm } from "react-hook-form";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { generateReportUrl, RecordReportParams } from "common/reports";
import { useAppSettingsContext } from "app/contexts/AppSettingsContext";

interface GenerateReportDialogProps extends BaseDialogProps {
  record: RecordDetailView;
}

export const GenerateReportDialogV1 = (props: GenerateReportDialogProps) => {
  const { appSettings } = useAppSettingsContext();

  const methods = useForm<RecordReportParams>({
    defaultValues: {
      id: props.record.id,
      pdf: true,
      appendPdfAttachments: true,
      includeHistory: true,
      includePictures: true,
      includeAssemblyPicture: true,
      includeKpis: true,
      includeCompletedRecommendations: true,
    },
  });

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6">Generate report </Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={0}>
            <CheckboxFormItem fieldName="includePictures" label="Include pictures" />
            <CheckboxFormItem fieldName="includeAssemblyPicture" label="Include assembly picture" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF attachments" />
            <CheckboxFormItem fieldName="includeKpis" label="Include KPI data" />
            <CheckboxFormItem fieldName="includeCompletedRecommendations" label="Include completed recommendations" />
            <CheckboxFormItem fieldName="includeHistory" label="Include history" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!appSettings}
            onClick={() => {
              const reportParams = methods.getValues();
              const reportUrl = generateReportUrl("record", reportParams, appSettings!);
              window.open(reportUrl!, "_blank");
              props.onClose();
            }}
          >
            Generate <OpenInNewIcon sx={{ pl: 1, width: "1rem" }} />
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
