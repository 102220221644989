import { GridActionsCellItem, GridActionsCellItemProps } from "@mui/x-data-grid-pro";
import { JSXElementConstructor, ReactElement } from "react";

export type TableRowAction = GridActionsCellItemProps & {};

export const CreateGridActionCell = (
   tableRowAction: TableRowAction
): React.ReactElement<GridActionsCellItemProps> | null =>
   tableRowAction.hidden
      ? null
      : ((<GridActionsCellItem {...(tableRowAction as any)} />) as React.ReactElement<GridActionsCellItemProps>);
