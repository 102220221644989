import { TextField, TextFieldProps } from "@mui/material";
import { FormItemLayout, FormItemLayoutProps } from "app/mui/forms/FormItemLayout";
import { Controller } from "react-hook-form";
import { ReadonlyFormItem } from "../ReadonlyFormItem";
import { BaseFormItemProps } from "../Base/BaseFormItemProps";
import { ReactNode } from "react";

export interface ControlledTextFieldFormItemProps extends BaseFormItemProps {
  textFieldProps?: TextFieldProps;
  readOnly?: boolean;
  alwaysShowInput?: boolean;
  children?: ReactNode;
}

export const ControlledTextFieldFormItem = (props: ControlledTextFieldFormItemProps) => {
  const { fieldName, textFieldProps, readOnly, alwaysShowInput, ...formItemLayoutProps } = props;

  return props.readOnly && !alwaysShowInput ? (
    <ReadonlyFormItem label={props.label || ""} fieldName={props.fieldName} />
  ) : (
    <Controller
      name={fieldName ?? ""}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormItemLayout
          controlled
          error={error?.message}
          fieldName={props.fieldName}
          label={props.label}
          hint={props.hint}
          {...formItemLayoutProps}
        >
          {(inputProps) => (
            <>
              <TextField
                {...props.textFieldProps}
                {...inputProps}
                error={!!error}
                onChange={onChange}
                disabled={readOnly}
                value={value ?? ""}
              />
              {props.children}
            </>
          )}
        </FormItemLayout>
      )}
    />
  );
};
