import { IrisTooltip } from "app";
import { dateFormat } from "common";
import moment from "moment";
import { FunctionComponent } from "react";
import _ from "lodash";
import { IrisColors } from "app/mui/theme";
import InfoIcon from "@mui/icons-material/Info";

interface EffectiveRateTooltipProps {
  rate: number | null;
  rateType: "Specialist" | "Site personnel";
  rateEffectiveDate: Date | null;
  rateEffectiveDateType: string | null;
  isKpiTemplate: boolean;
}

const kpiTemplateAddition = ` Record KPIs using this template may have different rates.`;

const getStandardContent = (props: EffectiveRateTooltipProps) => {
  const preamble = !!props.rate ? "This is the" : "There is no";
  return `${preamble} ${_.lowerFirst(props.rateType)} labour rate configured at the site level for ${moment(
    props.rateEffectiveDate
  ).format(dateFormat)} (${_.lowerFirst(
    props.rateEffectiveDateType ?? ""
  )}). Please contact your site administrator to make changes.${props.isKpiTemplate ? kpiTemplateAddition : ""}`;
};

export const EffectiveRateTooltip: FunctionComponent<EffectiveRateTooltipProps> = (props) => {
  const content = !!props.rateEffectiveDate
    ? getStandardContent(props)
    : `This rate was manually set on the KPI prior to centralized site administration of labour rates. Please contact your site administrator to make changes.`;

  return (
    <IrisTooltip title={content}>
      <span className="kpi-help-icon">
        <InfoIcon sx={{ color: IrisColors.gray500 }} />
      </span>
    </IrisTooltip>
  );
};
