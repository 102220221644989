export enum RecordType {
  Reactive = "Reactive",
  Preventative = "Preventative",
  Predictive = "Predictive",
  Proactive = "Proactive",
  CandSU = "C&SU",
  Activity = "Activity",
  ValveDiagnostic = "Valve diagnostic",
  FieldInspection = "Field inspection",
  OutageScope = "Outage scope",
  VibrationAnalysis = "Vibration analysis",
  EquipmentHealth = "Equipment health",
}

export const RecordTypes: RecordType[] = [
  RecordType.Activity,
  RecordType.CandSU,
  RecordType.FieldInspection,
  RecordType.OutageScope,
  RecordType.Predictive,
  RecordType.Preventative,
  RecordType.Proactive,
  RecordType.Reactive,
  RecordType.ValveDiagnostic,
  RecordType.VibrationAnalysis,
  RecordType.EquipmentHealth,
];
