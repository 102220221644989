import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { Container, Grid, Skeleton } from "@mui/material";
import Fuse from "fuse.js";
import { SearchableSiteDigestWithCustomer } from "sites/models";
import { CustomerAndSiteSearchFilter } from "./CustomerAndSiteFilterBox";
import { WithSiteRouting } from "app/SiteNavigator";
import { SiteCard } from "./SiteCard";

interface SitesListProps {
   sites: WithSiteRouting<SearchableSiteDigestWithCustomer>[];
   isLoading: boolean;
}

export const SitesList = React.memo((props: SitesListProps) => {
   const fuse = useRef<Fuse<WithSiteRouting<SearchableSiteDigestWithCustomer>> | null>(null);

   useEffect(() => {
      fuse.current = new Fuse(props.sites, {
         keys: ["name", "customer.name", "combinedSearchName"],
         isCaseSensitive: false,
         useExtendedSearch: true,
         threshold: 0.2,
      });
   }, [props.sites]);

   const [searchTerm, setSearchTerm] = useState<string>("");

   const debouncedSearchTerm = useDebounce(searchTerm, 200);

   const filteredItems = useMemo(() => {
      return fuse.current?.search(debouncedSearchTerm);
   }, [debouncedSearchTerm]);

   const searchResults =
      filteredItems && filteredItems.length > 0 ? filteredItems : props.sites.map((s) => ({ item: s }));

   return (
      <Container>
         <CustomerAndSiteSearchFilter searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
         {props.isLoading ? (
            <SkeletonCards />
         ) : (
            <Grid container spacing={2} my={2}>
               {searchResults?.map((result) => (
                  <Grid key={result.item.id} item xs={12} sm={6} md={4} lg={3}>
                     <SiteCard key={result.item.id} site={result.item} />
                  </Grid>
               ))}
            </Grid>
         )}
      </Container>
   );
});

const SkeletonCards = () => {
   return (
      <Grid container spacing={2}>
         {[...new Array(3)].map((_, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
               <Skeleton height="276px" />
            </Grid>
         ))}
      </Grid>
   );
};

