import { ClickAwayListener, Link, Paper, Popper, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Point, ResponsiveLine } from "@nivo/line";
import { dateFormat } from "common";
import moment from "moment";
import { getColorForHealth } from "assets/mui/health/HealthBadge";
import { useState } from "react";
import { virtualElement } from "../utils";
import { ValveHealthChartDataModel } from "./models";

const chartTheme = {
  axis: {
    ticks: {
      text: {
        fontSize: "15px",
      },
    },
    domain: {
      line: {
        stroke: "#777777",
        strokeWidth: 2,
      },
    },
  },
};

interface ValveHealthChartProps {
  data: { id: string; data: ValveHealthChartDataModel[] }[];
  startDate: Date;
  endDate: Date;
}

export const ValveHealthChart = ({ data, startDate, endDate /* see data tab */ }: ValveHealthChartProps) => {
  const theme = useTheme();

  const [focusedTooltip, setFocusedTooltip] = useState<{ point: Point; virtualElement: any }>();

  const handlePointClicked = (point: Point, event: React.MouseEvent) => {
    setFocusedTooltip({ point, virtualElement: virtualElement(event.clientX, event.clientY) });
  };

  return (
    <>
      <ResponsiveLine
        theme={chartTheme}
        data={data}
        margin={{ top: 20, bottom: 40, left: 10, right: 0 }}
        enableGridX={false}
        enableGridY={false}
        curve="monotoneX"
        xScale={{
          type: "time",
          min: startDate,
          max: endDate,
        }}
        yScale={{
          type: "linear",
          min: 0,
          max: 100,
          stacked: false,
          reverse: false,
        }}
        axisLeft={null}
        axisBottom={{
          tickSize: 0,
          tickValues: [startDate, endDate], // only show first and last tick
          format: (value) => moment(value).year().toString(),
          tickPadding: 10,
          ticksPosition: "before",
          renderTick: ({ value, format, tickIndex, ...rest }) => {
            return (
              <text
                textAnchor={tickIndex === 0 ? "start" : "end"}
                style={{ transform: "translateY(20px)" }}
                x={rest.x}
                y={rest.y}
                fill="#777777"
              >
                {format?.(value)}
              </text>
            );
          },
        }}
        colors={() => "#000000"} // line color
        pointSymbol={(e) => {
          const healthColor = getColorForHealth(e?.datum?.category);
          const color = theme.palette[healthColor ?? "primary"].main;

          if (e.datum.type === "asleft") {
            return (
              <circle cx="0" cy="0" r="5" stroke={color} strokeWidth="2" fill={color} style={{ cursor: "pointer" }} />
            );
          } else {
            return (
              <circle
                cx="0"
                cy="0"
                r="5"
                stroke={color}
                strokeWidth="2"
                fill={"transparent"}
                style={{ cursor: "pointer" }}
              />
            );
          }
        }}
        pointSize={10}
        pointBorderWidth={3}
        useMesh={true}
        enableCrosshair={false}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            data: [
              {
                id: "asfound",
                label: "as found",
              },
              {
                id: "asleft",
                label: "as left",
              },
            ],

            symbolShape: (params) => {
              return params.id === "asleft" ? (
                <circle
                  cx={params.x}
                  cy={params.y}
                  r={5}
                  transform={`translate(5, 5)`}
                  stroke="black"
                  strokeWidth="2"
                  fill={"black"}
                />
              ) : (
                <circle
                  cx={params.x}
                  cy={params.y}
                  r={5}
                  transform={`translate(5, 5)`}
                  stroke={"black"}
                  strokeWidth="2"
                  fill={"white"}
                />
              );
            },
            justify: false,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            translateY: 40,
          },
        ]}
        onClick={handlePointClicked}
        tooltip={({ point }) => {
          return <>{!focusedTooltip && <Tooltip point={point} />} </>;
        }}
      />
      {focusedTooltip && (
        <ClickAwayListener
          onClickAway={() => {
            setFocusedTooltip(undefined);
          }}
        >
          <Popper
            open={Boolean(focusedTooltip)}
            anchorEl={focusedTooltip?.virtualElement}
            placement={"left"}
            sx={{ pb: 2 }}
          >
            <Tooltip point={focusedTooltip.point} />
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

interface TooltipProps {
  point: Point;
}

const Tooltip = (props: TooltipProps) => {
  const theme = useTheme();
  const data = props.point.data as unknown as ValveHealthChartDataModel;
  const healthColor = getColorForHealth(data.category);
  const color = theme.palette[healthColor ?? "primary"].main;

  return (
    <Paper
      sx={{ p: 1, fontFamily: theme.typography.fontFamily }}
      style={{ position: "absolute", top: "0", left: "0", transform: "translate(-100%, -100%)", width: "max-content" }}
    >
      <Stack>
        <Typography fontWeight="medium">
          Record Date:{" "}
          <Link component={RouterLink} to={(props.point.data as any).link} color="secondary">
            {moment(props.point.data.x).format(dateFormat)}
          </Link>
        </Typography>
        {(props.point.data as any).type === "asfound" ? (
          <Typography fontWeight="medium">
            As found:{" "}
            <Typography component="span" fontWeight="bold" color={color}>
              {Math.round(Number(props.point.data.y))}%
            </Typography>
          </Typography>
        ) : (
          <Typography fontWeight="medium">
            As left:{" "}
            <Typography component="span" fontWeight="bold" color={color}>
              {Math.round(Number(props.point.data.y))}%
            </Typography>
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};
