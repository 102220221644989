import { useEffect, useRef, useState, useCallback } from "react";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import {
  useCreateCustomDataViewRequest,
  useDeleteCustomDataViewRequest,
  useGetCustomDataViewsRequest,
  useUpdateCustomDataViewRequest,
} from "./tabViews/api";
import {
  createDefaultEmptyTableState,
  CustomDataView,
  CustomDataViewScopes,
  CustomDataViewType,
} from "./tabViews/models";
import { useDirtyStateTracker } from "./useDirtyStateTracker";
import { useSearchParams } from "react-router-dom";
import { StateTransformer } from "./queryParamSync/stateTransformer";
import { useSiteContext } from "app/contexts";
import { useDataGridExtensionsContext } from "app/contexts/DataGridExtensions";

export type TabsApi = ReturnType<typeof useTabbedViews>;

export const useTabbedViews = (type: CustomDataViewType) => {
  const apiRef = useGridApiContext();
  const [searchParams] = useSearchParams();
  const { currentSite } = useSiteContext();
  const [viewIndex, setViewIndex] = useState<number>();
  const { isDirty, setOriginalView, recalculateDirtyState } = useDirtyStateTracker(apiRef);
  const dataGridExtensions = useDataGridExtensionsContext();

  const { data: views, loading: viewsLoading } = useGetCustomDataViewsRequest(type, currentSite);
  const createNewViewRequest = useCreateCustomDataViewRequest();
  const updateViewRequest = useUpdateCustomDataViewRequest();
  const deleteViewRequest = useDeleteCustomDataViewRequest();

  const newlyCreatedViewRef = useRef<CustomDataView>();

  const currentView: CustomDataView | null = views && viewIndex !== undefined ? views?.[viewIndex] : null;

  // user can only save views that are their own, not shared views
  const canSaveView: boolean = currentView ? !currentView.shared : false;

  const initialize = useCallback(() => {
    // get the tab view id from the url,
    // get the initial query value from the url
    // select the correct tab index
    // restore table state from either the url or the tab

    if (!views || views.length == 0) {
      // should never happen, but for type safety...
      return;
    }

    const initialTab = searchParams.get("view");
    const initialTableState = searchParams.get("query");

    let restorableState: object | undefined;

    if (initialTab) {
      const initialTabIndex = views.findIndex((v) => v.id.toString() === initialTab);

      if (initialTabIndex > -1) {
        setViewIndex(initialTabIndex);
        setOriginalView(views[initialTabIndex]);
        restorableState = StateTransformer.transformIncoming(views[initialTabIndex].view, apiRef);
      }

      // if the tab specified wasn't found,
      // don't select any tabs. The user may have followed a link.
      // we will show either the default table, or hopefully a query form the url
    } else {
      // if no tab was specified, find the first one.
      setViewIndex(0);
      setOriginalView(views[0]);
      restorableState = StateTransformer.transformIncoming(views[0].view, apiRef);
    }

    if (initialTableState) {
      // attempt to restore table state from url.
      // url states are altered to make them smaller, so we need to transform it back.
      restorableState = StateTransformer.transformIncoming(initialTableState, apiRef);
    }

    if (restorableState) {
      apiRef.current.restoreState(restorableState);
    }
  }, [views]);

  // when the user changes the tab, we should reflect that into the url
  // and restore the state of the new tab - we should then remove the query
  // from the url as that query no longer applies
  const changeTabView = useCallback(
    (newIndex: number) => {
      if (!views) {
        // should never happen, but for type safety...
        return;
      }

      setViewIndex(newIndex);
      setOriginalView(views[newIndex]);

      var params = new URLSearchParams(window.location.search);
      params.set("view", views[newIndex].id.toString());
      params.delete("query");
      window.history.replaceState(null, "", `?${params.toString()}`);

      Promise.resolve().then(() => {
        var newState = StateTransformer.transformIncoming(views[newIndex].view, apiRef);
        apiRef.current.restoreState(newState);
      });
    },
    [views]
  );

  const createNewView = useCallback(
    async (options: { name: string; scope: CustomDataViewScopes; viewState: string | undefined }) => {
      let siteId: number | null = null;
      let customerId: number | null = null;

      if (options.scope === CustomDataViewScopes.ThisCustomer) {
        siteId = null;
        customerId = currentSite?.customerId ?? null;
      }

      if (options.scope === CustomDataViewScopes.ThisSite) {
        siteId = currentSite?.id ?? null;
        customerId = null;
      }

      // prettier-ignore
      const view =
            options.viewState ?? StateTransformer.transformOutgoing(
               createDefaultEmptyTableState(apiRef),
               apiRef
            );

      const response = await createNewViewRequest.call({
        type: type,
        siteId: siteId,
        customerId: customerId,
        view: view,
        name: options.name,
      });

      newlyCreatedViewRef.current = response;
    },
    [createNewViewRequest]
  );

  const saveCurrentView = useCallback(async () => {
    if (!canSaveView || !currentView) {
      return;
    }

    const state = apiRef.current.exportState();
    const currentViewState = StateTransformer.transformOutgoing(state, apiRef);

    await updateViewRequest.call({
      ...currentView,
      view: currentViewState,
    });

    const newCleanState = { ...currentView, view: currentViewState };
    setOriginalView(newCleanState);
    recalculateDirtyState(newCleanState);
  }, [updateViewRequest, canSaveView, currentView]);

  const editCurrentView = useCallback(
    async (options: { name: string; scope: CustomDataViewScopes }) => {
      if (!canSaveView || !currentView) {
        return;
      }

      let siteId: number | null = null;
      let customerId: number | null = null;

      if (options.scope === CustomDataViewScopes.ThisCustomer) {
        siteId = null;
        customerId = currentSite?.customerId ?? null;
      }

      if (options.scope === CustomDataViewScopes.ThisSite) {
        siteId = currentSite?.id ?? null;
        customerId = null;
      }

      await updateViewRequest.call({
        ...currentView,
        name: options.name,
        siteId: siteId,
        customerId: customerId,
      });
    },
    [updateViewRequest, canSaveView, currentView]
  );

  const deleteCurrentView = useCallback(async () => {
    if (!canSaveView || !currentView) {
      return;
    }

    const currentViewId = currentView.id;
    await deleteViewRequest.call(currentViewId);

    // now that we've deleted this view, we need to try to nav to a new view.
    // try to find the first available view,
    changeTabView(0);
  }, [deleteViewRequest, canSaveView, currentView, views]);

  const exportView = useCallback(() => {
    const state = apiRef.current.exportState();
    return StateTransformer.transformOutgoing(state, apiRef);
  }, []);

  const resetTableState = useCallback(() => {
    if (viewIndex != undefined) {
      changeTabView(viewIndex);
    }
  }, [viewIndex]);

  useEffect(() => {
    dataGridExtensions.setIsLoading(viewsLoading);
  }, [viewsLoading]);

  useEffect(() => {
    // once the views are loaded, and a view index hasn't yet been selected,
    // we should run the init
    if (views && viewIndex === undefined) {
      initialize();
    }

    // if the newlyCreatedViewRef is populated, that means that views has
    // been updated, and we need to find the new view index specified in the
    // ref, in order to navigate to that tab correctly.
    if (views && newlyCreatedViewRef.current) {
      const index = views?.findIndex((v) => v.id === newlyCreatedViewRef.current!.id);
      changeTabView(index);

      // reset the ref.
      newlyCreatedViewRef.current = undefined;
    }
  }, [views]);

  return {
    currentView,
    views,
    viewIndex,
    isDirty,
    canSaveView,
    changeTabView,
    createNewView,
    saveCurrentView,
    editCurrentView,
    deleteCurrentView,
    resetTableState,
    exportView,
  };
};
