import { Col, Collapse, Row } from "reactstrap";
import { PODetails } from "./PODetails";
import { QuoteDetails } from "./QuoteDetails";
import { ScopeOfWorkDetails } from "./ScopeOfWorkDetails";
import { TextAreaInput } from "../../inputs/TextAreaInput";

interface RecommendedScopeOfWorkDetailsProps {
   userCanEdit: boolean;
   accepted?: boolean;
   acceptedScopeExists: boolean;
}

export const RecommendedScopeOfWorkDetails = (props: RecommendedScopeOfWorkDetailsProps) => {
   return (
      <div style={{ display: "flex" }}>
         <div className="spacer"></div>
         <div className="form-body">
            <Row style={{ padding: "1rem" }}>
               <Col>
                  <TextAreaInput
                     label="Justification"
                     field="justification"
                     userCanEdit={props.userCanEdit && !props.acceptedScopeExists}
                  />
               </Col>
            </Row>

            <ScopeOfWorkDetails userCanEdit={props.userCanEdit && !props.acceptedScopeExists} />

            <QuoteDetails userCanEdit={props.userCanEdit && !props.acceptedScopeExists} />

            <Row style={{ padding: "1rem" }}>
               <Col>
                  <TextAreaInput
                     label="Scope of work details"
                     field="details"
                     userCanEdit={props.userCanEdit && !props.acceptedScopeExists}
                  />
               </Col>
            </Row>
            <Collapse isOpen={props.accepted}>
               <PODetails userCanEdit={props.userCanEdit} />
            </Collapse>
         </div>
      </div>
   );
};
