import { Box, Paper, Stack } from "@mui/material";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { RecordFieldLabel } from "records/models";

export const RecordTesting = () => {
  return (
    <Paper>
      <ExpansionPanel title="TESTING & ANALYSIS" defaultExpanded={false}>
        <Box>
          <Stack spacing={4} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <RichTextEditor label={RecordFieldLabel.TestCriteria} fieldName="testCriteria" />
            <RichTextEditor label={RecordFieldLabel.TestResults} fieldName="testResults" />
          </Stack>
        </Box>
      </ExpansionPanel>
    </Paper>
  );
};
