import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { ActiveObsoleteStatusChip } from "common/mui/ActiveObsoleteStatusChip";
import { Controller, useFormContext } from "react-hook-form";
import { useEditableContext } from "./EditableContext";
import { ReadonlyFormItem } from "./ReadonlyFormItem";

export interface ActiveStatusFormItemProps {
  label: string;
  fieldName: string;
}

export const EditableActiveStatusFormItem = (props: ActiveStatusFormItemProps) => {
  const { editable } = useEditableContext();
  const { watch } = useFormContext();
  const value = watch(props.fieldName);
  const valueAsString = !!value ? "Active" : "Obsolete";

  return editable ? (
    <Controller
      name={props.fieldName}
      defaultValue={true}
      render={({ field: { onChange, value } }) => (
        <FormItemLayout controlled label={props.label}>
          {(inputProps) => (
            <ActiveObsoleteStatusChip
              isObsolete={!value}
              onChange={(e) => onChange(!e)}
              disabled={inputProps.disabled}
            />
          )}
        </FormItemLayout>
      )}
    />
  ) : (
    <ReadonlyFormItem label={props.label} value={valueAsString} />
  );
};
