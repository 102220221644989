import React, { useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
} from "@mui/material";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import DomainAddRoundedIcon from "@mui/icons-material/DomainAddRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {
  useCreateCustomerRequest,
  useCreateSiteRequest,
  useDeleteCustomerRequest,
  useDeleteSiteRequest,
  useUpdateCustomerLogoRequest,
  useUpdateCustomerRequest,
  useUpdateSiteRequest,
  useUpsertSiteUserRequest,
} from "../api";
import { SiteSelector } from "app/mui/SiteSelector";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { SiteDigest, CustomerDigest, Customer, Site } from "sites/models";
import AutoSave from "app/mui/forms/AutoSave";
import { useModal } from "app/mui/common/useModal";
import { useNotifications } from "app";
import { pluralize } from "common";
import GpsFormItem from "app/mui/forms/Input/GpsFormItem";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { AllUsersContext } from "../allUsersContext";
import { User } from "users/models";
import { AddIcon } from "icons/AddIcon";

interface CustomersAndSitesPanelProps {
  customers: CustomerDigest[];
  sites: SiteDigest[];
  selectedCustomerId: number | null;
  selectedSiteId: number | null;
  onSelectCustomerId: (customerId: number | null) => void;
  onSelectSiteId: (siteId: number | null) => void;
  selectedCustomer: Customer | null;
  selectedSite: Site | null;
}

export const CustomersAndSitesPanel = (props: CustomersAndSitesPanelProps) => {
  const addCustomerModal = useModal();
  const deleteCustomerModal = useModal();
  const addSiteModal = useModal();
  const deleteSiteModal = useModal();
  const addSiteUserModal = useModal();

  const handleSiteDeleted = () => {
    props.onSelectSiteId(null);
  };

  const handleCustomerDeleted = () => {
    props.onSelectCustomerId(null);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Typography variant="h5" fontWeight="bold" fontFamily="Montserrat">
          Customers And Sites
        </Typography>
        <ActionsDropdownMenu
          actions={[
            {
              icon: <GroupAddRoundedIcon />,
              label: "Create new customer",
              action: () => {
                addCustomerModal.openModal();
              },
            },
            {
              icon: <DomainAddRoundedIcon />,
              label: "Create new site",
              disabled: !props.selectedCustomer,
              action: () => {
                addSiteModal.openModal();
              },
            },
            <Divider />,
            {
              icon: <DeleteForeverRoundedIcon />,
              label: "Delete customer",
              disabled: !props.selectedCustomer,
              action: () => {
                deleteCustomerModal.openModal();
              },
            },
            {
              icon: <DeleteForeverRoundedIcon />,
              label: "Delete site",
              disabled: !props.selectedCustomer || !props.selectedSite,
              action: () => {
                deleteSiteModal.openModal();
              },
            },
            {
              icon: <AddIcon />,
              label: "Add Team Member",
              disabled: !props.selectedCustomer,
              action: () => {
                addSiteUserModal.openModal();
              },
            },
          ]}
        />
      </Stack>
      <SiteSelector
        sites={props.sites || []}
        customers={props.customers || []}
        allowClearable
        onCustomerSelected={(id) => {
          props.onSelectSiteId(null);
          props.onSelectCustomerId(id ?? null);
        }}
        onSiteSelected={(id) => props.onSelectSiteId(id ?? null)}
        currentCustomerId={props.selectedCustomerId}
        currentSiteId={props.selectedSiteId}
        allowSelectionOfCustomerWithoutSite={true}
      />
      <EditPanel customer={props.selectedCustomer} site={props.selectedSite} />
      {addCustomerModal.open && (
        <CreateCustomerModal
          {...addCustomerModal.modalProps}
          onCreated={(customerId) => props.onSelectCustomerId(customerId)}
        />
      )}
      {deleteCustomerModal.open && props.selectedCustomer && (
        <DeleteCustomerModal
          {...deleteCustomerModal.modalProps}
          customer={props.selectedCustomer}
          onDelete={handleCustomerDeleted}
          customerSiteCount={props.sites.filter((s) => s.customerId === props.selectedCustomerId).length}
        />
      )}
      {addSiteModal.open && props.selectedCustomer && (
        <CreateSiteModal
          {...addSiteModal.modalProps}
          customer={props.selectedCustomer}
          onSiteCreated={(newSiteId) => {
            props.onSelectSiteId(newSiteId);
          }}
        />
      )}
      {deleteSiteModal.open && props.selectedSite && (
        <DeleteSiteModal {...deleteSiteModal.modalProps} site={props.selectedSite} onDelete={handleSiteDeleted} />
      )}
      {addSiteUserModal.open && props.selectedCustomer && (
        <AddSiteUserModal {...addSiteUserModal.modalProps} customer={props.selectedCustomer} sites={props.sites || []} />
      )}
    </Stack>
  );
};

interface EditPanelProps {
  customer: Customer | null;
  site: Site | null;
}

const EditPanel = (props: EditPanelProps) => {
  return (
    <Box>
      <Grid container>
        <Grid item sm={6} md={6}>
          {props.customer && <CustomerDetailsForm key={props.customer.id} customer={props.customer} />}
        </Grid>
        <Grid item sm={6} md={6}>
          {props.site && <SiteDetailsForm key={props.site.id} site={props.site} />}
        </Grid>
      </Grid>
    </Box>
  );
};

const CustomerDetailsForm = (props: { customer: Customer }) => {
  const updateCustomerLogoRequest = useUpdateCustomerLogoRequest(props.customer.id);
  const updateCustomerRequest = useUpdateCustomerRequest();

  const defaultValues = { customerName: props.customer.name };

  const methods = useForm({ defaultValues });

  const handleSaveCustomer = () => {
    if (!props.customer) return;

    methods.handleSubmit((values) => {
      const newCustomer = {
        ...(props.customer as Customer),
        name: values.customerName,
      };
      updateCustomerRequest.call(newCustomer);
    })();
  };

  const handleNewLogoUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const formData = new FormData();
    formData.append("LogoImage", e.target.files[0]);
    updateCustomerLogoRequest.call(formData);
  };

  return (
    <FormProvider {...methods}>
      <AutoSave onSubmit={handleSaveCustomer} defaultValues={defaultValues} />
      <Stack direction="row" spacing={2}>
        <TextFieldFormItem label="Customer name" fieldName="customerName" />

        <FormItemLayout label="Logo" fieldName="logo">
          {(params) => {
            const imgSrc = props.customer?.logoUri;
            return (
              <Stack direction="row" spacing={1}>
                <Box sx={{ width: "100px", height: "40px" }}>
                  {!!imgSrc && (
                    <img
                      src={imgSrc}
                      style={{ width: "100%", height: "100%", objectFit: "contain" }}
                      alt="Customer logo"
                    />
                  )}
                </Box>
                <Button variant="text" color="secondary" component="label">
                  {!!imgSrc ? "Change" : "Upload logo"}
                  <input type="file" hidden accept="image/*" onChange={handleNewLogoUploaded} />
                </Button>
              </Stack>
            );
          }}
        </FormItemLayout>
      </Stack>
    </FormProvider>
  );
};

const SiteDetailsForm = (props: { site: Site }) => {
  const updateSiteRequest = useUpdateSiteRequest();

  const defaultValues = { siteName: props.site.name, siteLocation: props.site.location };

  const methods = useForm({ defaultValues });

  const handleSaveSite = () => {
    if (!props.site) return;

    methods.handleSubmit((values) => {
      const newSite = {
        ...(props.site as Site),
        name: values.siteName,
        location: values.siteLocation,
      };
      updateSiteRequest.call(newSite);
    })();
  };

  return (
    <FormProvider {...methods}>
      <AutoSave onSubmit={handleSaveSite} defaultValues={defaultValues} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFieldFormItem label="Site name" fieldName="siteName" />
        </Grid>
        <Grid item xs={6}>
          <GpsFormItem label="Site GPS coordinates" fieldName="siteLocation" includeViewLink />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

interface CreateCustomerModalProps {
  open: boolean;
  onClose: () => void;
  onCreated: (customerId: number) => any;
}

const CreateCustomerModal = (props: CreateCustomerModalProps) => {
  const methods = useForm();
  const notifications = useNotifications();
  const createCustomerRequest = useCreateCustomerRequest();

  const submit = () => {
    methods.handleSubmit(async (values) => {
      await createCustomerRequest
        .call({
          name: values.name,
        })
        .then((customer) => {
          if (!!customer) {
            notifications.success(`${customer.name} created`);
            props.onCreated(customer.id);
          }
        });

      props.onClose();
    })();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <DialogTitle>Create New Customer</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <TextFieldFormItem fieldName="name" label={"Customer name"} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={submit} color="secondary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

interface DeleteCustomerModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  customer: Customer;
  customerSiteCount: number;
}

const DeleteCustomerModal = (props: DeleteCustomerModalProps) => {
  const methods = useForm();
  const notifications = useNotifications();
  const deleteCustomerRequest = useDeleteCustomerRequest();

  const submit = () => {
    methods.handleSubmit(async (values) => {
      if (values.name === props.customer.name) {
        await deleteCustomerRequest
          .call(props.customer.id)
          .then(() => notifications.success(`${props.customer.name} deleted`));
        props.onDelete();
      }
      props.onClose();
    })();
  };

  const typedValue = useWatch({ name: "name", control: methods.control });

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <DialogTitle>Delete this customer?</DialogTitle>
        <DialogContent>
          {props.customerSiteCount === 0 && (
            <Stack spacing={3}>
              <Typography variant="body2">
                Are you sure you want to delete customer "{props.customer.name}"? If you're sure, please enter the
                customer name below.
              </Typography>
              <TextFieldFormItem fieldName="name" label={"Customer name"} />
            </Stack>
          )}
          {props.customerSiteCount > 0 && (
            <Typography variant="body2">
              This customer has {props.customerSiteCount} {pluralize(props.customerSiteCount, "site")} which must be
              deleted before the customer can be deleted.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button
            onClick={submit}
            color="error"
            variant="contained"
            disabled={props.customerSiteCount > 0 || typedValue !== props.customer.name}
          >
            Delete
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

interface CreateSiteModalProps {
  open: boolean;
  onClose: () => void;
  onSiteCreated: (newSiteId: number) => any;
  customer: Customer;
}

const CreateSiteModal = (props: CreateSiteModalProps) => {
  const methods = useForm();
  const notifications = useNotifications();
  const createSiteRequest = useCreateSiteRequest();

  const submit = () => {
    methods.handleSubmit(async (values) => {
      if (!values.name) {
        return;
      }

      await createSiteRequest
        .call({
          customerId: props.customer.id,
          name: values.name,
          location: values.location,
        })
        .then((newSite) => {
          if (newSite) {
            notifications.success("Site created");
            props.onSiteCreated(newSite.id);
          }
        });

      props.onClose();
    })();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <DialogTitle>Create New Site</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <TextFieldFormItem
              fieldName="name"
              label={"Site name"}
              textFieldProps={{ required: true }}
              hint="required"
            />
            <GpsFormItem fieldName="location" label={"Site GPS coordinates"} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={submit} color="secondary" variant="contained" disabled={createSiteRequest.loading}>
            Submit
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

interface DeleteSiteModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  site: Site;
}

const DeleteSiteModal = (props: DeleteSiteModalProps) => {
  const methods = useForm();
  const notifications = useNotifications();
  const deleteSiteRequest = useDeleteSiteRequest();

  const submit = () => {
    methods.handleSubmit(async (values) => {
      if (values.name === props.site.name) {
        await deleteSiteRequest.call(props.site.id);
        notifications.success(`${props.site.name} deleted`);
        props.onDelete();
      }
      props.onClose();
    })();
  };

  const typedValue = useWatch({ name: "name", control: methods.control });

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <DialogTitle>Delete this site?</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography variant="body2">
              Are you sure you want to delete the site "{props.site.name}" and its associated areas? If so, please enter
              the site name below.
            </Typography>
            <TextFieldFormItem fieldName="name" label={"Site name"} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={submit} color="error" variant="contained" disabled={typedValue !== props.site.name}>
            Delete
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

interface AddSiteUserModalProps {
  customer: Customer;
  sites: SiteDigest[];
  open: boolean;
  onClose: () => void;
}

const AddSiteUserModal = (props: AddSiteUserModalProps) => {
  const methods = useForm({ defaultValues: { users: [] as User[] } });
  const upsertSiteUserRequest = useUpsertSiteUserRequest();

  const { users: allUsers, loading: loadingAll } = useContext(AllUsersContext);

  const submit = () => {
    methods.handleSubmit(async (values) => {
      await upsertSiteUserRequest.call({
        userIds: values.users.map((u) => u.userId),
        customerId: props.customer.id,
      });
      props.onClose();
    })();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth={true}>
      <FormProvider {...methods}>
        <DialogTitle>Add users</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2">Are you sure you want to add user to {props.sites.length} number of sites?</Typography>
            <AutocompleteFormItem
              options={allUsers}
              idSelector={(user) => user.userId}
              getOptionLabel={(user) => user.fullName}
              multiple
              loading={loadingAll}
              fieldName="users"
              label="Users"
              autocompleteProps={{
                disablePortal: false,
                isOptionEqualToValue: (option, value) => option.userId === value.userId,
                renderOption: (optionProps, option, _state) => {
                  return (
                    <li {...{ ...optionProps, key: option.userId }}>
                      <Stack spacing={2} alignItems="center" direction="row">
                        <Avatar src={option.picture} alt={option.fullName} />
                        <Typography variant="body2">{option.fullName}</Typography>
                      </Stack>
                    </li>
                  );
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={submit} color="secondary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
