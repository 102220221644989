import { FlagOption, RecordFlags, recordFlagTypeToOption } from "flags";
import _ from "lodash";
import { ReactNode, useMemo, useRef } from "react";

interface FlagsLoaderProps {
  children: (params: { options: FlagOption[] }) => ReactNode;
}

export const FlagsLoader = (props: FlagsLoaderProps) => {
  const data = useRef(RecordFlags.map((f) => recordFlagTypeToOption(f)));

  // const options = _.orderBy(data, (k) => k);
  const options: FlagOption[] = useMemo(() => _.orderBy(data.current, (k) => k.name), [data]);
  return <>{props.children({ options })}</>;
};
