import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import { getInvalidValidity, getValidValidity, Validity } from "common";
import { EditKpiFormProps } from "./EditKpiFormProps";
import { NumberInputGroup } from "records/bs/NumberInputGroup";
import { SelectTimeUnit } from "common/fields/SelectTimeUnit";
import { DelayTypeLocationAndActionsTakenRow } from "kpis/formFields/DelayTypeLocationAndActionsTakenRow";
import { KpiOneTimeScheduleDelayReduction } from "kpis/models";

interface Props extends EditKpiFormProps {}

export interface KpiOneTimeScheduleDelayReductionValidity {
  reductionAmount: Validity;
  reductionTimeUnit: Validity;
  potentialDelayType: Validity;
  location: Validity;
  actionsTaken: Validity;
}

export const getKpiOneTimeScheduleDelayReductionValidity = (
  fields: KpiOneTimeScheduleDelayReduction
): KpiOneTimeScheduleDelayReductionValidity => {
  return {
    reductionAmount: !!fields.reductionAmount && fields.reductionAmount > 0 ? getValidValidity() : getInvalidValidity(),
    reductionTimeUnit: !!fields.reductionTimeUnit ? getValidValidity() : getInvalidValidity(),
    potentialDelayType: getValidValidity(),
    location: getValidValidity(),
    actionsTaken: getValidValidity(),
  };
};

export const EditKpiOneTimeScheduleDelayReductionForm: React.FunctionComponent<Props> = (props) => {
  const model = props.model as KpiOneTimeScheduleDelayReduction;

  const [validity, setValidity] = useState<KpiOneTimeScheduleDelayReductionValidity>(
    getKpiOneTimeScheduleDelayReductionValidity(model)
  );

  const validate = (fields: KpiOneTimeScheduleDelayReduction) => {
    const newValidity = getKpiOneTimeScheduleDelayReductionValidity(fields);
    setValidity(newValidity);
    return _.values(newValidity).filter((v) => !v.valid).length === 0;
  };

  useEffect(() => {
    if (!!props.onLoaded) {
      const isValid = _.values(validity).filter((v) => !v.valid).length === 0;
      props.onLoaded(isValid);
    }
  }, []);

  const saveChange = (updates: Partial<KpiOneTimeScheduleDelayReduction>) => {
    const updatedFields = { ...model, ...updates };
    const isValid = validate(updatedFields);
    props.onUpdated(updatedFields, isValid);
  };

  return (
    <>
      <Row className="gray-form-section">
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <p className="form-section-header">Potential delay reduction</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form-group-wrapper">
                <NumberInputGroup
                  style={{ width: "80px" }}
                  value={model.reductionAmount}
                  onChange={(reductionAmount) => saveChange({ reductionAmount })}
                  valid={!props.showValidationErrors || validity.reductionAmount.valid}
                  validationError={validity.reductionAmount.message}
                  readOnly={props.readOnly}
                  placeholder={"e.g. 5"}
                />
                <SelectTimeUnit
                  mode="Plural"
                  limitTo={["Hour", "Day"]}
                  value={model.reductionTimeUnit}
                  readOnly={props.readOnly}
                  saveChange={(reductionTimeUnit) => saveChange({ reductionTimeUnit })}
                  showValidationErrors={props.showValidationErrors}
                  validity={validity.reductionAmount}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <DelayTypeLocationAndActionsTakenRow
        fields={model}
        readOnly={props.readOnly}
        saveChange={saveChange}
        showValidationErrors={props.showValidationErrors}
        validity={validity}
      />
    </>
  );
};
