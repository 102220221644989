import { Box } from "@mui/material";
import { UnknownIcon } from "icons/UnknownIcon";
import { getTrafficLightOption, TrafficLightValue } from "records/models";

interface TrafficLightCircleProps {
  value: TrafficLightValue | null;
}

/*
 * Render an icon based on a traffic light circle value - either a colored circle or a square question mark
 * the colors are defined through the TrafficLightOptions so that they stay consistent everywhere
 */
function TrafficLightCircle(props: TrafficLightCircleProps) {
  const { value } = props;
  const option = getTrafficLightOption(value);

  return (
    <Box sx={{ width: 24, display: "flex", justifyContent: "center" }}>
      {value === null ? (
        <UnknownIcon sx={{ color: option.color, fontSize: 24 }} />
      ) : (
        <div style={{ borderRadius: "50%", backgroundColor: option.color, width: 32, aspectRatio: "1" }}></div>
      )}
    </Box>
  );
}

export default TrafficLightCircle;
