import { Row, Col, Card, FormGroup, Label, Button, Form, Input } from "reactstrap";
import { useAutosaver, useNotifications, useUserContext } from "app";
import { dateFormat, getValidity } from "common";
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "icons/DeleteIcon";
import { formatRoutePath, Routes } from "app";
import classNames from "classnames";
import Textarea from "react-textarea-autosize";
import { ConfirmationModal } from "common/ConfirmationModal";
import moment from "moment";
import { EventIcon } from "icons/EventIcon";
import { Checkbox } from "common/Checkbox";
import { FunctionComponent, useEffect, useState } from "react";
import { RecordsEvent, RecordsEventDetail } from "recordsEvents/models";
import { useDeleteRecordsEventRequest, useUpdateRecordsEventRequest } from "recordsEvents/api";

interface Props {
  recordsEvent: RecordsEventDetail;
}

export const RecordsEventScreen: FunctionComponent<Props> = (props) => {
  const userCanEdit = useUserContext().userPermissions.userCanEditRecordsEvents;
  const navigate = useNavigate();
  const notifications = useNotifications();

  const updateRecordsEventRequest = useUpdateRecordsEventRequest();
  const [model, saveModel] = useAutosaver(props.recordsEvent.model, updateRecordsEventRequest.call);
  const saveChange = (changes: Partial<RecordsEvent>, now?: boolean) => saveModel({ ...model, ...changes }, now);

  const [deleting, setDeleting] = useState(false);
  const deleteRecordsEventRequest = useDeleteRecordsEventRequest();

  const nameValidity = getValidity(!!model.name.trim());

  const [startDateValue, setStartDateValue] = useState(model.startDate);
  const startDateValidity = getValidity(!!startDateValue && moment(startDateValue).isValid());
  useEffect(() => {
    if (startDateValue !== model.startDate && startDateValidity.valid) {
      saveChange({ startDate: startDateValue }, true);
    }
  }, [startDateValue, model.startDate]);

  const [endDateValue, setEndDateValue] = useState(model.endDate);
  const endDateValidity = getValidity(!endDateValue || moment(endDateValue).isValid());
  useEffect(() => {
    if (endDateValue !== model.endDate && endDateValidity.valid) {
      saveChange({ endDate: endDateValue }, true);
    }
  }, [endDateValue, model.endDate]);

  return (
    <div className="summary-screen">
      <title>SpartanPRO IRIS - {model.name}</title>
      <Row className="title-card-header">
        <Col md={8} lg={9}>
          <Card body className="summary-screen-title">
            <div className="screen-title">
              <div className="icon-and-title">
                <EventIcon sx={{ fontSize: 42 }} />
                <Form className="hover-borders">
                  <Textarea
                    className={classNames("form-control", {
                      "is-invalid": !nameValidity.valid,
                    })}
                    value={model.name}
                    onChange={(e) => saveChange({ name: e.target.value })}
                    readOnly={!userCanEdit}
                  />
                  <div className="invalid-feedback">{nameValidity.message}</div>
                </Form>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={9}>
          <Card body>
            <div className="section">
              <Form>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Start Date</Label>
                      {!userCanEdit ? (
                        <Input
                          plaintext
                          readOnly
                          value={startDateValue === null ? "" : moment(startDateValue).format(dateFormat)}
                        />
                      ) : (
                        <Input
                          className={classNames("form-control", {
                            "dim-date": startDateValue === null,
                            "is-invalid": !startDateValidity.valid,
                          })}
                          type="date"
                          value={!startDateValue ? "" : moment(startDateValue).format(dateFormat)}
                          onChange={(e) => setStartDateValue(moment(e.target.value).toDate())}
                          readOnly={!userCanEdit}
                        />
                      )}
                      <div className="invalid-feedback">Event must have a start date.</div>
                    </FormGroup>
                    <FormGroup>
                      <Label>End Date</Label>
                      {!userCanEdit ? (
                        <Input
                          plaintext
                          readOnly
                          value={!endDateValue ? "" : moment(endDateValue).format(dateFormat)}
                        />
                      ) : (
                        <Input
                          className={classNames("form-control", {
                            "dim-date": endDateValue === null,
                            "is-invalid": !endDateValidity.valid,
                          })}
                          type="date"
                          value={endDateValue === null ? "" : moment(endDateValue).format(dateFormat)}
                          onChange={(e) => setEndDateValue(moment(e.target.value).toDate())}
                          readOnly={!userCanEdit}
                        />
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Checkbox
                        readOnly={!userCanEdit}
                        value={model.isArchived}
                        onClick={() =>
                          saveChange({
                            isArchived: !model.isArchived,
                          })
                        }
                      >
                        Archived
                      </Checkbox>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
          <div className="infrequent-commands">
            {userCanEdit && (
              <>
                <Button color="danger" size="sm" onClick={() => setDeleting(true)}>
                  <DeleteIcon />
                  <span>Delete event</span>
                </Button>
                <ConfirmationModal
                  title="Delete event?"
                  body="Are you sure you want to delete this event?"
                  show={deleting}
                  cancel={() => setDeleting(false)}
                  confirm={async () => {
                    deleteRecordsEventRequest.call(model.id).then(() => {
                      navigate(formatRoutePath(Routes.RecordsEvents, model));
                      notifications.success("Event deleted.");
                    });
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
