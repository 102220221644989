import React from "react";
import { formatRoutePath, Routes, useUserContext } from "app";
import { Button, Card, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSiteIdFromRoute } from "app";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { formatSortCaret } from "common";
import moment from "moment";
import { dateFormat } from "common/formats";
import { useEffect, useState } from "react";
import DoubleScrollbar from "react-double-scrollbar";
import { EventIcon } from "icons/EventIcon";
import { RecordsEventFiltersDisplay } from "./RecordsEventFiltersDisplay";
import { RecordsEventDetail, RecordsEventFilters } from "recordsEvents/models";
import { useGetFilteredRecordsEventDetailsRequest } from "recordsEvents/api";
import { CreateRecordsEventModal } from "./CreateRecordsEventModal";
import { Icon } from "@mui/material";
export const RecordsEventList: React.FunctionComponent<{}> = (props) => {
  const siteId = useSiteIdFromRoute()!;
  const userCanCreateEvents = useUserContext().userPermissions.userCanEditRecordsEvents;
  const navigate = useNavigate();

  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [filters, setFilters] = useState(new RecordsEventFilters());
  const [showFilters, setShowFilters] = useState(true);

  const [createEventModalOpen, setCreateEventModalOpen] = useState(false);

  const updateItemsPerPage = (value: string, onSizePerPageChange: (size: number) => void) => {
    const itemsPerPage = value === "" ? 0 : parseInt(value);

    setItemsPerPage(itemsPerPage);
    onSizePerPageChange(itemsPerPage);
  };

  const query = useGetFilteredRecordsEventDetailsRequest(siteId);
  useEffect(() => {
    query.call(filters);
  }, [filters]);
  const recordsEvents = query.data ?? [];

  const getDataColumns = () => {
    const nameColumn = {
      dataField: "model.name",
      text: "Name",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: React.ReactText, recordsEvent: RecordsEventDetail) => (
        <Link key={recordsEvent.id} to={formatRoutePath(Routes.RecordsEvent, recordsEvent)}>
          {value}
        </Link>
      ),
      headerClasses: "xl-column",
    };

    const startDateColumn = {
      dataField: "model.startDate",
      text: "Start Date",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: string, recordsEvent: RecordsEventDetail) => moment(value).format(dateFormat),
      headerClasses: "xs-column",
    };

    const endDateColumn = {
      dataField: "model.endDate",
      text: "End Date",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: string, recordsEvent: RecordsEventDetail) => (value ? moment(value).format(dateFormat) : ""),
      headerClasses: "xs-column",
    };

    const durationColumn = {
      dataField: "duration",
      text: "Duration",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: number | null, recordsEvent: RecordsEventDetail) => {
        return value;
      },
      headerClasses: "xs-column",
    };

    const archivedColumn = {
      dataField: "model.isArchived",
      text: "Status",
      sort: true,
      sortCaret: formatSortCaret,
      formatter: (value: boolean, recordsEvent: RecordsEventDetail) => (value ? "Archived" : "Active"),
      headerClasses: "sm-column",
    };

    return [nameColumn, startDateColumn, endDateColumn, durationColumn, archivedColumn];
  };

  const toggleCreateEventModal = () => {
    setCreateEventModalOpen(!createEventModalOpen);
  };

  return (
    <div className="legacy_bootstrap">
      <div className="event-list">
        <title>SpartanPRO IRIS - Events</title>
        <ToolkitProvider keyField="id" data={recordsEvents} columns={getDataColumns()}>
          {(toolkitProps: any) => (
            <Card body>
              <div className="screen-title">
                <div className="icon-and-title">
                  <EventIcon sx={{ fontSize: 42 }} />
                  <div>Events</div>
                </div>
                <div className="screen-commands">
                  {userCanCreateEvents && (
                    <Button color="secondary" onClick={() => toggleCreateEventModal()}>
                      Create Event
                    </Button>
                  )}
                  {showFilters && (
                    <Button
                      color="secondary"
                      onClick={() => {
                        setFilters(new RecordsEventFilters());
                      }}
                    >
                      Clear filters
                    </Button>
                  )}
                  <Button color="secondary" onClick={() => setShowFilters(!showFilters)}>
                    {showFilters ? "Hide filters" : "Show filters"}
                  </Button>
                </div>
              </div>

              {showFilters && (
                <RecordsEventFiltersDisplay
                  filters={filters}
                  updateFilters={(newFilters) => {
                    setFilters(newFilters);
                  }}
                  nameFragment={filters.nameFragment || ""}
                />
              )}
              <div className="stacking-context">
                <DoubleScrollbar>
                  <div className="pagination-top">
                    <BootstrapTable
                      {...toolkitProps.baseProps}
                      classes="table-sm list-table"
                      remote={{ filter: true }}
                      loading={query.loading}
                      bootstrap4={true}
                      striped
                      bordered={false}
                      defaultSorted={[{ dataField: "name", order: "asc" }]}
                      defaultSortDirection="asc"
                      headerClass="header"
                      pagination={paginationFactory({
                        sizePerPage: itemsPerPage,
                        hidePageListOnlyOnePage: true,
                        sizePerPageRenderer: (props: {
                          options: any;
                          currSizePerPage: number;
                          onSizePerPageChange: (size: number) => void;
                        }) => {
                          return (
                            <div className="items-per-page">
                              <label>Events per page</label>
                              <Input
                                type="number"
                                value={itemsPerPage}
                                onChange={(e) => updateItemsPerPage(e.target.value, props.onSizePerPageChange)}
                              />
                              <label style={{ paddingLeft: "1em" }}>Events: {recordsEvents.length}</label>
                            </div>
                          );
                        },
                        hideSizePerPage: false,
                      })}
                      noDataIndication={() => (query.loading ? <div>Loading...</div> : <div>No events found.</div>)}
                    />
                  </div>
                </DoubleScrollbar>
              </div>
            </Card>
          )}
        </ToolkitProvider>
        {userCanCreateEvents && createEventModalOpen && (
          <CreateRecordsEventModal
            close={toggleCreateEventModal}
            createdRecordsEvent={(newEvent, viewRecordsEvent) => {
              toggleCreateEventModal();
              if (viewRecordsEvent) {
                navigate(formatRoutePath(Routes.RecordsEvent, newEvent));
              } else {
                query.call(filters);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
