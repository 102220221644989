import React from "react";
import { Dialog, DialogProps, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface BaseDialogProps extends Omit<DialogProps, "open" | "onClose"> {
  onClose: () => void;
}

export const BaseDialog = (props: BaseDialogProps & { children?: React.ReactNode; closeWithBackdrop?: boolean }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { onClose, maxWidth = "sm", fullWidth = true, scroll = "body", ...dialogProps } = props;

  const handleClose = (reason: string) => {
    // ignore backdrop clicks
    if (reason !== "backdropClick" || props.closeWithBackdrop) {
      props.onClose();
    }
  };

  return (
    <Dialog
      open={true}
      onClose={(_, reason) => handleClose(reason)}
      scroll={scroll}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      {...dialogProps}
    >
      {props.children}
    </Dialog>
  );
};
