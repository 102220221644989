import React, { useEffect, useState } from "react";
import {
  GetKpiCommodityUnitsForEmissions,
  KpiEmissionsType,
  KpiEmissionsTypes,
  KpiOngoingEmissionsReduction,
} from "../models";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import { getInvalidValidity, getValidValidity, Validity } from "common";
import { EditKpiFormProps } from "./EditKpiFormProps";
import { ProductionRateFormGroup } from "kpis/formFields/ProductionRateFormGroup";

interface Props extends EditKpiFormProps {}

export interface KpiOngoingEmissionsReductionValidity {
  amountBeforeReduction: Validity;
  amountAfterReduction: Validity;
  reductionUnit: Validity;
  reductionType: Validity;
  reductionTimeUnit: Validity;
}

export const getKpiOngoingEmissionsReductionValidity = (
  fields: KpiOngoingEmissionsReduction
): KpiOngoingEmissionsReductionValidity => {
  const amountsReversed = (fields.amountAfterReduction ?? 0) > (fields.amountBeforeReduction ?? 0);
  return {
    amountBeforeReduction:
      fields.amountBeforeReduction === null
        ? getInvalidValidity()
        : amountsReversed
        ? getInvalidValidity("Emissions should be reduced.")
        : getValidValidity(),
    amountAfterReduction:
      fields.amountAfterReduction === null
        ? getInvalidValidity()
        : amountsReversed
        ? getInvalidValidity("Emissions should be reduced.")
        : getValidValidity(),
    reductionUnit:
      !!fields.reductionUnit &&
      GetKpiCommodityUnitsForEmissions(fields.reductionType).indexOf(fields.reductionUnit) >= 0
        ? getValidValidity()
        : getInvalidValidity(),
    reductionType:
      !!fields.reductionType && KpiEmissionsTypes.indexOf(fields.reductionType as KpiEmissionsType) >= 0
        ? getValidValidity()
        : getInvalidValidity(),
    reductionTimeUnit: !!fields.reductionTimeUnit ? getValidValidity() : getInvalidValidity(),
  };
};

export const EditKpiOngoingEmissionsReductionForm: React.FunctionComponent<Props> = (props) => {
  const model = props.model as KpiOngoingEmissionsReduction;

  const [validity, setValidity] = useState<KpiOngoingEmissionsReductionValidity>(
    getKpiOngoingEmissionsReductionValidity(model)
  );

  const validate = (fields: KpiOngoingEmissionsReduction) => {
    const newValidity = getKpiOngoingEmissionsReductionValidity(fields);
    setValidity(newValidity);
    return _.values(newValidity).filter((v) => !v.valid).length === 0;
  };

  useEffect(() => {
    if (!!props.onLoaded) {
      const isValid = _.values(validity).filter((v) => !v.valid).length === 0;
      props.onLoaded(isValid);
    }
  }, []);

  const saveChange = (updates: Partial<KpiOngoingEmissionsReduction>) => {
    const updatedFields = { ...model, ...updates };
    const isValid = validate(updatedFields);
    props.onUpdated(updatedFields, isValid);
  };

  const kpiCommodityUnitsForEmissions = GetKpiCommodityUnitsForEmissions(model.reductionType);

  useEffect(() => {
    if (!!!model.reductionUnit) {
      saveChange({ reductionUnit: "tonnes" });
    }
    if (!!!model.reductionType) {
      saveChange({ reductionType: KpiEmissionsTypes[0] });
    }
  });

  return (
    <Row className="gray-form-section">
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <p className="form-section-header">Emission reduction</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ProductionRateFormGroup
              label="Baseline emissions (quantity/unit/product/rate)"
              showValidationErrors={props.showValidationErrors}
              productionRateReadOnly={props.readOnly}
              productionRateValidity={validity.amountBeforeReduction}
              productionRateValue={model.amountBeforeReduction}
              setProductionRate={(amountBeforeReduction) => saveChange({ amountBeforeReduction })}
              unitReadOnly={props.readOnly}
              unitValidity={validity.reductionUnit}
              unitValue={model.reductionUnit}
              setUnit={(reductionUnit) => saveChange({ reductionUnit })}
              typeReadOnly={props.readOnly}
              typeValidity={validity.reductionType}
              typeValue={model.reductionType}
              typeValues={KpiEmissionsTypes}
              units={kpiCommodityUnitsForEmissions}
              setType={(reductionType) => saveChange({ reductionType })}
              productionRateTimeUnitReadOnly={props.readOnly}
              productionRateTimeUnitValidity={validity.reductionTimeUnit}
              productionRateTimeUnitValue={model.reductionTimeUnit}
              setProductionRateTimeUnit={(reductionTimeUnit) => saveChange({ reductionTimeUnit })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ProductionRateFormGroup
              label="As-left emissions (quantity/unit/product/rate)"
              showValidationErrors={props.showValidationErrors}
              productionRateReadOnly={props.readOnly}
              productionRateValidity={validity.amountAfterReduction}
              productionRateValue={model.amountAfterReduction}
              setProductionRate={(amountAfterReduction) => saveChange({ amountAfterReduction })}
              unitReadOnly={true}
              unitValidity={getValidValidity()}
              unitValue={model.reductionUnit}
              units={kpiCommodityUnitsForEmissions}
              setUnit={() => {}}
              typeReadOnly={true}
              typeValidity={getValidValidity()}
              typeValue={model.reductionType}
              setType={() => {}}
              productionRateTimeUnitReadOnly={true}
              productionRateTimeUnitValidity={getValidValidity()}
              productionRateTimeUnitValue={model.reductionTimeUnit}
              setProductionRateTimeUnit={() => {}}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
