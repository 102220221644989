import * as React from "react";
import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { useFormContext } from "react-hook-form";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";

export const MachineryComponentSpecificationCard = (props: { componentNumber: number; fieldPrefix: string }) => {
   const { watch } = useFormContext();
   const componentType = watch(`${props.fieldPrefix}Type`);

   const fields = [
      <EditableActiveObsoleteFormItem label="Status" fieldName={`${props.fieldPrefix}IsObsolete`} />,
      <SpecOptionLoader type="MachineryComponentType">
         {(options) => (
            <EditableAutocompleteFormItem label="Component type" fieldName={`${props.fieldPrefix}Type`} {...options} />
         )}
      </SpecOptionLoader>,
      <SpecOptionLoader type={`Machinery${componentType}Subtype`} disabled={!componentType}>
         {(options) => (
            <EditableAutocompleteFormItem
               label="Component sub-type"
               fieldName={`${props.fieldPrefix}SubType`}
               {...options}
            />
         )}
      </SpecOptionLoader>,
      <SpecOptionLoader type="MachineryBearingType">
         {(options) => (
            <EditableAutocompleteFormItem
               label="Bearing type"
               fieldName={`${props.fieldPrefix}BearingType`}
               {...options}
            />
         )}
      </SpecOptionLoader>,
   ];

   return <AssetSpecificationCard title={`Component ${props.componentNumber}`} specs={fields} />;
};
