import React from "react";
import { Input, Button } from "reactstrap";

interface State {
   editingField: boolean;
   value: string;
}

interface Props {
   value: string;
   editable: boolean;
   onChange: (changedValue: string) => void;
}

export class EditHeader extends React.Component<Props, State> {
   public render() {
      return this.state.editingField ? (
         <div className="tag-edit-form editable-rich-text-label w-100">
            <Input
               className="mr-2 hover-borders"
               value={this.props.value}
               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.props.onChange(e.target.value);
               }}
            />
            <Button
               outline
               className="x-small"
               color="secondary"
               onClick={() => this.setState({ editingField: false })}
               disabled={!this.props.editable}
            >
               Done
            </Button>
         </div>
      ) : (
         <>
            <div className="read-only-tag editable-rich-text-label w-100">
               <label className="mr-2">{this.props.value}</label>
               {this.props.editable && (
                  <Button outline className="x-small" color="secondary" onClick={() => this.setState({ editingField: true })}>
                     Edit
                  </Button>
               )}
            </div>
         </>
      );
   }

   constructor(props: Props) {
      super(props);
      this.state = {
         value: this.props.value,
         editingField: false,
      };
   }
}
