import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";

const transformIncoming = (incoming: string, apiRef: React.MutableRefObject<GridApiPro>): GridInitialStatePro => {
   // we need to know all of the columns because when we transformed the
   // outgoing state we compressed it by removing hidden columns - but the
   // mui data grid requires us to explicity call out hidden columns

   const allColumns = apiRef.current.getAllColumns().map((a) => a.field);

   // we received the state as a string, uri encoded, and json crushed
   const state: GridInitialStatePro = JSON.parse(incoming);

   let newVisibilityModel: GridColumnVisibilityModel = {};
   let newOrderedFields: string[] = [];

   allColumns.forEach((column) => {
      // repopulate the columnVisibilityModel
      newVisibilityModel[column] = state.columns?.columnVisibilityModel?.[column] ?? false;
   });

   // repopulate the orderedFields, push in the order of visible fields then hidden fields
   const visibleOrderedFields = state.columns?.orderedFields || [];
   const hiddenOrderedFields = allColumns.filter((c) => !visibleOrderedFields.includes(c));

   newOrderedFields.push(...[...visibleOrderedFields, ...hiddenOrderedFields]);

   return {
      ...state,
      columns: {
         ...state.columns,
         columnVisibilityModel: newVisibilityModel,
         orderedFields: newOrderedFields,
      },
   };
};

const transformOutgoing = (state: GridInitialStatePro, apiRef: React.MutableRefObject<GridApiPro>): string => {
   // destructuring state because we do not want to take all of it.
   const { columns, pinnedColumns, filter, sorting } = state;

   const columnVisibilityModel = columns?.columnVisibilityModel ?? {};
   const orderedFields = columns?.orderedFields ?? [];

   if (Object.keys(columnVisibilityModel).length == 0) {
      // mui is dumb and returns no columns when all are visible..
      // this is inconsisent and breaks a lot of things,
      // especially since restoring the state assumes columns
      // not listed means hidden we want to hide them...
      //
      // so if this is empty, we need to assume user clicked "show all"
      // and we need to populate this model with every column set to true.
      const allColumns = apiRef.current.getAllColumns().map((a) => a.field);

      allColumns.forEach((column) => {
         columnVisibilityModel[column] = true;
      });
   }

   // in order to make the state a little more url friendly,
   // we are going to strip out all the unnecessary data from the state.
   // mui requires us to list all columns with visibility true or false,
   // but we will likely have only 10 visible columns, and over 200 hidden
   // ones. So we will transform the state so that we only list things
   // that are visible, and the hidden ones can be "put back in" when
   // we transform on the incoming state.

   const newVisibilityModel: GridColumnVisibilityModel = {};
   const newOrderedFields: string[] = [];

   Object.keys(columnVisibilityModel).forEach((field) => {
      if (columnVisibilityModel![field] === true) {
         newVisibilityModel[field] = true;
      }
   });

   orderedFields.forEach((field) => {
      if (columnVisibilityModel![field]) {
         newOrderedFields.push(field);
      }
   });

   const newState = {
      columns: {
         ...columns,
         columnVisibilityModel: newVisibilityModel,
         orderedFields: newOrderedFields,
      },
      pinnedColumns,
      filter,
      sorting,
   };

   return JSON.stringify(newState);
};

export const StateTransformer = { transformIncoming, transformOutgoing };
