import {
  useBaseAxiosGetRequest,
  useBaseAxiosPostRequest,
} from "app";
import {
  OfflineAssetModel,
  OfflineDataConfirmViewModel,
  OfflineDataImportViewModel,
  OfflineImportResult,
} from '@bluemarvel/iris-common/model';

export const useGetOfflineDataImportRequest = (siteId: number) =>
  useBaseAxiosGetRequest<OfflineDataImportViewModel[]>(`/api/offline/importData/${siteId}`, { useNode: true });


export const useGetOfflineCreateAssetRequest = (importId: number) =>
  useBaseAxiosGetRequest<OfflineImportResult>(`/api/offline/${importId}/offlineAssets/create`, { useNode: true });

export const useSyncOfflineCreateAssetRequest = (importId: number) =>
  useBaseAxiosPostRequest<number[], OfflineAssetModel[]>(`/api/offline/${importId}/offlineAssets/create`, { useNode: true });

export const useGetOfflineDataConfirmRequest = (importId: number) =>
  useBaseAxiosGetRequest<OfflineDataConfirmViewModel[]>(`/api/offline/${importId}/offlineAssets/confirm`, { useNode: true });
