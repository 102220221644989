import * as React from "react";
import { useState } from "react";
import { Card, CardActions, CardContent, Collapse, Grid, IconButton, Menu, Stack, Typography } from "@mui/material";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDownRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { EditableContextProvider } from "app/mui/forms/EditableContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface AssetSpecificationCardProps {
  title: string;
  specs: React.ReactNode[];
  overflowMenuOptions?: React.ReactNode[];
}

export const AssetSpecificationCard = (props: AssetSpecificationCardProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const specs = props.specs.filter((n) => !!n);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <Card
        sx={{
          width: "100%",
          minHeight: "410px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        variant="outlined"
      >
        <CardContent>
          <EditableContextProvider editable={editMode}>
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ position: "relative" }}>
                <Typography variant="h6" fontWeight="600" lineHeight="1">
                  {props.title}
                </Typography>
                {props.overflowMenuOptions && (
                  <>
                    <IconButton
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      sx={{ position: "absolute", right: 0 }}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Menu
                      open={open}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {props.overflowMenuOptions}
                    </Menu>
                  </>
                )}
              </Stack>
              {specs.slice(0, 5).map((node, index) => (
                <React.Fragment key={index}>{node}</React.Fragment>
              ))}
              <Collapse in={expanded}>
                <Stack spacing={2}>
                  {specs.slice(5).map((node, index) => (
                    <React.Fragment key={index}>{node}</React.Fragment>
                  ))}
                </Stack>
              </Collapse>
            </Stack>
          </EditableContextProvider>
        </CardContent>
        <CardActions>
          <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
            <IconButton
              onClick={() => {
                setEditMode(!editMode);
              }}
            >
              <EditRoundedIcon sx={{ color: "primary.dark" }} />
            </IconButton>
            <IconButton onClick={() => setExpanded(!expanded)}>
              <ExpandCircleDownRoundedIcon
                sx={{
                  color: "primary.dark",
                  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </IconButton>
          </Stack>
        </CardActions>
      </Card>
    </Grid>
  );
};
