import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useGridApiContext } from "@mui/x-data-grid-pro";

export type TableStateSubscriptionFunction = (state: any) => void;

export interface TableStateSubscriptionProps {
   subscribe: (fn: TableStateSubscriptionFunction) => void;
   unsubscribe: (fn: TableStateSubscriptionFunction) => void;
}

export const useTableStateSubscription = (): TableStateSubscriptionProps => {
   const apiRef = useGridApiContext();
   const subscribers = useRef<TableStateSubscriptionFunction[]>([]);
   const cachedState = useRef<any>();

   const notifyAllOfNewState = () => {
      Promise.resolve().then(() => {
         const state = apiRef.current.exportState();
         cachedState.current = state;
         subscribers.current.forEach((callback) => callback(state));
      });
   };

   const debouncedHandleStateChanged = useCallback(_.debounce(notifyAllOfNewState, 500), []);

   const subscribe = (fn: TableStateSubscriptionFunction) => {
      subscribers.current.push(fn);

      if (cachedState.current) {
         fn(cachedState.current);
      }
   };

   const unsubscribe = (fn: TableStateSubscriptionFunction) => {
      subscribers.current = subscribers.current.filter((s) => s !== fn);
   };

   useEffect(() => {
      const eventListeners = [
         apiRef.current.subscribeEvent("columnOrderChange", debouncedHandleStateChanged),
         apiRef.current.subscribeEvent("columnVisibilityModelChange", debouncedHandleStateChanged),
         apiRef.current.subscribeEvent("filterModelChange", debouncedHandleStateChanged),
         apiRef.current.subscribeEvent("sortModelChange", debouncedHandleStateChanged),
         apiRef.current.subscribeEvent("columnWidthChange", debouncedHandleStateChanged),
      ];

      return () => {
         eventListeners.forEach((cleanUp) => cleanUp());
      };
   }, [apiRef.current]);

   return { subscribe, unsubscribe };
};
