import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Breakpoint, Breakpoints, Theme } from "@mui/material";

interface HideShowProps {
   at: (breakpoints: Breakpoints) => string;
   children: React.ReactNode;
}

export const Hide = (props: HideShowProps) => {
   const matches = useMediaQuery((theme: Theme) => props.at(theme.breakpoints));

   return <>{matches ? null : props.children}</>;
};

export const Show = (props: HideShowProps) => {
   const matches = useMediaQuery((theme: Theme) => props.at(theme.breakpoints));

   return <>{matches ? props.children : null}</>;
};
