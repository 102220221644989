import { LinkColumn, MetaFieldDefinition } from "common";

export type ImportType = "Assets" | "KPIs" | "Records";
export const getImportTypeDisplayName = (importType: ImportType): string => importType;

export type ImportEmptyValuesHandling = "IgnoreField" | "ClearValue";

export type ImportMode = "NoChanges" | "Error" | "Create" | "Update";
export const getImportModeDisplay = (importMode: ImportMode) =>
   importMode === "NoChanges" ? "No changes" : importMode;

export const getImportModeSortableOrder = (importMode: ImportMode) => {
   switch (importMode) {
      case "Error":
         return 1;
      case "Create":
      case "Update":
         return 2;
      case "NoChanges":
      default:
         return 3;
   }
};

export interface ImportFieldValueDelta {
   old: string;
   new: string;
}

export interface ImportCsvRowResult {
   /** row number */
   id: number;
   link: LinkColumn;
   importMode: ImportMode;
   deltas: { [id: string]: ImportFieldValueDelta };
   updatedModel: any;
   errors: string[];
}

export interface ImportCsvResult {
   rows: ImportCsvRowResult[];
   muiColumns: MetaFieldDefinition[];
}

export interface ImportCsvSaveDeltaModel {
   /** row number */
   id: number;
   updatedModel: any;
}

export interface ImportCsvSaveDeltaResultModel extends ImportCsvSaveDeltaModel {
   link: LinkColumn;
   importMode: ImportMode;
   errors: string[];
}
