import React from "react";

export const useModal = () => {
   const [open, setOpen] = React.useState(false);

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   return {
      open,
      openModal: handleClickOpen,
      closeModal: handleClose,
      modalProps: {
         open,
         onClose: handleClose,
      },
   };
};
