import _ from "lodash";
import { useGetAllComponentTemplatesRequest } from "machinery/api";
import { ComponentTemplate } from "machinery/models/componentTemplate";
import { ReactNode } from "react";

interface ComponentTemplatesLoaderProps {
  children: (params: { options: ComponentTemplate[]; loading: boolean }) => ReactNode;
}

export const ComponentTemplatesLoader = (props: ComponentTemplatesLoaderProps) => {
  const { data, loading } = useGetAllComponentTemplatesRequest();
  const templates = data ?? [];
  const options = _.orderBy(templates, (template) => template.name);
  return <>{props.children({ options, loading })}</>;
};
