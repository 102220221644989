import { Box, Stack, Typography } from "@mui/material";

export const LabeledChart = (props: { label?: string; children: React.ReactNode }) => {
  return (
    <Stack sx={{ flex: "0 1 auto", width: "100%" }}>
      <Box sx={{ height: "48px", display: "flex", alignItems: "end", justifyContent: "center", paddingTop: "8px" }}>
        {props.children}
      </Box>
      <Typography variant="caption" sx={{ textAlign: "center" }}>
        {props.label}
      </Typography>
    </Stack>
  );
};
