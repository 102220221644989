import { createContext, FunctionComponent, ReactNode } from "react";
import { useGetAllUsersRequest, User } from "users";

export interface AllUsersContextProps {
   users: User[];
   loading: boolean;
}

export const AllUsersContext = createContext<AllUsersContextProps>({ users: [], loading: true });

export const AllUsersContextProvider: FunctionComponent<{
   children?: ReactNode;
}> = (props) => {
   const { data, loading } = useGetAllUsersRequest(true);
   return <AllUsersContext.Provider value={{ users: data || [], loading }}>{props.children}</AllUsersContext.Provider>;
};
