import { Grid, Paper, Stack } from "@mui/material";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { Routes } from "app/routes";
import { useGetKpiTemplateDetailRequest, useUpdateKpiTemplateRequest } from "kpis/api";
import { KpiId, KpiTemplateDetail, KpiTemplateModel, parseKpiId } from "kpis/models";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useMatch } from "react-router-dom";
import { KpiTemplateHeader } from "./KpiTemplateHeader";
import { KpiTemplateTabSections } from "./KpiTemplateTabSections";
import { KpiTemplateRow } from "./KpiTemplateRow";

export const KpiTemplatePage = () => {
  const routeMatch = useMatch(Routes.KpiTemplate);
  if (!routeMatch?.params?.id) return null;
  const kpiId = parseKpiId(routeMatch?.params?.id);

  return <KpiTemplatePageHandler kpiId={kpiId} />;
};

const KpiTemplatePageHandler = ({ kpiId }: { kpiId: KpiId }) => {
  const { data, loading } = useGetKpiTemplateDetailRequest(kpiId);

  if (loading && !data) {
    return <EntityDetailsContainerSkeleton />;
  } else if (!data) {
    return null;
  }

  return <KpiTemplatePageWithForm kpiTemplateDetail={data} />;
};

interface KpiTemplatePageWithFormProps {
  kpiTemplateDetail: KpiTemplateDetail;
}

const KpiTemplatePageWithForm: React.FunctionComponent<KpiTemplatePageWithFormProps> = (props) => {
  const { kpiTemplateDetail } = props;
  const kpiTemplate = kpiTemplateDetail.model;
  const defaultValues = makeSafeForReactHookForm(kpiTemplate);
  const methods = useForm<KpiTemplateModel>({ defaultValues });
  const updateKpiTemplate = useUpdateKpiTemplateRequest();

  const saveChanges = (onValid?: () => void) => {
    methods.handleSubmit((values) => {
      updateKpiTemplate.call(values).then(() => {
        onValid?.();
      });
    })();
  };

  return (
    <PermissionAwareFormProvider {...methods} require={(permissions) => permissions.userCanEditKpiTemplate}>
      <AutoSave onSubmit={saveChanges} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={9}>
          <Stack spacing={2}>
            <KpiTemplateHeader kpiTemplateDetail={kpiTemplateDetail} methods={methods} saveChanges={saveChanges} />
            <KpiTemplateRow kpiTemplateDetail={kpiTemplateDetail} />
            <KpiTemplateTabSections kpiTemplateDetail={kpiTemplateDetail} saveChanges={saveChanges} />
          </Stack>
        </Grid>
      </Grid>
    </PermissionAwareFormProvider>
  );
};
