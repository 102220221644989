import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { useSiteContext } from "app";
import { useAppSettingsContext } from "app/contexts/AppSettingsContext";
import { useBackgroundReportContext } from "app/contexts/BackgroundReportContext";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import { NumberFormItem } from "app/mui/forms/Input/NumberFormItem";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { IrisColors } from "app/mui/theme";
import { useGetAssetsByIdsRequest } from "assets";
import { BulkValveHealthSummaryNodeReportParams } from "common/reports";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RecordDetailView } from "records";
import { Criticality, SiteArea } from "sites";
import { Summary } from "summaries";

type HealthMetric = "overall" | "condition" | "performance";

interface ConfigureBulkValveHealthReportModalProps extends BaseDialogProps {
  summary: Summary;
  summaryRecordDetails: RecordDetailView[];
}

export const ConfigureBulkValveHealthReportModal: React.FunctionComponent<ConfigureBulkValveHealthReportModalProps> = (
  props
) => {
  const currentSite = useSiteContext().currentSite!;

  const allRecordAssetIds = _.uniq(props.summaryRecordDetails.flatMap((r) => r.model.assets.map((a) => a.id)));
  const allRecordAssetsQuery = useGetAssetsByIdsRequest(allRecordAssetIds);

  const methods = useForm<
    Omit<BulkValveHealthSummaryNodeReportParams, "areaIds" | "criticalityIds"> & {
      areas: SiteArea[];
      criticalities: Criticality[];
    }
  >({
    defaultValues: {
      id: props.summary.id,
      pdf: true,
      includeAsFoundAsLeftBreakdown: true,
      includeBadActors: true,
      includeMovers: true,
      includeNotes: true,
      includePhotos: true,
      includeValveHealthLegend: true,
      appendPdfAttachments: true,
      includeTestResults: true,
      overall: true,
      condition: false,
      performance: false,
      minScore: 0,
      maxScore: 100,
      worstN: null,
      onlyWithOpenRecommendations: false,
      areas: [],
      criticalities: [],
    },
  });
  const { appSettings } = useAppSettingsContext();

  const areas = useMemo(() => {
    if (allRecordAssetsQuery.loading || !allRecordAssetsQuery.data) {
      return [];
    }

    const allRecordAssets = allRecordAssetsQuery.data;
    return _.orderBy(
      currentSite.areas.filter((area) => !!allRecordAssets.some((asset) => asset.model.areaId === area.id)),
      (area) => area.name
    );
  }, [allRecordAssetsQuery.loading, allRecordAssetsQuery.data]);

  const criticalities = useMemo(() => {
    if (allRecordAssetsQuery.loading || !allRecordAssetsQuery.data) {
      return [];
    }

    const allRecordAssets = allRecordAssetsQuery.data;
    return _.orderBy(
      currentSite.customer.criticalities.filter((c) => !!allRecordAssets.some((a) => a.model.criticalityId === c.id)),
      (c) => c.displayValue
    );
  }, [allRecordAssetsQuery.loading, allRecordAssetsQuery.data]);

  const { generateBulkValveReport: generateBulkValveHealthSummaryReportNode } = useBackgroundReportContext();

  const handleSubmit = useCallback(() => {
    const values = methods.getValues();
    generateBulkValveHealthSummaryReportNode?.call({
      id: props.summary.id,
      pdf: true,
      includeAsFoundAsLeftBreakdown: values.includeAsFoundAsLeftBreakdown,
      includeBadActors: values.includeBadActors,
      includeMovers: values.includeMovers,
      includeNotes: values.includeNotes,
      includeTestResults: values.includeTestResults,
      includePhotos: values.includePhotos,
      includeValveHealthLegend: values.includeValveHealthLegend,
      appendPdfAttachments: values.appendPdfAttachments,
      overall: values.overall,
      condition: values.condition,
      performance: values.performance,
      minScore: values.minScore,
      maxScore: values.maxScore,
      worstN: values.worstN,
      onlyWithOpenRecommendations: values.onlyWithOpenRecommendations,
      areaIds: values.areas.map((x) => x.id),
      criticalityIds: values.criticalities.map((x) => x.id),
    });
    props.onClose();
  }, []);

  const includeTestResults = methods.watch("includeTestResults");
  const condition = methods.watch("condition");
  const maxScore = methods.watch("maxScore");
  const minScore = methods.watch("minScore");
  const onlyWithOpenRecommendations = methods.watch("onlyWithOpenRecommendations");
  const overall = methods.watch("overall");
  const performance = methods.watch("performance");
  const worstN = methods.watch("worstN");
  const selectedAreas = methods.watch("areas");
  const selectedCriticalities = methods.watch("criticalities");

  const countSelectedValves = () => {
    const filteredRecords = props.summaryRecordDetails.filter((r) => {
      const recordAssets = allRecordAssetsQuery.data?.filter(
        (asset) => !!r.model?.assets.find((recordAsset) => recordAsset.id === asset.id)
      );
      if (selectedAreas.length > 0 && selectedAreas.every((a) => recordAssets?.[0]?.model?.areaId !== a.id)) {
        return false;
      }

      if (
        selectedCriticalities.length > 0 &&
        selectedCriticalities.every((c) => recordAssets?.[0]?.model?.criticalityId !== c.id)
      ) {
        return false;
      }

      if (
        onlyWithOpenRecommendations &&
        !r.recommendations.some((rec) => rec.status !== "Complete" && rec.status !== "Archived")
      ) {
        return false;
      }

      return true;
    });

    const scoredRecords: [RecordDetailView, number][] = [];
    filteredRecords.forEach((record) => {
      if (!record.model.health) return;

      let score: number | null = null;
      if (overall) {
        score = record.model.health.overall;
      } else if (condition) {
        score = record.model.health.condition;
      } else if (performance) {
        score = record.model.health.performance;
      }

      if (score === null || score > maxScore || score < minScore) return;

      scoredRecords.push([record, score]);
    });

    const sortOrder = overall ? "asc" : "desc";
    const recordsOrderedByScore = _.orderBy(scoredRecords, (sr) => sr[1], sortOrder);

    return recordsOrderedByScore.slice(0, worstN !== null ? worstN : undefined).length;
  };

  const getIncompleteValveCountIndicator = () => {
    const count = props.summaryRecordDetails.filter(
      (r) => !r.model.health || (r.model.health && r.model.health.overall === null)
    ).length;
    return count > 0 ? ` (${count} incomplete and excluded)` : "";
  };

  const changeHealthMetric = (metric: HealthMetric) => {
    methods.setValue("overall", metric === "overall");
    methods.setValue("condition", metric === "condition");
    methods.setValue("performance", metric === "performance");
    methods.setValue("minScore", metric === "overall" ? 0 : 1);
    methods.setValue("maxScore", metric === "overall" ? 100 : 4);
  };

  const selectedValveCount = countSelectedValves();

  return (
    <BaseDialog onClose={props.onClose} maxWidth="md">
      <DialogTitle variant="h6" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        Generate report
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={0}>
            <CheckboxFormItem fieldName="includeAsFoundAsLeftBreakdown" label="As found / as left breakdown" />
            <CheckboxFormItem fieldName="includeBadActors" label="Include top 10 bad actors" />
            <CheckboxFormItem fieldName="includeMovers" label="Include top 10 movers" />
            <CheckboxFormItem fieldName="includeNotes" label="Include notes" />
            <CheckboxFormItem fieldName="includePhotos" label="Include photos" />
            <CheckboxFormItem fieldName="includeValveHealthLegend" label="Include valve health legend" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF Attachments" />
          </Stack>
          <Divider />

          <Typography variant="h6" mt={1}>
            Test Results
          </Typography>
          <Stack spacing={4}>
            <Grid container>
              <Grid item xs={6}>
                <CheckboxFormItem fieldName="includeTestResults" label="Select valves to include in test results" />
              </Grid>
              {includeTestResults && (
                <Grid item xs={6} textAlign="left">
                  <Typography variant="h6" color={selectedValveCount === 0 ? IrisColors.red : IrisColors.graySpartan}>
                    {selectedValveCount}&nbsp;valve
                    {selectedValveCount !== 1 ? "s" : ""} selected{getIncompleteValveCountIndicator()}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {includeTestResults && (
              <>
                <Stack direction="row" spacing={8} justifyContent="space-between">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        changeHealthMetric(e.target.value as HealthMetric);
                      }}
                      value={performance ? "performance" : condition ? "condition" : "overall"}
                    >
                      <FormControlLabel value="overall" control={<Radio />} label="Overall" />
                      <FormControlLabel value="condition" control={<Radio />} label="Condition" />
                      <FormControlLabel value="performance" control={<Radio />} label="Performance" />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Score Range</FormLabel>
                    <Grid container>
                      <Grid item xs={12}>
                        <Slider
                          min={overall ? 0 : 1}
                          max={overall ? 100 : 4}
                          color="secondary"
                          value={[minScore, maxScore]}
                          onChange={(e, value) => {
                            methods.setValue("minScore", value[0]);
                            methods.setValue("maxScore", value[1]);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldFormItem fieldName="minScore" label="Minimum" />
                      </Grid>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}>
                        <TextFieldFormItem fieldName="maxScore" label="Maximum" />
                      </Grid>
                    </Grid>
                    <Box sx={{ paddingLeft: 4 }}></Box>
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <NumberFormItem fieldName="worstN" label="Worst valves (count)" />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={6}>
                      <CheckboxFormItem
                        fieldName="onlyWithOpenRecommendations"
                        label="Only valves with open recommendations"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <AutocompleteFormItem
                    label="Areas"
                    options={areas}
                    multiple
                    fieldName="areas"
                    getOptionLabel={(a) => a.name}
                    idSelector={(a) => a.id}
                    placeholder="Any"
                    autocompleteProps={{
                      disablePortal: false,
                      isOptionEqualToValue: (option, value) => option.id === value.id,
                    }}
                  />
                  <AutocompleteFormItem
                    label="Criticalities"
                    fieldName="criticalities"
                    multiple
                    options={criticalities}
                    idSelector={(a) => a.id}
                    getOptionLabel={(c) => c.displayValue}
                    placeholder="Any"
                    autocompleteProps={{
                      disablePortal: false,
                      isOptionEqualToValue: (option, value) => option.id === value.id,
                    }}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton
            loading={false}
            variant="contained"
            color="secondary"
            disabled={!appSettings}
            onClick={handleSubmit}
          >
            Generate <OpenInNewIcon sx={{ pl: 1, width: "1rem" }} />
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
