import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        placeholder={undefined}
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      />
      <GridToolbarFilterButton
        placeholder={undefined}
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      />
    </GridToolbarContainer>
  );
}
