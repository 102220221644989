import { Col, Row } from "reactstrap";
import { DateInput } from "../../inputs/DateInput";
import { DollarAmountInput } from "../../inputs/DollarAmountInput";
import { TextInput } from "../../inputs/TextInput";

interface PODetailsProps {
   userCanEdit: boolean;
}

export const PODetails = (props: PODetailsProps) => {
   return (
      <>
         <Row style={{ padding: "1rem" }}>
            <Col xl="3" lg="4">
               <DollarAmountInput label="PO amount" field="poAmount" userCanEdit={props.userCanEdit} />
            </Col>
            <Col xl="3" lg="4">
               <DateInput label="PO date" field="poDate" userCanEdit={props.userCanEdit} />
            </Col>
            <Col xl="3" lg="4">
               <TextInput label="PO number" field="poNumber" userCanEdit={props.userCanEdit} />
            </Col>
         </Row>
      </>
   );
};
