export interface FlagOption {
  id: RecordFlagType;
  name: string;
}

export type RecordFlagType = "LessonLearned" | "BigWin";

export const RecordFlags: RecordFlagType[] = ["LessonLearned", "BigWin"];
export const RecordFlagOptions: FlagOption[] = RecordFlags.map((x) => recordFlagTypeToOption(x));

export function recordFlagTypeToOption(value: RecordFlagType): FlagOption {
  return { id: value, name: recordFlagTypeToString(value) };
}

export function recordFlagTypeToString(flagType: RecordFlagType) {
  switch (flagType) {
    case "LessonLearned":
      return "Lesson Learned";
    case "BigWin":
      return "Big Win";
  }
}
