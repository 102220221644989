import { lighten, Menu, MenuItem, PopoverPosition, Skeleton, Tooltip } from "@mui/material";
import { CurrentSiteWithNavigation } from "app";
import { useUiThemeContext } from "app/contexts/UiThemeContext";
import { AssetType } from "assets/models/assetType";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ComponentTemplatesLoader } from "../loaders/componentTemplatesLoader";
import { IrisColors, theme } from "../theme";
import {
  ASSET_MENU_GAP,
  ASSET_MENU_WIDTH,
  AssetPageLink,
  createAssetTypeRoute,
  getAssetMenuPosition,
} from "./AssetsMenuButton";

interface MachineryMenuButtonProps {
  active: boolean;
  currentSiteWithNavigation: CurrentSiteWithNavigation;
  expanded: boolean;
  onClose?: () => void;
}

function createMachineryComponentRoute(currentSiteWithNavigation, componentTemplateId: number) {
  return currentSiteWithNavigation.routeTo.MachineryComponents({ templateId: componentTemplateId });
}

const getMachineryMenuPosition = (expanded: boolean): PopoverPosition => {
  const assetMenuPosition = getAssetMenuPosition(expanded);
  return { top: assetMenuPosition.top, left: assetMenuPosition.left + ASSET_MENU_WIDTH + ASSET_MENU_GAP };
};

export const MachineryMenuButton = ({
  currentSiteWithNavigation: siteNav,
  expanded,
  active,
  onClose
}: MachineryMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const isDarkTheme = useUiThemeContext().navbar === "dark";
  const bgColor = isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper;
  const fillColor = isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;

  const label = "Machinery";
  return (
    <>
      <Tooltip title={expanded ? "" : label} placement="right">
        <MenuItem
          onClick={handleOpenMenu}
          component={Link}
          to="#"
          sx={{
            px: "12px",
            height: 48,
            justifyContent: "initial",
            color: active ? bgColor : fillColor,
            background: active ? fillColor : bgColor,
            "&:hover": {
              background: active ? fillColor : lighten(bgColor, 0.2),
            },
          }}
        >
          {label}
        </MenuItem>
      </Tooltip>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={getMachineryMenuPosition(expanded)}
        sx={{
          "& .MuiMenu-paper": {
            background: bgColor,
            padding: "10px",
          },
        }}
      >
        <AssetPageLink
          handleClose={handleClose}
          label="All Machinery Assets"
          link={createAssetTypeRoute(siteNav, AssetType.Machinery)}
          active={false}
        />
        <ComponentTemplatesLoader>
          {({ options, loading }) => {
            return loading ? (
              <MenuSkeleton />
            ) : (
              options.map((template) => (
                <MachineryPageLink
                  key={template.id}
                  handleClose={handleClose}
                  label={template.name}
                  link={createMachineryComponentRoute(siteNav, template.id)}
                  active={false}
                />
              ))
            );
          }}
        </ComponentTemplatesLoader>
      </Menu>
    </>
  );
};

const MachineryPageLink = (props: { label: string; link: string; handleClose: () => void; active: boolean }) => {
  const isDarkTheme = useUiThemeContext().navbar === "dark";
  const bgColor = isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper;
  const fillColor = isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;

  return (
    <MenuItem
      onClick={props.handleClose}
      component={Link}
      to={props.link}
      sx={{
        px: "12px",
        height: 48,
        justifyContent: "initial",
        color: props.active ? bgColor : fillColor,
        background: props.active ? fillColor : bgColor,
        borderRadius: "5px",
        margin: "2px",
        "&:hover": {
          background: lighten(bgColor, 0.2),
        },
      }}
    >
      {props.label}
    </MenuItem>
  );
};

const MenuSkeleton = () => {
  return (
    <>
      {Array(15)
        .fill(1)
        .map(() => (
          <Skeleton height={48} sx={{ bgcolor: IrisColors.gray600 }}></Skeleton>
        ))}
    </>
  );
};
