import React, { useCallback, useContext, useState } from "react";
import { Box, Button, Stack, Tab, Tabs, Typography, Avatar, InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AddIcon } from "icons/AddIcon";
import { ActionsDropdownMenu, createActionMenu, useActionMenu } from "app/mui/ActionsDropdownMenu";
import { useCreateSiteAreaRequest, useCreateSiteConfigValueRequest, useUpdateSiteRequest } from "../api";
import { FormProvider, useForm } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { Site, KpiRateRoleOptions } from "sites/models";
import { UsersTab } from "./tabs/UsersTab";
import { TabPanel } from "app/mui/tabs/TabPanel";
import { AreasTab } from "./tabs/AreasTab";
import { OutageSettingsTab } from "./tabs/OutageSettingsTab";
import { KpiSettingsTab } from "./tabs/KpiSettingsTab";
import { useModal } from "app/mui/common/useModal";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { useGetUsersInSiteRequest } from "users/api";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { AllUsersContext } from "../allUsersContext";
import { User } from "users";
import GpsFormItem from "app/mui/forms/Input/GpsFormItem";

interface SiteAdministrationPanelProps {
  site: Site;
}

const TeamActionMenu = createActionMenu((dispatcher) => [
  {
    icon: <AddIcon />,
    label: "Add Team Member",
    action: () => dispatcher.dispatch("add-user"),
  },
]);

const AreasActionMenu = createActionMenu((dispatcher) => [
  {
    icon: <AddIcon />,
    label: "Add Area",
    action: () => dispatcher.dispatch("add-area"),
  },
]);

const KpiSettingActionMenu = createActionMenu((dispatcher) => [
  {
    icon: <AddIcon />,
    label: "Add KPI setting",
    action: () => dispatcher.dispatch("add-kpi"),
  },
]);

export const SiteAdministrationPanel = (props: SiteAdministrationPanelProps) => {
  const actionDispatcher = useActionDispatcher();

  const areaModal = useModal();
  const usersModal = useModal();
  const kpisModal = useModal();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabChanged = (_event: any, index: number) => {
    setTabIndex(index);
  };

  const actionMessageRecieved = useCallback(
    (action: string) => {
      if (action === "add-user") {
        usersModal.openModal();
      }
      if (action === "add-area") {
        areaModal.openModal();
      }
      if (action === "add-kpi") {
        kpisModal.openModal();
      }
    },
    [usersModal, areaModal]
  );

  React.useEffect(() => {
    actionDispatcher.subscribe(actionMessageRecieved);
    return () => actionDispatcher.unsubscribe(actionMessageRecieved);
  }, []);

  const usersMenu = useActionMenu(TeamActionMenu, actionDispatcher);
  const areasMenu = useActionMenu(AreasActionMenu, actionDispatcher);
  const kpisMenu = useActionMenu(KpiSettingActionMenu, actionDispatcher);

  const allMenus = [usersMenu, areasMenu, kpisMenu];

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack>
            <Typography variant="h5" fontWeight="bold" fontFamily="Montserrat">
              Site Administration
            </Typography>
          </Stack>
          {tabIndex !== 3 && <ActionsDropdownMenu actions={allMenus[tabIndex]} />}
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, width: "100%" }}>
          <Tabs value={tabIndex} onChange={handleTabChanged}>
            <Tab label="Team" />
            <Tab label="Areas" />
            <Tab label="KPI Settings" />
            <Tab label="Outage Settings" />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <UsersTab site={props.site} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <AreasTab site={props.site} />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <KpiSettingsTab site={props.site} />
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <OutageSettingsTab site={props.site} />
        </TabPanel>
      </Stack>
      {usersModal.open && <AddUserModal {...usersModal.modalProps} site={props.site} />}
      {areaModal.open && <AddAreaModal {...areaModal.modalProps} site={props.site} />}
      {kpisModal.open && <AddKpiSettingModal {...kpisModal.modalProps} site={props.site} />}
    </>
  );
};

interface AddUserModalProps {
  site: Site;
  open: boolean;
  onClose: () => void;
}

const AddUserModal = (props: AddUserModalProps) => {
  const methods = useForm({ defaultValues: { users: [] as User[] } });
  const updateSiteRequest = useUpdateSiteRequest();

  const { data: currentUsers, loading: loadingCurrent } = useGetUsersInSiteRequest(props.site.id);
  const { users: allUsers, loading: loadingAll } = useContext(AllUsersContext);

  const options = allUsers.filter((user) => !currentUsers?.map((u) => u.userId).includes(user.userId));

  const submit = () => {
    methods.handleSubmit(async (values) => {
      await updateSiteRequest.call({
        ...props.site,
        team: [...props.site.team, ...values.users],
      });
      props.onClose();
    })();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth={true}>
      <FormProvider {...methods}>
        <DialogTitle>Add users</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2">Select users to add to the team</Typography>
            <AutocompleteFormItem
              options={options}
              idSelector={(user) => user.userId}
              getOptionLabel={(user) => user.fullName}
              multiple
              loading={loadingCurrent || loadingAll}
              fieldName="users"
              label="Users"
              renderOption={(optionProps, option, _state) => {
                return (
                  <li {...{ ...optionProps, key: option.userId }}>
                    <Stack spacing={2} alignItems="center" direction="row">
                      <Avatar src={option.picture} alt={option.fullName} />
                      <Typography variant="body2">{option.fullName}</Typography>
                    </Stack>
                  </li>
                );
              }}
              autocompleteProps={{
                disablePortal: false,
                isOptionEqualToValue: (option, value) => option.userId === value.userId,
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={submit} color="secondary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

interface AddAreaModalProps {
  site: Site;
  open: boolean;
  onClose: () => void;
}

const AddAreaModal = (props: AddAreaModalProps) => {
  const methods = useForm();

  const createAreaRequest = useCreateSiteAreaRequest(props.site.id);

  const submit = () => {
    methods.handleSubmit(async (values) => {
      if (!values.name) {
        return;
      }
      await createAreaRequest.call({
        name: values.name,
        location: values.location,
      });

      props.onClose();
    })();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <DialogTitle>Create New Area</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <TextFieldFormItem fieldName="name" label={"Name"} textFieldProps={{ required: true }} hint="required" />
            <GpsFormItem fieldName="location" label={"GPS coordinates"} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={submit} color="secondary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

interface AddKpiSettingModalProps {
  site: Site;
  open: boolean;
  onClose: () => void;
}

const AddKpiSettingModal = (props: AddKpiSettingModalProps) => {
  const methods = useForm();

  const createKpiSetting = useCreateSiteConfigValueRequest(props.site.id);

  const submit = () => {
    methods.handleSubmit(async ({ value, name, effective }) => {
      if (!value || !name || !effective) {
        return;
      }

      await createKpiSetting.call({
        siteId: props.site.id,
        value,
        name,
        effective,
      });

      props.onClose();
    })();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <DialogTitle>Create KPI Setting</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <AutocompleteFormItem
              fieldName="name"
              label="Role"
              options={KpiRateRoleOptions}
              idSelector={(o) => o.value}
              hint="required"
            />
            <TextFieldFormItem
              fieldName="value"
              label={"Rate"}
              hint="required"
              textFieldProps={{
                InputProps: { endAdornment: <InputAdornment position="end">$/hr</InputAdornment> },
              }}
            />
            <DateInputFormItem fieldName="effective" label={"Effective"} hint="required" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={submit} color="secondary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
