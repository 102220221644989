import { ReactNode } from "react";
import { useSiteContext } from "app/contexts";
import { Criticality } from "sites";

interface CriticalitiesLoaderProps {
   children: (params: { criticalities: Criticality[] }) => ReactNode;
}

export const CriticalitiesLoader = (props: CriticalitiesLoaderProps) => {
   const { currentSite } = useSiteContext();
   const data = currentSite?.customer?.criticalities?.sort((a,b) => a.calculationValue - b.calculationValue) ?? [];

   return <>{props.children({ criticalities: data })}</>;
};
