import { AppRoutes } from "app/AppRoutes";
import { AxiosSetup } from "app/AxiosSetup";
import { AutoEnsureLoggedIn } from "app/AutoEnsureLoggedIn";
import { ApiStatusContextProvider, SiteContextProvider, UserContextProvider, NotificationProvider } from "app";
import { SnackbarProvider } from "notistack";
import { ScopedCssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./mui/theme";
import { Auth0ProviderWithRedirectCallback } from "./contexts/Auth0ProviderWithRedirectCallback";
import { AppInsightsContextProvider } from "./contexts/AppInsightsContextProvider";
import { AppSettingsContextProvider } from "./contexts/AppSettingsContext";
import { IrisErrorBoundary } from "./IrisErrorBoundary";
import { FeatureFlagsContextProvider } from "./contexts/FeatureFlagsContext";
import { UiThemeContextProvider } from "./contexts/UiThemeContext";
import { BackgroundReportContextProvider } from "./contexts/BackgroundReportContext";

export const App = () => {
  return (
    <ScopedCssBaseline sx={{ minHeight: "100vh", width: "100%" }}>
      <ThemeProvider theme={theme}>
        <AppInsightsContextProvider>
          <IrisErrorBoundary boundaryName="app">
            <Auth0ProviderWithRedirectCallback>
              <SnackbarProvider
                maxSnack={3}
                preventDuplicate
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <NotificationProvider>
                  <AutoEnsureLoggedIn>
                    <AxiosSetup>
                      <UserContextProvider>
                        <AppSettingsContextProvider>
                          <FeatureFlagsContextProvider>
                            <UiThemeContextProvider>
                              <SiteContextProvider>
                                <ApiStatusContextProvider>
                                  <BackgroundReportContextProvider>
                                    <AppRoutes />
                                  </BackgroundReportContextProvider>
                                </ApiStatusContextProvider>
                              </SiteContextProvider>
                            </UiThemeContextProvider>
                          </FeatureFlagsContextProvider>
                        </AppSettingsContextProvider>
                      </UserContextProvider>
                    </AxiosSetup>
                  </AutoEnsureLoggedIn>
                </NotificationProvider>
              </SnackbarProvider>
            </Auth0ProviderWithRedirectCallback>
          </IrisErrorBoundary>
        </AppInsightsContextProvider>
      </ThemeProvider>
    </ScopedCssBaseline>
  );
};
