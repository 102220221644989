import {
   Box,
   Link,
   Paper,
   Typography,
} from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useActionDispatcher, ActionDispatcherProvider } from "app/mui/ActionDispatcher";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { createStaticColumns, createStaticRowActions, useColumns, useStaticRowActions } from "app/mui/tables/utils/createStaticColumns";
import moment from "moment";
import { formatRoutePath, Routes, useSiteIdFromRoute } from "app/routes";
import { useGetOfflineDataImportRequest } from "./api";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { OfflineDataImportStatusEnum, OfflineDataImportViewModel } from "@bluemarvel/iris-common/model";
import { useCallback, useEffect } from "react";


const rowActions = createStaticRowActions((actionDispatcher) => (params) => [
   {
      onClick: () => {
         actionDispatcher.dispatch("viewDetails", params.row);
      },
      label: "View Details",
      showInMenu: true,
      disabled: params.row.status !== OfflineDataImportStatusEnum.Values["Sync Completed"]
   },
]);

export const ImportOfflinePage = () => {

   const navigate = useNavigate();
   const actionDispatcher = useActionDispatcher();
   const getTableRowActions = useStaticRowActions(rowActions, actionDispatcher);

   const siteId = useSiteIdFromRoute()!;
   const getOfflineDataImportRequest = useGetOfflineDataImportRequest(siteId);
   const offlineDataImports = getOfflineDataImportRequest.data || [];

   const columnDef = createStaticColumns(() => [
      {
         field: "date",
         headerName: "Date",
         valueGetter: (params) => params.row.id,
         flex: 2,
         renderCell: (params) => {
            return (
               params.row.status === OfflineDataImportStatusEnum.Values["Sync Completed"] ?
                  <Typography variant="body2">
                     {moment(params.row.created).format("MM/DD/YYYY")}
                  </Typography>
                  :
                  <Link
                     color="secondary"
                     component={RouterLink}
                     to={formatRoutePath(Routes.ImportOfflineCreate, params.row as OfflineDataImportViewModel)}
                     sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
                     state={{ returnTo: window.location.pathname }}
                  >
                     {moment(params.row.created).format("MM/DD/YYYY")}
                  </Link >
            );
         },
      },
      {
         field: "time",
         headerName: "Time",
         flex: 1,
         type: "string",
         valueGetter: (params) => moment(params.row.created).format("HH:mm:ss"),
      },
      {
         field: "uploadedBy",
         headerName: "Uploaded by",
         flex: 1,
         type: "string",
         valueGetter: (params) => params.row.createdBy?.fullName,
      },
      {
         field: "status",
         headerName: "Status",
         flex: 1,
         type: "string",
         valueGetter: (params) => params.row.status,
      },
      {
         field: "assetSynced",
         headerName: "Asset Synced",
         flex: 1,
         type: "number",
         valueGetter: (params) => params.row.assetSynced,
      },
      {
         field: "totalAssets",
         headerName: "Total Assets",
         flex: 1,
         type: "number",
         valueGetter: (params) => params.row.totalAssets,
      },
      {
         field: "photos",
         headerName: "Photos",
         flex: 1,
         type: "number",
         valueGetter: (params) => params.row.photosCount,
      },
      {
         field: "actions",
         type: "actions",
         getActions: getTableRowActions,
         hideable: false,
      },
   ]);

   const columns = useColumns(columnDef, actionDispatcher);
   const apiRef = useGridApiRef();

   const handleAction = useCallback(
      (action: string, params: any) => {
         if (action === "viewDetails") {
            navigate(formatRoutePath(Routes.ImportOfflineConfirm, { siteId, id: params.id }))
         }
      },
      []
   );

   useEffect(() => {
      const unsubscribe = actionDispatcher.subscribe(handleAction);
      return () => unsubscribe();
   }, []);

   return (
      <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
         <Paper sx={{ p: 2 }}>
            <Box sx={{ height: "700px", width: "100%" }}>
               <StripedDataGrid
                  apiRef={apiRef}
                  density="compact"
                  loading={getOfflineDataImportRequest.loading}
                  columns={columns}
                  rows={offlineDataImports}
                  initialState={{
                     sorting: {
                        sortModel: [{ field: "date", sort: "desc" }],
                     },
                  }}
               />
            </Box>
         </Paper>
      </ActionDispatcherProvider>

   );
};
