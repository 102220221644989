import * as yup from "yup";

export const SimplifiedKpiOneTimeLabourOrEquipmentCostSavingsValidationSchema = yup.object({
  simplifiedPotentialCost: yup.number().nullable().required("Required").moreThan(0),
  simplifiedActualCost: yup.number().nullable().required("Required").moreThan(0),
});

export const KpiOneTimeLabourOrEquipmentCostSavingsValidationSchema = yup.object({
  multiplier: yup.number().nullable().required("Required"),
  spartanHours: yup.number().nullable().required("Required"),
  customerHours: yup
    .number()
    .nullable()
    .when("potentialHours", {
      is: (value: number) => !!value,
      then: yup.number().nullable().required("Required"),
    }),
  actualEquipmentCost: yup.number().nullable(),
  potentialHours: yup.number().nullable(),
  remoteHours: yup
    .number()
    .nullable()
    .test("is-valid", "Remote hours cannot be higher than labour hours", function (value) {
      const { spartanHours, remoteHours } = this.parent;
      return !spartanHours || (remoteHours !== null && remoteHours >= 0 && remoteHours <= spartanHours);
    }),
  potentialEquipmentCost: yup.number().nullable(),
  savingsCategory: yup.string().nullable(),
  dedicatedTripsAvoided: yup.bool().required("Required"),
});
