import { DragDropContext, DropResult, Droppable } from "@hello-pangea/dnd";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Paper, Stack } from "@mui/material";
import { useUserContext } from "app";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionMenuActionType, ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { AddIcon } from "icons/AddIcon";
import { sortBy } from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useGetRecommendationsRequest, useUpdateRecordRecommendationRequest } from "records/api";
import { Recommendation, RecordDetailView, Status } from "records/models";
import { AddRecordRecommendationDialog } from "../dialogs/AddRecordRecommendation";
import { DeleteRecommendationDialog } from "../dialogs/DeleteRecommendation";
import { RecordRecommendationCard } from "./recommendations/RecordRecommendationCard";

interface RecordRecommendationsProps {
  recordDetail: RecordDetailView;
  resetRecordStatus: (status: Status) => void;
}

type DialogActionsType = "addRecommendation" | "deleteRecommendation" | null;
const validDialogActions = ["addRecommendation", "deleteRecommendation"];

export const RecordRecommendations: FC<RecordRecommendationsProps> = (props) => {
  const { recordDetail, resetRecordStatus } = props;
  const record = recordDetail.model;

  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const getRecommendationsRequest = useGetRecommendationsRequest(record.id);
  const updateRecommendationRequest = useUpdateRecordRecommendationRequest(record.id);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<number[]>([]);
  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);
  const [recommendationForDialog, setRecommendationForDialog] = useState<Recommendation>();

  const refreshRecommendationsList = () => {
    getRecommendationsRequest.call();
  };

  const handleAction = useCallback((action: string, params: any) => {
    if (validDialogActions.indexOf(action) > -1) {
      if (action === "deleteRecommendation") {
        setRecommendationForDialog(params);
      }

      setOpenDialog(action as DialogActionsType);
    }
  }, []);
  const closeDialog = () => {
    setOpenDialog(null);
  };

  const { userPermissions } = useUserContext();
  const userCanEdit = userPermissions.userCanEditRecord(
    record.assets.map((asset) => asset.assetType),
    record.recordType
  );

  const recordIsComplete = useMemo(() => recordDetail.model.status === "Complete", [recordDetail.model.status]);
  const recordIsArchived = useMemo(() => recordDetail.model.status === "Archived", [recordDetail.model.status]);
  const isClosed = useMemo(() => recordIsComplete || recordIsArchived, [recordIsComplete, recordIsArchived]);
  const actionDispatcher = useActionDispatcher();

  const allRecommendationsExpanded = detailPanelExpandedRowIds.length === recommendations.length;

  const toggleExpand = useCallback((id: number) => {
    setDetailPanelExpandedRowIds((prev) => {
      const newExpanded = [...prev];
      const index = newExpanded.indexOf(id);
      if (index === -1) {
        newExpanded.push(id);
      } else {
        newExpanded.splice(index, 1);
      }
      return newExpanded;
    });
  }, []);

  useEffect(() => {
    if (getRecommendationsRequest.loading) {
      return;
    }

    setRecommendations(sortBy(getRecommendationsRequest.data, (x) => x.priority) || []);
  }, [getRecommendationsRequest.loading]);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const actions = [
    {
      icon: <AddIcon />,
      label: "Add Recommendation",
      action: () => actionDispatcher.dispatch("addRecommendation"),
    },
    {
      icon: allRecommendationsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />,
      label: allRecommendationsExpanded ? "Collapse All" : "Expand All",
      action: () => {
        if (allRecommendationsExpanded) {
          setDetailPanelExpandedRowIds([]);
        } else {
          const idsToExpand = recommendations.map((r) => r.id);
          setDetailPanelExpandedRowIds(idsToExpand);
        }
      },
      hidden: recommendations.length === 0,
    },
  ] as ActionMenuActionType[];

  const handleRowOrderChange = useCallback(
    (params: { id: number; targetIndex: number; originalIndex: number }) => {
      const originalRecommendation = recommendations.find((a) => a.id === params.id);

      const newRecommendation = {
        ...originalRecommendation,
        priority: params.targetIndex + 1,
      } as Recommendation;

      if (params.targetIndex !== params.originalIndex) {
        updateRecommendationRequest.call(newRecommendation).then((recordDetails) => {
          const newRecommendations = recordDetails.recommendations;
          setRecommendations(sortBy(newRecommendations, (x) => x.priority));
        });
      }
    },
    [updateRecommendationRequest]
  );

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    handleRowOrderChange({
      id: recommendations[source.index].id,
      originalIndex: source.index,
      targetIndex: destination.index,
    });
  };

  const saveRecommendation = useCallback(
    (newRecommendation: Recommendation) => {
      updateRecommendationRequest.call(newRecommendation).then((recordDetails) => {
        if (!!recordDetails) {
          resetRecordStatus(recordDetails.model.status);

          // by using key in rendering, we can prevent rerenders of components
          setRecommendations(sortBy(recordDetails.recommendations, (x) => x.priority));
        }
      });

      return newRecommendation;
    },
    [updateRecommendationRequest]
  );

  return (
    <>
      <Paper>
        <ExpansionPanel
          title="RECOMMENDATIONS"
          defaultExpanded={false}
          rightSideComponent={(userCanEdit || !isClosed) && <ActionsDropdownMenu actions={actions} />}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <Stack direction="column" ref={provided.innerRef} {...provided.droppableProps}>
                  {recommendations.map((recommendation: Recommendation, index: number) => (
                    <RecordRecommendationCard
                      assets={record.assets}
                      recordType={record.recordType}
                      recommendation={recommendation}
                      index={index}
                      key={recommendation.id}
                      expanded={detailPanelExpandedRowIds.includes(recommendation.id)}
                      onExpandChange={() => toggleExpand(recommendation.id)}
                      canEdit={userCanEdit}
                      isClosed={isClosed}
                      onSave={saveRecommendation}
                      doRefresh={refreshRecommendationsList}
                    />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </ExpansionPanel>
      </Paper>
      {openDialog === "addRecommendation" && (
        <AddRecordRecommendationDialog
          onClose={closeDialog}
          record={recordDetail}
          onAddRecommendation={refreshRecommendationsList}
        />
      )}
      {openDialog === "deleteRecommendation" && (
        <DeleteRecommendationDialog
          onClose={closeDialog}
          recommendation={recommendationForDialog!}
          onDeleteRecommendation={refreshRecommendationsList}
        />
      )}
    </>
  );
};
