import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { FlagsLoader } from "app/mui/loaders/FlagsLoader";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { FlagOption, RecordFlagType, recordFlagTypeToOption, recordFlagTypeToString } from "flags/models";
import { useState } from "react";
import { useChangeRecordFlags } from "records";

interface RecordFlagsControlProps {
  recordId: number;
  modelFlags: RecordFlagType[];
  readOnly?: boolean;
}

export const RecordFlagsControl = (props: RecordFlagsControlProps) => {
  const { recordId, modelFlags, readOnly } = props;

  const [isLoading, setIsLoading] = useState(false);
  const setRecordFlags = useChangeRecordFlags();

  const handleOnChange = (_event: React.SyntheticEvent, value: FlagOption[]) => {
    var selectedFlags = value.map((x) => x.id as RecordFlagType);
    setIsLoading(true);

    setRecordFlags.call({ recordId: recordId, flagTypes: selectedFlags }).finally(() => {
      setIsLoading(false);
    });
  };

  return readOnly ? (
    <FormItemLayout label="Flags">
      {() =>
        modelFlags?.length ? (
          modelFlags.map((flag) => (
            <Typography variant="body2" fontWeight="bold" lineHeight="1">
              {recordFlagTypeToString(flag)}
            </Typography>
          ))
        ) : (
          <Typography variant="body2" fontWeight="bold" lineHeight="1">
            —
          </Typography>
        )
      }
    </FormItemLayout>
  ) : (
    <FlagsLoader>
      {({ options }) => (
        <FormItemLayout label="Flags">
          {(inputProps) => (
            <Autocomplete
              disabled={isLoading}
              multiple
              size={"small"}
              options={options}
              getOptionLabel={(o) => o.name}
              onChange={handleOnChange}
              value={modelFlags.map((f) => recordFlagTypeToOption(f))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params: AutocompleteRenderInputParams) => <TextField {...inputProps} {...params} />}
            />
          )}
        </FormItemLayout>
      )}
    </FlagsLoader>
  );
};
