import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { FormGroup, Label, Input, ModalFooter, Button } from "reactstrap";
import classNames from "classnames";
import { ApplicationDigest } from "./models";
import { getValidity } from "common";
import { FunctionComponent, useState } from "react";
import { useNotifications } from "app/contexts";
import { useSiteIdFromRoute } from "app/routes";
import { useCreateApplicationRequest } from "./api";

interface Props {
   close: () => void;
   createdApplication: (application: ApplicationDigest) => void;
}

export const CreateApplicationModal: FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const siteId = useSiteIdFromRoute()!;
   const createApplicationRequest = useCreateApplicationRequest();
   const [name, setName] = useState("");
   const nameValidity = getValidity(!!name.trim());
   const [showValidation, setShowValidation] = useState(false);

   return (
      <Modal
         className="create-application-modal"
         isOpen={true}
         toggle={props.close}
         container={"#bootstrap-modal-container"}
      >
         <ModalHeader>Create application</ModalHeader>
         <ModalBody>
            <FormGroup>
               <Label>Name</Label>
               <Input
                  className={classNames({ "is-invalid": showValidation && !nameValidity.valid })}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required={true}
               />
               <div className="invalid-feedback">{nameValidity.message}</div>
            </FormGroup>
         </ModalBody>
         <ModalFooter>
            <Button
               color="primary"
               onClick={async () => {
                  setShowValidation(true);
                  if (!nameValidity.valid) {
                     return;
                  }

                  createApplicationRequest
                     .call({
                        name,
                        siteId,
                     })
                     .then((application) => {
                        if (!!application) {
                           notifications.success("Application created.");
                           props.createdApplication(application);
                        }
                     });
               }}
               disabled={createApplicationRequest.loading || !nameValidity.valid}
            >
               Create application
            </Button>
            <Button color="secondary" onClick={props.close} disabled={createApplicationRequest.loading}>
               Cancel
            </Button>
         </ModalFooter>
      </Modal>
   );
};
