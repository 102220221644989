import { AutocompleteFormItem, AutocompleteFormItemProps, Option } from "app/mui/forms/AutocompleteFormItem";
import { KpiSavingsCategoryOptions } from "kpis/models";

interface SavingsCategoryFormItemProps extends Omit<AutocompleteFormItemProps<false, false, Option>, "options"> {
  readOnly?: boolean;
}

function SavingsCategoryFormItem(props: SavingsCategoryFormItemProps) {
  const { label = "Savings Category", readOnly, autocompleteProps, ...rest } = props;

  return (
    <AutocompleteFormItem
      label={label}
      options={KpiSavingsCategoryOptions}
      idSelector={(option) => option.label}
      autocompleteProps={{ disabled: readOnly, ...autocompleteProps }}
      {...rest}
    />
  );
}

export default SavingsCategoryFormItem;
