import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { SpecOptionLoader } from "assets/mui/sections/specifications/SpecOptionLoader";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { useUpdateRegulatorSpecificationRequest } from "assets/api";
import { Asset, RegulatorSpec } from "assets/models/asset";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";

interface RegulatorSpecificationCardProps {
  asset: Asset;
  regulator: RegulatorSpec;
}

export const RegulatorSpecificationCard = (props: RegulatorSpecificationCardProps) => {
  const updateSpecificationRequest = useUpdateRegulatorSpecificationRequest();

  const defaultValues = makeSafeForReactHookForm(props.regulator);
  const methods = useForm({ defaultValues });

  const updateRegulator = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <SpecOptionLoader type="ValveOrElementSize">
      {(options) => <EditableAutocompleteFormItem label="Size" fieldName="size" {...options} />}
    </SpecOptionLoader>,
    <SpecOptionLoader type="PressureClass">
      {(options) => <EditableAutocompleteFormItem label="Rating" fieldName="rating" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="Orifice size" fieldName="orificeSize" />,
    <EditableTextFieldFormItem label="Spring range" fieldName="springRange" />,
    <EditableTextFieldFormItem label="Setpoint" fieldName="setpoint" />,
    <SpecOptionLoader type="SetPressureUnits">
      {(options) => <EditableAutocompleteFormItem label="Setpoint units" fieldName="setpointUnits" {...options} />}
    </SpecOptionLoader>,
  ];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave onSubmit={updateRegulator} />
      <AssetSpecificationCard title="Regulator" specs={fields} />
    </PermissionAwareFormProvider>
  );
};
