import _ from "lodash";
import colors from "assets/customColors";
import React, { useCallback, useEffect } from "react";
import { Box, Popover, Stack, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import { gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { usePopover } from "app/mui/common/usePopover";
import { compactWholeNumberFormatter, wholeNumberFormatter } from "common";
import { KpiValueWithRecordDetailViewModel, toKpiTypeDisplayName, ValueKpiType } from "kpis/models";
import { ChartsContainer } from "./ChartsContainer";

const graphColors = [
  colors.congressBlue,
  colors.seance,
  colors.roti,
  colors.brightTurquoise,
  colors.frenchRose,
  colors.illusion,
  colors.pizzaz,
  colors.lightningYellow,
  colors.robinsEggBlue,
  colors.forestGreen,
];

export const useTabelRowDataExtractor = (apiRef: React.MutableRefObject<GridApiPro>) => {
  const [rows, setRows] = React.useState<KpiValueWithRecordDetailViewModel[] | null>(null);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const updateData = () => {
    //a datagird loading can be long process so we need to show a loading indicator. It happens after API call is finished.
    setGridLoading(true);
    setRows(gridFilteredSortedRowEntriesSelector(apiRef).map((r) => r.model as KpiValueWithRecordDetailViewModel));
    setGridLoading(false);
  };

  const debouncedHandleStateChanged = useCallback(_.debounce(updateData, 500), []);

  useEffect(() => {
    if (!apiRef.current || !apiRef.current.subscribeEvent) {
      return;
    }
    setGridLoading(true);
    const eventListeners = [apiRef.current.subscribeEvent("stateChange", debouncedHandleStateChanged)];
    return () => {
      eventListeners.forEach((cleanUp) => cleanUp());
    };
  }, [apiRef.current]);

  return { rows, gridLoading };
};

interface ChartItemDataFormat {
  id: any;
  label: string;
  value: number;
  color?: string;
  type?: string;
}

const useStatusData = (data: KpiValueWithRecordDetailViewModel[]): ChartItemDataFormat[] => {
  const statusesMap = {
    signed: { id: "signed", label: "Signed", value: 0, color: graphColors[0] },
    unsigned: { id: "unsigned", label: "Unsigned", value: 0, color: graphColors[1] },
  };

  data.forEach((d) => {
    if (d.kpi?.model?.signedOff) {
      statusesMap["signed"].value = statusesMap["signed"].value + 1;
    } else {
      statusesMap["unsigned"].value = statusesMap["unsigned"].value + 1;
    }
  });

  return Object.values(statusesMap);
};

const useSavingsAmountData = (data: KpiValueWithRecordDetailViewModel[]) => {
  const statusesMap = {
    signed: { id: "signed", label: "Signed", value: 0, color: graphColors[0], type: "currency" },
    unsigned: { id: "unsigned", label: "Unsigned", value: 0, color: graphColors[1], type: "currency" },
  };

  data.forEach((d) => {
    const savings = d.kpi?.calculatedSavings;
    if (d.kpi?.model?.signedOff) {
      statusesMap["signed"].value = statusesMap["signed"].value + savings;
    } else {
      statusesMap["unsigned"].value = statusesMap["unsigned"].value + savings;
    }
  });

  return statusesMap;
};

const useKpiSavingsTypesData = (data: KpiValueWithRecordDetailViewModel[]) => {
  const stats: { [key: string]: any } = {};

  data.forEach((d) => {
    const kpiType = d.kpi?.kpiType?.toString() as ValueKpiType;
    const key = toKpiTypeDisplayName(kpiType, true);
    if (!stats[key]) {
      stats[key] = 0;
    }

    stats[key] += 1;
  });

  return [stats];
};

export const KpiListDataViz = (props: { apiRef: React.MutableRefObject<GridApiPro>; loading: boolean | null }) => {
  const { rows, gridLoading } = useTabelRowDataExtractor(props.apiRef);
  const total = rows?.length || 0;
  const savingsTypes = useKpiSavingsTypesData(rows || []);
  const statuses = useStatusData(rows || []);
  const savingsAmount = useSavingsAmountData(rows || []);

  return (
    <ChartsContainer loading={props.loading || gridLoading} totalNumberOfRows={rows ? rows.length : null}>
      <TotalKpisCountBarChart data={total} />
      <KpiSavingsTypeBarChart data={savingsTypes} />
      <KpisByStatusPieChart data={statuses} />
      <KpisSavingsAmountChart data={savingsAmount} />
    </ChartsContainer>
  );
};

const LabeledChart = (props: { label: string; children: React.ReactNode }) => {
  return (
    <Stack sx={{ flex: "0 1 auto", width: "100%" }}>
      <Box sx={{ height: "48px", display: "flex", alignItems: "end", justifyContent: "center", paddingTop: "8px" }}>
        {props.children}
      </Box>
      <Typography variant="caption" sx={{ textAlign: "center" }}>
        {props.label}
      </Typography>
    </Stack>
  );
};

export const TotalKpisCountBarChart = (props: { data: number }) => {
  return (
    <LabeledChart label="Kpis">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          background: graphColors[0],
          color: "white",
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        {props.data}
      </Typography>
    </LabeledChart>
  );
};

export const KpiSavingsTypeBarChart = (props: { data: any[] }) => {
  return (
    <LabeledChart label="Savings Types">
      <MyResponsiveBar data={props.data} />
    </LabeledChart>
  );
};

export const KpisByStatusPieChart = (props: { data: ChartItemDataFormat[] }) => {
  return (
    <LabeledChart label="Status">
      <MyResponsivePie data={props.data} />
    </LabeledChart>
  );
};

export const KpisSavingsAmountChart = (props: {
  data: { [key: string]: { id: string; label: string; value: number } };
}) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <LabeledChart label="Savings">
      <Box onMouseEnter={openPopover} onMouseLeave={closePopover} height="100%">
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            background: graphColors[0],
            color: "white",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          {`$${compactWholeNumberFormatter.format(
            Object.values(props.data)
              .map((d) => d.value)
              .reduce((a, b) => a + b)
          )}`}
        </Typography>
      </Box>
      <HoverTooltip {...popoverProps} allData={Object.values(props.data)} />
    </LabeledChart>
  );
};

const MyResponsivePie = ({ data }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <Box onMouseEnter={openPopover} onMouseLeave={closePopover} width="100%" height="100%">
      <ResponsivePie
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        startAngle={-90}
        endAngle={90}
        borderWidth={0}
        isInteractive={false}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={graphColors}
        legends={[]}
        animate={false}
      />
      <HoverTooltip {...popoverProps} allData={data} />
    </Box>
  );
};

const MyResponsiveBar = ({ data }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <Box onMouseEnter={openPopover} onMouseLeave={closePopover} width="100%" height="100%">
      <ResponsiveBar
        data={data}
        margin={{ top: 0, right: 20, bottom: 0, left: 20 }}
        layout="horizontal"
        groupMode={"stacked"}
        valueScale={{ type: "linear" }}
        keys={Object.keys(data[0])}
        indexScale={{ type: "band", round: true }}
        colors={graphColors}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        legends={[]}
        isInteractive={false}
        animate={false}
      />
      <HoverTooltip
        {...popoverProps}
        allData={Object.keys(data[0]).map((k) => ({ id: k, label: k, value: data[0][k] }))}
      />
    </Box>
  );
};

interface HoverTooltipProps {
  allData: ChartItemDataFormat[];
  open: boolean;
  anchorEl: any;
  onClose: () => void;
}

const HoverTooltip = (props: HoverTooltipProps) => {
  return (
    <Popover
      sx={{
        pointerEvents: "none",
        marginTop: "20px",
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={props.onClose}
      disableRestoreFocus
    >
      <Box sx={{ p: 2 }}>
        <table style={{ border: "none", borderCollapse: "collapse", maxWidth: 0, whiteSpace: "nowrap" }}>
          {props.allData?.map((item, index) => (
            <tr key={item.id}>
              <td style={{ textAlign: "right", width: "100%" }}>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: item.color || graphColors[index], fontWeight: "bold" }}
                >
                  {item.label}:
                </Typography>
              </td>
              <td style={{ textAlign: "left" }}>
                <Typography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
                  {item.type && item.type === "currency"
                    ? `$${wholeNumberFormatter.format(item.value)}`
                    : wholeNumberFormatter.format(item.value)}
                </Typography>
              </td>
            </tr>
          ))}
        </table>
      </Box>
    </Popover>
  );
};
