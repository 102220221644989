import { useNavigate } from "react-router-dom";
import { useSiteContext, useMatchesRoutes, Routes } from "app";
import { StyledToggleButtonGroup, StyledToggleButton } from "app/mui/forms/Tabs/TabSelect";

type ApplicationListType = "General" | "PackagedSolution";


export const ApplicationTabSelector = () => {

   const navigate = useNavigate();
   const { currentSiteWithNavigation } = useSiteContext();

   const applicationListType: ApplicationListType = useMatchesRoutes(Routes.PackagedSolutions) ? 'PackagedSolution' : 'General';

   const handleToggleChanged = (_event: any, value: ApplicationListType) => {
      if (!currentSiteWithNavigation) {
         return;
      }
      switch (value) {
         case "General":
            navigate(currentSiteWithNavigation.routeTo.Applications());
            return;
         case "PackagedSolution":
            navigate(currentSiteWithNavigation.routeTo.PackageSolutions());
            return;
      };
   }

   return (
      <StyledToggleButtonGroup value={applicationListType} exclusive onChange={handleToggleChanged} size="small">
         <StyledToggleButton value={"General"}>Applications</StyledToggleButton>
         <StyledToggleButton value={"PackagedSolution"}>Packaged Solutions</StyledToggleButton>
      </StyledToggleButtonGroup>
   );
};
