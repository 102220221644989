import { ToggleButton, ToggleButtonGroup, Typography, lighten, styled } from "@mui/material";
import { IrisColors } from "app/mui/theme";
import { range } from "lodash";

interface ITabSelectProps {
  value: string;
  onChange: (value: string) => void;
  tabs: number;
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  margin: 0,
  paddingTop: 0,
});
export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "&:hover": {
    background: lighten(theme.palette.primary.light, 0.7),
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    background: theme.palette.primary.main,
    borderLeft: `solid 1px ${IrisColors.borders} !important`, // the selected toggle looks funny without this.
  },
}));

export const TabSelect = (props: ITabSelectProps) => {
  const { value, onChange, tabs } = props;

  return (
    <StyledToggleButtonGroup size="large" value={value}>
      {range(1, tabs + 1).map((tabVal) => (
        <StyledToggleButton
          key={tabVal}
          value={tabVal.toString()}
          onClick={() => onChange(tabVal.toString())}
          sx={{ aspectRatio: 1, width: "40px", padding: 0 }}
        >
          <Typography lineHeight={1}>{tabVal}</Typography>
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};
