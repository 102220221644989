import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Divider, Grid, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import { IrisTooltip, useUserContext } from "app";
import { TrafficLightFormItem } from "app/mui/forms/Select/TrafficLightFormItem";
import { IrisColors } from "app/mui/theme";
import React from "react";
import { useForm } from "react-hook-form";
import { Record as RecordModel } from "records/models";

import InfoIcon from "@mui/icons-material/Info";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useUpdateReportCardPneumaticPartialStroke } from "records/api";
import {
  ReportCardPneumaticPartialStroke,
  createNewReportCardPneumaticPartialStroke,
} from "records/models/reportCardPneumaticPartialStroke";
import {
  PneumaticPartialStrokeCondition,
  PneumaticPartialStrokeConditionFieldLabel,
  PneumaticPartialStrokeConditionTooltip,
} from "records/models/pneumaticPartialStrokeCondition";
import {
  PneumaticPartialStrokePerformance,
  PneumaticPartialStrokePerformanceFieldLabel,
  PneumaticPartialStrokePerformanceTooltip,
} from "records/models/pneumaticPartialStrokePerformance";

const conditionFields: Array<{
  fieldName: keyof PneumaticPartialStrokeCondition;
  label: PneumaticPartialStrokeConditionFieldLabel;
  toolTipContent?: PneumaticPartialStrokeConditionTooltip;
}> = [
  {
    fieldName: "torqueThrust",
    label: PneumaticPartialStrokeConditionFieldLabel.TorqueThrust,
    toolTipContent: PneumaticPartialStrokeConditionTooltip.TorqueThrust,
  },
  {
    fieldName: "breakoutPressure",
    label: PneumaticPartialStrokeConditionFieldLabel.BreakoutPressure,
    toolTipContent: PneumaticPartialStrokeConditionTooltip.BreakoutPressure,
  },
  {
    fieldName: "endPointProfile",
    label: PneumaticPartialStrokeConditionFieldLabel.EndPointProfile,
    toolTipContent: PneumaticPartialStrokeConditionTooltip.EndPointProfile,
  },
  {
    fieldName: "dynamicErrorBand",
    label: PneumaticPartialStrokeConditionFieldLabel.DynamicErrorBand,
    toolTipContent: PneumaticPartialStrokeConditionTooltip.DynamicErrorBand,
  },
  {
    fieldName: "driveSignal",
    label: PneumaticPartialStrokeConditionFieldLabel.DriveSignal,
    toolTipContent: PneumaticPartialStrokeConditionTooltip.DriveSignal,
  },
];

const performanceFields: Array<{
  fieldName: keyof PneumaticPartialStrokePerformance;
  label: PneumaticPartialStrokePerformanceFieldLabel;
  toolTipContent?: PneumaticPartialStrokePerformanceTooltip;
}> = [
  {
    fieldName: "minMaxTravel",
    label: PneumaticPartialStrokePerformanceFieldLabel.MinMaxTravel,
    toolTipContent: PneumaticPartialStrokePerformanceTooltip.MinMaxTravel,
  },
  {
    fieldName: "rampRate",
    label: PneumaticPartialStrokePerformanceFieldLabel.RampRate,
    toolTipContent: PneumaticPartialStrokePerformanceTooltip.RampRate,
  },
  {
    fieldName: "travelDeviation",
    label: PneumaticPartialStrokePerformanceFieldLabel.TravelDeviation,
    toolTipContent: PneumaticPartialStrokePerformanceTooltip.TravelDeviation,
  },
  {
    fieldName: "breakoutTime",
    label: PneumaticPartialStrokePerformanceFieldLabel.BreakoutTime,
    toolTipContent: PneumaticPartialStrokePerformanceTooltip.BreakoutTime,
  },
  {
    fieldName: "testDuration",
    label: PneumaticPartialStrokePerformanceFieldLabel.TestDuration,
    toolTipContent: PneumaticPartialStrokePerformanceTooltip.TestDuration,
  },
];

interface PneumaticPartialStrokeReportCardProps {
  record: RecordModel;
  reportCard?: ReportCardPneumaticPartialStroke | null;
}

export const PneumaticPartialStrokeReportCard = (props: PneumaticPartialStrokeReportCardProps) => {
  const { record, reportCard } = props;
  const defaultValues = makeSafeForReactHookForm(reportCard || createNewReportCardPneumaticPartialStroke(record.id));
  const methods = useForm({ defaultValues });
  const updateReportCard = useUpdateReportCardPneumaticPartialStroke();
  const { userPermissions } = useUserContext();

  if (!reportCard) {
    return <></>;
  }

  const userCanEdit = userPermissions.userCanEditRecord(
    record.assets.map((asset) => asset.assetType),
    record.recordType
  );

  const saveChanges = () => {
    methods.handleSubmit((values) => {
      updateReportCard.call({ ...values, recordId: record.id });
    })();
  };

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permissions) =>
        permissions.userCanEditRecord(
          record.assets?.map((asset) => asset.assetType),
          record.recordType
        )
      }
    >
      <AutoSave onSubmit={saveChanges} />
      <PneumaticPartialStrokeReportCardFields canEdit={userCanEdit} />
    </PermissionAwareFormProvider>
  );
};

const PneumaticPartialStrokeReportCardFields = (props: { canEdit: boolean }) => {
  const asFoundWidth = "65px";
  const arrowWidth = "35px";
  const { canEdit: userCanEdit } = props;

  return (
    <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="column" sx={{ width: "100%" }}>
        <Typography variant="h6">REPORT CARD</Typography>
        <Paper>
          <List sx={{ width: "100%" }}>
            <ListItem alignItems="flex-start">
              <Grid container justifyContent="space-between" columnSpacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 500, textTransform: "uppercase" }}>
                    Condition
                  </Typography>
                </Grid>
                <Grid item xs={false} width={asFoundWidth}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;found</Typography>
                </Grid>
                <Grid item xs={false} width={arrowWidth} />
                <Grid item xs={true}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;left</Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider component="li" />
            {conditionFields.map((cField, cIndex) => (
              <React.Fragment key={`condition-${cField.fieldName}`}>
                <ListItem>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                      <Grid item xs={5} sx={{ display: "flex" }}>
                        <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                          {cField.label}
                        </Typography>
                        {cField.toolTipContent && (
                          <IrisTooltip title={<>{cField.toolTipContent}</>}>
                            <span>
                              <InfoIcon sx={{ color: IrisColors.gray500, fontSize: "1.1rem" }} />
                            </span>
                          </IrisTooltip>
                        )}
                      </Grid>
                      <Grid item xs={false} width={asFoundWidth}>
                        <TrafficLightFormItem
                          fieldName={"asFoundCondition_" + cField.fieldName}
                          syncFieldName={"asLeftCondition_" + cField.fieldName}
                          readOnly={!userCanEdit}
                        />
                      </Grid>
                      <Grid item xs={false} width={arrowWidth}>
                        <ArrowForwardIcon sx={{ fontSize: "20px", color: IrisColors.gray500 }} />
                      </Grid>
                      <Grid item xs={true}>
                        <TrafficLightFormItem
                          fieldName={"asLeftCondition_" + cField.fieldName}
                          readOnly={!userCanEdit}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ListItem>
                {cIndex < conditionFields.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
        <Paper sx={{ mt: 3 }}>
          <List sx={{ width: "100%" }}>
            <ListItem alignItems="flex-start">
              <Grid container justifyContent="space-between" columnSpacing={2} alignItems="flex-end">
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 500, textTransform: "uppercase" }}>
                    Performance
                  </Typography>
                </Grid>
                <Grid item xs={false} width={asFoundWidth}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;found</Typography>
                </Grid>
                <Grid item xs={false} width={arrowWidth} />
                <Grid item xs={true}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;left</Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider component="li" />
            {performanceFields.map((pField, pIndex) => (
              <React.Fragment key={`performance-${pField.fieldName}`}>
                <ListItem>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                      <Grid item xs={5} sx={{ display: "flex" }}>
                        <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                          {pField.label}
                        </Typography>
                        {pField.toolTipContent && (
                          <IrisTooltip title={<>{pField.toolTipContent}</>}>
                            <span>
                              <InfoIcon sx={{ color: IrisColors.gray500, fontSize: "1.1rem" }} />
                            </span>
                          </IrisTooltip>
                        )}
                      </Grid>
                      <Grid item xs={false} width={asFoundWidth}>
                        <TrafficLightFormItem
                          fieldName={"asFoundPerformance_" + pField.fieldName}
                          readOnly={!userCanEdit}
                          syncFieldName={"asLeftPerformance_" + pField.fieldName}
                        />
                      </Grid>
                      <Grid item xs={false} width={arrowWidth}>
                        <ArrowForwardIcon sx={{ fontSize: "20px", color: IrisColors.gray500 }} />
                      </Grid>
                      <Grid item xs={true}>
                        <TrafficLightFormItem
                          fieldName={"asLeftPerformance_" + pField.fieldName}
                          readOnly={!userCanEdit}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ListItem>
                {pIndex < performanceFields.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Stack>
    </Paper>
  );
};
