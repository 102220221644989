import { Box, Divider, Grid, Paper } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { RecordsEvent, RecordsEventDetail, RecordsEventFieldLabel } from "recordsEvents/models";
import { RecordsEventHeader } from "./RecordsEventHeader";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { useUserContext } from "app";

interface IRecordsEventDetailsProps {
  recordsEventDetail: RecordsEventDetail;
  methods: UseFormReturn<RecordsEvent>;
  saveChanges: (onValid?: () => void) => void;
}

export const RecordsEventDetails = (props: IRecordsEventDetailsProps) => {
  const { recordsEventDetail, methods, saveChanges } = props;
  const { userPermissions } = useUserContext();

  const userCanEdit = userPermissions.userCanEditRecordsEvents;

  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <RecordsEventHeader recordsEventDetail={recordsEventDetail} methods={methods} saveChanges={saveChanges} />
      </Box>
      <Divider />
      <Box sx={{ p: 2, pb: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <DateInputFormItem
              label={RecordsEventFieldLabel.StartDate}
              fieldName="startDate"
              readOnly={!userCanEdit}
              readOnlyValue={recordsEventDetail.model.startDate}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <DateInputFormItem
              label={RecordsEventFieldLabel.EndDate}
              fieldName="endDate"
              readOnly={!userCanEdit}
              readOnlyValue={recordsEventDetail.model.endDate}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
