import { AddIcon } from "icons/AddIcon";
import { MinusIcon } from "icons/MinusIcon";
import React from "react";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Col, Collapse, Fade, Row } from "reactstrap";
import {
   RecordOutagePlanningActions,
   RecordOutagePlanningScopeComponents,
   RecordOutagePlanningScopeLocations,
   SecondaryRecordOutagePlanningActions,
} from "records/bs/outage/models";
import { SelectInput } from "../../inputs/SelectInput";

interface ScopeOfWorkDetailsProps {
   userCanEdit: boolean;
}

export const ScopeOfWorkDetails = (props: ScopeOfWorkDetailsProps) => {
   const { setValue } = useFormContext();
   const primaryAction = useWatch({ name: "primaryAction" });
   const primaryComponent = useWatch({ name: "primaryComponent" });

   const showAdditionalFields =
      primaryAction !== "Function test only" &&
      primaryAction !== "Visual inspection only" &&
      primaryAction !== "Removed from scope";

   const isReplaceAssembly = primaryAction === "Replace" && primaryComponent === "Assembly";
   const canShowLocation = !isReplaceAssembly;

   const secondaryAction = useWatch({ name: "secondaryAction" });
   const secondaryComponent = useWatch({ name: "secondaryComponent" });
   const secondaryLocation = useWatch({ name: "secondaryLocation" });
   const hasSecondaryScopeOfWork =
   !!secondaryAction || !!secondaryComponent || !!secondaryLocation;

   useEffect(() => {
      if (!showAdditionalFields || isReplaceAssembly) {
         setValue("primaryLocation", null);
      }
   }, [showAdditionalFields, isReplaceAssembly]);

   const [showSecondaryScopeOfWork, setShowSecondaryScopeOfWork] = useState<boolean>(
      hasSecondaryScopeOfWork
   );

   const addSecondaryScopeOfWork = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setShowSecondaryScopeOfWork(true);
   };

   const removeSecondaryScopeOfWork = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setShowSecondaryScopeOfWork(false);

      setValue("secondaryAction", null);
      setValue("secondaryComponent", null);
      setValue("secondaryLocation", null);
   };

   return (
      <>
         <Row style={{ padding: "1rem" }}>
            <Col xl="8">
               <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ width: "180px" }}>
                     <SelectInput
                        label="Scope of work"
                        field="primaryAction"
                        placeholder="Select action"
                        options={RecordOutagePlanningActions}
                        userCanEdit={props.userCanEdit}
                     />
                  </div>
                  {showAdditionalFields && (
                     <>
                        <div style={{ width: "180px", marginLeft: "8px", marginRight: "8px" }}>
                           <SelectInput
                              label=""
                              field="primaryComponent"
                              placeholder="component"
                              options={RecordOutagePlanningScopeComponents}
                              userCanEdit={props.userCanEdit}
                           />
                        </div>
                        {canShowLocation && (
                           <div style={{ width: "100px" }}>
                              <SelectInput
                                 label=""
                                 field="primaryLocation"
                                 placeholder="location"
                                 options={RecordOutagePlanningScopeLocations}
                                 userCanEdit={props.userCanEdit}
                              />
                           </div>
                        )}
                     </>
                  )}
               </div>
            </Col>
            <Col xl="4">
               <Fade
                  in={showAdditionalFields && !showSecondaryScopeOfWork}
                  className="add-secondary-scope-button-wrapper"
               >
                  <button onClick={addSecondaryScopeOfWork}>
                     <AddIcon />
                     Add secondary scope of work
                  </button>
               </Fade>
            </Col>
         </Row>
         <Collapse isOpen={showSecondaryScopeOfWork}>
            <Row style={{ padding: "1rem" }}>
               <Col xl="8">
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                     <div style={{ width: "180px" }}>
                        <SelectInput
                           label="Scope of work"
                           field="secondaryAction"
                           placeholder="Select action"
                           options={SecondaryRecordOutagePlanningActions}
                           userCanEdit={props.userCanEdit}
                        />
                     </div>
                     <div style={{ width: "180px", marginLeft: "8px", marginRight: "8px" }}>
                        <SelectInput
                           label=""
                           field="secondaryComponent"
                           placeholder="component"
                           options={RecordOutagePlanningScopeComponents}
                           userCanEdit={props.userCanEdit}
                        />
                     </div>
                     <div style={{ width: "100px" }}>
                        <SelectInput
                           label=""
                           field="secondaryLocation"
                           placeholder="location"
                           options={RecordOutagePlanningScopeLocations}
                           userCanEdit={props.userCanEdit}
                        />
                     </div>
                  </div>
               </Col>
               <Col xl="4">
                  <div className="remove-secondary-scope-button-wrapper">
                     <button onClick={removeSecondaryScopeOfWork}>
                        <MinusIcon />
                        Remove secondary scope of work
                     </button>
                  </div>
               </Col>
            </Row>
         </Collapse>
      </>
   );
};
