import { FlagsLoader } from "app/mui/loaders/FlagsLoader";
import classNames from "classnames";
import Select, { MultiValue } from "react-select";
import { FlagOption, RecordFlagType, recordFlagTypeToOption } from "./models";

interface Props {
  selectedFlags: RecordFlagType[];
  ignoreFlags?: string[];
  onChangeFlags: (selectedFlags: RecordFlagType[]) => void;
  disabled?: boolean;
  invalid?: boolean;
  disallowCreation?: boolean;
  menuPlacement?: MenuPlacement;
  className?: string;
}

export enum MenuPlacement {
  Top = "top",
  Bottom = "bottom",
  Auto = "auto",
}

export const FlagSelect = (props: Props) => {
  const handleFlagsChange = (flags: MultiValue<FlagOption>) => {
    props.onChangeFlags(flags.map((x) => x.id));
  };

  const selectedFlagOptions = props.selectedFlags.map((x) => recordFlagTypeToOption(x));

  return (
    <FlagsLoader>
      {({ options }) => (
        <Select
          menuPlacement={props.menuPlacement}
          isMulti
          onChange={handleFlagsChange}
          closeMenuOnSelect
          isClearable={false}
          options={options}
          value={selectedFlagOptions}
          className={classNames("keyword-select react-select", { "is-invalid": props.invalid }, props.className)}
          classNamePrefix="react-select"
          getOptionLabel={(x) => x.name}
          getOptionValue={(x) => x.id.toString()}
          isDisabled={props.disabled}
          placeholder="Choose flags..."
          backspaceRemovesValue={false}
        />
      )}
    </FlagsLoader>
  );
};
