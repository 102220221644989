import { NotEnoughData } from "app/mui/common/placeholders/NotEnoughDataYetMessage";
import { YearlyRecordCountByRecordType } from "dashboard/models";
import _ from "lodash";
import { FunctionComponent, useMemo } from "react";
import { RecordTypeBarChart, RecordTypeBarChartData } from "./RecordTypeBarChart";

interface RecordTypeWidgetProps {
  data: YearlyRecordCountByRecordType[];
}

const getData = (yearlyRecordsCounts: YearlyRecordCountByRecordType[]) => {
  const yearlyCountsDict: { [year: number]: Omit<RecordTypeBarChartData, "year"> } = {};

  _.orderBy(_.uniq(yearlyRecordsCounts.map((d) => d.year)), (x) => x).forEach((year) => {
    yearlyCountsDict[year] = { "All Records": 0 } as any;
  });

  yearlyRecordsCounts.forEach(({ year, recordType, value }) => {
    if (!yearlyCountsDict[year][recordType]) {
      yearlyCountsDict[year][recordType] = 0;
    }
    yearlyCountsDict[year][recordType] += value;
    yearlyCountsDict[year]["All Records"] += value;
  });

  return Object.keys(yearlyCountsDict).map((key) => {
    return {
      year: key,
      ...yearlyCountsDict[key],
    };
  });
};

export const RecordTypeWidget: FunctionComponent<RecordTypeWidgetProps> = (props) => {
  const data = useMemo(() => getData(props.data), [props.data]);

  if (data.length === 0) {
    return <NotEnoughData />;
  }

  return <RecordTypeBarChart data={data} />;
};
