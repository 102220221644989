import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Box, Button, Card, CardContent, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import {
  useCreateAssetDeviceRequest,
  useGetAssetComponentsByIdQuery,
  useUpdateAssetMachineryAttributeRequest,
} from "assets/api";
import { Asset } from "assets/models/asset";
import { AssetDetailView } from "assets/models/assetDetail";
import { AssetMachineryComponent } from "assets/models/machineryComponent";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { CreateMachineryComponentsDialog } from "../../dialogs/CreateMachineryComponents";
import { AssetComponentCard } from "./AssetComponentCard";
import _ from "lodash";
import { createAttributeFieldName, getAttributeIdFromFieldName } from "./AssetAttributeField";

interface AssetComponentsProps {
  assetDetail: AssetDetailView;
}

export const AssetComponents = (props: AssetComponentsProps) => {
  const getAssetComponents = useGetAssetComponentsByIdQuery(props.assetDetail.model.id);
  const namedComponents = React.useMemo(() => {
    // group each component by its template id giving incremental counts to each component
    const components = getAssetComponents.data;
    if (!components) {
      return [];
    }

    const indexedComponents = new Map<number, number>();
    const namedComponents: [string, AssetMachineryComponent][] = [];

    components.forEach((component) => {
      const count = indexedComponents.get(component.componentTemplate.id) || 0;
      indexedComponents.set(component.componentTemplate.id, count + 1);
      namedComponents.push([`${component.componentTemplate.name} ${count + 1}`, component]);
    });

    return namedComponents;
  }, [getAssetComponents.data]);

  return <AssetComponentsWithForm assetDetail={props.assetDetail} namedComponents={namedComponents} />;
};

type DialogActionsType = "addMachineryComponents" | null;
const validDialogActions = ["addMachineryComponents"];

const pageActions = (asset: Asset, isLoading: boolean) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <AddBoxOutlinedIcon />,
      label: "Add new component",
      disabled: isLoading,
      hidden: !userPermissions.userCanEditAsset(asset.assetType),
      action: () => {
        actionDispatcher.dispatch("addMachineryComponents");
      },
    },
  ]);

interface AssetComponentsWithFormProps {
  assetDetail: AssetDetailView;
  namedComponents: [string, AssetMachineryComponent][];
}

const AssetComponentsWithForm = (props: AssetComponentsWithFormProps) => {
  const [openDialog, setOpenDialog] = React.useState<DialogActionsType>(null);
  const asset = props.assetDetail.model;
  const updateAssetAttributes = useUpdateAssetMachineryAttributeRequest(asset.id);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const createDeviceRequest = useCreateAssetDeviceRequest(asset.id);

  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(asset, createDeviceRequest.loading), actionDispatcher);

  const handleAction = useCallback((action: string) => {
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const saveChanges = () => {
    methods.handleSubmit((values) => {
      const updatedValues = Object.keys(values)
        .filter((k) => values[k] !== undefined)
        .map((k) => {
          return {
            id: getAttributeIdFromFieldName(k),
            value: values[k].toString(),
          };
        });

      if (updatedValues.length) {
        updateAssetAttributes.call(updatedValues);
      }
    })();
  };

  const methods = useForm();

  useEffect(() => {
    const defaultValues = props.namedComponents.reduce((acc, [_title, component]) => {
      const values = component.assetComponentAttributes.reduce((acc, attribute) => {
        acc[createAttributeFieldName(attribute)] = attribute.value;
        return acc;
      }, {});
      return { ...acc, ...values };
    }, {});

    methods.reset(defaultValues);
  }, [props.namedComponents]);

  return (
    <>
      <PermissionAwareFormProvider
        {...methods}
        require={(permissions) => permissions.userCanEditAsset(asset.assetType)}
      >
        <AutoSave onSubmit={saveChanges} />
        <Paper>
          <ExpansionPanel title="Specifications" rightSideComponent={<ActionsDropdownMenu actions={actions} />}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                {props.namedComponents.length === 0 && (
                  <Stack width="100%" justifyContent={"center"} pl={2}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      startIcon={<AddBoxOutlinedIcon />}
                      sx={{ borderStyle: "dashed" }}
                      onClick={() => {
                        actionDispatcher.dispatch("addMachineryComponents");
                      }}
                    >
                      Add New Component
                    </Button>
                  </Stack>
                )}
                {props.namedComponents.map(([title, component]) => (
                  <AssetComponentCard key={component.id} component={component} title={title} />
                ))}
              </Grid>
            </Box>
          </ExpansionPanel>
        </Paper>
      </PermissionAwareFormProvider>

      {openDialog === "addMachineryComponents" && (
        <CreateMachineryComponentsDialog onClose={closeDialog} asset={asset} />
      )}
    </>
  );
};
