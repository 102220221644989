import { styled, Box, lighten } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ReactNode, useContext } from "react";
import { Routes, useMatchesRoutes } from "app/routes";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext, useSiteContext } from "app";
import { IrisColors } from "app/mui/theme";

type TasksListType = "records" | "recommendations" | "kpis";

interface TasksListSelectorProps {
   defaultList?: TasksListType;
   children: (tasksList: TasksListType) => ReactNode | undefined;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({});
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
   paddingLeft: theme.spacing(2),
   paddingRight: theme.spacing(2),
   "&:hover": {
      background: lighten(theme.palette.primary.light, 0.7),
   },
   "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      background: theme.palette.primary.main,
      borderLeft: `solid 1px ${IrisColors.borders} !important`, // the selected toggle looks funny without this.
   },
}));

export const TasksListSelector = (props: TasksListSelectorProps) => {
   const navigate = useNavigate();
   const { currentSiteWithNavigation } = useSiteContext();
   const { userPermissions } = useContext(UserContext);

   const isBaseTasksList = useMatchesRoutes(Routes.Tasks);
   const isRecordsList = useMatchesRoutes(Routes.Records);
   const isRecommendationsList = useMatchesRoutes(Routes.Recommendations);
   const isKpiList = useMatchesRoutes(Routes.Kpis);

   /* prettier-ignore */
   const value: TasksListType | null =
      isRecordsList ? "records" :
      isRecommendationsList ? "recommendations" :
      isKpiList ? "kpis" :
      null;

   const handleToggleChanged = (_event: any, value: TasksListType) => {
      if (!currentSiteWithNavigation) {
         return;
      }
      switch (value) {
         case "records":
            navigate(currentSiteWithNavigation.routeTo.Records());
            break;
         case "recommendations":
            navigate(currentSiteWithNavigation.routeTo.Recommendations());
            break;
         case "kpis":
            navigate(currentSiteWithNavigation.routeTo.Kpis());
            break;
      }
   };

   if (!value && isBaseTasksList) {
      if (!currentSiteWithNavigation) {
         return null;
      }

      return <Navigate to={currentSiteWithNavigation.routeTo.Records()} replace />;
   }

   return (
      <>
         <StyledToggleButtonGroup value={value} exclusive onChange={handleToggleChanged} size="small">
            <StyledToggleButton value={"records"}>Records</StyledToggleButton>
            <StyledToggleButton value={"recommendations"}>Recommendations</StyledToggleButton>
            <StyledToggleButton value={"kpis"} disabled={!userPermissions.userCanViewKpi}>Kpis</StyledToggleButton>
         </StyledToggleButtonGroup>
         <Box key={value}>{value && props.children(value)}</Box>
      </>
   );
};
