import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import { Routes, formatRoutePath, useSiteContext } from "app";
import { BaseDialog } from "app/mui/common/dialogs/BaseDialog";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { KpiTypeSelectFormItem } from "app/mui/forms/Select/KpiTypeSelectFormItem";
import { useCreateKpiTemplateRequest } from "kpis/api";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { kpiTemplateCreateSchemaValidation } from "../utilities";
import { getKpiTypeOption } from "kpis/models";

interface CreateKpiTemplateDialogProps {
  onClose: () => void;
}

export const CreateKpiTemplateDialog = (props: CreateKpiTemplateDialogProps) => {
  const navigate = useNavigate();
  const { currentSiteWithNavigation } = useSiteContext();

  const createKpiTemplateRequest = useCreateKpiTemplateRequest();

  const methods = useForm({
    defaultValues: { name: "", kpiType: getKpiTypeOption("OneTimeLabourOrEquipmentCostSavings") },
    resolver: yupResolver(kpiTemplateCreateSchemaValidation),
  });

  const onSubmit = (options?: { navigate?: boolean }) => {
    if (!currentSiteWithNavigation) return;

    methods.handleSubmit((values) => {
      createKpiTemplateRequest
        .call({
          siteId: currentSiteWithNavigation.id,
          kpiType: values.kpiType.value,
          name: values.name,
          enabled: true,
        })
        .then((newKpiTemplate) => {
          if (newKpiTemplate) {
            if (options?.navigate) {
              navigate(formatRoutePath(Routes.KpiTemplate, { siteId: newKpiTemplate.siteId, id: newKpiTemplate.id }));
            } else {
              props.onClose();
              window.location.reload();
            }
          }
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle>Create KPI Template</DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <KpiTypeSelectFormItem fieldName="kpiType" showTip />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormItem label="Name" fieldName="name" />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={() => onSubmit({ navigate: true })}>
            Create Template
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
