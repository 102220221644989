import React, { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const AutoEnsureLoggedIn = (props: any) => {
   const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

   const loginAndRedirectHere = useCallback(() => {
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
   }, [loginWithRedirect]);

   React.useEffect(() => {
      if (!isAuthenticated && !isLoading) {
         loginAndRedirectHere();
      }
   }, [isAuthenticated, isLoading, loginAndRedirectHere]);

   if (isLoading && !isAuthenticated) {
      return <div>Authenticating...</div>;
   }

   if (isAuthenticated) {
      return <>{props.children}</>;
   }

   if (error) {
      return (
         <div>
            An error occured. Please{" "}
            <a href={window.location.origin} onClick={loginAndRedirectHere}>
               click here
            </a>{" "}
            to try logging in again.
            <p>{JSON.stringify(error)}</p>
         </div>
      );
   }

   return (
      <div>
         Redirecting to login. If you are not redirected in a few seconds{" "}
         <a href={"#"} onClick={loginAndRedirectHere}>
            click here
         </a>
      </div>
   );
};
