import {
  GetKpiCommodityUnitsForEmissions,
  KpiCommodityUnit,
  KpiCommodityUnitsForSpills,
  KpiEmissionsType,
} from "kpis/models";
import { KpiReleaseType } from "kpis/models/KpiOneTimeReleaseOrSpillAvoidance";
import * as yup from "yup";

export const KpiOneTimeReleaseOrSpillAvoidanceValidationSchema = yup.object({
  releaseType: yup.mixed<KpiReleaseType>().nullable().required("Required"),
  reductionAmount: yup.number().nullable().required("Required").moreThan(0, "Must be greater than zero"),
  reductionUnit: yup
    .mixed<KpiCommodityUnit>()
    .nullable()
    .test("does-match-release-type", "Does not match release type", function (value) {
      if (!!!value) {
        return false;
      }
      const { releaseType, reductionType } = this.parent;
      const validUnits =
        releaseType === "Emission" ? GetKpiCommodityUnitsForEmissions(reductionType) : KpiCommodityUnitsForSpills;
      return validUnits.includes(value);
    })
    .required("Required"),
  reductionType: yup
    .mixed()
    .when("releaseType", {
      is: (value: KpiReleaseType) => value === "Emission",
      then: yup.mixed<KpiEmissionsType>().required("Required"),
    })
    .required("Required"),
});
