import classNames from "classnames";
import { getInvalidValidity, getValidValidity, Validity } from "common";
import { dateFormat } from "common/formats";
import { LockedIcon } from "icons/LockedIcon";
import { KpiSignoff } from "kpis/models";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Button, Card, CardSubtitle, Col, FormGroup, Input, Label, Row } from "reactstrap";

export interface KpiSignoffValidity {
  customerSignOff: Validity;
  signOffDate: Validity;
}

export const getKpiSignoffValidity = (fields: KpiSignoff, isSigningOff: boolean): KpiSignoffValidity => {
  return {
    customerSignOff:
      isSigningOff && (!fields.customerSignOff || fields.customerSignOff.length === 0)
        ? getInvalidValidity("Please enter a value for the customer.")
        : getValidValidity(),
    signOffDate:
      isSigningOff && !fields.signOffDate
        ? getInvalidValidity("Please enter a value for the sign-off date.")
        : getValidValidity(),
  };
};

export const isKpiSignoffValidityValid = (kpiSignoffValidity: KpiSignoffValidity) =>
  _.values(kpiSignoffValidity).filter((v) => !v.valid).length === 0;

interface KpiSignoffFormProps {
  fields: KpiSignoff;
  serverFields: KpiSignoff;
  showValidationErrors: boolean;
  userCanUnlock: boolean;
  validity: KpiSignoffValidity;
  readOnly: boolean;
  hideButtons?: boolean;
  saveValueChange: (changed: KpiSignoff, skipDelay?: boolean) => any;
  signOff: () => any;
  signOffRemoved: () => any;
}

export const KpiSignoffForm: React.FunctionComponent<KpiSignoffFormProps> = (props) => {
  const isSignedOff = props.fields.signedOff;
  return (
    <Card body>
      <CardSubtitle>Sign-off</CardSubtitle>
      <FormGroup>
        <Label>Customer</Label>
        <Input
          className={classNames("form-control", {
            "is-invalid": props.showValidationErrors && !props.validity.customerSignOff.valid,
          })}
          value={props.fields.customerSignOff || ""}
          onChange={(e) => {
            props.saveValueChange({ ...props.fields, customerSignOff: e.target.value });
          }}
          readOnly={props.readOnly || isSignedOff}
        />
        {!props.validity.customerSignOff.valid && (
          <div className="invalid-feedback">{props.validity.customerSignOff.message}</div>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Date</Label>
        <Row>
          <Col xs={12} sm={10}>
            <Input
              className={classNames(
                "form-control",
                { "dim-date": props.fields.signOffDate === null },
                {
                  "is-invalid": props.showValidationErrors && !props.validity.signOffDate.valid,
                }
              )}
              type="date"
              value={props.fields.signOffDate === null ? "" : moment(props.fields.signOffDate).format(dateFormat)}
              onChange={(e) => {
                const signOffDateMoment = moment(e.target.value);
                const signOffDate = signOffDateMoment.isValid() ? signOffDateMoment.toDate() : null;

                props.saveValueChange({ ...props.fields, signOffDate: signOffDate }, false);
              }}
              readOnly={props.readOnly || isSignedOff}
            />
            {!props.validity.signOffDate.valid && (
              <div className="invalid-feedback">{props.validity.signOffDate.message}</div>
            )}
          </Col>
          <Col xs={12} sm={2}>
            <div style={{ marginTop: "3px" }}>
              <Button
                size="sm"
                disabled={props.readOnly || isSignedOff}
                onClick={() => {
                  props.saveValueChange({ ...props.fields, signOffDate: new Date() }, true);
                }}
              >
                Today
              </Button>
            </div>
          </Col>
        </Row>
      </FormGroup>
      {!isSignedOff && !props.hideButtons && (
        <div className="sign-off-button-container">
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              props.signOff();
            }}
            disabled={props.readOnly}
          >
            Sign-off
          </Button>
        </div>
      )}
      {isSignedOff && (
        <div className="signed-off">
          <div className="lock-details-container">
            <div className="lock-icon-container">
              <LockedIcon />
            </div>
            <div>Signed off</div>
          </div>
          {props.userCanUnlock && !props.hideButtons && (
            <div>
              <Button
                color="secondary"
                size="sm"
                onClick={() => {
                  props.saveValueChange({ signedOff: false, customerSignOff: "", signOffDate: null }, true);
                  props.signOffRemoved();
                }}
              >
                Remove sign-off
              </Button>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};
