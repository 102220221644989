import { AutocompleteFormItem, AutocompleteFormItemProps, Option } from "app/mui/forms/AutocompleteFormItem";
import { KpiPotentialDelayTypes } from "kpis/models";

interface PotentialDelayTypeFormItemProps extends Omit<AutocompleteFormItemProps<false, false, Option>, "options"> {
  readOnly?: boolean;
}

export function PotentialDelayTypeFormItem(props: PotentialDelayTypeFormItemProps) {
  const { label = "Potential delay type", readOnly, autocompleteProps, ...rest } = props;

  const options = KpiPotentialDelayTypes.map((dt) => ({ id: dt, label: dt }));

  return (
    <AutocompleteFormItem
      label={label}
      options={options}
      autocompleteProps={{ disabled: readOnly, ...autocompleteProps }}
      {...rest}
    />
  );
}
