import { useUserContext } from "app/contexts";
import { createContext, useContext } from "react";
import {
   FieldValues,
   FormProvider,
   FormProviderProps,
   SubmitErrorHandler,
   SubmitHandler,
   UseFormHandleSubmit,
} from "react-hook-form";
import { UserPermissions } from "users";

type RequirePermissionSelector = (_: UserPermissions) => boolean;

interface PermissionAwareFormProviderProps<TFieldValues extends FieldValues = FieldValues, TContext = any>
   extends FormProviderProps<TFieldValues, TContext> {
   require?: RequirePermissionSelector;
}

type FormEditPermissionsContextType = {
   hasPermissionToEdit: boolean;
};

export const FormEditPermissionsContext = createContext({
   hasPermissionToEdit: true,
});

const dummySubmit: UseFormHandleSubmit<any> = (_valid: any, _invalid?: any) => (e?: React.BaseSyntheticEvent) =>
   Promise.resolve();

export const PermissionAwareFormProvider = <TFieldValues extends FieldValues, TContext = any>(
   props: PermissionAwareFormProviderProps<TFieldValues, TContext>
) => {
   const { require, handleSubmit, children, ...rest } = props;

   const { userPermissions } = useUserContext();

   const permission = { hasPermissionToEdit: require?.(userPermissions) ?? true };

   // prevent handleSubmit from being called when we don't have permission
   const controlledSubmit = permission.hasPermissionToEdit ? handleSubmit : dummySubmit;

   return (
      <FormEditPermissionsContext.Provider value={permission}>
         <FormProvider {...rest} handleSubmit={controlledSubmit as any}>
            {children}
         </FormProvider>
      </FormEditPermissionsContext.Provider>
   );
};

export const useFormEditPermission = (): FormEditPermissionsContextType => {
   var ctx = useContext(FormEditPermissionsContext);

   return ctx ?? { hasPermissionToEdit: true };
};
