import { StandardTextFieldProps, TextFieldProps } from "@mui/material";
import { RegisterOptions } from "react-hook-form";
import { TextFieldFormItem, TextFieldFormItemProps } from "./TextFieldFormItem";

interface TextAreaFormItemProps
  extends TextFieldFormItemProps,
    Omit<StandardTextFieldProps, keyof TextFieldFormItemProps> {
  textFieldProps?: TextFieldProps;
  formFieldProps?: RegisterOptions<any, any>;
  children?: React.ReactNode;
  minRows?: number;
}

// Value binds to comments, and provides a readonly Template comments if desired
function TextAreaFormItem(props: TextAreaFormItemProps) {
  const textAreaProps = {
    multiline: true,
    minRows: props.minRows,
  };

  return (
    <TextFieldFormItem
      formFieldProps={props.formFieldProps}
      textFieldProps={{ ...props.textFieldProps, ...textAreaProps }}
      {...props}
    />
  );
}

export default TextAreaFormItem;
