import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { checkAssetType } from "assets/utilities";
import { AccessorySpec, Asset } from "assets/models/asset";
import { EditableYesNoFormItem } from "app/mui/forms/YesNoForItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateAccessorySpecificationRequest } from "assets/api";

interface AccessorySpecificationCardProps {
  asset: Asset;
  accessory: AccessorySpec;
}

export const AccessoriesSpecificationCard = (props: AccessorySpecificationCardProps) => {
  const updateSpecificationRequest = useUpdateAccessorySpecificationRequest();

  const defaultValues = makeSafeForReactHookForm(props.accessory);
  const methods = useForm({ defaultValues });

  const updateAccessory = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const { isAutomatedValve, isManualValve, isMotorOperatedValve } = checkAssetType(props.asset.assetType);

  const automatedValveFields = isAutomatedValve
    ? [
        <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
        isMotorOperatedValve && <EditableTextFieldFormItem label="Gearbox" fieldName="gearBox" />,
        <EditableTextFieldFormItem label="Volume booster" fieldName="volumeBooster" />,
        <EditableTextFieldFormItem label="Quick release" fieldName="quickRelease" />,
        <EditableTextFieldFormItem label="Solenoid valve" fieldName="solenoidValve" />,
        <EditableTextFieldFormItem label="Instrument regulator" fieldName="instrumentRegulator" />,
        <EditableTextFieldFormItem label="Pressure switch" fieldName="pressureSwitch" />,
        <EditableTextFieldFormItem label="Position transmitter" fieldName="positionTransmitter" />,
        <EditableTextFieldFormItem label="Limit switch" fieldName="limitSwitch" />,
        <EditableYesNoFormItem label="Handwheel" fieldName="handwheel" />,
      ]
    : [];

  const manualValveFields = isManualValve
    ? [
        <EditableTextFieldFormItem label="Position transmitter" fieldName="positionTransmitter" />,
        <EditableTextFieldFormItem label="Limit switch" fieldName="limitSwitch" />,
        <EditableYesNoFormItem label="Handwheel" fieldName="handwheel" />,
      ]
    : [];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave onSubmit={updateAccessory} />
      <AssetSpecificationCard title="Accessories" specs={[...automatedValveFields, ...manualValveFields]} />
    </PermissionAwareFormProvider>
  );
};
