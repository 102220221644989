import { NotEnoughData } from "app/mui/common/placeholders/NotEnoughDataYetMessage";
import { YearlyAssetCountByHealthCategory } from "dashboard/models";
import _ from "lodash";
import { FunctionComponent, useMemo } from "react";
import { VisualIntegrityBarChart, VisualIntegrityBarChartData } from "./VisualIntegrityBarChart";

interface VisualIntegrityWidgetProps {
  data: YearlyAssetCountByHealthCategory[];
}

type VisualIntegrityBarChartBar = Omit<VisualIntegrityBarChartData, "year">;

const getData = (yearlyRecordsCounts: YearlyAssetCountByHealthCategory[]) => {
  const yearlyCountsDict: { [year: number]: VisualIntegrityBarChartBar } = {};

  _.orderBy(_.uniq(yearlyRecordsCounts.map((d) => d.year)), (x) => x).forEach((year) => {
    yearlyCountsDict[year] = {} as VisualIntegrityBarChartBar;
  });

  yearlyRecordsCounts.forEach(({ year, healthCategory, value }) => {
    yearlyCountsDict[year][healthCategory] = (yearlyCountsDict[year][healthCategory] ?? 0) + value;
  });

  return Object.keys(yearlyCountsDict).map((key) => {
    return {
      year: key,
      ...yearlyCountsDict[key],
    };
  });
};

export const VisualIntegrityWidget: FunctionComponent<VisualIntegrityWidgetProps> = (props) => {
  const data = useMemo(() => getData(props.data), [props.data]);

  if (data.length === 0) {
    return <NotEnoughData />;
  }

  return <VisualIntegrityBarChart data={data} />;
};
