import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import { Health } from "records";
import { HealthTrafficLightBadge } from "assets/mui/health/HealthTrafficLightBadge";
import { Link as RouterLink } from "react-router-dom";
import { formatRoutePath, Routes } from "app/routes";
import { IrisTooltip } from "app";
import InfoIcon from "@mui/icons-material/Info";
import { IrisColors } from "app/mui/theme";

interface VibrationHealthProps {
  health?: Health;
  previousRecordDescription?: string;
  previousRecordId?: number;
  siteId: number;
}

export const VibrationHealth = (props: VibrationHealthProps) => {
  const { health, previousRecordDescription, previousRecordId, siteId } = props;

  if (!health?.overall || !health?.overallCategory) {
    return null;
  }

  return (
    <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems={"center"}>
        <Typography variant="h6">VIBRATION HEALTH</Typography>
        {previousRecordId && (
          <Typography component="span" variant="body2">
            <Link
              component={RouterLink}
              to={formatRoutePath(Routes.Record, {
                siteId: siteId,
                id: previousRecordId,
              })}
              color="secondary"
              sx={{ fontWeight: "bold" }}
            >
              {previousRecordDescription}
            </Link>
          </Typography>
        )}
        <IrisTooltip
          title={
            <>
              <p>Vibration health is a health metric based on the non-intrusive inspection of the physical asset..</p>
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                <li>Good &ndash; no areas of concern present</li>
                <li>
                  Minor &ndash; small concerns that could be rectified through planned maintenance and/or design change
                </li>
                <li>
                  Moderate &ndash; areas of elevated concern that may be harmful to equipment, personnel, or environment
                </li>
                <li>
                  Severe &ndash; significant concerns that require immediate attention, to prevent arm to equipment,
                  personnel, environment, or ability to operate safely
                </li>
              </ul>
            </>
          }
        >
          <span>
            <InfoIcon sx={{ color: IrisColors.gray500, verticalAlign: "middle", fontSize: "1.7rem" }} />
          </span>
        </IrisTooltip>
      </Stack>
      <Box
        sx={{
          pt: 2,
          pb: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            minHeight: "0",
            minWidth: "0",
            width: "100%",
            height: "100%",
            pt: 1,
          }}
        >
          <HealthTrafficLightBadge overall={health.overall} overallCategory={health.overallCategory} />
        </Stack>
      </Box>
    </Paper>
  );
};
