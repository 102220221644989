import { Routes } from "app";
import { FunctionComponent } from "react";
import { useMatch } from "react-router-dom";
import { DashboardPage } from "./DashboardPage";

export const CustomerDashboardPage: FunctionComponent<{}> = (props) => {
   const routeMatch = useMatch(Routes.CustomerDashboard);
   const customerId = routeMatch?.params?.customerId ?? "";

   return <DashboardPage customerId={parseInt(customerId)} />;
};
