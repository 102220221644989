import React, { useState } from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { SummaryDetail } from "summaries/models";
import { useUserContext } from "app";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import EditIcon from "@mui/icons-material/Edit";
import { PropertiesPlugin } from "@microsoft/applicationinsights-web";

interface SummaryDetailNotesProps {
  summaryDetail: SummaryDetail;
  methods: UseFormReturn<any>;
  saveChanges: (onValid?: () => void) => void;
}

type EditableField = "highlightsLabel" | "challengesLabel" | "generalRecommendationLabel" | "pathForwardLabel";

export const SummaryNotes: React.FC<SummaryDetailNotesProps> = ({ summaryDetail, methods, saveChanges }) => {
  const { userCanEditSummary } = useUserContext().userPermissions;
  const isClosed = summaryDetail.model.status === "Closed";
  const summary = summaryDetail.model;

  const [editingField, setEditingField] = useState<null | string>(null);
  const [openDialog, setOpenDialog] = useState<null | string>(null);

  const openRenameTitle = (fieldName: EditableField) => {
    setEditingField(fieldName);
    setOpenDialog("renameTitle");
  };

  const handleSave = () => {
    if (editingField) {
      saveChanges();
      setOpenDialog(null);
      setEditingField(null);
    }
  };

  const actions = (fieldName: EditableField) => [
    {
      icon: <EditIcon />,
      label: "Rename Title",
      action: () => openRenameTitle(fieldName),
    },
  ];

  return (
    <Paper>
      <ExpansionPanel title="NOTES" defaultExpanded={true}>
        <Box>
          <Stack spacing={2} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {summary.highlightsLabel}
              </Typography>
              {userCanEditSummary && !isClosed && (
                <ActionsDropdownMenu rowActionMenu actions={actions("highlightsLabel")} />
              )}
            </Box>
            <RichTextEditor fieldName={"highlights"} label={undefined} disabled={!userCanEditSummary || isClosed} />
          </Stack>
          <Stack spacing={2} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {summary.challengesLabel}
              </Typography>
              {userCanEditSummary && !isClosed && (
                <ActionsDropdownMenu rowActionMenu actions={actions("challengesLabel")} />
              )}
            </Box>
            <RichTextEditor fieldName={"challenges"} label={undefined} disabled={!userCanEditSummary || isClosed} />
          </Stack>
          <Stack spacing={2} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {summary.generalRecommendationLabel}
              </Typography>
              {userCanEditSummary && !isClosed && (
                <ActionsDropdownMenu rowActionMenu actions={actions("generalRecommendationLabel")} />
              )}
            </Box>
            <RichTextEditor
              fieldName={"generalRecommendations"}
              label={undefined}
              disabled={!userCanEditSummary || isClosed}
            />
          </Stack>
          <Stack spacing={2} direction="column" alignItems="flex-start" sx={{ p: 2, width: "100%" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                {summary.pathForwardLabel}
              </Typography>
              {userCanEditSummary && !isClosed && (
                <ActionsDropdownMenu rowActionMenu actions={actions("pathForwardLabel")} />
              )}
            </Box>
            <RichTextEditor fieldName={"pathForward"} label={undefined} disabled={!userCanEditSummary || isClosed} />
          </Stack>
        </Box>
      </ExpansionPanel>
      {openDialog === "renameTitle" && editingField && (
        <EditFieldDialog
          fieldName={editingField}
          methods={methods}
          saveChanges={handleSave}
          onClose={() => setOpenDialog(null)}
          dialogTitle="Rename Title"
          label="Title"
          confirmButtonText="Confirm"
        />
      )}
    </Paper>
  );
};
