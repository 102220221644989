import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Box, Card, CardActionArea, CardContent, IconButton, Skeleton, styled, Typography } from "@mui/material";
import { IrisColors } from "app/mui/theme";
import { AttachmentModel as Attachment } from "@bluemarvel/iris-common/model";
import { attachmentIsImage, openAttachment } from "attachments/utilities";
import { dateFormat } from "common";
import _ from "lodash";
import moment from "moment";
import React, { useRef } from "react";
import { FileIconOrImage } from "./FileIconOrImage";

type LoadingPlaceholder = {
  id: number;
  isLoading: boolean;
};

export type AttachmentOrPlaceholder = Attachment | LoadingPlaceholder;

interface AttachmentGridItemProps {
  attachment: AttachmentOrPlaceholder;
  setOpenAttachment: (attachment: Attachment) => void;
  toggleOpenMenu: (attachment: Attachment, event: React.MouseEvent<HTMLElement>) => void;
  toggleSelected: (attachmentId: number) => void;
  isSelectMode: boolean;
  isSelected: boolean;
}

const GridTile = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: 0.5,
    width: "100%",
    maxWidth: {
      xs: "calc(100% / 2)",
      sm: "calc(100% / 3)",
      md: "calc(100% / 4)",
      lg: "calc(100% / 5)",
      xl: "min(calc(100% / 6), 185px)",
    },
  })
);

export const AttachmentsGridItem = (props: AttachmentGridItemProps) => {
  const { attachment: a, setOpenAttachment, toggleOpenMenu, isSelectMode, isSelected, toggleSelected } = props;

  const debouncedCardClick = useRef(
    _.debounce((e: React.MouseEvent<HTMLElement>) => {
      if (e.detail === 1 && attachmentIsImage(attachment.fileName)) {
        setOpenAttachment(attachment);
      } else {
        openAttachment(attachment);
      }
    }, 500)
  ).current;

  if ((a as LoadingPlaceholder).isLoading) {
    return (
      <GridTile>
        <Skeleton variant="rounded" height="190px" width="100%" />
      </GridTile>
    );
  }
  const attachment = a as Attachment;

  const onMenuSelect = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    toggleOpenMenu(attachment, e);
  };

  const onCardClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isSelectMode) {
      props.toggleSelected(attachment.id);
      return;
    }
    debouncedCardClick(e);
  };

  return (
    <GridTile>
      <Card
        sx={{
          width: "100%",
          position: "relative",
          ...(isSelected && {
            border: `2px solid ${IrisColors.red}`,
            backgroundColor: IrisColors.pink,
          }),
        }}
        variant="outlined"
      >
        <CardActionArea onClick={onCardClick}>
          <FileIconOrImage attachment={attachment} />

          <CardContent>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {attachment.caption || attachment.fileName}
            </Typography>
            <Typography
              variant="caption"
              component="div"
              sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {moment(attachment.created).format(dateFormat)}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Box
          sx={{
            position: "absolute",
            top: 4,
            right: 4,
          }}
        >
          {isSelectMode ? (
            <IconButton
              size="small"
              sx={{
                borderRadius: "50% !important", // important required to trump legacy_bootstrap
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
              onClick={() => toggleSelected(attachment.id)}
            >
              {isSelected ? (
                <CheckCircleIcon
                  sx={{
                    color: IrisColors.red,
                    stroke: IrisColors.red,
                    strokeWidth: "0.5px",
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  sx={{
                    color: IrisColors.red,
                    stroke: IrisColors.red,
                    strokeWidth: "0.5px",
                  }}
                />
              )}
            </IconButton>
          ) : (
            <IconButton
              onClick={onMenuSelect}
              size="small"
              sx={{
                borderRadius: "50% !important", // important required to trump legacy_bootstrap
                background: "rgba(255, 255, 255, 0.7)",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
        {attachment.isPrimaryImage && (
          <StarRoundedIcon
            sx={{
              position: "absolute",
              top: 4,
              left: 4,
              color: IrisColors.yellow,
              stroke: "white",
              strokeWidth: "0.5px",
            }}
          />
        )}
      </Card>
    </GridTile>
  );
};
