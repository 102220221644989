import _ from "lodash";

export enum AssetType {
  ControlValve = "ControlValve",
  IsolationValve = "IsolationValve",
  Machinery = "Machinery",
  ManualValve = "ManualValve",
  Measurement = "Measurement",
  Regulator = "Regulator",
  SteamTrap = "SteamTrap",
  Tank = "Tank",
  MotorOperatedValve = "MotorOperatedValve",
  ReliefValve = "ReliefValve",
}

export function parseAssetType(assetTypeAsString?: string | null): AssetType | null {
  if (!assetTypeAsString) return null;

  for (var i = 0; i < AssetTypes.length; i++) {
    const assetType = AssetTypes[i];
    if (assetTypeAsString.toLowerCase() === assetType.toLowerCase()) return assetType;
    if (assetTypeAsString.toLowerCase() === toAssetTypeDisplay(assetType)) return assetType;
  }

  return null;
}

export const toAssetTypeDisplay = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.ControlValve:
      return "Control Valve";
    case AssetType.IsolationValve:
      return "Isolation Valve";
    case AssetType.ManualValve:
      return "Manual Valve";
    case AssetType.ReliefValve:
      return "Relief Valve";
    case AssetType.SteamTrap:
      return "Steam Trap";
    case AssetType.Tank:
      return "Tank";
    case AssetType.MotorOperatedValve:
      return "Motor Operated Valve";
  }
  return assetType as string;
};

export const AssetTypes = _.values(AssetType);
export const AssetTypeOptions = AssetTypes.map((assetType) => ({
  value: assetType,
  label: toAssetTypeDisplay(assetType),
}));
export type AssetTypeOption = typeof AssetTypeOptions;

export const normalizeAssetType = (assetTypeAsString: string | null) => {
  if (!assetTypeAsString) return null;

  for (var i = 0; i < AssetTypes.length; i++) {
    const assetType = AssetTypes[i];
    if (assetTypeAsString.toLowerCase() === assetType.toLowerCase()) return assetType;
    if (assetTypeAsString.toLowerCase() === toAssetTypeDisplay(assetType)) return assetType;
  }

  return null;
};
