import { Box, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { CustomerDashboardViewModel } from "dashboard/models";
import _ from "lodash";
import { FunctionComponent, useState } from "react";
import { WidgetSkeleton } from "../WidgetSkeleton";
import { VisualIntegrityWidget } from "./VisualIntegrity/VisualIntegrityWidget";
import { AssetHealthWidget } from "./AssetHealth/AssetHealthWidget";
import { RecordTypeWidget } from "./RecordType/RecordTypeWidget";
import { KpiSavingsWidget } from "./KpiSavings/KpiSavingsWidget";

interface TrendingWidgetProps {
  data?: CustomerDashboardViewModel;
}

export const TrendingWidget: FunctionComponent<TrendingWidgetProps> = ({ data }) => {
  enum TrendingMenuOption {
    RecordCreation = "Record Creation",
    AssetHealth = "Asset Health",
    VisualIntegrity = "Visual Integrity",
    KpiSavings = "KPI Savings",
  }
  const [selectedMenu, setSelectedMenu] = useState<TrendingMenuOption>(TrendingMenuOption.RecordCreation);
  const handleChange = (event: React.MouseEvent<HTMLElement>, newTrendingMenuOption: string | null) => {
    if (newTrendingMenuOption !== null) {
      setSelectedMenu(newTrendingMenuOption as TrendingMenuOption);
    }
  };

  return (
    <Paper sx={{ p: { xs: 2, md: 3 }, width: "100%", height: "636px" }}>
      {!!!data ? (
        <WidgetSkeleton />
      ) : (
        <Stack spacing={4} sx={{ height: "100%", minHeight: 0 }}>
          <Typography variant="h5">Trending</Typography>

          <Box>
            <ToggleButtonGroup exclusive size="small" color="secondary" value={selectedMenu} onChange={handleChange}>
              {_.map(TrendingMenuOption, (value) => (
                <ToggleButton key={value} value={value}>
                  {value.toString()}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Box sx={{ width: "100%", height: "100%", minHeight: 0 }}>
            {selectedMenu === TrendingMenuOption.RecordCreation && (
              <RecordTypeWidget data={data.yearlyRecordCountsByRecordType} />
            )}
            {selectedMenu === TrendingMenuOption.AssetHealth && (
              <AssetHealthWidget data={data.yearlyAssetCountsByValveHealth} />
            )}
            {selectedMenu === TrendingMenuOption.VisualIntegrity && (
              <VisualIntegrityWidget data={data.yearlyAssetCountsByVisualIntegrity} />
            )}
            {selectedMenu === TrendingMenuOption.KpiSavings && <KpiSavingsWidget data={data.yearlyKpiSavings} />}
          </Box>
        </Stack>
      )}
    </Paper>
  );
};
