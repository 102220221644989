import { TrafficLightValue } from "records/models";

export interface PneumaticPartialStrokeCondition {
  torqueThrust: TrafficLightValue | null;
  breakoutPressure: TrafficLightValue | null;
  endPointProfile: TrafficLightValue | null;
  dynamicErrorBand: TrafficLightValue | null;
  driveSignal: TrafficLightValue | null;
}

export interface PneumaticPartialStrokeConditionValidity {
  valid: boolean;
  torqueThrustValid: boolean;
  breakoutPressureValid: boolean;
  endPointProfileValid: boolean;
  dynamicErrorBandValid: boolean;
  driveSignalValid: boolean;
}

export enum PneumaticPartialStrokeConditionFieldLabel {
  TorqueThrust = "Torque/thrust",
  BreakoutPressure = "Breakout pressure",
  EndPointProfile = "End point profile",
  DynamicErrorBand = "Dynamic error band",
  DriveSignal = "Drive signal",
}

export enum PneumaticPartialStrokeConditionTooltip {
  TorqueThrust = "Assess the torque/thrust present throughout partial stroke operation by comparing analyzed values to baseline/expected conditions.",
  BreakoutPressure = "Assess the actuator pressure required to initiate valve movement from it’s fail-safe position, in response to a safety demand signal.",
  EndPointProfile = "Assess the output saturation at the fail-safe position by evaluating supply pressure, end point pressure control, hold pressure, etc.",
  DynamicErrorBand = "Assess the hysteresis and dead band present in opening and closing signal lines, by comparing offset.",
  DriveSignal = "Assess the effort level to maintain/leave fail-safe position and/or reach targeted travel.",
}
