import { KpiEmissionsType } from "./models";

export type KpiCommodityUnit =
  | "barrels"
  | "cubic feet"
  | "cubic metres"
  | "decs"
  | "gallons"
  | "kilowatts"
  | "litres"
  | "megawatts"
  | "standard cubic feet"
  | "tonnes";
const KpiCommodityUnits: KpiCommodityUnit[] = [
  "barrels",
  "cubic feet",
  "cubic metres",
  "decs",
  "gallons",
  "kilowatts",
  "litres",
  "megawatts",
  "standard cubic feet",
  "tonnes",
];
export const GetKpiCommodityUnitOptions = (commodityUnits: KpiCommodityUnit[]) =>
  commodityUnits.map((o) => ({ label: o, value: o }));

export const GetKpiCommodityUnitsForEmissions = (
  emissionType: KpiEmissionsType | string | null
): KpiCommodityUnit[] => {
  switch (emissionType) {
    case "Carbon Dioxide (CO2)":
      return ["tonnes"];
    case "Methane (CH4)":
      return ["cubic metres", "standard cubic feet", "tonnes"];
  }

  return ["cubic metres", "standard cubic feet", "tonnes"];
};
export const KpiCommodityUnitsForSpills: KpiCommodityUnit[] = KpiCommodityUnits.filter(
  (u) => u.indexOf("watts") === -1 && u !== "standard cubic feet"
);
export const KpiCommodityUnitsForProduction: KpiCommodityUnit[] = KpiCommodityUnits.filter(
  (u) => u !== "standard cubic feet"
);

export const normalizeKpiCommodityUnit = (value?: string | null) => {
  if (!!value) {
    const selected = KpiCommodityUnits.filter((v) => v.toLowerCase() === value.toLowerCase());
    if (selected.length > 0) {
      return selected[0];
    }
  }
  return null;
};
