import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
} from "app";
import { RecordsEvent, RecordsEventDetail, RecordsEventDigest, RecordsEventFilters } from "./models";

export const recordsEventsMutationUriMatchPatterns = [/api\/recordsEvents.*/gi];

export const useGetRecordsEventDigestQuery = (recordsEventId: number) =>
  useBaseAxiosGetRequest<RecordsEventDigest>(`api/recordsEvents/digests/${recordsEventId}`, {
    useNode: true,
  });

export const useGetFilteredRecordsEventDigestsRequest = (siteId: number) =>
  useBaseAxiosPostRequest<Partial<RecordsEventFilters>, RecordsEventDigest[]>(
    `api/recordsEvents/digests/filtered/${siteId}`,
    { app: { hideFromAutosaver: true }, useNode: true }
  );

export const useGetFilteredRecordsEventDetailsRequest = (siteId: number) =>
  useBaseAxiosPostRequest<Partial<RecordsEventFilters>, RecordsEventDetail[]>(
    `api/recordsEvents/details/filtered/${siteId}`,
    { app: { hideFromAutosaver: true }, useNode: true }
  );

export const useGetRecordsEventDetailQuery = (recordsEventId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<RecordsEventDetail>(
    `api/recordsEvents/details/${recordsEventId}`,
    recordsEventsMutationUriMatchPatterns,
    { useNode: true }
  );

export const useCreateRecordsEventRequest = () =>
  useBaseAxiosPostRequest<Partial<RecordsEvent>, RecordsEventDigest>(`api/recordsEvents`, {
    useNode: true,
  });

export const useUpdateRecordsEventRequest = () =>
  useBaseAxiosPutRequest<RecordsEvent>(`api/recordsEvents`, {
    useNode: true,
  });
export const useDeleteRecordsEventRequest = () =>
  useBaseAxiosDeleteRequest((id) => `api/recordsEvents/${id}`, {
    useNode: true,
  });
