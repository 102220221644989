import { ValueKpiType } from "./models";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { DropletOutlinedIcon } from "icons/DropletOutlinedIcon";

interface KpiIconProps {
  kpiType: ValueKpiType;
}

export function KpiTypeIcon({ kpiType }: KpiIconProps) {
  switch (kpiType) {
    case "Legacy":
      return <HistoryOutlinedIcon />;
    case "OneTimeLabourOrEquipmentCostSavings":
      return <HandymanOutlinedIcon />;
    case "OneTimeDowntimeAvoidance":
      return <FactoryOutlinedIcon />;
    case "OneTimeReleaseOrSpillAvoidance":
      return <DropletOutlinedIcon />;
    case "OneTimeScheduleDelayReduction":
      return <TimerOutlinedIcon />;
    case "OngoingEmissionsReduction":
      return <TrendingDownOutlinedIcon />;
    case "OngoingMaintenanceSavingsOrProductionImprovement":
      return <TrendingUpOutlinedIcon />;
  }
  return <p>KpiIcon</p>;
}
