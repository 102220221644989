import { GridFilterInputValueProps } from "@mui/x-data-grid";
import { GridFilterInputMultiSelectProps, GridFilterSelectOption } from "./MultiFilterInputSelect";

export const withSelectOptions =
  (
    WrappedMultiSelectComponent: React.FC<GridFilterInputMultiSelectProps>,
    selectOptions: GridFilterSelectOption[]
  ): React.FC<GridFilterInputValueProps> =>
  (props: GridFilterInputValueProps) =>
    <WrappedMultiSelectComponent {...props} selectOptions={selectOptions} />;
