import { ResponsiveBar } from "@nivo/bar";
import { IrisColors } from "app/mui/theme";
import { getUniqueKeys } from "common";
import { HealthCategoryType } from "records";

const theme = {
	axis: {
		ticks: {
			text: {
				fill: "#777",
			},
		},
		legend: {
			text: {
				fontWeight: 500,
				fontSize: "12pt",
				fill: "#777",
			},
		},
	},
	legends: {
		text: {
			fontWeight: 600,
		},
	},
};

const keysAndColors = [
	{ key: HealthCategoryType.Good, color: IrisColors.green },
	{ key: HealthCategoryType.Minor, color: IrisColors.yellow },
	{ key: HealthCategoryType.Moderate, color: IrisColors.orange },
	{ key: HealthCategoryType.Severe, color: IrisColors.red },
];

export const AssetHealthBarChart = ({ data }) => {
	const uniqKeysFromDataSet = getUniqueKeys(data);

	const keys = keysAndColors.filter((kc) => uniqKeysFromDataSet.includes(kc.key)).map((kc) => kc.key);
	const colors = keysAndColors.filter((kc) => uniqKeysFromDataSet.includes(kc.key)).map((kc) => kc.color);

	return (
		<ResponsiveBar
			theme={theme}
			data={data}
			keys={keys}
			indexBy="year"
			margin={{ top: 50, right: 160, bottom: 60, left: 70 }}
			padding={0.3}
			valueScale={{ type: "linear" }}
			colors={colors}
			enableLabel={false}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "TIME",
				legendPosition: "middle",
				legendOffset: 42,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "ASSET VOLUME",
				legendPosition: "middle",
				legendOffset: -50,
			}}
			legends={[
				{
					dataFrom: "keys",
					anchor: "right",
					direction: "column",
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 9,
					itemWidth: 110,
					itemHeight: 26,
					itemDirection: "left-to-right",
					itemOpacity: 0.85,
					symbolSize: 18,

					symbolShape: (params) => {
						let color = keysAndColors.find((kc) => kc.key === params.id)!.color;
						let strokeColor = keysAndColors.find((kc) => kc.key === params.id)!.color;

						return (
							<rect
								x={params.x}
								y={params.y}
								fill={color}
								opacity="1"
								strokeWidth="2"
								stroke={strokeColor}
								width={params.size}
								height={params.size}
								style={{ pointerEvents: "none" }}
							></rect>
						);
					},
					effects: [
						{
							on: "hover",
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	);
};

export interface AssetHealthBarChartData extends Record<HealthCategoryType, number> {
	year: number;
}
