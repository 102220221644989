import { Box, Popover, Stack, Typography } from "@mui/material";
import { gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { useSiteContext } from "app/contexts";
import { usePopover } from "app/mui/common/usePopover";
import customColors from "assets/customColors";
import { AssetDetailView } from "assets/models/assetDetail";
import { toAssetTypeDisplay } from "assets/models/assetType";
import { wholeNumberFormatter } from "common";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";

const graphColors = [
  customColors.congressBlue,
  customColors.seance,
  customColors.roti,
  customColors.brightTurquoise,
  customColors.frenchRose,
  customColors.illusion,
  customColors.pizzaz,
  customColors.lightningYellow,
  customColors.robinsEggBlue,
  customColors.forestGreen,
];

export const useTabelRowDataExtractor = (apiRef: React.MutableRefObject<GridApiPro>) => {
  const [rows, setRows] = React.useState<AssetDetailView[]>([]);

  const updateData = () => {
    if (apiRef.current) {
      setRows(gridFilteredSortedRowEntriesSelector(apiRef).map((r) => r.model as AssetDetailView));
    }
  };

  const debouncedHandleStateChanged = useCallback(_.debounce(updateData, 500), []);

  useEffect(() => {
    if (!apiRef.current || !apiRef.current.subscribeEvent) {
      return;
    }

    const eventListeners = [apiRef.current.subscribeEvent("stateChange", debouncedHandleStateChanged)];

    return () => {
      eventListeners.forEach((cleanUp) => cleanUp());
    };
  }, [apiRef.current]);

  return rows;
};

interface ChartItemDataFormat {
  id: any;
  label: string;
  value: number;
}

const useCriticalityData = (data: AssetDetailView[]): ChartItemDataFormat[] => {
  const siteContext = useSiteContext().currentSite!;
  const criticalities: { [id: string]: ChartItemDataFormat } = { "n/a": { id: "n/a", label: "N/A", value: 0 } };

  _.sortBy(siteContext.customer.criticalities, (c) => c.calculationValue).forEach((c) => {
    const id = c.id;
    const label = siteContext.customer.criticalities.find((c) => c.id === id)?.displayValue ?? "";
    criticalities[id.toString()] = { id, label, value: 0 };
  });

  data.forEach((d) => {
    const id = d.model.criticalityId?.toString() || "n/a";
    criticalities[id].value = criticalities[id].value + 1;
  });

  return Object.values(_.sortBy(criticalities, (c) => c.label));
};

const useObsoleteStatusData = (data: AssetDetailView[]): ChartItemDataFormat[] => {
  const stats = {
    Active: { id: "Active", label: "Active", value: 0 },
    Obsolete: { id: "Obsolete", label: "Obsolete", value: 0 },
  };

  data.forEach((d) => {
    const isObsolete = d.obsoleteComponentCount > 0 ? "Obsolete" : "Active";
    stats[isObsolete].value += 1;
  });

  return Object.values(stats);
};

const useAssetTypesData = (data: AssetDetailView[]) => {
  const stats: { [key: string]: any } = {};

  data.forEach((d) => {
    const key = toAssetTypeDisplay(d.model.assetType);
    if (!stats[key]) {
      stats[key] = 0;
    }

    stats[key] += 1;
  });

  return [stats];
};

export const AssetListDataViz = (props: { apiRef: React.MutableRefObject<GridApiPro> }) => {
  const rows = useTabelRowDataExtractor(props.apiRef);
  const total = rows.length;
  const assetTypes = useAssetTypesData(rows);
  const criticalities = useCriticalityData(rows);
  const obsoletes = useObsoleteStatusData(rows);
  const hasResults = total > 0;

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.grey[200],
        borderRadius: 1,
        height: "70px",
        width: "100%",
        maxWidth: "700px",
      }}
    >
      {!hasResults && (
        <Stack sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "24px" }}>
          <Typography variant="caption" sx={{ textAlign: "center" }}>
            No results found.
          </Typography>
        </Stack>
      )}
      {hasResults && (
        <Stack direction={"row"} height={"70px"}>
          <TotalAssetCountBarChart data={total} />
          <AssetsByRecordTypeBarChart data={assetTypes} />
          <AssetsByCriticalityPieChart data={criticalities} />
          <AssetsByObsoletePieChart data={obsoletes} />
        </Stack>
      )}
    </Box>
  );
};

const LabeledChart = (props: { label: string; children: React.ReactNode }) => {
  return (
    <Stack sx={{ flex: "0 1 auto", width: "100%" }}>
      <Box sx={{ height: "48px", display: "flex", alignItems: "end", justifyContent: "center", paddingTop: "8px" }}>
        {props.children}
      </Box>
      <Typography variant="caption" sx={{ textAlign: "center" }}>
        {props.label}
      </Typography>
    </Stack>
  );
};

export const TotalAssetCountBarChart = (props: { data: number }) => {
  return (
    <LabeledChart label="Assets">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          background: graphColors[0],
          color: "white",
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        {props.data}
      </Typography>
    </LabeledChart>
  );
};

export const AssetsByRecordTypeBarChart = (props: { data: any[] }) => {
  return (
    <LabeledChart label="Types">
      <MyResponsiveBar data={props.data} />
    </LabeledChart>
  );
};

export const AssetsByCriticalityPieChart = (props: { data: ChartItemDataFormat[] }) => {
  return (
    <LabeledChart label="Criticality">
      <MyResponsivePie data={props.data} />
    </LabeledChart>
  );
};

export const AssetsByObsoletePieChart = (props: { data: ChartItemDataFormat[] }) => {
  return (
    <LabeledChart label="Status">
      <MyResponsivePie data={props.data} />
    </LabeledChart>
  );
};

const MyResponsivePie = ({ data }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <Box onMouseEnter={openPopover} onMouseLeave={closePopover} width="100%" height="100%">
      <ResponsivePie
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        startAngle={-90}
        endAngle={90}
        borderWidth={0}
        isInteractive={false}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={graphColors}
        legends={[]}
        animate={false}
      />
      <HoverTooltip {...popoverProps} allData={data} />
    </Box>
  );
};

const MyResponsiveBar = ({ data }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <Box onMouseEnter={openPopover} onMouseLeave={closePopover} width="100%" height="100%">
      <ResponsiveBar
        data={data}
        margin={{ top: 0, right: 20, bottom: 0, left: 20 }}
        layout="horizontal"
        groupMode={"stacked"}
        valueScale={{ type: "linear" }}
        keys={Object.keys(data[0])}
        indexScale={{ type: "band", round: true }}
        colors={graphColors}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        legends={[]}
        isInteractive={false}
        animate={false}
      />
      <HoverTooltip
        {...popoverProps}
        allData={Object.keys(data[0]).map((k) => ({ id: k, label: k, value: data[0][k] }))}
      />
    </Box>
  );
};

interface HoverTooltipProps {
  allData: ChartItemDataFormat[];
  open: boolean;
  anchorEl: any;
  onClose: () => void;
}

const HoverTooltip = (props: HoverTooltipProps) => {
  return (
    <Popover
      sx={{
        pointerEvents: "none",
        marginTop: "20px",
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={props.onClose}
      disableRestoreFocus
    >
      <Box sx={{ p: 2 }}>
        <table style={{ border: "none", borderCollapse: "collapse", maxWidth: 0, whiteSpace: "nowrap" }}>
          {props.allData?.map((item, index) => (
            <tr key={item.id}>
              <td style={{ textAlign: "right", width: "100%" }}>
                <Typography component="span" variant="body2" sx={{ color: graphColors[index], fontWeight: "bold" }}>
                  {item.label}:
                </Typography>
              </td>
              <td style={{ textAlign: "left" }}>
                <Typography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
                  {wholeNumberFormatter.format(item.value)}
                </Typography>
              </td>
            </tr>
          ))}
        </table>
      </Box>
    </Popover>
  );
};
