import { Typography, Stack } from "@mui/material";

interface ListPageHeaderProps {
  title: string;
  icon?: React.ReactNode;
}

export const PageTitle = ({ title, icon }: ListPageHeaderProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        svg: {
          fill: (theme) => theme.palette.primary.main,
          width: "28px",
          height: "28px",
        },
      }}
    >
      {icon || <></>}
      <Typography variant="h5" fontWeight="bold">
        {title}
      </Typography>
    </Stack>
  );
};
