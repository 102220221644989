import { Typography } from "@mui/material";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { KeywordsLoader } from "app/mui/loaders/KeywordsLoader";
import { Keyword } from "keywords";
import _ from "lodash";
import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

interface HasKeywords {
  keywords: Keyword[];
}

interface KeywordsFieldProps<T extends FieldValues & HasKeywords> {
  formMethods: UseFormReturn<T, any>;
  modelKeywords: Keyword[];
  readOnly?: boolean;
}

const getOptionLabel = (keyword: Keyword) =>
  keyword.id === 0 ? `Create new keyword "${keyword.value}"` : keyword.value;

export const KeywordsField = <T extends FieldValues & HasKeywords>(props: KeywordsFieldProps<T>) => {
  /// When a user clicks on 'Create new keyword "abc"', the keywords field will have added [{id:0, value:'abc'}]
  /// When PUT or POST-ed to the server, the server model will return the keyword, along with others, populated with an id.
  /// This effect will then reset the form's keywords field to the actual keyword id from the server, causing it to show up on the field.
  useEffect(() => {
    const formKeywords = props.formMethods.getValues().keywords ?? [];
    const newFormKeywords = formKeywords.filter((k) => k.id === 0);
    if (newFormKeywords.length > 0) {
      const createdFormKeywords = props.modelKeywords.filter((mk) =>
        newFormKeywords.some((fk) => fk.value === mk.value)
      );
      if (createdFormKeywords.length === newFormKeywords.length) {
        const updatedKeywords = formKeywords.map((fk) => {
          if (fk.id > 0) return fk;
          const createdKeyword = _.find(createdFormKeywords, (cfk) => cfk.value === fk.value);
          return createdKeyword ?? fk;
        });

        (props.formMethods as UseFormReturn<any, any>).setValue("keywords", updatedKeywords, {
          shouldDirty: false,
        });
      }
    }
  }, [props.modelKeywords.map((k) => k.id)]);

  const formHasNewKeywords = (props.formMethods.getValues().keywords ?? []).some((k) => k.id === 0);

  return props.readOnly ? (
    <FormItemLayout label="Keywords">
      {() =>
        props.modelKeywords?.length ? (
          props.modelKeywords.map((keyword) => (
            <Typography variant="body2" fontWeight="bold" lineHeight="1">
              {keyword.value}
            </Typography>
          ))
        ) : (
          <Typography variant="body2" fontWeight="bold" lineHeight="1">
            —
          </Typography>
        )
      }
    </FormItemLayout>
  ) : (
    <KeywordsLoader modelKeywords={props.modelKeywords}>
      {(options) => (
        <AutocompleteFormItem
          multiple
          fieldName="keywords"
          label="Keywords"
          {...options}
          getOptionLabel={getOptionLabel}
          transformMultiFieldValues={(values) => {
            return (values ?? []).filter((v) => v.id > 0);
          }}
          autocompleteProps={{
            disabled: formHasNewKeywords,
            isOptionEqualToValue: (option, value) => option.id === value.id,
            renderOption: (renderProps, keyword) => (
              <li {...{ ...renderProps, key: keyword.id }}>{getOptionLabel(keyword)}</li>
            ),
            filterOptions: (options, state) => {
              const inputValue = state.inputValue ?? "";
              const filtered = options.filter((o) => o.value.toLowerCase().includes(inputValue.toLowerCase()));

              if (
                !!inputValue &&
                (filtered.length === 0 ||
                  !filtered.some((f) => f.value.toLowerCase() === state.inputValue.trim().toLowerCase()))
              ) {
                filtered.push({
                  id: 0,
                  value: inputValue,
                });
              }

              const nonExistingOptions = filtered.filter((k) => !props.modelKeywords.some((m) => m.id === k.id));
              return nonExistingOptions;
            },
          }}
        />
      )}
    </KeywordsLoader>
  );
};
