import { AssetDigest } from "assets";
import { RecordDigest } from "records";

export interface ApplicationDigest {
   id: number;
   name: string;
   siteId: number;
}

export interface ApplicationDetailView {
   id: number;
   siteId: number;
   model: Application;
   attachmentCount: number;
   strategies: Strategy[];
   failureModes: FailureModeView[];
   mitigations: MitigationView[];
}

export interface Application {
   id: number;
   name: string;
   siteId: number;
   processCriticality: string;
   maintenanceHistoryAndIntensity: string;
   proactiveRecommendations: string;
   assets: AssetDigest[];
}

export interface Strategy {
   id: number;
   applicationId: number;
   name: string;
   sortOrder: number;
   assets: AssetDigest[];
}
export interface FailureModeDigest {
   id: number;
   strategyId: number;
   name: string;
}

export interface FailureModeView {
   id: number;
   strategyId: number;
   model: FailureMode;
   mitigations: MitigationDigest[];
}

export interface FailureMode {
   id: number;
   strategyId: number;
   name: string;
   potentialEffect: string;
   potentialRootCause: string;
   severity: FailureSeverity | null;
   unmitigatedProbability: FailureProbability | null;
   mitigatedProbability: FailureProbability | null;
   detectionAbility: FailureDetectionAbility | null;
   contingencyPlan: string;
   records: RecordDigest[];
}

export interface MitigationDigest {
   id: number;
   strategyId: number;
   name: string;
   type: MitigationType;
}

export interface MitigationView {
   id: number;
   strategyId: number;
   model: Mitigation;
   implementations: MitigationImplementation[];
}

export interface Mitigation {
   id: number;
   strategyId: number;
   name: string;
   type: MitigationType;
   setPoint: string;
   outOfService: boolean;
   frequency: string;
   diagnosisNotes: string;
   details: string;

   failureModes: FailureModeDigest[];
}

export enum MitigationType {
   Alert = "Alert",
   Diagnostic = "Diagnostic",
   FieldWork = "Field work",
   Proactive = "Proactive",
}

export interface MitigationImplementation {
   assetId: number;
   mitigationId: number;
   implemented: Date;
}

export type FailureSeverity = "LowImpact" | "ModerateImpact" | "SignificantImpact" | "Failure";
export type FailureProbability = "Remote" | "Low" | "Moderate" | "High";

export type FailureDetectionAbility =
   | "AlwaysDetectedAndPrevented"
   | "SometimesDetectedAndPrevented"
   | "DetectedOnly"
   | "UnableToDetect";
