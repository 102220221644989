import * as React from "react";
import { Button, ButtonGroup, Stack, TextField, TextFieldProps } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { Controller } from "react-hook-form";
import { withEditableToggle } from "../withEditableToggle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { dateFormat } from "common/formats";
import { ReadonlyFormItem } from "../ReadonlyFormItem";

export interface FollowUpDateFormItemProps {
  label: string;
  hint?: string;
  textFieldProps?: TextFieldProps;
  fieldName?: string;
  readOnly?: boolean;
  readOnlyValue?: Date | null;
}

export const FollowUpDateFormItem = (props: FollowUpDateFormItemProps) => {
  return props.readOnly ? (
    <ReadonlyFormItem
      label={props.label || ""}
      value={props.readOnlyValue ? moment(props.readOnlyValue).format(dateFormat) : undefined}
    />
  ) : (
    <Controller
      name={props.fieldName ?? ""}
      render={({ field: { onChange, value, ...fieldProps }, fieldState }) => (
        <FormItemLayout
          label={props.label}
          hint={props.hint}
          controlled
          error={fieldState.error?.message}
          variant="noError"
        >
          {({ ...inputProps }) => {
            const addDays = (value: number) => {
              onChange(moment().add(value, "d"));
            };

            return (
              <Stack direction="row" spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disablePast
                    value={value ? moment(value) : null}
                    onChange={(parsedValue, keyboardValue) => onChange(parsedValue?.toDate())}
                    renderInput={(params) => <TextField {...props.textFieldProps} {...inputProps} {...params} />}
                  />
                </LocalizationProvider>
                <ButtonGroup size="small" variant="outlined" disabled={inputProps.disabled}>
                  <Button onClick={() => addDays(7)}>+7</Button>
                  <Button onClick={() => addDays(30)}>+30</Button>
                  <Button onClick={() => addDays(60)}>+60</Button>
                  <Button onClick={() => addDays(90)}>+90</Button>
                </ButtonGroup>
              </Stack>
            );
          }}
        </FormItemLayout>
      )}
    />
  );
};

export const EditableFollowUpDateFormItem = withEditableToggle(FollowUpDateFormItem);
