import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";

export const ExpansionPanelSkeleton = () => {
	return (
		<Paper>
			<Box sx={{ p: 2 }}>
				<Stack spacing={1} direction="row" alignItems="center" width="100%">
					<Typography variant="h6" width={200}>
						<Skeleton />
					</Typography>
				</Stack>
			</Box>
		</Paper>
	);
};
