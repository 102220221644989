import { Box, Paper, Stack } from "@mui/material";
import { UseFormReturn } from "react-hook-form";

import { PageTitle } from "app/mui/common/headers/PageTitle";
import { KpiIcon } from "icons/KpiIcon";
import { KpiTypeBadge } from "kpis/KpiTypeBadge";
import { KpiTemplateDetail, KpiTemplateModel } from "kpis/models";
import { ArchivedStatusBadge } from "recordsEvents/mui/ArchivedStatusBadge";
import { KpiTemplateActions } from "./KpiTemplateActions";
import { IrisColors } from "app/mui/theme";

interface KpiTemplateDetailsProps {
  kpiTemplateDetail: KpiTemplateDetail;
  methods: UseFormReturn<KpiTemplateModel>;
  saveChanges: (onValid?: () => void) => void;
}

export const KpiTemplateHeader = (props: KpiTemplateDetailsProps) => {
  const { kpiTemplateDetail, methods, saveChanges } = props;

  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" pb={2}>
            <PageTitle title={kpiTemplateDetail.model.name} icon={<KpiIcon />} />
            <Stack alignItems="stretch">
              <KpiTemplateActions kpiTemplateDetail={kpiTemplateDetail} methods={methods} saveChanges={saveChanges} />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" pb={2}>
            <KpiTypeBadge kpiType={kpiTemplateDetail.model.kpiType} color={IrisColors.blueSpartan} />
            <ArchivedStatusBadge isArchived={!kpiTemplateDetail.model.enabled} activeIcon={<></>} />
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};
