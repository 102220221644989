import { SvgIcon, SvgIconProps } from "@mui/material";

export const UnknownIcon = (props: Omit<SvgIconProps, "viewBox">) => {
  return (
    <SvgIcon viewBox={"0 0 28 28"} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.304612 13.2924C-0.0859126 13.6829 -0.085912 14.3161 0.304612 14.7066L13.2924 27.6944C13.6829 28.0849 14.3161 28.0849 14.7066 27.6944L27.6944 14.7066C28.0849 14.3161 28.0849 13.6829 27.6944 13.2924L14.7066 0.304612C14.3161 -0.0859126 13.6829 -0.085912 13.2924 0.304612L0.304612 13.2924ZM12.5669 16.8426V16.9859H14.402V16.8426C14.4057 16.4532 14.4516 16.1262 14.5397 15.8617C14.6316 15.5935 14.7712 15.3602 14.9586 15.1618C15.1496 14.9635 15.3939 14.7724 15.6915 14.5887C16.0295 14.3867 16.3215 14.1571 16.5677 13.8999C16.8138 13.6427 17.003 13.3488 17.1353 13.0182C17.2712 12.6875 17.3392 12.3128 17.3392 11.894C17.3392 11.2731 17.1885 10.7368 16.8873 10.2849C16.5897 9.82933 16.1727 9.47848 15.6364 9.23233C15.1037 8.98619 14.4865 8.86312 13.7848 8.86312C13.1419 8.86312 12.5541 8.98068 12.0214 9.2158C11.4923 9.45092 11.0662 9.80361 10.7429 10.2739C10.4233 10.7441 10.2543 11.3282 10.2359 12.0263H12.1977C12.2161 11.6809 12.3006 11.3962 12.4512 11.1721C12.6055 10.9443 12.7984 10.7753 13.0298 10.6651C13.2649 10.5512 13.5129 10.4943 13.7738 10.4943C14.0566 10.4943 14.312 10.5531 14.5397 10.6706C14.7712 10.7882 14.9549 10.9535 15.0908 11.1666C15.2267 11.3797 15.2947 11.6313 15.2947 11.9216C15.2947 12.1787 15.2433 12.412 15.1404 12.6214C15.0375 12.8271 14.8961 13.0145 14.7161 13.1835C14.5397 13.3488 14.3395 13.5013 14.1154 13.6409C13.7885 13.8429 13.5111 14.0652 13.2833 14.3077C13.0555 14.5465 12.8792 14.8624 12.7543 15.2555C12.633 15.6486 12.5706 16.1776 12.5669 16.8426ZM12.6716 20.0719C12.9104 20.307 13.1951 20.4245 13.5258 20.4245C13.7462 20.4245 13.9464 20.3713 14.1264 20.2647C14.3101 20.1545 14.4571 20.0076 14.5673 19.8239C14.6812 19.6402 14.7381 19.4363 14.7381 19.2122C14.7381 18.8816 14.6169 18.5987 14.3744 18.3635C14.1356 18.1284 13.8527 18.0109 13.5258 18.0109C13.1951 18.0109 12.9104 18.1284 12.6716 18.3635C12.4328 18.5987 12.3134 18.8816 12.3134 19.2122C12.3134 19.5502 12.4328 19.8367 12.6716 20.0719Z"
      />
    </SvgIcon>
  );
};
