import { Option, AutocompleteFormItem, AutocompleteFormItemProps } from "app/mui/forms/AutocompleteFormItem";
import { GetKpiCommodityUnitOptions, KpiCommodityUnit } from "kpis/models";

interface UnitFormItemProps extends Omit<AutocompleteFormItemProps<false, false, Option>, "options"> {
  readOnly?: boolean;
  units: KpiCommodityUnit[];
}

export function UnitFormItem(props: UnitFormItemProps) {
  const { readOnly, units, autocompleteProps, ...rest } = props;

  // const options = KpiCommodityUnitsForProduction.map((unit) => ({ id: unit, label: unit }));
  const options = GetKpiCommodityUnitOptions(units).map((x) => ({ id: x.value, label: x.label }));

  return (
    <AutocompleteFormItem
      options={options}
      placeholder="Unit"
      autocompleteProps={{ ...autocompleteProps, disabled: readOnly }}
      {...rest}
    />
  );
}
