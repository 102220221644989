import React, { useEffect, useState } from "react";
import { KpiCommodityUnitsForProduction, KpiOngoingMaintenanceSavingsOrProductionImprovement } from "../models";
import { NumberInputGroup } from "records/bs/NumberInputGroup";
import { Col, Collapse, FormGroup, Input, Label, Row } from "reactstrap";
import _ from "lodash";
import { getInvalidValidity, getValidValidity, twoDecimalNumberFormatter, Validity } from "common";
import { EditKpiFormProps } from "./EditKpiFormProps";
import { SelectTimeUnit } from "../../common/fields/SelectTimeUnit";
import { KpiRemoteHoursOption, RemoteHoursFormGroup } from "../formFields/RemoteHoursFormGroup";
import NumberFormat from "react-number-format";
import { ProductionRateFormGroup } from "kpis/formFields/ProductionRateFormGroup";
import { CommodityPricePerUnitFormGroup } from "kpis/formFields/CommodityPricePerUnitFormGroup";
import { KpiHelpTooltip } from "kpis/formFields/KpiHelpTooltip";
import { EffectiveRateTooltip } from "kpis/formFields/EffectiveRateTooltip";

interface Props extends EditKpiFormProps {}

export interface KpiOngoingMaintenanceSavingsOrProductionImprovementValidity {
  spartanHours: Validity;
  remoteHours: Validity;
  customerHours: Validity;
  equipmentAndMaterialsCost: Validity;
  maintenanceCostBeforeImplementation: Validity;
  maintenanceCostAfterImplementation: Validity;
  maintenanceCostTimeUnit: Validity;
  productionRateBeforeImplementation: Validity;
  productionRateAfterImplementation: Validity;
  commodityUnit: Validity;
  commodityType: Validity;
  commodityProductionRateTimeUnit: Validity;
  commodityPricePerUnit: Validity;
}

export const getKpiOngoingMaintenanceSavingsOrProductionImprovementValidity = (
  fields: KpiOngoingMaintenanceSavingsOrProductionImprovement
): KpiOngoingMaintenanceSavingsOrProductionImprovementValidity => {
  const maintenanceSavingsIsOngoing = !!fields.maintenanceSavingsIsOngoing;
  const productionImprovementIsOngoing = !!fields.productionImprovementIsOngoing;
  return {
    spartanHours: getValidValidity(),
    remoteHours: !fields.spartanHours || fields.remoteHours !== null ? getValidValidity() : getInvalidValidity(),
    customerHours: getValidValidity(),
    equipmentAndMaterialsCost: getValidValidity(),
    maintenanceCostBeforeImplementation:
      !maintenanceSavingsIsOngoing || (fields.maintenanceCostBeforeImplementation || 0) > 0
        ? getValidValidity()
        : getInvalidValidity(),
    maintenanceCostAfterImplementation: getValidValidity(),
    maintenanceCostTimeUnit:
      !maintenanceSavingsIsOngoing || !!fields.maintenanceCostTimeUnit ? getValidValidity() : getInvalidValidity(),
    productionRateBeforeImplementation:
      !productionImprovementIsOngoing || (fields.productionRateBeforeImplementation || 0) > 0
        ? getValidValidity()
        : getInvalidValidity(),
    productionRateAfterImplementation:
      !productionImprovementIsOngoing || (fields.productionRateAfterImplementation || 0) > 0
        ? getValidValidity()
        : getInvalidValidity(),
    commodityUnit:
      !productionImprovementIsOngoing ||
      (!!fields.commodityUnit && KpiCommodityUnitsForProduction.indexOf(fields.commodityUnit) >= 0)
        ? getValidValidity()
        : getInvalidValidity(),
    commodityType:
      !productionImprovementIsOngoing || !!fields.commodityType ? getValidValidity() : getInvalidValidity(),
    commodityProductionRateTimeUnit:
      !productionImprovementIsOngoing || !!fields.commodityProductionRateTimeUnit
        ? getValidValidity()
        : getInvalidValidity(),
    commodityPricePerUnit:
      !productionImprovementIsOngoing || (fields.commodityPricePerUnit || 0) > 0
        ? getValidValidity()
        : getInvalidValidity(),
  };
};

export const EditKpiOngoingMaintenanceSavingsOrProductionImprovementForm: React.FunctionComponent<Props> = (props) => {
  const model = props.model as KpiOngoingMaintenanceSavingsOrProductionImprovement;
  const serverModel = props.detail.model as KpiOngoingMaintenanceSavingsOrProductionImprovement;

  const [validity, setValidity] = useState<KpiOngoingMaintenanceSavingsOrProductionImprovementValidity>(
    getKpiOngoingMaintenanceSavingsOrProductionImprovementValidity(model)
  );
  const [selectedRemoteHoursOption, setSelectedRemoteHoursOption] = useState<KpiRemoteHoursOption | null>(
    model.remoteHours === null
      ? null
      : model.remoteHours === 0
      ? "None"
      : model.remoteHours === model.spartanHours
      ? "All"
      : "Some"
  );

  const validate = (fields: KpiOngoingMaintenanceSavingsOrProductionImprovement) => {
    const newValidity = getKpiOngoingMaintenanceSavingsOrProductionImprovementValidity(fields);
    setValidity(newValidity);
    return _.values(newValidity).filter((v) => !v.valid).length === 0;
  };

  useEffect(() => {
    if (!!props.onLoaded) {
      const isValid = _.values(validity).filter((v) => !v.valid).length === 0;
      props.onLoaded(isValid);
    }
  }, []);

  const saveChange = (updates: Partial<KpiOngoingMaintenanceSavingsOrProductionImprovement>) => {
    const updatedFields = { ...model, ...updates };
    const isValid = validate(updatedFields);
    props.onUpdated(updatedFields, isValid);
  };

  return (
    <div className="editKpiOngoingMaintenanceSavingsOrProductionImprovementForm">
      <Row className="gray-form-section" xs={1}>
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={6}>
              <p className="form-section-header">Cost to Implement</p>
            </Col>
            <Col className="totals" xs={12} sm={6}>
              <div>
                <p className="figure">
                  <NumberFormat
                    displayType="text"
                    value={serverModel.calculatedCostToImplement || 0}
                    prefix="$"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={4}>
              <FormGroup>
                <Label>Specialist labour</Label>
                <NumberInputGroup
                  postLabel={
                    <>
                      <span>
                        hrs &times; ${twoDecimalNumberFormatter.format(serverModel.spartanRatePerHour ?? 0)} / hr
                      </span>
                      <EffectiveRateTooltip
                        isKpiTemplate={!props.record}
                        rate={serverModel.spartanRatePerHour}
                        rateEffectiveDate={serverModel.rateEffectiveDate}
                        rateEffectiveDateType={serverModel.rateEffectiveDateType}
                        rateType="Specialist"
                      />
                    </>
                  }
                  placeholder="hours"
                  inputStyle={{ width: "60px" }}
                  value={model.spartanHours}
                  onChange={(newValue) =>
                    saveChange({
                      spartanHours: newValue,
                      remoteHours: selectedRemoteHoursOption === "All" ? newValue || 0 : model.remoteHours,
                    })
                  }
                  valid={!props.showValidationErrors || validity.spartanHours.valid}
                  validationError={validity.spartanHours.message}
                  readOnly={props.readOnly}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
              <RemoteHoursFormGroup
                fields={model}
                readOnly={props.readOnly}
                saveChange={saveChange}
                selectedRemoteHoursOption={selectedRemoteHoursOption}
                setSelectedRemoteHoursOption={setSelectedRemoteHoursOption}
                showValidationErrors={props.showValidationErrors}
                validity={validity.remoteHours}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label>Site personnel labour</Label>
                <NumberInputGroup
                  postLabel={
                    <>
                      <span>
                        hrs &times; ${twoDecimalNumberFormatter.format(serverModel.customerRatePerHour ?? 0)} / hr
                      </span>
                      <EffectiveRateTooltip
                        isKpiTemplate={!props.record}
                        rate={serverModel.customerRatePerHour}
                        rateEffectiveDate={serverModel.rateEffectiveDate}
                        rateEffectiveDateType={serverModel.rateEffectiveDateType}
                        rateType="Site personnel"
                      />
                    </>
                  }
                  placeholder="hours"
                  inputStyle={{ width: "60px" }}
                  value={model.customerHours}
                  onChange={(newValue) =>
                    saveChange({
                      customerHours: newValue,
                    })
                  }
                  valid={!props.showValidationErrors || validity.customerHours.valid}
                  validationError={validity.customerHours.message}
                  readOnly={props.readOnly}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label style={{ marginLeft: "15px" }}>Equipment cost</Label>
                <div style={{ display: "flex", gap: "6px" }}>
                  <NumberInputGroup
                    preLabel="$"
                    tooltip={<KpiHelpTooltip type="equipmentAndMaterialsCost" />}
                    placeholder="0"
                    value={model.equipmentAndMaterialsCost}
                    onChange={(newValue) =>
                      saveChange({
                        equipmentAndMaterialsCost: newValue,
                      })
                    }
                    valid={!props.showValidationErrors || validity.equipmentAndMaterialsCost.valid}
                    validationError={validity.equipmentAndMaterialsCost.message}
                    readOnly={props.readOnly}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="gray-form-section" xs={1}>
        <Col xs={12}>
          <Row>
            <Col>
              <p className="form-section-header">
                <span>Maintenance Savings</span>
              </p>
            </Col>
            <Col className="totals">
              <div>
                <p className="figure">
                  <NumberFormat
                    displayType="text"
                    value={serverModel.calculatedMaintenanceAnnualSavings || 0}
                    prefix="$"
                    suffix=" per year"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <FormGroup tag="fieldset">
                <Label>Ongoing savings?</Label>
                <div className="radio-group-wrapper">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="maintenanceSavingsIsOngoing"
                        checked={!!model.maintenanceSavingsIsOngoing}
                        onChange={(e) => saveChange({ maintenanceSavingsIsOngoing: true })}
                        disabled={props.readOnly}
                      />{" "}
                      Yes
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="maintenanceSavingsIsOngoing"
                        checked={!!!model.maintenanceSavingsIsOngoing}
                        onChange={(e) => saveChange({ maintenanceSavingsIsOngoing: false })}
                        disabled={props.readOnly}
                      />{" "}
                      No
                    </Label>
                  </FormGroup>
                  <KpiHelpTooltip type="ongoingMaintenanceCostSavings" />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Collapse isOpen={!!model.maintenanceSavingsIsOngoing}>
          <Col xs={12}>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Label style={{ marginLeft: "15px" }}>Costs before implementation</Label>
                  <div style={{ display: "flex", gap: "6px" }}>
                    <NumberInputGroup
                      preLabel="$"
                      value={model.maintenanceCostBeforeImplementation}
                      onChange={(newValue) =>
                        saveChange({
                          maintenanceCostBeforeImplementation: newValue,
                        })
                      }
                      valid={!props.showValidationErrors || validity.maintenanceCostBeforeImplementation.valid}
                      validationError={validity.maintenanceCostBeforeImplementation.message}
                      readOnly={props.readOnly}
                      inputStyle={{ width: "80px" }}
                    />
                    <div className="form-group-input-wrapper">
                      <SelectTimeUnit
                        mode="Per"
                        value={model.maintenanceCostTimeUnit}
                        readOnly={props.readOnly}
                        saveChange={(timeUnit) => saveChange({ maintenanceCostTimeUnit: timeUnit })}
                        showValidationErrors={props.showValidationErrors}
                        validity={validity.maintenanceCostTimeUnit}
                      />
                      <KpiHelpTooltip type="costsBeforeImplementation" />
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Label style={{ marginLeft: "15px" }}>Projected costs after implementation</Label>
                  <div style={{ display: "flex", gap: "6px" }}>
                    <NumberInputGroup
                      preLabel="$"
                      postLabel={`per ${model.maintenanceCostTimeUnit}`}
                      value={model.maintenanceCostAfterImplementation}
                      onChange={(newValue) =>
                        saveChange({
                          maintenanceCostAfterImplementation: newValue,
                        })
                      }
                      valid={!props.showValidationErrors || validity.maintenanceCostAfterImplementation.valid}
                      validationError={validity.maintenanceCostAfterImplementation.message}
                      readOnly={props.readOnly}
                      inputStyle={{ width: "80px" }}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Collapse>
      </Row>
      <Row className="gray-form-section" xs={1}>
        <Col xs={12}>
          <Row>
            <Col>
              <p className="form-section-header">
                <span>Production Improvement</span>
              </p>
            </Col>
            <Col className="totals">
              <div>
                <p className="figure">
                  <NumberFormat
                    displayType="text"
                    value={serverModel.calculatedProductionIncrease || 0}
                    prefix="$"
                    suffix=" per year"
                    thousandSeparator
                    decimalScale={0}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <FormGroup tag="fieldset">
                <Label>Ongoing improvement?</Label>
                <div className="radio-group-wrapper">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="productionImprovementIsOngoing"
                        checked={!!model.productionImprovementIsOngoing}
                        onChange={(e) => saveChange({ productionImprovementIsOngoing: true })}
                        disabled={props.readOnly}
                      />{" "}
                      Yes
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="productionImprovementIsOngoing"
                        checked={!!!model.productionImprovementIsOngoing}
                        onChange={(e) => saveChange({ productionImprovementIsOngoing: false })}
                        disabled={props.readOnly}
                      />{" "}
                      No
                    </Label>
                  </FormGroup>
                  <KpiHelpTooltip type="ongoingProductionImprovements" />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Collapse isOpen={!!model.productionImprovementIsOngoing}>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <div style={{ display: "flex", gap: "0 10px" }}>
                  <ProductionRateFormGroup
                    label="Production before implementation (quantity/units/product/rate)"
                    style={{ flexGrow: 1 }}
                    showValidationErrors={props.showValidationErrors}
                    productionRateValue={model.productionRateBeforeImplementation}
                    setProductionRate={(productionRateBeforeImplementation) =>
                      saveChange({ productionRateBeforeImplementation })
                    }
                    productionRateValidity={validity.productionRateBeforeImplementation}
                    productionRateReadOnly={props.readOnly}
                    productionRateTimeUnitValue={model.commodityProductionRateTimeUnit}
                    setProductionRateTimeUnit={(commodityProductionRateTimeUnit) =>
                      saveChange({ commodityProductionRateTimeUnit })
                    }
                    productionRateTimeUnitValidity={validity.commodityProductionRateTimeUnit}
                    productionRateTimeUnitReadOnly={props.readOnly}
                    typeValue={model.commodityType}
                    setType={(commodityType) => saveChange({ commodityType })}
                    typeValidity={validity.commodityType}
                    typeReadOnly={props.readOnly}
                    unitValue={model.commodityUnit}
                    units={KpiCommodityUnitsForProduction}
                    unitValidity={validity.commodityUnit}
                    unitReadOnly={props.readOnly}
                    setUnit={(commodityUnit) => saveChange({ commodityUnit })}
                  />
                  <CommodityPricePerUnitFormGroup
                    commodityPricePerUnitValidity={validity.commodityPricePerUnit}
                    commodityPricePerUnitValue={model.commodityPricePerUnit}
                    commodityTypeValue={model.commodityType}
                    readOnly={props.readOnly}
                    setCommodityPricePerUnit={(commodityPricePerUnit) => saveChange({ commodityPricePerUnit })}
                    showValidationErrors={props.showValidationErrors}
                    isOngoing={true}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <ProductionRateFormGroup
                  label="Projected production after implementation (quantity/units/product/rate)"
                  showValidationErrors={props.showValidationErrors}
                  productionRateValue={model.productionRateAfterImplementation}
                  setProductionRate={(productionRateAfterImplementation) =>
                    saveChange({ productionRateAfterImplementation })
                  }
                  productionRateValidity={validity.productionRateAfterImplementation}
                  productionRateReadOnly={props.readOnly}
                  productionRateTimeUnitValue={model.commodityProductionRateTimeUnit}
                  setProductionRateTimeUnit={() => {}}
                  productionRateTimeUnitValidity={getValidValidity()}
                  productionRateTimeUnitReadOnly={true}
                  typeValue={model.commodityType}
                  setType={() => {}}
                  typeValidity={getValidValidity()}
                  typeReadOnly={true}
                  unitValue={model.commodityUnit}
                  units={KpiCommodityUnitsForProduction}
                  unitValidity={getValidValidity()}
                  unitReadOnly={true}
                  setUnit={() => {}}
                />
              </Col>
            </Row>
          </Col>
        </Collapse>
      </Row>
    </div>
  );
};
