import { Box, Stack, Typography } from "@mui/material";

import { HealthCategory } from "records";

import { HealthTrafficLightBadge } from "assets/mui/health/HealthTrafficLightBadge";
import { HealthFormItem } from "app/mui/forms/Slider/HealthFormItem";

interface ValveHealthCardSectionProps {
  overall: number | null;
  overallCategory: HealthCategory | null;
  sectionTitle: string;
  conditionFieldName: string;
  performanceFieldName: string;
  syncConditionFieldName?: string;
  syncPerformanceFieldName?: string;
  pristineOnChangeFieldName?: string;
}

export const ValveHealthCardSection = (props: ValveHealthCardSectionProps) => {
  const {
    overall,
    overallCategory,
    sectionTitle,
    conditionFieldName,
    performanceFieldName,
    syncConditionFieldName,
    syncPerformanceFieldName,
    pristineOnChangeFieldName,
  } = props;

  return (
    <Box
      sx={{
        pt: 2,
        pb: 2,
      }}
    >
      <Typography sx={{ fontWeight: 700 }}>{sectionTitle}</Typography>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          minHeight: "0",
          minWidth: "0",
          width: "100%",
          height: "100%",
          pt: 1,
        }}
      >
        <HealthTrafficLightBadge overall={overall} overallCategory={overallCategory} />
        <Stack
          sx={{
            width: "100%",
            pt: 1,
            pr: 1,
          }}
          spacing={2}
          direction="column"
        >
          <HealthFormItem
            fieldName={conditionFieldName}
            syncFieldName={syncConditionFieldName}
            label="Condition"
            pristineOnChangeFieldName={pristineOnChangeFieldName}
          />
          <HealthFormItem
            fieldName={performanceFieldName}
            syncFieldName={syncPerformanceFieldName}
            pristineOnChangeFieldName={pristineOnChangeFieldName}
            label="Performance"
          />
        </Stack>
      </Stack>
    </Box>
  );
};
