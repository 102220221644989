import { useCallback, useEffect, useState } from "react";
import { Box, LinearProgress, Stack } from "@mui/material";
import { useSiteContext } from "app";
import { gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid";
import { gridFilteredSortedRowIdsSelector, useGridApiRef } from "@mui/x-data-grid-pro";
import { KpiValueFilters } from "records/models";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import DownloadIcon from "@mui/icons-material/Download";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import {
  createStaticActions,
  createStaticRowActions,
  useStaticActions,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import { ActionDispatcherApi, useActionDispatcher } from "app/mui/ActionDispatcher";
import { useDownloadCsvExportRequest } from "importCsv/api";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import { BusinessAreaContextProvider, useBusinessArea } from "app/mui/BusinessAreaContext";
import { useGetKpiValueDetailWithRecordsListInSiteRequest } from "kpis";
import { SummaryIcon } from "icons/SummaryIcon";
import { KpiValueDetail } from "kpis/models";
import { CreateKpiSummaryDialog } from "kpis/mui/dialogs/CreateKpiSummary";
import moment from "moment";
import { dateFormat } from "common";
import { KpiListDataViz } from "./visualizations/KpiListDataViz";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { Site } from "sites/models";

const pageActions = createStaticActions((actionDispatcher, userPermissions) => [
  {
    icon: <SummaryIcon />,
    label: "Create KPI summary",
    action: () => actionDispatcher.dispatch("CreateKpiSummary"),
    hidden: !userPermissions.userCanEditSummary,
  },
  {
    icon: <DownloadIcon />,
    label: "Download editable CSV",
    action: () => actionDispatcher.dispatch("DownloadCSV"),
    hidden: !userPermissions.userCanExportAssetList,
  },
  {
    icon: <DownloadIcon />,
    label: "Export current view",
    action: () => actionDispatcher.dispatch("ExportCurrentViewCSV"),
    hidden: !userPermissions.userCanExportAssetList,
  },
]);

export const KpisList = () => {
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions, actionDispatcher);

  const apiRef = useGridApiRef();

  const { businessArea } = useBusinessArea();

  const site = useSiteContext().currentSite!;
  const siteId = site.id;
  const kpisRequest = useGetKpiValueDetailWithRecordsListInSiteRequest(siteId);

  useEffect(() => {
    const filters = new KpiValueFilters();
    filters.businessArea = businessArea?.toString() ?? null;
    kpisRequest.call({
      filters,
    });
  }, [businessArea]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <KpiListDataViz apiRef={apiRef} loading={kpisRequest.loading} />
        <ActionsDropdownMenu primary actions={actions} />
      </Stack>

      <BusinessAreaContextProvider>
        <FilteredKpisTable
          apiRef={apiRef}
          actionDispatcher={actionDispatcher}
          loading={kpisRequest.loading}
          rows={kpisRequest.data ?? []}
          site={site}
        />
      </BusinessAreaContextProvider>
    </Stack>
  );
};

const rowActions = createStaticRowActions((actionDispatcher, permissions) => (params) => [
  // no actions yet.
]);

interface FilteredKpisTableProps {
  apiRef: React.MutableRefObject<GridApiPro>;
  actionDispatcher: ActionDispatcherApi;
  loading: boolean;
  rows: KpiValueDetail[];
  site: Site;
}

const GridComponents = {
  LoadingOverlay: LinearProgress,
  Toolbar: ToolbarWithTabViews("KPIs", { includeUserDefinedTabs: true, includeBusinessAreaFilter: true }),
};

const FilteredKpisTable = (props: FilteredKpisTableProps) => {
  const downloadCsvExportRequest = useDownloadCsvExportRequest(props.site, "KPIs");

  const [openDialog, setOpenDialog] = useState<"CreateKpiSummary" | null>(null);
  const [kpisForSummary, setKpisForSummary] = useState<KpiValueDetail[]>([]);

  const getTableRowActions = useStaticRowActions(rowActions, props.actionDispatcher);

  const handleAction = useCallback(
    (action: string, params: any) => {
      if (action === "DownloadCSV") {
        const ids = gridFilteredSortedRowIdsSelector(props.apiRef);
        downloadCsvExportRequest.call(ids as string[]);
      }
      if (action === "ExportCurrentViewCSV") {
        props.apiRef.current.exportDataAsCsv({
          fileName: `${props.site.name} KPIs CurrentView ${moment().format(dateFormat)}`,
          utf8WithBom: true,
        });
      }
      if (action === "CreateKpiSummary") {
        setOpenDialog("CreateKpiSummary");
        const rows = gridFilteredSortedRowEntriesSelector(props.apiRef);
        setKpisForSummary(rows.map((r) => r.model.kpi) as KpiValueDetail[]);
      }
    },
    [downloadCsvExportRequest]
  );

  useEffect(() => {
    props.actionDispatcher.subscribe(handleAction);
    return () => props.actionDispatcher.unsubscribe(handleAction);
  }, []);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  return (
    <>
      <Box sx={{ height: "700px", width: "100%" }}>
        <MetadataColumnProvider type="KpiValueWithRecordDetailViewModel" getActions={getTableRowActions}>
          {(columns) => (
            <StripedDataGrid
              apiRef={props.apiRef}
              density="compact"
              loading={props.loading}
              components={GridComponents}
              columns={columns}
              rows={props.rows}
              getRowId={(row) => row.kpi.id}
            />
          )}
        </MetadataColumnProvider>
      </Box>

      {openDialog === "CreateKpiSummary" && <CreateKpiSummaryDialog onClose={closeDialog} kpis={kpisForSummary} />}
    </>
  );
};
