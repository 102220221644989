import React from "react";
import { Validity } from "common";
import { NumberInputGroup } from "records/bs/NumberInputGroup";
import { KpiHelpTooltip } from "./KpiHelpTooltip";

interface Props {
  commodityPricePerUnitValue: number | null;
  setCommodityPricePerUnit: (newValue: number | null) => any;
  commodityPricePerUnitValidity: Validity;
  commodityTypeValue: string | null;
  showValidationErrors: boolean;
  readOnly: boolean;
  isOngoing: boolean;
}

export const CommodityPricePerUnitFormGroup: React.FunctionComponent<Props> = (props) => {
  return (
    <NumberInputGroup
      label="Price per 1 unit"
      tooltip={
        <KpiHelpTooltip type={props.isOngoing ? "commodityPricePerUnitOngoing" : "commodityPricePerUnitOneTime"} />
      }
      inputStyle={{ width: "80px" }}
      value={props.commodityPricePerUnitValue}
      onChange={props.setCommodityPricePerUnit}
      valid={!props.showValidationErrors || props.commodityPricePerUnitValidity.valid}
      validationError={props.commodityPricePerUnitValidity.message}
      readOnly={props.readOnly}
    />
  );
};
