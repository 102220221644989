import * as React from "react";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { Controller } from "react-hook-form";
import { Select, MenuItem } from "@mui/material";
import { RecordStatusBadge } from "../../RecordStatusBadge";
import { Status, StatusIdLabelOptions } from "records/models";

export interface RecordStatusFormItemProps {
  label?: string;
  hint?: string;
  fieldName: string;
  statusToExlude?: Status[];
}

export const RecordStatusFormItem = (props: RecordStatusFormItemProps) => {
  return (
    <Controller
      name={props.fieldName}
      defaultValue={null}
      render={({ field: { onChange, value, ...fieldProps }, fieldState }) => (
        <FormItemLayout controlled hint={props.hint} label={props.label}>
          {(inputProps) => (
            <Select
              {...inputProps}
              value={value}
              onChange={(e) => onChange(e.target?.value)}
              renderValue={(selectedStatus: Status) => <RecordStatusBadge status={selectedStatus} variant="simple" />}
            >
              {StatusIdLabelOptions.filter((st) => !(props.statusToExlude || []).includes(st.id)).map((status) => (
                <MenuItem key={status.id} value={status.id}>
                  <RecordStatusBadge status={status.id} variant="simple" />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormItemLayout>
      )}
    />
  );
};
