import * as React from "react";
import { SummaryDetail } from "summaries";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Grid, Box, Typography } from "@mui/material";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import _ from "lodash";
import {
  OutageRecordMilestoneFieldLabelOptions,
  useGetRecordDetailsByRecordsEventQuery,
  useGetRecordOutageDetailsLookupValuesByRecordsEventIdQuery,
} from "records";
import {
  RecordOutageDetailsLookupValuesViewModel,
  RecordOutageEvaluationScopeOutcome,
  RecordOutageEvaluationScopeOutcomeOptions,
  RecordOutageScopePhase,
  RecordOutageScopePhaseOptions,
} from "records/bs/outage/models";
import { useGetAssetsByIdsRequest } from "assets";
import { useSiteContext } from "app";
import {
  OutageSummaryReportParams,
  OutageSummaryReportParamsWithFilters,
  useOutageSummaryReportUrlBuilder,
} from "common/reports";

interface Props extends BaseDialogProps {
  summary: SummaryDetail;
}

interface RecordsFilters {
  areaIds: number[];
  vendor: string | null;
  phase: RecordOutageScopePhase | null;
  milestone: string | null;
  scopeOfWork: string | null;
  scopeOutcome: RecordOutageEvaluationScopeOutcome | null;
}

interface FormValues extends RecordsFilters {
  includeOverallPhaseProgress: boolean;
  includePhaseProgressByArea: boolean;
  includeAssetsByVendor: boolean;
  includeScopeOptimizationSummary: boolean;
  includeScopeOptimizationByType: boolean;
  includeExecutionMilestoneSummary: boolean;
  includeLongestMilestoneDelays: boolean;
  includeEvaluationSummary: boolean;
  includeRecordDetails: boolean;
  includeAssetPhotos: boolean;
  selectRecordsToIncludeInReport: boolean;
}

export const ConfigureOutageScopeSummaryReportModal: React.FunctionComponent<Props> = (props) => {
  const { data: lookupValues } = useGetRecordOutageDetailsLookupValuesByRecordsEventIdQuery(
    props.summary.model.recordsEventId || 0
  );

  const methods = useForm<FormValues>({
    defaultValues: {
      includeOverallPhaseProgress: true,
      includePhaseProgressByArea: true,
      includeAssetsByVendor: true,
      includeScopeOptimizationSummary: true,
      includeScopeOptimizationByType: true,
      includeExecutionMilestoneSummary: false,
      includeLongestMilestoneDelays: false,
      includeEvaluationSummary: false,
      includeRecordDetails: false,
      includeAssetPhotos: false,
      selectRecordsToIncludeInReport: false,
      areaIds: [],
      vendor: null,
      phase: null,
      milestone: null,
      scopeOfWork: null,
      scopeOutcome: null,
    },
  });

  const values = useWatch({ control: methods.control });

  const reportParams: OutageSummaryReportParams = {
    pdf: false,
    appendPdfAttachments: false,
    id: props.summary.id,
    includeOverallPhaseProgress: values.includeOverallPhaseProgress ?? false,
    includePhaseProgressByArea: values.includePhaseProgressByArea ?? false,
    includeAssetsByVendor: values.includeAssetsByVendor ?? false,
    includeScopeOptimizationSummary: values.includeScopeOptimizationSummary ?? false,
    includeScopeOptimizationByType: values.includeScopeOptimizationByType ?? false,
    includeExecutionMilestoneSummary: values.includeExecutionMilestoneSummary ?? false,
    includeLongestMilestoneDelays: values.includeLongestMilestoneDelays ?? false,
    includeEvaluationSummary: values.includeEvaluationSummary ?? false,
    includeRecordDetails: values.includeRecordDetails ?? false,
    includeAssetPictures: values.includeAssetPhotos ?? false,
  };

  const reportParamsWithFilters: OutageSummaryReportParamsWithFilters = {
    ...reportParams,
    areaIds: values.areaIds?.length ? values.areaIds.join(",") : null,
    milestone: values.milestone || null,
    phase: values.phase || null,
    scopeOfWork: values.scopeOfWork || null,
    scopeOutcome: values.scopeOutcome || null,
    vendor: values.vendor || null,
  };

  const reportUrl = useOutageSummaryReportUrlBuilder(
    values.selectRecordsToIncludeInReport ? reportParamsWithFilters : reportParams
  );

  const handleSubmit = methods.handleSubmit(() => {
    window.open(reportUrl!, "_blank");
    props.onClose();
  });

  return (
    <BaseDialog onClose={props.onClose} maxWidth="md">
      <DialogTitle variant="h6" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        Report options
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <Typography variant="subtitle1">Progress</Typography>
            <CheckboxFormItem fieldName="includeOverallPhaseProgress" label="Include overall phase progress" />
            <CheckboxFormItem fieldName="includePhaseProgressByArea" label="Include phase progress by area" />
            <Typography variant="subtitle1">Planning</Typography>
            <CheckboxFormItem fieldName="includeAssetsByVendor" label="Include assets by vendor" />
            <CheckboxFormItem fieldName="includeScopeOptimizationSummary" label="Include scope optimization summary" />
            <CheckboxFormItem fieldName="includeScopeOptimizationByType" label="Include scope optimization by type" />
            <Typography variant="subtitle1">Execution</Typography>
            <CheckboxFormItem
              fieldName="includeExecutionMilestoneSummary"
              label="Include execution milestone summary"
            />
            <CheckboxFormItem fieldName="includeLongestMilestoneDelays" label="Include longest milestone delays" />
            <Typography variant="subtitle1">Evaluation</Typography>
            <CheckboxFormItem fieldName="includeEvaluationSummary" label="Include evaluation summary" />
            <Typography variant="subtitle1">Records details</Typography>
            <CheckboxFormItem fieldName="includeRecordDetails" label="Include record details" />
            <CheckboxFormItem
              fieldName="includeAssetPhotos"
              label="Include asset assembly photos"
              disabled={!values.includeRecordDetails}
            />
            <CheckboxFormItem fieldName="selectRecordsToIncludeInReport" label="Select records to include in report" />
            {values.selectRecordsToIncludeInReport && (
              <ConfigureOutageScopeSummaryReportFilters
                filters={{
                  areaIds: values.areaIds ?? [],
                  vendor: values.vendor ?? null,
                  phase: values.phase ?? null,
                  milestone: values.milestone ?? null,
                  scopeOfWork: values.scopeOfWork ?? null,
                  scopeOutcome: values.scopeOutcome ?? null,
                }}
                lookupValues={lookupValues}
                summary={props.summary}
                updateFilters={(changes) => {
                  for (const key in changes) {
                    methods.setValue(key as keyof FormValues, changes[key]);
                  }
                }}
              />
            )}
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton loading={false} variant="contained" color="secondary" onClick={handleSubmit}>
            Generate
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};

interface FiltersProps {
  summary: SummaryDetail;
  filters: RecordsFilters;
  lookupValues: RecordOutageDetailsLookupValuesViewModel | null;
  updateFilters: (filters: Partial<RecordsFilters>) => void;
}

const ConfigureOutageScopeSummaryReportFilters: React.FunctionComponent<FiltersProps> = (props) => {
  const currentSite = useSiteContext().currentSite!;
  const recordsQuery = useGetRecordDetailsByRecordsEventQuery(props.summary.model.recordsEventId);
  const records = recordsQuery.data ?? [];

  const assets = records.flatMap((r) => r.model.assets);
  const allAssetIds = _.uniq(assets.map((a) => a.id));
  const allAssetDetailsRequest = useGetAssetsByIdsRequest(allAssetIds);
  const allAssetDetails = allAssetDetailsRequest.data ?? [];
  const allAssetAreas = currentSite.areas.filter(
    (area) => !!allAssetDetails.find((asset) => asset.model.areaId === area.id)
  );

  const areaOptions = allAssetAreas.map((area) => ({ label: area.name, value: area.id }));

  const primaryScopesOfWork =
    props.lookupValues?.scopesOfWork.map((s) => ({
      value: s.value,
      label: s.value,
      isDisabled: s.usageCount === 0,
    })) || [];
  const vendorOptions =
    props.lookupValues?.vendors.map((v) => ({ value: v.value, label: v.value, isDisabled: v.usageCount === 0 })) || [];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AutocompleteFormItem
            label="Area"
            fieldName="areaIds"
            options={areaOptions}
            multiple={true}
            getOptionLabel={(a) => a.label}
            placeholder="Any"
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteFormItem
            label="Vendor"
            fieldName="vendor"
            options={vendorOptions}
            getOptionLabel={(v) => v.label}
            placeholder="Any"
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteFormItem
            label="Phase"
            fieldName="phase"
            options={RecordOutageScopePhaseOptions}
            getOptionLabel={(p) => p.label}
            placeholder="Any"
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteFormItem
            label="Milestone"
            fieldName="milestone"
            options={OutageRecordMilestoneFieldLabelOptions}
            getOptionLabel={(m) => m.label}
            placeholder="Any"
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteFormItem
            label="Scope of work"
            fieldName="scopeOfWork"
            options={primaryScopesOfWork}
            getOptionLabel={(s) => s.label}
            placeholder="Any"
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteFormItem
            label="Evaluation scope outcome"
            fieldName="scopeOutcome"
            options={RecordOutageEvaluationScopeOutcomeOptions}
            getOptionLabel={(o) => o.label}
            placeholder="Any"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
