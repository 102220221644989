import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import {
   RecordOutageDetail,
   RecordOutageDetailView,
   RecordOutageEvaluationConsideration,
   RecordOutageEvaluationConsiderationCategories,
   RecordOutageScopeOutcomes,
} from "../models";
import { RecordDetailSectionProps } from "../RecordOutageDetailCard";
import classNames from "classnames";
import { UpIcon } from "icons/UpIcon";
import { DownIcon } from "icons/DownIcon";
import { XIcon } from "icons/XIcon";
import {
   useCreateOutageEvaluationConsiderationRequest,
   useDeleteOutageEvaluationConsiderationRequest,
   useUpdateOutageEvaluationConsiderationRequest,
   useUpdateRecordOutageDetailRequest,
} from "records";
import { FormProvider, useForm } from "react-hook-form";
import { SelectInput } from "../inputs/SelectInput";
import { TextAreaInput } from "../inputs/TextAreaInput";
import { useAutoSave } from "app";
import AutoSave from "app/mui/forms/AutoSave";

interface EvaluationProps extends RecordDetailSectionProps {
   outageDetailView: RecordOutageDetailView;
}

export const EvaluationTab = (props: EvaluationProps) => {
   const updateOutageRequest = useUpdateRecordOutageDetailRequest();
   const createEvaluationRequest = useCreateOutageEvaluationConsiderationRequest(props.outageDetailView.recordId);
   const updateEvaluationRequest = useUpdateOutageEvaluationConsiderationRequest(props.outageDetailView.recordId);
   const deleteEvaluationRequest = useDeleteOutageEvaluationConsiderationRequest(props.outageDetailView.recordId);

   const methods = useForm<RecordOutageDetail>({
      defaultValues: props.outageDetailView.model,
      mode: "onChange",
   });

   const outage = props.outageDetailView.model;

   const isLoading =
      updateOutageRequest.loading ||
      updateEvaluationRequest.loading ||
      createEvaluationRequest.loading ||
      deleteEvaluationRequest.loading;

   const addNewRow = () => {
      const newItem: Partial<RecordOutageEvaluationConsideration> = {
         recordOutageDetailId: outage.id,
         category: null,
         details: null,
      };

      createEvaluationRequest.call(newItem);
   };

   const removeRow = (row: RecordOutageEvaluationConsideration) => {
      deleteEvaluationRequest.call(row.id);
   };

   const moveRowUp = (row: RecordOutageEvaluationConsideration) => {
      const orderedItems = _.orderBy(outage.evaluationConsiderations, (ec) => ec.order);

      const indexOfCurrentItem = orderedItems.findIndex((i) => i.id === row.id);

      if (indexOfCurrentItem > 0) {
         const currentItem = orderedItems[indexOfCurrentItem];
         const previousItem = orderedItems[indexOfCurrentItem - 1];

         const newEvaluationConsiderations = [...orderedItems];

         newEvaluationConsiderations[indexOfCurrentItem] = previousItem;
         newEvaluationConsiderations[indexOfCurrentItem - 1] = currentItem;

         const newOrderedItems = recalculateOrderFromIndex(newEvaluationConsiderations);

         const newOutageDetails = {
            ...outage,
            evaluationConsiderations: newOrderedItems,
         };

         updateOutageRequest.call(newOutageDetails);
      }
   };

   const moveRowDown = (row: RecordOutageEvaluationConsideration) => {
      const orderedItems = _.orderBy(outage.evaluationConsiderations, (ec) => ec.order);

      const indexOfCurrentItem = orderedItems.findIndex((i) => i.id === row.id);

      if (indexOfCurrentItem < outage.evaluationConsiderations.length - 1) {
         const currentItem = orderedItems[indexOfCurrentItem];
         const nextItem = orderedItems[indexOfCurrentItem + 1];

         const newEvaluationConsiderations = [...orderedItems];

         newEvaluationConsiderations[indexOfCurrentItem] = nextItem;
         newEvaluationConsiderations[indexOfCurrentItem + 1] = currentItem;

         const newOrderedItems = recalculateOrderFromIndex(newEvaluationConsiderations);

         const newOutageDetails = {
            ...outage,
            evaluationConsiderations: newOrderedItems,
         };

         updateOutageRequest.call(newOutageDetails);
      }
   };

   const recalculateOrderFromIndex = (evaluationConsiderations: RecordOutageEvaluationConsideration[]) => {
      return evaluationConsiderations.map((ec, index) => {
         return {
            ...ec,
            order: index + 1,
         };
      });
   };

   const handleSave = useCallback(
      methods.handleSubmit(({ scopeOutcome }) => {
         updateOutageRequest.call({ ...outage, scopeOutcome });
      }),
      [outage]
   );

   if (props.outageDetailView.currentPhase === "Planning") {
      return <NotYetEvaluationPhase />;
   }

   const getDisplayTextForValue = (value: string) => {
      return value === "NotPerformed" ? "Evaluation not performed" : value;
   };

   return (
      <div className="evaluation">
         <FormProvider {...methods}>
            <AutoSave onSubmit={handleSave} />
            <Row>
               <Col lg="3">
                  <SelectInput
                     label="Scope outcome"
                     field="scopeOutcome"
                     options={RecordOutageScopeOutcomes}
                     getDisplayTextForValue={getDisplayTextForValue}
                     userCanEdit={props.userCanEdit}
                     isClearable = {true}
                  />
               </Col>
            </Row>
            <Row>
               <Col>
                  <hr />
                  {_.orderBy(outage.evaluationConsiderations, (item) => item.order).map((item, index) => {
                     return (
                        <RecordOutageDetailEvaluationConsiderationRow
                           key={item.id}
                           index={index}
                           evaluation={item}
                           recordId={props.outageDetailView.recordId}
                           userCanEdit={props.userCanEdit}
                           moveUp={() => moveRowUp(item)}
                           moveDown={() => moveRowDown(item)}
                           remove={() => removeRow(item)}
                           isLoading={isLoading}
                        />
                     );
                  })}
                  <div style={{ textAlign: "right", marginTop: "1em" }}>
                     <Button color="secondary" size="sm" onClick={() => addNewRow()} disabled={isLoading}>
                        <span>+ Add consideration</span>
                     </Button>
                  </div>
               </Col>
            </Row>
         </FormProvider>
      </div>
   );
};

interface RecordOutageDetailEvaluationConsiderationRowProps {
   index: number;
   evaluation: RecordOutageEvaluationConsideration;
   recordId: number;
   userCanEdit: boolean;
   moveUp: () => void;
   moveDown: () => void;
   remove: () => void;
   isLoading: boolean;
}
export const RecordOutageDetailEvaluationConsiderationRow = (
   props: RecordOutageDetailEvaluationConsiderationRowProps
) => {
   const updateEvaluationRequest = useUpdateOutageEvaluationConsiderationRequest(props.recordId);

   const methods = useForm<RecordOutageEvaluationConsideration>({
      defaultValues: props.evaluation,
      mode: "onChange",
   });

   const handleSave = useCallback(
      methods.handleSubmit((values) => {
         updateEvaluationRequest.call({ ...props.evaluation, ...values });
      }),
      [props.evaluation]
   );

   return (
      <FormProvider {...methods}>
         <AutoSave onSubmit={handleSave} />
         <Row>
            <Col lg="5" className="evaluation-row-left">
               <div className="evaluation-consideration-index">{props.index + 1}</div>
               <SelectInput
                  label="Consideration for next outage"
                  field={`category`}
                  userCanEdit={props.userCanEdit}
                  options={RecordOutageEvaluationConsiderationCategories}
                  placeholder={!props.userCanEdit ? "" : "Select category"}
               />
            </Col>
            <Col lg="7" className="evaluation-row-right">
               <TextAreaInput label="Details" field={`details`} userCanEdit={props.userCanEdit} />
               <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                     className={classNames("reorder-button")}
                     disabled={!!!props.moveUp || !props.userCanEdit || props.isLoading}
                     onClick={() => {
                        props.moveUp!();
                     }}
                  >
                     <UpIcon />
                  </Button>
                  <Button
                     className={classNames("reorder-button")}
                     disabled={!!!props.moveDown || !props.userCanEdit || props.isLoading}
                     onClick={() => {
                        props.moveDown!();
                     }}
                  >
                     <DownIcon />
                  </Button>
                  <Button
                     className={classNames("reorder-button")}
                     disabled={!props.userCanEdit || props.isLoading}
                     onClick={() => {
                        props.remove();
                     }}
                  >
                     <XIcon />
                  </Button>
               </div>
            </Col>
         </Row>
      </FormProvider>
   );
};

const NotYetEvaluationPhase = () => {
   return <div className="incorrect-outage-phase">A planned scope must be accepted before you can continue.</div>;
};
