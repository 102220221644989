import { TextField, TextFieldProps } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormItemLayout, FormItemLayoutProps } from "app/mui/forms/FormItemLayout";
import { dateFormat } from "common/formats";
import moment from "moment";
import { Controller } from "react-hook-form";
import { ReadonlyFormItem } from "../ReadonlyFormItem";
import { withEditableToggle } from "../withEditableToggle";

export interface DateInputFormItemProps extends Omit<FormItemLayoutProps, "children" | "controlled"> {
  textFieldProps?: TextFieldProps;
  disableFuture?: boolean;
  disablePast?: boolean;
  readOnlyValue?: Date | null;
  readOnly?: boolean;
  alwaysShowInput?: boolean;
}

export const DateInputFormItem = (props: DateInputFormItemProps) => {
  const {
    textFieldProps,
    disableFuture,
    disablePast,
    readOnlyValue,
    readOnly,
    alwaysShowInput,
    ...formItemLayoutProps
  } = props;

  return props.readOnly && !alwaysShowInput ? (
    <ReadonlyFormItem
      label={props.label || ""}
      value={props.readOnlyValue ? moment(props.readOnlyValue).format(dateFormat) : undefined}
    />
  ) : (
    <Controller
      name={props.fieldName ?? ""}
      render={({ field: { onChange, value }, fieldState }) => (
        <FormItemLayout
          controlled
          label={props.label}
          hint={props.hint}
          error={fieldState.error?.message}
          {...formItemLayoutProps}
        >
          {({ ...inputProps }) => {
            return (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture={props.disableFuture}
                  disablePast={props.disablePast}
                  value={value ? moment(value) : null}
                  onChange={(parsedValue, _keyboardValue) => onChange(parsedValue?.toDate())}
                  renderInput={(params) => (
                    <TextField {...props.textFieldProps} {...inputProps} {...params} error={!!fieldState.error} />
                  )}
                  disabled={inputProps.disabled || readOnly}
                />
              </LocalizationProvider>
            );
          }}
        </FormItemLayout>
      )}
    />
  );
};

export const EditableDateInputFormItem = withEditableToggle(DateInputFormItem);
