import { BusinessArea } from "assets/models/businessArea";
import { useState, useContext, createContext, ReactNode } from "react";

interface ContextType {
  businessArea?: BusinessArea;
  setBusinessArea: React.Dispatch<React.SetStateAction<BusinessArea | undefined>>;
}

const BusinessAreaContext = createContext<ContextType>({} as ContextType);

export const BusinessAreaContextProvider = (props: { children: ReactNode }) => {
  const [businessArea, setBusinessArea] = useState<BusinessArea>();

  return (
    <BusinessAreaContext.Provider
      value={{
        businessArea,
        setBusinessArea,
      }}
    >
      {props.children}
    </BusinessAreaContext.Provider>
  );
};

export const useBusinessArea = () => {
  const context = useContext(BusinessAreaContext);
  if (!context) {
    throw "Attempted to use the hook 'useBusinessArea' outside of a BusinessAreaContextProvider";
  }

  return context;
};
