// https://stackoverflow.com/questions/3518504/regular-expression-for-matching-latitude-longitude-coordinates
export const LatLonRegex =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const isValidLatLng = (value: string) => {
  return value.match(LatLonRegex) != null;
};

export const generateLinkToLocation = (value: string) => {
  const [lat, lng] = value.replace(" ", "").split(",");
  return `https://www.google.ca/maps/?q=${lat},${lng}`;
};

export function parseLatLng(val: string): [number, number] {
  if (!isValidLatLng(val)) {
    console.warn("Invalid lat/lng string", val);
    return [0, 0];
  }
  const [lat, lng] = val.split(",").map((x) => parseFloat(x));
  return [lat, lng];
}
