import { TimeUnit } from "common";
import { KpiCommodityUnit, KpiCommodityUnitsForProduction, KpiEmissionsType, KpiPotentialDelayType } from "kpis/models";
import * as yup from "yup";

export const SimplifiedKpiOneTimeDowntimeAvoidanceValidationSchema = yup.object({
  potentialDelayType: yup.mixed<KpiPotentialDelayType>().nullable(),
  location: yup.string().nullable(),
  probabilityOfDowntime: yup.string().nullable().required("Required"),
  simplifiedPotentialCost: yup.number().nullable().required("Required").moreThan(0),
  simplifiedActualCost: yup.number().nullable().required("Required").moreThan(0),
});

export const KpiOneTimeDowntimeAvoidanceValidationSchema = yup.object({
  potentialDelayType: yup.mixed<KpiPotentialDelayType>().nullable(),
  location: yup.string().nullable(),
  actionsTaken: yup.string().nullable(),
  commodityProductionRate: yup.number().nullable().moreThan(0).required("Required"),
  commodityUnit: yup
    .mixed<KpiCommodityUnit>()
    .test("is-valid", "Must be a production unit", function (value) {
      return !!value && KpiCommodityUnitsForProduction.includes(value);
    })
    .required("Required"),
  commodityType: yup.mixed<KpiEmissionsType>().nullable().required("Required"),
  commodityProductionRateTimeUnit: yup.mixed<TimeUnit>().nullable().required("Required"),
  commodityPricePerUnit: yup.number().nullable().required("Required").moreThan(0),
  potentialDowntime: yup
    .number()
    .nullable()
    .test("is-valid", "Must be greater than zero", function (value) {
      return !!value && value > 0;
    })
    .required("Required"),
  potentialDowntimeTimeUnit: yup.string().nullable().required("Required"),
  probabilityOfDowntime: yup.string().nullable().required("Required"),
});
