import { TrafficLightValue } from "records/models";

export interface MotorOperatedCondition {
  currentDraw: TrafficLightValue | null;
  voltage: TrafficLightValue | null;
  ratedTravel: TrafficLightValue | null;
  torqueThrust: TrafficLightValue | null;
  stemNutWear: TrafficLightValue | null;
}

export interface MotorOperatedConditionValidity {
  valid: boolean;
  currentDraw: boolean;
  voltage: boolean;
  ratedTravel: boolean;
  torqueThrust: boolean;
  stemNutWear: boolean;
}

export enum MotorOperatedConditionFieldLabel {
  CurrentDraw = "Current draw",
  Voltage = "Voltage",
  RatedTravel = "Rated travel",
  TorqueThrust = "Torque/thrust",
  StemNutWear = "Stem nut wear",
}

export enum MotorOperatedConditionTooltip {
  CurrentDraw = "Assess the amount of electrical current being consumed by the actuator's motor during operation. Things such as mechanical stress, binding, short circuits etc can cause an elevated draw.",
  Voltage = "Assess the motor voltage levels to ensure that it's operating within expected range. The voltage levels can provide insight into power supply stability, wiring/circuitry integrity, and motor loading.",
  RatedTravel = "Assess the ability to meet expected travel range between fully closed and open position.",
  TorqueThrust = "Assess the force applied by the actuator to the valve, by comparing against expected values. Increased torque/thrust may suggest increased friction, degradation of components, or misalignment.",
  StemNutWear = `Assess the stem nut condition as it's crucial for converting the rotary or linear motion generated by the actuator into the corresponding movement of the valve.`,
}
