import * as React from "react";

interface Props {
   content: string;
}

export class TextWithNewLines extends React.PureComponent<Props> {
   public render() {
      const commentLines = this.props.content.split("\n");
      return (
         <>
            {commentLines.length > 1
               ? commentLines.map((line) => (
                    <div key={Math.random()}>
                       {line}
                       <br />
                    </div>
                 ))
               : this.props.content}
         </>
      );
   }
}
