import {
   Button,
   CircularProgress,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   Stack,
} from "@mui/material";
import { useUserContext } from "app";
import { FunctionComponent, useEffect, useState } from "react";
import { ImportEmptyValuesHandling, ImportType } from "./models";
import { FileInput } from "common";
import { Site } from "sites";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UndoIcon from "@mui/icons-material/Undo";
import { DownloadCsvTemplate } from "./DownloadCsvTemplate";

interface UploadFileSectionProps {
   site: Site;
   importType: ImportType | null;
   setImportType: (importType: ImportType) => any;
   emptyValuesHandling: ImportEmptyValuesHandling;
   setEmptyValuesHandling: (emptyValuesHandling: ImportEmptyValuesHandling) => any;
   onFileSelected: (file: File) => any;
   isUploading: boolean;
   isDisabled: boolean;
   onSettingsVersionIncremented?: (newVersion: number) => any;
}

export const UploadFileSection: FunctionComponent<UploadFileSectionProps> = (props) => {
   const { userCanEditAssets, userCanEditKpi, userCanEditRecords } = useUserContext().userPermissions;
   const [settingsVersion, setSettingsVersion] = useState(0);
   const [fileSelectedForVersion, setFileSelectedForVersion] = useState(-1);
   const isFileSelected = fileSelectedForVersion === settingsVersion;

   useEffect(() => {
      setSettingsVersion(settingsVersion + 1);
   }, [props.importType, props.emptyValuesHandling]);

   useEffect(() => {
      !!props.onSettingsVersionIncremented && props.onSettingsVersionIncremented(settingsVersion);
   }, [settingsVersion]);

   const fileUploadDisabled = !props.importType || props.isUploading || props.isDisabled;

   return (
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ maxWidth: "1024px" }}>
         <Stack direction="column" spacing={1} alignItems="top">
            <FormControl fullWidth sx={{ minWidth: "100px" }}>
               <InputLabel id="import-mode-select-label">Type</InputLabel>
               <Select
                  labelId="import-mode-select-label"
                  id="import-mode-select"
                  value={props.importType ?? ""}
                  label="Age"
                  onChange={(changed) => [props.setImportType(changed.target.value as ImportType)]}
                  disabled={props.isDisabled}
                  required
                  error={false}
                  size="small"
               >
                  {userCanEditAssets && <MenuItem value={"Assets"}>Assets</MenuItem>}
                  {userCanEditKpi && <MenuItem value={"KPIs"}>KPIs</MenuItem>}
                  {userCanEditRecords && <MenuItem value={"Records"}>Records</MenuItem>}
               </Select>
            </FormControl>
            {!!props.importType && <DownloadCsvTemplate importType={props.importType} site={props.site} />}
         </Stack>
         {!!props.importType && (
            <FormControl sx={{ width: "475px" }}>
               <FormLabel id="emptyValuesHandling-group-label">How to process empty cells?</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="emptyValuesHandling-group-label"
                  name="emptyValuesHandling-group"
                  value={props.emptyValuesHandling}
                  onChange={(changed) =>
                     props.setEmptyValuesHandling(changed.target.value as ImportEmptyValuesHandling)
                  }
               >
                  <FormControlLabel
                     value="IgnoreField"
                     control={<Radio />}
                     label="Leave field as-is (ignore)"
                     disabled={props.isDisabled}
                  />
                  <FormControlLabel
                     value="ClearValue"
                     control={<Radio />}
                     label="Clear field value (update)"
                     disabled={props.isDisabled}
                  />
               </RadioGroup>
            </FormControl>
         )}
         <Stack sx={{ minWidth: "150px" }} justifyContent="space-around">
            {!!props.importType && (
               <FileInput
                  onFilesSelected={(files) => {
                     setFileSelectedForVersion(settingsVersion);
                     props.onFileSelected(files[0]);
                  }}
                  accept="*.csv"
                  disabled={fileUploadDisabled}
                  incrementToClearFile={settingsVersion}
               >
                  {!isFileSelected && (
                     <Button
                        variant="outlined"
                        startIcon={<UploadFileIcon />}
                        component="span"
                        disabled={fileUploadDisabled}
                     >
                        Choose CSV
                     </Button>
                  )}
                  {props.isUploading && <CircularProgress sx={{ cursor: "auto" }} />}
                  {isFileSelected && !props.isUploading && (
                     <Button
                        variant="outlined"
                        startIcon={<UndoIcon />}
                        onClick={() => {
                           setSettingsVersion(settingsVersion + 1);
                        }}
                     >
                        Start over
                     </Button>
                  )}
               </FileInput>
            )}
         </Stack>
      </Stack>
   );
};
