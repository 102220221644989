import React from "react";
import { RecordsEventScreen } from "./RecordsEventScreen";
import { Routes, useValidatedSiteIdFromRoute } from "app";
import { LinearProgress } from "@mui/material";
import { useGetRecordsEventDetailQuery } from "recordsEvents/api";

export const RecordsEventScreenQuery: React.FunctionComponent = () => {
  const id = useValidatedSiteIdFromRoute(Routes.RecordsEvents);
  const query = useGetRecordsEventDetailQuery(id);
  const recordsEvent = query.data;

  if (query.loading && !recordsEvent) return <LinearProgress />;
  if (!recordsEvent) return <div>Not found.</div>;

  return (
    <div className="legacy_bootstrap">
      <RecordsEventScreen key={recordsEvent.id} recordsEvent={recordsEvent} />
    </div>
  );
};
