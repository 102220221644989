import { User } from "users";

export enum AssetHistoryEventType {
  Created = "Asset created",
}

export interface AssetHistoryEvent {
  id: number;
  type: AssetHistoryEventType;
  content: string | null;
  oldValue: string | null;
  newValue: string | null;
  postedDate: Date;
  postedBy: User;
  assetId: number;
}

export function GetAssetHistoryEventDescription(event: AssetHistoryEvent) {
  let eventDescription = "";
  switch (event.type) {
    case AssetHistoryEventType.Created:
      eventDescription = "created the asset";
      break;

    default:
      eventDescription = "unknown event type";
  }

  return eventDescription;
}
