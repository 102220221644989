import { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useCreateSiteConfigValueRequest, useUpdateSiteConfigValueRequest } from "sites/manage/api";
import { getOutageRelatedConfigs, Site, SiteConfigValue } from "sites/models";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import AutoSave from "app/mui/forms/AutoSave";
import Grid from "@mui/system/Unstable_Grid";
import { FormProvider, useForm } from "react-hook-form";

interface OutageSettingsTabProps {
  site: Site;
}

export const OutageSettingsTab = (props: OutageSettingsTabProps) => {
  const createConfigValueRequest = useCreateSiteConfigValueRequest(props.site.id);
  const updateConfigValueRequest = useUpdateSiteConfigValueRequest(props.site.id);

  const outageSettings = getOutageRelatedConfigs(props.site);
  const hasOutageSetting = outageSettings.length > 0;

  const defaultValues = { ...outageSettings[0] };
  const methods = useForm({ defaultValues });

  // site should always have this setting, if blank then create the setting.
  useEffect(() => {
    if (!hasOutageSetting) {
      createConfigValueRequest.call({
        siteId: props.site.id,
        name: "QuoteAmountPercentageRedValue",
        value: "80", // defaults to 80%,
      });
      methods.setValue("value", "80");
    }
  }, []);

  const handleSave = (original: SiteConfigValue) => {
    methods.handleSubmit((values) => {
      updateConfigValueRequest.call({
        ...original,
        value: values.value,
      });
    })();
  };

  useEffect(() => {
    // if the date is reloaded, and the value isn't set yet, reset the form
    if (!methods.getValues().value) {
      methods.reset();
    }
  }, [outageSettings]);

  return (
    <Box sx={{ height: "500px", my: 1 }}>
      {hasOutageSetting && (
        <FormProvider {...methods}>
          <Grid container>
            <Grid sm={6} md={3}>
              <AutoSave onSubmit={() => handleSave(outageSettings[0])} defaultValues={defaultValues} />
              <Stack spacing={2}>
                <Typography variant="subtitle1">Outage scope records</Typography>
                <TextFieldFormItem label="Planning Scopes" fieldName="value" hint="% repair vs replace red at" />
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </Box>
  );
};
