import { TimeUnit } from "common";
import { KpiPotentialDelayType } from "kpis/models";
import * as yup from "yup";

export const KpiOneTimeScheduleDelayReductionValidationSchema = yup.object({
  reductionAmount: yup.number().nullable().required("Required").moreThan(0),
  reductionTimeUnit: yup.mixed<TimeUnit>().nullable().required("Required"),

  potentialDelayType: yup.mixed<KpiPotentialDelayType>().nullable(),
  location: yup.string().nullable(),
  actionsTaken: yup.string().nullable(),
});
