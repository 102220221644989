import React from "react";
import { Card, CardTitle, Input, Button, Collapse, Alert, Label } from "reactstrap";
import classNames from "classnames";
import { Record, RecordDetailView, RecordDigest, usePostRecordsByIdQuery } from "records";
import _ from "lodash";
import { CheckmarkIcon } from "icons/CheckmarkIcon";
import { AddIcon } from "icons/AddIcon";
import { formatRoutePath, Routes, useDebounce } from "app";
import { Link } from "react-router-dom";
import { DeleteIcon } from "icons/DeleteIcon";
import { CollapseIcon } from "icons/CollapseIcon";
import moment from "moment";
import { useEffect, useCallback, useState } from "react";
import { useRecordDetailsSearchRequest } from "search/api";
import { dateFormat } from "common";

interface Props {
  records: RecordDigest[];
  siteId: number;
  userCanEdit: boolean;
  userCanViewRecords: boolean;
  recordFilter?: (existingRecordDetails: RecordDetailView[]) => (record: RecordDetailView) => boolean;
  addRecord: (record: RecordDigest) => void;
  removeRecord: (record: RecordDigest) => void;
  parentDescriptor: string;
  valveDiagnosticsOnly: boolean;
  selectingForBulkValveHealthSummary: boolean;
  createSummaryMode?: boolean;
  hideHint?: boolean;
  recordWarning?: (record: RecordDetailView) => JSX.Element | null;
}

export const SummaryRecordSelector: React.FunctionComponent<Props> = (props) => {
  const recordIds = props.records.map((r) => r.id);
  const [records, setRecords] = useState<RecordDetailView[]>([]);
  const postRecordsByIdQuery = usePostRecordsByIdQuery();

  useEffect(() => {
    postRecordsByIdQuery.call(recordIds).then((recordsDetails) => {
      setRecords(recordsDetails ?? []);
    });
  }, [props.records]);

  const [recordSearchPhrase, setRecordSearchPhrase] = useState("");
  const [preexistingRecords] = useState(props.records);
  const [expanded, setExpanded] = useState(true);
  const [hasAttemptedSearch, setHasAttemptedSearch] = useState(false);

  const debouncedSearchPhrase = useDebounce(recordSearchPhrase, 700);

  const recordsSearchRequest = useRecordDetailsSearchRequest();

  const performSearch = useCallback(
    (query: string) => {
      recordsSearchRequest.call({
        query,
        siteId: props.siteId,
        valveDiagnosticsOnly: props.valveDiagnosticsOnly,
      });
    },
    [recordsSearchRequest]
  );

  useEffect(() => {
    if (debouncedSearchPhrase.length >= 3) {
      performSearch(debouncedSearchPhrase);
      setHasAttemptedSearch(true);
    } else {
      setHasAttemptedSearch(false);
      if (recordsSearchRequest.loading) recordsSearchRequest.cancel();
    }
  }, [debouncedSearchPhrase]);

  const countEligibleRecords = () => {
    if (!props.selectingForBulkValveHealthSummary) return props.records.length;

    const recordsGroupedBySingleAsset = _(records)
      .filter((record) => record.model.assets.length === 1)
      .groupBy((record) => record.model.assets[0].id)
      .toPairs()
      .value();

    return recordsGroupedBySingleAsset.length;
  };
  const eligibleRecordCount = countEligibleRecords();

  const renderValveDiagnostics = () => {
    return (
      <div className="items valve-diagnostics">
        {_.orderBy(records, (r) => r.model.assets[0]?.tag ?? "").map((record) => {
          const tag = record.model.assets[0]?.tag ?? "";
          const identifier = (
            <>
              <div className="tag mr-3">{tag}</div>
              <div>{moment(record.model.eventDate!).format(dateFormat)}</div>
            </>
          );
          return (
            <div className={classNames("item")} key={record.id}>
              <div className="item-identifier">
                {props.userCanViewRecords ? (
                  <Link to={formatRoutePath(Routes.Record, record)}>{identifier}</Link>
                ) : (
                  identifier
                )}
              </div>
              {props.recordWarning ? props.recordWarning(record) : null}
              {props.userCanEdit && (
                <Button className="delete-button" onClick={() => props.removeRecord(record.model)}>
                  <DeleteIcon />
                </Button>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderRecordsByAsset = () => {
    let recordsByAsset = _(records)
      .groupBy((record) =>
        record.model.assets.length === 1
          ? record.model.assets[0].tag
          : record.model.assets.length > 1
          ? "Multiple assets"
          : "Site record"
      )
      .toPairs()
      .sortBy((pair) => pair[0])
      .value();

    if (props.createSummaryMode && props.selectingForBulkValveHealthSummary) {
      // Show only the groups that include ineligible records.
      recordsByAsset = recordsByAsset.filter((pair) => pair[1].length > 1 || pair[1][0].model.assets.length === 0);
    }

    return (
      <div className="items-by-category">
        {props.createSummaryMode && countEligibleRecords() < props.records.length && (
          <>
            <h5 className="mt-3">Ineligible records</h5>
            <Alert color="info">
              These records are ineligible for the summary and will not be included. They may be ineligible for these
              reasons:
              <ul>
                <li>they have been superseded by more recent records for the same assets</li>
                <li>they are site records (no asset)</li>
              </ul>
            </Alert>
          </>
        )}

        {recordsByAsset.map((pair) => {
          const type = pair[0];
          const records = pair[1];
          const mostRecentRecord = _.orderBy(records, (r) => r.model.eventDate, "desc")[0];

          return (
            <div className="item-group" key={type}>
              <div className="category">{type}</div>
              <div className="items">
                {_(records)
                  .orderBy(["eventDate", "id"], "desc")
                  .value()
                  .map((record) => {
                    const supersededRecord =
                      props.selectingForBulkValveHealthSummary && record.id !== mostRecentRecord.id;
                    const noAsset = props.selectingForBulkValveHealthSummary && record.model.assets.length === 0;

                    const skipRecord = supersededRecord || noAsset;
                    if (props.createSummaryMode && !skipRecord) return null;

                    return (
                      <div
                        className={classNames("item", {
                          skip: skipRecord,
                        })}
                        key={record.id}
                        title={
                          noAsset
                            ? "This record is a site record."
                            : supersededRecord
                            ? "There's a more recent diagnostic for this valve."
                            : undefined
                        }
                      >
                        <div className="item-identifier">
                          {props.userCanViewRecords ? (
                            <Link to={formatRoutePath(Routes.Record, record)}>{record.model.description}</Link>
                          ) : (
                            record.model.description
                          )}
                        </div>
                        {props.recordWarning ? props.recordWarning(record) : null}
                        {props.userCanEdit && (
                          <Button className="delete-button" onClick={() => props.removeRecord(record.model)}>
                            <DeleteIcon />
                          </Button>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getAddRecordLabel = (record: Record) =>
    `${record.description}${` (${
      record.assets.length === 1 ? record.assets[0].tag : record.assets.length > 1 ? "multiple assets" : "site record"
    })`}`;

  const matchingRecords = hasAttemptedSearch ? recordsSearchRequest.data?.results ?? [] : [];
  const filteredRecords = matchingRecords.filter(!!props.recordFilter ? props.recordFilter(records) : () => true);
  const newRecords = filteredRecords.filter(
    (a) => preexistingRecords.every((pa) => pa.id !== a.id) || props.records.every((aa) => aa.id !== a.id)
  );
  const recordsToShow = 12;
  const sortedRecords = _.take(
    _.orderBy(newRecords, (record) => record.model.eventDate, "desc"),
    recordsToShow
  );
  return (
    <Card body className="record-selector">
      <CardTitle
        className={classNames("collapse-toggle", "d-flex", "align-items-center", {
          collapsed: !expanded,
        })}
        onClick={() => setExpanded(!expanded)}
      >
        <CollapseIcon expanded={expanded} />
        <div className="title">Records</div>
        <div className="ml-auto counter">
          {props.createSummaryMode && <Label>Record count:</Label>}
          {eligibleRecordCount}
        </div>
      </CardTitle>
      <Collapse isOpen={expanded}>
        {props.userCanEdit && (
          <>
            <Input
              placeholder={
                props.createSummaryMode
                  ? "Search by tag or description to add more records"
                  : "Search by tag or description"
              }
              className={classNames({
                "no-results": props.records.length === 0 && !props.hideHint,
              })}
              value={recordSearchPhrase}
              onChange={(e) => setRecordSearchPhrase(e.target.value)}
            />
            {!!matchingRecords && (
              <div className="add-buttons">
                {sortedRecords.map((record) => {
                  const recordAlreadyAdded = props.records.some((a) => a.id === record.id);
                  return (
                    <Button
                      key={record.id}
                      color="primary"
                      size="sm"
                      onClick={() => props.addRecord(record.model)}
                      disabled={recordAlreadyAdded}
                    >
                      {recordAlreadyAdded ? <CheckmarkIcon /> : <AddIcon />}
                      {getAddRecordLabel(record.model)}
                    </Button>
                  );
                })}
                {hasAttemptedSearch && !recordsSearchRequest.loading && sortedRecords.length === 0 && (
                  <div className="text-info">No eligible records found.</div>
                )}
              </div>
            )}
          </>
        )}
        {props.records.length === 0
          ? !props.hideHint && <div className="text-info">Add some records to the {props.parentDescriptor}.</div>
          : props.valveDiagnosticsOnly && !props.createSummaryMode
          ? renderValveDiagnostics()
          : renderRecordsByAsset()}
      </Collapse>
    </Card>
  );
};
