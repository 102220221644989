import { FunctionComponent, useState } from "react";
import { Mitigation, MitigationType, MitigationView, ApplicationDetailView } from "./models";
import { FormGroup, Input, Label, Row, Col, Collapse, Badge, Card, CardTitle } from "reactstrap";
import Select from "react-select";
import Textarea from "react-textarea-autosize";
import classNames from "classnames";
import { CollapseIcon } from "icons/CollapseIcon";
import { Asset } from "assets";
import { Checkbox } from "common";
import { DeleteIcon } from "icons/DeleteIcon";
import { useAutosaver, useUserContext } from "app";
import { ConfirmationModal } from "common/ConfirmationModal";
import _ from "lodash";
import moment from "moment";
import { dateFormat } from "common/formats";
import { useDeleteApplicationMitigationRequest, useUpdateApplicationMitigationRequest } from "./api";

const availabilityOptions = [
   { label: "In-service", value: false },
   { label: "Out-of-service", value: true },
];

interface Props {
   applicationView: ApplicationDetailView;
   mitigationView: MitigationView;
   forAsset: Asset | null;
   readOnly: boolean;
   expanded: boolean;
   implementedForAsset?: Date | null;
   toggleExpanded: () => void;
   toggleImplementedForAsset?: null | (() => void);
   mitigationDeleted: () => any;
}

export const MitigationDisplay: FunctionComponent<Props> = (props) => {
   const { mitigationView, expanded, applicationView } = props;
   const strategy = applicationView.strategies.find((s) => s.id === mitigationView.strategyId)!;
   const strategyFailureModes = applicationView.failureModes.filter(
      (fm) => fm.strategyId === mitigationView.strategyId
   );
   const strategyAssets = strategy.assets;

   const { userPermissions } = useUserContext();
   const [deleting, setDeleting] = useState(false);

   const updateMitigationRequest = useUpdateApplicationMitigationRequest(props.applicationView.id);
   const [mitigation, saveMitigation] = useAutosaver(mitigationView.model, updateMitigationRequest.call);
   const saveChange = (changed: Partial<Mitigation>, skipDelay?: boolean) =>
      saveMitigation({ ...mitigation, ...changed }, skipDelay);
   const deleteMitigationRequest = useDeleteApplicationMitigationRequest(props.applicationView.id);

   const getMitigationImplementationStatus = () => {
      if (strategyAssets.length === 0) {
         return <span className="text-info">No assets assigned to strategy</span>;
      }

      if (
         mitigationView.implementations.length > 0 &&
         strategyAssets.every((sa) => mitigationView.implementations.some((mc) => mc.assetId === sa.id))
      ) {
         const mostRecentImplementation = _.orderBy(mitigationView.implementations, (mi) => mi.implemented, "desc")[0];
         return (
            <span className="text-success">{`Fully implemented as of ${moment(
               mostRecentImplementation.implemented
            ).format(dateFormat)}`}</span>
         );
      }

      if (strategyAssets.some((sa) => mitigationView.implementations.some((mc) => mc.assetId === sa.id))) {
         return <span className="text-info">Implemented on some assets</span>;
      }
      return <span className="text-danger">Not yet implemented</span>;
   };
   const mitigationImplementationStatus = getMitigationImplementationStatus();

   const selectedFailureModes = mitigation.failureModes;

   const editable = !props.forAsset && userPermissions.userCanEditApplication;

   const mitigationTypeName =
      mitigation.type === MitigationType.Alert
         ? "Alert"
         : mitigation.type === MitigationType.Diagnostic
         ? "Diagnostic"
         : mitigation.type === MitigationType.FieldWork
         ? "Field work"
         : "Proactive modification";

   return (
      <Card body className={classNames("mitigation", { collapsed: !expanded })}>
         <CardTitle className={classNames("mitigation-header", { collapsed: !expanded })}>
            <div className="collapse-toggle" onClick={() => props.toggleExpanded()}>
               <CollapseIcon expanded={expanded} />

               <div className="name-and-badges">
                  <div className="name">
                     {expanded && <span>{mitigationTypeName}:&nbsp;</span>}
                     {mitigation.name ? <span>{mitigation.name}</span> : <span className="text-muted">unnamed</span>}
                  </div>
                  {!expanded && (
                     <div className="badges">
                        {mitigation.failureModes
                           .map((fmm) => _.first(strategyFailureModes.filter((sfm) => sfm.id === fmm.id)))
                           .filter((fmm) => !!fmm)
                           .map((fmm) => (
                              <Badge key={fmm!.id} color="secondary">
                                 {fmm!.model.name}
                              </Badge>
                           ))}
                     </div>
                  )}
               </div>

               {!props.forAsset && mitigationImplementationStatus}
            </div>

            {props.forAsset && (
               <Checkbox
                  value={!!props.implementedForAsset!}
                  onClick={() => {
                     props.toggleImplementedForAsset!();
                  }}
                  disabled={!userPermissions.userCanEditAsset}
               >
                  Implemented
                  {!!props.implementedForAsset && (
                     <>
                        <br />
                        {moment(props.implementedForAsset).format(dateFormat)}
                     </>
                  )}
               </Checkbox>
            )}

            {!props.forAsset && editable && (
               <>
                  <div className="delete-button" onClick={() => setDeleting(true)}>
                     <DeleteIcon />
                  </div>

                  {deleting && (
                     <ConfirmationModal
                        title="Delete mitigation?"
                        body="Are you sure you want to delete this mitigation?"
                        show={true}
                        cancel={() => setDeleting(false)}
                        confirm={() => {
                           deleteMitigationRequest.call(mitigation.id).then(() => {
                              props.mitigationDeleted();
                           });
                        }}
                     />
                  )}
               </>
            )}
         </CardTitle>

         <Collapse isOpen={expanded}>
            {editable && (
               <FormGroup>
                  <Label for="mitigation-name">Name</Label>
                  <Input
                     name="mitigation-name"
                     value={mitigation.name || ""}
                     onChange={(e) => {
                        saveChange({
                           name: e.target.value,
                        });
                     }}
                     plaintext={!editable}
                     readOnly={!editable}
                  />
               </FormGroup>
            )}

            <Row>
               {mitigation.type === MitigationType.Alert && (
                  <Col md={6}>
                     <FormGroup>
                        <Label>Set point</Label>
                        <Input
                           value={mitigation.setPoint || (editable ? "" : "--")}
                           onChange={(e) => {
                              saveChange({
                                 setPoint: e.target.value,
                              });
                           }}
                           plaintext={!editable}
                           readOnly={!editable}
                        />
                     </FormGroup>
                  </Col>
               )}

               {(mitigation.type === MitigationType.Diagnostic || mitigation.type === MitigationType.FieldWork) && (
                  <>
                     <Col md={6}>
                        <Label>Availability</Label>
                        {editable ? (
                           <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              value={availabilityOptions.find((o) => o.value === mitigation.outOfService)}
                              options={availabilityOptions}
                              onChange={(newValue) => {
                                 saveChange(
                                    {
                                       outOfService: (newValue as { value: boolean }).value,
                                    },
                                    true
                                 );
                              }}
                           />
                        ) : (
                           <p>{availabilityOptions.filter((o) => o.value === mitigation.outOfService)[0].label}</p>
                        )}
                     </Col>

                     <Col md={6}>
                        <FormGroup>
                           <Label>Frequency</Label>
                           <Input
                              value={mitigation.frequency || (editable ? "" : "--")}
                              onChange={(e) => {
                                 saveChange({
                                    frequency: e.target.value,
                                 });
                              }}
                              plaintext={!editable}
                              readOnly={!editable}
                           />
                        </FormGroup>
                     </Col>
                  </>
               )}
            </Row>

            {mitigation.type !== MitigationType.Proactive ? (
               <FormGroup>
                  <Label>Diagnosis notes</Label>
                  {editable ? (
                     <Textarea
                        className="form-control"
                        value={mitigation.diagnosisNotes || ""}
                        onChange={(e) => {
                           saveChange({
                              diagnosisNotes: e.target.value,
                           });
                        }}
                        readOnly={!editable}
                        minRows={5}
                     />
                  ) : (
                     <p>
                        {mitigation.diagnosisNotes && mitigation.diagnosisNotes.trim()
                           ? mitigation.diagnosisNotes
                           : "--"}
                     </p>
                  )}
               </FormGroup>
            ) : (
               <FormGroup>
                  <Label>Details</Label>
                  {editable ? (
                     <Textarea
                        className="form-control"
                        value={mitigation.details || ""}
                        onChange={(e) => {
                           saveChange({
                              details: e.target.value,
                           });
                        }}
                        readOnly={!editable}
                        minRows={5}
                     />
                  ) : (
                     <p>{mitigation.details && mitigation.details.trim() ? mitigation.details : "--"}</p>
                  )}
               </FormGroup>
            )}

            <FormGroup>
               <div className="header">Failure modes</div>
               {editable ? (
                  <Select
                     isMulti
                     className="react-select"
                     classNamePrefix="react-select"
                     value={selectedFailureModes}
                     getOptionLabel={(fm) => fm.name || "unnamed"}
                     getOptionValue={(fm) => fm.id.toString()}
                     options={strategyFailureModes.map((sfm) => ({
                        id: sfm.id,
                        name: sfm.model.name,
                        strategyId: sfm.id,
                     }))}
                     placeholder="Select failure modes"
                     backspaceRemovesValue={false}
                     onChange={(selectedFailureModes) => {
                        saveChange(
                           {
                              failureModes: selectedFailureModes.map((failureMode) => ({
                                 id: failureMode.id,
                                 name: failureMode.name,
                                 strategyId: failureMode.strategyId,
                              })),
                           },
                           true
                        );
                     }}
                  />
               ) : mitigation.failureModes.length > 0 ? (
                  <div className="failure-modes">
                     {selectedFailureModes.map((fmm) => (
                        <Badge key={fmm.id} color="secondary">
                           {fmm.name || "unnamed"}
                        </Badge>
                     ))}
                  </div>
               ) : (
                  <div>No associated failure modes.</div>
               )}
            </FormGroup>
         </Collapse>
      </Card>
   );
};
