import * as React from "react";
import { FormGroup, Label, Input, CardSubtitle } from "reactstrap";
import classNames from "classnames";

interface Props {
   label: string;
   value: number | null;
   units?: string;
   emphasize?: boolean;
   places?: number;
   dontShowZero?: boolean;
}

export class KpiOutputGroup extends React.Component<Props> {
   public render() {
      const units = this.props.units || "$";
      const amount = this.props.value === null ? 0 : this.props.value;
      const displayValue =
         !this.props.dontShowZero || amount !== 0
            ? units === "$"
               ? amount.toLocaleString("en-CA", { style: "currency", currency: "CAD" })
               : `${amount.toFixed(this.props.places !== undefined ? this.props.places : 0)} ${units}`
            : "--";

      return (
         <FormGroup>
            {this.props.emphasize ? <CardSubtitle>{this.props.label}</CardSubtitle> : <Label>{this.props.label}</Label>}
            <Input
               plaintext
               className={classNames("text-right", { tbd: this.props.value === null, emphasize: this.props.emphasize })}
               value={displayValue}
               readOnly
            />
         </FormGroup>
      );
   }
}
