import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
} from "app";
import {
  Application,
  ApplicationDetailView,
  ApplicationDigest,
  FailureMode,
  FailureModeView,
  Mitigation,
  MitigationImplementation,
  Strategy,
} from "./models";

export const useGetApplicationRequest = (applicationId: number | null) =>
  useBaseAxiosGetRequestWithAutoRefresh<ApplicationDetailView>(
    !!applicationId ? `api/applications/${applicationId}` : null,
    applicationMutationUriMatchPatterns,
    { useNode: true }
  );

export const useGetApplicationDigestsInSiteRequest = (siteId: number) =>
  useBaseAxiosGetRequest<ApplicationDigest[]>(`api/applications/digests/by/site/${siteId}`, { useNode: true });


export const useGetApplicationFailureModesBySiteIdRequest = (siteId: number) =>
  useBaseAxiosGetRequest<FailureModeView[]>(`api/applications/failureModes?siteId=${siteId}`);

export const useGetStrategiesByAssetIdsRequest = (assetIds: number[]) =>
  useBaseAxiosGetRequest<Strategy[]>(`api/applications/strategies?assetIds=${assetIds.join(",")}`);

export const applicationMutationUriMatchPatterns = [/api\/applications.*/gi];

export const useCreateApplicationRequest = () =>
  useBaseAxiosPostRequest<Partial<Application>, ApplicationDigest>(`api/applications`, { useNode: true });

export const useUpdateApplicationRequest = () => useBaseAxiosPutRequest<Application>(`api/applications`, { useNode: true });

interface DuplicateApplicationRequest {
  applicationId: number;
  name: string;
  siteId: number;
}

export const useDuplicateApplicationRequest = () =>
  useBaseAxiosPostRequest<DuplicateApplicationRequest, ApplicationDigest>(`api/applications`);

export const useDeleteApplicationRequest = () => useBaseAxiosDeleteRequest((id) => `api/applications/${id}`);

export const useCreateApplicationStrategyRequest = () =>
  useBaseAxiosPostRequest<Partial<Strategy>, Strategy>(`api/applications/strategies`, { useNode: true });
export const useUpdateApplicationStrategyRequest = () =>
  useBaseAxiosPutRequest<Strategy>(`api/applications/strategies`, { useNode: true });
export const useDeleteApplicationStrategyRequest = () =>
  useBaseAxiosDeleteRequest((id) => `api/applications/strategies/${id}`, { useNode: true });

export const useUpsertApplicationStrategyMitigationImplementationsRequest = (strategyId: number) =>
  useBaseAxiosPostRequest<MitigationImplementation[]>(
    `api/applications/strategies/${strategyId}/mitigationImplementations`,
    { useNode: true }
  );
export const useUpsertApplicationStrategyMitigationImplementationsForAssetRequest = (
  strategyId: number,
  assetId: number
) =>
  useBaseAxiosPostRequest<number[]>(`api/applications/strategies/${strategyId}/mitigationImplementations/${assetId}`, { useNode: true });

export const useCreateApplicationFailureModeRequest = (applicationId: number) =>
  useBaseAxiosPostRequest<Partial<FailureMode>, FailureModeView>(`api/applications/${applicationId}/failureModes`, {
    useNode: true,
  });
export const useUpdateApplicationFailureModeRequest = (applicationId: number) =>
  useBaseAxiosPutRequest<FailureMode>(`api/applications/${applicationId}/failureModes`, { useNode: true });
export const useDeleteApplicationFailureModeRequest = (applicationId: number) =>
  useBaseAxiosDeleteRequest((id) => `api/applications/${applicationId}/failureModes/${id}`, { useNode: true });

export const useCreateApplicationMitigationRequest = (applicationId: number) =>
  useBaseAxiosPostRequest<Partial<Mitigation>, Mitigation>(`api/applications/${applicationId}/mitigations`, {
    useNode: true,
  });
export const useUpdateApplicationMitigationRequest = (applicationId: number) =>
  useBaseAxiosPutRequest<Mitigation>(`api/applications/${applicationId}/mitigations`, { useNode: true });
export const useDeleteApplicationMitigationRequest = (applicationId: number) =>
  useBaseAxiosDeleteRequest((id) => `api/applications/${applicationId}/mitigations/${id}`, { useNode: true });
