import { AssetType, AssetTypeValues } from "@bluemarvel/iris-common/model";
import _ from "lodash";

export enum BusinessArea {
  Instrumentation = "Instrumentation",
  Machinery = "Machinery",
}

export const getBusinessAreaForAssetType = (assetType: AssetType): BusinessArea => {
  switch (assetType) {
    case 'Machinery':
      return BusinessArea.Machinery;
    default:
      return BusinessArea.Instrumentation;
  }
};

export const BusinessAreaMap = _.groupBy(AssetTypeValues, (assetType) => getBusinessAreaForAssetType(assetType));
