import * as React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

interface ConfirmRemoveScopeModalProps {
   scopeDisplayName: string;
   close: () => void;
   isOpen: boolean;
   onRemoveScope: () => void;
}

export const ConfirmRemoveScopeModal = (props: ConfirmRemoveScopeModalProps) => {
   return (
      <Modal
         className="create-summary-modal"
         isOpen={props.isOpen}
         toggle={() => props.close()}
         container={"#bootstrap-modal-container"}
      >
         <ModalHeader>Remove {props.scopeDisplayName}?</ModalHeader>
         <ModalBody>
            <p>Are you sure you want to remove this scope? This action cannot be undone.</p>
         </ModalBody>
         <ModalFooter>
            <Button
               color="danger"
               onClick={() => {
                  props.onRemoveScope();
                  props.close();
               }}
            >
               Remove Scope
            </Button>
            <Button color="secondary" onClick={() => props.close()}>
               Cancel
            </Button>
         </ModalFooter>
      </Modal>
   );
};
