import React, { useCallback, useEffect } from "react";
import colors from "assets/customColors";
import { Box, Popover, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import { gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import _ from "lodash";
import { usePopover } from "app/mui/common/usePopover";
import { wholeNumberFormatter } from "common";
import { RecordDetailView, Statuses } from "records";
import moment from "moment";
import { LabeledChart } from "./LabeledChart";
import { ChartsContainer } from "./ChartsContainer";

const graphColors = [
  colors.congressBlue,
  colors.seance,
  colors.roti,
  colors.brightTurquoise,
  colors.frenchRose,
  colors.illusion,
  colors.pizzaz,
  colors.lightningYellow,
  colors.robinsEggBlue,
  colors.forestGreen,
];

export const useTabelRowDataExtractor = (apiRef: React.MutableRefObject<GridApiPro>) => {
  const [rows, setRows] = React.useState<RecordDetailView[] | null>(null);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const updateData = () => {
    if (apiRef.current) {
      setGridLoading(true);
      setRows(gridFilteredSortedRowEntriesSelector(apiRef).map((r) => r.model as RecordDetailView));
      setGridLoading(false);
    }
  };

  const debouncedHandleStateChanged = useCallback(_.debounce(updateData, 500), []);

  useEffect(() => {
    if (!apiRef.current || !apiRef.current.subscribeEvent) {
      return;
    }
    setGridLoading(true);
    const eventListeners = [apiRef.current.subscribeEvent("stateChange", debouncedHandleStateChanged)];

    return () => {
      eventListeners.forEach((cleanUp) => cleanUp());
    };
  }, [apiRef.current]);

  return { rows, gridLoading };
};

interface ChartItemDataFormat {
  id: any;
  label: string;
  value: number;
  color?: string;
}

const useStatusData = (data: RecordDetailView[]): ChartItemDataFormat[] => {
  const statusesMap = {};
  Statuses.forEach((item) => {
    const itemLabel = item.replace(/([a-z])([A-Z])/g, "$1 $2"); // Add a space between words
    statusesMap[item] = { id: item, label: itemLabel, value: 0 };
  });

  data.forEach((d) => {
    const id = d.model.status?.toString();
    if (statusesMap[id]) {
      statusesMap[id].value = statusesMap[id].value + 1;
    }
  });

  return Object.values(statusesMap);
};

const useOverdueCountData = (data: RecordDetailView[]) => {
  const stats = {
    zeroToThree: { id: "zeroToThree", label: "0-3 Months", value: 0, color: graphColors[6] },
    threeToTwelve: { id: "threeToTwelve", label: "3-12 Months", value: 0, color: graphColors[6] },
    twelvePlus: { id: "twelvePlus", label: "1 Year +", value: 0, color: graphColors[6] },
  };

  data.forEach((d) => {
    const followUp = d.model.followUp;
    if (followUp) {
      const months = moment().diff(followUp, "months");

      if (months < 3) {
        stats["zeroToThree"].value += 1;
      } else if (months < 12) {
        stats["threeToTwelve"].value += 1;
      } else {
        stats["twelvePlus"].value += 1;
      }
    }
  });

  return stats;
};

const useRecordTypesData = (data: RecordDetailView[]) => {
  const stats: { [key: string]: any } = {};

  data.forEach((d) => {
    const key = d.model.recordType;
    if (!stats[key]) {
      stats[key] = 0;
    }

    stats[key] += 1;
  });

  return [stats];
};

export const RecordsListDataViz = (props: { apiRef: React.MutableRefObject<GridApiPro>; loading: boolean | null }) => {
  const { rows, gridLoading } = useTabelRowDataExtractor(props.apiRef);
  const total = rows?.length || 0;
  const recordTypes = useRecordTypesData(rows || []);
  const statuses = useStatusData(rows || []);
  const overdue = useOverdueCountData(rows || []);

  return (
    <ChartsContainer loading={props.loading || gridLoading} totalNumberOfRows={rows ? rows.length : null}>
      <TotalRecordsCountBarChart data={total} />
      <RecordTypeBarChart data={recordTypes} />
      <RecordsByStatusPieChart data={statuses} />
      <RecordsOverdueCountChart data={overdue} />
    </ChartsContainer>
  );
};

export const TotalRecordsCountBarChart = (props: { data: number }) => {
  return (
    <LabeledChart label="Records">
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          background: graphColors[0],
          color: "white",
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        {props.data}
      </Typography>
    </LabeledChart>
  );
};

export const RecordTypeBarChart = (props: { data: any[] }) => {
  return (
    <LabeledChart label="Types">
      <MyResponsiveBar data={props.data} />
    </LabeledChart>
  );
};

export const RecordsByStatusPieChart = (props: { data: ChartItemDataFormat[] }) => {
  return (
    <LabeledChart label="Status">
      <MyResponsivePie data={props.data} />
    </LabeledChart>
  );
};

export const RecordsOverdueCountChart = (props: {
  data: { [key: string]: { id: string; label: string; value: number } };
}) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <LabeledChart label="Overdue">
      <Box onMouseEnter={openPopover} onMouseLeave={closePopover} height="100%">
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            background: graphColors[6],
            color: "white",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          {Object.values(props.data)
            .map((d) => d.value)
            .reduce((a, b) => a + b)}
        </Typography>
      </Box>
      <HoverTooltip {...popoverProps} allData={Object.values(props.data)} />
    </LabeledChart>
  );
};

const MyResponsivePie = ({ data }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <Box onMouseEnter={openPopover} onMouseLeave={closePopover} width="100%" height="100%">
      <ResponsivePie
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        startAngle={-90}
        endAngle={90}
        borderWidth={0}
        isInteractive={false}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={graphColors}
        legends={[]}
        animate={false}
      />
      <HoverTooltip {...popoverProps} allData={data} />
    </Box>
  );
};

const MyResponsiveBar = ({ data }) => {
  const { openPopover, closePopover, popoverProps } = usePopover();

  return (
    <Box onMouseEnter={openPopover} onMouseLeave={closePopover} width="100%" height="100%">
      <ResponsiveBar
        data={data}
        margin={{ top: 0, right: 20, bottom: 0, left: 20 }}
        layout="horizontal"
        groupMode={"stacked"}
        valueScale={{ type: "linear" }}
        keys={Object.keys(data[0])}
        indexScale={{ type: "band", round: true }}
        colors={graphColors}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        legends={[]}
        isInteractive={false}
        animate={false}
      />
      <HoverTooltip
        {...popoverProps}
        allData={Object.keys(data[0]).map((k) => ({ id: k, label: k, value: data[0][k] }))}
      />
    </Box>
  );
};

interface HoverTooltipProps {
  allData: ChartItemDataFormat[];
  open: boolean;
  anchorEl: any;
  onClose: () => void;
}

const HoverTooltip = (props: HoverTooltipProps) => {
  if (props.allData.length <= 0) {
    return <></>;
  }

  return (
    <Popover
      sx={{
        pointerEvents: "none",
        marginTop: "20px",
      }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={props.onClose}
      disableRestoreFocus
    >
      <Box sx={{ p: 2 }}>
        <table style={{ border: "none", borderCollapse: "collapse", maxWidth: 0, whiteSpace: "nowrap" }}>
          {props.allData?.map((item, index) => (
            <tr key={item.id}>
              <td style={{ textAlign: "right", width: "100%" }}>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: item.color || graphColors[index], fontWeight: "bold" }}
                >
                  {item.label}:
                </Typography>
              </td>
              <td style={{ textAlign: "left" }}>
                <Typography component="span" variant="body2" sx={{ fontWeight: "bold" }}>
                  {wholeNumberFormatter.format(item.value)}
                </Typography>
              </td>
            </tr>
          ))}
        </table>
      </Box>
    </Popover>
  );
};
