import React from "react";
import { Status, statusToDisplayName } from "records";
import { IdentifiedIcon } from "icons/IdentifiedIcon";
import { PendingIcon } from "icons/PendingIcon";
import Badge from "reactstrap/lib/Badge";
import { CompleteIcon } from "icons/CompleteIcon";
import { ArchivedIcon } from "icons/ArchivedIcon";

interface Props {
  status: Status;
}

export class RecordStatusBadge extends React.PureComponent<Props> {
  public render() {
    const { status } = this.props;
    return (
      <Badge pill className="status" color={this.getColorForStatus(status)}>
        {this.getIconForStatus(status)}
        {statusToDisplayName(status)}
      </Badge>
    );
  }

  private getIconForStatus = (status: Status) => {
    switch (status) {
      case "Identified":
        return <IdentifiedIcon />;
      case "CustomerAction":
      case "ImpactPartnerAction":
        return <PendingIcon />;
      case "Complete":
        return <CompleteIcon />;
      case "Archived":
        return <ArchivedIcon />;
      default:
        return null;
    }
  };

  private getColorForStatus = (status: Status) => {
    switch (status) {
      case "Identified":
        return "identified";
      case "CustomerAction":
        return "pending-customer-action";
      case "ImpactPartnerAction":
        return "pending-spartan-action";
      case "Complete":
        return "complete";
      case "Archived":
        return "archived";
      default:
        return undefined;
    }
  };
}
