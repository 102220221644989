import { TrafficLightValue } from "records";

export interface VisualIntegrity {
  environmental: TrafficLightValue | null;
  healthAndSafety: TrafficLightValue | null;
  installation: TrafficLightValue | null;
  mechanicalIntegrity: TrafficLightValue | null;
  obsolescence: TrafficLightValue | null;
  taggingSignage: TrafficLightValue | null;
  overallScore: TrafficLightValue | null;
  overallIntegrity?: string | null;
}

export interface VisualIntegrityValidity {
  valid: boolean;

  environmental: boolean;
  healthAndSafety: boolean;
  installation: boolean;
  mechanicalIntegrity: boolean;
  obsolescence: boolean;
  taggingSignage: boolean;
}

export enum VisualIntegrityFieldLabel {
  Environmental = "Environmental",
  HealthAndSafety = "Health and safety",
  Installation = "Installation",
  MechanicalIntegrity = "Mechanical integrity",
  Obsolescence = "Obsolescence",
  TaggingSignage = "Tagging/signage",
  OverallScore = "Overall visual integrity",
}
