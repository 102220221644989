import { Accordion, AccordionDetails, AccordionSummaryProps, Box, Paper, styled } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useUserContext } from "app/contexts/UserContext";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useGetKpiValueDetailsByRecordRequest, useUpdateKpiValuesRequest } from "kpis/api";
import { KpiFields, KpiValueDetail, KpiValueModel } from "kpis/models";
import { useCallback, useEffect, useState } from "react";
import { RecordDetailView } from "records/models";
import { NoDataToDisplay } from "app/mui/common/placeholders/NoDataToDisplay";
import { IrisColors } from "app/mui/theme";
import { AddRecordKpiDialog } from "records/mui/dialogs/AddRecordKpiDialog";
import { DeleteKpiDialog } from "records/mui/dialogs/DeleteKpiDialog";
import { UnlinkKpiDialog } from "records/mui/dialogs/UnlinkKpiDialog";
import RecordKpiDetail from "../RecordKpiDetail";
import RecordKpiHeader from "./RecordKpiRowHeader";

interface RecordKpisProps {
  recordDetail: RecordDetailView;
  loading?: boolean;
}

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(() => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

export const RecordKpis = (props: RecordKpisProps) => {
  const { recordDetail, loading = false } = props;

  const record = recordDetail.model;

  const { userPermissions } = useUserContext();

  const updateKpiValuesRequest = useUpdateKpiValuesRequest();

  const userCanEdit =
    userPermissions.userCanEditRecord(
      record.assets.map((asset) => asset.assetType),
      record.recordType
    ) && userPermissions.userCanEditKpi;

  const userCanUnlock = userPermissions.userCanUnlockKpi;

  const kpiValuesQuery = useGetKpiValueDetailsByRecordRequest(record.id);
  const kpiValues = kpiValuesQuery.data || [];

  const recordIsComplete = recordDetail.model.status === "Complete";
  const recordIsArchived = recordDetail.model.status === "Archived";
  const isClosed = recordIsComplete || recordIsArchived;
  const actionDispatcher = useActionDispatcher();

  const panelActions = () => {
    return createStaticActions((actionDispatcher, userPermissions) => {
      return [
        {
          icon: <AddIcon />,
          label: "Add Kpi",
          action: () => actionDispatcher.dispatch("addKpi"),
        },
      ];
    });
  };
  const actions = useStaticActions(panelActions(), actionDispatcher);

  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);
  const [kpiForDialog, setKpiForDialog] = useState<KpiValueDetail>();

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const handleAction = useCallback((action: string, params: any) => {
    if (validDialogActions.includes(action)) {
      if (action === "deleteKpi" || action === "unlinkKpi") {
        setKpiForDialog(params);
      }

      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  type DialogActionsType = "addKpi" | "deleteKpi" | "unlinkKpi" | null;
  const validDialogActions = ["addKpi", "deleteKpi", "unlinkKpi"];

  const onUnlinkKpi = (updatedKpiModel: KpiValueModel) => {
    updateKpiValuesRequest.call(updatedKpiModel);
  };

  const saveChanges = (model: KpiValueModel, updatedKpiModel: Partial<KpiFields>) => {
    const updatedFields = { ...model, ...updatedKpiModel, siteId: recordDetail.siteId };
    updateKpiValuesRequest.call(updatedFields);
  };

  return (
    <>
      <Paper>
        <ExpansionPanel
          title="KEY PERFORMANCE INDICATORS"
          defaultExpanded={false}
          rightSideComponent={(userCanEdit || !isClosed) && <ActionsDropdownMenu actions={actions} />}
        >
          <Box sx={{ p: 2 }}>
            {kpiValues.length > 0 ? (
              <>
                {kpiValues
                  .filter((x) => x.kpiType === "Legacy")
                  .map((kpiValue) => (
                    <Accordion
                      key={kpiValue.id}
                      expanded={false}
                      sx={{
                        "&": {
                          backgroundColor: IrisColors.gray100,
                          pointerEvents: "none",
                          cursor: "default",
                        },
                      }}
                    >
                      <StyledAccordionSummary
                        expandIcon={<Box sx={{ mt: "0.5em", mb: "0.8em", minWidth: "24px" }} />}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        <RecordKpiHeader
                          recordDetail={recordDetail}
                          kpiValue={kpiValue}
                          isLegacy
                          actionDispatcher={actionDispatcher}
                        />
                      </StyledAccordionSummary>
                    </Accordion>
                  ))}
                {kpiValues
                  .filter((x) => x.kpiType !== "Legacy")
                  .map((kpiValue) => (
                    <Accordion key={kpiValue.id}>
                      <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ mt: "0.5em", mb: "0.8em", minWidth: "24px" }} />}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        <RecordKpiHeader
                          recordDetail={recordDetail}
                          kpiValue={kpiValue}
                          actionDispatcher={actionDispatcher}
                        />
                      </StyledAccordionSummary>
                      <AccordionDetails>
                        <RecordKpiDetail
                          detail={kpiValue}
                          record={recordDetail}
                          readOnly={
                            !userCanEdit || kpiValue.model.signedOff || loading || actionDispatcher.isActionInProgress
                          }
                          userCanEdit={userCanEdit && !loading}
                          userCanUnlock={userCanUnlock}
                          saveChanges={(fields: Partial<KpiFields>) => saveChanges(kpiValue.model, fields)}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </>
            ) : (
              <NoDataToDisplay />
            )}
          </Box>
        </ExpansionPanel>
      </Paper>

      {openDialog === "addKpi" && (
        <AddRecordKpiDialog onClose={closeDialog} siteId={recordDetail.siteId} recordId={recordDetail.id} />
      )}
      {openDialog === "deleteKpi" && <DeleteKpiDialog onClose={closeDialog} kpiValue={kpiForDialog!} />}
      {openDialog === "unlinkKpi" && (
        <UnlinkKpiDialog
          onClose={closeDialog}
          kpiValueModel={{ ...kpiForDialog?.model!, siteId: recordDetail.siteId }}
          onUnlink={onUnlinkKpi}
        />
      )}
    </>
  );
};
