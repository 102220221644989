import { PhaseCompleteIcon } from "icons/PhaseCompleteIcon";
import { PhaseInProgressIcon } from "icons/PhaseInProgressIcon";
import { PhaseWaitingIcon } from "icons/PhaseWaitingIcon";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useCallback } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Col, Row } from "reactstrap";
import {
   GetMilestonesForOutage,
   GetRecordOutageExecutionStatusDisplay,
   RecordOutageDetail,
   RecordOutageDetailView,
   RecordOutageExecutionStatuses,
} from "../models";
import { DateInput } from "../inputs/DateInput";
import { SelectInput } from "../inputs/SelectInput";
import { TextAreaInput } from "../inputs/TextAreaInput";
import { RecordDetailSectionProps } from "../RecordOutageDetailCard";
import { RecordsEventDigest, useGetRecordsEventDigestQuery } from "recordsEvents";
import { pluralize } from "common";
import { Loading } from "app/bs";
import { Record, useUpdateRecordOutageDetailRequest } from "records";
import { useAutoSave } from "app";
import AutoSave from "app/mui/forms/AutoSave";
import useDeepCompareEffect from "use-deep-compare-effect";

interface ExecutionTabProps extends RecordDetailSectionProps {
   outageDetailView: RecordOutageDetailView;
   record: Record;
}

export const ExecutionTab = (props: ExecutionTabProps) => {
   const { data: recordsEvent } = useGetRecordsEventDigestQuery(props.record.recordsEventId!);
   if (!recordsEvent) return <Loading />;
   return <ExecutionTabWithLoadedData {...props} recordsEvent={recordsEvent} />;
};

interface ExecutionTabWithLoadedDataProps extends ExecutionTabProps {
   recordsEvent: RecordsEventDigest;
}
const ExecutionTabWithLoadedData = (props: ExecutionTabWithLoadedDataProps) => {
   const updateOutageRequest = useUpdateRecordOutageDetailRequest();

   const defaultValues = props.outageDetailView.model;

   const methods = useForm<RecordOutageDetail>({
      defaultValues,
      mode: "onChange",
   });

   const handleSave = methods.handleSubmit((values) => {
      const updated = {
         ...props.outageDetailView.model,
         ...values,
      };

      updateOutageRequest.call(updated);
   });

   const outageDetail = props.outageDetailView.model;

   const milestoneRows = GetMilestonesForOutage(props.outageDetailView);

   const indexOfInProgressItem = useMemo(() => {
      const indexesOfCompletedMilestones = milestoneRows
         .map((item, index) => {
            const knownValue = outageDetail?.actualMilestones?.[item.fieldName];
            if (knownValue != null && (knownValue as any) !== "") {
               return index;
            }
         })
         .filter((x) => x != null);

      const indexOfLastCompletedItem = _.max(indexesOfCompletedMilestones) ?? -1;
      return indexOfLastCompletedItem + 1;
   }, [milestoneRows, outageDetail]);

   if (props.outageDetailView.currentPhase === "Planning") {
      return <NotYetExecutionPhase />;
   }

   return (
      <>
         <FormProvider {...methods}>
            <AutoSave onSubmit={handleSave} defaultValues={defaultValues} />
            <form className="milestones">
               <Row>
                  <Col lg="3">
                     <SelectInput
                        field="executionStatus"
                        label="Execution status"
                        userCanEdit={props.userCanEdit}
                        options={RecordOutageExecutionStatuses}
                        getDisplayTextForValue={GetRecordOutageExecutionStatusDisplay}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <table>
                        <thead>
                           <tr className="milestones-row">
                              <th>
                                 <div className="cell">Milestones</div>
                              </th>
                              <th>
                                 <div className="cell expected header">Expected</div>
                              </th>
                              <th>
                                 <div className="cell actual header">Actuals</div>
                              </th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>
                           {milestoneRows.map((row, index) => (
                              <tr className="milestones-row" key={row.fieldName}>
                                 <td>
                                    <div className="cell label">
                                       {index < indexOfInProgressItem ? (
                                          <PhaseCompleteIcon className="completed-icon" />
                                       ) : index === indexOfInProgressItem ? (
                                          <PhaseInProgressIcon className="in-progress-icon" />
                                       ) : (
                                          <PhaseWaitingIcon className="waiting-icon" />
                                       )}
                                       {row.label}
                                    </div>
                                 </td>
                                 <td>
                                    <div className="cell expected">
                                       <DateInput
                                          field={`expectedMilestones.[${row.fieldName}]`}
                                          label=""
                                          userCanEdit={props.userCanEdit}
                                          hideTextWhenNoValue
                                       />
                                    </div>
                                 </td>
                                 <td>
                                    <div className="cell actual">
                                       <DateInput
                                          field={`actualMilestones.[${row.fieldName}]`}
                                          label=""
                                          userCanEdit={props.userCanEdit}
                                          hideTextWhenNoValue
                                       />
                                    </div>
                                 </td>
                                 <td>
                                    <div className="cell difference">
                                       <ExpectedActualWarning
                                          recordsEvent={props.recordsEvent}
                                          expectedField={`expectedMilestones.[${row.fieldName}]`}
                                          actualField={`actualMilestones.[${row.fieldName}]`}
                                          previousActualField={
                                             !!row.previousFieldName
                                                ? `actualMilestones.[${row.previousFieldName}]`
                                                : null
                                          }
                                       />
                                    </div>
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </Col>
               </Row>
               <hr />
               <Row>
                  <Col>
                     <TextAreaInput label="Summary of repair" field="summaryOfRepair" userCanEdit={props.userCanEdit} />
                  </Col>
               </Row>
            </form>
         </FormProvider>
      </>
   );
};

interface ExpectedActualWarningProps {
   recordsEvent: RecordsEventDigest;
   expectedField: string;
   actualField: string;
   previousActualField?: string | null;
}

const useConditionalWatch = (props: { name: string | null | undefined }) => {
   const value = useWatch({ name: props.name as any });

   if (props.name) {
      return value;
   } else {
      return null;
   }
};

const useGetDatesAsMoments = (props: ExpectedActualWarningProps) => {
   const expectedValue = useConditionalWatch({ name: props.expectedField });
   const actualValue = useConditionalWatch({ name: props.actualField });
   const previousActualValue = useConditionalWatch({ name: props.previousActualField });

   const expectedValueAsMoment = expectedValue ? moment(expectedValue).startOf("day") : null;
   const actualValueAsMoment = actualValue ? moment(actualValue).startOf("day") : null;
   const previousActualValueAsMoment = previousActualValue ? moment(previousActualValue).startOf("day") : null;

   const recordEventStartDate = props.recordsEvent.startDate
      ? moment(props.recordsEvent.startDate).startOf("day")
      : null;
   const recordEventEndDate = props.recordsEvent.endDate ? moment(props.recordsEvent.endDate).startOf("day") : null;

   return {
      expected: expectedValueAsMoment,
      actual: actualValueAsMoment,
      previousActual: previousActualValueAsMoment,
      recordEventStartDate,
      recordEventEndDate,
   };
};

const differenceInDays = (left: moment.Moment, right: moment.Moment): string => {
   const daysDiff = left.diff(right, "days");
   return `${daysDiff} ${pluralize(daysDiff, "day")}`;
};

const ExpectedActualWarning = (props: ExpectedActualWarningProps) => {
   const { expected, actual, previousActual, recordEventStartDate, recordEventEndDate } = useGetDatesAsMoments(props);

   // try to compare the actual column to the previous actual column
   if (previousActual && actual) {
      if (actual.isBefore(previousActual)) {
         return <span className="error">{differenceInDays(previousActual, actual)} before the above date.</span>;
      }
   }

   // otherwise compare the actual column to start/end date of the record event.
   // show a message if the actual date occurs outside this window
   if (actual) {
      if (recordEventStartDate && recordEventStartDate.isAfter(actual)) {
         return (
            <span className="error">{differenceInDays(recordEventStartDate, actual)} before the event period.</span>
         );
      }
      if (recordEventEndDate && recordEventEndDate.isBefore(actual)) {
         return <span className="error">{differenceInDays(actual, recordEventEndDate)} after the event period.</span>;
      }
   }

   // otherwise assume the actual date is within the start/end date window of
   // the record event - and therefore we need to compare the start/end date
   // to show the difference.
   if (expected && actual) {
      if (expected.isAfter(actual)) {
         return <span>{differenceInDays(expected, actual)} before expected.</span>;
      }
      if (actual.isAfter(expected)) {
         return <span>{differenceInDays(actual, expected)} after expected.</span>;
      }
   }

   // if none of these conditions are true, maybe we don't have enough data,
   // or nothing is worth bringing attention to so don't show anything.
   return <></>;
};

const NotYetExecutionPhase = () => {
   return <div className="incorrect-outage-phase">A planned scope must be accepted before you can continue.</div>;
};
