import React from "react";
import { KpiCommonDetail } from "./models";
import NumberFormat from "react-number-format";
import _ from "lodash";

export const KpiSummary: React.FunctionComponent<{ kpi: KpiCommonDetail }> = (props) => {
  return (
    <>
      <span>
        Savings:&nbsp;
        <span className="savings-figure">
          <NumberFormat
            value={props.kpi.calculatedSavings}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={0}
            prefix={!!props.kpi.calculatedSavingsUnit?.startsWith("Dollars") ? "$" : ""}
          />
          {props.kpi.calculatedSavingsUnit !== "Dollars" && (
            <>&nbsp;{_.lowerFirst(props.kpi.calculatedSavingsUnit?.replace("Dollars ", ""))}</>
          )}
        </span>
      </span>
      {props.kpi.kpiType === "OngoingMaintenanceSavingsOrProductionImprovement" &&
        props.kpi.calculatedReturnOnInvestment !== "Immediate" && (
          <span>
            &nbsp;<span>{`after ${props.kpi.calculatedReturnOnInvestment}`}</span>
          </span>
        )}
    </>
  );
};
