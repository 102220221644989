import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { KpiEmissionsTypes } from "kpis/models";
import { KpiOneTimeReleaseOrSpillAvoidance, KpiReleaseType } from "kpis/models/KpiOneTimeReleaseOrSpillAvoidance";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface ReleaseTypeFormItemProps extends BaseFormItemProps {
  reductionUnitFieldName: string;
  reductionTypeFieldName: string;
}

function ReleaseTypeFormItem(props: ReleaseTypeFormItemProps) {
  const {
    label = "Release type",
    reductionTypeFieldName,
    reductionUnitFieldName,
    fieldName,
    readOnly,
    ...formItemLayoutProps
  } = props;

  const methods = useFormContext();

  const handleReleaseTypeChange = (value: KpiReleaseType, onChange: (value: KpiReleaseType) => void) => {
    methods.setValue(reductionUnitFieldName, value === "Emission" ? "tonnes" : "barrels");
    methods.setValue(reductionTypeFieldName, value === "Emission" ? KpiEmissionsTypes[0] : "");
    onChange(value);
  };

  return (
    <Controller
      name={fieldName}
      render={({ field: { value, onChange }, fieldState: { error: fieldError }, formState: { errors } }) => {
        return (
          <FormItemLayout
            controlled
            variant="unstyled"
            hint={props.hint}
            label={label}
            error={errors[fieldName]?.message?.toString()}
            formControlProps={{ disabled: readOnly }}
            fullWidth={false}
            {...formItemLayoutProps}
          >
            {(inputProps) => (
              <ToggleButtonGroup
                color={!!fieldError ? "error" : "secondary"}
                value={value}
                {...inputProps}
                disabled={readOnly}
                onChange={(_e: React.SyntheticEvent, value: KpiReleaseType) => handleReleaseTypeChange(value, onChange)}
                exclusive
              >
                <ToggleButton value="Emission">Emissions</ToggleButton>
                <ToggleButton value="Spill">Spill</ToggleButton>
              </ToggleButtonGroup>
            )}
          </FormItemLayout>
        );
      }}
    />
  );
}

export default ReleaseTypeFormItem;
