import { FunctionComponent } from "react";
import { InputBase, Paper, Stack } from "@mui/material";
import { SearchIcon } from "icons/SearchIcon";
import { IrisColors } from "app/mui/theme";

interface CustomerAndSiteSearchFilterProps {
   searchTerm: string;
   setSearchTerm: (_: string) => void;
}

export const CustomerAndSiteSearchFilter: FunctionComponent<CustomerAndSiteSearchFilterProps> = (props) => {
   return (
      <Paper
         component="form"
         sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 600,
            maxWidth: "100%",
            svg: { fill: IrisColors.softDark, width: "24px", height: "24px" },
         }}
      >
         <Stack
            direction="row"
            spacing={2}
            sx={{
               p: "6px",
            }}
            alignItems="center"
         >
            <SearchIcon />
            <InputBase
               autoFocus
               sx={{ ml: 1, flex: 1 }}
               placeholder="Filter sites or customers"
               value={props.searchTerm}
               onChange={(e) => props.setSearchTerm(e.target.value)}
            />
         </Stack>
      </Paper>
   );
};
