import { Box, Collapse, Grid, Typography } from "@mui/material";
import CurrencyFormItem from "app/mui/forms/Input/CurrencyFormItem";
import { NumberFormItem } from "app/mui/forms/Input/NumberFormItem";
import { RadioYesNoFormItem } from "app/mui/forms/Radio/RadioYesNoFormItem";
import { formatCurrency } from "common";
import { EffectiveRateTooltip } from "kpis/formFields/EffectiveRateTooltip";
import { KpiHelpTooltip } from "kpis/formFields/KpiHelpTooltip";
import { KpiCommodityUnitsForProduction, KpiValueModel } from "kpis/models";
import {
  KpiOngoingMaintenanceSavingsOrProductionImprovement,
  KpiOngoingMaintenanceSavingsOrProductionImprovementViewModel,
} from "kpis/models/KpiOngoingMaintenanceSavingsOrProductionImprovement";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { EditKpiFormProps } from "../EditKpiFormProps";
import { KpiGridStyles } from "../KpiGridStyles";
import { CurrencyTimeUnitFormItem } from "../formItems/CurrencyTimeUnitFormItem";
import ProductionRateFormItem from "../formItems/ProductionRateFormItem";
import { RemoteHoursFormItem } from "../formItems/RemoteHoursFormItem";
import { SitePersonnelLabourFormItem } from "../formItems/SitePersonnelLabourFormItem";
import { SpecialistLabourFormItem } from "../formItems/SpecialistLabourFormItem";
import KpiDivider from "../sections/KpiDivider";
import { SwitchFormItem } from "app/mui/forms/Toggle/SwitchFormItem";
import { KpiCommentsFormItem } from "../formItems/KpiCommentsFormItem";

interface KpiOngoingMaintenanceSavingsOrProductionImprovementDetailsProps extends EditKpiFormProps {}

function KpiOngoingMaintenanceSavingsOrProductionImprovementDetails(
  props: KpiOngoingMaintenanceSavingsOrProductionImprovementDetailsProps
) {
  const { watch } = useFormContext();

  const kpi = useMemo(
    () =>
      new KpiOngoingMaintenanceSavingsOrProductionImprovementViewModel(
        props.detail.model as KpiOngoingMaintenanceSavingsOrProductionImprovement,
        watch
      ),
    [props.detail.model, watch]
  );
  const isKpiTemplate = props.record === undefined;
  const hasTemplateComments =
    isKpiTemplate || (!!(props.model as KpiValueModel).kpiTemplateId && !!props.model.templateComments);

  const { gridRowProps, gridContainerProps, primaryDividerTitleProps } = KpiGridStyles;

  const inputProps = useMemo(() => ({ readOnly: props.readOnly }), [props.readOnly]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Box width="min-content">
              <SwitchFormItem
                label="Simplified&nbsp;Version"
                fieldName="isSimplifiedVersion"
                {...inputProps}
                readOnly={kpi.isSimplifiedVersionLockedValue || inputProps.readOnly}
                tip={
                  <KpiHelpTooltip
                    type={kpi.isSimplifiedVersionLockedValue ? "simplifiedViewLocked" : "simplifiedView"}
                  />
                }
              />
            </Box>
          </Grid>
          {kpi.isSimplifiedVersionValue && (
            <>
              <Grid container {...gridContainerProps}>
                <Grid item xs={12} {...gridRowProps}>
                  <KpiDivider
                    left={<Typography {...primaryDividerTitleProps}>Cost to Implement</Typography>}
                    right={
                      <CurrencyFormItem
                        fieldName="simplifiedCostToImplement"
                        label={undefined}
                        numberFormatInputProps={{ placeholder: "0" }}
                        {...inputProps}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} {...gridRowProps}>
                  <KpiDivider
                    left={<Typography {...primaryDividerTitleProps}>Maintenance Savings</Typography>}
                    right={
                      kpi.maintenanceSavingsIsOngoingValue ? (
                        <CurrencyFormItem
                          fieldName="simplifiedMaintenanceSavings"
                          label={undefined}
                          numberFormatInputProps={{ placeholder: "0" }}
                          {...inputProps}
                        />
                      ) : (
                        <Typography {...primaryDividerTitleProps}>$0</Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioYesNoFormItem
                    fieldName="maintenanceSavingsIsOngoing"
                    label="Ongoing savings?"
                    showYesFirst
                    tip={<KpiHelpTooltip type="ongoingMaintenanceCostSavings" />}
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={12} {...gridRowProps}>
                  <KpiDivider
                    left={<Typography {...primaryDividerTitleProps}>Production Improvement</Typography>}
                    right={
                      kpi.productionImprovementIsOngoingValue ? (
                        <CurrencyFormItem
                          fieldName="simplifiedProductionImprovement"
                          label={undefined}
                          numberFormatInputProps={{ placeholder: "0" }}
                          {...inputProps}
                        />
                      ) : (
                        <Typography {...primaryDividerTitleProps}>$0</Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioYesNoFormItem
                    fieldName="productionImprovementIsOngoing"
                    label="Ongoing savings?"
                    showYesFirst
                    tip={<KpiHelpTooltip type="ongoingProductionImprovements" />}
                    {...inputProps}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {!kpi.isSimplifiedVersionValue && (
            <>
              <Grid container {...gridContainerProps}>
                <Grid item xs={12}>
                  <KpiDivider
                    left={<Typography {...primaryDividerTitleProps}>Cost to Implement</Typography>}
                    right={
                      <Typography {...primaryDividerTitleProps}>
                        {formatCurrency(kpi.calculatedCostToImplementFieldValue ?? 0, true)}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={6} {...gridRowProps}>
                  <SpecialistLabourFormItem
                    hourlyRate={kpi.spartanRatePerHourFieldValue ?? 0}
                    fieldName="spartanHours"
                    label="Specialist Labour"
                    tip={
                      <EffectiveRateTooltip
                        isKpiTemplate={!props.record}
                        rate={kpi.spartanRatePerHourFieldValue ?? 0}
                        rateEffectiveDate={kpi.model.rateEffectiveDate}
                        rateEffectiveDateType={kpi.model.rateEffectiveDateType}
                        rateType="Specialist"
                      />
                    }
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={12} lg={6} {...gridRowProps}>
                  <RemoteHoursFormItem
                    fieldName="remoteHours"
                    hoursFieldName="spartanHours"
                    label="Remote Hours"
                    tip={<KpiHelpTooltip type="remoteHours" />}
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SitePersonnelLabourFormItem
                    hourlyRate={kpi.model.customerRatePerHour ?? 0}
                    fieldName="customerHours"
                    label="Site personnel Labour"
                    tip={
                      <EffectiveRateTooltip
                        isKpiTemplate={!props.record}
                        rate={kpi.model.customerRatePerHour}
                        rateEffectiveDate={kpi.model.rateEffectiveDate}
                        rateEffectiveDateType={kpi.model.rateEffectiveDateType}
                        rateType="Site personnel"
                      />
                    }
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={0} lg={6} />
                <Grid item xs={12} lg={4}>
                  <CurrencyFormItem
                    fieldName="equipmentAndMaterialsCost"
                    label="Equipment Cost"
                    tip={<KpiHelpTooltip type="equipmentAndMaterialsCost" />}
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <KpiDivider
                    left={<Typography {...primaryDividerTitleProps}>Maintenance Savings</Typography>}
                    right={
                      <Typography {...primaryDividerTitleProps}>
                        {formatCurrency(kpi.calculatedMaintenanceAnnualSavingsFieldValue ?? 0, true)}&nbsp;per&nbsp;year
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioYesNoFormItem
                    fieldName="maintenanceSavingsIsOngoing"
                    label="Ongoing savings?"
                    showYesFirst
                    tip={<KpiHelpTooltip type="ongoingMaintenanceCostSavings" />}
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={kpi.maintenanceSavingsIsOngoingValue}>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <CurrencyTimeUnitFormItem
                          label="Costs before implementation"
                          currencyFieldName="maintenanceCostBeforeImplementation"
                          timeUnitFieldName="maintenanceCostTimeUnit"
                          timeUnitProps={{ mode: "Per" }}
                          tip={<KpiHelpTooltip type="costsBeforeImplementation" />}
                          {...inputProps}
                        />
                      </Grid>
                      <Grid item xs={0} lg={6} />
                      <Grid item xs={12} lg={6} {...gridRowProps}>
                        <CurrencyTimeUnitFormItem
                          label="Projected cost after implementation"
                          currencyFieldName="maintenanceCostAfterImplementation"
                          timeUnitFieldName="maintenanceCostTimeUnit"
                          timeUnitProps={{ mode: "Per", readOnly: true }}
                          tip={null}
                          {...inputProps}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <KpiDivider
                    left={<Typography {...primaryDividerTitleProps}>Production Improvement</Typography>}
                    right={
                      <Typography {...primaryDividerTitleProps}>
                        {formatCurrency(kpi.model.calculatedProductionIncrease, true)}&nbsp;per&nbsp;year
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioYesNoFormItem
                    fieldName="productionImprovementIsOngoing"
                    label="Ongoing savings?"
                    showYesFirst
                    tip={<KpiHelpTooltip type="ongoingProductionImprovements" />}
                    {...inputProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={kpi.productionImprovementIsOngoingValue}>
                    <Grid container>
                      <Grid item xs={4}>
                        <NumberFormItem
                          fieldName="commodityPricePerUnit"
                          label="Price per 1 unit"
                          tip={<KpiHelpTooltip type={"commodityPricePerUnitOngoing"} />}
                          {...inputProps}
                        />
                      </Grid>
                      <Grid item xs={8} />
                      <Grid item xs={12} {...gridRowProps}>
                        <ProductionRateFormItem
                          label="Production before implementation (quantity/units/product/rate)"
                          quantityFieldName="productionRateBeforeImplementation"
                          unitFieldName="commodityUnit"
                          productFieldName="commodityType"
                          rateFieldName="commodityProductionRateTimeUnit"
                          units={KpiCommodityUnitsForProduction}
                          productionRateProps={{ mode: "Per" }}
                          {...inputProps}
                        />
                      </Grid>
                      <Grid item xs={12} {...gridRowProps}>
                        <ProductionRateFormItem
                          label="Projected production after implementation (quantity/units/product/rate)"
                          quantityFieldName="productionRateAfterImplementation"
                          unitFieldName="commodityUnit"
                          productFieldName="commodityType"
                          rateFieldName="commodityProductionRateTimeUnit"
                          units={KpiCommodityUnitsForProduction}
                          productionRateProps={{ mode: "Per" }}
                          quantityOnly
                          {...inputProps}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container rowSpacing={2}>
            {hasTemplateComments && (
              <Grid item xs={12}>
                <KpiCommentsFormItem
                  label="Comments"
                  fieldName="templateComments"
                  alwaysShowInput
                  readOnly={!isKpiTemplate}
                />
              </Grid>
            )}

            {!isKpiTemplate && (
              <Grid item xs={12}>
                <KpiCommentsFormItem label="Additional Comments" fieldName="comments" readOnly={!props.userCanEdit} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default KpiOngoingMaintenanceSavingsOrProductionImprovementDetails;
