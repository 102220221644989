import { theme } from "app/mui/theme";
import Highlighter, { HighlighterProps } from "react-highlight-words";

interface ThemeHighlighterProps extends HighlighterProps {
	isDarkTheme: boolean;
}

const darkThemeHighlight = {
	backgroundColor: "#c2e1fc",
	color: theme.palette.primary.main
}

export const ThemeHighlighter = (props: ThemeHighlighterProps) => {

	const { highlightStyle, ...rest } = props;
	const themeHighlight = props.isDarkTheme ? darkThemeHighlight : {};

	return (
		<Highlighter
			highlightStyle={{ ...themeHighlight, ...highlightStyle }}
			{...rest}
		/>
	);
};
