import { GridActionsColDef, GridColDef, GridRowParams, GridValidRowModel } from "@mui/x-data-grid-pro";
import { useUserContext } from "app/contexts";
import { ActionDispatcherApi, useActionDispatcherContext } from "app/mui/ActionDispatcher";
import { ActionMenuActionType } from "app/mui/ActionsDropdownMenu";
import { useMemo } from "react";
import { UserPermissions } from "users";
import { GetActions, GetTableRowActions } from "../metadata";
import { CreateGridActionCell } from "./tableRowAction";

type creatorFunction = (actionDispatcher: ActionDispatcherApi) => (GridColDef | GridActionsColDef)[];
type actionCreatorFunction<T> = (actionDispatcher: ActionDispatcherApi, permissions: UserPermissions) => T;

export const createStaticColumns = (fn: creatorFunction) => {
  return fn;
};

export const useColumns = (fn: creatorFunction, api: ActionDispatcherApi) => {
  const columns = useMemo(() => fn(api), []);
  return columns;
};

export function createStaticRowActions<T extends GridValidRowModel>(fn: actionCreatorFunction<GetTableRowActions<T>>) {
  return fn;
}

export const useStaticRowActions = (
  fn: actionCreatorFunction<GetTableRowActions<any>>,
  api?: ActionDispatcherApi
): GetActions => {
  const { userPermissions } = useUserContext();
  const apiFromContext = useActionDispatcherContext({ unsafe: true });

  const apiToUse = api ?? apiFromContext;

  if (!apiToUse) {
    throw "Attempted to use hook 'useStaticRowActions' without either providing an ActionDispatcherApi or using it inside an ActionDispatcherProvider context.";
  }

  const getActionsFunction = useMemo(() => fn(apiToUse, userPermissions), []);

  return (params) => {
    return getActionsFunction(params)
      .map((item) => CreateGridActionCell(item))
      .filter((item) => item != null) as any; // typescript is being dumb...
  };
};

export const createStaticActions = (fn: actionCreatorFunction<ActionMenuActionType[]>) => {
  return fn;
};

export const useStaticActions = (
  fn: actionCreatorFunction<ActionMenuActionType[]>,
  api?: ActionDispatcherApi,
  deps?: any[]
): ActionMenuActionType[] => {
  const { userPermissions } = useUserContext();
  const apiFromContext = useActionDispatcherContext({ unsafe: true });

  const apiToUse = api ?? apiFromContext;

  if (!apiToUse) {
    throw "Attempted to use hook 'useStaticActions' without either providing an ActionDispatcherApi or using it inside an ActionDispatcherProvider context.";
  }

  const getActionsFunction = useMemo(() => fn(apiToUse, userPermissions), deps ? deps : []);
  return getActionsFunction;
};
