import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import SplitButton from "app/mui/SplitButton";
import { useNavigate } from "react-router-dom";
import { formatRoutePath, Routes, useSiteContext } from "app";
import { PackagedSolutionTypeValues } from "@bluemarvel/iris-common/model";
import { useCreatePackagedSolutionRequest } from "packagedSolution/api";

interface CreatePackagedSolutionDialogProps extends BaseDialogProps {}

const validationSchema = yup.object({
  name: yup.string().nullable().required("required"),
  packagedSolutionType: yup.string().nullable().required("required"),
});

export const CreatePackagedSolutionDialog = (props: CreatePackagedSolutionDialogProps) => {
  const navigate = useNavigate();
  const { currentSiteWithNavigation } = useSiteContext();

  const createPackagedSolutionRequest = useCreatePackagedSolutionRequest();
  const methods = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (options?: { navigate?: boolean }) => {
    if (!currentSiteWithNavigation) return;

    methods.handleSubmit((values) => {
      createPackagedSolutionRequest
        .call({
          siteId: currentSiteWithNavigation.id,
          packagedSolutionType: values.packagedSolutionType,
          name: values.name,
        })
        .then((packagedSolution) => {
          if (options?.navigate) {
            navigate(formatRoutePath(Routes.PackagedSolution, packagedSolution));
          } else {
            props.onClose();
            window.location.reload();
          }
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Create Packaged Solution
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "250px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <TextFieldFormItem label="Name" fieldName="name" />
            <AutocompleteFormItem
              autocompleteProps={{ disablePortal: false }}
              label="Packaged Solution Type"
              fieldName="packagedSolutionType"
              options={PackagedSolutionTypeValues.map((type) => ({ id: type, label: type }))}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained" }}
            primaryButton={<Button onClick={() => onSubmit({ navigate: true })}>Create And Open</Button>}
            additionalActions={[<MenuItem onClick={() => onSubmit()}>Create</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
