import React, { useEffect } from "react";
import { useState } from "react";
import { Label } from "reactstrap";

export interface FileInputProps {
   onFilesSelected: (files: FileList) => void;
   disabled?: boolean;
   accept?: string;
   className?: string;
   multiple?: boolean;
   children?: React.ReactNode;
   incrementToClearFile?: number;
}

export const FileInput: React.FunctionComponent<FileInputProps> = (props) => {
   const [randomId, setRandomId] = useState("0");
   const id = `file-input-${randomId}`;
   useEffect(() => {
      setRandomId((Math.random() * 1000000).toFixed(0));
   }, [props.incrementToClearFile]);
   return (
      <div style={{ position: "relative", cursor: "pointer" }} className={props.className}>
         <input
            type="file"
            key={id}
            accept={props.accept ?? "image/*"}
            name={id}
            id={id}
            onChange={(e) => {
               !!e.target.files && e.target.files?.length > 0 && props.onFilesSelected(e.target.files);
            }}
            disabled={props.disabled}
            style={{
               position: "absolute",
               overflow: "hidden",
               width: "0.1px",
               height: "0.1px",
               opacity: 0,
               zIndex: -1,
            }}
            multiple={props.multiple}
         />
         <Label
            for={id}
            style={{
               cursor: "pointer",
               margin: 0,
               display: "flex",
            }}
         >
            {props.children}
         </Label>
      </div>
   );
};
