import { useSiteIdFromRoute } from "app";
import classNames from "classnames";
import { Validity } from "common";
import { dateFormat } from "common/formats";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
import {
   useGetFilteredRecordsEventDigestsRequest,
} from "recordsEvents";

interface Props {
   value: number | null;
   showValidity: boolean;
   validity: Validity;
   showArchived: boolean;
   setRecordsEventId: (value: number | null) => any;
   disabledRecordsEventIds?: number[] | null;
}

export const RecordsEventFormField: React.FunctionComponent<Props> = (props) => {
   const siteId = useSiteIdFromRoute()!;
   const recordsEventsQuery = useGetFilteredRecordsEventDigestsRequest(siteId);
   useEffect(() => {
      recordsEventsQuery.call({ siteId, isArchived: props.showArchived ? null : false });
   }, []);
   const recordsEvents = recordsEventsQuery.data ?? [];

   const recordsEventsOptions = recordsEvents.map((re) => ({
      value: re.id,
      label: `${re.name} (${moment(re.startDate).format(dateFormat)})`,
      isDisabled:
         !!props.disabledRecordsEventIds && _.some(props.disabledRecordsEventIds!.filter((reId) => re.id === reId)),
   }));

   const selectedOptions = recordsEventsOptions.filter((reo) => reo.value === props.value);

   return (
      <FormGroup>
         <Label>Event</Label>
         <Select
            value={selectedOptions.length === 0 ? null : selectedOptions[0]}
            options={recordsEventsOptions}
            onChange={(newEventId) =>
               props.setRecordsEventId(!!newEventId && !!newEventId.value ? newEventId.value : null)
            }
            formatOptionLabel={(reo) => reo.label}
            className={classNames("record-status-select", "react-select hide-cursor", {
               "is-invalid": props.showValidity && !props.validity.valid,
            })}
            classNamePrefix="react-select"
            isClearable={true}
         />
         <div className="invalid-feedback">Select an event.</div>
      </FormGroup>
   );
};
