import { maxBy, minBy } from "lodash";
import moment from "moment";

// generate a virtual element from coordinates for popper.
// this will be used for positioning chart tooltips on click.
export const virtualElement = (x: number, y: number) => ({
   getBoundingClientRect: () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
   }),
});

export const getStartAndEndDates = (data: { x: Date; y: number | null; }[]) => {
   const minDate = minBy(data, (d) => d.x)?.x ?? new Date();
   const maxDate = maxBy(data, (d) => d.x)?.x ?? new Date();
   const start = moment(minDate).startOf("year"); // find the min date, go to the beginning of that year.
   const end = moment(maxDate).endOf("year").add(1, "day"); // end date will be next year,
   return [start.toDate(), end.toDate()];
};
