import { Box } from "@mui/material";
import { AttachmentModel as Attachment } from "@bluemarvel/iris-common/model";
import { AttachmentParent, useUpdateAttachmentRequest } from "attachments/api";
import { attachmentIsImage } from "attachments/utilities";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AttachmentItemMenu } from "./AttachmentItemMenu";
import { AttachmentOrPlaceholder, AttachmentsGridItem } from "./AttachmentsGridItem";
import { ShowAttachmentModal } from "./ShowAttachmentModal";
import { ConfirmationModal } from "common/ConfirmationModal";
import { useNotifications } from "app";

interface AttachmentsGridProps {
  parentType: AttachmentParent;
  parentId: number;
  attachments: AttachmentOrPlaceholder[];
  onDeleteAttachment: (id: number) => void;
  canCopyAssemblyImage: boolean;
  isSelectMode: boolean;
  selectedAttachmentIds: Set<number>;
  onMultiSelectToggle: (id: number) => void;
}

export const AttachmentsGrid = (props: AttachmentsGridProps) => {
  const [openAttachment, setOpenAttachment] = useState<Attachment>();
  const [openSetPrimaryImage, setOpenSetPrimaryImage] = useState<Attachment>();
  const [attachmentForMenu, setAttachmentForMenu] = useState<Attachment>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const updateAttachmentRequest = useUpdateAttachmentRequest();
  const notifications = useNotifications();

  const toggleOpenMenu = (attachment: Attachment, event: React.MouseEvent<HTMLElement>) => {
    setAttachmentForMenu(attachment);
    setAnchorEl(event.currentTarget);
  };

  const toggleSelected = (id: number) => {
    props.onMultiSelectToggle(id);
  };

  const scrollableImages = useMemo(() => {
    return props.attachments.filter((attachment) =>
      attachment.hasOwnProperty("fileName") ? attachmentIsImage((attachment as Attachment).fileName) : false
    ) as Attachment[];
  }, [props.attachments]);

  const handleScrollImages = useCallback(
    (direction: "left" | "right", from: Attachment) => {
      const startingIndex = scrollableImages.findIndex((attachment) => attachment.id === from.id);
      let nextIndex = 0;

      if (direction === "left") {
        nextIndex = startingIndex > 0 ? startingIndex - 1 : scrollableImages.length - 1;
      } else if (direction === "right") {
        nextIndex = (startingIndex + 1) % scrollableImages.length;
      }

      const nextImage = scrollableImages[nextIndex];
      setOpenAttachment(nextImage);
    },
    [scrollableImages]
  );

  const arrowKeyHandler = useCallback(
    (event: KeyboardEvent) => {
      if (!openAttachment) return;

      switch (event.key) {
        case "ArrowLeft":
          handleScrollImages("left", openAttachment);
          break;
        case "ArrowRight":
          handleScrollImages("right", openAttachment);
          break;
        default:
      }
    },
    [handleScrollImages, openAttachment]
  );

  useEffect(() => {
    document.addEventListener("keyup", arrowKeyHandler);
    return () => document.removeEventListener("keyup", arrowKeyHandler);
  }, [arrowKeyHandler]);

  const handleSetPrimaryImage = async (copyToAsset: boolean) => {
    if (openSetPrimaryImage) {
      const request = { ...openSetPrimaryImage, isPrimaryImage: !openSetPrimaryImage.isPrimaryImage };
      try {
        await updateAttachmentRequest.call({
          attachment: request,
          rotate: false,
          copyToAsset: copyToAsset,
        });
        if (copyToAsset) {
          notifications.success("Copied assembly image to asset.");
        }
        setOpenSetPrimaryImage(undefined);
        setConfirmationModalOpen(false);
      } catch (error) {
        notifications.error("Failed to copy assembly image to asset.");
      }
    }
  };

  const handleOpenConfirmationModal = (attachment: Attachment) => {
    setOpenSetPrimaryImage(attachment);
    setConfirmationModalOpen(true);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", m: -1 }}>
      <>
        {props.attachments.map((a) => (
          <AttachmentsGridItem
            key={a.id}
            attachment={a}
            setOpenAttachment={(attachment: Attachment) => setOpenAttachment(attachment)}
            toggleOpenMenu={toggleOpenMenu}
            toggleSelected={toggleSelected}
            isSelected={props.selectedAttachmentIds.has(a.id)}
            isSelectMode={props.isSelectMode}
          />
        ))}
        {!!openAttachment && (
          <ShowAttachmentModal
            attachmentType={props.parentType}
            attachment={openAttachment}
            onClose={() => {
              setOpenAttachment(undefined);
            }}
            scrollLeft={() => {
              handleScrollImages("left", openAttachment);
            }}
            scrollRight={() => {
              handleScrollImages("right", openAttachment);
            }}
          />
        )}
        {openSetPrimaryImage && (
          <ConfirmationModal
            title="Copy assembly image to asset?"
            body="Are you sure you want to copy the assembly image to the asset?"
            show={confirmationModalOpen}
            cancel={() => setConfirmationModalOpen(false)}
            confirm={async () => {
              await handleSetPrimaryImage(true);
            }}
          />
        )}

        {attachmentForMenu && (
          <AttachmentItemMenu
            parentType={props.parentType}
            parentId={props.parentId}
            attachment={attachmentForMenu}
            anchorElement={anchorEl}
            onClose={() => setAnchorEl(null)}
            onRequestEdit={setOpenAttachment}
            onSetPrimaryImage={handleOpenConfirmationModal}
            onDeleteAttachment={props.onDeleteAttachment}
            canCopyAssembleyImage={props.canCopyAssemblyImage}
          />
        )}
      </>
    </Box>
  );
};
