import React from "react";
import { KpiTemplateScreen } from "./KpiTemplateScreen";
import { parseKpiId } from "./models";
import { Routes } from "../app/routes";
import { useGetKpiTemplateDetailRequest } from "./api";
import { LinearProgress } from "@mui/material";
import { useMatch } from "react-router-dom";

export const KpiTemplateScreenQuery: React.FunctionComponent<{}> = () => {
  const routeMatch = useMatch(Routes.KpiTemplate);
  const kpiId = parseKpiId(routeMatch!.params.id!);
  const query = useGetKpiTemplateDetailRequest(kpiId);
  const kpiTemplate = query.data;

  if (query.loading && !query.data) return <LinearProgress />;
  if (!kpiTemplate) return null;
  return (
    <div className="legacy_bootstrap">
      <KpiTemplateScreen key={kpiTemplate.model.rowId} kpiTemplate={kpiTemplate} />
    </div>
  );
};
