import * as yup from "yup";

export const recordsEventBaseSchemaValidation = yup.object({
  startDate: yup
    .date()
    .nullable()
    .when("endDate", {
      is: (value: Date) => !!value,
      then: yup.date().max(yup.ref("endDate"), "Start date must be on or before end date"),
    })
    .required("Start date is required"),
  endDate: yup.date().nullable().min(yup.ref("startDate"), "End date must be on or after start date"),
});

export const recordsEventCreateSchemaValidation = recordsEventBaseSchemaValidation.shape({
  name: yup.string().nullable().required("required"),
});
