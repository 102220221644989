import React from "react";
import { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import Select from "react-select";
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";

interface TextAreaInputProps {
   field: any;
   label: string;
   userCanEdit: boolean;
}

export const TextAreaInput = (props: TextAreaInputProps) => {
   const {
      register,
      formState: { errors },
   } = useFormContext();

   const { ref, ...fieldProps } = register(props.field);

   const userCanEdit = { readOnly: props.userCanEdit === false };

   const errorMessage = errors[props.field]?.message as string | null | undefined;

   return (
      <FormGroup>
         <Label>{props.label}</Label>
         <Input type="textarea" {...fieldProps} innerRef={ref} {...userCanEdit} />
         <div className="form-error">{errorMessage}</div>
      </FormGroup>
   );
};
