import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { TrafficLight } from "../health/TrafficLight";
import { formatRoutePath, Routes } from "app/routes";
import { VisualIntegrityBadge } from "../health/VisualIntegrityBadge";
import { VisualIntegrity } from "records";
import { IrisTooltip } from "app";
import InfoIcon from "@mui/icons-material/Info";
import { IrisColors } from "app/mui/theme";

interface VisualIntegrityCardProps {
  visualIntegrity?: VisualIntegrity;
  previousRecordDescription?: string;
  previousRecordId?: number;
  siteId: number;
}

export const VisualIntegrityCard = (props: VisualIntegrityCardProps) => {
  const { visualIntegrity, previousRecordDescription, previousRecordId, siteId } = props;

  if (!props.visualIntegrity || !props.visualIntegrity?.overallScore) {
    return null;
  }

  return (
    <Paper sx={{ p: 2, height: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography variant="h6" textTransform="uppercase">
          Visual Integrity
        </Typography>
        {previousRecordId && (
          <Typography component="span" variant="body2">
            <Link
              component={RouterLink}
              to={formatRoutePath(Routes.Record, {
                siteId: siteId,
                id: previousRecordId,
              })}
              color="secondary"
              sx={{ fontWeight: "bold" }}
            >
              {previousRecordDescription}
            </Link>
          </Typography>
        )}
        <IrisTooltip
          title={
            <>
              <p>Visual integrity is a health metric based on the non-intrusive inspection of the physical asset.</p>
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                <li>Good &ndash; no areas of concern present</li>
                <li>
                  Minor &ndash; small concerns that could be rectified through planned maintenance and/or design change
                </li>
                <li>
                  Moderate &ndash; areas of elevated concern that may be harmful to equipment, personnel, or environment
                </li>
                <li>
                  Severe &ndash; significant concerns that require immediate attention, to prevent arm to equipment,
                  personnel, environment, or ability to operate safely
                </li>
              </ul>
            </>
          }
        >
          <span>
            <InfoIcon sx={{ color: IrisColors.gray500, verticalAlign: "middle", fontSize: "1.7rem" }} />
          </span>
        </IrisTooltip>
      </Stack>
      <Stack justifyContent="space-between" direction="row" height="100%" alignItems="center">
        <Stack alignItems="center" sx={{ flexBasis: "125px", flexShrink: 0 }}>
          <TrafficLight score={visualIntegrity?.overallScore!} />
          <VisualIntegrityBadge score={visualIntegrity?.overallScore} />
        </Stack>
        <Box>{/* placeholder */}</Box>
      </Stack>
    </Paper>
  );
};
