import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { Asset, ElementSpec } from "assets/models/asset";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateElementSpecificationRequest } from "assets/api";

interface ElementSpecificationCardProps {
  asset: Asset;
  element: ElementSpec;
}

export const ElementSpecificationCard = (props: ElementSpecificationCardProps) => {
  const updateSpecificationRequest = useUpdateElementSpecificationRequest();

  const defaultValues = makeSafeForReactHookForm(props.element);
  const methods = useForm({ defaultValues });

  const updateElement = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <SpecOptionLoader type="ValveOrElementSize">
      {(options) => <EditableAutocompleteFormItem label="Size" fieldName="size" {...options} />}
    </SpecOptionLoader>,
    <SpecOptionLoader type="PressureClass">
      {(options) => <EditableAutocompleteFormItem label="Pressure class" fieldName="pressureClass" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="k-Factor" fieldName="kFactor" />,
    <EditableTextFieldFormItem label="Cal number" fieldName="calNumber" />,
  ];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave onSubmit={updateElement} />
      <AssetSpecificationCard title="Element" specs={fields} />
    </PermissionAwareFormProvider>
  );
};
