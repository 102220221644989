import * as React from "react";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { Controller } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useFormEditPermission } from "./PermissionAwareFormProvider";
import { BaseFormItemProps } from "./Base/BaseFormItemProps";

export interface RadioGroupFormItemProps extends BaseFormItemProps {
  options: { value: string; label: string }[];
}

export const RadioGroupFormItem = (props: RadioGroupFormItemProps) => {
  const { readOnly, fieldName, label, options, ...formItemLayoutProps } = props;
  const { hasPermissionToEdit } = useFormEditPermission();
  return (
    <Controller
      name={props.fieldName}
      defaultValue={false}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <FormItemLayout
            controlled
            variant="unstyled"
            hint={props.hint}
            label={label}
            error={errors[fieldName]?.message?.toString()}
            formControlProps={{ disabled: readOnly }}
            fullWidth={false}
            {...formItemLayoutProps}
          >
            {(inputProps) => (
              <RadioGroup {...inputProps} onChange={(e) => onChange(e.target.value)} value={value}>
                {props.options.map(({ value, label }) => (
                  <FormControlLabel
                    value={value}
                    control={<Radio />}
                    label={label}
                    disabled={!hasPermissionToEdit || readOnly}
                  />
                ))}
              </RadioGroup>
            )}
          </FormItemLayout>
        );
      }}
    />
  );
};

{
  /* <FormControlLabel
               control={
                  <RadioGroup {...field}>
                     {props.options.map(({ value, label }) => (
                        <FormControlLabel
                           value={value}
                           control={<Radio />}
                           label={label}
                           disabled={!hasPermissionToEdit}
                        />
                     ))}
                  </RadioGroup>
               }
               label={props.label}
            />
          </FormItemLayout> */
}
