import { Stack, Typography } from "@mui/material";

import { AssetIcon } from "icons/AssetIcon";

interface AssetTagProps {
  tag: string;
}

export const AssetTag = (props: AssetTagProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <AssetIcon sx={{ fill: (theme) => theme.palette.primary.main, fontSize: 28 }} />
      <Typography variant="h5">{props.tag}</Typography>
    </Stack>
  );
};
