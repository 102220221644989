import moment from "moment";
import { Record } from "records";

export function recordBeforeSummaryDate(record: Record, summaryDate: Date | string) {
   if (record.eventDate === null) return false;

   const summaryDateMidnight = moment(summaryDate).startOf("day");
   const recordEventDateMidnight = moment(record.eventDate!).startOf("day");
   return recordEventDateMidnight.isSameOrBefore(summaryDateMidnight);
}
