import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Input, MenuItem, Select, TextField } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { CascadingSelectFormItem, CascadingSelectOption } from "app/mui/forms/Select/CascadingSelectFormItem";
import _ from "lodash";
import { ComponentTemplate, ComponentType } from "machinery/models/componentTemplate";
import { useMemo } from "react";
import { DeepRequired, FieldErrorsImpl, UseFormReturn } from "react-hook-form";

interface IMachineryCreateFormProps {
  componentTemplates: ComponentTemplate[];
  componentTemplate: ComponentTemplate | undefined;
  methods: UseFormReturn<any>;
  index: number;
  fieldArrayName: string;
  onRemove: (index: number) => void;
}

interface TypeOption extends CascadingSelectOption {
  maxTabs: number;
  selectable: boolean;
  tabFieldName: string;
}

function flattenTypeOptions(types: ComponentType[], activePath: string[]): TypeOption[] {
  if (types.length === 0) {
    return [];
  }
  let newTypes: TypeOption[] = [];
  types.forEach((type) => {
    newTypes.push({
      path: [...activePath, type.name],
      id: type.id,
      maxTabs: type.maxTabs,
      tabFieldName: type.tabFieldName,
      selectable: type.subtypes.length === 0,
      sortOrder: type.sortOrder ?? 0,
    });
    newTypes = [...newTypes, ...flattenTypeOptions(type.subtypes, [...activePath, type.name])];
  });
  return newTypes;
}

export const MachineryCreateForm = (props: IMachineryCreateFormProps) => {
  const { componentTemplates, componentTemplate, methods, index, fieldArrayName, onRemove } = props;

  const typeSelectOptions = useMemo(() => {
    const typeOptions = flattenTypeOptions(componentTemplate?.types ?? [], []);
    const hasTypes = typeOptions.length > 0;
    methods.setValue(`${fieldArrayName}.${index}.hasTypes`, typeOptions.length > 0);
    if (hasTypes) {
      methods.setValue(`${fieldArrayName}.${index}.types`, "");
    }
    return typeOptions;
  }, [componentTemplate]);

  const activeTypeId = methods.watch(`${fieldArrayName}.${index}.typeId`);

  const activeType: TypeOption | null = useMemo(() => {
    const selectedType = typeSelectOptions.find((x) => x.id === activeTypeId) ?? null;
    const hasTabs = selectedType?.maxTabs != null;
    methods.setValue(`${fieldArrayName}.${index}.hasTabs`, hasTabs);
    if (hasTabs) {
      methods.setValue(`${fieldArrayName}.${index}.tabs`, undefined);
    }
    return selectedType;
  }, [typeSelectOptions, activeTypeId]);

  const componentErrors = useMemo(() => {
    const errors = (methods.formState.errors[fieldArrayName] as unknown as FieldErrorsImpl<DeepRequired<any>>)?.[index];
    return errors ?? {};
  }, [methods.formState.errors]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <FormItemLayout controlled label={""} formControlProps={{ disabled: true }}>
          {(inputProps) => (
            <TextField
              {...inputProps}
              value={componentTemplates.find((o) => o.id === componentTemplate?.id)?.name}
              disabled
            />
          )}
        </FormItemLayout>
      </Grid>
      {typeSelectOptions.length > 0 ? (
        <Grid item xs>
          <CascadingSelectFormItem
            options={typeSelectOptions}
            fieldName={`${fieldArrayName}.${index}.typeId`}
            label="Type"
            error={componentErrors["typeId"]?.message?.toString()}
          />
        </Grid>
      ) : (
        <Grid item xs></Grid>
      )}
      {activeType && activeType.maxTabs != null && (
        <Grid item xs>
          <FormItemLayout
            label={activeType?.tabFieldName}
            error={componentErrors["tabs"]?.message?.toString()}
            fieldName={`${fieldArrayName}.${index}.tabs`}
          >
            {(inputProps) => (
              <Select {...inputProps} sx={{ width: "100%" }}>
                {_.range(activeType?.maxTabs).map((o) => (
                  <MenuItem key={o} value={o + 1}>
                    {o + 1}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormItemLayout>
        </Grid>
      )}
      <Grid item width="auto" sx={{ display: "grid", placeContent: "center" }}>
        <IconButton type="button" onClick={() => onRemove(index)} sx={{ mt: "20px" }}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
