import { DataGridProProps, GridValidRowModel } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { Routes } from "app/routes";
import { LinkColumnType } from "common";

export interface DataGridWrapperProps<T extends GridValidRowModel> extends DataGridProProps<T> {
   apiRef: React.MutableRefObject<GridApiPro>;
}

export const routeForIdColumnType = (type: LinkColumnType) => {
   switch (type) {
      case LinkColumnType.Asset:
         return Routes.Asset;
      case LinkColumnType.Record:
         return Routes.Record;
      case LinkColumnType.Application:
         return Routes.Application;
      case LinkColumnType.KpiTemplate:
         return Routes.KpiTemplate;
      case LinkColumnType.Summary:
         return Routes.Summary;
      case LinkColumnType.RecordEvent:
         return Routes.RecordsEvent;
      default:
         return ""; // all other routes not supported.
   }
};
