import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, Typography } from "@mui/material";
import { Asset } from "assets/models/asset";
import { FormProvider, useForm } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import SplitButton from "app/mui/SplitButton";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { useSiteContext } from "app";
import { useDuplicateAssetRequest } from "assets/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface DuplicateDialogProps extends BaseDialogProps {
  asset: Asset;
}

const validationSchema = yup.object({
  newTag: yup.string().nullable().required("required"),
});

export const DuplicateDialog = (props: DuplicateDialogProps) => {
  const { currentSiteWithNavigation } = useSiteContext();

  const duplicateAssetRequest = useDuplicateAssetRequest();

  const methods = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (options?: { navigate?: boolean }) => {
    methods.handleSubmit((values) => {
      duplicateAssetRequest
        .call({
          assetId: props.asset.id,
          newTag: values.newTag,
        })
        .then((newAsset) => {
          if (options?.navigate) {
            window.open(currentSiteWithNavigation!.routeTo.Asset({ id: newAsset.id }));
          }
          props.onClose();
        });
    })();
  };

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6">Duplicate asset</Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <TextFieldFormItem label="New tag" fieldName="newTag" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained" }}
            primaryButton={<Button onClick={() => onSubmit({ navigate: true })}>Duplicate and Open</Button>}
            additionalActions={[<MenuItem onClick={() => onSubmit()}>Duplicate and exit</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
