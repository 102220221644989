import * as React from "react";
import { Card, CardTitle, Button, Label } from "reactstrap";
import classNames from "classnames";
import { Record, useGetRecordsByIdQuery } from "records";
import _ from "lodash";
import { formatRoutePath, Routes } from "app";
import { Link } from "react-router-dom";
import { DeleteIcon } from "icons/DeleteIcon";
import moment from "moment";
import { dateFormat } from "./formats";
import { KpiValueCommon, KpiValueDigest, orderByValueKpiType, toKpiTypeDisplayName } from "kpis";
import { Loading } from "app/bs";
import { KpiSummary } from "kpis/KpiSummary";
import { useSiteIdFromRoute } from "../app";

export interface KpiValueCommonWithRecord extends KpiValueCommon {
  record: Record;
}

interface Props {
  kpiValues: KpiValueDigest[];
  hideDetails?: boolean;
  ineligibleBecause?: (kpiValue: KpiValueDigest) => string | null;
  remove?: null | ((kpiValue: KpiValueDigest) => void);
}

export const SelectedKpiValues: React.FunctionComponent<Props> = (props) => {
  const siteId = useSiteIdFromRoute()!;

  const recordIds = props.kpiValues.map((kpi) => kpi.recordId);
  const getRecordsRequest = useGetRecordsByIdQuery(recordIds);
  const records = getRecordsRequest.data ?? [];

  if (getRecordsRequest.loading) return <Loading />;

  const kpiValuesWithIneligibility = props.kpiValues.map((kpiValue) => ({
    kpiValue,
    ineligibleBecause: !!props.ineligibleBecause ? props.ineligibleBecause(kpiValue) : null,
  }));
  const eligibleKpiValues = kpiValuesWithIneligibility.filter((kpiValue) => !!!kpiValue.ineligibleBecause);

  const orderedKpiValues = _.sortBy(props.kpiValues, orderByValueKpiType);
  const kpiValuesGroupedByRecord = _.sortBy(
    _.values(_.groupBy(orderedKpiValues, (kpiValue) => kpiValue.recordId))
      .map((group) => ({
        record: records.find((r) => r.id === group[0].recordId)!,
        kpiValues: group,
      }))
      .filter((g) => !!g.record),
    (group) => group.record.model.eventDate,
    "desc"
  );
  const kpiRecordsGroupedByAsset = _.orderBy(
    _.values(
      _.groupBy(
        kpiValuesGroupedByRecord.map((recordGroup) => recordGroup.record),
        (record) => _.sortBy(record.model.assets.map((ar) => ar.tag)).join()
      )
    ).map((records) => ({ key: records[0], records })),
    (group) => _.sortBy(group.key.model.assets.map((ar) => ar.tag)).join()
  );

  return (
    <Card body className="record-selector">
      <CardTitle className={classNames("d-flex", "align-items-center")}>
        <div className="title no-transform">KPIs</div>
        <div className="ml-auto counter">
          <Label>Count:</Label>
          {props.kpiValues.length > eligibleKpiValues.length && (
            <span style={{ textDecoration: "line-through", marginRight: "1em" }}>
              &nbsp;{props.kpiValues.length}&nbsp;
            </span>
          )}
          {eligibleKpiValues.length}
        </div>
      </CardTitle>

      <div className="items-by-category">
        {kpiRecordsGroupedByAsset.map((assetsGroup, i) => {
          return (
            <div className="item-group" key={i}>
              <div className="category">
                {_.orderBy(assetsGroup.key.model.assets, (a) => a.tag).map((asset, i) => (
                  <span key={i}>
                    <Link target="_blank" to={formatRoutePath(Routes.Asset, { siteId, id: asset.id })}>
                      {asset.tag}
                    </Link>
                    {i < assetsGroup.key.model.assets.length - 1 && <span>,&nbsp;</span>}
                  </span>
                ))}
              </div>
              <div className="items">
                {assetsGroup.records.map((record) => {
                  return (
                    <div className="item-group" key={record.id}>
                      <div className="category">
                        <Link target="_blank" to={formatRoutePath(Routes.Record, record)}>
                          {record.model.description} (
                          {!!record.model.eventDate && moment(record.model.eventDate).format(dateFormat)})
                        </Link>
                      </div>
                      {!!!props.hideDetails && (
                        <div className="items">
                          {kpiValuesGroupedByRecord
                            .filter((recordGroup) => recordGroup.record.id === record.id)[0]
                            .kpiValues.map((kpiValue) => {
                              const ineligibleBecause = kpiValuesWithIneligibility.filter(
                                (kpiValueWI) => kpiValueWI.kpiValue.id === kpiValue.id
                              )[0].ineligibleBecause;
                              return (
                                <div className={classNames("item")} key={kpiValue.id}>
                                  <div
                                    className={classNames(
                                      "item-identifier",
                                      {
                                        "item-danger": !!ineligibleBecause,
                                      },
                                      {
                                        "line-through": !!ineligibleBecause,
                                      }
                                    )}
                                    title={ineligibleBecause || ""}
                                  >
                                    {toKpiTypeDisplayName(kpiValue.kpiType)}
                                  </div>
                                  {!!ineligibleBecause && (
                                    <div className="item-danger" title={ineligibleBecause}>
                                      {ineligibleBecause}
                                    </div>
                                  )}
                                  <div>
                                    <KpiSummary kpi={kpiValue} />
                                  </div>
                                  {!!props.remove && (
                                    <Button className="delete-button" onClick={() => props.remove!(kpiValue)}>
                                      <DeleteIcon />
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};
