import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useDeleteRecordRecommendationRequest } from "records/api";
import { Recommendation } from "records/models";

interface DeleteRecommendationDialogProps {
  onClose: () => void;
  recommendation: Recommendation;
  onDeleteRecommendation: () => void;
}

export const DeleteRecommendationDialog = (props: DeleteRecommendationDialogProps) => {
  const deleteRecommendationRequest = useDeleteRecordRecommendationRequest(props.recommendation.recordId);

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Delete &quot;{props.recommendation.title || "Blank recommendation"}&quot;?</DialogTitle>
      <DialogContent>
        <Typography variant="body2">Are you sure you want to delete this recommendation?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} disabled={deleteRecommendationRequest.loading}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteRecommendationRequest.call(props.recommendation.id).then(() => {
              props.onDeleteRecommendation();
              props.onClose();
            });
          }}
          color="error"
          variant="contained"
          disabled={deleteRecommendationRequest.loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
