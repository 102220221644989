import React, { useCallback, useRef } from "react";
import { NotificationContext } from "./NotificationContext";
import { useSnackbar, SnackbarKey } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const getDismissableAction = (closeSnackbar: (key?: SnackbarKey) => void, color: any) => (snackBarId: SnackbarKey) => {
   return (
      <IconButton onClick={() => closeSnackbar(snackBarId)}>
         <CloseIcon sx={{ color: "white" }} />
      </IconButton>
   );
};

interface DismissSnackbarTimer {
   key: SnackbarKey;
   timerId: any;
}

export const NotificationProvider = (props: { children?: React.ReactNode }) => {
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   const dismissTimers = useRef<DismissSnackbarTimer[]>([]);

   const success = useCallback((message: string) => {
      const key = enqueueSnackbar(message, {
         variant: "success",
         action: getDismissableAction(closeSnackbar, "success"),
      });

      scheduleDismiss(key);
   }, []);

   const warning = useCallback((message: string) => {
      const key = enqueueSnackbar(message, {
         variant: "warning",
         action: getDismissableAction(closeSnackbar, "error"),
      });

      scheduleDismiss(key);
   }, []);

   const error = useCallback((message: string) => {
      const key = enqueueSnackbar(message, {
         variant: "error",
         action: getDismissableAction(closeSnackbar, "error"),
      });

      scheduleDismiss(key);
   }, []);

   const info = useCallback((message: string, skipClose: boolean = false) => {
      const key = enqueueSnackbar(message, {
         variant: "info",
         persist: true,
         action: getDismissableAction(closeSnackbar, "info"),
      });

      if(!skipClose)
         scheduleDismiss(key);
         
      return key;
   }, []);

   const scheduleDismiss = (key: SnackbarKey) => {
      const timerId = setTimeout(() => {
         closeSnackbar(key);
      }, 5000);

      dismissTimers.current.push({ key, timerId });
   };

   return (
      <NotificationContext.Provider
         value={{
            success,
            warning,
            error,
            info,
            scheduleDismiss
         }}
      >
         {props.children}
      </NotificationContext.Provider>
   );
};
