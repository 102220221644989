import { Box, Paper, Skeleton, Stack, Tabs, Typography } from "@mui/material";

export const TableSkeleton = () => {
  return (
    <Stack direction="column">
      <Stack direction="row">
        <Skeleton variant="text" height="20px" width="60px" />
        <Skeleton variant="text" height="20px" width="60px" />
        <Skeleton variant="text" height="20px" width="60px" />
      </Stack>
      <Skeleton>
        <Box height="700px" width="100" />
      </Skeleton>
    </Stack>
  );
};
