import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { KpiValueModel } from "kpis/models";

interface UnlinkKpiDialogProps {
  onClose: () => void;
  kpiValueModel: KpiValueModel;
  onUnlink: (upadatedKpiModel: KpiValueModel) => void;
}

export const UnlinkKpiDialog = (props: UnlinkKpiDialogProps) => {
  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Unlink KPI from template?</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Unlinking will allow you to edit the KPI details. Any updates to the KPI template will not be reflected on
          this KPI. Any template comments will no longer be shown.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={() => {
            props.onUnlink({
              ...props.kpiValueModel,
              kpiTemplateId: null,
              name: null,
              templateComments: null,
            });

            props.onClose();
          }}
          color="error"
          variant="contained"
        >
          Unlink
        </Button>
      </DialogActions>
    </Dialog>
  );
};
