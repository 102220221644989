import * as React from "react";
import { Box, Card, CardActionArea, CardContent, Divider, IconButton, Stack, Typography } from "@mui/material";
import { AssetIcon } from "icons/AssetIcon";
import { RecordIcon } from "icons/RecordIcon";
import { SiteDigest } from "sites";
import { WithSiteRouting } from "app/SiteNavigator";
import { ApplicationIcon } from "icons/ApplicationIcon";
import { SummaryIcon } from "icons/SummaryIcon";
import { KpiIcon } from "icons/KpiIcon";
import Dotdotdot from "react-dotdotdot";
import { IrisColors } from "app/mui/theme";

export const SiteCard = React.memo(({ site }: { site: WithSiteRouting<SiteDigest> }) => {
   if (!site) {
      return null;
   }

   return (
      <Card>
         <CardActionArea href={site.routeTo.SiteDashboard()}>
            <CardContent>
               <Stack spacing={2}>
                  <Box textAlign="center" sx={{ height: "40px" }}>
                     {!!site.customer.logoUri && (
                        <img
                           src={site.customer.logoUri}
                           style={{ width: "auto", height: "100%" }}
                           loading="lazy"
                           alt={site.customer.name}
                        />
                     )}
                     {!site.customer.logoUri && (
                        <Typography variant="h6">
                           <Dotdotdot clamp={1}>{site.customer.name}</Dotdotdot>
                        </Typography>
                     )}
                  </Box>
                  <Box justifyContent="center" alignItems="center" display="flex" textAlign="center" height="60px">
                     <Typography variant="subtitle1">
                        <Dotdotdot clamp={"60px"}>{site.name}</Dotdotdot>
                     </Typography>
                  </Box>
                  <Divider />
                  <Stack direction="row" justifyContent="center" sx={{ svg: { fill: IrisColors.softDark } }}>
                     <IconButton href={site.routeTo.Assets()} tabIndex={-1}>
                        <AssetIcon />
                     </IconButton>
                     <IconButton href={site.routeTo.Records()} tabIndex={-1}>
                        <RecordIcon />
                     </IconButton>
                     <IconButton href={site.routeTo.Applications()} tabIndex={-1}>
                        <ApplicationIcon />
                     </IconButton>
                     <IconButton href={site.routeTo.Summaries()} tabIndex={-1}>
                        <SummaryIcon />
                     </IconButton>
                     <IconButton href={site.routeTo.KpiTemplates()} tabIndex={-1}>
                        <KpiIcon />
                     </IconButton>
                  </Stack>
               </Stack>
            </CardContent>
         </CardActionArea>
      </Card>
   );
});

