import { Box, Slider, styled } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { getColorForHealthByFourPointScore } from "common/utilities";
import _ from "lodash";
import { useCallback } from "react";
import { Controller, useController } from "react-hook-form";

interface HealthFormItemProps extends BaseFormItemProps {
  syncFieldName?: string;
  pristineOnChangeFieldName?: string;
}

export function HealthFormItem(props: HealthFormItemProps) {
  const { readOnly, fieldName, label, syncFieldName, pristineOnChangeFieldName, ...formItemLayoutProps } = props;

  const syncFieldControl = useController({ name: syncFieldName ?? "" });
  const pristineOnChangeControl = useController({ name: pristineOnChangeFieldName ?? "" });

  const HealthSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-valueLabel": {
      fontSize: 14,
      fontWeight: "normal",
      backgroundColor: "unset",
    },
  }));

  const getSliderMarks = () => {
    const values = [
      {
        value: 0, // "N/A"
      },
    ];

    for (let i = 1; i <= 4; i += 0.25) {
      values.push({
        value: i,
      });
    }
    return values;
  };

  const syncFieldControlOnChange = (newValue: number | number[]) => {
    syncFieldControl.field.onChange(newValue);
  };

  const pristineOnChangeControlOnChange = () => {
    if (pristineOnChangeControl.field.value === false) {
      pristineOnChangeControl.field.onChange(true);
    }
  };

  //The value of the slider is debounced to prevent the slider from being too sensitive during AutoSave
  const debouncedSyncFieldControlOnChange = useCallback(_.debounce(syncFieldControlOnChange, 500), []);

  return (
    <Controller
      name={fieldName}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <FormItemLayout
            controlled
            variant="unstyled"
            hint={props.hint}
            label={label}
            error={errors[fieldName]?.message?.toString()}
            formControlProps={{ disabled: readOnly, sx: { pt: 1 } }}
            {...formItemLayoutProps}
          >
            {(inputProps) => (
              <HealthSlider
                defaultValue={null}
                valueLabelDisplay="on"
                marks={getSliderMarks()}
                step={null}
                max={4}
                onChange={(event, newValue) => {
                  onChange(newValue);
                  if (syncFieldName) {
                    debouncedSyncFieldControlOnChange(newValue);
                  }
                  if (pristineOnChangeFieldName) {
                    pristineOnChangeControlOnChange();
                  }
                }}
                value={value}
                sx={{ mt: 1 }}
                valueLabelFormat={(value) => (
                  <Box
                    sx={{
                      color: getColorForHealthByFourPointScore(value),
                      mb: "-16px",
                      fontWeight: "bold",
                    }}
                  >
                    {value ? value.toFixed(2) : "N/A"}
                  </Box>
                )}
                {...inputProps}
              />
            )}
          </FormItemLayout>
        );
      }}
    />
  );
}
