import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useAxiosGenerateReport } from "app";
import { useAppSettingsContext } from "app/contexts/AppSettingsContext";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import { Asset } from "assets/models/asset";
import { FeatureFlagName, useFeatureEnabled } from "common";
import { AssetReportParams, generateReportUrl } from "common/reports";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

interface GenerateReportDialogProps extends BaseDialogProps {
  asset: Asset;
}

export const GenerateReportDialog: FunctionComponent<GenerateReportDialogProps> = (props) => {
  const { appSettings } = useAppSettingsContext();
  const useNodeReports = useFeatureEnabled(FeatureFlagName.UseNodeReports);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<AssetReportParams>({
    defaultValues: {
      id: props.asset.id,
      pdf: true,
      includeAssetDetails: true,
      includeSpecs: true,
      includeRecordHistory: true,
      includeRecordDetails: true,
      includeRecordRecommendations: true,
      includePictures: true,
      appendPdfAttachments: true,
    },
  });

  const generateAssetReportNode = useAxiosGenerateReport<AssetReportParams>("api/reports/asset", {
    onStartCallback: () => setIsLoading(true),
    onSuccessCallback: () => {
      setIsLoading(false);
      props.onClose();
    },
    onFailCallback: () => setIsLoading(false),
  });
  const generateAssetReportWindow = useCallback(() => {
    const reportParams = methods.getValues();
    const reportUrl = generateReportUrl("asset", reportParams, appSettings!);
    window.open(reportUrl!, "_blank");
    props.onClose();
  }, []);

  const handleSubmit = useCallback(() => {
    if (useNodeReports) {
      generateAssetReportNode.call(methods.getValues());
      return;
    }
    generateAssetReportWindow();
  }, []);

  const recordDetailsValue = methods.watch("includeRecordDetails");

  useEffect(() => {
    methods.setValue("includeRecordRecommendations", recordDetailsValue);
  }, [recordDetailsValue]);

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        variant="h6"
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Generate report
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={0}>
            <CheckboxFormItem fieldName="includeAssetDetails" label="Include Asset Details" />
            <CheckboxFormItem fieldName="includeSpecs" label="Include Asset Specifications" />
            <CheckboxFormItem fieldName="includeRecordHistory" label="Include Record History" />
            <CheckboxFormItem fieldName="includeRecordDetails" label="Include Record Details" />
            <Box sx={{ paddingLeft: 4 }}>
              <CheckboxFormItem
                fieldName="includeRecordRecommendations"
                label="Include record recommendations"
                disabled={!recordDetailsValue}
              />
            </Box>
            <CheckboxFormItem fieldName="includePictures" label="Include Photos" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF attachments" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="secondary"
            disabled={!appSettings || isLoading}
            onClick={handleSubmit}
          >
            Generate <OpenInNewIcon sx={{ pl: 1, width: "1rem" }} />
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
