import { useAuth0 } from "@auth0/auth0-react";
import GroupsIcon from "@mui/icons-material/Groups";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import Logout from "@mui/icons-material/Logout";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Divider, ListItemIcon, ListItemText, Stack, styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useSiteContext, useUserContext } from "app/contexts";
import { useAppSettingsContext } from "app/contexts/AppSettingsContext";
import { useUiThemeContext } from "app/contexts/UiThemeContext";
import {
  Routes,
  customersIdSegment,
  sitesIdSegment,
  useCustomerIdFromRoute,
  useGetDefaultLandingPageForCustomer,
} from "app/routes";
import { SearchIcon } from "icons/SearchIcon";
import * as React from "react";
import { Link, useMatch } from "react-router-dom";
import { SearchBar } from "search/mui/SearchBar";
import { AboutPopup } from "./AboutPopup";
import { SiteContextHeader } from "./SiteContextHeader";
import { SubmitFeedbackDialog } from "./SubmitFeedbackDialog";
import { Hide, Show } from "./layoutUtils/HideShowUtils";

const shouldForwardProp = (prop) => prop !== "darkTheme";

const StyledMenu = styled(Menu, { shouldForwardProp })<{ darkTheme: boolean }>(({ darkTheme, theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: darkTheme ? theme.palette.primary.main : theme.palette.background.paper,
  },
}));

const StyledMenuSection = styled(MenuItem, { shouldForwardProp })<{ darkTheme: boolean }>(({ darkTheme, theme }) => ({
  fontSize: "0.9em",
  color: darkTheme ? theme.palette.background.paper : theme.palette.primary.main,
  fontStyle: "italic",
  pb: 0,
  "&.Mui-disabled": {
    opacity: 1,
    backgroundColor: darkTheme ? theme.palette.primary.main : theme.palette.background.paper,
  },
}));

const StyledMenuIcon = styled(ListItemIcon, { shouldForwardProp })<{ darkTheme: boolean }>(({ darkTheme, theme }) => ({
  color: darkTheme ? theme.palette.background.paper : theme.palette.primary.main,
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  color: theme.palette.background.paper,
}));

const darkThemeMenuItemStyle = {
  backgroundColor: "primary.main",
  color: "background.paper",
};

const ResponsiveAppBar = () => {
  const { currentUser, userPermissions } = useUserContext();
  const [showAboutPopup, setShowAboutPopup] = React.useState<boolean>(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = React.useState<boolean>(false);
  // only show site selector on pages that are site/customer focused.
  const isSitePage = useMatch({ path: sitesIdSegment, end: false });
  const isCustomerPage = useMatch({ path: customersIdSegment, end: false });
  const shouldShowSiteSelector = Boolean(isSitePage) || Boolean(isCustomerPage);

  const currentCustomerId = useCustomerIdFromRoute();

  const currentSite = useSiteContext().currentSite;
  const currentSiteId = currentSite?.id ?? null;
  const isDarkTheme = useUiThemeContext().header === "dark";

  const defaultLandingPageForCustomer = useGetDefaultLandingPageForCustomer(
    currentSite?.customerId ?? currentCustomerId ?? null
  );

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const [searchActive, setSearchActive] = React.useState(false);

  const { logout } = useAuth0();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const closeAboutPopup = () => {
    setShowAboutPopup(false);
  };

  const closeFeedbackDialog = () => {
    setShowFeedbackDialog(false);
  };

  const handleSearchToggle = () => {
    setSearchActive((prev) => !prev);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: "background.paper", boxShadow: 1 }}>
        <Toolbar disableGutters color="default">
          <Container
            sx={{
              bgcolor: (theme) => (isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper),
            }}
            maxWidth={false}
          >
            <Stack direction="row" justifyContent={"space-between"} alignItems="center" sx={{ width: "100%" }}>
              <Link to={defaultLandingPageForCustomer.url ?? "/"}>
                <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                  <Box>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        (isDarkTheme ? "/images/iris-brand-logo-dark.svg" : "/images/iris-brand-logo-light.svg")
                      }
                      style={{ height: "64px", width: "100%", padding: "8px", paddingTop: "15px" }}
                      alt="SpartanPro IRIS"
                    />
                  </Box>
                  {/* </Stack> */}
                </Box>
                <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}>
                  <img
                    style={{ height: "44px", width: "44px" }}
                    src={process.env.PUBLIC_URL + "/images/iris-brand-logo-mobile.svg"}
                    alt="SpartanPro IRIS"
                  />
                </Box>
              </Link>
              <Hide at={(breakpoint) => breakpoint.down("md")}>
                <SiteContextHeader
                  isDarkTheme={isDarkTheme}
                  showSiteSelector={shouldShowSiteSelector}
                  currentCustomerId={currentCustomerId}
                  currentSiteId={currentSiteId}
                />
              </Hide>

              <Box flexGrow={1} display="flex" justifyContent="flex-end" sx={{ p: 1, paddingRight: 2 }}>
                <Hide at={(breakpoint) => breakpoint.down("md")}>
                  {!searchActive && (
                    <IconButton onClick={handleSearchToggle} sx={{ fill: "white" }}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </Hide>

                {searchActive && (
                  <Hide at={(breakpoint) => breakpoint.down("md")}>
                    {userPermissions.userCanSearchAnything && <SearchBar onBlur={handleSearchToggle} autoFocus />}
                  </Hide>
                )}

                <Show at={(breakpoint) => breakpoint.down("md")}>
                  {userPermissions.userCanSearchAnything && <SearchBar onBlur={handleSearchToggle} autoFocus />}
                </Show>
              </Box>

              <Stack spacing={4} direction="row" alignItems="center">
                <Tooltip title="My Account">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar src={currentUser?.picture} />
                  </IconButton>
                </Tooltip>
                <StyledMenu
                  darkTheme={isDarkTheme}
                  sx={{ mt: "-9px", ml: "8px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Stack direction="row" justifyContent="flex-end" sx={{ pb: 2, pr: 1 }}>
                    <Avatar src={currentUser?.picture} />
                  </Stack>
                  <StyledDivider />
                  {(userPermissions.userCanManageCustomersAndSites || userPermissions.userCanManageKeywords) && (
                    <StyledMenuSection disabled darkTheme={isDarkTheme}>
                      <ListItemText primary="Administration" inset />
                    </StyledMenuSection>
                  )}
                  {userPermissions.userCanManageCustomersAndSites && (
                    <MenuItem component="a" href={Routes.Customers} sx={isDarkTheme ? darkThemeMenuItemStyle : {}}>
                      <StyledMenuIcon darkTheme={isDarkTheme}>
                        <GroupsIcon fontSize="small" />
                      </StyledMenuIcon>
                      <ListItemText primary="Customers &amp; Sites" />
                    </MenuItem>
                  )}
                  {userPermissions.userCanManageKeywords && (
                    <MenuItem component="a" href={Routes.ManageKeywords} sx={isDarkTheme ? darkThemeMenuItemStyle : {}}>
                      <StyledMenuIcon darkTheme={isDarkTheme}>
                        <ManageSearchRoundedIcon fontSize="small" />
                      </StyledMenuIcon>
                      <ListItemText primary="Manage Keywords" />
                    </MenuItem>
                  )}
                  <StyledDivider />
                  <StyledMenuSection disabled darkTheme={isDarkTheme}>
                    <ListItemText primary="Help" inset />
                  </StyledMenuSection>
                  <MenuItem component="a" href={Routes.Tutorials} sx={isDarkTheme ? darkThemeMenuItemStyle : {}}>
                    <StyledMenuIcon darkTheme={isDarkTheme}>
                      <HelpIcon fontSize="small" />
                    </StyledMenuIcon>
                    <Typography textAlign="center">Tutorials</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      setShowAboutPopup(true);
                    }}
                    sx={isDarkTheme ? darkThemeMenuItemStyle : {}}
                  >
                    <StyledMenuIcon darkTheme={isDarkTheme}>
                      <InfoIcon fontSize="small" />
                    </StyledMenuIcon>
                    <Typography textAlign="center">About</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      setShowFeedbackDialog(true);
                    }}
                    sx={isDarkTheme ? darkThemeMenuItemStyle : {}}
                  >
                    <StyledMenuIcon darkTheme={isDarkTheme}>
                      <RateReviewIcon fontSize="small" />
                    </StyledMenuIcon>
                    <Typography textAlign="center">Feedback</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => logout({ localOnly: false, returnTo: window.location.origin })}
                    sx={isDarkTheme ? darkThemeMenuItemStyle : {}}
                  >
                    <StyledMenuIcon darkTheme={isDarkTheme}>
                      <Logout fontSize="small" />
                    </StyledMenuIcon>
                    <Typography textAlign="center">Sign out</Typography>
                  </MenuItem>
                </StyledMenu>
              </Stack>
            </Stack>
          </Container>
        </Toolbar>
        <Show at={(breakpoint) => breakpoint.down("md")}>
          <SiteContextHeader
            isDarkTheme={isDarkTheme}
            showSiteSelector={shouldShowSiteSelector}
            currentCustomerId={currentCustomerId}
            currentSiteId={currentSiteId}
          />
        </Show>
      </AppBar>
      {/*
          the header is sticky and overlaps content, so we need to create a
          hidden element to offset the header height
          */}
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "130px",
            md: "70px",
          },
        }}
      />
      {showAboutPopup && <AboutPopup onClose={closeAboutPopup} />}
      {showFeedbackDialog && <SubmitFeedbackDialog onClose={closeFeedbackDialog} />}
    </>
  );
};

export default ResponsiveAppBar;
