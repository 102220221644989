import { Typography } from "@mui/material";

interface FormItemLabelProps {
  label?: string | null;
}

function FormItemLabel({ label }: FormItemLabelProps) {
  if (label === null || label === undefined) {
    return <></>;
  }

  return (
    <Typography color="primary.main" variant="caption">
      {label === "" ? <>&nbsp;</> : label}
    </Typography>
  );
}

export default FormItemLabel;
