import { Typography, Stack } from "@mui/material";
import { formatRoutePath, Routes } from "app/routes";
import { InternalLink } from "common/mui/InternalLink";
import { useGetApplicationRequest } from "applications";
import FormItemLabel from "app/mui/forms/FormItemLabel";

interface AssetApplicationProps {
  applicationId: number;
  siteId: number;
}

export const AssetApplication = (props: AssetApplicationProps) => {
  const { applicationId, siteId } = props;
  const assetApplication = useGetApplicationRequest(applicationId).data;
  return (
    <Stack direction="column">
      <FormItemLabel label="Application" />
      <Typography color="primary.main" variant="caption"></Typography>

      <span key={applicationId}>
        <Typography component="span" variant="body2" sx={{ whiteSpace: "nowrap" }}>
          <InternalLink to={formatRoutePath(Routes.Application, { siteId, id: applicationId })}>
            {assetApplication?.model.name}
          </InternalLink>
        </Typography>
      </span>
    </Stack>
  );
};
