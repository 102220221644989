import { Box, Divider, Grid, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import { IrisTooltip, useUserContext } from "app";
import { TrafficLightFormItem } from "app/mui/forms/Select/TrafficLightFormItem";
import { IrisColors } from "app/mui/theme";
import React from "react";
import { useForm } from "react-hook-form";
import { Record as RecordModel } from "records/models";
import InfoIcon from "@mui/icons-material/Info";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { createNewReportCardVibrationAnalysis, ReportCardVibrationAnalysis, VibrationAnalysisFieldLabel, VibrationAnalysisFields, VibrationAnalysisTooltip } from "records/models/reportCardVibrationAnalysis";
import { useUpdateReportCardVibrationAnaylsis } from "records/api";

const vibrationAnalysisFields: Array<{
  fieldName: keyof VibrationAnalysisFields;
  label: VibrationAnalysisFieldLabel;
  toolTipContent?: VibrationAnalysisTooltip;
}> = [
    {
      fieldName: "balance",
      label: VibrationAnalysisFieldLabel.Balance,
      toolTipContent: VibrationAnalysisTooltip.Balance,
    },
    {
      fieldName: "alignmentCoupling",
      label: VibrationAnalysisFieldLabel.AlignmentCoupling,
      toolTipContent: VibrationAnalysisTooltip.AlignmentCoupling,
    },
    {
      fieldName: "bearings",
      label: VibrationAnalysisFieldLabel.Bearings,
      toolTipContent: VibrationAnalysisTooltip.Bearings,
    },
    {
      fieldName: "gears",
      label: VibrationAnalysisFieldLabel.Gears,
      toolTipContent: VibrationAnalysisTooltip.Gears,
    },
    {
      fieldName: "resonance",
      label: VibrationAnalysisFieldLabel.Resonance,
      toolTipContent: VibrationAnalysisTooltip.Resonance,
    },
    {
      fieldName: "looseness",
      label: VibrationAnalysisFieldLabel.Looseness,
      toolTipContent: VibrationAnalysisTooltip.Looseness,
    },
    {
      fieldName: "electrical",
      label: VibrationAnalysisFieldLabel.Electrical,
      toolTipContent: VibrationAnalysisTooltip.Electrical,
    },
    {
      fieldName: "process",
      label: VibrationAnalysisFieldLabel.Process,
      toolTipContent: VibrationAnalysisTooltip.Process,
    },
    {
      fieldName: "structurePiping",
      label: VibrationAnalysisFieldLabel.StructurePiping,
      toolTipContent: VibrationAnalysisTooltip.StructurePiping,
    },
  ];

interface VibrationAnalysisReportCardProps {
  record: RecordModel;
  reportCard?: ReportCardVibrationAnalysis | null;
}

export const VibrationAnalysisReportCard = (props: VibrationAnalysisReportCardProps) => {
  const { record, reportCard } = props;
  const defaultValues = makeSafeForReactHookForm(reportCard || createNewReportCardVibrationAnalysis(record.id));
  const methods = useForm({ defaultValues });
  const updateReportCard = useUpdateReportCardVibrationAnaylsis();
  const { userPermissions } = useUserContext();

  if (!reportCard) {
    return <></>;
  }

  const userCanEdit = userPermissions.userCanEditRecord(
    record.assets.map((asset) => asset.assetType),
    record.recordType
  );

  const saveChanges = () => {
    methods.handleSubmit((values) => {
      updateReportCard.call({ ...values, recordId: record.id });
    })();
  };

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permissions) =>
        permissions.userCanEditRecord(
          record.assets?.map((asset) => asset.assetType),
          record.recordType
        )
      }
    >
      <AutoSave onSubmit={saveChanges} />
      <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
        <Stack justifyContent="space-between" direction="column" sx={{ width: "100%" }}>
          <Typography variant="h6">REPORT CARD</Typography>
          <Paper>
            <List sx={{ width: "100%" }}>
              {vibrationAnalysisFields.map((field, index) => (
                <React.Fragment key={`${field.fieldName}`}>
                  <ListItem>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                        <Grid item xs={8} sx={{ display: "flex" }}>
                          <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                            {field.label}
                          </Typography>
                          {field.toolTipContent && (
                            <IrisTooltip title={<>{field.toolTipContent}</>}>
                              <span>
                                <InfoIcon sx={{ color: IrisColors.gray500, fontSize: "1.1rem" }} />
                              </span>
                            </IrisTooltip>
                          )}
                        </Grid>
                        <Grid item xs={true}>
                          <TrafficLightFormItem
                            fieldName={field.fieldName}
                            readOnly={!userCanEdit}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </ListItem>
                  {index < vibrationAnalysisFields.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Stack>
      </Paper>
    </PermissionAwareFormProvider>
  );
};
