import * as React from "react";
import { CollapseIcon } from "icons/CollapseIcon";
import _ from "lodash";

export function formatSortCaret(order: "asc" | "desc" | undefined, column: any) {
   if (!order) {
      return (
         <span className="sort-carets">
            <CollapseIcon expanded />
            <CollapseIcon expanded={false} />
         </span>
      );
   } else if (order === "asc") {
      return (
         <span className="sort-carets">
            <CollapseIcon expanded className="active" />
            <CollapseIcon expanded={false} />
         </span>
      );
   } else if (order === "desc") {
      return (
         <span className="sort-carets">
            <CollapseIcon expanded />
            <CollapseIcon expanded={false} className="active" />
         </span>
      );
   }
   return null;
}

export enum ColumnSize {
   XxSmall = "xxs-column",
   XSmall = "xs-column",
   Small = "sm-column",
   Medium = "md-column",
   Large = "lg-column",
}

export function forwardSort(a: any, b: any, order: string) {
   if (typeof a === "string" && typeof b === "string") {
      if (!a?.trim() && b?.trim()) return 1;
      if (a?.trim() && !b?.trim()) return -1;

      if (order === "asc") return a.localeCompare(b);
      else return b.localeCompare(a);
   } else {
      // disregard order, blanks to bottom
      if ((a === null || a === undefined || a === "") && b !== null && b !== undefined) return 1;
      if (a !== null && a !== undefined && (b === null || b === undefined || b === "")) return -1;

      if (order === "asc") return b - a;
      else return a - b;
   }
}

export function reverseSort(a: any, b: any, order: any) {
   if (typeof a === "string" && typeof b === "string") {
      if (!a?.trim() && b?.trim()) return 1;
      if (a?.trim() && !b?.trim()) return -1;

      if (order === "asc") return b.localeCompare(a);
      else return a.localeCompare(b);
   } else {
      // disregard order, blanks to bottom
      if ((a === null || a === undefined || a === "") && b !== null && b !== undefined) return 1;
      if (a !== null && a !== undefined && (b === null || b === undefined || b === "")) return -1;

      if (order !== "asc") return b - a;
      else return a - b;
   }
}
