import { Col, Collapse, Row } from "reactstrap";
import { QuoteDetails } from "./QuoteDetails";
import { TextAreaInput } from "../../inputs/TextAreaInput";
import { PODetails } from "./PODetails";

interface BudgetaryReplacementCostDetailsProps {
   userCanEdit: boolean;
   accepted?: boolean;
   acceptedScopeExists: boolean;
}

export const BudgetaryReplacementCostDetails = (props: BudgetaryReplacementCostDetailsProps) => {
   return (
      <div style={{ display: "flex" }}>
         <div className="spacer" />
         <div className="form-body">
            <QuoteDetails userCanEdit={props.userCanEdit && !props.acceptedScopeExists} />
            <Row style={{ padding: "1rem" }}>
               <Col>
                  <TextAreaInput
                     label="Details"
                     field="details"
                     userCanEdit={props.userCanEdit && !props.acceptedScopeExists}
                  />
               </Col>
            </Row>
            <Collapse isOpen={props.accepted}>
               <PODetails userCanEdit={props.userCanEdit} />
            </Collapse>
         </div>
      </div>
   );
};
