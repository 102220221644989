import { Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export interface DeleteModalProps {
   open: boolean;
   onClose: () => void;
   onConfirm: () => void;
   title?: string;
   message?: string;
   confirmButtonText?: string;
}

export const SimpleDeleteModal = (props: DeleteModalProps) => {
   return (
      <Dialog open={props.open} onClose={props.onClose}>
         <DialogTitle>{props.title || "Are you sure?"}</DialogTitle>
         <DialogContent>
            <Typography variant="body2">{props.message || "This action cannot be undone."}</Typography>
         </DialogContent>
         <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
               onClick={() => {
                  props.onConfirm();
                  props.onClose();
               }}
               color="error"
            >
               {props.confirmButtonText || "Delete"}
            </Button>
         </DialogActions>
      </Dialog>
   );
};
