import { useFeatureFlagsRequest, FeatureFlag } from "common";
import { createContext, FunctionComponent, ReactNode, useContext } from "react";

export interface FeatureFlagsContextProps {
   featureFlags: FeatureFlag[] | null;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextProps>({
   featureFlags: null,
});

export const FeatureFlagsContextProvider: FunctionComponent<{
   children?: ReactNode;
}> = (props) => {
   const { data: featureFlags } = useFeatureFlagsRequest();

   return <FeatureFlagsContext.Provider value={{ featureFlags }}>{props.children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);

