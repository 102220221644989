import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { useGetAssetComponentsByIdQuery } from "assets";
import { Controller } from "react-hook-form";
import { includes } from "lodash";

interface MultiAssetComponentSelectFormItemProps {
  assetId: number;
  fieldName: string;
  label: string;
}

export const MultiAssetComponentSelectFormItem = (props: MultiAssetComponentSelectFormItemProps) => {
  const { assetId, fieldName, label } = props;
  const { data } = useGetAssetComponentsByIdQuery(assetId);
  const options = data || [];

  return (
    <Controller
      name={fieldName}
      defaultValue={[]}
      render={({ field: { onChange, value } }) => (
        <FormItemLayout label={label}>
          {(inputProps) => (
            <Autocomplete
              multiple
              size={"small"}
              options={options}
              getOptionLabel={(o) => o.title}
              onChange={(_e, values) => {
                onChange(values.map((c) => c.id));
              }}
              value={options.filter((o) => includes(value, o.id))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params: AutocompleteRenderInputParams) => <TextField {...inputProps} {...params} />}
            />
          )}
        </FormItemLayout>
      )}
    />
  );
};
