import React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { Input, ModalFooter, Button, FormGroup, Label } from "reactstrap";
import { useNotifications } from "app";
import classNames from "classnames";
import { getValidity } from "common";
import { useDuplicateApplicationRequest, ApplicationDetailView, ApplicationDigest } from "applications";
import { useState } from "react";

interface Props {
   open: boolean;
   toggle: () => void;
   duplicatedApplication: (application: ApplicationDigest, openNewApplication: boolean) => void;
   application: ApplicationDetailView;
}

export const DuplicateApplicationModal: React.FunctionComponent<Props> = (props) => {
   const notifications = useNotifications();
   const [name, setName] = useState("");
   const nameValidity = getValidity(!!name.trim());
   const [showValidation, setShowValidation] = useState(false);

   const duplicateApplicationRequest = useDuplicateApplicationRequest();

   const duplicateApplication = (openNewApplication: boolean) => {
      setShowValidation(true);
      if (!nameValidity.valid) return;

      duplicateApplicationRequest
         .call({
            applicationId: props.application.id,
            siteId: props.application.siteId,
            name: name,
         })
         .then((duplicatedApplication) => {
            if (!!duplicatedApplication) {
               props.duplicatedApplication(duplicatedApplication, openNewApplication);
               notifications.success("Application duplicated.");
            } else {
               notifications.error("Error duplicating application.");
            }
         });
   };

   const disableDuplication = !nameValidity.valid;

   return (
      <Modal
         className="add-application-modal"
         isOpen={props.open}
         toggle={props.toggle}
         container={"#bootstrap-modal-container"}
      >
         <ModalHeader>{`Duplicate application`}</ModalHeader>
         <ModalBody>
            <FormGroup>
               <Label>Name of new application</Label>
               <Input
                  className={classNames({ "is-invalid": showValidation && !nameValidity.valid })}
                  value={name}
                  onChange={(e) => {
                     setName(e.target.value);
                  }}
                  required={true}
               />
               <div className="invalid-feedback">{nameValidity.message}</div>
            </FormGroup>
         </ModalBody>
         <ModalFooter>
            <Button
               color="primary"
               onClick={() => {
                  duplicateApplication(true);
               }}
               disabled={disableDuplication}
            >
               Duplicate application and open
            </Button>
            <Button
               color="secondary"
               onClick={() => {
                  duplicateApplication(false);
               }}
               disabled={disableDuplication}
            >
               Duplicate and exit
            </Button>
            <Button
               color="secondary"
               onClick={() => {
                  setName("");
                  props.toggle();
               }}
               disabled={duplicateApplicationRequest.loading}
            >
               Cancel
            </Button>
         </ModalFooter>
      </Modal>
   );
};
