import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { Asset, TankSpec } from "assets/models/asset";
import { useForm } from "react-hook-form";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import AutoSave from "app/mui/forms/AutoSave";
import { useUpdateTankSpecificationRequest } from "assets/api";

interface TankSpecificationCardProps {
  asset: Asset;
  tank: TankSpec;
}

export const TankSpecificationCard = (props: TankSpecificationCardProps) => {
  const updateSpecificationRequest = useUpdateTankSpecificationRequest();

  const defaultValues = makeSafeForReactHookForm(props.tank);
  const methods = useForm({ defaultValues });

  const updateTank = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const standardFields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
  ];

  const advancedFields = [
    <EditableTextFieldFormItem label="Product" fieldName="product" />,
    <EditableTextFieldFormItem label="API Standard" fieldName="apiStandard" />,
    <EditableTextFieldFormItem label="Annex" fieldName="annex" />,
    <EditableTextFieldFormItem label="Edition" fieldName="edition" />,
    <EditableTextFieldFormItem label="Nominal Diameter" fieldName="nominalDiameter" />,
    <EditableTextFieldFormItem label="Maximum Capacity" fieldName="maximumCapacity" />,
    <EditableTextFieldFormItem label="Design Specific Gravity" fieldName="designSpecificGravity" />,
    <EditableTextFieldFormItem label="Design Pressure" fieldName="designPressure" />,
    <EditableTextFieldFormItem label="Pressure Combination Factor" fieldName="pressureCombinationFactor" />,
    <EditableTextFieldFormItem label="Fabricated By" fieldName="fabricatedBy" />,
    <EditableTextFieldFormItem label="Erected By" fieldName="erectedBy" />,
    <EditableTextFieldFormItem label="Year Completed" fieldName="yearCompleted" />,
    <EditableTextFieldFormItem label="Nominal Height" fieldName="nominalHeight" />,
    <EditableTextFieldFormItem label="Design Liquid Level" fieldName="designLiquidLevel" />,
    <EditableTextFieldFormItem label="Design Metal Temperature" fieldName="designMetalTemperature" />,
    <EditableTextFieldFormItem label="Maximum Design Temperature" fieldName="maximumDesignTemperature" />,
    <EditableTextFieldFormItem label="Stress Relief" fieldName="stressRelief" />,
    <EditableTextFieldFormItem label="Purchaser's Tank Number" fieldName="purchasersTankNumber" />,
    <EditableTextFieldFormItem label="Shell Course" fieldName="shellCourse" />,
    <EditableTextFieldFormItem label="Material" fieldName="material" />,
  ];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave onSubmit={updateTank} />
      <AssetSpecificationCard title="Tank" specs={[...standardFields, ...advancedFields]} />
    </PermissionAwareFormProvider>
  );
};
