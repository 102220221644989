import { ThemeName } from "common";
import { createContext, FunctionComponent, ReactNode, useContext } from "react";


interface UiThemeContextProps {
   header: ThemeName;
   navbar: ThemeName;
}

const defaultContext: UiThemeContextProps = {
   header: 'dark',
   navbar: 'dark'
}

const UiThemeContext = createContext<UiThemeContextProps>(defaultContext);

export const UiThemeContextProvider: FunctionComponent<{
   children?: ReactNode;
}> = (props) => {

   return <UiThemeContext.Provider value={defaultContext}>{props.children}</UiThemeContext.Provider>;
};

export const useUiThemeContext = () => useContext(UiThemeContext);
