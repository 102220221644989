import { KpiSignoff } from "./KpiSignoff";
import { TemplateKpiType, ValueKpiType } from "./KpiType";

export interface KpiBase extends KpiSignoff {
  /* unique id - use .rowId for row Id */
  id: string;
  rowId: number;
  kpiType: ValueKpiType | TemplateKpiType;
  templateComments: string | null;
}

export const KpiBaseFields: (keyof KpiBase)[] = [
  "id",
  "rowId",
  "templateComments",
  "customerSignOff",
  "signOffDate",
  "signedOff",
];
