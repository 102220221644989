import { FunctionComponent } from "react";
import { Routes, useValidatedSiteIdFromRoute } from "app";
import { LinearProgress } from "@mui/material";
import { useGetSummaryDetailRequest } from "summaries/api";
import { SummaryScreen } from "./SummaryScreen";

export const SummaryScreenQuery: FunctionComponent = () => {
  const summaryId = useValidatedSiteIdFromRoute(Routes.Summaries);
  const { data: summary, loading, error } = useGetSummaryDetailRequest(summaryId);

  if (loading && !summary) return <LinearProgress />;
  if (!!error || !summary) return null;

  return (
    <div className="legacy_bootstrap">
      <SummaryScreen key={summaryId} summary={summary} />
    </div>
  );
};
