import React from "react";

export const usePopover = (options?: { id?: any }) => {
   const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

   const handleClick = (event: React.MouseEvent<any>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);
   const id = open ? options?.id ?? "simple-popover" : undefined;

   return {
      popoverProps: {
         open,
         id,
         anchorEl,
         onClose: handleClose,
      },
      open,
      openPopover: handleClick,
      closePopover: handleClose,
   };
};
