import { Badge, Chip, Stack, Tooltip } from "@mui/material";
import FormItemLabel from "app/mui/forms/FormItemLabel";
import { pluralize } from "common/utilities";

interface AssetStatusProps {
  obsoleteComponentCount: number;
  obsoleteComponents: string[];
}

export const AssetStatus = (props: AssetStatusProps) => {
  const { obsoleteComponents, obsoleteComponentCount } = props;
  return (
    <Stack direction="column">
      <FormItemLabel label="Status" />
      <Tooltip
        title={
          obsoleteComponentCount > 0
            ? `${obsoleteComponents.join(", ")} ${pluralize(obsoleteComponentCount, "is", "are")} obsolete.`
            : ""
        }
      >
        <Badge invisible={obsoleteComponentCount === 0} badgeContent={obsoleteComponentCount} color="Yellow">
          <Chip size="small" label={obsoleteComponentCount > 0 ? "Obsolete" : "Active"} sx={{ mt: "4px" }} />
        </Badge>
      </Tooltip>
    </Stack>
  );
};
