import { ReactNode } from "react";
import { StatusIdLabelOptions } from "records";
import { Option } from "./forms/AutocompleteFormItem";

interface RecordStatusLoaderProps {
   children: (params: { options: Option[] }) => ReactNode;
}

export const RecordStatusLoader = (props: RecordStatusLoaderProps) => (
   <>{props.children({ options: StatusIdLabelOptions })}</>
);
