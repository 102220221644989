import { ReactNode } from "react";
import { AssetDigest } from "assets";
import { Option } from "../forms/AutocompleteFormItem";
import { useGetApplicationRequest } from "applications";

interface AvailableFailureModesLoaderProps {
  assets?: AssetDigest[];
  children: (params: { options: Option[]; loading: boolean }) => ReactNode;
}

export const AvailableFailureModesLoader = (props: AvailableFailureModesLoaderProps) => {
  const asset = !!props.assets ? props.assets[0] : null;
  const { data: application, loading } = useGetApplicationRequest(!!asset ? asset.applicationId : null);

  const assetStrategies = application?.strategies.filter((s) => s.assets.some((sa) => sa.id === asset!.id));

  const options = !!assetStrategies
    ? application!.failureModes
        .filter((fm) => assetStrategies.some((as) => as.id === fm.strategyId))
        .map((fm) => ({ id: fm.id, label: fm.model.name || "unnamed" }))
    : [];

  return <>{props.children({ options, loading })}</>;
};
