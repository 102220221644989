import { dateFormat } from "common/formats";
import moment from "moment";
import { CSSProperties } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";

interface DateInputProps {
   field: any;
   label: string;
   userCanEdit: boolean;
   hideTextWhenNoValue?: boolean;
}

export const DateInput = (props: DateInputProps) => {
   const {
      control,
      formState: { errors },
   } = useFormContext();

   const userCanEdit = { readOnly: props.userCanEdit === false };

   const value = useWatch({ name: props.field });

   let additionalStyle: CSSProperties = {};

   if (props.hideTextWhenNoValue && !value) {
      additionalStyle = {
         color: "#bababa",
      };
   }

   const formattedValue = value ? moment(value).format(dateFormat) : "";
   const errorMessage = errors[props.field]?.message as string | null | undefined;

   return (
      <Controller
         control={control}
         name={props.field}
         render={({ field: { ref, ...fieldProps } }) => (
            <FormGroup>
               <Label>{props.label}</Label>
               <Input
                  type="date"
                  innerRef={ref}
                  {...fieldProps}
                  value={formattedValue}
                  {...userCanEdit}
                  style={additionalStyle}
               />
               <div className="form-error">{errorMessage}</div>
            </FormGroup>
         )}
      />
   );
};