import React from "react";
import { UserPermissions } from "users";
import { useUserContext } from "./UserContext";

interface Props {
   children: (userPermissions: UserPermissions, notYetLoaded: boolean) => React.ReactElement<any> | null;
}

export const AuthorizationProvider: React.FunctionComponent<Props> = (props) => {
   const { userPermissions, currentUserLoading: permissionsLoading } = useUserContext();
   return props.children(userPermissions, permissionsLoading);
};
