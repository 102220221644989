import * as React from "react";
import { Box, Paper, Stack } from "@mui/material";
import { PackagedSolutionDetailViewModel } from "@bluemarvel/iris-common/model";
import { PageTitle } from "app/mui/common/headers/PageTitle";
import { PackagedSolutionIcon } from "icons/PackagedSolutionIcon";
import { PackagedSolutionActions } from "./PackagedSolutionActions";
import { PackagedSolutionTypeBadge } from "packagedSolution/PackagedSolutionTypeBadge";
import { LastModified } from "app/mui/LastModified";
import { User } from "users";

interface PackagedSolutionHeaderProps {
  packagedSolutionDetail: PackagedSolutionDetailViewModel;
}

export const PackagedSolutionHeader = ({ packagedSolutionDetail }: PackagedSolutionHeaderProps) => {
  const packagedSolution = packagedSolutionDetail.model;

  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" pb={2}>
          <Stack direction="column" spacing={1}>
            <PageTitle title={packagedSolution.name} icon={<PackagedSolutionIcon />} />
            <Stack direction="row" spacing={2} alignItems="center" pb={0}>
              <PackagedSolutionTypeBadge packagedSolutionType="Packaged Solution" />
              <PackagedSolutionTypeBadge packagedSolutionType={packagedSolution.packagedSolutionType} />
            </Stack>
            <Stack direction="row" flexWrap="wrap" pb={0}>
              <Box sx={{ pr: 3, pt: 1 }}>Equipment Count: [todo]</Box>
              <Box sx={{ pr: 3, pt: 1 }}>Asset Count: [todo]</Box>
            </Stack>
          </Stack>
          <Stack spacing={2} alignItems="flex-end">
            <PackagedSolutionActions packagedSolutionDetail={packagedSolutionDetail} />
            <div>
              {packagedSolutionDetail.lastModified && packagedSolutionDetail.lastModifiedBy ? (
                <LastModified
                  lastModifiedBy={packagedSolutionDetail.lastModifiedBy as User}
                  lastModified={packagedSolutionDetail.lastModified as Date}
                />
              ) : (
                <></>
              )}
            </div>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};
