import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { EditableNumberFormItem } from "app/mui/forms/Input/NumberFormItem";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { AssetComponentAttribute } from "assets/models/machineryComponent";
import { useMemo } from "react";

interface IAssetComponentFieldProps {
  attribute: AssetComponentAttribute;
  isEdit: boolean;
}

export const createAttributeFieldName = (attribute: AssetComponentAttribute) => {
  return `${attribute.id}`;
};
export const getAttributeIdFromFieldName = (fieldName: string): number => {
  return parseInt(fieldName);
};

export const AssetAttributeField = (props: IAssetComponentFieldProps) => {
  const { attribute } = props;

  const fieldName = createAttributeFieldName(attribute);
  const autoCompleteOptions: string[] = useMemo(() => {
    // return attribute.componentTemplateAttribute.options.map((option) => option.value);
    if (
      !attribute.componentTemplateAttribute.dataFieldOptions ||
      attribute.componentTemplateAttribute.dataFieldOptions.length === 0
    ) {
      return [];
    }
    return JSON.parse(attribute.componentTemplateAttribute.dataFieldOptions);
  }, [attribute.componentTemplateAttribute.dataFieldOptions]);

  switch (attribute.componentTemplateAttribute.fieldType) {
    case "Status":
      return (
        <EditableActiveObsoleteFormItem
          label={attribute.componentTemplateAttribute.fieldName}
          fieldName={fieldName}
          convertToString
        />
      );
    case "Text":
      return autoCompleteOptions?.length ? (
        <EditableAutocompleteFormItem
          label={attribute.componentTemplateAttribute.fieldName}
          fieldName={fieldName}
          freeSolo={true}
          autocompleteProps={{ autoSelect: false }}
          options={autoCompleteOptions}
          idSelector={(option) => option}
        />
      ) : (
        <EditableTextFieldFormItem label={attribute.componentTemplateAttribute.fieldName} fieldName={fieldName} />
      );
    case "Number":
      return <EditableNumberFormItem label={attribute.componentTemplateAttribute.fieldName} fieldName={fieldName} />;

    default:
      return <p>Bad Data for ${attribute.componentTemplateAttribute.fieldName}</p>;
  }
};
