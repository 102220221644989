import * as React from "react";
import { Input } from "reactstrap";

export interface Props {
   saveValue: (newValue: number | null) => void;
   initialValue: number | null;
   isProbability: boolean;
}

interface State {
   inputNumberValue: number | null;
}

export class NumberInput extends React.Component<Props, State> {
   public render() {
      return (
         <Input
            type="number"
            value={this.state.inputNumberValue || undefined}
            onChange={(e) => this.setState({ inputNumberValue: Number.parseFloat(e.target.value) })}
            onBlur={(e) => this.saveValue()}
            onKeyPress={(e) => {
               if (e.key === "Enter") {
                  this.saveValue();
               }
            }}
         />
      );
   }

   constructor(props: Props) {
      super(props);

      let initialValue = props.initialValue;
      if (props.isProbability && initialValue !== null) {
         initialValue = initialValue * 100;
      }
      this.state = {
         inputNumberValue: initialValue,
      };
   }

   private saveValue = () => {
      const value = this.state.inputNumberValue;

      const valueToSave = value === null ? null : this.props.isProbability ? value / 100 : value;
      this.props.saveValue(valueToSave);
   };
}
