import { ResponsiveBar } from "@nivo/bar";
import colors from "assets/customColors";
import { getUniqueKeys } from "common";
import _ from "lodash";
import { useState } from "react";
import { RecordType } from "records";

const theme = {
	axis: {
		ticks: {
			text: {
				fill: "#777",
			},
		},
		legend: {
			text: {
				fontWeight: 500,
				fontSize: "12pt",
				fill: "#777",
			},
		},
	},
	legends: {
		text: {
			fontWeight: 600,
		},
	},
};

const allRecordsGrey = colors.nobel;

const keysAndColors = [
	{ key: RecordType.ValveDiagnostic, color: colors.seance },
	{ key: RecordType.Reactive, color: colors.aeroBlue },
	{ key: RecordType.Proactive, color: colors.brightTurquoise },
	{ key: RecordType.Preventative, color: colors.frenchRose },
	{ key: RecordType.Predictive, color: colors.illusion },
	{ key: RecordType.OutageScope, color: colors.pizzaz },
	{ key: RecordType.FieldInspection, color: colors.lightningYellow },
	{ key: RecordType.CandSU, color: colors.robinsEggBlue },
	{ key: RecordType.Activity, color: colors.forestGreen },
	{ key: "All Records", color: colors.congressBlue },
];

/**
 * We want to create a series toggle, allowing users to click on the different
 * series of data available in the legend and toggle a comparison between that
 * data set and the all records dataset. An example is to comapre "all records",
 * to "valve diagnostic" records.
 *
 * There is a built in seriesToggle option, but it doesn't do what we want, and
 * maintaining consistent colors/data in nivo is stupid and requires the order
 * of data elements to match the order of the colors array :eye_roll:
 *
 * So this hook modifies the data, and the keys, and the colors array to
 * simulate the behaviour that we want.
 */
const useSingleSeriesToggle = (data: RecordTypeBarChartData[]) => {
	const [selectedSeries, setSelectedSeries] = useState<"All Records" | keyof typeof RecordType>("All Records");

	const uniqKeysFromDataSet = getUniqueKeys(data);

	const keys = keysAndColors.filter((kc) => uniqKeysFromDataSet.includes(kc.key)).map((kc) => kc.key);
	const colors = keysAndColors
		.filter((kc) => uniqKeysFromDataSet.includes(kc.key))
		.map((kc) => {
			if (kc.key === selectedSeries) {
				return kc.color;
			} else if (kc.key === "All Records") {
				return allRecordsGrey;
			} else {
				return "transparent";
			}
		});

	const filteredData =
		selectedSeries !== "All Records"
			? data.map((d) => {
					return {
						year: d.year,
						[selectedSeries]: d[selectedSeries] ?? 0,
						"All Records": d["All Records"] - (d[selectedSeries] ?? 0),
					};
			  })
			: data.map((d) => {
					return {
						year: d.year,
						"All Records": d["All Records"],
					};
			  });

	return { selectedSeries, setSelectedSeries, keys, colors, data: filteredData };
};

export const RecordTypeBarChart = ({ data }) => {
	const { selectedSeries, setSelectedSeries, keys, colors, data: filteredData } = useSingleSeriesToggle(data);

	const handleSeriesClicked = (datum: any, _event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
		setSelectedSeries(datum.id);
	};

	return (
		<ResponsiveBar
			theme={theme}
			data={filteredData}
			keys={keys}
			indexBy="year"
			margin={{ top: 50, right: 160, bottom: 60, left: 70 }}
			padding={0.3}
			valueScale={{ type: "linear" }}
			colors={colors}
			enableLabel={false}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "TIME",
				legendPosition: "middle",
				legendOffset: 42,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "RECORD VOLUME",
				legendPosition: "middle",
				legendOffset: -50,
			}}
			legends={[
				{
					onClick: handleSeriesClicked,
					dataFrom: "keys",
					anchor: "right",
					direction: "column",
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 9,
					itemWidth: 110,
					itemHeight: 26,
					itemDirection: "left-to-right",
					itemOpacity: 0.85,
					symbolSize: 18,

					symbolShape: (params) => {
						let color = keysAndColors.find((kc) => kc.key === params.id)!.color;
						let strokeColor = keysAndColors.find((kc) => kc.key === params.id)!.color;

						if (params.id === "All Records" && selectedSeries !== "All Records") {
							color = allRecordsGrey;
							strokeColor = allRecordsGrey;
						} else if (params.id !== selectedSeries) {
							color = "transparent";
							strokeColor = allRecordsGrey;
						}

						return (
							<rect
								x={params.x}
								y={params.y}
								fill={color}
								opacity="1"
								strokeWidth="2"
								stroke={strokeColor}
								width={params.size}
								height={params.size}
								style={{ pointerEvents: "none" }}
							></rect>
						);
					},
					effects: [
						{
							on: "hover",
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	);
};

export interface RecordTypeBarChartData extends Record<RecordType | "All Records", number> {
	year: number;
}
