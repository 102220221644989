import * as React from "react";
import { makeSiteDigestRouteNavigator, WithSiteRouting } from "app/SiteNavigator";
import { SitesList } from "./components/SitesList";
import { SearchableSiteDigestWithCustomer } from "sites/models";
import { useGetSiteDigestsRequest } from "sites/api";

export const MySitesPage = () => {
   const { data: sites, loading } = useGetSiteDigestsRequest();

   const flattenedCustomerSites: WithSiteRouting<SearchableSiteDigestWithCustomer>[] = React.useMemo(() => {
      if (!sites) {
         return [];
      }

      return sites.map((s) => ({ ...s, customer: s.customer, combinedSearchName: `${s.name} ${s.customer.name}` }))
         .map((s) => makeSiteDigestRouteNavigator(s));
   }, [sites]);

   return <SitesList sites={flattenedCustomerSites} isLoading={loading} />;
};
