import { Stack, Theme, useTheme } from "@mui/material";

type BadgeVariant = "outlined" | "simple";

export interface BadgeProps {
  icon?: React.ReactNode;
  text: string;
  isDarkTheme?: boolean;
  variant?: BadgeVariant;
  color?: string;
}

function getFillColor(isDarkTheme: boolean, theme: Theme, colorOverride?: string) {
  if (colorOverride != null) {
    return colorOverride;
  }
  return !!isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;
}

export const Badge = (props: BadgeProps) => {
  const { icon, text, variant = "outlined", isDarkTheme, color } = props;
  const theme = useTheme();
  const fillColor = getFillColor(isDarkTheme ?? false, theme, color);

  if (text == null) {
    return <></>;
  }

  return (
    <Stack
      spacing={0.5}
      direction="row"
      alignItems="center"
      width="fit-content"
      gap={1}
      sx={{
        border: variant === "outlined" ? `1px solid ${fillColor}` : "none",
        padding: variant === "outlined" ? "0.1rem 0.5rem" : "0",
        fontSize: variant === "outlined" ? ".8rem" : "1rem",
        borderRadius: "5px",
        color: fillColor,
      }}
    >
      {icon}
      {text}
    </Stack>
  );
};
