import { Grid } from "@mui/material";
import { EditKpiFormProps } from "../EditKpiFormProps";
import { useMemo } from "react";
import {
  KpiOneTimeScheduleDelayReduction,
  KpiOneTimeScheduleDelayReductionViewModel,
} from "kpis/models/KpiOneTimeScheduleDelayReduction";
import { KpiGridStyles } from "../KpiGridStyles";
import { PotentialDelayTypeFormItem } from "../formItems/PotentialDelayTypeFormItem";
import { NumberTimeUnitFormItem } from "../formItems/NumberTimeUnitFormItem";
import TextAreaFormItem from "app/mui/forms/Input/TextAreaFormItem";
import { ControlledTextFieldFormItem } from "app/mui/forms/Input/ControlledTextFieldFormItem";
import { KpiHelpTooltip } from "kpis/formFields/KpiHelpTooltip";
import { KpiCommentsFormItem } from "../formItems/KpiCommentsFormItem";
import { KpiValueModel } from "kpis/models";

interface KpiOneTimeScheduleDelayReductionDetailsProps extends EditKpiFormProps {}

export function KpiOneTimeScheduleDelayReductionDetails(props: KpiOneTimeScheduleDelayReductionDetailsProps) {
  const {} = props;
  const kpi = useMemo(
    () => new KpiOneTimeScheduleDelayReductionViewModel(props.detail.model as KpiOneTimeScheduleDelayReduction),
    [props.detail.model]
  );
  const isKpiTemplate = props.record === undefined;
  const hasTemplateComments =
    isKpiTemplate || (!!(props.model as KpiValueModel).kpiTemplateId && !!props.model.templateComments);

  const { gridRowProps, gridContainerProps, kpiSectionProps, secondaryDividerTitleProps } = KpiGridStyles;
  const inputProps = useMemo(() => ({ readOnly: props.readOnly }), [props.readOnly]);

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid container {...gridContainerProps} {...gridRowProps}>
          <Grid item xs={12}>
            <NumberTimeUnitFormItem
              valueFieldName="reductionAmount"
              timeUnitFieldName="reductionTimeUnit"
              label="Potential delay reduction"
              timeUnitProps={{
                limitTo: ["Hour", "Day"],
                mode: "Plural",
              }}
              {...inputProps}
            />
          </Grid>
        </Grid>
        <Grid container {...gridContainerProps} {...gridRowProps}>
          <Grid item xs={12} md={6}>
            <PotentialDelayTypeFormItem
              fieldName="potentialDelayType"
              label="Potential delay type"
              tip={<KpiHelpTooltip type="potentialDelayType" />}
              {...inputProps}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextFieldFormItem
              alwaysShowInput
              fieldName="location"
              label="Unit/facility/plant"
              tip={<KpiHelpTooltip type="location" />}
              {...inputProps}
            />
          </Grid>
          <Grid item xs={12}>
            <TextAreaFormItem fieldName="actionsTaken" label="Actions taken" minRows={3} {...inputProps} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          {hasTemplateComments && (
            <Grid item xs={12}>
              <KpiCommentsFormItem
                label="Comments"
                fieldName="templateComments"
                alwaysShowInput
                readOnly={!isKpiTemplate}
              />
            </Grid>
          )}
          {!isKpiTemplate && (
            <Grid item xs={12}>
              <KpiCommentsFormItem label="Additional Comments" fieldName="comments" readOnly={!props.userCanEdit} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
