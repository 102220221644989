import { ReactNode, useEffect } from "react";
import _ from "lodash";
import { KpiTypeOption, toKpiTypeDisplayName } from "kpis/models";
import { useSiteIdFromRoute } from "app";
import { useGetFilteredKpiTemplateDigestsInSiteRequest } from "kpis";
import { Option } from "./forms/AutocompleteFormItem";

interface KpiTemplatesLoaderProps {
  usedTemplateIds?: string[];
  kpiType?: KpiTypeOption;
  children: (params: { options: Option[] }) => ReactNode;
}

export const KPITemplatesLoader = (props: KpiTemplatesLoaderProps) => {
  const { kpiType, usedTemplateIds } = props;
  const siteId = useSiteIdFromRoute()!;
  const kpiTemplatesQuery = useGetFilteredKpiTemplateDigestsInSiteRequest(siteId);
  useEffect(() => {
    kpiTemplatesQuery.call({ siteId, enabled: true });
  }, []);

  const availableTemplates =
    kpiTemplatesQuery.data?.filter(
      (t) =>
        (!!!kpiType?.value || t.kpiType === kpiType?.value) && (!!!usedTemplateIds || !usedTemplateIds.includes(t.id))
    ) || [];

  const kpiTemplateOptions = !!kpiType
    ? (!!kpiType?.value ? [{ label: "Blank", id: "" }] : []).concat(
        availableTemplates.map((t) => ({ label: t.name, id: t.id }))
      )
    : _.orderBy(_.uniq(availableTemplates.map((t) => t.kpiType))).map((kpiType) => ({
        label: toKpiTypeDisplayName(kpiType),
        options: availableTemplates.filter((t) => t.kpiType === kpiType).map((t) => ({ id: t.id, label: t.name })),
      }));

  const options = kpiTemplateOptions;

  return <>{props.children({ options })}</>;
};
