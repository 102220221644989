import { BulkValveHealthSummaryNodeReportParams, FieldInspectionSummaryReportParams } from "common/reports";
import { useAxiosGenerateReport } from "app";
import { createContext, FunctionComponent, ReactNode, useContext, useState } from "react";
import { useNotifications } from "./NotificationContext";
import { SnackbarKey } from "notistack";

export interface BackgroundReportContextProps {
  generateFieldReport: ReturnType<typeof useAxiosGenerateReport<FieldInspectionSummaryReportParams>> | null;
  generateBulkValveReport: ReturnType<typeof useAxiosGenerateReport<BulkValveHealthSummaryNodeReportParams>> | null;
}

export const BackgroundReportContext = createContext<BackgroundReportContextProps>({
  generateFieldReport: null,
  generateBulkValveReport: null,
});

export const BackgroundReportContextProvider: FunctionComponent<{
  children?: ReactNode;
}> = (props) => {
  const notify = useNotifications();
  let fieldInspectionKey: SnackbarKey | null = null;
  let bulkValveKey: SnackbarKey | null = null;

  const generateFieldReport = useAxiosGenerateReport<FieldInspectionSummaryReportParams>(
    "api/reports/summary/fieldInspection",
    {
      onStartCallback: () => {
        fieldInspectionKey = notify.info("Generating Field Inspection Summary Report", true);
      },
      onSuccessCallback: () => {
        fieldInspectionKey && notify.scheduleDismiss(fieldInspectionKey);
        fieldInspectionKey = null;
      },
      onFailCallback: () => {
        fieldInspectionKey && notify.scheduleDismiss(fieldInspectionKey);
        fieldInspectionKey = null;
        notify.error("Failed to generate report");
      },
    }
  );

  const generateBulkValveReport = useAxiosGenerateReport<BulkValveHealthSummaryNodeReportParams>(
    "api/reports/summary/bulkValveHealth",
    {
      onStartCallback: () => {
        bulkValveKey = notify.info("Generating Bulk Valve Summary Report", true);
      },
      onSuccessCallback: () => {
        bulkValveKey && notify.scheduleDismiss(bulkValveKey);
        bulkValveKey = null;
      },
      onFailCallback: () => {
        bulkValveKey && notify.scheduleDismiss(bulkValveKey);
        bulkValveKey = null;
        notify.error("Failed to generate report");
      },
    }
  );

  return (
    <BackgroundReportContext.Provider value={{ generateFieldReport, generateBulkValveReport }}>
      {props.children}
    </BackgroundReportContext.Provider>
  );
};

export const useBackgroundReportContext = () => useContext(BackgroundReportContext);
