import { useState } from "react";
import {
   Box,
   Button,
   CircularProgress,
   LinearProgress,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Paper,
   Stack,
   Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { getImportModeDisplay, ImportEmptyValuesHandling, ImportMode, ImportType } from "./models";
import { useSaveCsvDeltasRequest, useUploadCsvRequest } from "./api";
import { UploadFileSection } from "./UploadFileSection";
import { CustomToolbar } from "app/mui/tables/CustomToolbar";
import { FeatureFlagName, pluralize, useFeatureEnabled } from "common";
import { useSiteContext, useWindowDimensions } from "app";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import ErrorIcon from "@mui/icons-material/Error";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { getGeneratedColumnsFromMetadata } from "app/mui/tables/metadata";
import { GridFooterContainer } from "@mui/x-data-grid-pro";

export const ImportCsvPage = () => {
   const site = useSiteContext().currentSite!;
   const windowDimensions = useWindowDimensions();
   const [importType, setImportType] = useState<ImportType>("Assets");
   const [emptyValuesHandling, setEmptyValuesHandling] = useState<ImportEmptyValuesHandling>("IgnoreField");
   const [settingsVersion, setSettingsVersion] = useState(0);
   const [uploadedSettingsVersion, setUploadedSettingsVersion] = useState(-1);
   const [savedSettingsVersion, setSavedSettingsVersion] = useState(-1);
   const useImportDataLayout = useFeatureEnabled(FeatureFlagName.UseImportDataLayout);

   const uploadCsvRequest = useUploadCsvRequest(importType, site.id);
   const uploadCsvResult =
      settingsVersion === uploadedSettingsVersion && !uploadCsvRequest.error ? uploadCsvRequest.data : null;
   const rows = uploadCsvResult?.rows ?? [];
   const rowsByImportMode = _.groupBy(rows, (r) => r.importMode);
   const saveableRows = (rowsByImportMode["Create"] ?? []).concat(rowsByImportMode["Update"] ?? []);
   const errorRows = rowsByImportMode["Error"] ?? [];

   const columns = uploadCsvResult?.muiColumns || [];
   const muiColumns = columns.length > 0 ? getGeneratedColumnsFromMetadata(columns, [site]) : [];
   const columnVisibilityModel = _.fromPairs((uploadCsvResult?.muiColumns ?? []).map((c) => [c.field, true]));

   const saveDeltasRequest = useSaveCsvDeltasRequest(importType, site.id);
   const savedResults = settingsVersion === savedSettingsVersion && !!uploadCsvResult ? saveDeltasRequest.data : null;
   const savedResultsByImportMode = !!savedResults ? _.groupBy(savedResults, (r) => r.importMode) : null;

   const updatedRows = rows.map((row) => {
      const savedResult = !!savedResults ? savedResults.find((r) => r.id === row.id) : null;
      if (!!savedResult) return { ...row, ...savedResult };

      return row;
   });

   const renderFooter = () => (
      <GridFooterContainer>
         {!uploadCsvRequest.loading && !!uploadCsvResult && (
            <Grid container alignItems="center" justifyContent="space-between">
               {!!!savedResultsByImportMode && (
                  <Grid>
                     <List dense={true}>
                        {_.toPairs(rowsByImportMode).map((r) => {
                           const importMode = r[0] as ImportMode;
                           const withOrTo = importMode === "Create" || importMode === "Update" ? "to" : "with";
                           return (
                              <ListItem key={importMode}>
                                 <ListItemIcon sx={{ minWidth: "24px" }}>
                                    {importMode === "NoChanges" && <InfoOutlinedIcon fontSize="small" />}
                                    {importMode === "Create" && <AddIcon fontSize="small" />}
                                    {importMode === "Update" && <SyncIcon fontSize="small" />}
                                    {importMode === "Error" && <ErrorIcon color="error" fontSize="small" />}
                                 </ListItemIcon>
                                 <ListItemText
                                    primary={`${r[1].length} ${pluralize(
                                       r[1].length,
                                       "row"
                                    )} ${withOrTo} ${_.lowerFirst(getImportModeDisplay(importMode))}`}
                                 />
                              </ListItem>
                           );
                        })}
                     </List>
                  </Grid>
               )}
               {!savedResults && (
                  <Grid sx={{ margin: 1 }}>
                     {!saveDeltasRequest.loading && (
                        <Button
                           color="primary"
                           variant="outlined"
                           onClick={() => {
                              setSavedSettingsVersion(settingsVersion);
                              saveDeltasRequest.call(
                                 saveableRows.map((r) => ({ id: r.id, updatedModel: r.updatedModel }))
                              );
                           }}
                           disabled={errorRows.length > 0 || saveableRows.length === 0}
                        >
                           Save {saveableRows.length} {pluralize(saveableRows.length, "row")}
                        </Button>
                     )}
                     {saveDeltasRequest.loading && <CircularProgress />}
                  </Grid>
               )}
               {!!savedResults && !!savedResultsByImportMode && (
                  <Grid flexGrow={1}>
                     <List dense={true}>
                        {_.toPairs(savedResultsByImportMode).map((r) => {
                           const importMode = r[0] as ImportMode;

                           const displayStatus =
                              importMode === "Create"
                                 ? "Created"
                                 : importMode === "Update"
                                    ? "Updated"
                                    : "Failed saving";

                           return (
                              <ListItem key={importMode}>
                                 <ListItemIcon sx={{ minWidth: "24px" }}>
                                    {importMode === "Create" && <AddIcon fontSize="small" />}
                                    {importMode === "Update" && <SyncIcon fontSize="small" />}
                                    {importMode === "Error" && <ErrorIcon color="error" fontSize="small" />}
                                 </ListItemIcon>
                                 <ListItemText
                                    primary={`${displayStatus} ${r[1].length} ${pluralize(r[1].length, "row")}`}
                                 />
                              </ListItem>
                           );
                        })}
                        {(savedResultsByImportMode["Error"] ?? []).flatMap((r) =>
                           r.errors.map((error, i) => (
                              <ListItem key={`${r.id}_${i}`}>
                                 <ListItemIcon sx={{ minWidth: "24px" }}>
                                    <ErrorIcon color="error" fontSize="small" />
                                 </ListItemIcon>
                                 <ListItemText primary={`Row ${r.id}: ${error}`} />
                              </ListItem>
                           ))
                        )}
                     </List>
                  </Grid>
               )}
            </Grid>
         )}
      </GridFooterContainer>
   );

   return (
      <Box sx={{ width: "100%" }}>
         <Stack spacing={2}>
            <Paper sx={{ width: "100%", minWidth: 680, p: 2 }}>
               <Stack spacing={2}>
                  {!useImportDataLayout &&
                     <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                           svg: {
                              fill: (theme) => theme.palette.primary.main,
                              width: "28px",
                              height: "28px",
                           },
                        }}
                     >
                        <UploadFileIcon />
                        <Typography variant="h5" fontWeight="bold" fontFamily="Montserrat">
                           Import CSV
                        </Typography>
                     </Stack>
                  }
                  <UploadFileSection
                     site={site}
                     emptyValuesHandling={emptyValuesHandling}
                     setEmptyValuesHandling={setEmptyValuesHandling}
                     importType={importType}
                     setImportType={setImportType}
                     onFileSelected={(file) => {
                        setUploadedSettingsVersion(settingsVersion);
                        const formData = new FormData();
                        formData.set("csvFile", file);
                        formData.set("emptyValuesHandling", emptyValuesHandling);
                        uploadCsvRequest.call(formData);
                     }}
                     isUploading={uploadCsvRequest.loading}
                     isDisabled={saveDeltasRequest.loading}
                     onSettingsVersionIncremented={(newSettingsVersion) => setSettingsVersion(newSettingsVersion)}
                  />
               </Stack>
            </Paper>
            {!uploadCsvRequest.loading && !!uploadCsvResult && (
               <Paper sx={{ p: 2 }}>
                  <Box sx={{ width: "100%", height: windowDimensions.height - 388 }}>
                     <StripedDataGrid
                        density="compact"
                        experimentalFeatures={{ newEditingApi: true }}
                        components={{
                           LoadingOverlay: LinearProgress,
                           Toolbar: CustomToolbar,
                           Footer: renderFooter,
                        }}
                        rows={updatedRows}
                        columns={muiColumns}
                        initialState={{
                           columns: {
                              columnVisibilityModel: {},
                              orderedFields: [],
                              dimensions: {},
                           },
                           sorting: {
                              sortModel: [
                                 {
                                    field: "importMode",
                                    sort: "asc",
                                 },
                              ],
                           },
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        getRowHeight={() => "auto"}
                     />
                  </Box>
               </Paper>
            )}
         </Stack>
      </Box>
   );
};
