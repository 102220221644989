import { Box, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { NotEnoughData } from "app/mui/common/placeholders/NotEnoughDataYetMessage";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import { CustomerDashboardViewModel } from "dashboard/models";
import { FunctionComponent } from "react";
import { WidgetSkeleton } from "./WidgetSkeleton";

interface HighRiskAssetsWidgetProps {
	data?: CustomerDashboardViewModel;
}

export const HighRiskAssetsWidget: FunctionComponent<HighRiskAssetsWidgetProps> = ({ data }) => {
	return (
		<Paper sx={{ p: { xs: 2, md: 3 }, width: "100%", height: "636px" }}>
			{!!!data ? (
				<WidgetSkeleton />
			) : (
				<Stack spacing={4} sx={{ height: "100%" }}>
					<Typography variant="h5">High Risk Assets</Typography>
					<Stack spacing={3} sx={{ height: "100%" }}>
						<HighRiskAssetsTable data={data} />
					</Stack>
				</Stack>
			)}
		</Paper>
	);
};

const GridComponents = {
	LoadingOverlay: LinearProgress,
	NoRowsOverlay: NotEnoughData,
	Toolbar: ToolbarWithTabViews("High-Risk-Assets"),
};

const HighRiskAssetsTable = (props: { data: CustomerDashboardViewModel }) => {
	return (
		<Box sx={{ height: "100%", width: "100%" }}>
			<MetadataColumnProvider type="HighRiskAssetViewModel" sites={props.data.sites.map((s) => s.site)}>
				{(columns) => (
					<StripedDataGrid
						density="compact"
						components={GridComponents}
						columns={columns}
						rows={props.data.highRiskAssets}
					/>
				)}
			</MetadataColumnProvider>
		</Box>
	);
};
