import { Box, Link, Paper, Typography } from "@mui/material";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import {
  createStaticActions,
  createStaticColumns,
  createStaticRowActions,
  useColumns,
  useStaticActions,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { useCallback, useEffect, useState } from "react";
import { AddEquipmentDialog } from "../dialogs/AddEquipmentDialog";
import { useGetOperatingSystemsRequest, useGetPackagedSolutionEquipmentsRequest } from "packagedSolution/api";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import React from "react";
import { formatRoutePath, Routes } from "app";
import { Link as RouterLink } from "react-router-dom";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { PackagedSolutionEquipmentModel } from "@bluemarvel/iris-common/model";
import { CreateEquipmentHealthDialog } from "../dialogs/CreateEquipmentHealthDialog";

interface PackagedSolutionEquipmentsProps {
  packagedSolutionId: number;
}

const sectionActions = createStaticActions((actionDispatcher) => [
  {
    icon: <AddBoxOutlinedIcon />,
    label: "Add Equipment",
    action: () => {
      actionDispatcher.dispatch("AddEquipment");
    },
  },
]);

const rowActions = createStaticRowActions((actionDispatcher) => (params) => [
  {
    icon: <CreateNewFolderRoundedIcon />,
    label: "Create Record",
    onClick: () => {
      actionDispatcher.dispatch("CreateRecord", params.row);
    },
    showInMenu: true,
  },
]);

export const PackagedSolutionEquipments = (props: PackagedSolutionEquipmentsProps) => {
  const { packagedSolutionId } = props;
  const [addEquipmentDialog, setAddEquipmentDialog] = useState(false);
  const [createRecordDialog, setCreateRecordDialog] = useState(false);
  const [equipmentForDialog, setEquipmentForDialog] = useState<PackagedSolutionEquipmentModel>();

  const operatingSystemsRequest = useGetOperatingSystemsRequest(packagedSolutionId);
  const operatingSystems = operatingSystemsRequest.data || [];

  const equipmentsRequest = useGetPackagedSolutionEquipmentsRequest(packagedSolutionId);
  const equipments = equipmentsRequest.data || [];

  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(sectionActions, actionDispatcher);
  const tableRowActions = useStaticRowActions(rowActions, actionDispatcher);

  const columnDef = createStaticColumns((actionDispatcher) => [
    // Name
    {
      field: "name",
      headerName: "Name",
      type: "string",
      valueGetter: (params) => params.row.name,
      flex: 2,
    },
    // Assets
    {
      field: "assets",
      headerName: "Assets",
      flex: 3,
      renderCell: (params) =>
        params.row.assets.map((asset, index) => (
          <React.Fragment key={asset.id}>
            <Link
              color="secondary"
              component={RouterLink}
              to={formatRoutePath(Routes.Asset, { siteId: asset.siteId, id: asset.id })}
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis" }} component="span">
                {asset.tag}
              </Typography>
            </Link>
            {index < params.row.assets.length - 1 ? ", " : ""}
          </React.Fragment>
        )),
    },
    // Record
    {
      field: "record",
      headerName: "Record",
      flex: 2,
      renderCell: (params) =>
        params.row.latestRecord && (
          <Link
            color="secondary"
            component={RouterLink}
            to={formatRoutePath(Routes.Record, params.row.latestRecord)}
            sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
            state={{ returnTo: window.location.pathname }}
          >
            {params.row.latestRecord.description}
          </Link>
        ),
    },
    // Operating System
    {
      field: "operatingSystem",
      headerName: "Operating System",
      type: "string",
      valueGetter: (params) => operatingSystems.find((os) => os.id === params.row.operatingSystemId)?.name,
      flex: 2,
    },
    // Action
    {
      field: "actions",
      type: "actions",
      width: 60,
      getActions: tableRowActions,
      hideable: false,
    },
  ]);

  const columns = useColumns(columnDef, actionDispatcher);
  const apiRef = useGridApiRef();

  const handleAction = useCallback((action: string, params: PackagedSolutionEquipmentModel) => {
    if (action === "AddEquipment") {
      setAddEquipmentDialog(true);
    }
    if (action === "CreateRecord") {
      setEquipmentForDialog(params);
      setCreateRecordDialog(true);
    }
  }, []);

  useEffect(() => apiRef.current.updateColumns(columnDef(actionDispatcher)), [operatingSystems]);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  return (
    <Paper>
      <ExpansionPanel title="Equipment" rightSideComponent={<ActionsDropdownMenu actions={actions} />}>
        <Box sx={{ p: 2, height: "350px", width: "100%" }}>
          <StripedDataGrid
            apiRef={apiRef}
            density="compact"
            loading={equipmentsRequest.loading}
            columns={columns}
            rows={equipments}
          />
        </Box>
      </ExpansionPanel>
      {addEquipmentDialog && (
        <AddEquipmentDialog
          packagedSolutionId={packagedSolutionId}
          operatingSystems={operatingSystems}
          onClose={() => setAddEquipmentDialog(false)}
        />
      )}
      {createRecordDialog && equipmentForDialog && (
        <CreateEquipmentHealthDialog
          equipment={equipmentForDialog}
          onClose={() => setCreateRecordDialog(false)}
          onComplete={() => equipmentsRequest.call()}
        />
      )}
    </Paper>
  );
};
