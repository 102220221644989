import BuildRoundedIcon from "@mui/icons-material/BuildRounded";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import { Tooltip, styled } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useBusinessArea } from "../BusinessAreaContext";
import { BusinessArea } from "assets/models/businessArea";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  height: "28px",
});

export const BusinessAreaFilterToggle = () => {
  const { businessArea, setBusinessArea } = useBusinessArea();

  const handleToggleChanged = (_event: React.MouseEvent<HTMLElement>, newValue: BusinessArea | "all") => {
    if (newValue === "all") {
      setBusinessArea(undefined);
    } else {
      setBusinessArea(newValue);
    }
  };

  const value = businessArea === undefined ? "all" : businessArea;

  return (
    <StyledToggleButtonGroup value={value} exclusive onChange={handleToggleChanged} size="small">
      <ToggleButton value={"all"}>All Assets</ToggleButton>
      <ToggleButton value={BusinessArea.Instrumentation}>
        <Tooltip title={"Instrumentation"} placement="bottom">
          <SpeedRoundedIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={BusinessArea.Machinery}>
        <Tooltip title={"Machinery"} placement="bottom">
          <BuildRoundedIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};
