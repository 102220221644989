import React, { PropsWithChildren } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsightsReactPlugin } from "./contexts/AppInsightsContextProvider";

export interface IrisErrorBoundaryProps extends PropsWithChildren {
   boundaryName?: string;
}

export class IrisErrorBoundary extends React.Component<IrisErrorBoundaryProps> {
   state = { hasError: false };

   componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      const customProps = !!this.props.boundaryName ? { boundaryName: this.props.boundaryName! } : {};
      appInsightsReactPlugin.trackException({
         error: error,
         exception: error,
         severityLevel: SeverityLevel.Error,
         properties: { ...errorInfo, ...customProps },
      });
   }

   render() {
      return this.props.children;
   }
}
