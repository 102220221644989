import { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2";
import { useUiThemeContext } from "app/contexts/UiThemeContext";
import { ThemeHighlighter } from "app/mui/common/hightlight/ThemeHighlighter";

interface SearchResultProps {
   icon: React.ReactNode;
   title: string;
   description: string;
   siteName: string;
   additionalDetails: string;
   badges: React.ReactNode[];
   searchWords: string[];
}

export const SearchResultComponent: FunctionComponent<SearchResultProps> = (props) => {

   const isDarkTheme = useUiThemeContext().header === "dark";

   return (
      <>
         <Grid container spacing={2} width="100%">
            <Grid xs={8} lg={9}>
               <Stack direction="column">
                  <Stack
                     direction="row"
                     justifyContent="flex-start"
                     alignItems="center"
                     spacing={1}
                     sx={{
                        svg: { fill: (theme) => isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main, width: "24px", height: "24px" },
                        width: "100%",
                     }}
                  >
                     <Box display="flex" alignItems="center">
                        {props.icon}
                     </Box>
                     <Typography
                        variant="subtitle2"
                        fontSize={"1.3em"}
                        sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                     >
                        <ThemeHighlighter
                           isDarkTheme={isDarkTheme}
                           className="tag"
                           searchWords={props.searchWords}
                           autoEscape={true}
                           textToHighlight={props.title}
                        />
                     </Typography>
                  </Stack>
                  <Typography variant="caption">
                     <ThemeHighlighter
                        isDarkTheme={isDarkTheme}
                        searchWords={props.searchWords}
                        autoEscape={true}
                        textToHighlight={props.description}
                     />
                  </Typography>
               </Stack>
            </Grid>
            <Grid xs={4} lg={3}>
               <Stack direction="column" alignContent="flex-end" justifyContent="flex-end" textAlign="right">
                  <Typography variant="caption">{props.siteName}</Typography>
                  <Typography variant="caption">
                     <ThemeHighlighter
                        isDarkTheme={isDarkTheme}
                        className="tag"
                        searchWords={props.searchWords}
                        autoEscape={true}
                        textToHighlight={props.additionalDetails}
                     />
                  </Typography>
               </Stack>
            </Grid>
            <Grid xs={12}>
               <Stack spacing={1} direction="row" justifyContent="flex-start">
                  {props.badges}
               </Stack>
            </Grid>
         </Grid>
      </>
   );
};
