import React from "react";
import { Divider, Drawer, IconButton, styled } from "@mui/material";
import { SiteSpecificMenu } from "./SiteSpecificMenu";
import { useSiteContext } from "app/contexts/SiteContext";
import CloseIcon from "@mui/icons-material/Close";
import { useUiThemeContext } from "app/contexts/UiThemeContext";

interface MobileMenuDrawerProps {
   open: boolean;
   requestClose: () => void;
}

const DrawerHeader = styled("div")(({ theme }) => ({
   display: "flex",
   alignItems: "center",
   padding: theme.spacing(0, 1),
   // necessary for content to be below app bar
   ...theme.mixins.toolbar,
   justifyContent: "flex-end",
}));

export const MobileMenuDrawer = (props: MobileMenuDrawerProps) => {
   const { currentSiteWithNavigation } = useSiteContext();
   const isDarkTheme = useUiThemeContext().navbar === "dark";

   return (
      <Drawer
         open={props.open}
         variant="temporary"
         anchor="left"
         PaperProps={{
            sx: {
               width: { xs: "80%", sm: "50%" },
               background: (theme) => isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper,
            },
         }}
      >
         <DrawerHeader
            sx={{
               background: (theme) => isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper,
            }}
         >
            <IconButton
               sx={{
                  color: (theme) => isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main,
               }}
               onClick={() => props.requestClose()}
            >
               <CloseIcon />
            </IconButton>
         </DrawerHeader>
         <Divider />
         <SiteSpecificMenu expanded={true} currentSiteWithNavigation={currentSiteWithNavigation} />
      </Drawer>
   );
};
