import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography, MenuItem } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BaseDialog } from "app/mui/common/dialogs/BaseDialog";
import { useCreateSummaryRequest } from "summaries/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import SplitButton from "app/mui/SplitButton";
import { useNavigate } from "react-router-dom";
import { formatRoutePath, Routes, useSiteContext } from "app";
import { SummaryStatus, SummaryType } from "summaries";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { EventsLoader } from "app/mui/loaders/EventsLoader";

const validationSchema = yup.object({
  title: yup.string().nullable().required("Title is required"),
  date: yup.date().nullable().required("Date is required"),
  summaryType: yup.string().nullable().required("Summary Type is required"),
  event: yup
    .mixed()
    .nullable()
    .when("summaryType", {
      is: SummaryType.OutageScope,
      then: yup.mixed().nullable().required("Event is required"),
    }),
});

export const CreateSummaryDialog = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const { currentSiteWithNavigation } = useSiteContext();
  const createSummaryRequest = useCreateSummaryRequest();

  const methods = useForm({
    defaultValues: { title: "", date: new Date(), summaryType: "", event: null },
    resolver: yupResolver(validationSchema),
  });

  const getSummaryTypes = () => {
    const allSummaryTypes = [SummaryType.General, SummaryType.BulkValveHealthType, SummaryType.OutageScope];

    return allSummaryTypes.map((type) => ({
      id: type,
      label: type,
    }));
  };

  const onSubmit = (options?: { navigate?: boolean }) => {
    if (!currentSiteWithNavigation) return;

    methods.handleSubmit((values) => {
      const selectedSummaryType = values.summaryType as SummaryType;

      createSummaryRequest
        .call({
          siteId: currentSiteWithNavigation.id,
          title: values.title,
          summaryDate: values.date,
          summaryType: selectedSummaryType,
          status: SummaryStatus.Open,
          recordsEventId: selectedSummaryType === SummaryType.OutageScope ? values.event : null,
        })
        .then((newSummary) => {
          if (options?.navigate) {
            navigate(formatRoutePath(Routes.Summary, { siteId: newSummary.siteId, id: newSummary.id }));
          } else {
            onClose();
            window.location.reload();
          }
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6">{"Create summary"}</Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
        <FormProvider {...methods}>
          <Stack spacing={2}>
            <AutocompleteFormItem
              label="Summary Type"
              fieldName="summaryType"
              options={getSummaryTypes()}
              autocompleteProps={{ disablePortal: true }}
            />

            <TextFieldFormItem label="Title" fieldName="title" />
            <DateInputFormItem label="Summary date" fieldName="date" />

            {methods.watch("summaryType") === SummaryType.OutageScope && (
              <EventsLoader>
                {(options) => <AutocompleteFormItem label="Event" fieldName="event" {...options} />}
              </EventsLoader>
            )}
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={onClose}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained" }}
            primaryButton={<Button onClick={() => onSubmit({ navigate: true })}>Create And Open</Button>}
            additionalActions={[<MenuItem onClick={() => onSubmit()}>Create</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
