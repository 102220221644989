import { AutocompleteFormItem, AutocompleteFormItemProps, Option } from "app/mui/forms/AutocompleteFormItem";
import { probabilityOfDowntimeOptions } from "kpis/forms/EditKpiOneTimeDowntimeAvoidanceForm";

interface ProbabilityOfDowntimeFormItemProps extends Omit<AutocompleteFormItemProps<false, false, Option>, "options"> {
  readOnly?: boolean;
}

export function ProbabilityOfDowntimeFormItem(props: ProbabilityOfDowntimeFormItemProps) {
  const { label = "Probability of downtime", readOnly, autocompleteProps, ...rest } = props;

  const options = probabilityOfDowntimeOptions.map((x) => ({ label: x.label, id: x.value }));

  return (
    <AutocompleteFormItem
      label={label}
      options={options}
      autocompleteProps={{ disabled: readOnly, ...autocompleteProps }}
      {...rest}
    />
  );
}
