import * as React from "react";
import { Card, CardTitle, Input, Button, Collapse } from "reactstrap";
import classNames from "classnames";
import _ from "lodash";
import { CheckmarkIcon } from "../icons/CheckmarkIcon";
import { AddIcon } from "../icons/AddIcon";
import { formatRoutePath, Routes } from "../app/routes";
import { Link } from "react-router-dom";
import { DeleteIcon } from "../icons/DeleteIcon";
import { CollapseIcon } from "../icons/CollapseIcon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAssetDigestsSearchRequest } from "search";
import { useDebounce, useUserContext } from "app";
import { toAssetTypeDisplay } from "assets/models/assetType";
import { getBusinessAreaForAssetType } from "assets/models/businessArea";
import { AssetDigest } from "assets/models/asset";

interface Props {
  assets: AssetDigest[];
  siteId: number;
  readOnly: boolean;
  assetFilter?: (asset: AssetDigest) => boolean;
  addAsset: (asset: AssetDigest) => void;
  removeAsset: (asset: AssetDigest) => void;
  assetWarning?: (asset: AssetDigest) => JSX.Element | null;
  parentDescriptor: string;
  hideHint?: boolean;
}

export const AssetSelector: React.FunctionComponent<Props> = (props) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { userPermissions } = useUserContext();

  const [assetSearchPhrase, setAssetSearchPhrase] = useState("");
  const [preexistingAssets] = useState(props.assets);
  const [expanded, setExpanded] = useState(true);
  const [hasAttemptedSearch, setHasAttemptedSearch] = useState(false);

  const debouncedSearchPhrase = useDebounce(assetSearchPhrase, 700);

  const assetSearchRequest = useAssetDigestsSearchRequest();

  const performSearch = useCallback(
    (query: string) => {
      if (isMounted.current) {
        assetSearchRequest.call({
          query,
          siteId: props.siteId,
          businessArea: props.assets.length > 0 ? getBusinessAreaForAssetType(props.assets[0].assetType) : null,
        });
      }
    },
    [assetSearchRequest]
  );

  useEffect(() => {
    if (debouncedSearchPhrase.length >= 3) {
      performSearch(debouncedSearchPhrase);
      setHasAttemptedSearch(true);
    } else {
      setHasAttemptedSearch(false);
      if (assetSearchRequest.loading) assetSearchRequest.cancel();
    }
  }, [debouncedSearchPhrase]);

  const matchingAssets = hasAttemptedSearch ? assetSearchRequest.data?.results ?? [] : [];
  const filteredAssets = matchingAssets.filter(props.assetFilter || ((_) => true));
  const newAssets = filteredAssets.filter(
    (a) => preexistingAssets.every((pa) => pa.id !== a.id) || props.assets.every((aa) => aa.id !== a.id)
  );
  const assetsToShow = 12;
  const sortedAssets = _.take(
    _.orderBy(newAssets, (a) => a.tag),
    assetsToShow
  );

  const renderAssetsByCategory = (siteId: number) => {
    const assetsByCategory: [string, AssetDigest[]][] = _(props.assets)
      .groupBy((a) => toAssetTypeDisplay(a.assetType))
      .toPairs()
      .sortBy((pair) => pair[0])
      .value();

    return (
      <div className="items-by-category">
        {assetsByCategory.map((pair) => {
          const type = pair[0];
          const assets = pair[1];

          return (
            <div className="item-group" key={type}>
              <div className="category">{type}</div>
              <div className="items">
                {_(assets)
                  .orderBy((a) => a.tag)
                  .value()
                  .map((asset) => (
                    <div className="item" key={asset.id}>
                      {userPermissions.userCanViewAsset ? (
                        <Link to={formatRoutePath(Routes.Asset, { siteId, id: asset.id })}>
                          <div className="tag" title={asset.serviceDescription}>
                            {asset.tag}
                          </div>
                        </Link>
                      ) : (
                        <div className="tag" title={asset.serviceDescription}>
                          {asset.tag}
                        </div>
                      )}
                      {props.assetWarning ? props.assetWarning(asset) : null}
                      {!props.readOnly && (
                        <Button className="delete-button" onClick={() => props.removeAsset(asset)}>
                          <DeleteIcon />
                        </Button>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Card body className="asset-selector">
      <CardTitle
        className={classNames("collapse-toggle", "d-flex", "align-items-center", {
          collapsed: !expanded,
        })}
        onClick={() => setExpanded(!expanded)}
      >
        <CollapseIcon expanded={expanded} />
        <div className="title">Assets</div>
        <div className="ml-auto counter">{props.assets.length}</div>
      </CardTitle>
      <Collapse isOpen={expanded}>
        {!props.readOnly && (
          <>
            <Input
              placeholder="Search by tag to add assets"
              className={classNames({ "no-results": props.assets.length === 0 && !props.hideHint })}
              value={assetSearchPhrase}
              onChange={(e) => setAssetSearchPhrase(e.target.value)}
            />
            {!!matchingAssets && (
              <div className="add-buttons">
                {sortedAssets.map((asset) => {
                  const assetAlreadyAdded = props.assets.some((a) => a.id === asset.id);
                  return (
                    <Button
                      key={asset.id}
                      color="primary"
                      size="sm"
                      onClick={() => props.addAsset(asset)}
                      disabled={assetAlreadyAdded}
                    >
                      {assetAlreadyAdded ? <CheckmarkIcon /> : <AddIcon />}
                      <span className="tag">{asset.tag}</span>
                    </Button>
                  );
                })}
                {hasAttemptedSearch && !assetSearchRequest.loading && sortedAssets.length === 0 && (
                  <div className="text-info">No eligible assets found.</div>
                )}
              </div>
            )}
          </>
        )}
        {props.assets.length > 0
          ? renderAssetsByCategory(props.siteId)
          : !props.hideHint && <div className="text-info">Add some assets to the {props.parentDescriptor}.</div>}
      </Collapse>
    </Card>
  );
};
