import { Link as RouterLink } from "react-router-dom";
import { Link, LinkProps } from "@mui/material";

interface InternalLinkProps extends Omit<LinkProps, "component" | "color" | "sx" | "target"> {
  to: string;
  openInNewTab?: boolean;
}

export function InternalLink(props: InternalLinkProps) {
  const { openInNewTab: newTab = false, ...linkProps } = props;

  return (
    <Link
      component={RouterLink}
      target={newTab ? "_blank" : undefined}
      color="secondary"
      sx={{ fontWeight: "bold" }}
      {...linkProps}
    />
  );
}
