import { User } from "users";
import { RecordFlagType } from "flags";
import { Keyword } from "keywords";
import { ValueKpiType } from "kpis";
import { Status, RecordType } from ".";

export class RecordFilters {
  public siteId: number | null = null;
  public ids: number[] = [];
  public areaIds: number[] = [];
  public assetIds: number[] = [];
  public businessArea: string | null = null;
  public createdBy: User[] = [];
  public criticalityIds: number[] = [];
  public descriptionContains: string | null = null;
  public recordTypes: RecordType[] = [];
  public closedFrom: Date | null = null;
  public closedTo: Date | null = null;
  public createdFrom: Date | null = null;
  public createdTo: Date | null = null;
  public eventDateFrom: Date | null = null;
  public eventDateTo: Date | null = null;
  public followUpFrom: Date | null = null;
  public followUpTo: Date | null = null;
  public flags: RecordFlagType[] = [];
  public keywords: Keyword[] = [];
  public overallVisualIntegrityOptions: string[] = [];
  public recommendationIds: number[] = [];
  public recordsEventIds: number[] = [];
  public statuses: Status[] = [];
  public tagFragment: string | null = null;
  public withoutKpis = false;
}

export class KpiValueFilters {
  public kpiTypes: ValueKpiType[] = [];
  public hasQbr: boolean | null = null;
  public qbrNumbers: string[] = [];
  public signedOff: boolean | null = null;
  public templateIds: string[] = [];
  public businessArea: string | null = null;
}

//REMOVE ME when records list is removed
export class OutageScopeRecordsFilters {
  public recordsEventId: number | null = null;
  public scopesOfWork: string[] = [];
}

export class RecommendationFilters {
  public titleFragments: string[] = [];
  public statuses: Status[] = [];
  public businessArea: string | null = null;
}

export interface RecordsListFilterLookupValuesViewModel {
  outageScopesOfWork: string[];
}
