import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid";

export interface GridFilterSelectOption {
  value: any;
  label: string;
}

export declare type GridFilterInputMultiSelectProps = GridFilterInputValueProps & {
  selectOptions: GridFilterSelectOption[];
};

const MultiFilterInputSelect = (props: GridFilterInputMultiSelectProps) => {
  const { item, applyValue, selectOptions } = props;

  const handleOnChange = (_event: React.SyntheticEvent, value: Array<GridFilterSelectOption>) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      sx={{ mt: 1, ml: 1 }}
      multiple
      size={"small"}
      options={selectOptions}
      getOptionLabel={(o) => o.label}
      onChange={handleOnChange}
      value={item.value || []}
      renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} />}
    />
  );
};

export default MultiFilterInputSelect;
