import { useSiteIdFromRoute } from "app/routes";
import { TemplateKpiType, toKpiTypeDisplayName } from "kpis";
import { useGetFilteredKpiTemplateDigestsInSiteRequest } from "kpis/api";
import _ from "lodash";
import { useEffect } from "react";
import { AutocompleteFormItem, AutocompleteFormItemProps } from "../AutocompleteFormItem";

type KpiTemplateSelectOptionsFormat = {
  value: string;
  label: string;
  rowId: number;
  type: string;
  typeDisplayValue: string;
};

interface KpiTemplateSelectFormItemProps
  extends Omit<AutocompleteFormItemProps<true, false, KpiTemplateSelectOptionsFormat>, "options"> {
  kpiType?: TemplateKpiType;
  usedTemplateIds?: string[];
}

export const KpiTemplateSelectFormItem = (props: KpiTemplateSelectFormItemProps) => {
  const siteId = useSiteIdFromRoute()!;
  const kpiTemplatesQuery = useGetFilteredKpiTemplateDigestsInSiteRequest(siteId);

  const { kpiType, usedTemplateIds, ...rest } = props;

  useEffect(() => {
    kpiTemplatesQuery.call({ siteId, enabled: true });
  }, []);

  const availableTemplates =
    kpiTemplatesQuery.data?.filter(
      (t) => (!kpiType || t.kpiType === kpiType) && (!usedTemplateIds || usedTemplateIds.indexOf(t.id) === -1)
    ) || [];

  const kpiTemplateOptions = availableTemplates.map((t) => ({
    value: t.id,
    label: t.name,
    rowId: t.rowId,
    type: t.kpiType,
    typeDisplayValue: toKpiTypeDisplayName(t.kpiType),
  }));

  return (
    <AutocompleteFormItem
      options={kpiTemplateOptions}
      loading={kpiTemplatesQuery.loading}
      // override the id selector to return the whole object to react-hook-form
      idSelector={(kpiTemplate) => kpiTemplate}
      {...rest}
    />
  );
};
