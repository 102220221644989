import { Grid, Stack } from "@mui/material";
import { Routes } from "app";
import { useGetPackagedSolutionByIdRequest } from "packagedSolution/api";
import { useMatch } from "react-router-dom";
import { PackagedSolutionDetails } from "./PackagedSolutionDetails";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";
import { PackagedSolutionReportCard } from "./sections/PackagedSolutionReportCard";
import { PackagedSolutionTabSections } from "./PackagedSolutionTabSections";
import { OperatingSystems } from "./sections/OperatingSystems";
import { PackagedSolutionHeader } from "./PackagedSolutionDetailsHeader";
import { PackagedSolutionEquipments } from "./sections/PackagedSolutionEquipment";

export const PackagedSolutionPage = () => {
  const routeMatch = useMatch(Routes.PackagedSolution);

  const { data: packagedSolution, loading } = useGetPackagedSolutionByIdRequest(Number(routeMatch?.params?.id));

  return (
    <Grid container spacing={2}>
      {loading || !packagedSolution ? (
        <EntityDetailsContainerSkeleton />
      ) : (
        <>
          <Grid item xs={12} lg={8} xl={9}>
            <Stack spacing={2}>
              <PackagedSolutionHeader packagedSolutionDetail={packagedSolution} />
              <PackagedSolutionDetails packagedSolutionDetail={packagedSolution} />
              <OperatingSystems packagedSolutionId={packagedSolution.id} />
              <PackagedSolutionEquipments packagedSolutionId={packagedSolution.id} />
              <PackagedSolutionTabSections packagedSolutionDetail={packagedSolution} />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4} xl={3}>
            <Stack spacing={2} direction={{ xs: "column", md: "row", lg: "column" }}>
              {packagedSolution?.reportCardPackagedSolution && (
                <PackagedSolutionReportCard
                  packagedSolutionId={packagedSolution.id}
                  reportCard={packagedSolution.reportCardPackagedSolution}
                />
              )}
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  );
};
