import * as React from "react";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { Controller } from "react-hook-form";
import { Criticality } from "sites";
import { CriticalitySelector } from "../CriticalitySelector";

export interface CriticalityFormItemProps {
   label?: string;
   hint?: string;
   fieldName: string;
   criticalities: Criticality[];
}

export const CriticalityFormItem = (props: CriticalityFormItemProps) => {
   return (
      <Controller
         name={props.fieldName}
         render={({ field: { onChange, value, ...fieldProps }, fieldState }) => (
            <FormItemLayout controlled hint={props.hint} label={props.label ?? ""}>
               {(inputProps) => (
                  <CriticalitySelector
                     criticalities={props.criticalities}
                     value={value}
                     onChange={onChange}
                     disabled={inputProps.disabled}
                  />
               )}
            </FormItemLayout>
         )}
      />
   );
};
