import React from "react";
import { SummaryDetail } from "summaries";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { StandardModalFooter } from "app/bs/StandardModalFooter";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import _ from "lodash";
import { FunctionComponent, useState } from "react";
import Select from "react-select";
import {
   OutageRecordMilestoneFieldLabelOptions,
   useGetRecordDetailsByRecordsEventQuery,
   useGetRecordOutageDetailsLookupValuesByRecordsEventIdQuery,
} from "records";
import {
   RecordOutageDetailsLookupValuesViewModel,
   RecordOutageEvaluationScopeOutcome,
   RecordOutageEvaluationScopeOutcomeOptions,
   RecordOutageScopePhase,
   RecordOutageScopePhaseOptions,
} from "records/bs/outage/models";
import { useGetAssetsByIdsRequest } from "assets";
import { useSiteContext } from "app";
import {
   OutageSummaryReportParams,
   OutageSummaryReportParamsWithFilters,
   useOutageSummaryReportUrlBuilder,
} from "common/reports";

interface Props {
   summary: SummaryDetail;
   close: () => void;
}

interface RecordsFilters {
   areaIds: number[];
   vendor: string | null;
   phase: RecordOutageScopePhase | null;
   milestone: string | null;
   scopeOfWork: string | null;
   scopeOutcome: RecordOutageEvaluationScopeOutcome | null;
}

export const ConfigureOutageScopeSummaryReportModal: React.FunctionComponent<Props> = (props) => {
   const [includeOverallPhaseProgress, setIncludeOverallPhaseProgress] = useState(true);
   const [includePhaseProgressByArea, setIncludePhaseProgressByArea] = useState(true);
   const [includeAssetsByVendor, setIncludeAssetsByVendor] = useState(true);
   const [includeScopeOptimizationSummary, setIncludeScopeOptimizationSummary] = useState(true);
   const [includeScopeOptimizationByType, setIncludeScopeOptimizationByType] = useState(true);
   const [includeExecutionMilestoneSummary, setIncludeExecutionMilestoneSummary] = useState(false);
   const [includeLongestMilestoneDelays, setIncludeLongestMilestoneDelays] = useState(false);
   const [includeEvaluationSummary, setIncludeEvaluationSummary] = useState(false);
   const [includeRecordDetails, setIncludeRecordDetails] = useState(false);
   const [includeAssetPhotos, setIncludeAssetPhotos] = useState(false);

   const [selectRecordsToIncludeInReport, setSelectRecordsToIncludeInReport] = useState(false);
   const [filters, setFilters] = useState<RecordsFilters>({
      areaIds: [],
      vendor: null,
      phase: null,
      milestone: null,
      scopeOfWork: null,
      scopeOutcome: null,
   });

   const reportParams: OutageSummaryReportParams = {
      pdf: false,
      appendPdfAttachments: false,
      id: props.summary.id,
      includeOverallPhaseProgress,
      includePhaseProgressByArea,
      includeAssetsByVendor,
      includeScopeOptimizationSummary,
      includeScopeOptimizationByType,
      includeExecutionMilestoneSummary,
      includeLongestMilestoneDelays,
      includeEvaluationSummary,
      includeRecordDetails,
      includeAssetPictures: includeAssetPhotos,
   };

   const reportParamsWithFilters: OutageSummaryReportParamsWithFilters = {
      ...reportParams,
      areaIds: !!filters.areaIds && !!filters.areaIds.length ? filters.areaIds.join(",") : null,
      milestone: filters.milestone,
      phase: filters.phase,
      scopeOfWork: filters.scopeOfWork,
      scopeOutcome: filters.scopeOutcome,
      vendor: filters.vendor,
   };

   const reportUrl = useOutageSummaryReportUrlBuilder(
      selectRecordsToIncludeInReport ? reportParamsWithFilters : reportParams
   );

   const { data: lookupValues } = useGetRecordOutageDetailsLookupValuesByRecordsEventIdQuery(
      props.summary.model.recordsEventId || 0
   );

   return (
      <Modal
         isOpen={true}
         size="lg"
         className="configure-outage-summary-report-modal"
         container={"#bootstrap-modal-container"}
      >
         <ModalHeader>Report options</ModalHeader>
         <ModalBody>
            <Form>
               <p className="mt-0">Progress</p>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeOverallPhaseProgress}
                        onChange={() => {
                           setIncludeOverallPhaseProgress(!includeOverallPhaseProgress);
                        }}
                     />
                     Include overall phase progress
                  </Label>
               </FormGroup>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includePhaseProgressByArea}
                        onChange={() => {
                           setIncludePhaseProgressByArea(!includePhaseProgressByArea);
                        }}
                     />
                     Include phase progress by area
                  </Label>
               </FormGroup>
               <p>Planning</p>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeAssetsByVendor}
                        onChange={() => {
                           setIncludeAssetsByVendor(!includeAssetsByVendor);
                        }}
                     />
                     Include assets by vendor
                  </Label>
               </FormGroup>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeScopeOptimizationSummary}
                        onChange={() => {
                           setIncludeScopeOptimizationSummary(!includeScopeOptimizationSummary);
                        }}
                     />
                     Include scope optimization summary
                  </Label>
               </FormGroup>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeScopeOptimizationByType}
                        onChange={() => {
                           setIncludeScopeOptimizationByType(!includeScopeOptimizationByType);
                        }}
                     />
                     Include scope optimization by type
                  </Label>
               </FormGroup>
               <p>Execution</p>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeExecutionMilestoneSummary}
                        onChange={() => {
                           setIncludeExecutionMilestoneSummary(!includeExecutionMilestoneSummary);
                        }}
                     />
                     Include execution milestone summary
                  </Label>
               </FormGroup>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeLongestMilestoneDelays}
                        onChange={() => {
                           setIncludeLongestMilestoneDelays(!includeLongestMilestoneDelays);
                        }}
                     />
                     Include longest milestone delays
                  </Label>
               </FormGroup>
               <p>Evaluation</p>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeEvaluationSummary}
                        onChange={() => {
                           setIncludeEvaluationSummary(!includeEvaluationSummary);
                        }}
                     />
                     Include evaluation summary
                  </Label>
               </FormGroup>
               <p>Records details</p>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeRecordDetails}
                        onChange={() => {
                           setIncludeRecordDetails(!includeRecordDetails);
                        }}
                     />
                     Include record details
                  </Label>
               </FormGroup>
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={includeRecordDetails && includeAssetPhotos}
                        onChange={() => {
                           setIncludeAssetPhotos(!includeAssetPhotos);
                        }}
                        disabled={!includeRecordDetails}
                     />
                     Include asset assembly photos
                  </Label>
               </FormGroup>
               <hr />
               <FormGroup>
                  <Label check>
                     <Input
                        type="checkbox"
                        checked={selectRecordsToIncludeInReport}
                        onChange={() => {
                           setSelectRecordsToIncludeInReport(!selectRecordsToIncludeInReport);
                        }}
                     />
                     Select records to include in report
                  </Label>
               </FormGroup>
               {selectRecordsToIncludeInReport && (
                  <ConfigureOutageScopeSummaryReportFilters
                     filters={filters}
                     lookupValues={lookupValues}
                     summary={props.summary}
                     updateFilters={(changes) => setFilters({ ...filters, ...changes })}
                  />
               )}
            </Form>
         </ModalBody>
         <StandardModalFooter
            confirmDisabled={!reportUrl}
            confirm={() => {
               window.open(reportUrl!, "_blank");
               props.close();
            }}
            cancel={props.close}
         />
      </Modal>
   );
};

interface FiltersProps {
   summary: SummaryDetail;
   filters: RecordsFilters;
   lookupValues: RecordOutageDetailsLookupValuesViewModel | null;
   updateFilters: (filters: Partial<RecordsFilters>) => void;
}

const ConfigureOutageScopeSummaryReportFilters: FunctionComponent<FiltersProps> = (props) => {
   const currentSite = useSiteContext().currentSite!;

   const recordsQuery = useGetRecordDetailsByRecordsEventQuery(props.summary.model.recordsEventId);
   const records = recordsQuery.data ?? [];

   const assets = records.flatMap((r) => r.model.assets);
   const allAssetIds = _.uniq(assets.map((a) => a.id));
   const allAssetDetailsRequest = useGetAssetsByIdsRequest(allAssetIds);
   const allAssetDetails = allAssetDetailsRequest.data ?? [];
   const allAssetAreas = currentSite.areas.filter(
      (area) => !!allAssetDetails.find((asset) => asset.model.areaId === area.id)
   );

   const areaOptions = allAssetAreas.map((area) => ({ label: area.name, value: area.id }));

   const primaryScopesOfWork =
      props.lookupValues?.scopesOfWork.map((s) => ({
         value: s.value,
         label: s.value,
         isDisabled: s.usageCount === 0,
      })) || [];
   const vendorOptions =
      props.lookupValues?.vendors.map((v) => ({ value: v.value, label: v.value, isDisabled: v.usageCount === 0 })) ||
      [];

   return (
      <>
         <Row>
            <Col xs={6}>
               <FormGroup>
                  <Label>Area</Label>
                  <Select
                     isMulti
                     className="react-select"
                     classNamePrefix="react-select"
                     value={areaOptions.filter((o) => props.filters.areaIds.indexOf(o.value) >= 0)}
                     onChange={(selections) => props.updateFilters({ areaIds: selections.map((s) => s.value) })}
                     options={areaOptions}
                     menuPlacement="top"
                     isClearable={true}
                  />
               </FormGroup>
            </Col>
            <Col xs={6}>
               <FormGroup>
                  <Label>Vendor</Label>
                  <Select
                     className="react-select"
                     classNamePrefix="react-select"
                     value={_.first(vendorOptions.filter((o) => o.value === props.filters.vendor))}
                     onChange={(selection) => props.updateFilters({ vendor: !!selection ? selection.value : null })}
                     options={vendorOptions}
                     menuPlacement="top"
                     isClearable={true}
                  />
               </FormGroup>
            </Col>
            <Col xs={6}>
               <FormGroup>
                  <Label>Phase</Label>
                  <Select
                     className="react-select"
                     classNamePrefix="react-select"
                     value={_.first(RecordOutageScopePhaseOptions.filter((o) => o.value === props.filters.phase))}
                     onChange={(selection) => props.updateFilters({ phase: !!selection ? selection.value : null })}
                     options={RecordOutageScopePhaseOptions}
                     menuPlacement="top"
                     isClearable={true}
                  />
               </FormGroup>
            </Col>
            <Col xs={6}>
               <FormGroup>
                  <Label>Milestone</Label>
                  <Select
                     className="react-select"
                     classNamePrefix="react-select"
                     value={_.first(
                        OutageRecordMilestoneFieldLabelOptions.filter((o) => o.value === props.filters.milestone)
                     )}
                     onChange={(selection) => props.updateFilters({ milestone: !!selection ? selection.value : null })}
                     options={OutageRecordMilestoneFieldLabelOptions}
                     menuPlacement="top"
                     isClearable={true}
                  />
               </FormGroup>
            </Col>
            <Col xs={6}>
               <FormGroup>
                  <Label>Scope of work</Label>
                  <Select
                     className="react-select"
                     classNamePrefix="react-select"
                     value={_.first(primaryScopesOfWork.filter((o) => o.value === props.filters.scopeOfWork))}
                     onChange={(selection) =>
                        props.updateFilters({ scopeOfWork: !!selection ? selection.value : null })
                     }
                     options={primaryScopesOfWork}
                     menuPlacement="top"
                     isClearable={true}
                  />
               </FormGroup>
            </Col>
            <Col xs={6}>
               <FormGroup>
                  <Label>Evaluation scope outcome</Label>
                  <Select
                     className="react-select"
                     classNamePrefix="react-select"
                     value={_.first(
                        RecordOutageEvaluationScopeOutcomeOptions.filter((o) => o.value === props.filters.scopeOutcome)
                     )}
                     onChange={(selection) =>
                        props.updateFilters({ scopeOutcome: !!selection ? selection.value : null })
                     }
                     options={RecordOutageEvaluationScopeOutcomeOptions}
                     menuPlacement="top"
                     isClearable={true}
                  />
               </FormGroup>
            </Col>
         </Row>
      </>
   );
};
