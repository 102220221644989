import _ from "lodash";

export const OutageRecordMilestoneFieldLabels = {
  readyToLeaveCustomerFacility: "Ready to leave customer facility",
  receivedAtCustomerFacility: "Received at customer facility",
  receivedByVendor: "Received by vendor",
  repairWorkComplete: "Repair/work complete",
  repairWorkStarted: "Repair/work started",
  shippedToCustomerFacility: "Shipped to customer facility",
};
export const OutageRecordMilestoneFieldLabelPairs = _.toPairs(OutageRecordMilestoneFieldLabels);
export const OutageRecordMilestoneFieldLabelOptions = OutageRecordMilestoneFieldLabelPairs.map((kv) => ({
  value: kv[0],
  label: kv[1],
}));
export const OutageRecordFieldLabelPairs = [...OutageRecordMilestoneFieldLabelPairs];

export const OutageRecordReadOnlyFieldLabel = {
  currentPhase: "Outage phase",
  acceptedScope: "Accepted scope",
  primaryScopeOfWork: "Scope of work",
  quoteAmount: "Quote amount",
  leadTime: "Lead time",
  latestCompleteMilestone: "Latest complete milestone",
  latestCompleteMilestoneDate: "Latest milestone date",
};
