import { ReactNode } from "react";
import { useGetAllKeywordsRequest } from "keywords/api";
import { Keyword } from "keywords";
import _ from "lodash";

interface KeywordsLoaderProps {
  modelKeywords: Keyword[];
  children: (params: { options: Keyword[]; loading: boolean }) => ReactNode;
}

export const KeywordsLoader = (props: KeywordsLoaderProps) => {
  const { data, loading } = useGetAllKeywordsRequest();
  const serverKeywords = data || [];
  const modelKeywords = props.modelKeywords || [];
  const keywords = modelKeywords.concat(serverKeywords.filter((s) => !modelKeywords.some((m) => s.id === m.id)));
  const options = _.orderBy(keywords, (k) => k.value);
  return <>{props.children({ options, loading })}</>;
};
