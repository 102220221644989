import { RecordOutageDetailCard } from "records/bs/outage/RecordOutageDetailCard";
import { Record } from "records/models";

interface RecordOutageProps {
  record: Record;
  userCanEdit: boolean;
}

// this is a temporary solution to display the outage scope record

export const RecordOutage = (props: RecordOutageProps) => {
  return (
    <div className="legacy_bootstrap record-screen">
      <RecordOutageDetailCard record={props.record} userCanEdit={props.userCanEdit} />
    </div>
  );
};
