import * as React from "react";
import Select from "react-select";
import { ValueKpiType, ValidValueKpiTypes, TemplateKpiType, TemplateKpiTypes, toKpiTypeDisplayName } from "../models";
import classNames from "classnames";
import _ from "lodash";
import { KpiTypeIcon } from "kpis/KpiTypeIcon";

type KpiTypeOption = { value: ValueKpiType; label: string };

const kpiTypeOptions: KpiTypeOption[] = ValidValueKpiTypes.map((t) => ({
  value: t,
  label: toKpiTypeDisplayName(t),
}));

const groupedKpiTypeOptions = (options: KpiTypeOption[]) => {
  const legacyOptions = options.filter((t) => t.value.startsWith("Legacy"));
  const oneTimeOptions = options.filter((t) => t.value.startsWith("OneTime"));
  const ongoingOptions = options.filter((t) => t.value.startsWith("Ongoing"));

  const result: { label: string; options: KpiTypeOption[] }[] = [];

  if (legacyOptions.length > 0)
    result.push({
      label: "Legacy",
      options: legacyOptions,
    });
  if (oneTimeOptions.length > 0)
    result.push({
      label: "One-time",
      options: oneTimeOptions,
    });
  if (ongoingOptions.length > 0)
    result.push({
      label: "Ongoing",
      options: ongoingOptions,
    });

  return result;
};

interface ValueMultiProps {
  className?: string;
  value: ValueKpiType[];
  options?: ValueKpiType[];
  onChange: (newValues: ValueKpiType[]) => any;
}

interface TemplateMultiProps {
  className?: string;
  value: TemplateKpiType[];
  options?: TemplateKpiType[];
  onChange: (newValues: TemplateKpiType[]) => any;
}

interface ValueSingleProps {
  className?: string;
  value?: ValueKpiType | null;
  options?: ValueKpiType[];
  onChange: (newValue: ValueKpiType) => any;
}

interface TemplateSingleProps {
  className?: string;
  value?: TemplateKpiType | null;
  options?: TemplateKpiType[];
  onChange: (newValue: TemplateKpiType) => any;
}

export const SelectMultiTemplateKpiType: React.FunctionComponent<TemplateMultiProps> = (props) => {
  return (
    <SelectMultiValueKpiType
      className={props.className}
      value={props.value}
      options={props.options || TemplateKpiTypes}
      onChange={(newValues) => props.onChange(newValues as TemplateKpiType[])}
    />
  );
};

export const SelectMultiValueKpiType: React.FunctionComponent<ValueMultiProps> = (props) => {
  const classes = classNames("react-select", props.className);
  const options = !!props.options ? kpiTypeOptions.filter((o) => props.options!.indexOf(o.value) >= 0) : kpiTypeOptions;
  return (
    <Select
      isMulti
      className={classes}
      classNamePrefix="react-select"
      value={kpiTypeOptions.filter((o) => props.value.indexOf(o.value) >= 0)}
      onChange={(selections) => props.onChange(selections.map((selection) => selection.value))}
      options={groupedKpiTypeOptions(options)}
      formatGroupLabel={(group) => <span className="selectKpiTypeGroupLabel">{group.label}</span>}
      formatOptionLabel={(option) => (
        <span className="selectKpiTypeOptionLabel">
          <KpiTypeIcon kpiType={option.value} />
          <span>{option.label}</span>
        </span>
      )}
    />
  );
};

export const SelectSingleTemplateKpiType: React.FunctionComponent<TemplateSingleProps> = (props) => {
  return (
    <SelectSingleValueKpiType
      className={props.className}
      value={props.value}
      options={props.options || TemplateKpiTypes}
      onChange={(newValue) => props.onChange(newValue as TemplateKpiType)}
    />
  );
};

export const SelectSingleValueKpiType: React.FunctionComponent<ValueSingleProps> = (props) => {
  const classes = classNames("react-select", props.className);
  const options = !!props.options ? kpiTypeOptions.filter((o) => props.options!.indexOf(o.value) >= 0) : kpiTypeOptions;
  return (
    <Select
      className={classes}
      classNamePrefix="react-select"
      value={_.first(kpiTypeOptions.filter((o) => o.value === props.value))}
      onChange={(selection) => props.onChange(selection!.value)}
      options={groupedKpiTypeOptions(options)}
      formatGroupLabel={(group) => <span className="selectKpiTypeGroupLabel">{group.label}</span>}
      formatOptionLabel={(option) => (
        <span className="selectKpiTypeOptionLabel">
          <KpiTypeIcon kpiType={option.value} />
          <span>{option.label}</span>
        </span>
      )}
    />
  );
};
