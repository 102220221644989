import { User } from "users";

export interface Customer {
  id: number;
  name: string;
  logoUri: string;
  logoBlobName: string;
  criticalities: Criticality[];
}

export interface CustomerDigest {
  id: number;
  name: string;
  logoUri: string;
  logoBlobName: string;
}

export interface Criticality {
  id: number;
  displayValue: string;
  calculationValue: number;
  customerId: number;
}

export interface SiteBase {
  id: number;
  name: string;
}
export interface SiteDigest extends SiteBase {
  customerId: number;
  customer: CustomerDigest;
}

export interface SearchableSiteDigestWithCustomer extends SiteDigest {
  combinedSearchName: string;
}

export interface SiteConfigValue {
  id: number;
  siteId: number;
  effective: Date | null;
  name: KnownSiteConfigValueName | string;
  value: string;
}

export type KnownSiteConfigValueName = "SitePersonnelRate" | "SpecialistRate" | "QuoteAmountPercentageRedValue";

export interface Site {
  id: number;
  name: string;
  customerId: number;
  location: string;

  areas: SiteArea[];
  customer: Customer;
  siteConfigValues: SiteConfigValue[];
  team: User[];
}

export interface SiteArea {
  id: number;
  name: string;
  location: string;
  siteId: number;
}

export interface UpsertSiteUserModel {
  customerId: number;
  userIds: string[];
}

export const getKpiRateRelatedConfigs = (site: Site) => {
  return site.siteConfigValues
    .filter((cv) => cv.name === "SitePersonnelRate" || cv.name === "SpecialistRate")
    .map((s) => ({
      ...s,
      value: Number(s.value),
    }));
};

export const KpiRateRoleOptions = [
  {
    label: "Site Personnel",
    value: "SitePersonnelRate",
  },
  {
    label: "Specialist",
    value: "SpecialistRate",
  },
];

export const getOutageRelatedConfigs = (site: Site) => {
  return site.siteConfigValues.filter((cv) => cv.name === "QuoteAmountPercentageRedValue");
};
