import { useState, useEffect } from "react";
import {
   Autocomplete,
   Box,
   CircularProgress,
   Divider,
   Fade,
   InputAdornment,
   InputBase,
   List,
   ListItemButton,
   Paper,
} from "@mui/material";
import { SearchIcon } from "icons/SearchIcon";
import { useGlobalSearchRequest } from "search/api";
import { useDebounce } from "usehooks-ts";
import {
   AssetSearchResult,
   AttachmentSearchResult,
   HistoryEventSearchResult,
   RecommendationSearchResult,
   RecordSearchResult,
   SearchResult,
} from "search/models";
import { makeSiteDigestRouteNavigator } from "app/SiteNavigator";
import { useNavigate } from "react-router-dom";
import { GenericSearchResultItem } from "./GenericSearchResult";
import _ from "lodash";
import { useSiteIdFromRoute } from "app";
import { IrisColors } from "app/mui/theme";
import { useUiThemeContext } from "app/contexts/UiThemeContext";

const minSearchTextLength = 3;

const generateLinkForSearchResult = (searchResult: SearchResult): string => {
   const siteNavigator = makeSiteDigestRouteNavigator(searchResult.site);

   switch (searchResult.resultType) {
      case "Asset":
         return siteNavigator.routeTo.Asset({ id: (searchResult as AssetSearchResult).id });

      case "Attachment":
         return (searchResult as AttachmentSearchResult).parentType === "Record"
            ? siteNavigator.routeTo.Record({ id: (searchResult as AttachmentSearchResult).recordId! })
            : siteNavigator.routeTo.Asset({ id: (searchResult as AttachmentSearchResult).assetId! });

      case "HistoryEvent":
         return siteNavigator.routeTo.Record({ id: (searchResult as HistoryEventSearchResult).recordId });

      case "Recommendation":
         return siteNavigator.routeTo.Record({ id: (searchResult as RecommendationSearchResult).recordId });

      case "Record":
         return siteNavigator.routeTo.Record({ id: (searchResult as RecordSearchResult).recordId });

      default:
         return "";
   }
};

export const SearchBar = ({onBlur, autoFocus}) => {
   const navigate = useNavigate();
   const searchQuery = useGlobalSearchRequest();
   const currentSiteId = useSiteIdFromRoute();

   const [searchText, setSearchText] = useState("");
   const [searchResults, setSearchResults] = useState<(SearchResult & { link: string })[]>([]);

   const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

   const strippedSearchText = searchText.replace(/"/g, "");
   const queryParts = [strippedSearchText, ...strippedSearchText.split(/\s/)];

   const isDarkTheme = useUiThemeContext().header === "dark";
   const debouncedSearchText = useDebounce(searchText, 250);

   // when the debounced search text changes, re-perform the search.
   useEffect(() => {
      if (debouncedSearchText.length >= minSearchTextLength) {
         searchQuery.call({ query: debouncedSearchText });
      }
   }, [debouncedSearchText]);

   // the the search text lenght is changed to < minSearchTextLength
   // and a search is currently in progress, cancel it.
   useEffect(() => {
      if (searchText.length < minSearchTextLength) {
         searchQuery.cancel();
         setSearchResults([]);
      }
   }, [searchText]);

   // store the search results locally in state when the network query results change.
   // (this is necessary to allow us clear the search results when user clears the search text)
   useEffect(() => {
      const results = (searchQuery.data?.results || []).map((result) => {
         return { ...result, link: generateLinkForSearchResult(result) };
      });

      // prefer the search results to be ordered by the site id
      const orderedResults = _.orderBy(results, (r) => (r.site.id === currentSiteId ? 0 : 1));

      setSearchResults(orderedResults);
   }, [searchQuery.data, currentSiteId]);

   return (
      <>
         <Fade in={showBackdrop}>
            <Box
               sx={{
                  background: "rgba(0,0,0,0.15)",
                  width: "100%",
                  height: "100%",
                  position: "fixed",
                  left: 0,
                  top: 0,
                  zIndex: 100,
               }}
            />
         </Fade>
         <Paper
            sx={{
               transition: "all 0.3s",
               width: "100%",
               maxWidth: { md: "400px", lg: "500px" },
               bgcolor: !showBackdrop ? "#f1f1f1" : "background.paper",
               zIndex: 101,
               position: "relative",
               border: "solid 1px transparent",
               "&:hover": {
                  borderColor: !showBackdrop ? IrisColors.gray500 : "transparent",
               },
            }}
            elevation={showBackdrop ? 2 : 0}
         >
            <Autocomplete
               onOpen={() => setShowBackdrop(true)}
               onClose={() => setShowBackdrop(false)}
               autoHighlight
               freeSolo
               clearOnBlur={false}
               options={searchResults}
               filterOptions={(x) => x} // no filtering locally as this happens server side
               getOptionLabel={(option) => (typeof option === "string" ? option : option.resultId)}
               inputValue={searchText}
               loading={searchQuery.loading}
               loadingText="searching..."
               forcePopupIcon={false}
               ListboxComponent={List}
               componentsProps={{
                  paper: {
                     elevation: 3,
                     sx: {
                        mt: "6px",
                        background: (theme) => isDarkTheme ? theme.palette.primary.main : theme.palette.background.paper,
                        ".MuiAutocomplete-listbox::-webkit-scrollbar": {
                           display: isDarkTheme ? 'none' : 'auto'
                        },
                     },
                  },
               }}
               renderInput={({ InputLabelProps, InputProps, ...rest }) => (
                  <InputBase
                     {...InputProps}
                     {...rest}
                     autoFocus={autoFocus}
                     onBlur={onBlur} 
                     sx={{ flex: 1, p: "6px", width: "100%" }}
                     placeholder="Search records, assets, etc."
                     startAdornment={
                        <InputAdornment position="start" sx={{ svg: { width: "18px", height: "18px", m: 1, mr: 2 } }}>
                           <SearchIcon />
                        </InputAdornment>
                     }
                     endAdornment={
                        searchQuery.loading && (
                           <InputAdornment position="end">
                              <CircularProgress size="24px" sx={{ m: 2, mr: 1 }} />
                           </InputAdornment>
                        )
                     }
                  />
               )}
               onInputChange={(_, newInputValue, reason) => {
                  if (reason !== "reset") {
                     setSearchText(newInputValue);
                  }
               }}
               onChange={(event, value, reason) => {
                  // on change will only be called when search
                  // results are navigated to, from a keyboard event.
                  if (value && typeof value !== "string") {
                     if (event.nativeEvent instanceof KeyboardEvent) {
                        navigate(value.link);
                        setSearchText("");
                     }
                  }
               }}
               renderOption={(props, option, { inputValue }) => {
                  return (
                     <>
                        <ListItemButton {...(props as any)}
                           href={option.link}
                           sx={{
                              color: (theme) => isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main,
                           }}>
                           <GenericSearchResultItem searchResult={option} searchWords={queryParts} isDarkTheme={isDarkTheme} />
                        </ListItemButton>
                        <Divider />
                     </>
                  );
               }}
            />
         </Paper>
      </>
   );
};
