import React from "react";
import { Container, Box, styled } from "@mui/material";
import Header from "app/mui/Header";
import { useSiteIdFromRoute } from "app";
import { Hide } from "./layoutUtils/HideShowUtils";
import { ExpandableLeftHandNavBar } from "./siteMenu/ExpandableLeftHandNavBar";
import { LegacyBootstrapModalContainerSupport } from "./bootstrap/LegacyBootstrapModalContainerSupport";

interface Props {
   children?: React.ReactNode;
}

export const NewLayout = (props: Props) => {
   const currentSiteId = useSiteIdFromRoute();
   const isOnASitePage = Boolean(currentSiteId);

   return (
      <Box sx={{ minHeight: "100vh", width: "100%", backgroundColor: "background.default" }}>
         <Header />
         <Box sx={{ paddingTop: 3, width: "100%", display: "flex", alignItems: "flex-start" }}>
            <Hide at={(breakpoint) => breakpoint.down("md")}>{isOnASitePage && <ExpandableLeftHandNavBar />}</Hide>

            <Container
               className="matt"
               maxWidth={false}
               sx={{ padding: { xs: 1, sm: 2, md: 3 }, pt: { xs: 0, sm: 0, md: 0 }, width: "0", flexGrow: 1 }}
            >
               {props.children}
            </Container>
         </Box>
         <LegacyBootstrapModalContainerSupport />
      </Box>
   );
};
