import { useCallback, useLayoutEffect, useRef, useState } from "react";
import _ from "lodash";

export const useHorizontalScrolling = (options?: { buffer?: number }) => {
  const containerRef = useRef<HTMLElement>();
  const sliderRef = useRef<HTMLDivElement>(null);

  const containerBounds = useRef<DOMRect>();
  const translation = useRef<number>(0);

  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

  useLayoutEffect(() => {
    init();

    window.addEventListener("resize", debouncedInit);
    return () => window.removeEventListener("resize", debouncedInit);
  }, []);

  const init = useCallback(() => {
    const bounds = containerRef.current?.getBoundingClientRect();
    if (!bounds) {
      return;
    }
    containerBounds.current = bounds;

    sliderRef.current!.setAttribute("style", `transition: all 0.3s; transform: translateX(${-translation.current}px)`);

    determineIfCanScroll();
  }, []);

  const debouncedInit = useCallback(_.debounce(init, 1000), []);

  const findFirstRightOverflowingElement = () => {
    const children: DOMRect[] = [];
    sliderRef.current?.childNodes.forEach((childNode) => {
      children.push((childNode as Element).getBoundingClientRect());
    });

    if (!containerBounds.current || children.length < 1) {
      return { element: null, isLast: null };
    }

    const orderedElements = _.orderBy(children, (c) => c.x);

    for (var elementIndex = 0; elementIndex < orderedElements.length; elementIndex++) {
      const element = orderedElements[elementIndex];

      // there seems to be a weird bug on windows where sometimes the container right is equal to the element right,
      // but is somehow registering as being less than, and as such it show that the user can scroll right, when they
      // shouldn't be able to.. so we are giving it a few pixel buffer. (╯°□°)╯︵ ┻━┻

      const arbitraryPixelThreshold = 1;
      if (element.right - containerBounds.current.right > arbitraryPixelThreshold) {
        return { element: element, isLast: elementIndex === orderedElements.length - 1 };
      }
    }

    return { element: null, isLast: null };
  };

  const findFirstLeftOverflowingElement = () => {
    const children: DOMRect[] = [];
    sliderRef.current?.childNodes.forEach((childNode) => {
      children.push((childNode as Element).getBoundingClientRect());
    });

    if (!containerBounds.current || children.length < 1) {
      return { element: null, isLast: null };
    }

    const orderedElements = _.orderBy(children, (c) => c.x, "desc");

    for (var elementIndex = 0; elementIndex < orderedElements.length; elementIndex++) {
      const element = orderedElements[elementIndex];
      if (element.left < containerBounds.current.left) {
        return { element: element, isLast: elementIndex === orderedElements.length - 1 };
      }
    }

    return { element: null, isLast: null };
  };

  const scrollLeft = () => {
    if (!containerBounds.current) {
      return;
    }

    const { element: overflowingElement, isLast } = findFirstLeftOverflowingElement();
    if (!overflowingElement) {
      return;
    }

    const leftEdge = containerBounds.current.left;

    let translationAmount = leftEdge - overflowingElement.left;

    if (!isLast) {
      translationAmount += options?.buffer ?? 0;
    }

    translation.current -= translationAmount;

    sliderRef.current!.setAttribute("style", `transition: all 0.3s; transform: translateX(${-translation.current}px)`);

    setTimeout(() => {
      determineIfCanScroll();
    }, 400); // delay this until after the scroll happens
  };

  const scrollRight = () => {
    if (!containerBounds.current) {
      return;
    }

    const { element: overflowingElement, isLast } = findFirstRightOverflowingElement();
    if (!overflowingElement) {
      return;
    }

    const rightEdge = containerBounds.current.right;

    let translationAmount = overflowingElement.right - rightEdge;
    if (!isLast) {
      translationAmount += options?.buffer ?? 0;
    }

    translation.current += translationAmount;

    sliderRef.current!.setAttribute("style", `transition: all 0.3s; transform: translateX(${-translation.current}px)`);

    setTimeout(() => {
      determineIfCanScroll();
    }, 400); // delay this until after the scroll happens
  };

  const determineIfCanScroll = () => {
    setCanScrollLeft((_) => findFirstLeftOverflowingElement()?.element != null);
    setCanScrollRight((_) => findFirstRightOverflowingElement()?.element != null);
  };

  return { sliderRef, containerRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight };
};
