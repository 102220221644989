import { RecordsEvent, RecordsEventDetail } from "recordsEvents/models";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { DeleteEventDialog } from "../dialogs/DeleteEventDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, formatRoutePath } from "app/routes";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";

interface IRecordsEventActionsProps {
  recordsEventDetail: RecordsEventDetail;
  methods: UseFormReturn<RecordsEvent>;
  saveChanges: (onValid?: () => void) => void;
}

const pageActions = (recordDetails: RecordsEventDetail) => {
  return createStaticActions((actionDispatcher, userPermissions) => {
    const userCanEditEvent = userPermissions.userCanEditRecordsEvents;

    return [
      {
        icon: <EditCalendarOutlinedIcon />,
        label: "Edit name",
        action: () => actionDispatcher.dispatch("renameRecordsEvent"),
        hidden: !userCanEditEvent,
      },
      {
        icon: recordDetails.model.isArchived ? <EventAvailableIcon /> : <Inventory2OutlinedIcon />,
        label: recordDetails.model.isArchived ? "Mark as Active" : "Mark as Archived",
        action: () => actionDispatcher.dispatch("archiveRecordsEvent"),
        hidden: !userCanEditEvent,
      },
      {
        icon: <DeleteForeverOutlinedIcon />,
        label: "Delete event",
        action: () => actionDispatcher.dispatch("deleteRecordsEvent"),
        hidden: !userCanEditEvent,
      },
    ];
  });
};

type DialogActionsType = "renameRecordsEvent" | "archiveRecordsEvent" | "deleteRecordsEvent" | null;
const validDialogActions = ["renameRecordsEvent", "archiveRecordsEvent", "deleteRecordsEvent"];

export const RecordsEventActions = (props: IRecordsEventActionsProps) => {
  const { recordsEventDetail, methods, saveChanges } = props;
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(recordsEventDetail), actionDispatcher, [recordsEventDetail]);

  const handleAction = useCallback((action: string) => {
    if (action === "archiveRecordsEvent") {
      methods.setValue("isArchived", !recordsEventDetail.model.isArchived);
      saveChanges();
    }
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);
  const closeDialog = () => {
    setOpenDialog(null);
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const { returnTo } = state || {};

  const onDeletedRecord = async () => {
    if (returnTo) {
      return navigate(returnTo);
    }

    const recordsEventsList = formatRoutePath(Routes.RecordsEvents, {
      siteId: recordsEventDetail.siteId,
    });
    navigate(recordsEventsList);
    closeDialog();
  };

  return (
    <>
      <ActionsDropdownMenu primary actions={actions} />
      {openDialog === "renameRecordsEvent" && (
        <EditFieldDialog
          methods={methods}
          saveChanges={saveChanges}
          fieldName="name"
          dialogTitle="Change event name"
          label="Name"
          confirmButtonText="Change Name"
          onClose={closeDialog}
        />
      )}

      {openDialog === "deleteRecordsEvent" && (
        <DeleteEventDialog
          onClose={closeDialog}
          recordsEventDetail={recordsEventDetail}
          onDeleteEvent={onDeletedRecord}
        />
      )}
    </>
  );
};
