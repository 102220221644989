import { useApiStatusHistoryWatcher } from "app";
import { Box, Chip, Fade } from "@mui/material";

export const SavingIndicator: React.FunctionComponent = () => {
   const { isSaving, justSaved } = useApiStatusHistoryWatcher();

   return (
      <Fade in={isSaving || justSaved}>
         {isSaving ? (
            <Chip label="Saving..." color="success" variant="outlined" />
         ) : justSaved ? (
            <Chip label="Saved" color="success" />
         ) : (
            <Box />
         )}
      </Fade>
   );
};
