import { Paper, Stack, Typography } from "@mui/material";
import { TasksIcon } from "icons/TasksIcon";
import { TasksListSelector } from "./TasksListSelector";
import { RecordsList } from "./RecordsList";
import { RecommendationsList } from "./RecommendationsList";
import { KpisList } from "./KpisList";

export const TasksListPage = () => {
   return (
      <Paper sx={{ p: 2 }}>
         <Stack spacing={2}>
            <Stack
               direction="row"
               alignItems="center"
               spacing={1}
               sx={{
                  svg: {
                     fill: (theme) => theme.palette.primary.main,
                     width: "28px",
                     height: "28px",
                  },
               }}
            >
               <TasksIcon />
               <Typography variant="h5" fontWeight="bold">
                  Tasks
               </Typography>
            </Stack>
            <TasksListSelector>
               {(tasksList) => {
                  switch (tasksList) {
                     case "records":
                        return <RecordsList />;
                     case "recommendations":
                        return <RecommendationsList />;
                     case "kpis":
                        return <KpisList />;
                  }
               }}
            </TasksListSelector>
         </Stack>
      </Paper>
   );
};
