import { DiagnosticType } from "records/models/diagnosticType";
import { Badge, BadgeProps } from "./Badge";

interface Props extends Omit<BadgeProps, "text"> {
  diagnosticType?: DiagnosticType;
}

export const DiagnosticTypeBadge = (props: Props) => {
  const { diagnosticType, ...badgeProps } = props;

  if (diagnosticType == null) {
    return <></>;
  }

  return <Badge {...badgeProps} text={diagnosticType}></Badge>;
};
