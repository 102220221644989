import { Grid, Stack } from "@mui/material";
import { EntityCardSkeleton } from "./EntityCardSkeleton";
import { EntityHeaderSkeleton } from "./EntityHeaderSkeleton";
import { ExpansionPanelSkeleton } from "./ExpansionPanelSkeleton";

export const EntityDetailsContainerSkeleton = () => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} lg={8} xl={9}>
				<Stack spacing={2}>
					<EntityHeaderSkeleton />
					<ExpansionPanelSkeleton />
					<ExpansionPanelSkeleton />
					<ExpansionPanelSkeleton />
				</Stack>
			</Grid>
			<Grid item xs={12} lg={4} xl={3}>
				<Stack spacing={2} direction={{ xs: "column", sm: "row", lg: "column" }}>
					<EntityCardSkeleton />
					<EntityCardSkeleton />
					<EntityCardSkeleton />
				</Stack>
			</Grid>
		</Grid>
	);
};
