import { FunctionComponent, useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Box, Stack, TextField } from "@mui/material";
import { formatCustomerRoutePath, Routes } from "app";
import { getCrossSiteRoutePath } from "app";
import { useGetSiteDigestsRequest } from "sites/api";
import { CustomerDigest, SiteDigest } from "sites";
import { ThemeAutoComplete } from "./common/autoComplete/ThemeAutoComplete";

interface SiteSelectorProps {
   customers: CustomerDigest[];
   sites: SiteDigest[];
   currentCustomerId?: number | null;
   currentSiteId?: number | null;
   onCustomerSelected?: (customerId: number | null) => void;
   onSiteSelected?: (siteId: number | null, customerId: number | null) => void;
   allowClearable?: boolean;
   /**The 'All sites' option has an id of 0 */
   showAllSites?: boolean;
   allowSelectionOfCustomerWithoutSite?: boolean;
   isDarkTheme?: boolean;
}

export const SiteSelector = (props: SiteSelectorProps) => {
   const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(props.currentCustomerId ?? null);
   const [selectedSiteId, setSelectedSiteId] = useState<number | null>(props.currentSiteId ?? null);

   const customerOptions = _.orderBy(props.customers, (c) => c.name?.toLowerCase()).map((customer) => ({
      value: customer.id,
      label: customer.name,
   }));
   const selectedCustomerOptions = customerOptions.filter((customer) => customer.value === selectedCustomerId);
   const selectedCustomerOption = selectedCustomerOptions.length > 0 ? selectedCustomerOptions[0] : null;

   const customerSites = props.sites.filter((site) => site.customerId === selectedCustomerId);
   const allSitesOption = props.showAllSites && customerSites.length > 1 ? [{ label: "All sites", value: 0 }] : [];
   const siteOptions = allSitesOption.concat(customerSites.map((site) => ({ label: site.name, value: site.id })));

   const selectedSiteOptions = siteOptions.filter((site) => site.value === selectedSiteId);
   const selectedSiteOption = selectedSiteOptions.length > 0 ? selectedSiteOptions[0] : null;

   useEffect(() => {
      if (!!props.currentSiteId) {
         setSelectedSiteId(props.currentSiteId);
         const findNewSite = props.sites.find((s) => s.id === props.currentSiteId);
         if (!!findNewSite) {
            setSelectedCustomerId(findNewSite.customerId ?? null);
         } // else this is a brand new site, it'll show up eventually
      } else if (!!props.currentCustomerId) {
         setSelectedCustomerId(props.currentCustomerId!);
         if (allSitesOption.length > 0) {
            setSelectedSiteId(0);
         } else {
            setSelectedSiteId(null);
         }
      }
   }, [props.currentCustomerId, props.currentSiteId]);

   return (
      <Stack direction="row" spacing={1} width="100%">
         <Box flexGrow="1" maxWidth="340px">
            <ThemeAutoComplete
               isDarkTheme={!!props.isDarkTheme}
               size="small"
               disableClearable={!props.allowClearable}
               disablePortal
               getOptionLabel={(option) => option.label}
               options={customerOptions}
               value={selectedCustomerOption!}
               isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
               }}
               onChange={(_, newValue) => {
                  var customerId = newValue?.value ?? null;
                  setSelectedCustomerId(customerId);
                  if (props.allowSelectionOfCustomerWithoutSite) {
                     setSelectedSiteId(null);
                     props.onCustomerSelected?.(customerId);
                  } else {
                     if (!!customerId) {
                        const newSites = props.sites.filter((s) => s.customerId === customerId);
                        if (newSites.length === 1) {
                           setSelectedSiteId(newSites[0].id);
                           props.onSiteSelected?.(newSites[0].id, customerId);
                        } else if (newSites.length > 0) {
                           if (!!props.showAllSites) {
                              setSelectedSiteId(0);
                              props.onSiteSelected?.(0, customerId);
                           } else {
                              setSelectedSiteId(newSites[0].id);
                              props.onSiteSelected?.(newSites[0].id, customerId);
                           }
                        }
                     } else {
                        setSelectedSiteId(null);
                     }
                  }
               }}
               renderInput={({ InputLabelProps, ...params }) => (
                  <TextField {...params} hiddenLabel={true} placeholder="Customer" />
               )}
            />
         </Box>
         {siteOptions.length > 0 && (
            <Box flexGrow="1" maxWidth="340px">
               <ThemeAutoComplete
                  isDarkTheme={!!props.isDarkTheme}
                  size="small"
                  disableClearable={!props.allowClearable}
                  disablePortal
                  getOptionLabel={(option) => option.label}
                  options={siteOptions}
                  value={selectedSiteOption}
                  isOptionEqualToValue={(option, value) => {
                     return option.value === value.value;
                  }}
                  onChange={(_, newValue) => {
                     const newSiteId = newValue?.value ?? null;
                     setSelectedSiteId(newSiteId);
                     props.onSiteSelected?.(newSiteId, selectedCustomerId);
                  }}
                  renderInput={({ InputLabelProps, ...params }) => (
                     <TextField {...params} hiddenLabel={true} placeholder="Site" />
                  )}
               />
            </Box>
         )}
      </Stack>
   );
};

export interface NavigatingSiteSelectorProps {
   currentCustomerId: number | null;
   currentSiteId: number | null;
   isDarkTheme: boolean;
}

export const NavigatingSiteSelector: FunctionComponent<NavigatingSiteSelectorProps> = (props) => {
   const navigate = useNavigate();
   const { data: sites } = useGetSiteDigestsRequest();
   const customers = !!sites
      ? _.orderBy(
         _.uniqBy(sites, (s) => s.customerId).map((s) => s.customer),
         (customer) => customer.name
      )
      : [];

   const currentCustomer = _.first(customers.filter((customer) => customer.id === props.currentCustomerId));
   const currentSite = _.first((sites || []).filter((site) => site.id === props.currentSiteId));

   if (!sites) return null;
   return (
      <SiteSelector
         isDarkTheme={props.isDarkTheme}
         customers={customers}
         sites={sites}
         onSiteSelected={(siteId, customerId) => {
            if (!!siteId) {
               navigate(getCrossSiteRoutePath(siteId, window.location.pathname));
            } else if (siteId === 0) {
               navigate(formatCustomerRoutePath(Routes.CustomerDashboard, { customerId: customerId! }));
            }
         }}
         currentCustomerId={currentCustomer?.id}
         currentSiteId={currentSite?.id}
         showAllSites={true}
         allowClearable={false}
      />
   );
};
