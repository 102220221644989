import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Stack } from "@mui/material";
import { Routes, formatRoutePath, useSiteContext } from "app";
import SplitButton from "app/mui/SplitButton";
import { BaseDialog } from "app/mui/common/dialogs/BaseDialog";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateRecordsEventRequest } from "recordsEvents/api";
import { recordsEventCreateSchemaValidation } from "recordsEvents/utilities";

interface CreateEventDialogProps {
  onClose: () => void;
}

export const CreateEventDialog = (props: CreateEventDialogProps) => {
  const navigate = useNavigate();
  const { currentSiteWithNavigation } = useSiteContext();

  const createRecordsEventRequest = useCreateRecordsEventRequest();
  const today = moment().startOf("day").toDate();

  const methods = useForm({
    defaultValues: { name: "", startDate: today, endDate: undefined },
    resolver: yupResolver(recordsEventCreateSchemaValidation),
  });

  const onSubmit = (options?: { navigate?: boolean }) => {
    if (!currentSiteWithNavigation) return;

    methods.handleSubmit((values) => {
      createRecordsEventRequest
        .call({
          siteId: currentSiteWithNavigation.id,
          name: values.name,
          startDate: values.startDate,
          endDate: values.endDate,
        })
        .then((newEvent) => {
          if (newEvent) {
            if (options?.navigate) {
              navigate(formatRoutePath(Routes.RecordsEvent, { siteId: newEvent.siteId, id: newEvent.id }));
            } else {
              props.onClose();
              window.location.reload();
            }
          }
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle>Create event</DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldFormItem label="Name" fieldName="name" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateInputFormItem label="Start date" fieldName="startDate" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateInputFormItem label="End date" fieldName="endDate" />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained" }}
            primaryButton={<Button onClick={() => onSubmit({ navigate: true })}>Create And Open</Button>}
            additionalActions={[<MenuItem onClick={() => onSubmit()}>Create</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
