import * as React from "react";
import { RecordType } from "records";
import Badge from "reactstrap/lib/Badge";

interface Props {
   type: RecordType;
}

export class RecordTypeBadge extends React.PureComponent<Props> {
   public render() {
      const { type } = this.props;
      return (
         <Badge className="record-type" color={this.getColorForRecordType(type)}>
            {type}
         </Badge>
      );
   }

   private getColorForRecordType = (recordType: RecordType) => {
      switch (recordType) {
         case RecordType.Activity:
            return "activity";
         case RecordType.CandSU:
            return "c-and-su";
         case RecordType.FieldInspection:
            return "field-inspection";
         case RecordType.OutageScope:
            return "outage-scope";
         case RecordType.Predictive:
            return "predictive";
         case RecordType.Proactive:
            return "proactive";
         case RecordType.Preventative:
            return "preventative";
         case RecordType.Reactive:
            return "reactive";
         case RecordType.ValveDiagnostic:
            return "valve-diagnostic";
         default:
            return "gray";
      }
   };
}

