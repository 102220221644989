import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useNotifications, useSiteContext } from "app";
import { useDeleteAssetRequest } from "assets/api";
import { Asset } from "assets/models/asset";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface DeleteAssetModalProps {
  onClose: () => void;
  asset: Asset;
}

export const DeleteAssetModal = (props: DeleteAssetModalProps) => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const deleteAssetRequest = useDeleteAssetRequest();

  const currentSite = useSiteContext().currentSiteWithNavigation!;

  const [assetTag] = useState(props.asset.tag);

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Delete {assetTag}?</DialogTitle>
      <DialogContent>
        <Typography variant="body2">Are you sure you want to delete this asset?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} disabled={deleteAssetRequest.loading}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteAssetRequest.call(props.asset.id).then(() => {
              notifications.success(`Deleted ${assetTag}`);
              navigate(currentSite.routeTo.Assets());
            });
          }}
          color="error"
          variant="contained"
          disabled={deleteAssetRequest.loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
