import { useRef, createContext, useContext, useState, useCallback, useEffect, ReactNode } from "react";

export type ActionReceiverFN = (action: string, ...params: any) => void;
export type ActionDispatcherApi = {
  subscribe: (receiver: ActionReceiverFN) => VoidFunction;
  unsubscribe: (receiver: ActionReceiverFN) => void;
  dispatch: ActionReceiverFN;
  isActionInProgress: boolean;
};

/**
 * useActionDispatcher provides a subscription model for dispatching and listening
 * to events. This is useful when the thing that triggers the action is higher up
 * in the component tree than the thing that responds to the action.
 **/

export const useActionDispatcher = (): ActionDispatcherApi => {
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const subscribers = useRef<ActionReceiverFN[]>([]);

  const subscribe = useCallback((fn: ActionReceiverFN) => {
    subscribers.current.push(fn);

    return () => unsubscribe(fn);
  }, []);

  const unsubscribe = useCallback((fn: ActionReceiverFN) => {
    subscribers.current = subscribers.current.filter((fn) => fn != fn);
  }, []);

  const dispatch = useCallback((action: string, ...params: any) => {
    setIsActionInProgress(true);
    try {
      subscribers.current.forEach((s) => {
        Promise.resolve().then(() => {
          s(action, ...params);
        });
      });
    } finally {
      setIsActionInProgress(false);
    }
  }, []);

  return { dispatch, subscribe, unsubscribe, isActionInProgress };
};

/**
 * The context / provider allow us to use the action dispatcher api through a context
 * without passing it around through props. This is useful if you have deeply nested
 * components that need to use dispatch.
 **/

const ActionDispatcherContext = createContext<ActionDispatcherApi>({} as ActionDispatcherApi);

export const ActionDispatcherProvider = (props: { actionDispatcher?: ActionDispatcherApi; children: ReactNode }) => {
  const api = useActionDispatcher();

  return (
    <ActionDispatcherContext.Provider value={props.actionDispatcher ?? api}>
      {props.children}
    </ActionDispatcherContext.Provider>
  );
};

export const useActionDispatcherContext = (options?: { unsafe: boolean }) => {
  const api = useContext(ActionDispatcherContext);
  if (!api && options?.unsafe !== true) {
    throw "Attempted to use hook: 'useActionDispatcherContext' outside of a 'ActionDispatcherProvider' component. Did you intend to use 'useActionDispatcher' instead.";
  }

  return api;
};
