import { TimeUnit } from "common";
import { KpiCommodityUnit, KpiCommodityUnitsForProduction, KpiEmissionsType } from "kpis/models";
import * as yup from "yup";

export const SimplifiedKpiOngoingMaintenanceSavingsOrProductionImprovementValidationSchema = yup.object({
  isSimplifiedVersion: yup.boolean().nullable(),
  simplifiedCostToImplement: yup.number().nullable().min(0).required("Required"),
  simplifiedMaintenanceSavings: yup.number().nullable().min(0).required("Required"),
  simplifiedProductionImprovement: yup.number().nullable().min(0).required("Required"),
  maintenanceSavingsIsOngoing: yup.boolean().nullable().required("Required"),
  productionImprovementIsOngoing: yup.boolean().nullable().required("Required"),
});

export const KpiOngoingMaintenanceSavingsOrProductionImprovementValidationSchema = yup.object({
  spartanHours: yup.number().nullable(),
  remoteHours: yup
    .number()
    .nullable()
    .when("spartanHours", {
      is: (value: number) => !!value,
      then: yup.number().nullable().required("Required"),
    }),
  customerHours: yup.number().nullable(),
  equipmentAndMaterialsCost: yup.number().nullable(),
  maintenanceCostBeforeImplementation: yup
    .number()
    .nullable()
    .when("maintenanceSavingsIsOngoing", {
      is: (value: boolean) => value,
      then: yup.number().moreThan(0, "Must be greater than zero").required("Required"),
    }),
  maintenanceCostAfterImplementation: yup.number().nullable(),
  maintenanceCostTimeUnit: yup
    .mixed<TimeUnit>()
    .nullable()
    .when("maintenanceSavingsIsOngoing", {
      is: (value: boolean) => !!value,
      then: yup.mixed<TimeUnit>().nullable().required("Required"),
    }),
  productionRateBeforeImplementation: yup
    .number()
    .nullable()
    .when("productionImprovementIsOngoing", {
      is: (value: boolean) => !!value,
      then: yup.number().nullable().moreThan(0, "Must be greater than zero").required("Required"),
    }),
  productionRateAfterImplementation: yup
    .number()
    .nullable()
    .when("productionImprovementIsOngoing", {
      is: (value: boolean) => !!value,
      then: yup.number().nullable().moreThan(0, "Must be greater than zero").required("Required"),
    }),
  commodityUnit: yup
    .mixed<KpiCommodityUnit>()
    .nullable()
    .when("productionImprovementIsOngoing", {
      is: (value: boolean) => !!value,
      then: yup.mixed<KpiCommodityUnit>().test("is-valid", "Must be a production unit", function (value) {
        return !!value && KpiCommodityUnitsForProduction.indexOf(value) >= 0;
      }),
    }),
  commodityType: yup
    .mixed<KpiEmissionsType>()
    .nullable()
    .when("productionImprovementIsOngoing", {
      is: (value: boolean) => !!value,
      then: yup.mixed<KpiEmissionsType>().required("Required"),
    }),
  commodityProductionRateTimeUnit: yup
    .mixed<TimeUnit>()
    .nullable()
    .when("productionImprovementIsOngoing", {
      is: (value: boolean) => !!value,
      then: yup.mixed<TimeUnit>().required("Required"),
    }),
  commodityPricePerUnit: yup
    .number()
    .nullable()
    .when("productionImprovementIsOngoing", {
      is: (value: boolean) => !!value,
      then: yup.number().nullable().moreThan(0, "Must be greater than zero").required("Required"),
    }),
});
