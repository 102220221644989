import { Typography } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { useWatch } from "react-hook-form";

interface ReadonlyFormItemProps {
  label: string;
  value?: string;
  hint?: string;
  fieldName?: string;
}

export const ReadonlyFormItem = (props: ReadonlyFormItemProps) => {
  const value: string = useWatch({ name: props.fieldName || "" });

  return (
    <FormItemLayout label={props.label} hint={props.hint}>
      {(_) => (
        <Typography variant="body2" fontWeight="bold" lineHeight="1">
          {props.value || value || "—"}
        </Typography>
      )}
    </FormItemLayout>
  );
};
