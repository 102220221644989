import {
   useBaseAxiosDeleteRequest,
   useBaseAxiosGetRequestWithAutoRefresh,
   useBaseAxiosPostRequest,
   useBaseAxiosPutRequest,
} from "app";
import { Customer, Site, SiteArea, SiteConfigValue, SiteDigest, UpsertSiteUserModel } from "sites";

const mutationUriMatchPatterns = [/api\/admin\/customers.*/gi, /api\/admin\/sites.*/gi];

export const useGetFreshCustomersQuery = () =>
   useBaseAxiosGetRequestWithAutoRefresh<Customer[]>(`api/admin/customers`, mutationUriMatchPatterns, { useNode: true });

export const useGetFreshCustomerQuery = (id: number | null) =>
   useBaseAxiosGetRequestWithAutoRefresh<Customer>(!!id ? `api/admin/customers/${id}` : null, mutationUriMatchPatterns, { useNode: true });

export const useCreateCustomerRequest = () =>
   useBaseAxiosPostRequest<Partial<Customer>, Customer>(`api/admin/customers`, { useNode: true });
export const useUpdateCustomerRequest = () => useBaseAxiosPutRequest<Customer>(`api/admin/customers`, { useNode: true });
export const useDeleteCustomerRequest = () => useBaseAxiosDeleteRequest((id) => `api/admin/customers/${id}`, { useNode: true });

export const useUpdateCustomerLogoRequest = (id: number | null) =>
   useBaseAxiosPostRequest<FormData, Customer>(`api/admin/customers/${id}/logo`, { useNode: true });

export const useGetSiteDigestsWithAutoRefreshRequest = () =>
   useBaseAxiosGetRequestWithAutoRefresh<SiteDigest[]>(`api/admin/sites`, mutationUriMatchPatterns, { useNode: true });

export const useGetSiteByIdRequestWithAutoRefresh = (id: number | null) =>
   useBaseAxiosGetRequestWithAutoRefresh<Site>(
      !!id ? `api/admin/sites/${id}?fresh=true` : null,
      mutationUriMatchPatterns,
      { useNode: true }
   );

export const useCreateSiteRequest = () => useBaseAxiosPostRequest<Partial<Site>, SiteDigest>(`api/admin/sites`, { useNode: true });
export const useUpdateSiteRequest = () => useBaseAxiosPutRequest<Site>(`api/admin/sites`, { useNode: true });
export const useDeleteSiteRequest = () => useBaseAxiosDeleteRequest((id) => `api/admin/sites/${id}`, { useNode: true });

export const useCreateSiteAreaRequest = (siteId: number) =>
   useBaseAxiosPostRequest<Partial<SiteArea>, SiteArea>(`api/admin/sites/${siteId}/areas`, { useNode: true });
export const useUpdateSiteAreaRequest = (siteId: number) =>
   useBaseAxiosPutRequest<SiteArea>(`api/admin/sites/${siteId}/areas`, { useNode: true });
export const useDeleteSiteAreaRequest = (siteId: number) =>
   useBaseAxiosDeleteRequest((id) => `api/admin/sites/${siteId}/areas/${id}`, { useNode: true });

export const useCreateSiteConfigValueRequest = (siteId: number) =>
   useBaseAxiosPostRequest<Partial<SiteConfigValue>, SiteConfigValue>(`api/admin/sites/${siteId}/configValues`, { useNode: true });
export const useUpdateSiteConfigValueRequest = (siteId: number) =>
   useBaseAxiosPutRequest<SiteConfigValue>(`api/admin/sites/${siteId}/configValues`, { useNode: true });
export const useDeleteSiteConfigValueRequest = (siteId: number) =>
   useBaseAxiosDeleteRequest((id) => `api/admin/sites/${siteId}/configValues/${id}`, { useNode: true });

export const useUpsertSiteUserRequest = () => useBaseAxiosPutRequest<UpsertSiteUserModel>(`api/admin/sites/users`, { useNode: true });
