import { Box, Stack, Typography } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import CurrencyFormItem from "app/mui/forms/Input/CurrencyFormItem";
import { TimeUnitFormItem, TimeUnitFormItemProps } from "./TimeUnitFormItem";

interface CurrencyTimeUnitFormItemProps extends Omit<BaseFormItemProps, "fieldName"> {
  currencyFieldName: string;
  timeUnitFieldName: string;
  timeUnitProps: TimeUnitFormItemProps;
}

export function CurrencyTimeUnitFormItem(props: CurrencyTimeUnitFormItemProps) {
  const { currencyFieldName, timeUnitFieldName, readOnly, label, timeUnitProps, ...formItemLayoutProps } = props;

  return (
    <FormItemLayout
      controlled
      hint={props.hint}
      label={label}
      formControlProps={{ disabled: readOnly }}
      {...formItemLayoutProps}
    >
      {(inputProps) => (
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <CurrencyFormItem fieldName={currencyFieldName} label={null} readOnly={readOnly} {...inputProps} />
          {timeUnitProps.mode === "Per" && (
            <Box pt="0.5em">
              <Typography>per</Typography>
            </Box>
          )}
          <TimeUnitFormItem
            fieldName={timeUnitFieldName}
            label={null}
            placeholder="Select rate"
            readOnly={readOnly}
            {...inputProps}
            {...timeUnitProps}
          />
        </Stack>
      )}
    </FormItemLayout>
  );
}
