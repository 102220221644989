import { Grid, Stack } from "@mui/material";
import { Routes, useUserContext } from "app";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMatch } from "react-router-dom";
import { useGetRecordsEventDetailQuery, useUpdateRecordsEventRequest } from "recordsEvents/api";
import { RecordsEventDetail } from "recordsEvents/models";
import { RecordsEventDetails } from "./sections/RecordsEventDetails";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { recordsEventBaseSchemaValidation } from "recordsEvents/utilities";

export const RecordsEventPage = () => {
  const routeMatch = useMatch(Routes.RecordsEvent);
  const [recordsEvent, setRecordsEvent] = useState<RecordsEventDetail | null>();

  const { data, loading } = useGetRecordsEventDetailQuery(Number(routeMatch?.params?.id));

  useEffect(() => {
    setRecordsEvent(data);
  }, [data]);

  if (loading && !recordsEvent) {
    return <EntityDetailsContainerSkeleton />;
  } else if (!recordsEvent) {
    return null;
  }
  return <RecordsEventPageWithForm recordsEventDetail={recordsEvent} />;
};

interface RecordsEventPageWithFormProps {
  recordsEventDetail: RecordsEventDetail;
}

const RecordsEventPageWithForm = (props: RecordsEventPageWithFormProps) => {
  const { recordsEventDetail } = props;
  const recordsEvent = recordsEventDetail.model;
  const defaultValues = makeSafeForReactHookForm(recordsEvent);
  const methods = useForm({ defaultValues, resolver: yupResolver(recordsEventBaseSchemaValidation) });
  const updateRecordsEvent = useUpdateRecordsEventRequest();
  const userCanEdit = useUserContext().userPermissions.userCanEditRecordsEvents;

  const saveChanges = () => {
    return methods.handleSubmit((values) => {
      updateRecordsEvent.call(values);
    })();
  };

  return (
    <PermissionAwareFormProvider {...methods} require={() => userCanEdit}>
      <AutoSave defaultValues={defaultValues} onSubmit={saveChanges} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={9}>
          <Stack spacing={2}>
            <RecordsEventDetails recordsEventDetail={recordsEventDetail} methods={methods} saveChanges={saveChanges} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <Stack spacing={2} direction={{ xs: "column", sm: "row", lg: "column" }} />
        </Grid>
      </Grid>
    </PermissionAwareFormProvider>
  );
};
