import { MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { Controller, useController } from "react-hook-form";
import { getTrafficLightOption, TrafficLightOptions, TrafficLightValue } from "records/models";
import TrafficLightCircle from "records/mui/TrafficLightCircle";
import { BaseFormItemProps } from "../Base/BaseFormItemProps";

export interface TrafficLightFormItemProps extends BaseFormItemProps {
  syncFieldName?: string;
  filterOptions?: boolean;
}

const NULL_VALUE_OPTION = -1;

const TrafficLightSelectOption = (props: { item: TrafficLightValue | null }) => {
  const { item } = props;

  var option = getTrafficLightOption(item);

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center">
      <TrafficLightCircle value={item} />
      <Typography sx={{ pl: 2 }}>{option.label}</Typography>
    </Stack>
  );
};

const getStyleProps = () => {
  return {
    width: "fit-content",
    ".MuiSelect-outlined ": {
      padding: 0,
      "& ~ fieldset.MuiOutlinedInput-notchedOutline": {
        borderWidth: "0px",
      },
      "&:hover ~ fieldset.MuiOutlinedInput-notchedOutline": {
        borderWidth: "0px",
      },
      "&:Mui-focused ~ fieldset.MuiOutlinedInput-notchedOutline": {
        borderWidth: "0px",
      },
    },
  };
};

export const TrafficLightFormItem = (props: TrafficLightFormItemProps) => {
  const { readOnly, syncFieldName } = props;

  const syncFieldControl = useController({ name: syncFieldName ?? "" });

  const getTrafficLightOptions = () => {
    if (props.filterOptions) {
      return TrafficLightOptions.filter(
        (tlo) => tlo.value === TrafficLightValue.Green || tlo.value === TrafficLightValue.Yellow || tlo.value === null
      );
    }
    return TrafficLightOptions;
  };

  return (
    <Controller
      name={props.fieldName}
      render={({ field: { onChange, value } }) => {
        const trafficLightOptions = getTrafficLightOptions();
        return readOnly ? (
          <Tooltip title={getTrafficLightOption(value).label}>
            <TrafficLightCircle value={value} />
          </Tooltip>
        ) : (
          <FormItemLayout controlled hint={props.hint} label={props.label}>
            {(inputProps) => (
              <Select
                {...inputProps}
                value={value ?? NULL_VALUE_OPTION}
                onChange={(e) => {
                  const value = e.target?.value;
                  onChange(value);
                  syncFieldControl.field.onChange(value);
                }}
                renderValue={(selectedValue: TrafficLightValue | typeof NULL_VALUE_OPTION) => (
                  <TrafficLightCircle value={selectedValue === NULL_VALUE_OPTION ? null : selectedValue} />
                )}
                sx={getStyleProps()}
              >
                {trafficLightOptions.map((tlo) => (
                  <MenuItem key={tlo.value} value={tlo.value || NULL_VALUE_OPTION}>
                    <TrafficLightSelectOption item={tlo.value} />
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormItemLayout>
        );
      }}
    />
  );
};
