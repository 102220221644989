import { Stack, Typography } from "@mui/material";
import { Routes, formatRoutePath } from "app/routes";
import { AssetDigest } from "assets/models/asset";
import { InternalLink } from "common/mui/InternalLink";

interface RecordAssetsProps {
  assets: AssetDigest[];
  assetDisplayLimit?: number;
  label?: string;
}

export const RecordAssets = (props: RecordAssetsProps) => {
  const { assets, assetDisplayLimit, label } = props;
  const extraAssetTags = [...assets.sort()];
  const assetsToDisplay = assetDisplayLimit ? extraAssetTags.splice(0, assetDisplayLimit) : extraAssetTags;

  return (
    <Stack direction="column">
      {label && (
        <Typography color="primary.main" variant="caption">
          {label}
        </Typography>
      )}
      <Stack direction="row" flexWrap="wrap">
        {assetsToDisplay.map((asset, index, assetArray) => (
          <span key={asset.id}>
            <Typography component="span" variant="body2">
              <InternalLink to={formatRoutePath(Routes.Asset, asset)}>{asset.tag}</InternalLink>
            </Typography>
            {index !== assetArray.length - 1 && <>, &nbsp;</>}
          </span>
        ))}
        {assetDisplayLimit && extraAssetTags.length > 0 && (
          <span title={extraAssetTags.map((asset) => asset.tag).join(", ")}>
            &nbsp;+&nbsp;{extraAssetTags.length}&nbsp;more
          </span>
        )}
      </Stack>
    </Stack>
  );
};
