import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { IrisColors } from "./theme";


export const AboutPopup = (props: BaseDialogProps) => {
  const { onClose } = props;

  return (
    <BaseDialog onClose={onClose}>
      <DialogTitle>About</DialogTitle>
      <DialogContent dividers sx={{ minHeight: "100px" }}>
        <Stack spacing={2}>
          <Typography component="span" sx={{ color: IrisColors.gray600 }}>
            Current Version
          </Typography>
          <Typography variant="body2" component="span" fontWeight="600">
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={onClose}>Cancel</Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
