import { memo, useEffect, useMemo, useState } from "react";
import { FieldValues, useFormContext, useFormState } from "react-hook-form";
import _ from "lodash";
import { useFormEditPermission } from "./PermissionAwareFormProvider";

interface Props {
  debounceDelay?: number;
  defaultValues?: FieldValues;
  onSubmit: VoidFunction;
}

const AutoSave = memo(({ debounceDelay, onSubmit }: Props) => {
  const { watch } = useFormContext();
  const { isDirty } = useFormState();
  const { hasPermissionToEdit } = useFormEditPermission();

  const [changes, setChanges] = useState<any>({});

  const debouncedSave = useMemo(() => {
    return _.debounce(() => {
      if (hasPermissionToEdit) {
        onSubmit();
      }
    }, debounceDelay ?? 1000);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, []);

  useEffect(() => {
    const { unsubscribe } = watch((value, { name }) => {
      setChanges((data: any) => ({
        ...data,
        [name!]: value,
      }));
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (Object.keys(changes).length > 0) debouncedSave();
  }, [changes, isDirty]);

  return <></>;
});

AutoSave.displayName = "AutoSave";
export default AutoSave;
