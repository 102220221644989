import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { Box, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import {
  gridFilteredSortedRowEntriesSelector,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { useSiteContext } from "app";
import { DataGridExtensionsContextProvider } from "app/contexts/DataGridExtensions";
import { ActionDispatcherProvider, useActionDispatcher, useActionDispatcherContext } from "app/mui/ActionDispatcher";
import { BusinessAreaContextProvider, useBusinessArea } from "app/mui/BusinessAreaContext";
import { ComponentTemplatesLoader } from "app/mui/loaders/componentTemplatesLoader";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { CustomDataViewType } from "app/mui/tables/persistence/tabViews/models";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import { createStaticRowActions, useStaticRowActions } from "app/mui/tables/utils/createStaticColumns";
import { useGetFilteredAssetComponentsRequest } from "assets/api";
import { AssetDigest } from "assets/models/asset";
import { AssetDetailView } from "assets/models/assetDetail";
import { AssetComponentDetailView, AssetComponentFilters } from "assets/models/machineryComponent";
import { CreateAssetDialog } from "assets/mui/dialogs/CreateAsset";
import { CreateAssetSummaryDialog } from "assets/mui/dialogs/CreateAssetSummary";
import { CreateRecordDialog } from "assets/mui/dialogs/CreateRecord";
import { DuplicateDialog } from "assets/mui/dialogs/Duplicate";
import { GenerateReportDialog } from "assets/mui/dialogs/GenerateReport";
import { useExportAssetAttachmentsRequest } from "attachments/api";
import { dateFormat } from "common";
import { AssetIcon } from "icons/AssetIcon";
import { useDownloadCsvExportRequest } from "importCsv/api";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const MachineryComponentsListPage = () => {
  const actionDispatcher = useActionDispatcher();

  // const actions = useStaticActions(pageActions, actionDispatcher);

  const { templateId } = useParams();
  const filteredTemplateId = Number(templateId);

  const apiRef = useGridApiRef();

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              svg: {
                fill: (theme) => theme.palette.primary.main,
                width: "28px",
                height: "28px",
              },
            }}
          >
            <AssetIcon />
            <Typography variant="h5" fontWeight="bold">
              Machinery Components
              <ComponentTemplatesLoader>
                {(options) => {
                  const match = options.options.find((x) => x.id === filteredTemplateId);
                  return match ? ` - ${match.name}` : "";
                }}
              </ComponentTemplatesLoader>
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {/* <AssetListDataViz apiRef={apiRef} /> */}
            {/* <ActionsDropdownMenu primary actions={actions} /> */}
          </Stack>
          <BusinessAreaContextProvider>
            <FilteredAssetsTable apiRef={apiRef} templateId={filteredTemplateId} />
          </BusinessAreaContextProvider>
        </Stack>
      </Paper>
    </ActionDispatcherProvider>
  );
};

const rowActions = createStaticRowActions<AssetComponentDetailView>((actionDispatcher, userPermissions) => (params) => [
  {
    icon: <CreateNewFolderRoundedIcon />,
    label: "Create Record",
    onClick: () => {
      actionDispatcher.dispatch("CreateRecord", params);
    },
    showInMenu: true,
    hidden: !userPermissions.userCanEditRecords,
  },
]);

interface FilteredAssetsTableProps {
  apiRef: React.MutableRefObject<GridApiPro>;
  templateId: number;
}

function getGridComponents(templateId: number) {
  const customDataViewTypeLookup = getCustomDataViewTypeLookup(templateId);

  return {
    LoadingOverlay: LinearProgress,
    Toolbar: ToolbarWithTabViews(customDataViewTypeLookup, {
      includeUserDefinedTabs: true,
    }),
  };
}

const FilteredAssetsTable = ({ apiRef, templateId }: FilteredAssetsTableProps) => {
  const { businessArea } = useBusinessArea();

  const actionDispatcher = useActionDispatcherContext();
  const site = useSiteContext().currentSite!;
  const siteId = site.id;
  const componentsRequest = useGetFilteredAssetComponentsRequest(siteId, templateId);
  const assetDetails = componentsRequest.data ?? [];

  const downloadCsvExportRequest = useDownloadCsvExportRequest(site, "Assets");
  const exportAssetAttachmentsRequest = useExportAssetAttachmentsRequest(site);

  const gridComponents = useMemo(() => getGridComponents(templateId), [templateId]);

  const [assetInDialog, setAssetInDialog] = React.useState<AssetDetailView | null>(null);
  const [openDialog, setOpenDialog] = React.useState<
    "createAsset" | "createAssetSummary" | "createRecord" | "generateReport" | "duplicate" | null
  >(null);

  const [assetsForSummary, setAssetsForSummary] = useState<AssetDigest[]>([]);

  const getTableRowActions = useStaticRowActions(rowActions, actionDispatcher);

  const handleAction = useCallback(
    (action: string, params: any) => {
      if (action === "DownloadCSV") {
        const ids = gridFilteredSortedRowIdsSelector(apiRef);
        downloadCsvExportRequest.call(ids.map((id) => Number(id)));
      }
      if (action === "ExportCurrentViewCSV") {
        apiRef.current.exportDataAsCsv({
          fileName: `${site.name} Asset CurrentView ${moment().format(dateFormat)}`,
          utf8WithBom: true,
        });
      }
      if (action === "BulkAttachmentExport") {
        const ids = gridFilteredSortedRowIdsSelector(apiRef);
        exportAssetAttachmentsRequest.call(ids.map((id) => Number(id)));
      }

      if (action === "CreateAsset") {
        setOpenDialog("createAsset");
      }
      if (action === "CreateAssetSummary") {
        setOpenDialog("createAssetSummary");
        const rows = gridFilteredSortedRowEntriesSelector(apiRef);
        setAssetsForSummary(rows.map((r) => r.model) as AssetDigest[]);
      }

      if (action === "CreateRecord") {
        setAssetInDialog(params.row);
        setOpenDialog("createRecord");
      }
      if (action === "GenerateReport") {
        setAssetInDialog(params.row);
        setOpenDialog("generateReport");
      }
      if (action === "Duplicate") {
        setAssetInDialog(params.row);
        setOpenDialog("duplicate");
      }
    },
    [downloadCsvExportRequest]
  );

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    const filters = new AssetComponentFilters();
    filters.templateId = templateId;
    componentsRequest.call(filters);
    // setMetadataColumnProvider("MachineryViewModel");
  }, [businessArea]);

  const closeDialog = () => {
    setOpenDialog(null);
    setAssetInDialog(null);
  };

  return (
    <>
      <Box sx={{ height: "700px", width: "100%" }}>
        <DataGridExtensionsContextProvider>
          <MetadataColumnProvider type={"MachineryViewModel"} getActions={getTableRowActions} templateId={templateId}>
            {(columns) => (
              <StripedDataGrid
                apiRef={apiRef}
                density="compact"
                loading={componentsRequest.loading}
                components={gridComponents}
                columns={columns}
                rows={assetDetails}
              />
            )}
          </MetadataColumnProvider>
        </DataGridExtensionsContextProvider>
      </Box>

      {assetInDialog && (
        <>
          {openDialog === "createRecord" && <CreateRecordDialog onClose={closeDialog} asset={assetInDialog.model} />}
          {openDialog === "generateReport" && (
            <GenerateReportDialog onClose={closeDialog} asset={assetInDialog.model} />
          )}
          {openDialog === "duplicate" && <DuplicateDialog onClose={closeDialog} asset={assetInDialog.model} />}
        </>
      )}
      {openDialog === "createAsset" && <CreateAssetDialog onClose={closeDialog} />}
      {openDialog === "createAssetSummary" && (
        <CreateAssetSummaryDialog onClose={closeDialog} assets={assetsForSummary} />
      )}
    </>
  );
};

function getCustomDataViewTypeLookup(_templateId?: number | null): CustomDataViewType {
  return "Machinery-Components";
}
