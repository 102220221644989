import { Paper, Stack, Typography } from "@mui/material";
import { AdvancedMarker, APIProvider, Map, Pin } from "@vis.gl/react-google-maps";
import { isValidLatLng, parseLatLng } from "app/mui/utils/google-maps-link";
import { CustomerDashboardViewModel, SiteDashboardViewModel } from "dashboard/models";
import { WidgetSkeleton } from "../WidgetSkeleton";

interface SitesMapWidgetProps {
  data?: CustomerDashboardViewModel;
}

export const SitesMapWidget = (props: SitesMapWidgetProps) => {
  const { data } = props;
  return (
    <Paper sx={{ p: { xs: 2, md: 3 }, width: "100%", height: "636px" }}>
      {!!!data ? (
        <WidgetSkeleton />
      ) : (
        <Stack spacing={4} sx={{ height: "100%" }}>
          <Typography variant="h5">Sites Map</Typography>
          <GoogleSitesMap sites={data.sites} />
        </Stack>
      )}
    </Paper>
  );
};

const GoogleSitesMap = ({ sites }: { sites: SiteDashboardViewModel[] }) => {
  const latLongPoints: Poi[] = sites
    .filter((x) => x.site.location && x.site.location !== "" && isValidLatLng(x.site.location))
    .map((x) => {
      const latLng = parseLatLng(x.site.location);
      return {
        key: x.site.name,
        location: { lat: latLng[0], lng: latLng[1] },
      };
    });

  // return <>{latLongPoints.join(",")}</>;
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ""}>
      <Map
        mapId={process.env.REACT_APP_GOOGLE_MAP_ID ?? ""}
        defaultBounds={calculateBounds({ pois: latLongPoints, padding: 0.1 })}
      >
        <PoiMarkers pois={latLongPoints} />
      </Map>
    </APIProvider>
  );
};

type Poi = { key: string; location: google.maps.LatLngLiteral };
const PoiMarkers = (props: { pois: Poi[] }) => {
  return (
    <>
      {props.pois.map((poi: Poi) => (
        <AdvancedMarker key={poi.key} position={poi.location}>
          <Pin glyphColor={"#000"} borderColor={"#000"} />
        </AdvancedMarker>
      ))}
    </>
  );
};

function calculateBounds({
  pois,
  padding,
}: {
  pois: Poi[];
  padding?: number;
}): google.maps.LatLngBoundsLiteral | undefined {
  if (pois.length === 0) {
    return undefined;
  }

  let minLat = pois[0].location.lat;
  let maxLat = pois[0].location.lat;
  let minLng = pois[0].location.lng;
  let maxLng = pois[0].location.lng;

  pois.forEach((poi) => {
    const { lat, lng } = poi.location;
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  return {
    east: maxLng + (padding ?? 0),
    north: maxLat + (padding ?? 0),
    south: minLat - (padding ?? 0),
    west: minLng - (padding ?? 0),
  };
}
