import React from "react";
import { useFormContext } from "react-hook-form";
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";

interface DollarAmountInputProps {
  field: any;
  label: string;
  userCanEdit: boolean;
}

export const DollarAmountInput = (props: DollarAmountInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { ref, ...fieldProps } = register(props.field);

  const userCanEdit = { readOnly: props.userCanEdit === false };
  const errorMessage = errors[props.field]?.message as string | null | undefined;

  return (
    <FormGroup>
      <Label>{props.label}</Label>
      <InputGroup>
        <InputGroupAddon addonType="prepend" className="outage-form-dollar-amount-input ">
          <InputGroupText>$</InputGroupText>
        </InputGroupAddon>
        <Input type="number" innerRef={ref} {...fieldProps} {...userCanEdit} />
      </InputGroup>
      <div className="form-error">{errorMessage}</div>
    </FormGroup>
  );
};
