import { Box, Chip, Stack, Tab, TabProps, useTheme } from "@mui/material";
import { IrisColors } from "../theme";

interface TabWithCountProps extends TabProps {
  badgeCount: number;
}

export function TabWithCount({ badgeCount, label, ...rest }: TabWithCountProps) {
  const theme = useTheme();
  return (
    <Tab
      {...rest}
      label={
        <Stack direction="row" alignItems="baseline" gap={theme.spacing(1)}>
          <Box>{label}</Box>
          <Chip
            label={badgeCount}
            sx={{ backgroundColor: IrisColors.accentBlue, color: IrisColors.dark }}
            size="small"
          />
        </Stack>
      }
    />
  );
}
