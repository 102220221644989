import { AxiosError, AxiosRequestConfig } from "axios";
import useAxios, { Options } from "axios-hooks";
import { dateFormat } from "common";
import fileDownload from "js-file-download";
import _ from "lodash";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ApiStatusContext, Message, useNotifications } from "../contexts";

interface AppOptions {
  hideFromAutosaver?: boolean;
  dontNotifyFormValidationErrors?: boolean;
}

export interface RequestOptions {
  axios?: Options;
  app?: AppOptions;
  useNode?: boolean;
  formData?: boolean;
}

const getBaseRequestHeaders = (contentType: string | null = "application/json") => {
  const headers: any = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "-1",
  };

  if (contentType) {
    headers["Accept"] = contentType;
    headers["Content-Type"] = contentType;
  }

  return headers;
};

export function apiRequestInit(contentType?: string | null): RequestInit {
  const headers = getBaseRequestHeaders(contentType);
  return {
    headers: new Headers(headers),
  };
}

export interface ModelStateDictionary {
  [key: string]: string[];
}

export interface ApiErrorResponse {
  message: string;
  errors: ModelStateDictionary;
}

export const isRequestCanceled = (reason: AxiosError<any>) =>
  reason.code === "ERR_CANCELED" || reason.name === "CanceledError" || reason.message === "canceled";

export function useAxiosGenerateReport<TRequest>(
  staticUrl?: string,
  callbacks?: { onStartCallback?: () => void; onSuccessCallback?: () => void; onFailCallback?: () => void }
) {
  const baseRequestHeaders = getBaseRequestHeaders(null);

  const [_, execute] = useAxios<BlobPart, TRequest>(
    {
      method: "POST",
      baseURL: process.env.REACT_APP_NODE_API_URL,
      url: staticUrl ?? undefined,
      responseType: "blob",
      headers: { ...baseRequestHeaders },
    },
    {
      manual: true,
      useCache: false,
    }
  );

  const call = (reportOptions: TRequest, config: AxiosRequestConfig<TRequest> = {}, options?: Options) => {
    callbacks?.onStartCallback?.();
    return execute({ data: reportOptions, ...config }, options)
      .then((response) => {
        const fileName = response.headers["content-disposition"]!.split("filename=")[1];
        const pdfBlob = new Blob([response.data], { type: response.headers["content-type"] });
        fileDownload(pdfBlob, fileName);
        callbacks?.onSuccessCallback?.();
      })
      .catch((e) => {
        callbacks?.onFailCallback?.();
        throw e;
      });
  };

  return { call };
}

export function useBaseAxiosGetRequest<TEntity>(staticUrl?: string | null, requestOptions?: RequestOptions) {
  const notify = useNotifications();
  const [isCanceled, setIsCanceled] = useState(false);
  const [hasBeenCalledManually, setHasBeenCalledManually] = useState(false);

  const axiosProps = useMemo(() => ({ method: "GET", url: staticUrl ?? undefined }), [staticUrl]);

  const axiosConfig = useMemo(
    () => ({ useCache: false, manual: !staticUrl, ...requestOptions?.axios }),
    [staticUrl, requestOptions?.axios]
  );

  const [{ data, loading: loadingRequest, error, response }, execute, cancelRequest] = useAxios<TEntity, {}>(
    { ...axiosProps, ...(requestOptions?.useNode && { baseURL: process.env.REACT_APP_NODE_API_URL }) },
    axiosConfig
  );

  const loading = loadingRequest && !isCanceled;

  const call = useCallback(
    (requestUrl?: string, config: AxiosRequestConfig<{}> = {}, options?: Options) => {
      setIsCanceled(false);
      setHasBeenCalledManually(true);
      const url = (requestUrl ?? staticUrl)!;
      return execute({ url, ...config }, options)
        .then((response) => {
          return response.data;
        })
        .catch((reason: AxiosError<ApiErrorResponse>) => {
          if (isRequestCanceled(reason)) return null;
          notify.error(reason?.response?.data?.message || "Contact an admin if this problem persists.");
          throw reason;
        }) as Promise<TEntity>;
    },
    [staticUrl, execute]
  );

  const cancel = () => {
    setIsCanceled(true);
    cancelRequest();
  };

  return {
    // data should be null if the url is null and it's set to automatically load
    data: hasBeenCalledManually || !axiosConfig.manual ? data ?? null : null,
    loading,
    error,
    response,
    call,
    cancel,
  };
}

/** This request will automatically call the server side staticUrl when any PUT/POST/DELETE requests in the matchPatterns have completed. */
export function useBaseAxiosGetRequestWithAutoRefresh<TEntity>(
  staticUrl: string | null,
  matchPatterns: RegExp[],
  options?: RequestOptions
) {
  const apiStatusContext = useContext(ApiStatusContext)!;
  const request = useBaseAxiosGetRequest<TEntity>(staticUrl, options);

  const handleApiRequestStatusChanged = useCallback(
    (message: Message) => {
      const url = message.id.substring(message.id.indexOf("_") + 1);
      const isMatch = matchPatterns.some((pattern) => url.match(pattern));

      if (isMatch && message.state === "completed") {
        if (!!staticUrl) request.call();
      }
    },
    [request.call]
  );

  useEffect(() => {
    return apiStatusContext.subscribe(handleApiRequestStatusChanged);
  }, [handleApiRequestStatusChanged]);

  return { ...request, call: request.call };
}

export function useBaseAxiosDeleteRequest<TId = number, TOut = void>(
  getUrl: (param: TId) => string,
  requestOptions?: RequestOptions
) {
  const baseRequestHeaders = getBaseRequestHeaders(null);
  const apiStatusContext = useContext(ApiStatusContext)!;
  const notify = useNotifications();
  const [formErrors, setFormErrors] = useState<ModelStateDictionary>({});
  const [isCanceled, setIsCanceled] = useState(false);

  const [{ loading: loadingRequest, error }, execute, cancelRequest] = useAxios<TOut, {}>(
    {
      method: "DELETE",
      headers: { ...baseRequestHeaders },
      ...(requestOptions?.useNode && { baseURL: process.env.REACT_APP_NODE_API_URL }),
    },
    {
      manual: true,
      useCache: false,
      ...requestOptions?.axios,
    }
  );
  const loading = loadingRequest && !isCanceled;

  const call = (id: TId, config: AxiosRequestConfig<{}> = {}, options?: Options) => {
    setIsCanceled(false);
    const url = getUrl(id);
    const apiCallId = `Delete_${url}`;
    if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.addSaving(apiCallId);
    return execute({ url, ...config }, options)
      .then((response) => {
        if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.removeSaving(apiCallId);
        setFormErrors({});
        return response.data;
      })
      .catch((reason: AxiosError<ApiErrorResponse>) => {
        if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.savingFailed(apiCallId);
        if (!!reason.response?.data?.errors) {
          const formErrors = reason.response?.data?.errors ?? {};
          setFormErrors(formErrors);
          notify.warning(reason?.response?.data?.message || "Invalid form data.");
          if (!requestOptions?.app?.dontNotifyFormValidationErrors)
            _.forEach(formErrors, (values, key) => notify.warning(`${key}: ${values.join()}`));
        } else {
          setFormErrors({});
          if (isRequestCanceled(reason)) return null;
          notify.error(reason?.response?.data?.message || "Contact an admin if this problem persists.");
          throw reason;
        }
      }) as Promise<TOut>;
  };

  const cancel = () => {
    setIsCanceled(true);
    cancelRequest();
  };

  return { loading, error, formErrors, call, cancel };
}

export function useBaseAxiosPostRequest<TIn, TOut = TIn>(url?: string, requestOptions?: RequestOptions) {
  const baseRequestHeaders = getBaseRequestHeaders(requestOptions?.formData ? "multipart/form-data" : null);
  const apiStatusContext = useContext(ApiStatusContext)!;
  const notify = useNotifications();
  const [formErrors, setFormErrors] = useState<ModelStateDictionary>({});
  const [isCanceled, setIsCanceled] = useState(false);

  const [{ data, loading: loadingRequest, error, response }, execute, cancelRequest] = useAxios<TOut, TIn>(
    {
      method: "POST",
      url,
      headers: { ...baseRequestHeaders },
      ...(requestOptions?.useNode && { baseURL: process.env.REACT_APP_NODE_API_URL }),
    },
    {
      manual: true,
      useCache: false,
      ...requestOptions?.axios,
    }
  );
  const loading = loadingRequest && !isCanceled;

  const call = (entity: TIn, config: AxiosRequestConfig<TIn> = {}, options?: Options) => {
    setIsCanceled(false);
    const apiCallId = `Post_${url}`;
    if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.addSaving(apiCallId);
    return execute({ data: entity, ...config }, options)
      .then((response) => {
        if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.removeSaving(apiCallId);
        setFormErrors({});
        return response.data;
      })
      .catch((reason: AxiosError<ApiErrorResponse>) => {
        if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.savingFailed(apiCallId);
        if (!!reason.response?.data?.errors) {
          const formErrors = reason.response?.data?.errors ?? {};
          setFormErrors(formErrors);
          notify.warning(reason?.response?.data?.message || "Invalid form data.");
          if (!requestOptions?.app?.dontNotifyFormValidationErrors)
            _.forEach(formErrors, (values, key) => notify.warning(`${key}: ${values.join()}`));
        } else {
          setFormErrors({});
          if (isRequestCanceled(reason)) return null;
          notify.error(reason?.response?.data?.message || "Contact an admin if this problem persists.");
          throw reason;
        }
      }) as Promise<TOut>;
  };

  const cancel = () => {
    setIsCanceled(true);
    cancelRequest();
  };

  return { data, error, formErrors, loading, response, call, cancel };
}

export function useBaseAxiosPutRequest<TIn, TOut = void>(url?: string, requestOptions?: RequestOptions) {
  const baseRequestHeaders = getBaseRequestHeaders();
  const apiStatusContext = useContext(ApiStatusContext)!;
  const notify = useNotifications();
  const [formErrors, setFormErrors] = useState<ModelStateDictionary>({});
  const [isCanceled, setIsCanceled] = useState(false);

  const [{ data, loading: loadingRequest, error, response }, execute, cancelRequest] = useAxios<TOut, TIn>(
    {
      method: "PUT",
      url,
      headers: { ...baseRequestHeaders },
      ...(requestOptions?.useNode && { baseURL: process.env.REACT_APP_NODE_API_URL }),
    },
    {
      manual: true,
      useCache: false,
      ...requestOptions?.axios,
    }
  );
  const loading = loadingRequest && !isCanceled;

  const call = (entity: TIn, config: AxiosRequestConfig<TIn> = {}, options?: Options) => {
    setIsCanceled(false);
    const apiCallId = `Put_${url}`;
    if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.addSaving(apiCallId);
    return execute({ data: entity, ...config }, options)
      .then((response) => {
        if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.removeSaving(apiCallId);
        setFormErrors({});
        return response.data;
      })
      .catch((reason: AxiosError<ApiErrorResponse>) => {
        if (!requestOptions?.app?.hideFromAutosaver) apiStatusContext.savingFailed(apiCallId);
        if (!!reason.response?.data?.errors) {
          const formErrors = reason.response?.data?.errors ?? {};
          setFormErrors(formErrors);
          notify.warning(reason?.response?.data?.message || "Invalid form data.");
          if (!requestOptions?.app?.dontNotifyFormValidationErrors)
            _.forEach(formErrors, (values, key) => notify.warning(`${key}: ${values.join()}`));
        } else {
          setFormErrors({});
          if (isRequestCanceled(reason)) return null;
          notify.error(reason?.response?.data?.message || "Contact an admin if this problem persists.");
          throw reason;
        }
      }) as Promise<TOut>;
  };

  const cancel = () => {
    setIsCanceled(true);
    cancelRequest();
  };

  return { data, error, formErrors, loading, response, call, cancel };
}

export function useBaseAxiosDownloadZipRequest<TRequest>(url: string, name: string) {
  const baseRequestHeaders = getBaseRequestHeaders(null);

  const [_, execute] = useAxios<BlobPart, TRequest>(
    {
      method: "POST",
      baseURL: process.env.REACT_APP_NODE_API_URL,
      url: url ?? undefined,
      responseType: "blob",
      headers: { ...baseRequestHeaders },
    },
    {
      manual: true,
      useCache: false,
    }
  );
  const notifications = useNotifications();

  const call = (entity: TRequest, config: AxiosRequestConfig<TRequest> = {}, options?: Options) => {
    const notify = notifications.info(`Downloading ${name}`, true);
    return execute({ data: entity, ...config }, options)
      .then((response) => {
        const zipBlob = new Blob([response.data], { type: "application/zip" });
        fileDownload(zipBlob, `${name} ${moment().format(dateFormat)}.zip`);
      })
      .finally(() => notifications.scheduleDismiss(notify));
  };

  return { call };
};
