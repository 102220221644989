import React from "react";
import { Label, Input, FormGroup, ButtonGroup, Button, Row, Col } from "reactstrap";
import classNames from "classnames";
import moment from "moment";
import { dateFormat } from "common/formats";
import { RecordFieldLabel } from "records";

interface Props {
   date: string;
   onChangeDate: (newDate: string) => void;
   bumpDate: (amount: number) => void;
   isInvalid?: boolean;
   readOnly?: boolean;
}

export class FollowUpInput extends React.Component<Props> {
   public render() {
      return (
         <FormGroup className="follow-up-input">
            <Label>{RecordFieldLabel.FollowUp}</Label>
            <Row>
               <Col sm={12} md={6}>
                  <Input
                     className={classNames({
                        "dim-date": this.props.date === "" || this.props.date === "Invalid date",
                        "is-invalid": this.props.isInvalid,
                     })}
                     type="date"
                     min={moment().format(dateFormat)}
                     value={this.props.date}
                     onChange={(e) => this.props.onChangeDate(e.target.value)}
                     readOnly={this.props.readOnly}
                  />
                  <div className="invalid-feedback">Enter a follow-up date, today or later.</div>
               </Col>
               {!this.props.readOnly && (
                  <Col sm={12} md={6}>
                     <ButtonGroup>
                        <Button outline color="secondary" onClick={() => this.props.bumpDate(7)}>
                           +7
                        </Button>
                        <Button outline color="secondary" onClick={() => this.props.bumpDate(30)}>
                           +30
                        </Button>
                        <Button outline color="secondary" onClick={() => this.props.bumpDate(60)}>
                           +60
                        </Button>
                        <Button outline color="secondary" onClick={() => this.props.bumpDate(90)}>
                           +90
                        </Button>
                     </ButtonGroup>
                  </Col>
               )}
            </Row>
         </FormGroup>
      );
   }
}
