import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
} from "app";
import { Summary, SummaryDetail, SummaryDigest } from "./models";

export const summariesMutationUriMatchPatterns = [/api\/summaries.*/gi];

export const useGetSummaryDigestsBySiteRequest = (siteId: number) =>
  useBaseAxiosGetRequest<SummaryDigest[]>(`api/summaries/digests/by/site/${siteId}`);

export const useGetSummaryDetailsBySiteRequest = (siteId: number) =>
  useBaseAxiosGetRequest<SummaryDetail[]>(`api/summaries/details/by/site/${siteId}`, { useNode: true });

export const useGetSummaryDetailRequest = (summaryId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<SummaryDetail>(
    `api/summaries/details/${summaryId}`,
    summariesMutationUriMatchPatterns,
    { useNode: true }
  );

export const useCreateSummaryRequest = () =>
  useBaseAxiosPostRequest<Partial<Summary>, SummaryDigest>(`api/summaries`, { useNode: true });
export const useUpdateSummaryRequest = () => useBaseAxiosPutRequest<Summary>(`api/summaries`, { useNode: true });
export const useDeleteSummaryRequest = () =>
  useBaseAxiosDeleteRequest((id) => `api/summaries/${id}`, { useNode: true });
