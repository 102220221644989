import * as React from "react";
import { configure } from "axios-hooks";
import Axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const AxiosSetup = (props: {children: React.ReactNode}) => {
   const { getAccessTokenSilently } = useAuth0();
   const [ready, setReady] = React.useState(false);

   React.useEffect(() => {
      const axios = Axios.create({
         baseURL: process.env.REACT_APP_API_URL,
      });

      axios.interceptors.request.use(
         async function (config) {
            var token = await getAccessTokenSilently();
            return {
               ...config,
               headers: {
                  ...config.headers,
                  Authorization: `Bearer ${token}`,
               },
            };
         },
         function (error) {
            return Promise.reject(error);
         }
      );

      configure({
         axios,
      });

      setReady(true);
   }, []);

   if (ready) {
      return <>{props.children}</>;
   } else {
      return <div></div>;
   }
};
