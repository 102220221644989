import { KpiCommodityUnit, KpiEmissionsType } from ".";
import { KpiBase, KpiBaseFields } from "./KpiBase";

export type KpiReleaseType = "Emission" | "Spill";
export const KpiReleaseTypes: KpiReleaseType[] = ["Emission", "Spill"];
export const normalizeKpiReleaseType = (value?: string | null) => {
  if (!!value) {
    const selected = KpiReleaseTypes.filter((v) => v.toLowerCase() === value.toLowerCase());
    if (selected.length > 0) {
      return selected[0];
    }
  }
  return null;
};
export interface KpiOneTimeReleaseOrSpillAvoidance extends KpiBase {
  kpiType: "OneTimeReleaseOrSpillAvoidance";
  releaseType: KpiReleaseType | null;
  reductionAmount: number | null;
  reductionUnit: KpiCommodityUnit | null;
  reductionType: string | KpiEmissionsType | null;
}
export const KpiOneTimeReleaseOrSpillAvoidanceFields: (keyof KpiOneTimeReleaseOrSpillAvoidance)[] = [
  ...KpiBaseFields,
  "releaseType",
  "reductionAmount",
  "reductionUnit",
  "reductionType",
];

export class KpiOneTimeReleaseOrSpillAvoidanceViewModel {
  model: KpiOneTimeReleaseOrSpillAvoidance;

  constructor(kpiValueDetail: KpiOneTimeReleaseOrSpillAvoidance) {
    this.model = kpiValueDetail;
  }
}
