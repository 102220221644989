import { useState } from "react";
import { Box, Button, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Stack, Tab, Tabs } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import { usePopover } from "app/mui/common/usePopover";
import { CustomDataViewScopes } from "./models";
import { useModal } from "app/mui/common/useModal";
import { TabsApi } from "../useTabbedViews";
import { NewViewModal, NewViewModalData } from "./NewViewModal";

interface TabViewsProps {
   tabsApi: TabsApi;
}

export const TabViews = (props: TabViewsProps) => {
   const { openPopover, closePopover, popoverProps } = usePopover();
   const { openModal, modalProps } = useModal();
   const [modalData, setModalData] = useState<NewViewModalData>();

   const addNewBlankView = () => {
      closePopover();
      setModalData({
         name: "",
         scope: CustomDataViewScopes.Everywhere,
         duplicate: false,
      });
      openModal();
   };

   const copyCurrentView = () => {
      closePopover();
      const placeholderName = props.tabsApi.currentView ? `${props.tabsApi.currentView.name} - copy` : "";
      setModalData({
         name: placeholderName,
         scope: CustomDataViewScopes.Everywhere,
         duplicate: true,
      });
      openModal();
   };

   return (
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, width: "100%" }}>
         <Stack direction="row" spacing={1}>
            <Tabs
               scrollButtons="auto"
               variant="scrollable"
               value={props.tabsApi.viewIndex ?? 0}
               onChange={(_event, newIndex) => props.tabsApi.changeTabView(newIndex)}
            >
               {props.tabsApi.views?.map((view) => (
                  <Tab key={view.id} label={view.name} />
               ))}
            </Tabs>
            <Button variant="text" onClick={openPopover}>
               <AddIcon fontSize="small" />
            </Button>
            <Popover
               {...popoverProps}
               anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
               }}
               transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
               }}
            >
               <MenuList>
                  <MenuItem onClick={addNewBlankView}>
                     <ListItemIcon>
                        <AddIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText>New Blank View</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={copyCurrentView}>
                     <ListItemIcon>
                        <ContentCopyIcon fontSize="small" />
                     </ListItemIcon>
                     <ListItemText>Copy Current View</ListItemText>
                  </MenuItem>
               </MenuList>
            </Popover>
         </Stack>
         {modalProps.open && modalData && (
            <NewViewModal {...modalProps} modalData={modalData} tabsApi={props.tabsApi} />
         )}
      </Box>
   );
};
