import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithRedirectCallback = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname, { replace: true });
  };

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
      scope="openid profile email offline_access"
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
