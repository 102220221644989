import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosDownloadZipRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
} from "app";
import { uriEncodeList } from "app/apiUtilities";
import { AttachmentModel as Attachment } from "@bluemarvel/iris-common/model";
import { Site } from "sites/models";

export type AttachmentParent = "Application" | "Asset" | "Record" | "Summary" | "PackagedSolution";

export const attachmentsUriMatchPatterns = [/api\/attachments/gi];

export const useGetAttachmentsRequest = (parent: AttachmentParent, parentId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<Attachment[]>(
    `api/attachments/${parent}/${parentId}`,
    attachmentsUriMatchPatterns,
    { useNode: true }
  );

export const useAddAttachmentRequest = () =>
  useBaseAxiosPostRequest<FormData, Attachment>(`api/attachments`, {
    formData: true,
    useNode: true,
  });

export interface UpdateAttachmentRequest {
  attachment: Attachment;
  rotate: boolean;
  copyToAsset: boolean;
}
export const useUpdateAttachmentRequest = () =>
  useBaseAxiosPutRequest<UpdateAttachmentRequest, Attachment>(`api/attachments`, {
    useNode: true,
  });

export interface CopyPhotoAttachmentToAssetRequest {
  assetId: number;
  attachmentId: number;
}

export const useCopyPhotoAttachmentToAssetRequest = () =>
  useBaseAxiosPostRequest<CopyPhotoAttachmentToAssetRequest, Attachment>(`/api/assets/copyPhotoToAsset`);

export const useDeleteAttachmentRequest = (parent: AttachmentParent, parentId: number) =>
  useBaseAxiosDeleteRequest((attachmentId) => `api/attachments/${parent}/${parentId}/${attachmentId}`, {
    useNode: true,
  });

export const useDeleteMultipleAttachmentsRequest = (parent: AttachmentParent, parentId: number) =>
  useBaseAxiosDeleteRequest(
    (attachmentIds: number[]) => `api/attachments/${parent}/${parentId}?${uriEncodeList(attachmentIds, "ids")}`,
    { useNode: true }
  );

export const useExportAttachmentsRequest = (parent: AttachmentParent, parentId: number, parentTag: string) =>
  useBaseAxiosDownloadZipRequest(`api/attachments/zip/${parent}/${parentId}`, `${parent} ${parentTag} attachments`);

export const useExportAssetAttachmentsRequest = (site: Site) =>
  useBaseAxiosDownloadZipRequest(`api/attachments/zip/assets`, `Assets ${site.customer.name} ${site.name}`);

export const useExportRecordAttachmentsRequest = (site: Site) =>
  useBaseAxiosDownloadZipRequest(`api/attachments/zip/records`, `Records ${site.customer.name} ${site.name}`);
