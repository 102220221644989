import { createContext, FunctionComponent, ReactNode, useContext, useState } from "react";

export interface DataGridExtensionsContextProps {
    isLoading: boolean | null;
    setIsLoading: (loading: boolean) => void;
}

export const DataGridExtensionsContext = createContext<DataGridExtensionsContextProps>({
    isLoading: null,
    setIsLoading: () => {},
});

export const DataGridExtensionsContextProvider: FunctionComponent<{
   children?: ReactNode;
}> = (props) => {
   const [ isLoading, setIsLoading] = useState(false);

   return <DataGridExtensionsContext.Provider value={{ isLoading, setIsLoading }}>{props.children}</DataGridExtensionsContext.Provider>;
};

export const useDataGridExtensionsContext = () => useContext(DataGridExtensionsContext);

