import { Paper, Stack, Typography } from "@mui/material";

import { Health } from "records";
import { IrisColors } from "app/mui/theme";
import { IrisTooltip } from "app/mui/IrisTooltip";
import { ValveHealthCardSection } from "./ValveHealthCardSection";
import InfoIcon from "@mui/icons-material/Info";

interface ValveHealthCardProps {
  health: Health;
}

export const ValveHealthCard = (props: ValveHealthCardProps) => {
  const { health } = props;

  return (
    <Paper sx={{ p: 2, minHeight: "240px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems={"center"}>
        <Typography variant="h6">VALVE HEALTH</Typography>
        <IrisTooltip
          title={
            <>
              <div className="popover-title">As-Found:</div>
              <div>
                rank the condition and performance of the assembly upon initial diagnostic evaluation, prior to any
                adjustments to the physical asset.
              </div>
              <div className="popover-title">As-Left:</div>
              <div>
                if work was completed that resulted in a differing health score, rank the condition and performance
                accordingly. To be based on follow-up diagnostic evaluation.
              </div>
              <div>
                <br />
                1 - Good (no areas of concern) <br />2 - Minor (small changes/fixes at opportune time) <br />3 -
                Moderate (elevated concerns affecting safety, operability, or control)
                <br />4 - Severe (major impedance or failure to operate)
              </div>
            </>
          }
        >
          <span>
            <InfoIcon sx={{ color: IrisColors.gray500, verticalAlign: "middle", fontSize: "1.7rem" }} />
          </span>
        </IrisTooltip>
      </Stack>
      <ValveHealthCardSection
        conditionFieldName="health.asFoundCondition"
        performanceFieldName="health.asFoundPerformance"
        syncConditionFieldName={!health.isAsLeftManuallyChanged ? "health.asLeftCondition" : undefined}
        syncPerformanceFieldName={!health.isAsLeftManuallyChanged ? "health.asLeftPerformance" : undefined}
        overall={health.asFoundOverall}
        overallCategory={health.asFoundOverallCategory}
        sectionTitle="As found"
      />
      <ValveHealthCardSection
        conditionFieldName="health.asLeftCondition"
        performanceFieldName="health.asLeftPerformance"
        pristineOnChangeFieldName={!health.isAsLeftManuallyChanged ? "health.isAsLeftManuallyChanged" : undefined}
        overall={health.asLeftOverall}
        overallCategory={health.asLeftOverallCategory}
        sectionTitle="As left"
      />
    </Paper>
  );
};
