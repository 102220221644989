import { LinearProgress } from "@mui/material";
import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";

interface Props {
  title: string;
  body: string;
  requireStringToBeTyped?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  show: boolean;
  confirm: () => void;
  cancel: () => void;
  noDanger?: boolean;
  loading?: boolean;
}

interface State {
  confirmationString: string;
}

export class ConfirmationModal extends React.PureComponent<Props, State> {
  public render() {
    return (
      <Modal isOpen={this.props.show} toggle={this.props.cancel} container={"#bootstrap-modal-container"}>
        <ModalHeader>{this.props.title}</ModalHeader>
        <ModalBody>
          {this.props.loading && <LinearProgress sx={{ mb: "5px" }} />}
          <p>{this.props.body}</p>
          {this.props.requireStringToBeTyped && (
            <Input
              value={this.state.confirmationString}
              onChange={(e) => this.setState({ confirmationString: e.target.value })}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color={this.props.noDanger ? "primary" : "danger"}
            onClick={this.props.confirm}
            disabled={
              this.props.loading || this.props.requireStringToBeTyped
                ? this.state.confirmationString !== this.props.requireStringToBeTyped
                : undefined
            }
          >
            {this.props.confirmButtonText || "OK"}
          </Button>
          <Button disabled={this.props.loading} onClick={this.props.cancel}>
            {this.props.cancelButtonText || "Cancel"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      confirmationString: "",
    };
  }
}
