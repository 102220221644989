import { ControlledTextAreaFormItem } from "app/mui/forms/Input/ControlledTextAreaFormItem";

interface KpiCommentsFormItemProps {
  fieldName: string;
  alwaysShowInput?: boolean;
  label?: string;
  minRows?: number;
  readOnly?: boolean;
}

export const KpiCommentsFormItem = ({
  label,
  fieldName,
  readOnly,
  alwaysShowInput,
  minRows = 3
}: KpiCommentsFormItemProps) => {
  
  return (
    <ControlledTextAreaFormItem
      minRows={minRows}
      label={label}
      alwaysShowInput={alwaysShowInput}
      fieldName={fieldName}
      readOnly={readOnly}
    />
  );
};