import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import { GridToolbarFilterButton, useGridApiRef } from "@mui/x-data-grid-pro";
import { Routes, formatRoutePath, useSiteContext } from "app";
import { ActionDispatcherProvider, useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import {
  createStaticActions,
  createStaticColumns,
  createStaticRowActions,
  useColumns,
  useStaticActions,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import { formatCurrency } from "common";
import { useGetFilteredKpiTemplateDetailsInSiteRequest } from "kpis/api";
import { KpiTemplateDetail, KpiTemplateFilters, TemplateKpiNames, toKpiTypeDisplayName } from "kpis/models";
import { sumBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CreateKpiTemplateDialog } from "./dialogs/CreateKpiTemplate";
import { PageTitle } from "app/mui/common/headers/PageTitle";
import { KpiIcon } from "icons/KpiIcon";
import { DeleteKpiTemplateDialog } from "./dialogs/DeleteKpiTemplateDialog";

type DialogActionsType = "deleteKpiTemplate" | "createKpiTemplate" | null;
const validDialogActions = ["deleteKpiTemplate", "createKpiTemplate"];

const rowActions = createStaticRowActions((actionDispatcher, permissions) => (params) => [
  {
    icon: <DeleteRoundedIcon />,
    onClick: () => {
      actionDispatcher.dispatch("deleteKpiTemplate", params.row);
    },
    label: "Delete",
    showInMenu: true,
    hidden: !permissions.userCanEditKpiTemplate,
  },
]);

const pageActions = createStaticActions((actionDispatcher, userPermissions) => [
  {
    icon: <InsertChartOutlinedIcon />,
    label: "Create KPI Template",
    action: () => actionDispatcher.dispatch("createKpiTemplate"),
    hidden: !userPermissions.userCanEditKpiTemplate,
  },
]);

export const KpiTemplateListPage = () => {
  const [filters] = useState(new KpiTemplateFilters());
  const site = useSiteContext().currentSite!;
  const siteId = site.id;

  const kpiTemplatesRequest = useGetFilteredKpiTemplateDetailsInSiteRequest(siteId);
  const kpiTemplateDetails = kpiTemplatesRequest.data || [];

  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);
  const actionDispatcher = useActionDispatcher();
  const [kpiTemplateForDialog, setKpiTemplateForDialog] = useState<KpiTemplateDetail>();

  const fetchKpiTemplates = useCallback(() => {
    filters.siteId = siteId;
    filters.enabled = true;
    kpiTemplatesRequest.call(filters);
  }, []);

  const actions = useStaticActions(pageActions, actionDispatcher);

  const closeDialog = () => setOpenDialog(null);

  const handleAction = useCallback((action: string, params: KpiTemplateDetail) => {
    if (validDialogActions.indexOf(action) > -1) {
      if (action === "deleteKpiTemplate") {
        setKpiTemplateForDialog(params);
      }

      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  useEffect(() => {
    fetchKpiTemplates();
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const getTableRowActions = useStaticRowActions(rowActions, actionDispatcher);

  const columnDef = createStaticColumns((actionDispatcher) => [
    // Name
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) => params.row.model.name,
      renderCell: (params) => {
        return (
          <Link
            color="secondary"
            component={RouterLink}
            to={formatRoutePath(Routes.KpiTemplate, params.row as KpiTemplateDetail)}
            sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
            state={{ returnTo: window.location.pathname }}
          >
            {params.row.model.name}
          </Link>
        );
      },
      flex: 1,
    },
    // Type
    {
      field: "kpiType",
      headerName: "Type",
      width: 420,
      valueGetter: (params) => toKpiTypeDisplayName(params.row.kpiType),
      type: "singleSelect",
      valueOptions: Array.from(TemplateKpiNames),
    },
    // Status
    {
      field: "model.enabled",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => (params.row.model.enabled ? "Active" : "Archived"),
      type: "singleSelect",
      valueOptions: ["Archived", "Active"],
    },

    // Signed off
    {
      field: "model.signedOff",
      headerName: "Signed off",
      valueGetter: (params) => (params.row.model.signedOff ? "Yes" : "No"),
      type: "singleSelect",
      valueOptions: ["Yes", "No"],
    },

    // Savings
    {
      field: "calculatedSavings",
      headerName: "Savings",
      valueGetter: (params) => {
        return params.row.calculatedSavingsUnit?.startsWith("Dollars")
          ? formatCurrency(params.row.calculatedSavings)
          : params.row.calculatedSavings;
      },
      type: "number",
    },

    // Savings unit
    {
      field: "calculatedSavingsUnit",
      headerName: "Savings unit",
      width: 300,
    },

    // ROI
    {
      field: "calculatedReturnOnInvestment",
      headerName: "ROI",
    },

    // Created
    {
      field: "created",
      headerName: "Created",
      type: "date",
    },

    // Last Assigned
    {
      field: "lastAssignedOn",
      headerName: "Last Assigned",
      type: "date",
      width: 120,
    },

    // Assigned records
    {
      field: "recordTypeUsages",
      headerName: "Assigned Records",
      type: "number",
      valueGetter: (params) => {
        if (!params.row.recordTypeUsages) return 0;
        return sumBy(params.row.recordTypeUsages, "numberOfRecords");
      },
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      getActions: getTableRowActions,
      hideable: false,
    },
  ]);

  const columns = useColumns(columnDef, actionDispatcher);
  const apiRef = useGridApiRef();

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <PageTitle title="KPI Templates" icon={<KpiIcon />} />
            <ActionsDropdownMenu primary actions={actions} />
          </Stack>

          <Box sx={{ height: "700px", width: "100%" }}>
            <StripedDataGrid
              apiRef={apiRef}
              density="compact"
              loading={kpiTemplatesRequest.loading}
              columns={columns}
              components={{
                Toolbar: GridToolbarFilterButton,
              }}
              rows={kpiTemplateDetails}
            />
          </Box>
        </Stack>
        {openDialog === "deleteKpiTemplate" && (
          <DeleteKpiTemplateDialog
            onClose={closeDialog}
            kpiTemplateDetail={kpiTemplateForDialog!}
            onDeleteKpiTemplate={fetchKpiTemplates}
          />
        )}
        {openDialog === "createKpiTemplate" && <CreateKpiTemplateDialog onClose={closeDialog} />}
      </Paper>
    </ActionDispatcherProvider>
  );
};
