import { Chip } from "@mui/material";
import { SummaryType } from "summaries/models";

interface Props {
  summaryType?: SummaryType;
  styleType?: "outlined" | "filled";
}

export const SummaryTypeBadge = (props: Props) => {
  if (!props.summaryType) {
    return null;
  }

  const summaryTypeBadgeProps = {
    borderRadius: 1,
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "12px",
  };

  if (props.styleType === "filled") {
    return (
      <Chip
        size="small"
        label={props.summaryType}
        variant={"filled"}
        color={"default"}
        sx={{
          ...summaryTypeBadgeProps,
          backgroundColor: "white",
          color: "#004b8d",
          padding: "0 8px",
          height: "20px",
          justifyContent: "flex-start",
          borderColor: "#004b8d",
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      />
    );
  }

  return (
    <Chip
      size="small"
      label={props.summaryType}
      variant={"outlined"}
      color={"secondary"}
      sx={{
        ...summaryTypeBadgeProps,
        backgroundColor: "transparent",
        color: "default",
        padding: "2px 8px",
        height: "auto",
        justifyContent: "flex-start",
        borderColor: "transparent",
        borderWidth: "0",
        borderStyle: "none",
      }}
    />
  );
};
