import { TypographyProps, GridProps } from "@mui/material";
import { IrisColors } from "app/mui/theme";

export class KpiGridStyles {
  public static primaryDividerTitleProps: TypographyProps = {
    variant: "h6",
    sx: {
      color: IrisColors.dark,
    },
  };

  public static primaryDividerSubtitleProps: TypographyProps = {
    fontSize: "1rem",
    fontStyle: "italic",
    variant: "subtitle1",
    sx: {
      color: IrisColors.gray600,
    },
  };

  public static secondaryDividerTitleProps: TypographyProps = {
    variant: "h6",
    sx: {
      color: IrisColors.gray600,
    },
  };

  public static gridContainerProps = {
    columnSpacing: 2,
    rowSpacing: 2,
  };

  public static kpiSectionProps: GridProps = {
    mt: 5,
  };

  public static gridRowProps: GridProps = {
    mt: 3,
  };
}
