import _ from "lodash";
import moment from "moment";
import { ModelStateDictionary } from "app";
import { HealthCategory } from "records";
import { IrisColors } from "app/mui/theme";
import { NumberFormatInput } from "app/mui/forms/Input/NumberFormatInput";

export interface Validity {
  valid: boolean;
  message: string;
}

export const getValidValidity = () => {
  return { valid: true, message: "" } as Validity;
};
export const getInvalidValidity = (message?: string) => {
  return { valid: false, message: message || "*" } as Validity;
};
export const getValidity = (isValid: boolean, messageIfInvalid?: string) =>
  isValid ? getValidValidity() : getInvalidValidity(messageIfInvalid);

export const getValidityFromFormErrors = (formErrors: ModelStateDictionary, fieldName: string) => {
  const formError = formErrors[fieldName];
  return getValidity(!formError, formError?.join());
};

export const getOverallValidity = (validities: Validity[]) => getValidity(!validities.some((v) => !v.valid));

export enum HealthColors {
  Grey = "grey",
  Green = "green",
  Yellow = "yellow",
  Orange = "orange",
  Red = "red",
}

export function getColorForHealthByCategory(category: HealthCategory | null) {
  //Same functionality as "GetClassForOverallHealthScore" found in Views\Report\RecordReport.cshtml
  //If these cutoff values are updated make sure to update them there as well.

  switch (category) {
    case "Severe":
      return HealthColors.Red;
    case "Moderate":
      return HealthColors.Orange;
    case "Minor":
      return HealthColors.Yellow;
    case "Good":
      return HealthColors.Green;
    default:
      return HealthColors.Grey;
  }
}

export function getColorForHealthByFourPointScore(score: number | null) {
  if (score == null || score === 0) {
    return IrisColors.gray500;
  }

  var rounded = _.round(score, 2);

  if (rounded <= 1.6) {
    return IrisColors.green;
  }

  if (rounded <= 2.38) {
    return IrisColors.yellow;
  }

  if (rounded < 3.19) {
    return IrisColors.orange;
  }

  return IrisColors.red;
}

export const visualIntegrityOptions = [
  { value: "Unknown", label: "Unknown" },
  { value: "1.000", label: "green" },
  { value: "2.000", label: "yellow" },
  { value: "3.000", label: "orange" },
  { value: "4.000", label: "red" },
];

export function getVisualIntegrityLabel(value: string) {
  return visualIntegrityOptions[Number(value)].label;
}

export function isInRange(value: number | null, bottom: number, top: number) {
  if (value === null) return true;

  return value >= bottom && value <= top;
}

export function normalizeTag(tag: string) {
  return tag.replace(/–/gi, "-").trim();
}

export function normalizeName(name: string) {
  return name.trim().toUpperCase();
}

export function escapeForCsv(text: any) {
  if (text === null) return "";
  if (typeof text === "string") return text.replace(/"/g, '""');

  return text;
}

// prepending a tab to the CSV value will prevent Excel from treating it as a number, instead preserving the full text
export function escapeForCsvNumeric(text: number | null) {
  var escaped = escapeForCsv(text);
  if (typeof escaped !== "string" || !!escaped.match(/^[\d\-.]+$/)) return `\t${escaped}`;
  return escaped;
}

export const upToTwoDecimalNumberFormatter = Intl.NumberFormat("en-CA", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
export const twoDecimalNumberFormatter = Intl.NumberFormat("en-CA", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const wholeNumberFormatter = Intl.NumberFormat("en-CA", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
export const compactWholeNumberFormatter = Intl.NumberFormat("en-CA", {
  compactDisplay: "short",
  notation: "compact",
});

export const formatCurrency = (value: number | null, isRounded: boolean = false) => {
  let val = value ?? 0;
  if (isNaN(val)) {
    return isRounded ? "$0" : "$0.00";
  }
  if (isRounded) {
    val = Math.round(val);
  }
  return `$${numberWithCommas(val ?? 0)}`;
};

export function numberWithCommas(amount: number | null, formatter: Intl.NumberFormat = upToTwoDecimalNumberFormatter) {
  return amount == null ? "" : formatter.format(amount);
}

export const hashString = (value: string | null) => {
  var hash = 0;
  for (var i = 0; i < (value || "").length; i++) {
    var code = value!.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

// rudimentary hasher to use for calculating effects changes of arrays
export function getArrayHash<T>(array: T[] | null | undefined, mapHash: (value: T) => number) {
  if (!array || array.length === 0) return 0;
  return array.length * _.sum(array.map(mapHash));
}

export const getNumericArrayHash = (array: number[] | null | undefined) => getArrayHash(array, (value) => value);
export const getStringArrayHash = (array: string[] | null | undefined) => getArrayHash(array, hashString);

export const getValidDateOrNull = (value: Date | null) => (!value || !moment(value).isValid() ? null : value);

/**
 * pluralizes the 'single' if count !== 1
 * @param count
 * @param single the single string, e.g. 'day'
 * @param plural defaults to `${single}s`
 * @returns
 */
export const pluralize = (count: number, single: string, plural?: string) => {
  if (count === 1) return single;
  return !!plural ? plural : `${single}s`;
};

export const stringEqualsIgnoreCase = (a: string | null, b: string | null) =>
  (a ?? "").toLowerCase() === (b ?? "").toLowerCase();

export const getUniqueKeys = (data: {}) =>
  _.uniq(
    Object.keys(data)
      .map((k) => Object.entries(data[k])) // each key/value pair
      .reduce((a, b) => [...a, ...b], [] as any[]) // combine all k/v pairs into one list
      .reduce((a, b) => [...a, b[0]], [] as any[]) // take only the keys
  );
