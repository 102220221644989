import { formatRoutePath, Routes, RoutingTokens } from "app";
import { Site } from "sites";

const generateRoutePath =
  (path: string) =>
    (siteId: number) =>
      (tokens: Partial<RoutingTokens> = {}) => {
        const routeParams: RoutingTokens = { siteId, ...tokens };
        return formatRoutePath(path, routeParams);
      };

export const SiteBasedRouting = <T extends { id: any }>(site?: T | null) => {
  const siteId = site?.id ?? "";

  return {
    SiteDashboard: generateRoutePath(Routes.SiteDashboard)(siteId),
    Assets: generateRoutePath(Routes.Assets)(siteId),
    MachineryComponents: generateRoutePath(Routes.MachineryComponents)(siteId),
    FilteredAssets: generateRoutePath(Routes.FilteredAssets)(siteId),
    Asset: generateRoutePath(Routes.Asset)(siteId),
    ImportData: generateRoutePath(Routes.ImportData)(siteId),
    ImportCsv: generateRoutePath(Routes.ImportCsv)(siteId),
    ImportOffline: generateRoutePath(Routes.ImportOffline)(siteId),
    Record: generateRoutePath(Routes.Record)(siteId),
    RecordsEvents: generateRoutePath(Routes.RecordsEvents)(siteId),
    RecordsEvent: generateRoutePath(Routes.RecordsEvent)(siteId),
    Applications: generateRoutePath(Routes.Applications)(siteId),
    PackageSolutions: generateRoutePath(Routes.PackagedSolutions)(siteId),
    Application: generateRoutePath(Routes.Application)(siteId),
    Summaries: generateRoutePath(Routes.Summaries)(siteId),
    Summary: generateRoutePath(Routes.Summary)(siteId),
    KpiTemplates: generateRoutePath(Routes.KpiTemplates)(siteId),
    KpiTemplate: generateRoutePath(Routes.KpiTemplate)(siteId),
    Tasks: generateRoutePath(Routes.Tasks)(siteId),
    Records: generateRoutePath(Routes.Records)(siteId),
    Kpis: generateRoutePath(Routes.Kpis)(siteId),
    Recommendations: generateRoutePath(Routes.Recommendations)(siteId),
  };
};

export const makeSiteContextRouteNavigator = (site?: Site | null) => {
  if (!site) {
    return null;
  }
  return {
    ...site,
    routeTo: SiteBasedRouting(site),
  };
};

export function makeSiteDigestRouteNavigator<T extends { id: any }>(site: T) {
  return {
    ...site,
    routeTo: SiteBasedRouting(site),
  };
}

export type WithSiteRouting<T> = { routeTo: ReturnType<typeof SiteBasedRouting> } & T;
