import { Stack, Typography } from "@mui/material";
import { BaseFormItemProps } from "app/mui/forms/Base/BaseFormItemProps";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { NumberFormatInput } from "app/mui/forms/Input/NumberFormatInput";
import { formatCurrency } from "common";
import { Controller } from "react-hook-form";

interface SpecialistLabourFormItemProps extends BaseFormItemProps {
  hourlyRate: number;
}

export function SpecialistLabourFormItem(props: SpecialistLabourFormItemProps) {
  const { hourlyRate, readOnly, fieldName, label = "Specialist Labour", ...formItemLayoutProps } = props;

  return (
    <Controller
      name={fieldName}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <FormItemLayout
            controlled
            hint={props.hint}
            label={label}
            fieldName={fieldName}
            error={errors[fieldName]?.message?.toString()}
            formControlProps={{ disabled: readOnly }}
            fullWidth={false}
            {...formItemLayoutProps}
          >
            {(inputProps) => (
              <Stack direction="row" alignItems="center" spacing={2}>
                <NumberFormatInput
                  onChange={onChange}
                  value={value}
                  {...inputProps}
                  numberFormatProps={{ decimalScale: 2 }}
                />
                <Typography>hrs&nbsp;&times;&nbsp;{`${formatCurrency(hourlyRate)}/hr`}</Typography>
              </Stack>
            )}
          </FormItemLayout>
        );
      }}
    />
  );
}
