import { ReactNode } from "react";
import { RecordDetailView, useGetRecordByNextRecordIdQuery } from "records";

interface PreviousRecordloaderProps {
  recordId: number;
  children: (params: { recordDetail: RecordDetailView | null; loading: boolean }) => ReactNode;
}

function PreviousRecordLoader(props: PreviousRecordloaderProps) {
  const { data, loading } = useGetRecordByNextRecordIdQuery(props.recordId);
  const recordDetail = data || null;
  return <>{props.children({ recordDetail, loading })}</>;
}

export default PreviousRecordLoader;
