import * as React from "react";
import classNames from "classnames";

export interface IconProps extends React.SVGProps<SVGSVGElement> {
   className?: string;
   viewBoxSize?: number;
   children?: React.ReactNode;
}

export const Icon: React.FunctionComponent<IconProps> = (props) => {
   const { className, viewBoxSize, children, ...rest } = props;
   const boxSize = viewBoxSize !== undefined ? viewBoxSize : 24;

   return (
      <svg
         className={classNames("icon", className)}
         xmlns="http://www.w3.org/2000/svg"
         viewBox={`0 0 ${boxSize} ${boxSize}`}
         width="1em"
         height="1em"
         {...rest}
      >
         {children}
      </svg>
   );
};
